/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Avocado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="avocado-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-avocado-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m211 130.66-29.8-84.19a56 56 0 0 0-106-1.14l-29.51 83.5A88 88 0 1 0 211 130.66ZM128 200a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" /></Svg>;
export default Avocado;