/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilRuler = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-ruler-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-ruler-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48v160a16 16 0 0 1-16 16h-48a16 16 0 0 1-16-16v-28a4 4 0 0 1 4-4h36a8 8 0 0 0 8-8.53 8.18 8.18 0 0 0-8.25-7.47H148a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h36a8 8 0 0 0 8-8.53 8.18 8.18 0 0 0-8.25-7.47H148a4 4 0 0 1-4-4v-16a4 4 0 0 1 4-4h36a8 8 0 0 0 8-8.53 8.18 8.18 0 0 0-8.27-7.47H148a4 4 0 0 1-4-4V48a16 16 0 0 1 16-16h48a16 16 0 0 1 16 16ZM109.66 58.34A8 8 0 0 1 112 64v144a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V64a8 8 0 0 1 2.34-5.66l32-32a8 8 0 0 1 11.32 0ZM48 80v104h16V80Zm32 0v104h16V80ZM51.31 64h41.38L72 43.31Z" /></Svg>;
export default PencilRuler;