/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Towel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="towel-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-towel-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48v104a8 8 0 0 1-8.53 8 8.17 8.17 0 0 1-7.47-8.25V48a8 8 0 0 0-8.55-8 8.19 8.19 0 0 0-7.45 8.28V180a4 4 0 0 1-4 4H52a4 4 0 0 1-4-4V48a24 24 0 0 1 24-24h128a24 24 0 0 1 24 24Zm-36 152H52a4 4 0 0 0-4 4v12a16 16 0 0 0 16 16h112a16 16 0 0 0 16-16v-12a4 4 0 0 0-4-4Z" /></Svg>;
export default Towel;