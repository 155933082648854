/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleCardboardLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-cardboard-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-cardboard-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 44H32a20 20 0 0 0-20 20v128a20 20 0 0 0 20 20h64a12 12 0 0 0 8.49-3.51L128 185l23.51 23.52A12 12 0 0 0 160 212h64a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20Zm-4 144h-55l-22.83-22.83a20 20 0 0 0-28.28 0L91 188H36V68h184ZM82 160a34 34 0 1 0-34-34 34 34 0 0 0 34 34Zm0-44a10 10 0 1 1-10 10 10 10 0 0 1 10-10Zm92 44a34 34 0 1 0-34-34 34 34 0 0 0 34 34Zm0-44a10 10 0 1 1-10 10 10 10 0 0 1 10-10Z" /></Svg>;
export default GoogleCardboardLogo;