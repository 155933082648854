/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Coin = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coin-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coin-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206.68 65.63C186.23 55.41 159 50 128 50s-58.23 5.41-78.68 15.63C29.41 75.58 18 89.57 18 104v48c0 14.43 11.41 28.42 31.32 38.37C69.77 200.59 97 206 128 206s58.23-5.41 78.68-15.63C226.59 180.42 238 166.43 238 152v-48c0-14.43-11.41-28.42-31.32-38.37Zm-152 10.74C73.21 67.1 99.25 62 128 62s54.79 5.1 73.32 14.37C217 84.21 226 94.28 226 104s-9 19.79-24.68 27.63C182.79 140.9 156.75 146 128 146s-54.79-5.1-73.32-14.37C39 123.79 30 113.72 30 104s9-19.79 24.68-27.63ZM122 157.93v36c-19.24-.48-37.07-3.28-52-8.11v-35.4c15.27 4.51 32.85 7.07 52 7.51Zm12 0c19.15-.44 36.73-3 52-7.52v35.4c-14.93 4.83-32.76 7.63-52 8.11ZM30 152v-23a76 76 0 0 0 19.32 13.36q4.16 2.07 8.68 3.88v35c-1.13-.52-2.24-1-3.32-1.58C39 171.79 30 161.72 30 152Zm171.32 27.63c-1.08.54-2.19 1.06-3.32 1.58v-35q4.51-1.81 8.68-3.88A76 76 0 0 0 226 129v23c0 9.72-9 19.79-24.68 27.63Z" /></Svg>;
export default Coin;