/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BugBeetle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bug-beetle-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bug-beetle-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204 148h20a4 4 0 0 0 0-8h-20v-24h20a4 4 0 0 0 0-8h-20.11a75.8 75.8 0 0 0-23.37-50.86l22.31-22.31a4 4 0 1 0-5.66-5.66L174.44 51.9a75.77 75.77 0 0 0-92.88 0L58.83 29.17a4 4 0 0 0-5.66 5.66l22.31 22.31A75.8 75.8 0 0 0 52.11 108H32a4 4 0 0 0 0 8h20v24H32a4 4 0 0 0 0 8h20v12a75.41 75.41 0 0 0 1 12H32a4 4 0 0 0 0 8h22.69a76 76 0 0 0 146.62 0H224a4 4 0 0 0 0-8h-21a75.41 75.41 0 0 0 1-12ZM128 44a68.08 68.08 0 0 1 67.87 64H60.13A68.08 68.08 0 0 1 128 44Zm4 183.87V144a4 4 0 0 0-8 0v83.87A68.08 68.08 0 0 1 60 160v-44h136v44a68.08 68.08 0 0 1-64 67.87Z" /></Svg>;
export default BugBeetle;