/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleChromeLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-chrome-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-chrome-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 128a40 40 0 1 1-40-40 40 40 0 0 1 40 40Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 16a88 88 0 0 1 73.72 40H128a48.08 48.08 0 0 0-45.6 33L59.32 73A87.89 87.89 0 0 1 128 40Zm32 88a32 32 0 1 1-32-32 32 32 0 0 1 32 32Zm-45.28 87A88 88 0 0 1 49.56 88.14L86.43 152c.06.1.13.19.19.28a48 48 0 0 0 51.2 22.72Zm18 .87L169.57 152c.08-.14.14-.28.22-.42a47.88 47.88 0 0 0-6-55.58H210a88 88 0 0 1-77.29 119.87Z" /></Svg>;
export default GoogleChromeLogo;