/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-nine__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-nine'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 40a56 56 0 1 0 15.62 109.77L113 204.07a8 8 0 1 0 14 7.85l49.55-88A56 56 0 0 0 128 40Zm0 96a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" /></Svg>;
export default NumberNine;