/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmReel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-reel-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-reel-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 218h-56a102 102 0 1 0-48 12h104a6 6 0 0 0 0-12ZM38 128a90 90 0 1 1 90 90 90.1 90.1 0 0 1-90-90Zm90-26a22 22 0 1 0-22-22 22 22 0 0 0 22 22Zm0-32a10 10 0 1 1-10 10 10 10 0 0 1 10-10Zm22 106a22 22 0 1 0-22 22 22 22 0 0 0 22-22Zm-32 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm58-26a22 22 0 1 0-22-22 22 22 0 0 0 22 22Zm0-32a10 10 0 1 1-10 10 10 10 0 0 1 10-10Zm-96-12a22 22 0 1 0 22 22 22 22 0 0 0-22-22Zm0 32a10 10 0 1 1 10-10 10 10 0 0 1-10 10Z" /></Svg>;
export default FilmReel;