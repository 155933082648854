/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CrownSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-simple__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-simple'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.9 73.6a15.85 15.85 0 0 0-18.9 3.79l-33.67 36.29-35.8-80.29a1 1 0 0 1 0-.1 16 16 0 0 0-29.06 0 1 1 0 0 1 0 .1l-35.8 80.29L44 77.39a16 16 0 0 0-27.75 13.42c0 .11 0 .21.07.32L39 195a16 16 0 0 0 15.72 13h146.57A16 16 0 0 0 217 195l22.68-103.87c0-.11 0-.21.07-.32a15.85 15.85 0 0 0-8.85-17.21Zm-29.55 118.08-.06.32H54.71l-.06-.32L32 88l.14.16 42 45.24a8 8 0 0 0 13.18-2.18L128 40l40.69 91.25a8 8 0 0 0 13.18 2.18l42-45.24.13-.19Z" /></Svg>;
export default CrownSimple;