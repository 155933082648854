/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CirclesThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circles-three-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circles-three-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 76a48 48 0 1 0-48 48 48.05 48.05 0 0 0 48-48Zm-48 24a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm60 24a48 48 0 1 0 48 48 48.05 48.05 0 0 0-48-48Zm0 72a24 24 0 1 1 24-24 24 24 0 0 1-24 24ZM68 124a48 48 0 1 0 48 48 48.05 48.05 0 0 0-48-48Zm0 72a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default CirclesThree;