/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyBtc = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-btc-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-btc-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 152a16 16 0 0 1-16 16h-48v-32h48a16 16 0 0 1 16 16Zm64-24A104 104 0 1 1 136 24a104.11 104.11 0 0 1 104 104Zm-48 24a32 32 0 0 0-15.51-27.42A32 32 0 0 0 160 73v-9a8 8 0 0 0-16 0v8h-16v-8a8 8 0 0 0-16 0v8H96a8 8 0 0 0 0 16v80a8 8 0 0 0 0 16h16v8a8 8 0 0 0 16 0v-8h16v8a8 8 0 0 0 16 0v-8a32 32 0 0 0 32-32Zm-24-48a16 16 0 0 0-16-16h-40v32h40a16 16 0 0 0 16-16Z" /></Svg>;
export default CurrencyBtc;