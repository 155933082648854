/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Television = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="television-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-television-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 68h-78.34l41.17-41.17a4 4 0 1 0-5.66-5.66L128 66.34 82.83 21.17a4 4 0 0 0-5.66 5.66L118.34 68H40a12 12 0 0 0-12 12v120a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V80a12 12 0 0 0-12-12ZM36 200V80a4 4 0 0 1 4-4h108v128H40a4 4 0 0 1-4-4Zm184 0a4 4 0 0 1-4 4h-60V76h60a4 4 0 0 1 4 4Zm-24-84a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm0 48a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default Television;