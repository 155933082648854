/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitMerge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-merge-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-merge-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 108a36.05 36.05 0 0 0-33.38 22.54l-39-5.57a4 4 0 0 1-2.47-1.35l-32.6-38.09A36 36 0 1 0 68 89.94v76.12a36 36 0 1 0 24 0v-53.62l23 26.8a28 28 0 0 0 17.3 9.49l41.34 5.91A36 36 0 1 0 208 108ZM80 44a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm0 168a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm128-56a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default GitMerge;