/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThermometerCold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="thermometer-cold-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-thermometer-cold-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m247.67 73.92-25.2 8.18L238 103.54a4 4 0 1 1-6.47 4.7L216 86.81l-15.57 21.43a4 4 0 0 1-3.24 1.65 4 4 0 0 1-3.23-6.35l15.57-21.44-25.2-8.18a4 4 0 1 1 2.47-7.61l25.2 8.18V48a4 4 0 0 1 8 0v26.49l25.2-8.18a4 4 0 1 1 2.47 7.61ZM148 184a28 28 0 1 1-32-27.71V120a4 4 0 0 1 8 0v36.29A28 28 0 0 1 148 184Zm-8 0a20 20 0 1 0-20 20 20 20 0 0 0 20-20Zm40 0a60 60 0 1 1-96-48V48a36 36 0 0 1 72 0v88a60.06 60.06 0 0 1 24 48Zm-8 0a52.06 52.06 0 0 0-22.29-42.68A4 4 0 0 1 148 138V48a28 28 0 0 0-56 0v90a4 4 0 0 1-1.71 3.28A52 52 0 1 0 172 184Z" /></Svg>;
export default ThermometerCold;