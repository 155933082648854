/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Plant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plant-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plant-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243.63 48.12a4 4 0 0 0-3.76-3.75c-50.25-3-90.44 12.1-107.52 40.29-11.64 19.22-11.17 41.92 1.24 64.21a67.33 67.33 0 0 0-16.65 34.41l-23.49-23.49c8.63-16 8.81-32.33.42-46.19C81.45 93.09 52.35 82.13 16 84.26A4 4 0 0 0 12.26 88c-2.13 36.33 8.83 65.43 29.34 77.85a41.82 41.82 0 0 0 21.88 6.18 51.29 51.29 0 0 0 24.31-6.6L116 193.67V224a4 4 0 0 0 8 0v-29.49a59.57 59.57 0 0 1 15.29-40c11.49 6.36 23.07 9.56 34.24 9.56a57 57 0 0 0 29.81-8.41c28.18-17.09 43.25-57.28 40.29-107.54ZM45.74 159C28.47 148.56 19 123.69 20.08 92.08c31.61-1.09 56.48 8.39 67 25.66 6.48 10.71 6.59 23.37.46 36.09l-28.72-28.66a4 4 0 0 0-5.65 5.65l28.67 28.67c-12.73 6.13-25.39 6.02-36.1-.49Zm153.45-10.2c-16.05 9.72-35.09 9.59-54.08-.25l57.72-57.73a4 4 0 0 0-5.65-5.66l-57.73 57.73c-9.84-19-10-38-.25-54.08 15.11-25 51.08-38.53 96.63-36.64 1.9 45.55-11.68 81.52-36.64 96.63Z" /></Svg>;
export default Plant;