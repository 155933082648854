/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreeEvergreen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tree-evergreen__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tree-evergreen'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m230.32 187.09-46-59.09H208a8 8 0 0 0 6.34-12.88l-80-104a8 8 0 0 0-12.68 0l-80 104A8 8 0 0 0 48 128h23.64l-46 59.09A8 8 0 0 0 32 200h88v40a8 8 0 0 0 16 0v-40h88a8 8 0 0 0 6.32-12.91ZM48.36 184l46-59.09A8 8 0 0 0 88 112H64.25L128 29.12 191.75 112H168a8 8 0 0 0-6.31 12.91L207.64 184Z" /></Svg>;
export default TreeEvergreen;