/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsSplit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-split-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-split-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m229.66 189.66-32 32a8 8 0 0 1-11.32 0l-32-32A8 8 0 0 1 160 176h24v-36.69l-56-56-56 56V176h24a8 8 0 0 1 5.66 13.66l-32 32a8 8 0 0 1-11.32 0l-32-32A8 8 0 0 1 32 176h24v-40a8 8 0 0 1 2.34-5.66L120 68.69V24a8 8 0 0 1 16 0v44.69l61.66 61.65A8 8 0 0 1 200 136v40h24a8 8 0 0 1 5.66 13.66Z" /></Svg>;
export default ArrowsSplit;