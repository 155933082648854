/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GearFine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gear-fine-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gear-fine-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 122h-18.23a85.56 85.56 0 0 0-8.49-31.69l15.79-9.11a6 6 0 0 0-6-10.4l-15.8 9.12a86.72 86.72 0 0 0-23.19-23.19l9.12-15.8a6 6 0 0 0-10.4-6l-9.11 15.79A85.56 85.56 0 0 0 134 42.23V24a6 6 0 0 0-12 0v18.23a85.56 85.56 0 0 0-31.69 8.49L81.2 34.93a6 6 0 0 0-10.4 6l9.12 15.8a86.72 86.72 0 0 0-23.19 23.19l-15.8-9.12a6 6 0 0 0-6 10.4l15.79 9.11A85.56 85.56 0 0 0 42.23 122H24a6 6 0 0 0 0 12h18.23a85.56 85.56 0 0 0 8.49 31.69l-15.79 9.11a6 6 0 0 0 6 10.4l15.8-9.12a86.72 86.72 0 0 0 23.19 23.19l-9.12 15.8a6 6 0 0 0 10.4 6l9.11-15.79a85.56 85.56 0 0 0 31.69 8.49V232a6 6 0 0 0 12 0v-18.23a85.56 85.56 0 0 0 31.69-8.49l9.11 15.79a6 6 0 0 0 5.21 3 5.93 5.93 0 0 0 3-.81 6 6 0 0 0 2.2-8.19l-9.12-15.8a86.72 86.72 0 0 0 23.19-23.19l15.8 9.12a6 6 0 0 0 6-10.4l-15.79-9.11a85.56 85.56 0 0 0 8.48-31.69H232a6 6 0 0 0 0-12ZM86 188.84a73.9 73.9 0 0 1 0-121.68L121.07 128ZM128 202a73.5 73.5 0 0 1-31.67-7.14L131.46 134h70.28A74.09 74.09 0 0 1 128 202Zm3.46-80L96.33 61.14A73.95 73.95 0 0 1 201.74 122Z" /></Svg>;
export default GearFine;