/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Building = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="building-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-building-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 228h-28V28h12a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8h12v200H24a4 4 0 0 0 0 8h208a4 4 0 0 0 0-8ZM60 28h136v200h-40v-44a4 4 0 0 0-4-4h-48a4 4 0 0 0-4 4v44H60Zm88 200h-40v-40h40ZM92 64a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8H96a4 4 0 0 1-4-4Zm48 0a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8h-16a4 4 0 0 1-4-4Zm-48 40a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8H96a4 4 0 0 1-4-4Zm48 0a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8h-16a4 4 0 0 1-4-4Zm-44 44a4 4 0 0 1 0-8h16a4 4 0 0 1 0 8Zm44-4a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8h-16a4 4 0 0 1-4-4Z" /></Svg>;
export default Building;