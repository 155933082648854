/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Placeholder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="placeholder-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-placeholder-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm2 14v153.52L54.49 46H208a2 2 0 0 1 2 2ZM46 208V54.48L201.51 210H48a2 2 0 0 1-2-2Z" /></Svg>;
export default Placeholder;