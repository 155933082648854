/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Person = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 40a24 24 0 1 1 24 24 24 24 0 0 1-24-24Zm108.49 99.51-45.32-51.38a24 24 0 0 0-18-8.13h-42.34a24 24 0 0 0-18 8.13l-45.32 51.38a12 12 0 0 0 17 17L96 128l-22.87 86.93a12 12 0 0 0 21.75 10.14L128 168l33.12 57.07a12 12 0 0 0 21.75-10.14L160 128l35.51 28.49a12 12 0 0 0 17-17Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M160 40a32 32 0 1 0-32 32 32 32 0 0 0 32-32Zm-32 16a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm90.34 78.05-45.17-51.22a32 32 0 0 0-24-10.83h-42.34a32 32 0 0 0-24 10.83l-45.17 51.22a20 20 0 0 0 28.13 28.43l16.3-13.08-16.54 62.88A20 20 0 0 0 102 228.8l26-44.87 26 44.87a20 20 0 0 0 36.41-16.52l-16.5-62.88 16.3 13.08a20 20 0 0 0 28.13-28.43Zm-11.51 16.77a4 4 0 0 1-5.66 0c-.21-.2-.42-.4-.65-.58L165 121.76a8 8 0 0 0-12.74 8.24l22.88 87a7.72 7.72 0 0 0 .48 1.35 4 4 0 1 1-7.25 3.38 6.25 6.25 0 0 0-.33-.63L134.92 164a8 8 0 0 0-13.84 0L88 221.05a6.25 6.25 0 0 0-.33.63 4 4 0 0 1-2.26 2.07 4 4 0 0 1-5-5.45 7.72 7.72 0 0 0 .48-1.35L103.74 130A8 8 0 0 0 91 121.76l-35.52 28.48c-.23.18-.44.38-.65.58a4 4 0 1 1-5.66-5.65c.12-.12.23-.24.34-.37l45.32-51.39a16 16 0 0 1 12-5.41h42.34a16 16 0 0 1 12 5.41l45.32 51.39c.11.13.22.25.34.37a4 4 0 0 1 0 5.65Z" /></Svg>;
export default Person;