/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WaveSawtooth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wave-sawtooth-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wave-sawtooth-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 64v64H24Zm104 64H128v64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m236.19 134.81-104 64A8 8 0 0 1 120 192V78.32l-91.81 56.49a8 8 0 0 1-8.38-13.62l104-64A8 8 0 0 1 136 64v113.68l91.81-56.49a8 8 0 0 1 8.38 13.62Z" /></Svg>;
export default WaveSawtooth;