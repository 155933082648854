/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Drone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drone-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drone-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M189.66 77.66 160 107.31v41.38l29.66 29.65a8 8 0 0 1-11.32 11.32L148.69 160h-41.38l-29.65 29.66a8 8 0 0 1-11.32-11.32L96 148.69v-41.38L66.34 77.66a8 8 0 0 1 11.32-11.32L107.31 96h41.38l29.65-29.66a8 8 0 0 1 11.32 11.32Zm-46.28-6.12a8 8 0 0 0 10.21-4.87 28 28 0 1 1 35.74 35.74A8 8 0 0 0 192 118a7.86 7.86 0 0 0 2.67-.46 44 44 0 1 0-56.16-56.16 8 8 0 0 0 4.87 10.16Zm51.29 67a8 8 0 0 0-5.34 15.08 28 28 0 1 1-35.74 35.74 8 8 0 0 0-15.08 5.34 44 44 0 1 0 56.16-56.16Zm-82 46a8 8 0 0 0-10.21 4.87 28 28 0 1 1-35.74-35.74 8 8 0 0 0-5.34-15.08 44 44 0 1 0 56.16 56.16 8 8 0 0 0-4.92-10.29Zm-51.29-67A7.86 7.86 0 0 0 64 118a8 8 0 0 0 2.67-15.54 28 28 0 1 1 35.74-35.74 8 8 0 1 0 15.08-5.34 44 44 0 1 0-56.16 56.16Z" /></Svg>;
export default Drone;