/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotesPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-plus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-plus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 48a6 6 0 0 1-6 6h-18v18a6 6 0 0 1-12 0V54h-18a6 6 0 0 1 0-12h18V24a6 6 0 0 1 12 0v18h18a6 6 0 0 1 6 6Zm-16 64v52a34.06 34.06 0 1 1-12-25.89V112a6 6 0 0 1 12 0Zm-12 52a22 22 0 1 0-22 22 22 22 0 0 0 22-22ZM86 108.68V196a34.06 34.06 0 1 1-12-25.89V56a6 6 0 0 1 4.54-5.82l56-14a6 6 0 1 1 2.92 11.64L86 60.68v35.64l72.54-18.14a6 6 0 1 1 2.92 11.64ZM74 196a22 22 0 1 0-22 22 22 22 0 0 0 22-22Z" /></Svg>;
export default MusicNotesPlus;