/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSwim = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-swim-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-swim-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 100a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0-48a20 20 0 1 1-20 20 20 20 0 0 1 20-20Zm43.08 135.84a4 4 0 0 1-.53 5.63C203.56 205.9 188.9 212 173.93 212a54.84 54.84 0 0 1-6.12-.34c-15-1.65-28.6-9-41.72-16.14-25.52-13.86-49.62-27-83.54 1.18a4 4 0 0 1-5.1-6.16c17-14.12 33.62-20.07 50.74-18.19 15 1.65 28.6 9 41.72 16.14 25.52 13.86 49.62 26.94 83.54-1.18a4 4 0 0 1 5.63.53ZM40 84h24.24a91.4 91.4 0 0 1 48.37 13.73l-34.42 34.43c-13.63 1-27 7-40.74 18.38a4 4 0 0 0 5.1 6.16c33.92-28.13 58-15 83.54-1.18 13.12 7.12 26.69 14.49 41.72 16.14a54.84 54.84 0 0 0 6.12.34c15 0 29.63-6.1 44.62-18.53a4 4 0 1 0-5.1-6.16c-8.12 6.73-15.68 11.1-22.83 13.66L135 105.29A99.34 99.34 0 0 0 64.24 76H40a4 4 0 0 0 0 8Zm141.66 79.32c-18.77 3.07-35-5.71-51.75-14.83-12.83-7-26.09-14.15-40.73-16l30.16-30.16a94 94 0 0 1 9.95 8.63Z" /></Svg>;
export default PersonSimpleSwim;