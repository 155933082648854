/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RadioButton = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-button-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-button-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90Zm0-144a54 54 0 1 0 54 54 54.06 54.06 0 0 0-54-54Zm0 96a42 42 0 1 1 42-42 42 42 0 0 1-42 42Z" /></Svg>;
export default RadioButton;