/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Binary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="binary-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-binary-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 24c-23.55 0-40 19.74-40 48s16.45 48 40 48 40-19.74 40-48-16.45-48-40-48Zm0 72c-15.55 0-16-21.54-16-24s.45-24 16-24 16 21.54 16 24-.45 24-16 24Zm53.27-42.63a12 12 0 0 1 5.36-16.1l24-12A12 12 0 0 1 192 36v72a12 12 0 0 1-24 0V55.42l-6.63 3.31a12 12 0 0 1-16.1-5.36ZM164 136c-23.55 0-40 19.74-40 48s16.45 48 40 48 40-19.74 40-48-16.45-48-40-48Zm0 72c-15.55 0-16-21.54-16-24s.45-24 16-24 16 21.54 16 24-.45 24-16 24Zm-60-60v72a12 12 0 0 1-24 0v-52.58l-6.63 3.31a12 12 0 1 1-10.74-21.46l24-12A12 12 0 0 1 104 148Z" /></Svg>;
export default Binary;