/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Beanie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beanie-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beanie-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 163.37V144a94.17 94.17 0 0 0-73.7-91.79 26 26 0 1 0-40.6 0A94.17 94.17 0 0 0 34 144v19.37A14 14 0 0 0 26 176v32a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14v-32a14 14 0 0 0-8-12.63ZM114 36a14 14 0 1 1 14 14 14 14 0 0 1-14-14Zm14 26a82.1 82.1 0 0 1 82 82v18H46v-18a82.1 82.1 0 0 1 82-82Zm-6 112v36H78v-36Zm12 0h44v36h-44Zm-96 34v-32a2 2 0 0 1 2-2h26v36H40a2 2 0 0 1-2-2Zm180 0a2 2 0 0 1-2 2h-26v-36h26a2 2 0 0 1 2 2Z" /></Svg>;
export default Beanie;