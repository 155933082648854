/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172.53 49.06a251.42 251.42 0 0 0-41.09-38 6 6 0 0 0-6.88 0 251.42 251.42 0 0 0-41.09 38C56.34 80.26 42 113.09 42 144a86 86 0 0 0 172 0c0-30.91-14.34-63.74-41.47-94.94ZM128 218a74.09 74.09 0 0 1-74-74c0-59.62 59-108.93 74-120.51 15 11.58 74 60.89 74 120.51a74.09 74.09 0 0 1-74 74Z" /></Svg>;
export default DropSimple;