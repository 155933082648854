/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Beanie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beanie-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beanie-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 162.16V144a96.18 96.18 0 0 0-72.34-93 28 28 0 1 0-47.32 0A96.18 96.18 0 0 0 32 144v18.16A16 16 0 0 0 24 176v32a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16v-32a16 16 0 0 0-8-13.84ZM120 176v32H80v-32Zm16 0h40v32h-40ZM116 36a12 12 0 1 1 12 12 12 12 0 0 1-12-12ZM40 176h24v32H40Zm176 32h-24v-32h24v32Z" /></Svg>;
export default Beanie;