/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pulse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pulse-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pulse-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 128a12 12 0 0 1-12 12h-24.58l-36.69 73.37A12 12 0 0 1 160 220h-.6a12 12 0 0 1-10.61-7.72L95 71.15 66.92 133A12 12 0 0 1 56 140H24a12 12 0 0 1 0-24h24.27l36.81-81a12 12 0 0 1 22.13.7l54.28 142.46 27.78-55.56A12 12 0 0 1 200 116h32a12 12 0 0 1 12 12Z" /></Svg>;
export default Pulse;