/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EyedropperSample = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyedropper-sample-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyedropper-sample-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 67.3a35.79 35.79 0 0 0-11.26-25.66c-14-13.28-36.72-12.78-50.62 1.13L138.8 66.2a24 24 0 0 0-33.14.77l-5 5a16 16 0 0 0 0 22.64l2 2.06-51 51a39.75 39.75 0 0 0-10.53 38l-8 18.41A13.65 13.65 0 0 0 36 219.29a15.9 15.9 0 0 0 17.71 3.36L71.24 215a39.9 39.9 0 0 0 37.05-10.75l51-51 2.06 2.06a16 16 0 0 0 22.62 0l5-5a24 24 0 0 0 .74-33.18l23.75-23.87A35.75 35.75 0 0 0 224 67.3ZM138 152H70.07l44-44 33.94 34Z" /></Svg>;
export default EyedropperSample;