/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Coins = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coins-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coins-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 132c0 19.88-35.82 36-80 36-19.6 0-37.56-3.17-51.47-8.44C146.76 156.85 176 142 176 124V96.72c36.52 3.34 64 17.86 64 35.28Zm-64-48c0-19.88-35.82-36-80-36S16 64.12 16 84s35.82 36 80 36 80-16.12 80-36Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M184 89.57V84c0-25.08-37.83-44-88-44S8 58.92 8 84v40c0 20.89 26.25 37.49 64 42.46V172c0 25.08 37.83 44 88 44s88-18.92 88-44v-40c0-20.7-25.42-37.32-64-42.43ZM232 132c0 13.22-30.79 28-72 28-3.73 0-7.43-.13-11.08-.37C170.49 151.77 184 139 184 124v-18.26c29.87 4.45 48 16.53 48 26.26ZM72 150.25v-23.79A183.74 183.74 0 0 0 96 128a183.74 183.74 0 0 0 24-1.54v23.79A163 163 0 0 1 96 152a163 163 0 0 1-24-1.75Zm96-40.32V124c0 8.39-12.41 17.4-32 22.87V123.5c12.91-3.13 23.84-7.79 32-13.57ZM96 56c41.21 0 72 14.78 72 28s-30.79 28-72 28-72-14.78-72-28 30.79-28 72-28Zm-72 68v-14.07c8.16 5.78 19.09 10.44 32 13.57v23.37C36.41 141.4 24 132.39 24 124Zm64 48v-4.17c2.63.1 5.29.17 8 .17 3.88 0 7.67-.13 11.39-.35a121.92 121.92 0 0 0 12.61 3.76v23.46c-19.59-5.47-32-14.48-32-22.87Zm48 26.25V174.4a179.48 179.48 0 0 0 24 1.6 183.74 183.74 0 0 0 24-1.54v23.79a165.45 165.45 0 0 1-48 0Zm64-3.38V171.5c12.91-3.13 23.84-7.79 32-13.57V172c0 8.39-12.41 17.4-32 22.87Z" /></Svg>;
export default Coins;