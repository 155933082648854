/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boules = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boules-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boules-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m215.52 88.48-127 127a96.47 96.47 0 0 1-48-48l127-127a96.47 96.47 0 0 1 48 48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm56.28 36.41L60.4 184.28a88.33 88.33 0 0 1-10.19-15.18L169.1 50.21a87.8 87.8 0 0 1 15.18 10.2Zm11.31 11.31a87.8 87.8 0 0 1 10.2 15.18L86.9 205.79a87.8 87.8 0 0 1-15.18-10.2ZM128 40a87.81 87.81 0 0 1 25.05 3.64L43.64 153.05A88 88 0 0 1 128 40Zm0 176a87.81 87.81 0 0 1-25-3.64L212.36 103A88 88 0 0 1 128 216Z" /></Svg>;
export default Boules;