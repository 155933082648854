/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-minus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-minus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 56a12 12 0 0 1 12-12h48a12 12 0 0 1 0 24h-48a12 12 0 0 1-12-12Zm65.85 36A108 108 0 1 1 128 20a109.19 109.19 0 0 1 18 1.49 12 12 0 0 1-4 23.67A85 85 0 0 0 128 44a83.94 83.94 0 0 0-65.95 135.94 83.48 83.48 0 0 1 29-23.42 52 52 0 1 1 74 0 83.36 83.36 0 0 1 29 23.42A83.94 83.94 0 0 0 207.22 100a12 12 0 0 1 22.63-8ZM128 148a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0 64a83.53 83.53 0 0 0 48.43-15.43 60 60 0 0 0-96.86 0A83.53 83.53 0 0 0 128 212Z" /></Svg>;
export default UserCircleMinus;