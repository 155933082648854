/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextIndent = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-indent-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-indent-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 128a12 12 0 0 1-12 12h-96a12 12 0 0 1 0-24h96a12 12 0 0 1 12 12ZM120 76h96a12 12 0 0 0 0-24h-96a12 12 0 0 0 0 24Zm96 104H40a12 12 0 0 0 0 24h176a12 12 0 0 0 0-24ZM31.51 144.49a12 12 0 0 0 17 0l40-40a12 12 0 0 0 0-17l-40-40a12 12 0 0 0-17 17L63 96l-31.49 31.51a12 12 0 0 0 0 16.98Z" /></Svg>;
export default TextIndent;