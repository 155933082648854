/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ReplitLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="replit-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-replit-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 88h-64V40a16 16 0 0 0-16-16H72a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h64v48H72a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-48h64a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16Zm-80 128H72v-48h64Zm0-176v48H72V40h64Zm80 112h-64v-48h64Z" /></Svg>;
export default ReplitLogo;