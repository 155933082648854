/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitCommit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-commit-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-commit-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 124h-68.17a52 52 0 0 0-103.66 0H8a4 4 0 0 0 0 8h68.17a52 52 0 0 0 103.66 0H248a4 4 0 0 0 0-8Zm-120 48a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Z" /></Svg>;
export default GitCommit;