/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TruckTrailer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-trailer-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-trailer-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 100.22V96a52.06 52.06 0 0 0-52-52h-8a12 12 0 0 0-12 12v124h-24V72a4 4 0 0 0-4-4H16A12 12 0 0 0 4 80v104a28 28 0 0 0 52 14.41A28 28 0 0 0 107.71 188h88.58a28 28 0 0 0 55.71-4v-48a36.06 36.06 0 0 0-32-35.78ZM12 80a4 4 0 0 1 4-4h100v104h-8.29A28 28 0 0 0 56 169.59a28 28 0 0 0-44-5.16Zm20 124a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm48 0a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm144 0a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm0-48a28 28 0 0 0-27.71 24H156V56a4 4 0 0 1 4-4h8a44.05 44.05 0 0 1 44 44v8a4 4 0 0 0 4 4 28 28 0 0 1 28 28v28.43a27.9 27.9 0 0 0-20-8.43Z" /></Svg>;
export default TruckTrailer;