/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinkBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="link-break-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-link-break-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M201.46 54.54a36.06 36.06 0 0 0-50.92 0l-11.65 12.22a4 4 0 0 1-5.78-5.52L144.82 49a44 44 0 1 1 62.29 62.15l-12.35 11.78a4 4 0 0 1-5.52-5.78l12.28-11.72a36 36 0 0 0-.06-50.89Zm-84.35 134.7-11.65 12.22a36 36 0 0 1-51-50.85l12.28-11.72a4 4 0 0 0-5.52-5.78l-12.33 11.78A44 44 0 1 0 111.18 207l11.71-12.28a4 4 0 1 0-5.78-5.52ZM216 156h-24a4 4 0 0 0 0 8h24a4 4 0 0 0 0-8ZM40 100h24a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8Zm120 88a4 4 0 0 0-4 4v24a4 4 0 0 0 8 0v-24a4 4 0 0 0-4-4ZM96 68a4 4 0 0 0 4-4V40a4 4 0 0 0-8 0v24a4 4 0 0 0 4 4Z" /></Svg>;
export default LinkBreak;