/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-focus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-focus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 40v36a4 4 0 0 1-8 0V44h-32a4 4 0 0 1 0-8h36a4 4 0 0 1 4 4Zm-4 136a4 4 0 0 0-4 4v32h-32a4 4 0 0 0 0 8h36a4 4 0 0 0 4-4v-36a4 4 0 0 0-4-4ZM76 212H44v-32a4 4 0 0 0-8 0v36a4 4 0 0 0 4 4h36a4 4 0 0 0 0-8ZM40 80a4 4 0 0 0 4-4V44h32a4 4 0 0 0 0-8H40a4 4 0 0 0-4 4v36a4 4 0 0 0 4 4Zm136 92a4 4 0 0 1-3.2-1.6 56 56 0 0 0-89.6 0 4 4 0 1 1-6.4-4.8 63.65 63.65 0 0 1 32.5-22.85 36 36 0 1 1 37.4 0 63.65 63.65 0 0 1 32.5 22.85 4 4 0 0 1-3.2 6.4Zm-48-32a28 28 0 1 0-28-28 28 28 0 0 0 28 28Z" /></Svg>;
export default UserFocus;