/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GreaterThan = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="greater-than-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-greater-than-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-20.65 103.26-104 48a8 8 0 0 1-6.7-14.52L164.91 128 76.65 87.26a8 8 0 1 1 6.7-14.52l104 48a8 8 0 0 1 0 14.52Z" /></Svg>;
export default GreaterThan;