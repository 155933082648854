/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SneakerMove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sneaker-move-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sneaker-move-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M70.8 184H32a8 8 0 0 1 0-16h38.8a8 8 0 1 1 0 16Zm32 16H48a8 8 0 0 0 0 16h54.8a8 8 0 1 0 0-16Zm128.36-33.37-28.63-14.31A47.74 47.74 0 0 1 176 109.39V80a8 8 0 0 0-7.93-8A48.05 48.05 0 0 1 120 24.07a8 8 0 0 0-12.83-6.44L45.11 64.68a4 4 0 0 0-.41 6l51.44 51.44a8.19 8.19 0 0 1 .6 11.09 8 8 0 0 1-11.71.43l-53-53a4 4 0 0 0-6.44 1.09 16 16 0 0 0 3.12 18.22L142.4 213.66a8 8 0 0 0 5.66 2.34H224a16 16 0 0 0 16-16v-19.06a15.92 15.92 0 0 0-8.84-14.31Z" /></Svg>;
export default SneakerMove;