/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Scroll = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scroll-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scroll-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M98 136a6 6 0 0 1 6-6h64a6 6 0 0 1 0 12h-64a6 6 0 0 1-6-6Zm6-26h64a6 6 0 0 0 0-12h-64a6 6 0 0 0 0 12Zm126 82a30 30 0 0 1-30 30H88a30 30 0 0 1-30-30V64a18 18 0 0 0-36 0c0 6.76 5.58 11.19 5.64 11.23a6 6 0 1 1-7.24 9.57C20 84.48 10 76.85 10 64a30 30 0 0 1 30-30h136a30 30 0 0 1 30 30v106h10a6 6 0 0 1 3.6 1.2c.4.32 10.4 7.95 10.4 20.8Zm-124 0c0-6.76-5.59-11.19-5.64-11.23A6 6 0 0 1 104 170h90V64a18 18 0 0 0-18-18H64a29.82 29.82 0 0 1 6 18v128a18 18 0 0 0 36 0Zm112 0a14.94 14.94 0 0 0-4.34-10h-97.78a24.83 24.83 0 0 1 2.12 10 29.87 29.87 0 0 1-6 18h88a18 18 0 0 0 18-18Z" /></Svg>;
export default Scroll;