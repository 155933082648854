/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SoundcloudLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="soundcloud-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-soundcloud-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M24 120v48a8 8 0 0 1-16 0v-48a8 8 0 0 1 16 0Zm24-32a8 8 0 0 0-8 8v96a8 8 0 0 0 16 0V96a8 8 0 0 0-8-8Zm32-8a8 8 0 0 0-8 8v104a8 8 0 0 0 16 0V88a8 8 0 0 0-8-8Zm32-32a8 8 0 0 0-8 8v136a8 8 0 0 0 16 0V56a8 8 0 0 0-8-8Zm110.84 58.34A80 80 0 0 0 144 40h-4a4 4 0 0 0-4 4v152a4 4 0 0 0 4 4h67.21c25.58 0 47.27-19.72 48.71-45.26a48.06 48.06 0 0 0-33.08-48.4Z" /></Svg>;
export default SoundcloudLogo;