/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boot-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boot-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 104h-28V56a20 20 0 0 0-20-20H32a12 12 0 0 0-11.2 16.3c10.88 28.39 8.3 78.36-.33 108.38A12 12 0 0 0 20 164v36a20 20 0 0 0 20 20h26.11a20.16 20.16 0 0 0 9-2.11L86.83 212h14.34l11.77 5.89a20.16 20.16 0 0 0 9 2.11h28.22a20.16 20.16 0 0 0 8.95-2.11l11.72-5.89h14.34l11.77 5.89a20.16 20.16 0 0 0 8.95 2.11H232a20 20 0 0 0 20-20v-36a60.07 60.07 0 0 0-60-60ZM48 60h92v44h-24a12 12 0 0 0 0 24h76a36.07 36.07 0 0 1 33.94 24H47.21c5.54-27.92 7.02-63.59.79-92Zm180 136h-21.17l-11.77-5.89a20.16 20.16 0 0 0-8.95-2.11h-16.22a20.16 20.16 0 0 0-8.95 2.11L149.17 196h-26.34l-11.77-5.89a20.16 20.16 0 0 0-9-2.11H85.89a20.16 20.16 0 0 0-9 2.11L65.17 196H44v-20h184Z" /></Svg>;
export default Boot;