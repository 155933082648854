/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCirclePlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-plus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-plus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 56a12 12 0 0 1 12-12h12V32a12 12 0 0 1 24 0v12h12a12 12 0 0 1 0 24h-12v12a12 12 0 0 1-24 0V68h-12a12 12 0 0 1-12-12Zm70.51 54A107.88 107.88 0 1 1 146 21.49a12 12 0 0 1-4 23.67A85 85 0 0 0 128 44a83.94 83.94 0 0 0-65.95 135.94 83.48 83.48 0 0 1 29-23.42 52 52 0 1 1 74 0 83.36 83.36 0 0 1 29 23.42A83.52 83.52 0 0 0 212 128a85.2 85.2 0 0 0-1.16-14 12 12 0 0 1 23.67-4ZM128 148a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0 64a83.53 83.53 0 0 0 48.43-15.43 60 60 0 0 0-96.86 0A83.53 83.53 0 0 0 128 212Z" /></Svg>;
export default UserCirclePlus;