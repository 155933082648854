/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sphere = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sphere-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sphere-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 16c8.15 0 24 31.06 24 88 0 8.24-.34 15.92-.93 23.07-7.15.59-14.83.93-23.07.93-56.94 0-88-15.85-88-24a88.1 88.1 0 0 1 88-88ZM43.4 152.26C63.28 162.65 95.76 168 128 168c7.09 0 14.19-.26 21.17-.77C144.23 199 134 216 128 216a88.17 88.17 0 0 1-84.6-63.74Z" /></Svg>;
export default Sphere;