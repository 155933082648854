/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyAngry = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-angry-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-angry-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 156a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm72-32a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm72 4A108 108 0 1 1 128 20a108.12 108.12 0 0 1 108 108Zm-24 0a84 84 0 1 0-84 84 84.09 84.09 0 0 0 84-84ZM85.34 102l36 24a12 12 0 0 0 13.32 0l36-24a12 12 0 0 0-13.32-20L128 101.58 98.66 82a12 12 0 0 0-13.32 20ZM154 167.12a51.1 51.1 0 0 0-52 0 12 12 0 1 0 12 20.76 27.13 27.13 0 0 1 28 0 12 12 0 1 0 12-20.76Z" /></Svg>;
export default SmileyAngry;