/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlyingSaucer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flying-saucer-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flying-saucer-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M181.69 214.1a6 6 0 1 1-11.38 3.8l-8-24a6 6 0 1 1 11.38-3.8ZM128 186a6 6 0 0 0-6 6v32a6 6 0 0 0 12 0v-32a6 6 0 0 0-6-6Zm-38.1.31a6 6 0 0 0-7.59 3.79l-8 24a6 6 0 1 0 11.38 3.8l8-24a6 6 0 0 0-3.79-7.59ZM246 112c0 15.37-12.94 29.39-36.44 39.46C187.67 160.84 158.71 166 128 166s-59.67-5.16-81.56-14.54C22.94 141.39 10 127.37 10 112c0-21.16 25.06-39.65 65.59-48.57a61.85 61.85 0 0 1 105.24.09C221.1 72.48 246 90.93 246 112ZM78 96.84v3a9.93 9.93 0 0 0 7.69 9.7A190.35 190.35 0 0 0 128 114a190.35 190.35 0 0 0 42.3-4.42 9.93 9.93 0 0 0 7.69-9.7V96a50 50 0 0 0-50-50h-.67C100.13 46.36 78 69.16 78 96.84ZM234 112c0-13.33-18.38-26.61-46.8-34.52A62.24 62.24 0 0 1 190 96v3.92a21.86 21.86 0 0 1-17 21.35 202 202 0 0 1-45 4.73 201.89 201.89 0 0 1-45-4.73 21.86 21.86 0 0 1-17-21.35v-3.07a63 63 0 0 1 3.08-19.45C40.49 85.3 22 98.62 22 112c0 10.12 10.63 20.48 29.17 28.43C71.59 149.18 98.88 154 128 154s56.41-4.82 76.83-13.57C223.37 132.48 234 122.12 234 112Z" /></Svg>;
export default FlyingSaucer;