/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudArrowUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-arrow-up-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-arrow-up-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M186.83 162.83a4 4 0 0 1-5.66 0L156 137.66V208a4 4 0 0 1-8 0v-70.34l-25.17 25.17a4 4 0 0 1-5.66-5.66l32-32a4 4 0 0 1 5.66 0l32 32a4 4 0 0 1 0 5.66ZM160 44a84.09 84.09 0 0 0-76.39 49.13A60 60 0 1 0 72 212h40a4 4 0 0 0 0-8H72a52 52 0 1 1 8.57-103.27A83.45 83.45 0 0 0 76 128a4 4 0 0 0 8 0 76 76 0 1 1 106.4 69.68 4 4 0 0 0 1.6 7.66 3.92 3.92 0 0 0 1.6-.33A84 84 0 0 0 160 44Z" /></Svg>;
export default CloudArrowUp;