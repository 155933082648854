/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GreaterThan = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="greater-than-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-greater-than-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 128a12 12 0 0 1-6.86 10.84l-152 72a12 12 0 0 1-10.27-21.69L188 128 58.87 66.85a12 12 0 0 1 10.27-21.69l152 72A12 12 0 0 1 228 128Z" /></Svg>;
export default GreaterThan;