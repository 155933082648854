/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WaveSawtooth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wave-sawtooth-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wave-sawtooth-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m234.1 131.41-104 64a4 4 0 0 1-2.1.59 4 4 0 0 1-4-4V71.16l-97.9 60.25a4 4 0 0 1-4.2-6.82l104-64A4 4 0 0 1 132 64v120.84l97.9-60.25a4 4 0 1 1 4.2 6.82Z" /></Svg>;
export default WaveSawtooth;