/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 16h-32a64.07 64.07 0 0 0-64 64v96a64.07 64.07 0 0 0 64 64h32a64.07 64.07 0 0 0 64-64V80a64.07 64.07 0 0 0-64-64Zm-8 96a8 8 0 0 1-16 0V64a8 8 0 0 1 16 0Z" /></Svg>;
export default MouseSimple;