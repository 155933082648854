/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudLightning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-lightning-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-lightning-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 18a74.21 74.21 0 0 0-69.89 49.3 6.18 6.18 0 0 0-1.83-.62A50.83 50.83 0 0 0 76 66a50 50 0 0 0 0 100h41.4l-18.55 30.91A6 6 0 0 0 104 206h29.4l-18.55 30.91a6 6 0 0 0 10.3 6.18l24-40A6 6 0 0 0 144 194h-29.4l16.8-28H156a74 74 0 0 0 0-148Zm0 136H76a38 38 0 1 1 6.31-75.48 6.82 6.82 0 0 0 .79.08 72.86 72.86 0 0 0-1.1 9.05 6 6 0 0 0 12 .7A62.06 62.06 0 1 1 156 154Z" /></Svg>;
export default CloudLightning;