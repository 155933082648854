/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-heart-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-heart-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M36 64a4 4 0 0 1 4-4h176a4 4 0 0 1 0 8H40a4 4 0 0 1-4-4Zm4 68h64a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8Zm80 56H40a4 4 0 0 0 0 8h80a4 4 0 0 0 0-8Zm124-44c0 14.51-8.56 29-25.44 43.07a143.52 143.52 0 0 1-24.77 16.51 4 4 0 0 1-3.58 0 143.52 143.52 0 0 1-24.77-16.51C148.56 173 140 158.51 140 144a28 28 0 0 1 52-14.41A28 28 0 0 1 244 144Zm-8 0a20 20 0 0 0-40 0 4 4 0 0 1-8 0 20 20 0 0 0-40 0c0 25.8 35.54 46.83 44 51.47 8.46-4.64 44-25.67 44-51.47Z" /></Svg>;
export default ListHeart;