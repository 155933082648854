/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Flower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flower__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flower'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M210.35 129.36c-.81-.47-1.7-.92-2.62-1.36.92-.44 1.81-.89 2.62-1.36a40 40 0 1 0-40-69.28c-.81.47-1.65 1-2.48 1.59.08-1 .13-2 .13-3a40 40 0 0 0-80 0c0 .94 0 1.94.13 3-.83-.57-1.67-1.12-2.48-1.59a40 40 0 1 0-40 69.28c.81.47 1.7.92 2.62 1.36-.92.44-1.81.89-2.62 1.36a40 40 0 1 0 40 69.28c.81-.47 1.65-1 2.48-1.59-.08 1-.13 2-.13 2.95a40 40 0 0 0 80 0c0-.94-.05-1.94-.13-2.95.83.57 1.67 1.12 2.48 1.59a39.79 39.79 0 0 0 19.94 5.36 40.43 40.43 0 0 0 10.42-1.38 40 40 0 0 0 9.64-73.28ZM104 128a24 24 0 1 1 24 24 24 24 0 0 1-24-24Zm74.35-56.79a24 24 0 1 1 24 41.57c-6.27 3.63-18.61 6.13-35.16 7.19a40 40 0 0 0-12.66-21.87c9.2-13.82 17.55-23.26 23.82-26.89ZM128 32a24 24 0 0 1 24 24c0 7.24-4 19.19-11.36 34.06a39.81 39.81 0 0 0-25.28 0C108 75.19 104 63.24 104 56a24 24 0 0 1 24-24ZM44.86 80a24 24 0 0 1 32.79-8.79c6.27 3.63 14.62 13.07 23.82 26.89A40 40 0 0 0 88.81 120c-16.55-1.06-28.89-3.56-35.16-7.18A24 24 0 0 1 44.86 80Zm32.79 104.79a24 24 0 1 1-24-41.57c6.27-3.63 18.61-6.13 35.16-7.19a40 40 0 0 0 12.66 21.87c-9.2 13.82-17.55 23.26-23.82 26.89ZM128 224a24 24 0 0 1-24-24c0-7.24 4-19.19 11.36-34.06a39.81 39.81 0 0 0 25.28 0C148 180.81 152 192.76 152 200a24 24 0 0 1-24 24Zm83.14-48a24 24 0 0 1-32.79 8.79c-6.27-3.63-14.62-13.07-23.82-26.89a40 40 0 0 0 12.66-21.9c16.55 1.06 28.89 3.56 35.16 7.18a24 24 0 0 1 8.79 32.82Z" /></Svg>;
export default Flower;