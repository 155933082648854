/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-heart__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-heart'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 64a8 8 0 0 1 8-8h176a8 8 0 0 1 0 16H40a8 8 0 0 1-8-8Zm8 72h64a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16Zm80 48H40a8 8 0 0 0 0 16h80a8 8 0 0 0 0-16Zm128-40c0 36.52-50.28 62.08-52.42 63.16a8 8 0 0 1-7.16 0C186.28 206.08 136 180.52 136 144a32 32 0 0 1 56-21.14A32 32 0 0 1 248 144Zm-16 0a16 16 0 0 0-32 0 8 8 0 0 1-16 0 16 16 0 0 0-32 0c0 20.18 26.21 39.14 40 46.93 13.79-7.78 40-26.74 40-46.93Z" /></Svg>;
export default ListHeart;