/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dresser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dresser-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dresser-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M142 192a6 6 0 0 1-6 6h-16a6 6 0 0 1 0-12h16a6 6 0 0 1 6 6ZM120 70h16a6 6 0 0 0 0-12h-16a6 6 0 0 0 0 12Zm16 52h-16a6 6 0 0 0 0 12h16a6 6 0 0 0 0-12Zm78-82v176a14 14 0 0 1-14 14H56a14 14 0 0 1-14-14V40a14 14 0 0 1 14-14h144a14 14 0 0 1 14 14ZM54 154h148v-52H54Zm0-114v50h148V40a2 2 0 0 0-2-2H56a2 2 0 0 0-2 2Zm148 176v-50H54v50a2 2 0 0 0 2 2h144a2 2 0 0 0 2-2Z" /></Svg>;
export default Dresser;