/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lightning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightning-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightning-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.89 119.09a4 4 0 0 0-2.49-2.84l-60.81-22.8 15.33-76.67a4 4 0 0 0-6.84-3.51l-112 120a4 4 0 0 0-1 3.64 4 4 0 0 0 2.49 2.84l60.81 22.8-15.3 76.67a4 4 0 0 0 6.84 3.51l112-120a4 4 0 0 0 .97-3.64ZM102.68 227l13.24-66.2a4 4 0 0 0-2.52-4.53L55 134.36 153.32 29l-13.24 66.2a4 4 0 0 0 2.52 4.53l58.4 21.91Z" /></Svg>;
export default Lightning;