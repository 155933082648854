/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyBtc = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-btc-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-btc-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174.69 116.41A42 42 0 0 0 150 42.05V24a6 6 0 0 0-12 0v18h-20V24a6 6 0 0 0-12 0v18H72a6 6 0 0 0 0 12h10v140H72a6 6 0 0 0 0 12h34v18a6 6 0 0 0 12 0v-18h20v18a6 6 0 0 0 12 0v-18h10a46 46 0 0 0 14.69-89.59ZM178 84a30 30 0 0 1-30 30H94V54h54a30 30 0 0 1 30 30Zm-18 110H94v-68h66a34 34 0 0 1 0 68Z" /></Svg>;
export default CurrencyBtc;