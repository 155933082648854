/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrademarkRegistered = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trademark-registered-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trademark-registered-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 112a16 16 0 0 1-16 16h-24V96h24a16 16 0 0 1 16 16Zm80 16A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88 88.1 88.1 0 0 0 88-88Zm-16 0a72 72 0 1 1-72-72 72.08 72.08 0 0 1 72 72Zm-33.34 35.56-15.57-23.35A32 32 0 0 0 136 80h-32a8 8 0 0 0-8 8v80a8 8 0 0 0 16 0v-24h22.39l19 28.44a8 8 0 0 0 13.32-8.88Z" /></Svg>;
export default TrademarkRegistered;