/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyEth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-eth-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-eth-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m222.29 123.06-88-112a8 8 0 0 0-12.58 0l-88 112a8 8 0 0 0 0 9.88l88 112a8 8 0 0 0 12.58 0l88-112a8 8 0 0 0 0-9.88ZM136 155.58V39.13l67.42 85.8Zm-16 0-67.42-30.65L120 39.13Zm0 17.57v43.72l-53.43-68Z" /></Svg>;
export default CurrencyEth;