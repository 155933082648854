/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FacebookLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="facebook-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-facebook-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128a104.16 104.16 0 0 1-91.55 103.26 4 4 0 0 1-4.45-4V152h24a8 8 0 0 0 8-8.53 8.17 8.17 0 0 0-8.25-7.47H136v-24a16 16 0 0 1 16-16h16a8 8 0 0 0 8-8.53 8.17 8.17 0 0 0-8.27-7.47H152a32 32 0 0 0-32 32v24H96a8 8 0 0 0-8 8.53 8.17 8.17 0 0 0 8.27 7.47H120v75.28a4 4 0 0 1-4.44 4 104.15 104.15 0 0 1-91.49-107.19c2-54 45.74-97.9 99.78-100A104.12 104.12 0 0 1 232 128Z" /></Svg>;
export default FacebookLogo;