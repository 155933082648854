/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSnowboard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-snowboard-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-snowboard-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 80a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm0-40a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm51.38 68.48-136-40a12 12 0 0 0-6.77 23L112.25 102l-28.64 43L44 133.27a30.79 30.79 0 0 0-27.38 4.94A31.18 31.18 0 0 0 4 163.39a31.53 31.53 0 0 0 22.43 30.11L180 238.71a31.23 31.23 0 0 0 40-30.11 31.52 31.52 0 0 0-22.44-30.11l-39.74-11.7L170 148.68a12 12 0 0 0-6.62-18.21l-34.56-10 7.58-11.38 76.25 22.43a12 12 0 1 0 6.77-23ZM196 208.6a7.36 7.36 0 0 1-3 6 6.92 6.92 0 0 1-6.23 1.13L33.21 170.48A7.46 7.46 0 0 1 31 157.42a7 7 0 0 1 6.23-1.13l153.59 45.22a7.39 7.39 0 0 1 5.18 7.09ZM140.88 149l-7.2 10.73-26-7.64 7.1-10.64Z" /></Svg>;
export default PersonSimpleSnowboard;