/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Truck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m255.14 115.54-14-35A19.89 19.89 0 0 0 222.58 68H196v-4a12 12 0 0 0-12-12H32a20 20 0 0 0-20 20v112a20 20 0 0 0 20 20h14.06a36 36 0 0 0 67.88 0h44.12a36 36 0 0 0 67.88 0H236a20 20 0 0 0 20-20v-64a21.7 21.7 0 0 0-.86-4.46ZM196 92h23.88l6.4 16H196ZM80 204a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm92-41.92A36.32 36.32 0 0 0 158.06 180h-44.12a36 36 0 0 0-67.88 0H36v-40h136Zm0-46.08H36V76h136Zm20 88a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm40-24h-6.06A36.09 36.09 0 0 0 196 156.23V132h36Z" /></Svg>;
export default Truck;