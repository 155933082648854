/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SneakerMove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sneaker-move-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sneaker-move-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m230.26 168.42-28.62-14.31A49.72 49.72 0 0 1 174 109.39V80a6 6 0 0 0-6-6 50.06 50.06 0 0 1-50-50 6 6 0 0 0-9.62-4.78l-77 58.41-.15.11a14 14 0 0 0-1.13 20.79l113.72 113.71a6 6 0 0 0 4.24 1.76H224a14 14 0 0 0 14-14v-19.06a13.94 13.94 0 0 0-7.74-12.52ZM226 200a2 2 0 0 1-2 2h-73.46L38.59 90a2 2 0 0 1-.59-1.48 2 2 0 0 1 .69-1.41l14.36-10.89 40 40a6 6 0 0 0 8.49-8.48L62.71 68.91 107 35.3a62.13 62.13 0 0 0 55 50.41v23.68a61.65 61.65 0 0 0 34.27 55.45l28.62 14.32a2 2 0 0 1 1.11 1.78ZM70.8 182H32a6 6 0 0 1 0-12h38.8a6 6 0 1 1 0 12Zm38 26a6 6 0 0 1-6 6H48a6 6 0 0 1 0-12h54.8a6 6 0 0 1 6 6Z" /></Svg>;
export default SneakerMove;