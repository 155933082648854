/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sparkle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sparkle-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sparkle-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m196.2 132.81-53.36-19.65-19.65-53.36a11.93 11.93 0 0 0-22.38 0l-19.65 53.36-53.36 19.65a11.93 11.93 0 0 0 0 22.38l53.36 19.65 19.65 53.36a11.93 11.93 0 0 0 22.38 0l19.65-53.36 53.36-19.65a11.93 11.93 0 0 0 0-22.38Zm-2.77 14.87L138.35 168a4 4 0 0 0-2.37 2.37l-20.3 55.08a3.92 3.92 0 0 1-7.36 0L88 170.35a4 4 0 0 0-2.35-2.35l-55.08-20.3a3.92 3.92 0 0 1 0-7.36L85.65 120a4 4 0 0 0 2.35-2.35l20.3-55.08a3.92 3.92 0 0 1 7.36 0L136 117.65a4 4 0 0 0 2.37 2.37l55.08 20.3a3.92 3.92 0 0 1 0 7.36ZM148 40a4 4 0 0 1 4-4h20V16a4 4 0 0 1 8 0v20h20a4 4 0 0 1 0 8h-20v20a4 4 0 0 1-8 0V44h-20a4 4 0 0 1-4-4Zm96 48a4 4 0 0 1-4 4h-12v12a4 4 0 0 1-8 0V92h-12a4 4 0 0 1 0-8h12V72a4 4 0 0 1 8 0v12h12a4 4 0 0 1 4 4Z" /></Svg>;
export default Sparkle;