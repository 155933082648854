/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CrownSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 75.4a13.87 13.87 0 0 0-16.52 3.34l-35.74 38.52-37.03-83.13a14 14 0 0 0-25.45.07l-37 83.06-35.78-38.52a14 14 0 0 0-24.26 11.72c0 .08 0 .16.05.24L41 194.57A14 14 0 0 0 54.71 206h146.58a14 14 0 0 0 13.76-11.43L237.73 90.7c0-.08 0-.16.05-.24A13.89 13.89 0 0 0 230 75.4Zm-4 12.89-22.69 103.82a2 2 0 0 0-.05.24 2 2 0 0 1-2 1.65H54.71a2 2 0 0 1-2-1.65 2 2 0 0 0-.05-.24L30 88.29a1.82 1.82 0 0 1 1.12-2.06 1.84 1.84 0 0 1 2.36.48l.12.13 42 45.24a6 6 0 0 0 9.88-1.64l40.7-91.28A1.92 1.92 0 0 1 128 38a1.87 1.87 0 0 1 1.78 1.09l40.74 91.35a6 6 0 0 0 9.88 1.64l42-45.24.12-.13a1.84 1.84 0 0 1 2.36-.48 1.82 1.82 0 0 1 1.12 2.06Z" /></Svg>;
export default CrownSimple;