/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const InfinityIcon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="infinity-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-infinity-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 128a52 52 0 0 1-88.77 36.77l-.17-.18L95 96.8a44 44 0 1 0 0 62.4l8.6-9.72a4 4 0 0 1 6 5.3l-8.68 9.81-.17.18a52 52 0 1 1 0-73.54l.17.18 60 67.79a44 44 0 1 0 0-62.4l-8.6 9.72a4 4 0 0 1-6-5.3l8.68-9.81.17-.18A52 52 0 0 1 244 128Z" /></Svg>;
export default InfinityIcon;