/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pi-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pi-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 200H88V64h80v108a28 28 0 0 0 28 28Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 172a36 36 0 0 1-72 0V72H96v128a8 8 0 0 1-16 0V72h-8a40 40 0 0 0-40 40 8 8 0 0 1-16 0 56.06 56.06 0 0 1 56-56h152a8 8 0 0 1 0 16h-48v100a20 20 0 0 0 40 0 8 8 0 0 1 16 0Z" /></Svg>;
export default Pi;