/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Baby = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M134.16 24.1a4 4 0 0 0-3.56 1.81C120.3 41.48 120 55.79 120 56a8 8 0 0 0 9.68 7.79 8.24 8.24 0 0 0 6.32-8.11 8 8 0 0 1 8.8-7.68 8.14 8.14 0 0 1 7.2 8.23 24 24 0 0 1-48-.27c0-.63.09-10.78 5.44-24a4 4 0 0 0-4.59-5.39 104.16 104.16 0 0 0-80.78 105.09C26 186.72 71.23 231 126.32 231.9a104 104 0 0 0 7.84-207.8ZM80 127.91a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm80.27 54.77a61 61 0 0 1-64.54 0 8 8 0 0 1 8.54-13.54 45 45 0 0 0 47.46 0 8 8 0 0 1 8.54 13.54Zm3.73-42.77a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default Baby;