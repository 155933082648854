/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radio = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 168a8 8 0 0 1-8 8H64a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8Zm-8-40H64a8 8 0 0 0 0 16h32a8 8 0 0 0 0-16Zm0-32H64a8 8 0 0 0 0 16h32a8 8 0 0 0 0-16Zm136-16v112a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16V72a8 8 0 0 1 5.7-7.66l160-48a8 8 0 0 1 4.6 15.33L86.51 64H216a16 16 0 0 1 16 16Zm-16 112V80H40v112h176Zm-16-56a40 40 0 1 1-40-40 40 40 0 0 1 40 40Zm-16 0a24 24 0 1 0-24 24 24 24 0 0 0 24-24Z" /></Svg>;
export default Radio;