/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PauseCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pause-circle__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pause-circle'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88ZM112 96v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Zm48 0v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Z" /></Svg>;
export default PauseCircle;