/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SidebarSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sidebar-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sidebar-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 36H40a20 20 0 0 0-20 20v144a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20ZM44 60h32v136H44Zm168 136H100V60h112Z" /></Svg>;
export default SidebarSimple;