/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SunHorizon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sun-horizon-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sun-horizon-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 160a8 8 0 0 1-8 8H16a8 8 0 0 1 0-16h40.45a73.54 73.54 0 0 1-.45-8 72 72 0 0 1 144 0 73.54 73.54 0 0 1-.45 8H240a8 8 0 0 1 8 8Zm-40 32H48a8 8 0 0 0 0 16h160a8 8 0 0 0 0-16ZM80.84 59.58a8 8 0 0 0 14.32-7.16l-8-16a8 8 0 0 0-14.32 7.16Zm-60.42 43.58 16 8a8 8 0 1 0 7.16-14.31l-16-8a8 8 0 1 0-7.16 14.31ZM216 112a8 8 0 0 0 3.57-.84l16-8a8 8 0 1 0-7.16-14.31l-16 8A8 8 0 0 0 216 112Zm-51.58-48.84a8 8 0 0 0 10.74-3.58l8-16a8 8 0 0 0-14.32-7.16l-8 16a8 8 0 0 0 3.58 10.74Z" /></Svg>;
export default SunHorizon;