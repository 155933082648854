/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPinSimpleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-simple-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-simple-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M79.25 38.69a4 4 0 0 1 3-6.69H192a8 8 0 0 1 8 8.53 8.17 8.17 0 0 1-8.27 7.47h-6.19l21.16 119.91a4 4 0 0 1-6.9 3.39Zm134.67 171.93-160-176a8 8 0 1 0-11.84 10.76L66.24 72l-16.95 96H40a8 8 0 0 0 0 16h80v56a8 8 0 0 0 16 0v-56h32.1l34 37.38a8 8 0 1 0 11.84-10.76Z" /></Svg>;
export default PushPinSimpleSlash;