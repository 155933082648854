/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CookingPot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cooking-pot-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cooking-pot-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 48V16a4 4 0 0 1 8 0v32a4 4 0 0 1-8 0Zm36 4a4 4 0 0 0 4-4V16a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4Zm32 0a4 4 0 0 0 4-4V16a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4Zm90.4 47.2L220 122v62a28 28 0 0 1-28 28H64a28 28 0 0 1-28-28v-62L5.6 99.2a4 4 0 0 1 4.8-6.4L36 112V80a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4v32l25.6-19.2a4 4 0 1 1 4.8 6.4ZM212 84H44v100a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20Z" /></Svg>;
export default CookingPot;