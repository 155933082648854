/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radioactive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radioactive-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radioactive-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 128a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm-15.78 3.51A29 29 0 0 1 100 128a28 28 0 0 1 16.94-25.73 4 4 0 0 0 1.87-5.66L90.75 48a16 16 0 0 0-23.1-5.07 103.83 103.83 0 0 0-43.58 75.49 16.21 16.21 0 0 0 4.17 12.37A16 16 0 0 0 40 136h56.26a4 4 0 0 0 3.96-4.49Zm131.71-13.09a103.83 103.83 0 0 0-43.58-75.49 16 16 0 0 0-23.1 5.07l-28.06 48.61a4 4 0 0 0 1.87 5.66A28 28 0 0 1 156 128a29 29 0 0 1-.22 3.51 4 4 0 0 0 4 4.49H216a16 16 0 0 0 11.76-5.21 16.21 16.21 0 0 0 4.17-12.37Zm-81.13 33.06a4 4 0 0 0-5.91-1.15 28 28 0 0 1-33.78 0 4 4 0 0 0-5.91 1.15l-27.95 48.43a16 16 0 0 0 7.12 22.52 104.24 104.24 0 0 0 87.26 0 16 16 0 0 0 7.12-22.52Z" /></Svg>;
export default Radioactive;