/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareEight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-eight-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-eight-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12Zm4 172a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4Zm-69.1-84.31a28 28 0 1 0-29.8 0 32 32 0 1 0 29.8 0ZM108 100a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm20 76a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default NumberSquareEight;