/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Percent = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="percent__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-percent'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m205.66 61.64-144 144a8 8 0 0 1-11.32-11.32l144-144a8 8 0 0 1 11.32 11.31Zm-155.12 39.8a36 36 0 0 1 50.92-50.91 36 36 0 0 1-50.92 50.91ZM56 76a20 20 0 1 0 34.14-14.16A20 20 0 0 0 56 76Zm160 104a36 36 0 1 1-10.54-25.46A35.76 35.76 0 0 1 216 180Zm-16 0a20 20 0 1 0-5.86 14.14A19.87 19.87 0 0 0 200 180Z" /></Svg>;
export default Percent;