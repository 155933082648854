/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyRub = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-rub-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-rub-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 104a24 24 0 0 1-24 24h-32V80h32a24 24 0 0 1 24 24Zm64 24A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-48-24a40 40 0 0 0-40-40h-40a8 8 0 0 0-8 8v56h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16a8 8 0 0 0 16 0v-16h40a8 8 0 0 0 0-16h-40v-16h32a40 40 0 0 0 40-40Z" /></Svg>;
export default CurrencyRub;