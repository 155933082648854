/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCss = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-css-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-css-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 180c0 8.67 5.5 16 12 16a10.27 10.27 0 0 0 7.33-3.43 12 12 0 1 1 17.34 16.6A34 34 0 0 1 60 220c-19.85 0-36-18-36-40s16.15-40 36-40a34 34 0 0 1 24.67 10.83 12 12 0 1 1-17.34 16.6A10.27 10.27 0 0 0 60 164c-6.5 0-12 7.31-12 16Zm97.51-5.71c-5.12-3.45-11.32-5.24-16.8-6.82a79.5 79.5 0 0 1-7.91-2.59c2.45-1.18 9.71-1.3 16.07.33A12 12 0 0 0 143 142a69 69 0 0 0-12-1.86c-9.93-.66-18 1.08-24.1 5.17a24.45 24.45 0 0 0-10.69 17.76c-1.1 8.74 2.49 16.27 10.11 21.19 4.78 3.09 10.36 4.7 15.75 6.26 3 .89 7.94 2.3 9.88 3.53a2.48 2.48 0 0 1-.21.71c-1.37 1.55-9.58 1.79-16.39-.06a12 12 0 1 0-6.46 23.11A63.75 63.75 0 0 0 125.1 220c6.46 0 13.73-1.17 19.73-5.15a24.73 24.73 0 0 0 10.95-18c1.22-9.32-2.45-17.32-10.27-22.58Zm68 0c-5.12-3.45-11.32-5.24-16.8-6.82a79.5 79.5 0 0 1-7.91-2.59c2.45-1.18 9.71-1.3 16.07.33A12 12 0 0 0 211 142a69 69 0 0 0-12-1.86c-9.93-.66-18 1.08-24.1 5.17a24.45 24.45 0 0 0-10.69 17.76c-1.1 8.74 2.49 16.27 10.11 21.19 4.78 3.09 10.36 4.7 15.75 6.26 3 .89 7.94 2.3 9.88 3.53a2.48 2.48 0 0 1-.21.71c-1.37 1.55-9.58 1.79-16.39-.06a12 12 0 1 0-6.46 23.11A63.75 63.75 0 0 0 193.1 220c6.46 0 13.73-1.17 19.73-5.15a24.73 24.73 0 0 0 10.95-18c1.22-9.32-2.45-17.32-10.27-22.58ZM36 108V40a20 20 0 0 1 20-20h96a12 12 0 0 1 8.49 3.51l56 56A12 12 0 0 1 220 88v20a12 12 0 1 1-24 0v-4h-48a12 12 0 0 1-12-12V44H60v64a12 12 0 1 1-24 0Zm124-28h23l-23-23Z" /></Svg>;
export default FileCss;