/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Divide = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="divide-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-divide-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 128a6 6 0 0 1-6 6H40a6 6 0 0 1 0-12h176a6 6 0 0 1 6 6Zm-94-50a14 14 0 1 0-14-14 14 14 0 0 0 14 14Zm0 100a14 14 0 1 0 14 14 14 14 0 0 0-14-14Z" /></Svg>;
export default Divide;