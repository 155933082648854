/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Television = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="television-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-television-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 60h-59l27.52-27.52a12 12 0 0 0-17-17L128 55 88.49 15.51a12 12 0 0 0-17 17L99 60H40a20 20 0 0 0-20 20v120a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20V80a20 20 0 0 0-20-20ZM44 84h84v112H44Zm168 112h-60V84h60Zm-44-80a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm32 48a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default Television;