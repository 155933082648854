/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyeglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyeglasses-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyeglasses-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 72v92a44 44 0 0 1-87.81 4h-32.38A44 44 0 0 1 24 164V72a32 32 0 0 1 32-32 8 8 0 0 1 0 16 16 16 0 0 0-16 16v58.08A44 44 0 0 1 110.32 152h35.36A44 44 0 0 1 216 130.08V72a16 16 0 0 0-16-16 8 8 0 0 1 0-16 32 32 0 0 1 32 32Z" /></Svg>;
export default Eyeglasses;