/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stethoscope = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stethoscope-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stethoscope-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 160a32 32 0 1 1-32-32 32 32 0 0 1 32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M220 160a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-4.55 39.29A48.08 48.08 0 0 1 168 240h-24a48.05 48.05 0 0 1-48-48v-40.51A64 64 0 0 1 40 88V40a8 8 0 0 1 8-8h24a8 8 0 0 1 0 16H56v40a48 48 0 0 0 48.64 48c26.11-.34 47.36-22.25 47.36-48.83V48h-16a8 8 0 0 1 0-16h24a8 8 0 0 1 8 8v47.17c0 32.84-24.53 60.29-56 64.31V192a32 32 0 0 0 32 32h24a32.06 32.06 0 0 0 31.22-25 40 40 0 1 1 16.23.27ZM232 160a24 24 0 1 0-24 24 24 24 0 0 0 24-24Z" /></Svg>;
export default Stethoscope;