/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shapes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shapes-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shapes-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M67.79 62.74a4 4 0 0 0-7.58 0l-40 120A4 4 0 0 0 24 188h80a4 4 0 0 0 3.79-5.26ZM29.55 180 64 76.65 98.45 180ZM204 76a48 48 0 1 0-48 48 48.05 48.05 0 0 0 48-48Zm-88 0a40 40 0 1 1 40 40 40 40 0 0 1-40-40Zm108 72h-88a4 4 0 0 0-4 4v56a4 4 0 0 0 4 4h88a4 4 0 0 0 4-4v-56a4 4 0 0 0-4-4Zm-4 56h-80v-48h80Z" /></Svg>;
export default Shapes;