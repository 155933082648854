/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PictureInPicture = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="picture-in-picture-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-picture-in-picture-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 52H40a12 12 0 0 0-12 12v128a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V64a12 12 0 0 0-12-12ZM36 192V64a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4v60h-84a4 4 0 0 0-4 4v68H40a4 4 0 0 1-4-4Zm180 4h-76v-64h80v60a4 4 0 0 1-4 4Z" /></Svg>;
export default PictureInPicture;