/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilePy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-py-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-py-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m210.83 85.17-56-56A4 4 0 0 0 152 28H56a12 12 0 0 0-12 12v72a4 4 0 0 0 8 0V40a4 4 0 0 1 4-4h92v52a4 4 0 0 0 4 4h52v124a4 4 0 0 1-4 4h-32a4 4 0 0 0 0 8h32a12 12 0 0 0 12-12V88a4 4 0 0 0-1.17-2.83ZM156 41.65 198.34 84H156ZM64 148H48a4 4 0 0 0-4 4v56a4 4 0 0 0 8 0v-12h12a24 24 0 0 0 0-48Zm0 40H52v-32h12a16 16 0 0 1 0 32Zm87.39-33.88-19.39 31V208a4 4 0 0 1-8 0v-22.85l-19.39-31a4 4 0 0 1 6.78-4.24L128 176.45l16.61-26.57a4 4 0 1 1 6.78 4.24Z" /></Svg>;
export default FilePy;