/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeHemisphereWest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-hemisphere-west-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-hemisphere-west-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm92 100a91.44 91.44 0 0 1-8.58 38.76l-48.62-29.91a12.07 12.07 0 0 0-4.68-1.67l-22.82-3.07a12 12 0 0 0-12.56 7l-.4.88h-11.2a4 4 0 0 1-3.6-2.26l-3.8-7.86a11.93 11.93 0 0 0-8.28-6.5l-13.39-2.87 10.6-18.5a4 4 0 0 1 3.47-2h16.71a12 12 0 0 0 5.8-1.5l12.24-6.76a11.79 11.79 0 0 0 2.25-1.6l26.91-24.34a12 12 0 0 0 2.43-14.75l-5.86-10.49A92.17 92.17 0 0 1 220 128Zm-74.11-90.25 9.6 17.2a4 4 0 0 1-.81 4.92l-26.91 24.34a4.41 4.41 0 0 1-.75.53l-12.24 6.76a4 4 0 0 1-1.93.5H96.14a12 12 0 0 0-10.41 6l-11.86 20.7a4 4 0 0 1-2.75-2.47L59.63 85.6a4 4 0 0 1 .06-3L71 55.81A91.51 91.51 0 0 1 128 36a92.53 92.53 0 0 1 17.89 1.75ZM36 128a91.52 91.52 0 0 1 20-57.23l-3.71 8.75a12 12 0 0 0-.18 8.88l11.49 30.67a11.93 11.93 0 0 0 8.72 7.52l21.43 4.61a4 4 0 0 1 2.76 2.17l3.8 7.86a12.07 12.07 0 0 0 10.8 6.77h7.64L109 169.85a12 12 0 0 0 2.26 13.15l19.68 20.26a4 4 0 0 1 1 3.47L129.36 220H128a92.1 92.1 0 0 1-92-92Zm101.6 91.5 2.18-11.29a12.08 12.08 0 0 0-3-10.49l-19.68-20.26a4 4 0 0 1-.71-4.35l13.7-30.74a4 4 0 0 1 4.18-2.33l22.82 3.07a4.12 4.12 0 0 1 1.56.56l49.11 30.2a92.12 92.12 0 0 1-70.16 45.63Z" /></Svg>;
export default GlobeHemisphereWest;