/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileIni = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-ini-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-ini-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 152v56a12 12 0 0 1-24 0v-56a12 12 0 0 1 24 0Zm72-12a12 12 0 0 0-12 12v18.55L89.76 145A12 12 0 0 0 68 152v56a12 12 0 0 0 24 0v-18.55L110.24 215a12 12 0 0 0 9.76 5 12.15 12.15 0 0 0 3.66-.57A12 12 0 0 0 132 208v-56a12 12 0 0 0-12-12Zm44 0a12 12 0 0 0-12 12v56a12 12 0 0 0 24 0v-56a12 12 0 0 0-12-12Zm56-52v136a12 12 0 0 1-24 0V104h-48a12 12 0 0 1-12-12V44H60v64a12 12 0 0 1-24 0V40a20 20 0 0 1 20-20h96a12 12 0 0 1 8.49 3.52l56 56A12 12 0 0 1 220 88Zm-60-8h23l-23-23Z" /></Svg>;
export default FileIni;