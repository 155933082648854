/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tire = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tire-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tire-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 172c13 0 20-22.67 20-44s-7-44-20-44-20 22.67-20 44 7 44 20 44Zm0-80c4.89 0 12 14 12 36s-7.11 36-12 36-12-14-12-36 7.11-36 12-36Zm64 128h-45.77c6.72-5.11 12.85-12.59 18.06-22.21C214.42 179.08 220 154.3 220 128s-5.58-51.08-15.71-69.79C193.73 38.73 179.43 28 164 28H92c-15.43 0-29.73 10.73-40.29 30.21C41.58 76.92 36 101.7 36 128s5.58 51.08 15.71 69.79C62.27 217.27 76.57 228 92 228h140a4 4 0 0 0 0-8ZM197.25 62c9.51 17.58 14.75 41 14.75 66s-5.24 48.42-14.75 66c-9.09 16.78-20.9 26-33.25 26s-24.16-9.24-33.25-26c-9.51-17.58-14.75-41-14.75-66s5.24-48.42 14.75-66c9.09-16.78 20.9-26 33.25-26s24.16 9.24 33.25 26Zm-138.5 0C67.84 45.24 79.65 36 92 36h49.77c-6.72 5.11-12.85 12.59-18.06 22.21-7 12.93-11.83 28.77-14.14 46L82.33 84.75a4 4 0 0 0-4.66 0L45.17 108c2.02-17.34 6.67-33.22 13.58-46Zm-14.49 56.44L80 92.92l28.59 20.42c-.39 4.81-.59 9.71-.59 14.66a169.72 169.72 0 0 0 5.32 42.89l-31-22.14a4 4 0 0 0-4.66 0l-28.53 20.38A161.44 161.44 0 0 1 44 128q0-4.81.26-9.56ZM92 220c-12.35 0-24.16-9.24-33.25-26a106.61 106.61 0 0 1-7.19-16.75L80 156.92l37.32 26.65a111.32 111.32 0 0 0 6.39 14.22c5.21 9.62 11.34 17.1 18.06 22.21Z" /></Svg>;
export default Tire;