/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bird = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bird-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bird-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 68a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm64 12a4 4 0 0 1-1.78 3.33L212 98.14V120a100.11 100.11 0 0 1-100 100H24a12 12 0 0 1-9.37-19.5L100 98.08V76.89c0-31.22 25-56.74 55.72-56.89h.28a55.94 55.94 0 0 1 53.79 40.39l24.43 16.28A4 4 0 0 1 236 80Zm-11.21 0-20.68-13.78a4.08 4.08 0 0 1-1.65-2.33A48 48 0 0 0 156 28h-.24C129.43 28.13 108 50.06 108 76.89v22.63a4 4 0 0 1-.93 2.56L20.84 205.56A4 4 0 0 0 24 212h28.79l72.14-86.56a4 4 0 0 1 6.14 5.12L63.21 212H112a92.1 92.1 0 0 0 92-92V96a4 4 0 0 1 1.78-3.33Z" /></Svg>;
export default Bird;