/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rss = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M106.91 149.09A71.53 71.53 0 0 1 128 200a8 8 0 0 1-16 0 56 56 0 0 0-56-56 8 8 0 0 1 0-16 71.53 71.53 0 0 1 50.91 21.09ZM56 80a8 8 0 0 0 0 16 104 104 0 0 1 104 104 8 8 0 0 0 16 0A120 120 0 0 0 56 80Zm118.79 1.21A166.9 166.9 0 0 0 56 32a8 8 0 0 0 0 16 151 151 0 0 1 107.48 44.52A151 151 0 0 1 208 200a8 8 0 0 0 16 0 166.9 166.9 0 0 0-49.21-118.79ZM60 184a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z" /></Svg>;
export default Rss;