/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-medium-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-medium-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52 136a4 4 0 0 1-4 4H24a4 4 0 0 1 0-8h12V60H24a4 4 0 0 1 0-8h16a4 4 0 0 1 3.39 1.87L80 112.45l36.61-58.57A4 4 0 0 1 120 52h16a4 4 0 0 1 0 8h-12v72h12a4 4 0 0 1 0 8h-24a4 4 0 0 1 0-8h4V70l-32.61 52.12a4 4 0 0 1-6.78 0L44 70v62h4a4 4 0 0 1 4 4Zm116-28h64a4 4 0 0 0 0-8h-64a4 4 0 0 0 0 8Zm64 24h-64a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Zm0 32H80a4 4 0 0 0 0 8h152a4 4 0 0 0 0-8Zm0 32H80a4 4 0 0 0 0 8h152a4 4 0 0 0 0-8Z" /></Svg>;
export default ArticleMedium;