/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Swatches = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="swatches-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-swatches-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 155.91a16 16 0 0 0-1-5.22l-19.06-52.21A16 16 0 0 0 199.49 89l-67.81 24.57 12.08-69A16 16 0 0 0 130.84 26l-54.67-9.75a15.94 15.94 0 0 0-18.47 13l-25 143.12A43.82 43.82 0 0 0 75.78 224H224a16 16 0 0 0 16-16ZM76 196a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm42.72-8.38 9.78-55.92 76.42-27.7L224 156.11 116.78 195a44.89 44.89 0 0 0 1.94-7.38ZM224 208h-96.26L224 173.11Z" /></Svg>;
export default Swatches;