/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Atom = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="atom-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-atom-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.23 159.2a196.66 196.66 0 0 0-18-31.2 196.66 196.66 0 0 0 18-31.2c11.84-26.31 11.69-47.48-.43-59.6s-33.29-12.27-59.6-.43a196.66 196.66 0 0 0-31.2 18 196.66 196.66 0 0 0-31.2-18c-26.31-11.84-47.48-11.69-59.6.43s-12.27 33.29-.43 59.6a196.66 196.66 0 0 0 18 31.2 196.66 196.66 0 0 0-18 31.2c-11.84 26.31-11.69 47.48.43 59.6 6.13 6.13 14.58 9.2 24.8 9.2 10 0 21.77-2.92 34.76-8.77a196.66 196.66 0 0 0 31.2-18 196.66 196.66 0 0 0 31.2 18c13 5.85 24.74 8.77 34.76 8.77 10.26 0 18.71-3.07 24.84-9.2 12.16-12.12 12.31-33.29.47-59.6Zm-17.41-105c5.25 5.26 1.79 26-16 53.78-5.61-6.66-11.65-13.25-18.07-19.67S154.7 75.83 148 70.22c27.82-17.83 48.56-21.29 53.82-16.04ZM171.24 128a288.6 288.6 0 0 1-20.51 22.73A288.6 288.6 0 0 1 128 171.24a288.6 288.6 0 0 1-22.73-20.51A288.6 288.6 0 0 1 84.76 128 298.55 298.55 0 0 1 128 84.76a286.83 286.83 0 0 1 22.73 20.51A286.83 286.83 0 0 1 171.24 128ZM54.18 54.18c1.46-1.45 4.1-2.24 7.75-2.24 9.53 0 25.94 5.39 46 18.28-6.66 5.61-13.25 11.65-19.67 18.07S75.83 101.3 70.22 108C52.39 80.18 48.93 59.44 54.18 54.18Zm0 147.64c-5.25-5.26-1.79-26 16-53.78 5.61 6.66 11.65 13.25 18.07 19.67s13 12.46 19.67 18.07c-27.74 17.83-48.48 21.29-53.74 16.04Zm147.64 0c-5.26 5.25-26 1.79-53.78-16 6.66-5.61 13.25-11.65 19.67-18.07s12.46-13 18.07-19.67c17.83 27.74 21.29 48.48 16.04 53.74ZM144 128a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default Atom;