/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardropText = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-text-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-text-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 24A100.11 100.11 0 0 0 32 124v84a16 16 0 0 0 16 16h84a100 100 0 0 0 0-200Zm32 128H96a8 8 0 0 1 0-16h68a8 8 0 0 1 0 16Zm0-32H96a8 8 0 0 1 0-16h68a8 8 0 0 1 0 16Z" /></Svg>;
export default ChatTeardropText;