/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserList = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-list-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-list-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 80a8 8 0 0 1 8-8h88a8 8 0 0 1 0 16h-88a8 8 0 0 1-8-8Zm96 40h-88a8 8 0 0 0 0 16h88a8 8 0 0 0 0-16Zm0 48h-64a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16Zm-138.71-26a48 48 0 1 0-58.58 0c-20.62 8.73-36.87 26.3-42.46 48A8 8 0 0 0 16 200h128a8 8 0 0 0 7.75-10c-5.59-21.71-21.84-39.28-42.46-48Z" /></Svg>;
export default UserList;