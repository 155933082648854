/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsThreeCircleVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-three-circle-vertical-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-three-circle-vertical-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128a104 104 0 1 0-104 104 104.13 104.13 0 0 0 104-104ZM116 84a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm0 44a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm0 44a12 12 0 1 1 12 12 12 12 0 0 1-12-12Z" /></Svg>;
export default DotsThreeCircleVertical;