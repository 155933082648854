/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PenNibStraight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pen-nib-straight-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pen-nib-straight-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M225.92 122.11c-.1-.19-.19-.37-.3-.56L196 68.85V32a20 20 0 0 0-20-20H80a20 20 0 0 0-20 20v36.87l-29.62 52.68a5.12 5.12 0 0 0-.3.57 19.89 19.89 0 0 0 1.83 20.71c.09.13.18.25.28.37l86.44 108.29a12 12 0 0 0 18.75 0l86.43-108.29c.1-.12.19-.24.28-.37a19.91 19.91 0 0 0 1.83-20.72ZM172 36v24H84V36Zm-44 108a10 10 0 1 1 10-10 10 10 0 0 1-10 10Zm12 65.74v-43.93a34 34 0 1 0-24 0v43.92l-63.17-79.14L79 84h98l26.19 46.59Z" /></Svg>;
export default PenNibStraight;