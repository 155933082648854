/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ToggleLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toggle-left-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toggle-left-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 58H80a70 70 0 0 0 0 140h96a70 70 0 0 0 0-140Zm0 128H80a58 58 0 0 1 0-116h96a58 58 0 0 1 0 116ZM80 90a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm0 64a26 26 0 1 1 26-26 26 26 0 0 1-26 26Z" /></Svg>;
export default ToggleLeft;