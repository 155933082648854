/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M215.38 14.54a12 12 0 0 0-10.29-2.18l-128 32A12 12 0 0 0 68 56v103.35A40 40 0 1 0 92 196v-82.63l104-26v40A40 40 0 1 0 220 164V24a12 12 0 0 0-4.62-9.46ZM52 212a16 16 0 1 1 16-16 16 16 0 0 1-16 16ZM92 88.63V65.37l104-26v23.26ZM180 180a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default MusicNotes;