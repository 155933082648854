/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrosoftWordLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microsoft-word-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microsoft-word-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 20H72a20 20 0 0 0-20 20v16H36a20 20 0 0 0-20 20v104a20 20 0 0 0 20 20h16v16a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V40a20 20 0 0 0-20-20Zm-32 88h28v40h-28ZM76 44h120v40h-28v-8a20 20 0 0 0-20-20H76ZM40 80h104v96H40Zm36 132v-12h72a20 20 0 0 0 20-20v-8h28v40Zm-11.64-57.09-12-48a12 12 0 1 1 23.28-5.82l4.13 16.53 1.5-3a12 12 0 0 1 21.46 0l1.5 3 4.13-16.53a12 12 0 0 1 23.28 5.82l-12 48a12 12 0 0 1-10.33 9 11.62 11.62 0 0 1-1.31.09 12 12 0 0 1-10.73-6.63L92 146.83l-5.27 10.54a12 12 0 0 1-22.37-2.46Z" /></Svg>;
export default MicrosoftWordLogo;