/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Screwdriver = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="screwdriver-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-screwdriver-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.23 22.75a50.46 50.46 0 0 0-71.31 0L108.68 76A15.92 15.92 0 0 0 104 87.3V104H87.17a16.14 16.14 0 0 0-9.66 3.24 8 8 0 0 0-.82.72l-8 8a16 16 0 0 0 0 22.63l18.7 18.71-77 77.05a8 8 0 0 0 11.32 11.32l77-77.06 18.71 18.71a16 16 0 0 0 22.62 0l8-8a8.08 8.08 0 0 0 .72-.83 16 16 0 0 0 3.25-9.66V152h16.69a15.86 15.86 0 0 0 11.3-4.7l53.23-53.23a50.43 50.43 0 0 0 0-71.32Zm-27.57 38.89-56 56a8 8 0 0 1-11.32-11.31l56-56a8 8 0 0 1 11.32 11.32Z" /></Svg>;
export default Screwdriver;