/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hamburger = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hamburger-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hamburger-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48.07 100h159.86a12 12 0 0 0 11.8-14.55c-3.39-16-14.56-30.68-31.46-41.23S150.11 28 128 28s-43.51 5.76-60.27 16.22-28.07 25.19-31.46 41.23A12 12 0 0 0 48.07 100Zm-4-12.89C50.26 58 86.33 36 128 36s77.74 22 83.91 51.11a4 4 0 0 1-.82 3.39 4 4 0 0 1-3.16 1.5H48.07a4 4 0 0 1-3.16-1.5 4 4 0 0 1-.82-3.39Zm186.54 69.13-42.56 15.48-38.58-15.43a4 4 0 0 0-3 0L108 171.69l-38.51-15.4a4 4 0 0 0-2.86 0l-44 16A4 4 0 0 0 24 180a4.08 4.08 0 0 0 1.37-.24L44 173v11a36 36 0 0 0 36 36h96a36 36 0 0 0 36-36v-12.47l21.37-7.77a4 4 0 0 0-2.74-7.52ZM204 184a28 28 0 0 1-28 28H80a28 28 0 0 1-28-28v-13.93l15.93-5.79 38.58 15.43a4 4 0 0 0 3 0l38.49-15.4 38.51 15.4a4 4 0 0 0 2.86 0l14.63-5.27ZM20 128a4 4 0 0 1 4-4h208a4 4 0 0 1 0 8H24a4 4 0 0 1-4-4Z" /></Svg>;
export default Hamburger;