/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BaseballHelmet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baseball-helmet-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baseball-helmet-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 156a32 32 0 1 0-32 32 32 32 0 0 0 32-32Zm-40 0a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm164-40h-16.66A108 108 0 0 0 12 128v24a76.08 76.08 0 0 0 76 76h40a76.08 76.08 0 0 0 76-76v-12h40a12 12 0 0 0 0-24ZM36 152v-24a84 84 0 0 1 167.15-12H152a12 12 0 0 0-12 12v24a52 52 0 0 1-104 0Zm144 0a52.07 52.07 0 0 1-33.67 48.65A75.63 75.63 0 0 0 164 152v-12h16Z" /></Svg>;
export default BaseballHelmet;