/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93A12 12 0 0 0 36.8 44.28 20 20 0 0 0 20 64v160a19.82 19.82 0 0 0 11.56 18.1 20.14 20.14 0 0 0 8.49 1.9 19.91 19.91 0 0 0 12.82-4.72l.12-.11L84.47 212h103.68l11 12.07a12 12 0 0 0 17.76-16.15ZM80 188a11.93 11.93 0 0 0-7.84 2.92L44 215.23V68h13.24l109.09 120ZM236 64v110.12a12 12 0 0 1-24 0V68h-95.4a12 12 0 0 1 0-24H216a20 20 0 0 1 20 20Z" /></Svg>;
export default ChatSlash;