/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreasureChest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="treasure-chest-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-treasure-chest-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 124v68a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-68a4 4 0 0 1 4-4h36v64a8 8 0 0 0 8.53 8 8.17 8.17 0 0 0 7.47-8.27V120h40v20a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4v-20h40v64a8 8 0 0 0 8.53 8 8.17 8.17 0 0 0 7.47-8.25V120h36a4 4 0 0 1 4 4Zm-56-84H72a56 56 0 0 0-56 56v4a4 4 0 0 0 4 4h36V64.27A8.17 8.17 0 0 1 63.47 56 8 8 0 0 1 72 64v40h40V92a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v12h40V64.27a8.17 8.17 0 0 1 7.47-8.27 8 8 0 0 1 8.53 8v40h36a4 4 0 0 0 4-4v-4a56 56 0 0 0-56-56Z" /></Svg>;
export default TreasureChest;