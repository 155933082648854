/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CoinVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coin-vertical-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coin-vertical-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 128c0 53-25.07 96-56 96s-56-43-56-96 25.07-96 56-96 56 43 56 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M198.51 56.09C186.44 35.4 169.92 24 152 24h-48c-17.92 0-34.44 11.4-46.51 32.09C46.21 75.42 40 101 40 128s6.21 52.58 17.49 71.91C69.56 220.6 86.08 232 104 232h48c17.92 0 34.44-11.4 46.51-32.09C209.79 180.58 216 155 216 128s-6.21-52.58-17.49-71.91Zm1.28 63.91h-32a152.78 152.78 0 0 0-9.68-48h30.59c6.12 13.38 10.16 30 11.09 48Zm-20.6-64h-28.73a83.13 83.13 0 0 0-12-16H152c10 0 19.4 6 27.19 16ZM56 128c0-47.7 22-88 48-88s48 40.3 48 88-22 88-48 88-48-40.3-48-88Zm96 88h-13.51a83.13 83.13 0 0 0 12-16h28.73C171.4 210 162 216 152 216Zm36.7-32h-30.58a152.78 152.78 0 0 0 9.68-48h32c-.94 18-4.98 34.62-11.1 48Z" /></Svg>;
export default CoinVertical;