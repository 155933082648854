/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-line-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-line-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224.49 76.2 179.8 31.51a12 12 0 0 0-17 0L39.51 154.83a12 12 0 0 0-3.51 8.48V208a12 12 0 0 0 12 12h168a4 4 0 0 0 0-8H105.66L224.49 93.17a12 12 0 0 0 0-16.97ZM73.66 188 164 97.66 186.35 120 96 210.34ZM68 182.34 45.66 160 136 69.66 158.35 92ZM44 208v-38.34l21.17 21.17L86.34 212H48a4 4 0 0 1-4-4ZM218.83 87.51 192 114.34 141.66 64l26.83-26.83a4 4 0 0 1 5.66 0l44.68 44.69a4 4 0 0 1 0 5.65Z" /></Svg>;
export default PencilLine;