/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldChevron = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-chevron-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-chevron-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 42H48a14 14 0 0 0-14 14v56c0 51.94 25.12 83.4 46.2 100.64 22.73 18.6 45.27 24.89 46.22 25.15a6 6 0 0 0 3.16 0c.95-.26 23.49-6.55 46.22-25.15C196.88 195.4 222 163.94 222 112V56a14 14 0 0 0-14-14Zm-39.44 161.06A131.17 131.17 0 0 1 128 225.72a130.94 130.94 0 0 1-40.56-22.66 113.09 113.09 0 0 1-25.56-29.45L128 127.32l66.12 46.29a113.09 113.09 0 0 1-25.56 29.45ZM210 112c0 18.75-3.44 35.75-10.28 50.88l-68.28-47.8a6 6 0 0 0-6.88 0l-68.28 47.8C49.44 147.75 46 130.75 46 112V56a2 2 0 0 1 2-2h160a2 2 0 0 1 2 2Z" /></Svg>;
export default ShieldChevron;