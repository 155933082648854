/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boules = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boules-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boules-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm62.13 32.21L60.21 190.13a91.94 91.94 0 0 1-14.88-21.8l123-123a91.94 91.94 0 0 1 21.8 14.88Zm5.66 5.66a92.24 92.24 0 0 1 14.88 21.8l-123 123a92.24 92.24 0 0 1-21.8-14.88ZM128 36a91.4 91.4 0 0 1 32.43 5.91L41.91 160.43A92 92 0 0 1 128 36Zm0 184a91.4 91.4 0 0 1-32.43-5.91L214.09 95.57A92 92 0 0 1 128 220Z" /></Svg>;
export default Boules;