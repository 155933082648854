/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CassetteTape = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cassette-tape__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cassette-tape'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48H32a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16ZM80 192l12-16h72l12 16Zm144 0h-28l-21.6-28.8a8 8 0 0 0-6.4-3.2H88a8 8 0 0 0-6.4 3.2L60 192H32V64h192v128ZM176 80H80a32 32 0 0 0 0 64h96a32 32 0 0 0 0-64Zm-27.7 16a31.92 31.92 0 0 0 0 32h-40.6a31.92 31.92 0 0 0 0-32ZM64 112a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm112 16a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default CassetteTape;