/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WashingMachine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="washing-machine__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-washing-machine'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm0 176H48V48h160v160ZM128 64a64 64 0 1 0 64 64 64.07 64.07 0 0 0-64-64Zm0 112a48 48 0 1 1 48-48 48.05 48.05 0 0 1-48 48Zm72-108a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-74.34 49.66-16 16a8 8 0 0 1-11.32-11.32l16-16a8 8 0 0 1 11.32 11.32Zm32-3.32a8 8 0 0 1 0 11.32l-32 32a8 8 0 0 1-11.32-11.32l32-32a8 8 0 0 1 11.32 0Z" /></Svg>;
export default WashingMachine;