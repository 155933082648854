/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Football = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="football-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-football-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.09 54.22a30.92 30.92 0 0 0-25.31-25.31c-32-5.42-92.28-7.91-136.53 36.34S23.49 169.78 28.91 201.78a30.92 30.92 0 0 0 25.31 25.31 205 205 0 0 0 34 2.91c31.69 0 71.12-7.88 102.49-39.25C235 146.5 232.51 86.22 227.09 54.22ZM167.44 37.9a194 194 0 0 1 32.33 2.83 18.93 18.93 0 0 1 15.5 15.5 187 187 0 0 1 2.6 41.21l-59.31-59.3c2.89-.14 5.84-.24 8.88-.24ZM56.23 215.27a18.93 18.93 0 0 1-15.5-15.5 187 187 0 0 1-2.6-41.21l59.31 59.31a187 187 0 0 1-41.21-2.6Zm126-33c-11.92 11.91-34.24 28.54-69.46 34l-73.05-73.08c5.47-35.22 22.1-57.54 34-69.46s34.28-28.53 69.48-34l73.08 73.08c-5.47 35.19-22.1 57.54-34.01 69.46Zm-18.01-90.49a6 6 0 0 1 0 8.48L148.48 116l7.75 7.75a6 6 0 1 1-8.48 8.48l-7.75-7.74L124.49 140l7.75 7.76a6 6 0 1 1-8.48 8.48l-7.76-7.76-15.75 15.74a6 6 0 1 1-8.48-8.48L107.52 140l-7.75-7.75a6 6 0 1 1 8.48-8.48l7.76 7.75 15.5-15.5-7.75-7.76a6 6 0 1 1 8.48-8.48l7.75 7.75 15.75-15.74a6 6 0 0 1 8.48-.01Z" /></Svg>;
export default Football;