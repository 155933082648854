/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Option = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="option__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-option'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 192a8 8 0 0 1-8 8h-63.06a15.92 15.92 0 0 1-14.31-8.84L95.06 88H32a8 8 0 0 1 0-16h63.06a15.92 15.92 0 0 1 14.31 8.84L160.94 184H224a8 8 0 0 1 8 8ZM152 88h72a8 8 0 0 0 0-16h-72a8 8 0 0 0 0 16Z" /></Svg>;
export default Option;