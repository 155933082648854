/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bookmark = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bookmark-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bookmark-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 36H72a12 12 0 0 0-12 12v176a4 4 0 0 0 6.12 3.39L128 188.72l61.89 38.67a4 4 0 0 0 2.11.61 4.06 4.06 0 0 0 1.94-.5A4 4 0 0 0 196 224V48a12 12 0 0 0-12-12ZM72 44h112a4 4 0 0 1 4 4v120.78l-57.89-36.17a4 4 0 0 0-4.24 0L68 168.78V48a4 4 0 0 1 4-4Zm58.11 136.61a4 4 0 0 0-4.24 0L68 216.78v-38.56l60-37.5 60 37.5v38.56Z" /></Svg>;
export default Bookmark;