/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Clover = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clover-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clover-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M210.07 161.88C222 156.72 228 142.63 228 120s-6-36.72-17.93-41.88c-12.59-5.45-30.83-.39-54.22 15l-3 2 2-3c15.41-23.39 20.47-41.63 15-54.22C164.72 26 150.63 20 128 20s-36.72 6-41.88 17.93c-5.45 12.59-.39 30.83 15 54.22q1 1.53 2 3l-3-2c-23.39-15.41-41.63-20.47-54.22-15C34 83.28 28 97.37 28 120s6 36.72 17.93 41.88a27.47 27.47 0 0 0 11 2.12c11.46 0 26-5.73 43.26-17.14l3-2-2 3c-15.41 23.39-20.47 41.63-15 54.21C91.28 214 105.37 220 128 220s36.72-6 41.88-17.94c3.81-8.8 2.47-20.39-3.95-34.56 15.65 20.5 31.41 46.36 38.19 73.5a4 4 0 0 0 3.88 3 4.09 4.09 0 0 0 1-.12 4 4 0 0 0 2.91-4.85c-8.41-33.65-29.45-64.78-48-87.2 13.82 8.09 25.62 12.17 35.26 12.17a27.47 27.47 0 0 0 10.9-2.12ZM93.47 41.11C97.24 32.41 108.86 28 128 28s30.76 4.41 34.53 13.11c8.21 18.94-19.86 56.41-34.53 73-14.67-16.59-42.74-54.06-34.53-73ZM49.11 154.53C40.41 150.76 36 139.14 36 120s4.41-30.76 13.11-34.53a19.41 19.41 0 0 1 7.8-1.47c20.08 0 50.66 23.15 65.15 36-16.54 14.67-54.01 42.74-72.95 34.53Zm113.42 44.36C158.76 207.59 147.14 212 128 212s-30.76-4.41-34.53-13.11C85.26 180 113.33 142.48 128 125.94c14.67 16.54 42.74 54.06 34.53 72.95Zm-28.42-78.74L134 120c16.54-14.68 54-42.75 72.94-34.53C215.59 89.24 220 100.86 220 120s-4.41 30.76-13.11 34.53c-18.89 8.18-56.16-19.67-72.78-34.38Z" /></Svg>;
export default Clover;