/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Calculator = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calculator-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calculator-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 20H56a20 20 0 0 0-20 20v176a20 20 0 0 0 20 20h144a20 20 0 0 0 20-20V40a20 20 0 0 0-20-20Zm-4 192H60V44h136ZM80 76a12 12 0 0 1 12-12h72a12 12 0 0 1 0 24H92a12 12 0 0 1-12-12Zm40 52a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm48 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-48 48a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm48 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default Calculator;