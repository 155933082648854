/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Football = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="football-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-football-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M225.12 54.56a28.94 28.94 0 0 0-23.68-23.68c-31.61-5.36-91.16-7.83-134.78 35.78S25.52 169.83 30.88 201.44a28.94 28.94 0 0 0 23.68 23.68A203.34 203.34 0 0 0 88.25 228c31.3 0 70.19-7.77 101.09-38.66C233 145.72 230.48 86.17 225.12 54.56Zm-57.7-18.66a196.29 196.29 0 0 1 32.69 2.86 21 21 0 0 1 17.13 17.13 185.26 185.26 0 0 1 2.32 46.11L154 36.43c4.33-.34 8.79-.53 13.42-.53ZM55.89 217.24a21 21 0 0 1-17.13-17.13A185.26 185.26 0 0 1 36.44 154L102 219.56a185.26 185.26 0 0 1-46.11-2.32Zm127.79-33.56c-12.22 12.23-35.23 29.34-71.56 34.73l-74.53-74.53c5.41-36.33 22.5-59.34 34.73-71.56S107.56 43 143.89 37.6l74.52 74.52c-5.41 36.33-22.5 59.34-34.73 71.56Zm-20.87-90.49a4 4 0 0 1 0 5.65L145.65 116l9.17 9.16a4 4 0 0 1-5.66 5.66l-9.16-9.16L121.66 140l9.17 9.17a4 4 0 0 1-5.66 5.66l-9.17-9.18-17.16 17.16a4 4 0 1 1-5.65-5.65L110.35 140l-9.17-9.16a4 4 0 1 1 5.66-5.66l9.17 9.17L134.34 116l-9.17-9.17a4 4 0 1 1 5.66-5.66l9.16 9.17 17.17-17.16a4 4 0 0 1 5.65.01Z" /></Svg>;
export default Football;