/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Flame = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flame-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flame-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M170.9 54.24a216.79 216.79 0 0 0-40.84-33.67 4 4 0 0 0-4.12 0A216.79 216.79 0 0 0 85.1 54.24C58.21 82.48 44 113.51 44 144a84 84 0 0 0 168 0c0-30.49-14.21-61.52-41.1-89.76ZM92 184c0-32.11 28.07-53.75 36-59.21 7.93 5.47 36 27.1 36 59.21a36 36 0 0 1-72 0Zm69.94 28A43.82 43.82 0 0 0 172 184c0-41.78-40.23-66.4-41.94-67.43a4 4 0 0 0-4.12 0C124.23 117.6 84 142.22 84 184a43.82 43.82 0 0 0 10.06 28A76.07 76.07 0 0 1 52 144c0-62.48 63.64-107.17 76-115.26 12.36 8.09 76 52.78 76 115.26a76.07 76.07 0 0 1-42.06 68Z" /></Svg>;
export default Flame;