/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FaceMask = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="face-mask-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-face-mask-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 72h-.85a16 16 0 0 0-9.68-10l-80-29a16.06 16.06 0 0 0-10.94 0l-80 29.09a16 16 0 0 0-9.68 10H32A32 32 0 0 0 0 104v24a32 32 0 0 0 32 32h5.19c7.19 15.8 21.79 29.43 43.23 40.16a191.16 191.16 0 0 0 46.15 15.71 7.93 7.93 0 0 0 2.86 0 191.16 191.16 0 0 0 46.15-15.71c21.44-10.73 36-24.36 43.23-40.16H224a32 32 0 0 0 32-32v-24a32 32 0 0 0-32-32ZM32 144a16 16 0 0 1-16-16v-24a16 16 0 0 1 16-16v48a58.74 58.74 0 0 0 .55 8Zm136 0H88a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16Zm0-32H88a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16Zm72 16a16 16 0 0 1-16 16h-.55a58.74 58.74 0 0 0 .55-8V88a16 16 0 0 1 16 16Z" /></Svg>;
export default FaceMask;