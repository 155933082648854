/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RectangleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rectangle-dashed-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rectangle-dashed-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M78 48a6 6 0 0 1-6 6H40a2 2 0 0 0-2 2v16a6 6 0 0 1-12 0V56a14 14 0 0 1 14-14h32a6 6 0 0 1 6 6ZM32 150a6 6 0 0 0 6-6v-32a6 6 0 0 0-12 0v32a6 6 0 0 0 6 6Zm40 52H40a2 2 0 0 1-2-2v-16a6 6 0 0 0-12 0v16a14 14 0 0 0 14 14h32a6 6 0 0 0 0-12Zm72 0h-32a6 6 0 0 0 0 12h32a6 6 0 0 0 0-12Zm80-24a6 6 0 0 0-6 6v16a2 2 0 0 1-2 2h-32a6 6 0 0 0 0 12h32a14 14 0 0 0 14-14v-16a6 6 0 0 0-6-6Zm0-72a6 6 0 0 0-6 6v32a6 6 0 0 0 12 0v-32a6 6 0 0 0-6-6Zm-8-64h-32a6 6 0 0 0 0 12h32a2 2 0 0 1 2 2v16a6 6 0 0 0 12 0V56a14 14 0 0 0-14-14Zm-72 0h-32a6 6 0 0 0 0 12h32a6 6 0 0 0 0-12Z" /></Svg>;
export default RectangleDashed;