/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-check-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-check-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 44H48a12 12 0 0 0-12 12v56c0 51.16 24.73 82.12 45.47 99.1 22.4 18.32 44.55 24.5 45.48 24.76a4 4 0 0 0 2.1 0c.93-.26 23.08-6.44 45.48-24.76 20.74-17 45.47-47.94 45.47-99.1V56a12 12 0 0 0-12-12Zm4 68c0 38.44-14.23 69.63-42.29 92.71A132.45 132.45 0 0 1 128 227.82a132.23 132.23 0 0 1-41.71-23.11C58.23 181.63 44 150.44 44 112V56a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4Zm-41.17-10.83a4 4 0 0 1 0 5.66l-56 56a4 4 0 0 1-5.66 0l-24-24a4 4 0 0 1 5.66-5.66L112 154.34l53.17-53.17a4 4 0 0 1 5.66 0Z" /></Svg>;
export default ShieldCheck;