/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Books = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="books-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-books-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 34H56a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h48a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14ZM54 78h52v100H54Zm2-32h48a2 2 0 0 1 2 2v18H54V48a2 2 0 0 1 2-2Zm48 164H56a2 2 0 0 1-2-2v-18h52v18a2 2 0 0 1-2 2Zm125.7-15L196.51 37.16a14 14 0 0 0-16.63-10.85l-46.81 10.06A14.09 14.09 0 0 0 122.3 53l33.19 157.81a14 14 0 0 0 6.1 8.9 13.85 13.85 0 0 0 7.57 2.26 13.55 13.55 0 0 0 3-.32l46.81-10.05A14.09 14.09 0 0 0 229.7 195Zm-82.81-83.32 50.73-10.9 14.12 67.16L161 178.81Zm-6.63-31.56L191 69.19 195.15 89l-50.73 10.9Zm-4.66-32 46.8-10.05a2.18 2.18 0 0 1 .42 0 1.89 1.89 0 0 1 1.05.32 2 2 0 0 1 .89 1.31l3.75 17.82-50.72 10.82-3.74-17.78a2.07 2.07 0 0 1 1.55-2.46Zm80.81 151.8L169.6 210a1.92 1.92 0 0 1-1.47-.27 2 2 0 0 1-.89-1.31l-3.75-17.82 50.72-10.9 3.79 17.73a2.07 2.07 0 0 1-1.59 2.47Z" /></Svg>;
export default Books;