/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bone-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bone-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.14 62.17A33.88 33.88 0 0 0 206 50a34 34 0 1 0-62.81 20 2 2 0 0 1-.23 2.54L72.56 143a2.06 2.06 0 0 1-2.55.23A34 34 0 1 0 50 206a34 34 0 1 0 62.81-20 2 2 0 0 1 .23-2.54l70.4-70.4a2 2 0 0 1 2.54-.23 34 34 0 0 0 44.15-50.65Zm-9.54 36.31a22 22 0 0 1-28.24 4.17 14 14 0 0 0-17.4 1.92L104.57 175a14 14 0 0 0-1.92 17.4 22 22 0 1 1-40.41 8.26 6 6 0 0 0-5.93-6.93 7.28 7.28 0 0 0-.93.07 22 22 0 1 1 8.26-40.41A14 14 0 0 0 81 151.43L151.43 81a14 14 0 0 0 1.92-17.4 22 22 0 1 1 40.41-8.26 6 6 0 0 0 6.86 6.86 22 22 0 0 1 20 36.24Z" /></Svg>;
export default Bone;