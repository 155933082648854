/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileArchive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-archive-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-archive-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m212.24 83.76-56-56A6 6 0 0 0 152 26H56a14 14 0 0 0-14 14v176a14 14 0 0 0 14 14h144a14 14 0 0 0 14-14V88a6 6 0 0 0-1.76-4.24ZM158 46.48 193.52 82H158ZM200 218h-90v-20h10a6 6 0 0 0 0-12h-10v-20h10a6 6 0 0 0 0-12h-10v-20h10a6 6 0 0 0 0-12h-10v-10a6 6 0 0 0-12 0v10H88a6 6 0 0 0 0 12h10v20H88a6 6 0 0 0 0 12h10v20H88a6 6 0 0 0 0 12h10v20H56a2 2 0 0 1-2-2V40a2 2 0 0 1 2-2h90v50a6 6 0 0 0 6 6h50v122a2 2 0 0 1-2 2Z" /></Svg>;
export default FileArchive;