/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bread = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bread-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bread-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 111v89a8 8 0 0 1-8 8h-56a8 8 0 0 0 8-8v-89a32 32 0 0 0-8-63h56a32 32 0 0 1 8 63Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M240 80a40 40 0 0 0-40-40H48a40 40 0 0 0-16 76.65V200a16 16 0 0 0 16 16h152a16 16 0 0 0 16-16v-83.35A40.06 40.06 0 0 0 240 80ZM48 120a8 8 0 0 0 0-16 24 24 0 0 1 0-48h96a24 24 0 0 1 0 48 8 8 0 0 0 0 16v80H48Zm152-16a8 8 0 0 0 0 16v80h-40v-83.35A40 40 0 0 0 176 56h24a24 24 0 0 1 0 48Z" /></Svg>;
export default Bread;