/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Notification = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="notification-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-notification-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M214 128v80a14 14 0 0 1-14 14H48a14 14 0 0 1-14-14V56a14 14 0 0 1 14-14h80a6 6 0 0 1 0 12H48a2 2 0 0 0-2 2v152a2 2 0 0 0 2 2h152a2 2 0 0 0 2-2v-80a6 6 0 0 1 12 0Zm16-68a34 34 0 1 1-34-34 34 34 0 0 1 34 34Zm-12 0a22 22 0 1 0-22 22 22 22 0 0 0 22-22Z" /></Svg>;
export default Notification;