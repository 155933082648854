/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const JarLabel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="jar-label-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-jar-label-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M182 50.48V32a14 14 0 0 0-14-14H88a14 14 0 0 0-14 14v18.48A38.05 38.05 0 0 0 42 88v112a38 38 0 0 0 38 38h96a38 38 0 0 0 38-38V88a38.05 38.05 0 0 0-32-37.52ZM54 110h148v68H54Zm116-78v18h-20V30h18a2 2 0 0 1 2 2Zm-52 18V30h20v20ZM88 30h18v20H86V32a2 2 0 0 1 2-2Zm-8 32h96a26 26 0 0 1 26 26v10H54V88a26 26 0 0 1 26-26Zm96 164H80a26 26 0 0 1-26-26v-10h148v10a26 26 0 0 1-26 26Z" /></Svg>;
export default JarLabel;