/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugCharging = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plug-charging-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plug-charging-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 56h-48V16a8 8 0 0 0-16 0v40H96V16a8 8 0 0 0-8-8c-3.21 0-8 2.27-8 8.54V56H32.55C26.28 56 24 60.78 24 64a8 8 0 0 0 8 8h16v88a40 40 0 0 0 40 40h32v40a8 8 0 0 0 16 0v-40h32a40 40 0 0 0 40-40V72h16a8 8 0 0 0 0-16Zm-72.51 74.81-12 32a8 8 0 0 1-15-5.62l8-21.19H112a8 8 0 0 1-7.49-10.81l12-32a8 8 0 1 1 15 5.62l-8 21.19H144a8 8 0 0 1 7.49 10.81Z" /></Svg>;
export default PlugCharging;