/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileySad = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-sad-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-sad-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90ZM82 108a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm92 0a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-.81 65a6 6 0 0 1-10.38 6c-7.84-13.54-20.2-21-34.81-21s-27 7.46-34.81 21a6 6 0 0 1-5.2 3 5.9 5.9 0 0 1-3-.81 6 6 0 0 1-2.18-8.19c9.92-17.16 26.39-27 45.19-27s35.27 9.84 45.19 27Z" /></Svg>;
export default SmileySad;