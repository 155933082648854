/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsCounterClockwise = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-counter-clockwise-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-counter-clockwise-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 108H40a12 12 0 0 1-12-12V48a12 12 0 0 1 24 0v19l7.8-7.8A99.42 99.42 0 0 1 130 29.94h.56a99.38 99.38 0 0 1 69.87 28.47 12 12 0 0 1-16.78 17.16 76 76 0 0 0-106.84.63L69 84h19a12 12 0 0 1 0 24Zm128 40h-48a12 12 0 0 0 0 24h19l-7.8 7.8a75.55 75.55 0 0 1-53.32 22.26h-.43a75.49 75.49 0 0 1-53.09-21.63 12 12 0 0 0-16.78 17.16 99.38 99.38 0 0 0 69.87 28.47h.55a99.42 99.42 0 0 0 70.16-29.29L204 189v19a12 12 0 0 0 24 0v-48a12 12 0 0 0-12-12Z" /></Svg>;
export default ArrowsCounterClockwise;