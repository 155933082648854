/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PintGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pint-glass__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pint-glass'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206 26.69a8 8 0 0 0-6-2.69H56a8 8 0 0 0-7.94 9l23.15 193a16 16 0 0 0 15.89 14h81.8a16 16 0 0 0 15.89-14.09L207.94 33a8 8 0 0 0-1.94-6.31ZM191 40l-2.9 24H67.9L65 40Zm-22.1 184H87.1L69.82 80h116.36Z" /></Svg>;
export default PintGlass;