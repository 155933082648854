/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldWarning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-warning__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-warning'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 136V96a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm8 48a12 12 0 1 0-12-12 12 12 0 0 0 12 12Zm96-128v56c0 52.72-25.52 84.67-46.93 102.19-23.06 18.86-46 25.27-47 25.53a8 8 0 0 1-4.2 0c-1-.26-23.91-6.67-47-25.53C57.52 196.67 32 164.72 32 112V56a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Zm-16 0H48v56c0 37.3 13.82 67.51 41.07 89.81A128.25 128.25 0 0 0 128 223.62a129.3 129.3 0 0 0 39.41-22.2C194.34 179.16 208 149.07 208 112Z" /></Svg>;
export default ShieldWarning;