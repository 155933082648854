/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pulse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pulse__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pulse'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 128a8 8 0 0 1-8 8h-27.06l-37.78 75.58A8 8 0 0 1 160 216h-.4a8 8 0 0 1-7.08-5.14L95.35 60.76l-32.07 70.55A8 8 0 0 1 56 136H24a8 8 0 0 1 0-16h26.85l37.87-83.31a8 8 0 0 1 14.76.46l57.51 151 31.85-63.71A8 8 0 0 1 200 120h32a8 8 0 0 1 8 8Z" /></Svg>;
export default Pulse;