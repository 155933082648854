/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Meteor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="meteor-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-meteor-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96 124a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 48a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm128.49-52.49a12 12 0 0 1 0 17l-48 48a12 12 0 0 1-17-17l48-48a12 12 0 0 1 17 0Zm-36-20a12 12 0 0 1 0 17l-20 20a12 12 0 0 1-17-17l20-20a12 12 0 0 1 17 0Zm44-27-16 16a12 12 0 0 1-17-17l16-16a12 12 0 0 1 17 17Zm-113 15 72-72a12 12 0 0 1 17 17l-72 72a12 12 0 1 1-17-17Zm30.23 109.26a12 12 0 0 1 0 17A76 76 0 1 1 42.26 106.26L125 23.51a12 12 0 1 1 17 17l-82.77 82.72a52 52 0 0 0 73.54 73.54 12 12 0 0 1 16.97 0Z" /></Svg>;
export default Meteor;