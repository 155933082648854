/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseLeftClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-left-click-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-left-click-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 18h-32a62.07 62.07 0 0 0-62 62v96a62.07 62.07 0 0 0 62 62h32a62.07 62.07 0 0 0 62-62V80a62.07 62.07 0 0 0-62-62Zm50 62v26h-60V30h10a50.06 50.06 0 0 1 50 50Zm-72-50v35.51L91.09 34.6A49.74 49.74 0 0 1 112 30ZM80.62 41.11 122 82.49V106h-7.51L66.9 58.42a50.3 50.3 0 0 1 13.72-17.31ZM62 80a50.17 50.17 0 0 1 .78-8.73L97.51 106H62Zm82 146h-32a50.06 50.06 0 0 1-50-50v-58h132v58a50.06 50.06 0 0 1-50 50Z" /></Svg>;
export default MouseLeftClick;