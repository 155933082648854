/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSnowboard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-snowboard-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-snowboard-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 78a26 26 0 1 0-26-26 26 26 0 0 0 26 26Zm0-40a14 14 0 1 1-14 14 14 14 0 0 1 14-14Zm53.69 76.24-136-40a6 6 0 1 0-3.38 11.51l43.45 12.78-35.84 53.77-46.13-13.37a23.19 23.19 0 0 0-20.54 3.75 23 23 0 0 0-9.25 18.5 23.32 23.32 0 0 0 16.77 22.25l157.44 45.63a23.67 23.67 0 0 0 6.58.94 23.17 23.17 0 0 0 6.44-45.44l-53.52-15.51L165 137.34a6 6 0 0 0-3.31-9.11l-38-11 10.11-15.16 80.49 23.67a5.82 5.82 0 0 0 1.71.26 6 6 0 0 0 1.69-11.76ZM202 206.81a11.05 11.05 0 0 1-4.46 8.9 11.26 11.26 0 0 1-10 1.82L30.11 171.9A11.24 11.24 0 0 1 22 161.18a11.05 11.05 0 0 1 4.46-8.9 11.26 11.26 0 0 1 10-1.82l157.44 45.63a11.24 11.24 0 0 1 8.1 10.72Zm-51.56-69.34-18.83 28.07L98 155.8l18.73-28.09Z" /></Svg>;
export default PersonSimpleSnowboard;