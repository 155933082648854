/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TruckTrailer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-trailer-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-trailer-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 96.8V96a56.06 56.06 0 0 0-56-56h-8a16 16 0 0 0-16 16v120h-16V72a8 8 0 0 0-8-8H16A16 16 0 0 0 0 80v104a32 32 0 0 0 56 21.13A32 32 0 0 0 111 192h82a32 32 0 0 0 63-8v-48a40.07 40.07 0 0 0-32-39.2ZM32 200a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm48 0a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm144 0a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default TruckTrailer;