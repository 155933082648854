/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-slash__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-slash'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M53.92 34.62a8 8 0 1 0-11.84 10.76l22.64 24.91C48.32 95 40 119.78 40 144a88 88 0 0 0 149.21 63.22l12.87 14.16a8 8 0 1 0 11.84-10.76ZM128 216a72.08 72.08 0 0 1-72-72c0-19.93 6.68-40.57 19.86-61.46l102.57 112.82A71.84 71.84 0 0 1 128 216ZM90 50.51a8 8 0 0 1-.27-11.31 247.8 247.8 0 0 1 33.68-29.75 8 8 0 0 1 9.18 0C136 11.83 216 68.7 216 144a88.08 88.08 0 0 1-3.15 23.4 8 8 0 0 1-7.71 5.88A7.79 7.79 0 0 1 203 173a8 8 0 0 1-5.59-9.83A72.55 72.55 0 0 0 200 144c0-57.24-55.48-105-72-118a252.23 252.23 0 0 0-26.66 24.23 8 8 0 0 1-11.34.28Z" /></Svg>;
export default DropSlash;