/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Factory = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="factory-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-factory-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 208h-8v-72.98a.76.76 0 0 0 0-.15L209 29.74A16.08 16.08 0 0 0 193.06 16h-18.12a16.08 16.08 0 0 0-15.84 13.74l-11.56 80.91L108.8 81.6A8 8 0 0 0 96 88v32L44.8 81.6A8 8 0 0 0 32 88v120h-8a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16Zm-124-24H80a8 8 0 0 1 0-16h28a8 8 0 0 1 0 16Zm68 0h-28a8 8 0 0 1 0-16h28a8 8 0 0 1 0 16Zm-5.33-56-8.53-6.4 12.8-89.6h18.12l13.72 96Z" /></Svg>;
export default Factory;