/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DoorOpen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="door-open-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-door-open-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 218h-26V40a14 14 0 0 0-14-14H64a14 14 0 0 0-14 14v178H24a6 6 0 0 0 0 12h208a6 6 0 0 0 0-12ZM194 40v178h-20V38h18a2 2 0 0 1 2 2ZM62 40a2 2 0 0 1 2-2h98v180H62Zm80 92a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default DoorOpen;