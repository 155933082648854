/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Keyhole = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="keyhole-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-keyhole-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 192a92 92 0 1 1 92-92 92.1 92.1 0 0 1-92 92Zm36-108a36 36 0 1 0-57 29.22l-14.66 33.17A4 4 0 0 0 96 180h64a4 4 0 0 0 3.66-5.61L149 141.22A36.15 36.15 0 0 0 164 112Zm-23.66 29.33L153.86 172h-51.72l13.52-30.67a4 4 0 0 0-1.66-5.07 28 28 0 1 1 28 0 4 4 0 0 0-1.66 5.07Z" /></Svg>;
export default Keyhole;