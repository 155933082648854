/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlyingSaucer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flying-saucer-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flying-saucer-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 112c0 26.51-50.14 48-112 48S16 138.51 16 112c0-19 25.86-35.49 63.35-43.25A57.07 57.07 0 0 0 72 96.83v3.07a15.94 15.94 0 0 0 12.34 15.52A195.87 195.87 0 0 0 128 120a195.71 195.71 0 0 0 43.64-4.58A16 16 0 0 0 184 99.9V96a55.7 55.7 0 0 0-7-27.18C214.29 76.61 240 93 240 112Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M183.59 213.47a8 8 0 0 1-15.18 5.06l-8-24a8 8 0 0 1 15.18-5.06ZM128 184a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0v-32a8 8 0 0 0-8-8Zm-37.47.41a8 8 0 0 0-10.12 5.06l-8 24a8 8 0 0 0 15.18 5.06l8-24a8 8 0 0 0-5.06-10.12ZM248 112c0 16.22-13.37 30.89-37.65 41.29C188.22 162.78 159 168 128 168s-60.22-5.22-82.35-14.71C21.37 142.89 8 128.22 8 112c0-8.37 3.67-20.79 21.17-32.5 11.37-7.61 26.94-13.76 45.18-17.85A63.64 63.64 0 0 1 173 50.45a64.84 64.84 0 0 1 9.11 11.3C223.43 71.09 248 89.74 248 112ZM80 96.83v3a7.92 7.92 0 0 0 6.13 7.76A188.24 188.24 0 0 0 128 112a188.09 188.09 0 0 0 41.85-4.37 7.93 7.93 0 0 0 6.15-7.76V96a48 48 0 0 0-48.64-48C101.25 48.34 80 70.25 80 96.83ZM232 112c0-11.7-16.63-23.89-41.9-31.59A64.68 64.68 0 0 1 192 96v3.92a23.86 23.86 0 0 1-18.56 23.3A204.05 204.05 0 0 1 128 128a204.15 204.15 0 0 1-45.44-4.78A23.86 23.86 0 0 1 64 99.92v-3.06a65.28 65.28 0 0 1 2.13-16.52C40.72 88 24 100.25 24 112c0 18.92 42.71 40 104 40s104-21.08 104-40Z" /></Svg>;
export default FlyingSaucer;