/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagBanner = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-banner-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-banner-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M242.15 49.59A12 12 0 0 0 232 44H40a12 12 0 0 0-8.48 20.49L71 104l-39.48 39.51A12 12 0 0 0 40 164h127.28l-26.11 54.84a12 12 0 1 0 21.66 10.32l80-168a12 12 0 0 0-.68-11.57ZM178.71 140H69l27.52-27.52a12 12 0 0 0 0-17L69 68h144Z" /></Svg>;
export default FlagBanner;