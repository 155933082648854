/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-nine-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-nine-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 42a54 54 0 1 0 19.94 104.17l-33.17 58.88a6 6 0 1 0 10.46 5.89l49.54-88A54 54 0 0 0 128 42Zm0 96a42 42 0 1 1 42-42 42 42 0 0 1-42 42Z" /></Svg>;
export default NumberNine;