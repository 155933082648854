/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShippingContainer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shipping-container-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shipping-container-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.5 66.8 131.3 36.46a12.07 12.07 0 0 0-5-.34l-105.13 15A20.1 20.1 0 0 0 4 70.94v114.12a20.1 20.1 0 0 0 17.17 19.8l105.13 15a12.15 12.15 0 0 0 1.7.12 12 12 0 0 0 3.3-.46l106.2-30.32A20.09 20.09 0 0 0 252 170V86a20.08 20.08 0 0 0-14.5-19.2ZM92 116h-8V66.41l32-4.57v132.32l-32-4.57V140h8a12 12 0 0 0 0-24ZM28 74.41l32-4.57V116h-8a12 12 0 0 0 0 24h8v46.16l-32-4.57ZM228 167l-88 25.14V63.91l88 25.14Z" /></Svg>;
export default ShippingContainer;