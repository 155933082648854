/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScanSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scan-smiley-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scan-smiley-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 40v36a6 6 0 0 1-12 0V46h-30a6 6 0 0 1 0-12h36a6 6 0 0 1 6 6Zm-6 134a6 6 0 0 0-6 6v30h-30a6 6 0 0 0 0 12h36a6 6 0 0 0 6-6v-36a6 6 0 0 0-6-6ZM76 210H46v-30a6 6 0 0 0-12 0v36a6 6 0 0 0 6 6h36a6 6 0 0 0 0-12ZM40 82a6 6 0 0 0 6-6V46h30a6 6 0 0 0 0-12H40a6 6 0 0 0-6 6v36a6 6 0 0 0 6 6Zm88 116a70 70 0 1 1 70-70 70.08 70.08 0 0 1-70 70Zm58-70a58 58 0 1 0-58 58 58.07 58.07 0 0 0 58-58Zm-72-12a10 10 0 1 0-10 10 10 10 0 0 0 10-10Zm38-10a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm-4 41.5c-4.2 3.71-12.81 6.5-20 6.5s-15.83-2.79-20-6.5a6 6 0 0 0-7.94 9c7.44 6.56 19.41 9.5 28 9.5s20.53-2.94 28-9.5a6 6 0 1 0-7.94-9Z" /></Svg>;
export default ScanSmiley;