/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-six-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-six-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M70 92a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm58-10a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm68 20a10 10 0 1 0-10-10 10 10 0 0 0 10 10ZM60 154a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm68 0a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm68 0a10 10 0 1 0 10 10 10 10 0 0 0-10-10Z" /></Svg>;
export default DotsSix;