/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleGear = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-gear-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-gear-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M169.57 46.11a12 12 0 0 1 15.12-7.7l3.31 1.07V36a12 12 0 0 1 24 0v3.48l3.31-1.07a12 12 0 1 1 7.42 22.82l-3.31 1.08 2 2.82a12 12 0 1 1-19.41 14.1L200 76.42l-2 2.81a12 12 0 1 1-19.41-14.1l2-2.82-3.31-1.08a12 12 0 0 1-7.71-15.12ZM236 128A108 108 0 1 1 128 20a109.19 109.19 0 0 1 18 1.49 12 12 0 0 1-4 23.67A85 85 0 0 0 128 44a83.94 83.94 0 0 0-65.95 135.94 83.48 83.48 0 0 1 29-23.42 52 52 0 1 1 74 0 83.48 83.48 0 0 1 29 23.42A83.57 83.57 0 0 0 212 128a85.2 85.2 0 0 0-1.16-14 12 12 0 0 1 23.67-4 109 109 0 0 1 1.49 18Zm-108 20a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0 64a83.53 83.53 0 0 0 48.43-15.43 60 60 0 0 0-96.86 0A83.53 83.53 0 0 0 128 212Z" /></Svg>;
export default UserCircleGear;