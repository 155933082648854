/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Spiral = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spiral-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spiral-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 144a8 8 0 0 1-16 0 96.11 96.11 0 0 0-96-96c-1.4 0-2.8 0-4.18.1A80.06 80.06 0 0 0 56 128a64.07 64.07 0 0 0 64 64 44.05 44.05 0 0 0 44-44 32 32 0 0 0-32-32 8 8 0 0 0 0 16 16 16 0 0 1 16 16 28 28 0 0 1-28 28 48.05 48.05 0 0 1-48-48 64.07 64.07 0 0 1 64-64 80.09 80.09 0 0 1 80 80 88.1 88.1 0 0 1-88 88 96.11 96.11 0 0 1-96-96A104.11 104.11 0 0 1 136 32a112.12 112.12 0 0 1 112 112Z" /></Svg>;
export default Spiral;