/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rainbow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rainbow-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rainbow-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 48A120.13 120.13 0 0 0 8 168v16a8 8 0 0 0 8 8h224a8 8 0 0 0 8-8v-16A120.13 120.13 0 0 0 128 48Zm32 128a8 8 0 0 1-8-8 24 24 0 0 0-48 0 8 8 0 0 1-16 0 40 40 0 0 1 80 0 8 8 0 0 1-8 8Zm32 0a8 8 0 0 1-8-8 56 56 0 0 0-112 0 8 8 0 0 1-16 0 72 72 0 0 1 144 0 8 8 0 0 1-8 8Zm32 0a8 8 0 0 1-8-8 88 88 0 0 0-176 0 8 8 0 0 1-16 0 104 104 0 0 1 208 0 8 8 0 0 1-8 8Z" /></Svg>;
export default Rainbow;