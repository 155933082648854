/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-user-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-user-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m181.36 114.73-48 24A12 12 0 0 1 116 128V72a12 12 0 0 1 24 0v36.58l30.63-15.31a12 12 0 1 1 10.73 21.46Zm45.72 120.86a12 12 0 0 1-14.68-8.51C210 218.2 201.65 212 192 212s-18 6.2-20.4 15.08A12 12 0 0 1 160 236a11.77 11.77 0 0 1-3.09-.41 12 12 0 0 1-8.52-14.68 43.76 43.76 0 0 1 15.08-23 36 36 0 1 1 57 0 43.7 43.7 0 0 1 15.08 23 12 12 0 0 1-8.47 14.68ZM180 176a12 12 0 1 0 12-12 12 12 0 0 0-12 12Zm-62.52 35.35a84 84 0 1 1 93.87-93.87 12 12 0 1 0 23.81-3 108 108 0 1 0-120.65 120.68 11 11 0 0 0 1.5.1 12 12 0 0 0 1.47-23.91Z" /></Svg>;
export default ClockUser;