/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MopedFront = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="moped-front-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-moped-front-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40h-40.8a40 40 0 0 0-78.4 0H48a8 8 0 0 0 0 16h40.8a40 40 0 0 0 12.58 21.82A64.08 64.08 0 0 0 64 136v64a16 16 0 0 0 16 16h16a32 32 0 0 0 64 0h16a16 16 0 0 0 16-16v-64a64.08 64.08 0 0 0-37.38-58.18A40 40 0 0 0 167.2 56H208a8 8 0 0 0 0-16Zm-64 176a16 16 0 0 1-32 0v-48a16 16 0 0 1 32 0ZM128 72a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default MopedFront;