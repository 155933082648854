/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartDonut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-donut-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-donut-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 161.94v65.34a4 4 0 0 1-4.46 4 104.28 104.28 0 0 1-84-64.5 4 4 0 0 1 2.69-5.34l63.09-16.94a4 4 0 0 1 4.35 1.66 32.25 32.25 0 0 0 15.59 12 4 4 0 0 1 2.74 3.78ZM128.06 24a8 8 0 0 0-8.06 8v56a7.94 7.94 0 0 0 7.87 8 32 32 0 0 1 10.86 62.15 4 4 0 0 0-2.73 3.79v65.34a4 4 0 0 0 4.45 4A104 104 0 0 0 128.06 24Zm-32 101.49a32 32 0 0 1 4.15-13.42V112a8 8 0 0 0-.57-8.87 8.36 8.36 0 0 0-2.46-2.13L48.85 73.06A8 8 0 0 0 37.92 76 104.12 104.12 0 0 0 25 142.68a4 4 0 0 0 5 3.32l63.22-17a3.94 3.94 0 0 0 2.88-3.51Z" /></Svg>;
export default ChartDonut;