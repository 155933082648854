/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Wheelchair = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wheelchair-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wheelchair-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M255.14 187.54a12 12 0 0 0-15.6-6.68l-9.75 3.9-27.06-54.13A12 12 0 0 0 192 124h-76v-16h52a12 12 0 0 0 0-24h-52v-6.19a34 34 0 1 0-24 0v8.88a76 76 0 1 0 88.35 106.57 12 12 0 1 0-21.57-10.52A52 52 0 1 1 92 112v24a12 12 0 0 0 12 12h80.58l28.68 57.37a12 12 0 0 0 15.19 5.77l20-8a12 12 0 0 0 6.69-15.6ZM104 36a10 10 0 1 1-10 10 10 10 0 0 1 10-10Z" /></Svg>;
export default Wheelchair;