/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ToiletPaper = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toilet-paper-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toilet-paper-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 120a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm164 0v88a20 20 0 0 1-20 20H112a20 20 0 0 1-20-20v-9.61C84 207.07 74.46 212 64 212c-29.65 0-52-39.55-52-92s22.35-92 52-92h128c29.65 0 52 39.55 52 92Zm-152 0c0-19.26-3.81-37.92-10.45-51.2C76.29 58.28 69.73 52 64 52s-12.29 6.28-17.55 16.8C39.81 82.08 36 100.74 36 120s3.81 37.92 10.45 51.2C51.71 181.72 58.27 188 64 188s12.29-6.28 17.55-16.8C88.19 157.92 92 139.26 92 120Zm128 84v-72h-8a12 12 0 0 1 0-24h7.5c-1.23-14.85-4.7-28.71-9.95-39.2S197.73 52 192 52H99.74c8.61 14.11 14.35 33.56 15.86 56h8.4a12 12 0 0 1 0 24h-8v72Zm-48-96h-8a12 12 0 0 0 0 24h8a12 12 0 0 0 0-24Z" /></Svg>;
export default ToiletPaper;