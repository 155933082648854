/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartDonut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-donut-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-donut-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a4 4 0 0 0-4 4v56a4 4 0 0 0 4 4 36 36 0 1 1-31.18 18 4 4 0 0 0-1.47-5.47l-48.5-28A4 4 0 0 0 41.39 78 100 100 0 1 0 128 28ZM46.38 85.5l41.67 24a43.86 43.86 0 0 0-3.4 25.93l-46.47 12.5a92.19 92.19 0 0 1 8.2-62.43Zm-6.13 70.15 46.46-12.45A44.11 44.11 0 0 0 124 171.81v48.1a92.17 92.17 0 0 1-83.75-64.26ZM132 219.91v-48.1a44 44 0 0 0 0-87.63V36.09a92 92 0 0 1 0 183.82Z" /></Svg>;
export default ChartDonut;