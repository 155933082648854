/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26ZM71.44 198a66 66 0 0 1 113.12 0 89.8 89.8 0 0 1-113.12 0ZM94 120a34 34 0 1 1 34 34 34 34 0 0 1-34-34Zm99.51 69.64a77.53 77.53 0 0 0-40-31.38 46 46 0 1 0-51 0 77.53 77.53 0 0 0-40 31.38 90 90 0 1 1 131 0Z" /></Svg>;
export default UserCircle;