/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CirclesFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circles-four-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circles-four-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M112 80a32 32 0 1 1-32-32 32 32 0 0 1 32 32Zm64 32a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm-96 32a32 32 0 1 0 32 32 32 32 0 0 0-32-32Zm96 0a32 32 0 1 0 32 32 32 32 0 0 0-32-32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M80 40a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 64a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm96 16a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm0-64a24 24 0 1 1-24 24 24 24 0 0 1 24-24Zm-96 80a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 64a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm96-64a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 64a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default CirclesFour;