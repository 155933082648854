/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PipeWrench = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pipe-wrench-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pipe-wrench-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M173.66 145.66 85 235a17 17 0 0 1-24-24l64.69-65.37a8 8 0 0 0 0-11.32L77 85a17 17 0 0 1 0-24 17 17 0 0 1 24 0l72.69 73.37a8 8 0 0 1-.03 11.29Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m220.28 55-.17-.17-44.9-42.28a16 16 0 0 0-22.5.08l-44.54 44.24-1.54-1.56a25 25 0 0 0-35.36 35.27l1.46 1.48L52.69 112a16 16 0 0 0 0 22.63l12.68 12.68a16 16 0 0 0 22.59 0l19.93-19.65L120 140l-64.69 65.37a25 25 0 1 0 35.34 35.29l88.67-89.35a16 16 0 0 0 0-22.6l-35.69-36.05L156.56 80l.1.09L194 115.4a16 16 0 0 0 22.53-.09l3.71-3.71a40 40 0 0 0 0-56.57ZM76.69 136 64 123.33l20-19.88 12.69 12.86Zm2.62 93.37a9 9 0 1 1-12.65-12.71l64.67-65.37a16 16 0 0 0 0-22.57l-48.7-49.41A9 9 0 0 1 95.29 66.6L168 140ZM209 100.28l-3.75 3.72a1.21 1.21 0 0 0-.16-.16l-37.4-35.34a16.05 16.05 0 0 0-22.39.12l-12.93 12.67-12.94-13.06L164 24l.17.16 44.88 42.26a24 24 0 0 1-.08 33.86Z" /></Svg>;
export default PipeWrench;