/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Mouse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 18h-32a62.07 62.07 0 0 0-62 62v96a62.07 62.07 0 0 0 62 62h32a62.07 62.07 0 0 0 62-62V80a62.07 62.07 0 0 0-62-62Zm50 62v26h-60V30h10a50.06 50.06 0 0 1 50 50Zm-82-50h10v76H62V80a50.06 50.06 0 0 1 50-50Zm32 196h-32a50.06 50.06 0 0 1-50-50v-58h132v58a50.06 50.06 0 0 1-50 50Z" /></Svg>;
export default Mouse;