/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Basketball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="basketball__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-basketball'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24ZM60 72.17A87.2 87.2 0 0 1 79.63 120H40.37A87.54 87.54 0 0 1 60 72.17ZM136 120V40.37a87.59 87.59 0 0 1 48.68 20.37A103.06 103.06 0 0 0 160.3 120Zm-16 0H95.7a103.06 103.06 0 0 0-24.38-59.26A87.59 87.59 0 0 1 120 40.37Zm-40.37 16A87.2 87.2 0 0 1 60 183.83 87.54 87.54 0 0 1 40.37 136Zm16.07 0H120v79.63a87.59 87.59 0 0 1-48.68-20.37A103.09 103.09 0 0 0 95.7 136Zm40.3 0h24.3a103.09 103.09 0 0 0 24.38 59.26A87.59 87.59 0 0 1 136 215.63Zm40.37 0h39.26A87.54 87.54 0 0 1 196 183.83 87.2 87.2 0 0 1 176.37 136Zm0-16A87.2 87.2 0 0 1 196 72.17 87.54 87.54 0 0 1 215.63 120Z" /></Svg>;
export default Basketball;