/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bicycle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bicycle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bicycle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204 108a51.82 51.82 0 0 0-15.13 2.25L168.89 76H192a4 4 0 0 1 4 4 12 12 0 0 0 24 0 28 28 0 0 0-28-28h-44a12 12 0 0 0-10.37 18l8.14 14h-36.21L94.37 58A12 12 0 0 0 84 52H52a12 12 0 0 0 0 24h25.11l11.07 19L74 112.89a52.17 52.17 0 1 0 18.8 14.92l8.37-10.57L118 146.05A12 12 0 1 0 138.7 134l-15.14-26h36.21l8.39 14.38A52 52 0 1 0 204 108ZM80 160a28 28 0 1 1-21.71-27.28l-15.7 19.83a12 12 0 0 0 18.82 14.9l15.7-19.83A27.84 27.84 0 0 1 80 160Zm124 28a28 28 0 0 1-23.11-43.79l12.74 21.84A12 12 0 0 0 214.37 154l-12.75-21.84c.79-.07 1.58-.11 2.38-.11a28 28 0 0 1 0 56Z" /></Svg>;
export default Bicycle;