/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cake = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cake-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cake-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 112a28 28 0 0 0-28-28h-68v-2.06A36.07 36.07 0 0 0 164 48c0-31.06-29.38-46.11-30.63-46.73a12 12 0 0 0-10.74 0C121.38 1.89 92 16.94 92 48a36.07 36.07 0 0 0 24 33.94V84H48a28 28 0 0 0-28 28v23.33a44.7 44.7 0 0 0 8 25.49V200a28 28 0 0 0 28 28h144a28 28 0 0 0 28-28v-39.18a44.7 44.7 0 0 0 8-25.49ZM124 29.93a42.49 42.49 0 0 1 4-3.57 41.26 41.26 0 0 1 4 3.57c5.33 5.52 8 11.6 8 18.07a12 12 0 0 1-24 0c0-6.47 2.71-12.55 8-18.07ZM44 112a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4v23.33c0 11.1-8.72 20.37-19.43 20.66A20 20 0 0 1 172 136a12 12 0 0 0-24 0 20 20 0 0 1-40 0 12 12 0 0 0-24 0 20 20 0 0 1-20.57 20C52.72 155.7 44 146.43 44 135.33Zm156 92H56a4 4 0 0 1-4-4v-21.71A42.65 42.65 0 0 0 62.77 180a43.68 43.68 0 0 0 31.91-12.44c.46-.44.9-.9 1.33-1.35a44 44 0 0 0 64 0c.43.45.87.91 1.33 1.35A43.66 43.66 0 0 0 192 180h1.25a42.65 42.65 0 0 0 10.75-1.71V200a4 4 0 0 1-4 4Z" /></Svg>;
export default Cake;