/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-dashed-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-dashed-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a96 96 0 1 1-96-96 96 96 0 0 1 96 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M96.26 37a8 8 0 0 1 5.74-9.71 104.11 104.11 0 0 1 52 0 8 8 0 0 1-2 15.75 8.15 8.15 0 0 1-2-.26 88.09 88.09 0 0 0-44 0A8 8 0 0 1 96.26 37ZM53.79 55.14a104 104 0 0 0-26 45 8 8 0 1 0 15.42 4.26 87.91 87.91 0 0 1 22-38.08 8 8 0 0 0-11.42-11.18Zm-10.58 96.41a8 8 0 1 0-15.42 4.28 104 104 0 0 0 26 45 8 8 0 1 0 11.41-11.21 88.14 88.14 0 0 1-21.99-38.07ZM150 213.22a88 88 0 0 1-44 0 8 8 0 1 0-4 15.49 104.11 104.11 0 0 0 52 0 8 8 0 0 0-4-15.49ZM222.65 146a8 8 0 0 0-9.85 5.57 87.88 87.88 0 0 1-22 38.09 8 8 0 1 0 11.42 11.21 104.05 104.05 0 0 0 26-45 8 8 0 0 0-5.57-9.87Zm-9.86-41.54a8 8 0 0 0 15.42-4.28 104 104 0 0 0-26-45 8 8 0 1 0-11.41 11.21 88.14 88.14 0 0 1 21.99 38.06Z" /></Svg>;
export default CircleDashed;