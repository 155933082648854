/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Visor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="visor-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-visor-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M183.05 60H72a68 68 0 0 0-67.93 71.21c1.6 34.66 30 63.08 64.65 64.71 14.77.72 30-5.64 46.63-19.38a19.9 19.9 0 0 1 25.35 0C150.79 184.92 166.25 196 184 196a68 68 0 0 0 68-68.9c-.49-37-31.42-67.1-68.95-67.1Zm43.65 110.15A59.57 59.57 0 0 1 184 188c-15.17 0-29.07-10-38.2-17.58a27.88 27.88 0 0 0-35.55 0c-15 12.41-28.45 18.14-41.15 17.55a60.37 60.37 0 0 1-57-57.09A60 60 0 0 1 72 68h111.05c33.18 0 60.52 26.56 60.94 59.2a59.59 59.59 0 0 1-17.29 42.95ZM180 96a4 4 0 0 1-4 4H80a4 4 0 0 1 0-8h96a4 4 0 0 1 4 4Z" /></Svg>;
export default Visor;