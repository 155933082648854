/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MagnifyingGlassMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="magnifying-glass-minus-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-magnifying-glass-minus-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 112a80 80 0 1 1-80-80 80 80 0 0 1 80 80Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m229.66 218.34-50.06-50.06a88.21 88.21 0 1 0-11.32 11.31l50.06 50.07a8 8 0 0 0 11.32-11.32ZM40 112a72 72 0 1 1 72 72 72.08 72.08 0 0 1-72-72Zm112 0a8 8 0 0 1-8 8H80a8 8 0 0 1 0-16h64a8 8 0 0 1 8 8Z" /></Svg>;
export default MagnifyingGlassMinus;