/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rocket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rocket-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rocket-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 224a8 8 0 0 1-8 8h-32a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8Zm71.62-68.17-12.36 55.63a16 16 0 0 1-25.51 9.11L158.51 200h-61l-27.26 20.57a16 16 0 0 1-25.51-9.11l-12.36-55.63a16.09 16.09 0 0 1 3.32-13.71l28.56-34.26a123.07 123.07 0 0 1 8.57-36.67c12.9-32.34 36-52.63 45.37-59.85a16 16 0 0 1 19.6 0c9.34 7.22 32.47 27.51 45.37 59.85a123.07 123.07 0 0 1 8.57 36.67l28.56 34.26a16.09 16.09 0 0 1 3.32 13.71Zm-139.23 34q-16.11-29.33-19.56-57.67L48 152.36 60.36 208l.18-.13ZM140 100a12 12 0 1 0-12 12 12 12 0 0 0 12-12Zm68 52.36-16.83-20.2q-3.42 28.28-19.56 57.69l23.85 18 .18.13Z" /></Svg>;
export default Rocket;