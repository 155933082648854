/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SnapchatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="snapchat-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-snapchat-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243.92 183.1a4 4 0 0 0-2.56-3c-.13 0-13.52-5-26.69-19.76a99.18 99.18 0 0 1-20-35.54l22.83-9.13a4 4 0 1 0-3-7.42l-22.08 8.83A149.77 149.77 0 0 1 188 80a60 60 0 0 0-120 0 150.25 150.25 0 0 1-4.43 37.15l-22.08-8.83a4 4 0 1 0-3 7.42l22.82 9.13a99.16 99.16 0 0 1-20 35.54c-13.18 14.73-26.56 19.71-26.69 19.76a4 4 0 0 0-1.39 6.68c6.12 5.73 16 6.71 25.55 7.65 6.7.67 13.64 1.35 17.92 3.71s7.73 7.18 11.51 12.36c5.25 7.2 10.69 14.65 19 16.77 7.92 2 16.23-.8 24.26-3.54 5.88-2 11.43-3.89 16.5-3.89s10.63 1.89 16.5 3.89c6.13 2.09 12.42 4.23 18.57 4.23a22.92 22.92 0 0 0 5.7-.69c8.31-2.12 13.74-9.57 19-16.77 3.79-5.18 7.36-10.08 11.51-12.36s11.22-3 17.93-3.71c9.55-.94 19.43-1.92 25.54-7.65a4 4 0 0 0 1.2-3.75Zm-27.51 3.41c-7.51.75-15.27 1.51-21 4.66s-10 9-14.12 14.66c-4.62 6.33-9 12.32-14.51 13.73s-12.46-.89-19.71-3.36c-6.23-2.12-12.68-4.32-19.08-4.32s-12.84 2.2-19.08 4.32c-7.24 2.47-14.09 4.8-19.7 3.36s-9.89-7.4-14.52-13.73c-4.13-5.66-8.41-11.52-14.11-14.66s-13.49-3.91-21-4.66c-5.26-.52-10.63-1-14.91-2.37A90.17 90.17 0 0 0 47 166.08c8.1-9 17.14-22.5 23-41.85a1.51 1.51 0 0 0 0-.23 3.8 3.8 0 0 0 .23-.91A154.12 154.12 0 0 0 76 80a52 52 0 0 1 104 0 154.12 154.12 0 0 0 5.74 43.13 3.41 3.41 0 0 0 .24.91c0 .08.07.14.1.22 5.81 19.35 14.86 32.88 23 41.85a90.16 90.16 0 0 0 22.27 18.06c-4.35 1.29-9.68 1.83-14.94 2.34Z" /></Svg>;
export default SnapchatLogo;