/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246 80a26 26 0 1 0-46.6 15.84l-29.83 36.72-26-59.76a26 26 0 1 0-31.16 0l-26 59.76L56.6 95.84a26 26 0 1 0-22.36 10.09L49 194.3A14 14 0 0 0 62.78 206h130.44A14 14 0 0 0 207 194.3l14.73-88.37A26 26 0 0 0 246 80ZM128 38a14 14 0 1 1-14 14 14 14 0 0 1 14-14ZM22 80a14 14 0 1 1 14 14 14 14 0 0 1-14-14Zm173.2 112.33a2 2 0 0 1-2 1.67H62.78a2 2 0 0 1-2-1.67L46.08 104l1.22-.55 36 44.36A6 6 0 0 0 88 150a5.52 5.52 0 0 0 .81-.06 6 6 0 0 0 4.69-3.55l29.92-68.8a25.79 25.79 0 0 0 9.16 0l29.92 68.8a6 6 0 0 0 4.69 3.55 5.52 5.52 0 0 0 .81.06 6 6 0 0 0 4.66-2.22l36-44.36 1.22.55ZM220 94a14 14 0 1 1 14-14 14 14 0 0 1-14 14Z" /></Svg>;
export default Crown;