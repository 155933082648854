/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Towel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="towel-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-towel-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 26H72a22 22 0 0 0-22 22v168a14 14 0 0 0 14 14h112a14 14 0 0 0 14-14V48a10 10 0 0 1 20 0v104a6 6 0 0 0 12 0V48a22 22 0 0 0-22-22ZM72 38h108.41A21.84 21.84 0 0 0 178 48v138H62V48a10 10 0 0 1 10-10Zm104 180H64a2 2 0 0 1-2-2v-18h116v18a2 2 0 0 1-2 2Z" /></Svg>;
export default Towel;