/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-user__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-user'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 72v43.05l36.42-18.21a8 8 0 0 1 7.16 14.31l-48 24A8 8 0 0 1 120 128V72a8 8 0 0 1 16 0Zm-8 144a88 88 0 1 1 88-88 8 8 0 0 0 16 0 104 104 0 1 0-104 104 8 8 0 0 0 0-16Zm103.73 5.94a8 8 0 1 1-15.46 4.11C213.44 215.42 203.46 208 192 208s-21.44 7.42-24.27 18.05A8 8 0 0 1 160 232a8.15 8.15 0 0 1-2.06-.27 8 8 0 0 1-5.67-9.79 40 40 0 0 1 17.11-23.32 32 32 0 1 1 45.23 0 40 40 0 0 1 17.12 23.32ZM176 176a16 16 0 1 0 16-16 16 16 0 0 0-16 16Z" /></Svg>;
export default ClockUser;