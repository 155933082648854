/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sock = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sock-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sock-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 20h-88a12 12 0 0 0-12 12v78.34l-39.92 39.92a54.91 54.91 0 0 0 77.66 77.66l68.4-68.41a19.85 19.85 0 0 0 5.86-14.14V32a12 12 0 0 0-12-12Zm-88 8h88a4 4 0 0 1 4 4v20h-96V32a4 4 0 0 1 4-4Zm20.08 194.26a46.91 46.91 0 1 1-66.34-66.34l41.09-41.09A4 4 0 0 0 100 112V60h96v48.17A52.05 52.05 0 0 0 148 160a51.44 51.44 0 0 0 9 29.3Zm68.4-68.4-29.66 29.67A43.5 43.5 0 0 1 156 160a44.06 44.06 0 0 1 40-43.81v29.18a11.92 11.92 0 0 1-3.52 8.49Z" /></Svg>;
export default Sock;