/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Taxi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="taxi-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-taxi-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 108h-13.68l-28.54-49.95a12 12 0 0 0-10.42-6h-24.65l-13-32.46A12 12 0 0 0 138.58 12h-21.16a12 12 0 0 0-11.15 7.54L93.29 52H68.64a12 12 0 0 0-10.42 6l-28.54 50H16a4 4 0 0 0 0 8h12v84a12 12 0 0 0 12 12h24a12 12 0 0 0 12-12v-20h104v20a12 12 0 0 0 12 12h24a12 12 0 0 0 12-12v-84h12a4 4 0 0 0 0-8ZM113.7 22.51a4 4 0 0 1 3.72-2.51h21.16a4 4 0 0 1 3.72 2.51L154.09 52h-52.18ZM65.17 62a4 4 0 0 1 3.47-2h118.72a4 4 0 0 1 3.47 2l26.28 46H38.89ZM68 200a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4v-20h32Zm148 4h-24a4 4 0 0 1-4-4v-20h32v20a4 4 0 0 1-4 4Zm4-32H36v-56h184ZM60 144a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8H64a4 4 0 0 1-4-4Zm112 0a4 4 0 0 1 4-4h16a4 4 0 0 1 0 8h-16a4 4 0 0 1-4-4Z" /></Svg>;
export default Taxi;