/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldCheckered = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-checkered-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-checkered-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40H48a16 16 0 0 0-16 16v56c0 52.72 25.52 84.67 46.93 102.19 23.06 18.86 46 25.27 47 25.53a8 8 0 0 0 4.2 0c1-.26 23.91-6.67 47-25.53C198.48 196.67 224 164.72 224 112V56a16 16 0 0 0-16-16Zm-80 183.62a128.25 128.25 0 0 1-38.92-21.81C65.83 182.79 52.37 158 48.9 128H128V56h80v56a141.24 141.24 0 0 1-.9 16H128v95.62Z" /></Svg>;
export default ShieldCheckered;