/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-check-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-check-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.68 106.08a6 6 0 0 0-4.92 6.91A91.66 91.66 0 0 1 218 128a89.65 89.65 0 0 1-24.49 61.64 77.53 77.53 0 0 0-40-31.38 46 46 0 1 0-51 0 77.53 77.53 0 0 0-40 31.38A89.95 89.95 0 0 1 128 38a91.57 91.57 0 0 1 15 1.24 6 6 0 1 0 2-11.83 101.9 101.9 0 1 0 83.6 83.6 6 6 0 0 0-6.92-4.93ZM94 120a34 34 0 1 1 34 34 34 34 0 0 1-34-34Zm-22.56 78a66 66 0 0 1 113.12 0 89.8 89.8 0 0 1-113.12 0Zm164.8-153.76-32 32a6 6 0 0 1-8.48 0l-16-16a6 6 0 0 1 8.48-8.48L200 63.51l27.76-27.75a6 6 0 0 1 8.48 8.48Z" /></Svg>;
export default UserCircleCheck;