/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CoatHanger = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coat-hanger-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coat-hanger-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.18 174.4 134.67 96l27.72-20.8A4 4 0 0 0 164 72a36 36 0 1 0-72 0 4 4 0 0 0 8 0 28 28 0 0 1 55.92-2l-30.26 22.7-.14.1-108.7 81.6A12 12 0 0 0 24 196h208a12 12 0 0 0 7.2-21.6Zm-3.41 10.87A3.88 3.88 0 0 1 232 188H24a4 4 0 0 1-2.4-7.2L128 101l106.38 79.8a3.88 3.88 0 0 1 1.39 4.47Z" /></Svg>;
export default CoatHanger;