/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSubscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-subscript-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-subscript-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246 208a6 6 0 0 1-6 6h-48a6 6 0 0 1-4.8-9.6l43.17-57.56A18 18 0 1 0 199 130a6 6 0 1 1-11.31-4A30 30 0 1 1 240 154.06L204 202h36a6 6 0 0 1 6 6ZM147.93 51.47a6 6 0 0 0-8.46.6L92 106.84 44.53 52.07a6 6 0 1 0-9.06 7.86L84.06 116l-48.59 56.07a6 6 0 1 0 9.06 7.86L92 125.16l47.47 54.77a6 6 0 0 0 9.06-7.86L99.94 116l48.59-56.07a6 6 0 0 0-.6-8.46Z" /></Svg>;
export default TextSubscript;