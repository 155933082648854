/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FloppyDiskBack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="floppy-disk-back-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-floppy-disk-back-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40h-32v40a8 8 0 0 1-8 8H88a8 8 0 0 1-8-8V40.73a8 8 0 0 0-2.34 1.61L42.34 77.66A8 8 0 0 0 40 83.31V208a8 8 0 0 0 8 8h160a8 8 0 0 0 8-8V48a8 8 0 0 0-8-8Zm-80 144a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 32H83.31A15.86 15.86 0 0 0 72 36.69L36.69 72A15.86 15.86 0 0 0 32 83.31V208a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM88 48h80v32H88Zm120 160H48V83.31l24-24V80a16 16 0 0 0 16 16h80a16 16 0 0 0 16-16V48h24Zm-80-96a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 64a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default FloppyDiskBack;