/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugsConnected = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plugs-connected-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plugs-connected-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240.49 15.51a12 12 0 0 0-17 0l-49.55 49.58-2.54-2.55a36.05 36.05 0 0 0-50.91 0L100 83l-3.51-3.52a12 12 0 0 0-17 17L83 100l-20.46 20.49a36 36 0 0 0 0 50.91l2.55 2.54-49.58 49.57a12 12 0 0 0 17 17l49.57-49.58 2.54 2.55a36.06 36.06 0 0 0 50.91 0L156 173l3.51 3.52a12 12 0 0 0 17-17L173 156l20.49-20.49a36 36 0 0 0 0-50.91l-2.55-2.54 49.58-49.57a12 12 0 0 0-.03-16.98Zm-121.95 161a12 12 0 0 1-17 0l-22.03-22.08a12 12 0 0 1 0-17L100 117l39 39Zm58-57.95L156 139l-39-39 20.49-20.49a12 12 0 0 1 17 0l22.06 22.06a12 12 0 0 1 0 17ZM85.27 33.37a12 12 0 0 1 21.46-10.74l8 16a12 12 0 1 1-21.46 10.74Zm-68 57.26a12 12 0 0 1 16.1-5.36l16 8a12 12 0 1 1-10.74 21.46l-16-8a12 12 0 0 1-5.36-16.1Zm221.46 74.74a12 12 0 0 1-16.1 5.36l-16-8a12 12 0 0 1 10.74-21.46l16 8a12 12 0 0 1 5.36 16.1Zm-68 57.26a12 12 0 1 1-21.46 10.74l-8-16a12 12 0 0 1 21.46-10.74Z" /></Svg>;
export default PlugsConnected;