/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Coin = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coin-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coin-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M209.37 60.27C188.08 49.62 160 44 128 44s-60.08 5.62-81.37 16.27C24.62 71.27 12 87.21 12 104v48c0 16.79 12.62 32.73 34.63 43.73C67.92 206.38 96.05 212 128 212s60.08-5.62 81.37-16.27c22-11 34.63-26.94 34.63-43.73v-48c0-16.79-12.62-32.73-34.63-43.73Zm-152 21.46C75.08 72.88 100.16 68 128 68s52.92 4.88 70.63 13.73C211.81 88.32 220 96.86 220 104s-8.19 15.68-21.37 22.27C180.92 135.12 155.84 140 128 140s-52.92-4.88-70.63-13.73C44.19 119.68 36 111.14 36 104s8.19-15.68 21.37-22.27ZM180 181.38a180.38 180.38 0 0 1-40 6.3v-24a210.39 210.39 0 0 0 40-5.51ZM76 158.22a210.39 210.39 0 0 0 40 5.51v24a180.38 180.38 0 0 1-40-6.3ZM36 152v-10.46a94.54 94.54 0 0 0 10.63 6.19c1.74.87 3.54 1.7 5.37 2.5v21.07c-10-6.06-16-13.19-16-19.3Zm168 19.3v-21.07c1.83-.8 3.63-1.63 5.37-2.5a94.54 94.54 0 0 0 10.63-6.19V152c0 6.11-6 13.24-16 19.3Z" /></Svg>;
export default Coin;