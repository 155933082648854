/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GooglePlayLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-play-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-play-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.84 115.93 71 19.89a14 14 0 0 0-14.12 0A13.68 13.68 0 0 0 50 31.87v192.26a13.68 13.68 0 0 0 6.92 11.94 14 14 0 0 0 14.12 0l167.8-96a13.75 13.75 0 0 0 0-24.14ZM62 217.5v-179l89.51 89.5Zm98-81 22.19 22.19L78.4 218.07ZM78.4 37.93l103.79 59.4L160 119.52ZM233 129.58l-.1.06-39.9 22.85L168.49 128 193 103.51l39.94 22.85.1.06a1.76 1.76 0 0 1 0 3.16Z" /></Svg>;
export default GooglePlayLogo;