/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareEight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-eight-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-eight-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M112 100a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm16 32a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm96-84v160a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Zm-60 104a35.93 35.93 0 0 0-14.19-28.61 32 32 0 1 0-43.62 0A36 36 0 1 0 164 152Z" /></Svg>;
export default NumberSquareEight;