/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Racquet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="racquet-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-racquet-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.7 23.3C218.05 8.66 197.43 2 174.62 4.53c-21.81 2.42-43.15 13.08-60.09 30s-27.59 38.27-30 60.09c-2 18.33 1.89 35.24 11.1 48.78l-20.3 20.31-1.86-1.86a20 20 0 0 0-28.28 0l-31.33 31.33a20 20 0 0 0 0 28.29l20.68 20.68a20 20 0 0 0 28.29 0l31.31-31.31a20 20 0 0 0 0-28.29l-1.86-1.85 20.31-20.31C123.78 168 137.28 172 152 172a83.09 83.09 0 0 0 9.38-.53c21.81-2.42 43.15-13.08 60.08-30s27.6-38.27 30-60.09C254 58.57 247.34 38 232.7 23.3Zm-184 199-15-15 25.65-25.65 15 15Zm79.3-80.54A39.33 39.33 0 0 1 114.25 128H128Zm-20-37.75a68.85 68.85 0 0 1 8.38-32H128v32ZM152 72h32v32h-32Zm56 32V72h20a68.68 68.68 0 0 1-8.37 32Zm13.75-56H208V34.26A39.47 39.47 0 0 1 221.74 48Zm-37.93-20h.18v20h-32V36.34c10.47-5.52 21.48-8.34 31.82-8.34ZM152 148.06V128h32v11.67a68.7 68.7 0 0 1-32 8.39Z" /></Svg>;
export default Racquet;