/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GithubLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="github-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-github-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M203.94 76.16A55.73 55.73 0 0 0 199.46 30a4 4 0 0 0-3.46-2 55.78 55.78 0 0 0-46 24h-28a55.78 55.78 0 0 0-46-24 4 4 0 0 0-3.46 2 55.73 55.73 0 0 0-4.48 46.16A53.78 53.78 0 0 0 60 104v8a52.06 52.06 0 0 0 52 52h1.41A36 36 0 0 0 100 192v12H72a28 28 0 0 1-28-28 36 36 0 0 0-36-36 4 4 0 0 0 0 8 28 28 0 0 1 28 28 36 36 0 0 0 36 36h28v20a4 4 0 0 0 8 0v-40a28 28 0 0 1 56 0v40a4 4 0 0 0 8 0v-40a36 36 0 0 0-13.41-28H160a52.06 52.06 0 0 0 52-52v-8a53.78 53.78 0 0 0-8.06-27.84ZM204 112a44.05 44.05 0 0 1-44 44h-48a44.05 44.05 0 0 1-44-44v-8a45.76 45.76 0 0 1 7.71-24.89 4 4 0 0 0 .53-3.84 47.82 47.82 0 0 1 2.1-39.21 47.8 47.8 0 0 1 38.12 22.1 4 4 0 0 0 3.37 1.84h32.34a4 4 0 0 0 3.37-1.84 47.8 47.8 0 0 1 38.12-22.1 47.82 47.82 0 0 1 2.1 39.21 4 4 0 0 0 .53 3.83A45.85 45.85 0 0 1 204 104Z" /></Svg>;
export default GithubLogo;