/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Voicemail = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="voicemail__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-voicemail'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 72a56 56 0 0 0-39.14 96H95.14A56 56 0 1 0 56 184h144a56 56 0 0 0 0-112ZM16 128a40 40 0 1 1 40 40 40 40 0 0 1-40-40Zm184 40a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" /></Svg>;
export default Voicemail;