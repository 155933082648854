/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleGear = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-gear-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-gear-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a95.76 95.76 0 0 1-31.8 71.37A72 72 0 0 0 128 160a40 40 0 1 0-40-40 40 40 0 0 0 40 40 72 72 0 0 0-64.2 39.37A96 96 0 0 1 184.92 50.69a16 16 0 0 0 20.39 20.39A95.61 95.61 0 0 1 224 128Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m228.25 63.07-4.66-2.69a23.6 23.6 0 0 0 0-8.76l4.66-2.69a8 8 0 0 0-8-13.86l-4.67 2.7a23.92 23.92 0 0 0-7.58-4.39V28a8 8 0 0 0-16 0v5.38a23.92 23.92 0 0 0-7.58 4.39l-4.67-2.7a8 8 0 1 0-8 13.86l4.66 2.69a23.6 23.6 0 0 0 0 8.76l-4.66 2.69a8 8 0 0 0 4 14.93 7.92 7.92 0 0 0 4-1.07l4.67-2.7a23.92 23.92 0 0 0 7.58 4.39V84a8 8 0 0 0 16 0v-5.38a23.92 23.92 0 0 0 7.58-4.39l4.67 2.7a7.92 7.92 0 0 0 4 1.07 8 8 0 0 0 4-14.93ZM192 56a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm29.35 48.11a8 8 0 0 0-6.57 9.21A88.85 88.85 0 0 1 216 128a87.62 87.62 0 0 1-22.24 58.41 79.66 79.66 0 0 0-36.06-28.75 48 48 0 1 0-59.4 0 79.66 79.66 0 0 0-36.06 28.75A88 88 0 0 1 128 40a88.76 88.76 0 0 1 14.68 1.22 8 8 0 0 0 2.64-15.78 103.92 103.92 0 1 0 85.24 85.24 8 8 0 0 0-9.21-6.57ZM96 120a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm-21.92 77.5a64 64 0 0 1 107.84 0 87.83 87.83 0 0 1-107.84 0Z" /></Svg>;
export default UserCircleGear;