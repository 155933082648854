/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popcorn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popcorn-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popcorn-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.14 75.66a6 6 0 0 0-5.79-1.43l-1.41.4a42 42 0 0 0-54.07-30.52 42 42 0 0 0-77.74 0 42 42 0 0 0-54.07 30.52l-1.41-.4a6 6 0 0 0-7.49 7.14l30.55 129.84A14 14 0 0 0 70.34 222h115.32a14 14 0 0 0 13.63-10.79l30.55-129.84a6 6 0 0 0-1.7-5.71ZM76 54a29.54 29.54 0 0 1 14 3.49 6 6 0 0 0 8.68-4 30 30 0 0 1 58.56 0 6 6 0 0 0 8.67 4A29.6 29.6 0 0 1 180 54a30 30 0 0 1 29.39 23.94l-41.07 11.73-35.12-14a14 14 0 0 0-10.4 0l-35.12 14-41.07-11.73A30 30 0 0 1 76 54Zm85.44 45.84L146.75 210h-37.5L94.56 99.84l32.7-13.08a2 2 0 0 1 1.48 0Zm-93 108.62L40.18 88.58l42.39 12.11L97.15 210H70.34a2 2 0 0 1-1.95-1.54Zm119.22 0a2 2 0 0 1-2 1.54h-26.81l14.58-109.31 42.39-12.11Z" /></Svg>;
export default Popcorn;