/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryVerticalHigh = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-vertical-high-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-vertical-high-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M90 8a6 6 0 0 1 6-6h64a6 6 0 0 1 0 12H96a6 6 0 0 1-6-6Zm108 48v168a22 22 0 0 1-22 22H80a22 22 0 0 1-22-22V56a22 22 0 0 1 22-22h96a22 22 0 0 1 22 22Zm-12 0a10 10 0 0 0-10-10H80a10 10 0 0 0-10 10v168a10 10 0 0 0 10 10h96a10 10 0 0 0 10-10Zm-26 58H96a6 6 0 0 0 0 12h64a6 6 0 0 0 0-12Zm0 40H96a6 6 0 0 0 0 12h64a6 6 0 0 0 0-12Zm0 40H96a6 6 0 0 0 0 12h64a6 6 0 0 0 0-12Z" /></Svg>;
export default BatteryVerticalHigh;