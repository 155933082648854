/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Umbrella = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="umbrella-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-umbrella-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238 126.79A110.43 110.43 0 0 0 53.11 55.22a109.51 109.51 0 0 0-35.06 71.57A14 14 0 0 0 32 142h90v58a30 30 0 0 0 60 0 6 6 0 0 0-12 0 18 18 0 0 1-36 0v-58h90a14 14 0 0 0 14-15.21ZM94.11 130c1.69-51.21 24.7-80.16 33.89-89.73 9.2 9.58 32.2 38.53 33.89 89.73Zm-63.57-.65a2 2 0 0 1-.53-1.56 98.14 98.14 0 0 1 82.91-88.62c-12 15-29.43 44.44-30.83 90.83H32a2 2 0 0 1-1.46-.65Zm194.92 0a2 2 0 0 1-1.46.65h-50.09c-1.4-46.39-18.81-75.87-30.83-90.83A98.14 98.14 0 0 1 226 127.79a2 2 0 0 1-.54 1.56Z" /></Svg>;
export default Umbrella;