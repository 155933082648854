/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SteeringWheel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="steering-wheel-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-steering-wheel-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26ZM46.43 166h44a2 2 0 0 1 1.87 1.3l18.33 49a90.3 90.3 0 0 1-64.2-50.3Zm98.87 50.32 18.37-49a2 2 0 0 1 1.88-1.3h44a90.29 90.29 0 0 1-64.25 50.3ZM214.17 154h-48.62a14 14 0 0 0-13.11 9.09l-20.55 54.82c-1.29.06-2.59.09-3.89.09s-2.63 0-3.94-.09l-20.5-54.81a14.06 14.06 0 0 0-13.11-9.1H41.83c-.65-2.17-1.23-4.37-1.72-6.61a122 122 0 0 1 175.78 0c-.49 2.24-1.07 4.44-1.72 6.61ZM128 98a134.38 134.38 0 0 0-89.88 34.64C38 131.1 38 129.56 38 128a90 90 0 0 1 180 0c0 1.56 0 3.1-.12 4.64A134.38 134.38 0 0 0 128 98Zm10 42a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default SteeringWheel;