/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-half-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-half-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm12 24.87a83.53 83.53 0 0 1 24 7.25v151.76a83.53 83.53 0 0 1-24 7.25ZM44 128a84.12 84.12 0 0 1 72-83.13v166.26A84.12 84.12 0 0 1 44 128Zm144 58.71V69.29a83.81 83.81 0 0 1 0 117.42Z" /></Svg>;
export default CircleHalf;