/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhosphorLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phosphor-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phosphor-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 104a76.08 76.08 0 0 0-76-76H72a12 12 0 0 0-12 12v128a84.09 84.09 0 0 0 84 84 12 12 0 0 0 12-12v-60.11A76.09 76.09 0 0 0 228 104ZM84 85.81 123.48 156H84Zm48 36.38L92.52 52H132ZM85.22 180H132v46.79A60.18 60.18 0 0 1 85.22 180ZM156 155.83V52.17a52 52 0 0 1 0 103.66Z" /></Svg>;
export default PhosphorLogo;