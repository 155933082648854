/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MedalMilitary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="medal-military-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-medal-military-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M207 42H49a15 15 0 0 0-15 15v49.21a15 15 0 0 0 8.79 13.65l66.4 30.14a46 46 0 1 0 37.62 0l66.4-30.18a15 15 0 0 0 8.79-13.61V57a15 15 0 0 0-15-15Zm-45 12v76l-34 15.45L94 130V54ZM46 106.21V57a3 3 0 0 1 3-3h33v70.5l-34.24-15.56a3 3 0 0 1-1.76-2.73ZM162 192a34 34 0 1 1-34-34 34 34 0 0 1 34 34Zm48-85.79a3 3 0 0 1-1.76 2.73L174 124.5V54h33a3 3 0 0 1 3 3Z" /></Svg>;
export default MedalMilitary;