/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GooglePhotosLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-photos-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-photos-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 122h-42.82A70 70 0 0 0 128 18a6 6 0 0 0-6 6v42.82A70 70 0 0 0 18 128a6 6 0 0 0 6 6h42.82A70 70 0 0 0 128 238a6 6 0 0 0 6-6v-42.82A70 70 0 0 0 238 128a6 6 0 0 0-6-6Zm-46-34a57.3 57.3 0 0 1-11 34h-41V30.31A58.08 58.08 0 0 1 186 88ZM88 70a57.3 57.3 0 0 1 34 11v41H30.31A58.08 58.08 0 0 1 88 70Zm-18 98a57.3 57.3 0 0 1 11-34h41v91.69A58.08 58.08 0 0 1 70 168Zm98 18a57.3 57.3 0 0 1-34-11v-41h91.69A58.08 58.08 0 0 1 168 186Z" /></Svg>;
export default GooglePhotosLogo;