/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneOutgoing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-outgoing__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-outgoing'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M146.34 109.66a8 8 0 0 1 0-11.32L180.69 64H160a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8v40a8 8 0 0 1-16 0V75.31l-34.34 34.35a8 8 0 0 1-11.32 0Zm77.54 73.42A56.26 56.26 0 0 1 168 232C88.6 232 24 167.4 24 88a56.26 56.26 0 0 1 48.92-55.88 16 16 0 0 1 16.62 9.51l21.12 47.16v.12a16 16 0 0 1-1.27 15.09c-.18.27-.37.52-.57.77L88 129.45c7.49 15.22 23.41 31 38.83 38.51l24.34-20.71a8.12 8.12 0 0 1 .75-.56 15.93 15.93 0 0 1 15.17-1.4l.13.06 47.11 21.11a16 16 0 0 1 9.55 16.62Zm-15.88-2h-.11l-47-21.06-24.35 20.72a8.44 8.44 0 0 1-.74.56 16 16 0 0 1-15.75 1.14c-18.73-9.05-37.4-27.58-46.46-46.11a16 16 0 0 1 1-15.7 6.13 6.13 0 0 1 .57-.77L96 95.15l-21-47a.61.61 0 0 1 0-.12A40.2 40.2 0 0 0 40 88a128.14 128.14 0 0 0 128 128 40.21 40.21 0 0 0 40-34.93Z" /></Svg>;
export default PhoneOutgoing;