/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bluetooth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M195.6 171.2 138 128l57.6-43.2a6 6 0 0 0 0-9.6l-64-48A6 6 0 0 0 122 32v84L67.6 75.2a6 6 0 0 0-7.2 9.6L118 128l-57.6 43.2a6 6 0 1 0 7.2 9.6L122 140v84a6 6 0 0 0 9.6 4.8l64-48a6 6 0 0 0 0-9.6ZM134 44l48 36-48 36Zm0 168v-72l48 36Z" /></Svg>;
export default Bluetooth;