/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatDots = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-dots-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-dots-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 128a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-52-8a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm88 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm56-56v128a12 12 0 0 1-12 12H81.49l-33.73 29.13A11.89 11.89 0 0 1 40 236a12.17 12.17 0 0 1-5.1-1.14A11.89 11.89 0 0 1 28 224V64a12 12 0 0 1 12-12h176a12 12 0 0 1 12 12Zm-8 0a4 4 0 0 0-4-4H40a4 4 0 0 0-4 4v160a4 4 0 0 0 6.56 3.08L77.38 197a4 4 0 0 1 2.62-1h136a4 4 0 0 0 4-4Z" /></Svg>;
export default ChatDots;