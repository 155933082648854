/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BeachBall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beach-ball-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beach-ball-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm84 108a84.26 84.26 0 0 1-1.32 14.74 204.79 204.79 0 0 0-18.52-31.49 176.78 176.78 0 0 1 19.08 5.62A85 85 0 0 1 212 128Zm-9.61-38.94a204.25 204.25 0 0 0-30-5.47 204.25 204.25 0 0 0-5.47-30 84.53 84.53 0 0 1 35.47 35.47Zm-63.26-44.3a176.78 176.78 0 0 1 5.62 19.08 204.79 204.79 0 0 0-31.49-18.52 82.49 82.49 0 0 1 25.87-.56Zm-58.06 13.6a179.84 179.84 0 0 1 49.8 25.06 204.19 204.19 0 0 0-85.23 28.05 84.23 84.23 0 0 1 35.43-53.11ZM45 140.65a180.19 180.19 0 0 1 104.62-34.22 180.19 180.19 0 0 1-34.22 104.62 84.22 84.22 0 0 1-70.4-70.4Zm99.58 69.71a204.19 204.19 0 0 0 28.05-85.23 179.84 179.84 0 0 1 25.06 49.8 84.23 84.23 0 0 1-53.16 35.43Z" /></Svg>;
export default BeachBall;