/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BugBeetle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bug-beetle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bug-beetle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 160a12 12 0 0 0 0-24h-12v-16h12a12 12 0 0 0 0-24h-13.55a83.7 83.7 0 0 0-18.78-38.7l16.82-16.81a12 12 0 1 0-17-17l-18 18a83.7 83.7 0 0 0-91.1 0l-18-18a12 12 0 0 0-17 17L64.33 57.3A83.7 83.7 0 0 0 45.55 96H32a12 12 0 0 0 0 24h12v16H32a12 12 0 0 0 0 24h12a83.55 83.55 0 0 0 1.55 16H32a12 12 0 0 0 0 24h22.15a84 84 0 0 0 147.7 0H224a12 12 0 0 0 0-24h-13.55a83.55 83.55 0 0 0 1.55-16ZM128 52a60.1 60.1 0 0 1 57.82 44H70.18A60.1 60.1 0 0 1 128 52Zm12 166.79V148a12 12 0 0 0-24 0v70.79A60.09 60.09 0 0 1 68 160v-40h120v40a60.09 60.09 0 0 1-48 58.79Z" /></Svg>;
export default BugBeetle;