/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bone-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bone-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.12 107.72a35.91 35.91 0 0 1-46.19 6.8.14.14 0 0 0-.1 0l-70.35 70.36v.08a36 36 0 1 1-66.37 22.92 36 36 0 1 1 22.92-66.37.14.14 0 0 0 .1 0l70.35-70.36v-.08a36 36 0 1 1 66.37-22.92 36 36 0 0 1 23.27 59.57Z" /></Svg>;
export default Bone;