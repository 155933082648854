/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Power = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="power-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-power-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104 104 0 0 0 128 24Zm-8 40a8 8 0 0 1 16 0v64a8 8 0 0 1-16 0Zm8 144A80 80 0 0 1 83.55 61.48a8 8 0 1 1 8.9 13.29 64 64 0 1 0 71.1 0 8 8 0 1 1 8.9-13.29A80 80 0 0 1 128 208Z" /></Svg>;
export default Power;