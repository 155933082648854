/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CellTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cell-tower-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cell-tower-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M131.58 86.21a4 4 0 0 0-7.16 0l-72 144a4 4 0 0 0 7.16 3.58L74.47 204h107.06l14.89 29.79A4 4 0 0 0 200 236a4.12 4.12 0 0 0 1.79-.42 4 4 0 0 0 1.79-5.37ZM128 96.94 161.53 164H94.47ZM78.47 196l12-24h75.06l12 24Zm84.95-101.55A35.57 35.57 0 0 0 164 88a36 36 0 0 0-72 0 35.57 35.57 0 0 0 .58 6.45 4 4 0 0 1-7.88 1.43A45.18 45.18 0 0 1 84 88a44 44 0 0 1 88 0 45.18 45.18 0 0 1-.7 7.88 4 4 0 0 1-3.94 3.29 4.38 4.38 0 0 1-.71-.07 4 4 0 0 1-3.23-4.65Zm-96.08 39.34a76 76 0 1 1 121.32 0 4 4 0 1 1-6.38-4.82 68 68 0 1 0-108.56 0 4 4 0 1 1-6.38 4.82Z" /></Svg>;
export default CellTower;