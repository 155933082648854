/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Meteor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="meteor-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-meteor-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 160a32 32 0 1 1-32-32 32 32 0 0 1 32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M96 120a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 64a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm125.66-61.66a8 8 0 0 1 0 11.32l-48 48a8 8 0 0 1-11.32-11.32l48-48a8 8 0 0 1 11.32 0Zm-56 11.32a8 8 0 0 1-11.32-11.32l24-24a8 8 0 0 1 11.32 11.32Zm64-64-16 16a8 8 0 0 1-11.32-11.32l16-16a8 8 0 0 1 11.32 11.32ZM122.34 90.34l72-72a8 8 0 1 1 11.32 11.32l-72 72a8 8 0 0 1-11.32-11.32Zm24.57 109.26a8 8 0 0 1 0 11.31A72 72 0 1 1 45.09 109.09l82.74-82.75a8 8 0 1 1 11.32 11.32L56.4 120.4a56 56 0 0 0 79.2 79.2 8 8 0 0 1 11.31 0Z" /></Svg>;
export default Meteor;