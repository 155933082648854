/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const XLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="x-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-x-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M215 219.85a8 8 0 0 1-7 4.15h-48a8 8 0 0 1-6.75-3.71l-40.49-63.63-58.84 64.72a8 8 0 0 1-11.84-10.76l61.77-68-62.6-98.32A8 8 0 0 1 48 32h48a8 8 0 0 1 6.75 3.71l40.49 63.63 58.84-64.72a8 8 0 0 1 11.84 10.76l-61.77 67.95 62.6 98.38a8 8 0 0 1 .25 8.14Z" /></Svg>;
export default XLogo;