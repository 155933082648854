/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Signpost = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="signpost-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-signpost-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m240 112-33.62 37.35a8 8 0 0 1-5.94 2.65H40a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8h160.44a8 8 0 0 1 5.94 2.65Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M246 106.65 212.33 69.3a16 16 0 0 0-11.89-5.3H136V32a8 8 0 0 0-16 0v32H40a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h80v64a8 8 0 0 0 16 0v-64h64.44a16 16 0 0 0 11.89-5.3L246 117.35a8 8 0 0 0 0-10.7ZM200.44 144H40V80h160.44l28.8 32Z" /></Svg>;
export default Signpost;