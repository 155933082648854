/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rocket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rocket-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rocket-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 228a12 12 0 0 1-12 12h-32a12 12 0 0 1 0-24h32a12 12 0 0 1 12 12Zm-28-112a16 16 0 1 0-16-16 16 16 0 0 0 16 16Zm99.53 40.7-12.36 55.63a19.9 19.9 0 0 1-12.88 14.53 20.16 20.16 0 0 1-6.69 1.14 19.87 19.87 0 0 1-12.29-4.27L157.17 204H98.83l-26.14 19.74A19.87 19.87 0 0 1 60.4 228a20.16 20.16 0 0 1-6.69-1.15 19.9 19.9 0 0 1-12.88-14.53L28.47 156.7a20.1 20.1 0 0 1 4.16-17.14l27.83-33.4a127 127 0 0 1 8.65-36.46c13.27-33.25 37-54.1 46.64-61.52a20 20 0 0 1 24.5 0c9.6 7.42 33.37 28.27 46.64 61.52a127 127 0 0 1 8.65 36.46l27.83 33.4a20.1 20.1 0 0 1 4.16 17.14ZM101.79 180h52.42c19.51-35.7 23-69.78 10.39-101.4C154.4 53 136.2 35.9 128 29.12 119.8 35.9 101.6 53 91.4 78.6c-12.62 31.62-9.12 65.7 10.39 101.4Zm-22.55 8.72a168 168 0 0 1-16.92-47.3l-10 12 10.58 47.64Zm124.43-35.31-10-12a168 168 0 0 1-16.92 47.3l16.33 12.33Z" /></Svg>;
export default Rocket;