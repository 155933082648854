/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileIni = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-ini-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-ini-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M46 152v56a6 6 0 0 1-12 0v-56a6 6 0 0 1 12 0Zm74-6a6 6 0 0 0-6 6v37.28l-29.12-40.77A6 6 0 0 0 74 152v56a6 6 0 0 0 12 0v-37.28l29.12 40.77A6 6 0 0 0 120 214a5.78 5.78 0 0 0 1.83-.29A6 6 0 0 0 126 208v-56a6 6 0 0 0-6-6Zm40 0a6 6 0 0 0-6 6v56a6 6 0 0 0 12 0v-56a6 6 0 0 0-6-6Zm54-58v136a6 6 0 0 1-12 0V94h-50a6 6 0 0 1-6-6V38H56a2 2 0 0 0-2 2v72a6 6 0 0 1-12 0V40a14 14 0 0 1 14-14h96a6 6 0 0 1 4.25 1.76l56 56A6 6 0 0 1 214 88Zm-56-6h35.52L158 46.48Z" /></Svg>;
export default FileIni;