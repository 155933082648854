/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSuperscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-superscript-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-superscript-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 144a12 12 0 0 1-12 12h-48a12 12 0 0 1-9.6-19.2l43.17-57.55A12 12 0 1 0 204.68 68a12 12 0 0 1-22.63-8 36.24 36.24 0 0 1 5.2-9.66 36 36 0 0 1 57.5 43.33L216 132h24a12 12 0 0 1 12 12ZM151.86 70.94a12 12 0 0 0-16.93 1.2L92 121.68 49.07 72.14a12 12 0 0 0-18.14 15.72L76.12 140l-45.19 52.14a12 12 0 0 0 18.14 15.72L92 158.32l42.93 49.54a12 12 0 1 0 18.14-15.72L107.88 140l45.19-52.14a12 12 0 0 0-1.21-16.92Z" /></Svg>;
export default TextSuperscript;