/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Notification = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="notification-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-notification-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 128v80a12 12 0 0 1-12 12H48a12 12 0 0 1-12-12V56a12 12 0 0 1 12-12h80a4 4 0 0 1 0 8H48a4 4 0 0 0-4 4v152a4 4 0 0 0 4 4h152a4 4 0 0 0 4-4v-80a4 4 0 0 1 8 0Zm16-68a32 32 0 1 1-32-32 32 32 0 0 1 32 32Zm-8 0a24 24 0 1 0-24 24 24 24 0 0 0 24-24Z" /></Svg>;
export default Notification;