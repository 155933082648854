/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VideoConference = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="video-conference-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-video-conference-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204 88a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-12 68a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm-96-52a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm136-48v144a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16Zm-64 64h48V56h-48Zm-32.25 46a39.76 39.76 0 0 0-17.19-23.34 32 32 0 1 0-45.12 0A39.84 39.84 0 0 0 56.25 166a8 8 0 0 0 15.5 4c2.64-10.25 13.06-18 24.25-18s21.62 7.73 24.25 18a8 8 0 1 0 15.5-4ZM216 200v-64h-48v64h48Z" /></Svg>;
export default VideoConference;