/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HourglassSimpleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hourglass-simple-medium-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hourglass-simple-medium-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m133.78 128 74.68-71.51A12 12 0 0 0 200 36H56a12 12 0 0 0-8.49 20.49l.07.06L122.22 128l-74.61 71.45-.07.06A12 12 0 0 0 56 220h144a12 12 0 0 0 8.42-20.55ZM87.84 84h80.32L128 122.46ZM52.33 46.47A3.93 3.93 0 0 1 56 44h144a4 4 0 0 1 2.89 6.77L176.51 76h-97L53.17 50.8a3.92 3.92 0 0 1-.84-4.33Zm151.34 163.06A3.93 3.93 0 0 1 200 212H56a4 4 0 0 1-2.86-6.8L124 137.37V168a4 4 0 0 0 8 0v-30.63l70.8 67.8a3.93 3.93 0 0 1 .87 4.36Z" /></Svg>;
export default HourglassSimpleMedium;