/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EyeSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eye-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eye-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 0 0-6 5.38L67.59 67.5C29.34 89 13 124.81 12.34 126.38a4.08 4.08 0 0 0 0 3.25c.34.77 8.52 18.89 26.83 37.2 17 17 46.14 37.17 88.83 37.17a122.59 122.59 0 0 0 53.06-11.69l24 26.38a4 4 0 1 0 5.92-5.38Zm98.1 119.85a36 36 0 0 1-48.1-52.94ZM128 196c-32 0-59.89-11.65-83-34.62A135.81 135.81 0 0 1 20.44 128c3.65-7.23 20.09-36.81 52.68-54.43l22.45 24.7a44 44 0 0 0 59 64.83l20.89 23A114.94 114.94 0 0 1 128 196Zm6.78-103.36a4 4 0 0 1 1.49-7.86 44.15 44.15 0 0 1 35.54 39.09 4 4 0 0 1-3.61 4.35h-.38a4 4 0 0 1-4-3.63 36.1 36.1 0 0 0-29.04-31.95Zm108.88 37c-.41.91-10.2 22.58-32.38 42.45a4 4 0 0 1-2.67 1 4 4 0 0 1-2.67-7A136.71 136.71 0 0 0 235.56 128 136.07 136.07 0 0 0 211 94.62C187.89 71.65 160 60 128 60a122 122 0 0 0-20 1.63 4 4 0 0 1-1.32-7.89A129.3 129.3 0 0 1 128 52c42.7 0 71.87 20.22 88.83 37.18 18.31 18.31 26.49 36.44 26.83 37.2a4.08 4.08 0 0 1 0 3.25Z" /></Svg>;
export default EyeSlash;