/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Images = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="images-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-images-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H72a12 12 0 0 0-12 12v20H40a12 12 0 0 0-12 12v112a12 12 0 0 0 12 12h144a12 12 0 0 0 12-12v-20h20a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12ZM68 56a4 4 0 0 1 4-4h144a4 4 0 0 1 4 4v72.4l-16.89-16.89a12 12 0 0 0-17 0l-22.83 22.83-46.79-46.83a12 12 0 0 0-17 0L68 119Zm120 144a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4V88a4 4 0 0 1 4-4h20v84a12 12 0 0 0 12 12h116Zm28-28H72a4 4 0 0 1-4-4v-37.66l37.17-37.17a4 4 0 0 1 5.66 0l49.66 49.66a4 4 0 0 0 5.65 0l25.66-25.66a4 4 0 0 1 5.66 0L220 139.71V168a4 4 0 0 1-4 4Zm-52-88a8 8 0 1 1 8 8 8 8 0 0 1-8-8Z" /></Svg>;
export default Images;