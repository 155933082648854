/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleIcon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128A104 104 0 1 1 128 24a104.13 104.13 0 0 1 104 104Z" /></Svg>;
export default CircleIcon;