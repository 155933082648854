/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Diamond = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="diamond-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-diamond-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.16 113.89 142.1 17.83a20 20 0 0 0-28.21 0l-96 96.06a20 20 0 0 0 0 28.22l96.05 96.06a20 20 0 0 0 28.21 0l96-96.06a20 20 0 0 0 0-28.22ZM128 218.33 37.68 128 128 37.67 218.32 128Z" /></Svg>;
export default Diamond;