/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrophoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m211 221.31-160-176a4 4 0 0 0-6 5.38l39 42.86V128a44 44 0 0 0 66 38.12l16.38 18A67.21 67.21 0 0 1 128 196a68.07 68.07 0 0 1-68-68 4 4 0 0 0-8 0 76.09 76.09 0 0 0 72 75.89V240a4 4 0 0 0 8 0v-36.11a75.1 75.1 0 0 0 39.79-13.77L205 226.69a4 4 0 1 0 5.92-5.38ZM128 164a36 36 0 0 1-36-36v-25.65L144.43 160a35.83 35.83 0 0 1-16.43 4Zm61.12-6.15A67.44 67.44 0 0 0 196 128a4 4 0 0 1 8 0 75.28 75.28 0 0 1-7.7 33.37 4 4 0 0 1-7.18-3.52ZM87.63 46.46A44 44 0 0 1 172 64v64a44.2 44.2 0 0 1-.24 4.61 4 4 0 0 1-4 3.58h-.42a4 4 0 0 1-3.57-4.39 36.67 36.67 0 0 0 .23-3.8V64a36 36 0 0 0-69-14.34 4 4 0 0 1-7.34-3.2Z" /></Svg>;
export default MicrophoneSlash;