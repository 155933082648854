/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileSql = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-sql-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-sql-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 118a6 6 0 0 0 6-6V40a2 2 0 0 1 2-2h90v50a6 6 0 0 0 6 6h50v18a6 6 0 1 0 12 0V88a6 6 0 0 0-1.76-4.25l-56-56A6 6 0 0 0 152 26H56a14 14 0 0 0-14 14v72a6 6 0 0 0 6 6Zm110-71.52L193.52 82H158ZM226 208a6 6 0 0 1-6 6h-28a6 6 0 0 1-6-6v-56a6 6 0 0 1 12 0v50h22a6 6 0 0 1 6 6ZM89.83 196a18.85 18.85 0 0 1-8.3 13.82c-4.82 3.23-10.87 4.18-16.37 4.18a58.87 58.87 0 0 1-14.67-2 6 6 0 0 1 3.23-11.56c3.71 1 15.58 3.11 21.19-.62a6.85 6.85 0 0 0 3-5.34c.57-4.43-2.08-6.26-14.2-9.76-9.31-2.69-23.37-6.75-21.57-20.94a18.61 18.61 0 0 1 8.08-13.54c11.11-7.49 29.18-3 31.2-2.48a6 6 0 1 1-3.06 11.6c-3.79-1-15.85-2.95-21.44.84a6.59 6.59 0 0 0-2.88 5.08c-.41 3.22 2.14 4.78 13 7.91 9.89 2.89 24.81 7.2 22.79 22.81ZM136 146c-16.54 0-30 15.25-30 34s13.45 34 30 34a27.32 27.32 0 0 0 16.15-5.37l3.61 3.61a6 6 0 1 0 8.49-8.48l-3.91-3.91A36.94 36.94 0 0 0 166 180c0-18.76-13.45-34-30-34Zm0 56c-9.92 0-18-9.87-18-22s8.07-22 18-22 18 9.87 18 22a25.58 25.58 0 0 1-2.45 11.06l-3.3-3.3a6 6 0 0 0-8.49 8.48l3.75 3.75A15.32 15.32 0 0 1 136 202Z" /></Svg>;
export default FileSql;