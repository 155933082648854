/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-four-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-four-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 176H52V52h152ZM88 164h44v12a12 12 0 0 0 24 0v-12h4a12 12 0 0 0 0-24h-4V80a12 12 0 0 0-21.47-7.37l-56 72A12 12 0 0 0 88 164Zm44-49v25h-19.46Z" /></Svg>;
export default NumberSquareFour;