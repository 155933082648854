/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UsersFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="users-four-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-users-four-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28.4 124.8a6 6 0 0 0 8.4-1.2 54 54 0 0 1 86.4 0 6 6 0 0 0 8.4 1.19 5.59 5.59 0 0 0 1.19-1.19 54 54 0 0 1 86.4 0 6 6 0 0 0 9.6-7.21 65.74 65.74 0 0 0-29.69-22.26 38 38 0 1 0-46.22 0A65.32 65.32 0 0 0 128 110.7a65.32 65.32 0 0 0-24.89-16.57 38 38 0 1 0-46.22 0A65.69 65.69 0 0 0 27.2 116.4a6 6 0 0 0 1.2 8.4ZM176 38a26 26 0 1 1-26 26 26 26 0 0 1 26-26Zm-96 0a26 26 0 1 1-26 26 26 26 0 0 1 26-26Zm119.11 160.13a38 38 0 1 0-46.22 0A65.32 65.32 0 0 0 128 214.7a65.32 65.32 0 0 0-24.89-16.57 38 38 0 1 0-46.22 0A65.69 65.69 0 0 0 27.2 220.4a6 6 0 1 0 9.6 7.2 54 54 0 0 1 86.4 0 6 6 0 0 0 8.4 1.19 5.59 5.59 0 0 0 1.19-1.19 54 54 0 0 1 86.4 0 6 6 0 0 0 9.6-7.21 65.74 65.74 0 0 0-29.68-22.26ZM80 142a26 26 0 1 1-26 26 26 26 0 0 1 26-26Zm96 0a26 26 0 1 1-26 26 26 26 0 0 1 26-26Z" /></Svg>;
export default UsersFour;