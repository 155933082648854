/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiceTwo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dice-two__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dice-two'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 32H64a32 32 0 0 0-32 32v128a32 32 0 0 0 32 32h128a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32Zm16 160a16 16 0 0 1-16 16H64a16 16 0 0 1-16-16V64a16 16 0 0 1 16-16h128a16 16 0 0 1 16 16Zm-88-84a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm40 40a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default DiceTwo;