/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Barn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="barn-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-barn-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 194h-10v-69.68l5.12 7.17a6 6 0 1 0 9.76-7l-40-56a6.14 6.14 0 0 0-1.47-1.45l-67.64-46.69a14 14 0 0 0-15.62.06L52.59 67.06a6.14 6.14 0 0 0-1.47 1.45l-40 56a6 6 0 1 0 9.76 7l5.12-7.19V194H16a6 6 0 0 0 0 12h224a6 6 0 0 0 0-12ZM38 107.52l22.27-31.18 66.62-46a2 2 0 0 1 2.14-.06l66.7 46.06L218 107.52V194h-28v-74a6 6 0 0 0-6-6H72a6 6 0 0 0-6 6v74H38Zm90 45.11L90.72 126h74.56Zm50-21v56.68L138.32 160ZM117.68 160 78 188.34v-56.68Zm10.32 7.37L165.28 194H90.72ZM106 88a6 6 0 0 1 6-6h32a6 6 0 0 1 0 12h-32a6 6 0 0 1-6-6Z" /></Svg>;
export default Barn;