/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThreeD = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="three-d-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-three-d-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 148a16 16 0 0 0-16-16 12 12 0 0 1-9.83-18.88L77 92H52a12 12 0 0 1 0-24h48a12 12 0 0 1 9.83 18.88l-18.34 26.2A40 40 0 1 1 43.43 176a12 12 0 1 1 17.14-16.8A16 16 0 0 0 88 148Zm76-80a60 60 0 0 1 0 120h-24a12 12 0 0 1-12-12V80a12 12 0 0 1 12-12Zm0 24h-12v72h12a36 36 0 0 0 0-72ZM32 56h192a12 12 0 0 0 0-24H32a12 12 0 0 0 0 24Zm192 144H32a12 12 0 0 0 0 24h192a12 12 0 0 0 0-24Z" /></Svg>;
export default ThreeD;