/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextTSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-t-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-t-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216.07 224.88a12 12 0 0 1-16.95-.81L140 159v29h20a12 12 0 0 1 0 24H96a12 12 0 0 1 0-24h20v-55.36l-48-52.8V88a12 12 0 0 1-24 0V56a11.75 11.75 0 0 1 .23-2.31l-5.11-5.62a12 12 0 1 1 17.76-16.14l160 176a12 12 0 0 1-.81 16.95ZM116 68v.57a12 12 0 1 0 24 0V68h48v20a12 12 0 0 0 24 0V56a12 12 0 0 0-12-12h-83.4a12 12 0 0 0-.6 24Z" /></Svg>;
export default TextTSlash;