/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShirtFolded = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shirt-folded__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shirt-folded'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 40h-20.69l-13.65-13.66A8 8 0 0 0 160 24H96a8 8 0 0 0-5.66 2.34L76.69 40H56a16 16 0 0 0-16 16v152a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm-72 25.58L111 40h34.1Zm33.24-21 6.76 6.73V104l-29.43-25.44ZM88 51.31l6.76-6.75 22.67 34L88 104ZM56 56h16v48a15.85 15.85 0 0 0 9.21 14.49A16.1 16.1 0 0 0 88 120a15.89 15.89 0 0 0 10.2-3.73.52.52 0 0 0 .11-.1L120 97.48V208H56Zm144 152h-64V97.48l21.65 18.7a.52.52 0 0 0 .11.1A15.89 15.89 0 0 0 168 120a16.1 16.1 0 0 0 6.83-1.54A15.85 15.85 0 0 0 184 104V56h16Z" /></Svg>;
export default ShirtFolded;