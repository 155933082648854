/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-dashed__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-dashed'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96.26 37a8 8 0 0 1 5.74-9.71 104.11 104.11 0 0 1 52 0 8 8 0 0 1-2 15.75 8.15 8.15 0 0 1-2-.26 88 88 0 0 0-44 0A8 8 0 0 1 96.26 37Zm-62.91 73a8 8 0 0 0 9.85-5.57 87.88 87.88 0 0 1 22-38.09 8 8 0 0 0-11.4-11.2 103.92 103.92 0 0 0-26 45 8 8 0 0 0 5.55 9.86ZM150 213.22a88 88 0 0 1-44 0 8 8 0 0 0-4 15.49 104.11 104.11 0 0 0 52 0 8 8 0 0 0-4-15.49Zm62.8-108.77a8 8 0 0 0 15.42-4.28 104 104 0 0 0-26-45 8 8 0 1 0-11.41 11.21 88.14 88.14 0 0 1 21.98 38.07Zm15.44 51.39a103.68 103.68 0 0 1-30.68 49.47 8 8 0 0 1-12.49-2.31 64 64 0 0 0-114.14 0 8 8 0 0 1-12.48 2.32 103.74 103.74 0 0 1-30.68-49.49 8 8 0 0 1 15.42-4.27 87.58 87.58 0 0 0 19 34.88 79.57 79.57 0 0 1 36.1-28.77 48 48 0 1 1 59.38 0 79.57 79.57 0 0 1 36.1 28.77 87.58 87.58 0 0 0 19-34.88 8 8 0 1 1 15.42 4.28ZM128 152a32 32 0 1 0-32-32 32 32 0 0 0 32 32Z" /></Svg>;
export default UserCircleDashed;