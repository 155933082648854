/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseRightClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-right-click__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-right-click'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 16h-32a64.07 64.07 0 0 0-64 64v96a64.07 64.07 0 0 0 64 64h32a64.07 64.07 0 0 0 64-64V80a64.07 64.07 0 0 0-64-64Zm-8 67.31 39.49-39.49A48.49 48.49 0 0 1 186.66 58l-46 46H136Zm55.78-7.78c.14 1.47.22 3 .22 4.47v24h-28.69Zm-30.37-40.25L136 60.69V32h8a47.73 47.73 0 0 1 17.41 3.28ZM112 32h8v72H64V80a48.05 48.05 0 0 1 48-48Zm32 192h-32a48.05 48.05 0 0 1-48-48v-56h128v56a48.05 48.05 0 0 1-48 48Z" /></Svg>;
export default MouseRightClick;