/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SteamLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="steam-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-steam-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm-11.62 158.17 61.21-49.93a34 34 0 1 0-47.83-47.83l-44.43 54.44L43 98.49a89.63 89.63 0 1 1-3.53 13.43l38.44 38.44a26 26 0 0 0 38.5 33.81Zm6.19-70a34.1 34.1 0 0 0 27.25 27.25l-24 19.58A26 26 0 0 0 103 138.18ZM156 130a22 22 0 1 1 22-22 22 22 0 0 1-22 22Zm-56 20a14 14 0 1 1-14 14 14 14 0 0 1 14-14Z" /></Svg>;
export default SteamLogo;