/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bed-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bed-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 112v56H112V80h104a32 32 0 0 1 32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 72H32V48a8 8 0 0 0-16 0v160a8 8 0 0 0 16 0v-32h208v32a8 8 0 0 0 16 0v-96a40 40 0 0 0-40-40ZM32 88h72v72H32Zm88 72V88h96a24 24 0 0 1 24 24v48Z" /></Svg>;
export default Bed;