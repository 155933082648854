/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowElbowRightUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-elbow-right-up-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-elbow-right-up-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 96h-96l48-48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m221.66 90.34-48-48a8 8 0 0 0-11.32 0l-48 48A8 8 0 0 0 120 104h40v80H24a8 8 0 0 0 0 16h144a8 8 0 0 0 8-8v-88h40a8 8 0 0 0 5.66-13.66ZM139.31 88 168 59.31 196.69 88Z" /></Svg>;
export default ArrowElbowRightUp;