/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceMobileSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-mobile-slash-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-mobile-slash-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 40v176a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V40a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m213.92 210.62-160-176a8 8 0 1 0-11.84 10.76L56 60.69V216a24 24 0 0 0 24 24h96a24 24 0 0 0 23.82-21.11l2.26 2.49a8 8 0 1 0 11.84-10.76ZM184 216a8 8 0 0 1-8 8H80a8 8 0 0 1-8-8V78.29l112 123.2ZM68.7 24a8 8 0 0 1 8-8H176a24 24 0 0 1 24 24v110.83a8 8 0 1 1-16 0V40a8 8 0 0 0-8-8H76.7a8 8 0 0 1-8-8Z" /></Svg>;
export default DeviceMobileSlash;