/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CraneTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crane-tower-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crane-tower-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 76H111.42L90.73 34.63A12 12 0 0 0 80 28H48a12 12 0 0 0-12 12v36H24a12 12 0 0 0 0 24h12v104H24a12 12 0 0 0 0 24h104a12 12 0 0 0 0-24h-12V100h88v80h-12v-4a12 12 0 0 0-24 0v8a20 20 0 0 0 20 20h20a20 20 0 0 0 20-20v-84h12a12 12 0 0 0 0-24ZM60 52h12.58l12 24H60Zm0 152v-40h32v40Zm32-64H60v-40h32Z" /></Svg>;
export default CraneTower;