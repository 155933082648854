/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-slash__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-slash'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.92 210.62a8 8 0 1 1-11.84 10.76l-52-57.15a60 60 0 0 0-57.41 7.24 8 8 0 1 1-9.42-12.93A75.43 75.43 0 0 1 128 144c1.28 0 2.55 0 3.82.1l-26.92-29.61A108 108 0 0 0 61 135.31 8 8 0 0 1 49.73 134 8 8 0 0 1 51 122.77a124.27 124.27 0 0 1 41.71-21.66L69.37 75.4a155.43 155.43 0 0 0-40.29 24A8 8 0 0 1 18.92 87 171.87 171.87 0 0 1 58 62.86L42.08 45.38a8 8 0 1 1 11.84-10.76ZM128 192a12 12 0 1 0 12 12 12 12 0 0 0-12-12ZM237.08 87A172.3 172.3 0 0 0 106 49.4a8 8 0 1 0 2 15.87A158.33 158.33 0 0 1 128 64a156.25 156.25 0 0 1 98.92 35.37A8 8 0 0 0 237.08 87ZM195 135.31a8 8 0 0 0 11.24-1.3 8 8 0 0 0-1.3-11.24 124.25 124.25 0 0 0-51.73-24.2 8 8 0 1 0-3.21 15.67 108.12 108.12 0 0 1 45 21.07Z" /></Svg>;
export default WifiSlash;