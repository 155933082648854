/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Copyleft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="copyleft-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-copyleft-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 56a72 72 0 1 0 72 72 72.08 72.08 0 0 0-72-72Zm0 120a47.66 47.66 0 0 1-38.4-19.19 8 8 0 0 1 12.8-9.61 32 32 0 1 0 0-38.4 8 8 0 0 1-12.8-9.61A48 48 0 1 1 128 176Zm0-152a104 104 0 1 0 104 104A104 104 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Z" /></Svg>;
export default Copyleft;