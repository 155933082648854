/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberOne = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-one-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-one-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M142 48v160a6 6 0 0 1-12 0V58.6L99.09 77.14a6 6 0 0 1-6.18-10.29l40-24A6 6 0 0 1 142 48Z" /></Svg>;
export default NumberOne;