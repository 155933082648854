/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FloppyDiskBack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="floppy-disk-back-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-floppy-disk-back-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28H83.31a19.86 19.86 0 0 0-14.14 5.86L33.86 69.17A19.86 19.86 0 0 0 28 83.31V208a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20ZM92 52h72v20H92Zm112 152H52V85l16-16v7a20 20 0 0 0 20 20h80a20 20 0 0 0 20-20V52h16Zm-76-96a42 42 0 1 0 42 42 42 42 0 0 0-42-42Zm0 60a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default FloppyDiskBack;