/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseLeftClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-left-click-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-left-click-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 20h-32a60.07 60.07 0 0 0-60 60v96a60.07 60.07 0 0 0 60 60h32a60.07 60.07 0 0 0 60-60V80a60.07 60.07 0 0 0-60-60Zm52 60v28h-64V28h12a52.06 52.06 0 0 1 52 52Zm-72-52v42.34L87.7 34.05A51.61 51.61 0 0 1 112 28ZM80.78 38.44 124 81.66V108h-10.34L64.51 58.85a52.5 52.5 0 0 1 16.27-20.41ZM60 80a51.55 51.55 0 0 1 1.59-12.75L102.34 108H60Zm84 148h-32a52.06 52.06 0 0 1-52-52v-60h136v60a52.06 52.06 0 0 1-52 52Z" /></Svg>;
export default MouseLeftClick;