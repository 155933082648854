/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Parallelogram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="parallelogram__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-parallelogram'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M245.43 47.31A15.94 15.94 0 0 0 232 40H88.81a16 16 0 0 0-14.59 9.43l-64.8 144A16 16 0 0 0 24 216h143.19a16 16 0 0 0 14.59-9.43l64.8-144a16 16 0 0 0-1.15-15.26ZM167.19 200H24L88.81 56H232Z" /></Svg>;
export default Parallelogram;