/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinktreeLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="linktree-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-linktree-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 164v68a12 12 0 0 1-24 0v-68a12 12 0 0 1 24 0Zm68-72h-51l35.52-35.51a12 12 0 0 0-17-17L140 75V24a12 12 0 0 0-24 0v51L80.49 39.51a12 12 0 0 0-17 17L99 92H48a12 12 0 0 0 0 24h51l-35.49 35.51a12 12 0 0 0 17 17L128 121l47.51 47.52a12 12 0 0 0 17-17L157 116h51a12 12 0 0 0 0-24Z" /></Svg>;
export default LinktreeLogo;