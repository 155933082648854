/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RepeatOnce = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="repeat-once-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-repeat-once-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M20 128a76.08 76.08 0 0 1 76-76h99l-3.52-3.51a12 12 0 1 1 17-17l24 24a12 12 0 0 1 0 17l-24 24a12 12 0 0 1-17-17L195 76H96a52.06 52.06 0 0 0-52 52 12 12 0 0 1-24 0Zm204-12a12 12 0 0 0-12 12 52.06 52.06 0 0 1-52 52H61l3.52-3.51a12 12 0 1 0-17-17l-24 24a12 12 0 0 0 0 17l24 24a12 12 0 1 0 17-17L61 204h99a76.08 76.08 0 0 0 76-76 12 12 0 0 0-12-12Zm-88 48a12 12 0 0 0 12-12v-48a12 12 0 0 0-17.36-10.74l-16 8a12 12 0 0 0 9.36 22V152a12 12 0 0 0 12 12Z" /></Svg>;
export default RepeatOnce;