/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SquareHalfBottom = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="square-half-bottom-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-square-half-bottom-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 40H56a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm0 16v72H56V56Z" /></Svg>;
export default SquareHalfBottom;