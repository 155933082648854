/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IdentificationBadge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="identification-badge-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-identification-badge-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 20H56a20 20 0 0 0-20 20v176a20 20 0 0 0 20 20h144a20 20 0 0 0 20-20V40a20 20 0 0 0-20-20Zm-4 192H60V44h136ZM84 68a12 12 0 0 1 12-12h64a12 12 0 0 1 0 24H96a12 12 0 0 1-12-12Zm8.8 127.37a48 48 0 0 1 70.4 0 12 12 0 0 0 17.6-16.32 72 72 0 0 0-19.21-14.68 44 44 0 1 0-67.19 0 72.12 72.12 0 0 0-19.2 14.68 12 12 0 0 0 17.6 16.32ZM128 116a20 20 0 1 1-20 20 20 20 0 0 1 20-20Z" /></Svg>;
export default IdentificationBadge;