/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Moped = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="moped-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-moped-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 132a35.7 35.7 0 0 0-8.82 1.1l-35.43-94.5A4 4 0 0 0 168 36h-32a4 4 0 0 0 0 8h29.23l34.47 91.92A36.06 36.06 0 0 0 180.23 164h-46.46l-26-69.4A4 4 0 0 0 104 92H24a4 4 0 0 0 0 8h12v20a52.1 52.1 0 0 0-32 48 4 4 0 0 0 4 4h12.23a36 36 0 0 0 71.54 0h88.46A36 36 0 1 0 216 132Zm-174.67-5.5a4 4 0 0 0 2.67-3.77V100h57.23l24 64h-113a44.1 44.1 0 0 1 29.1-37.5ZM56 196a28 28 0 0 1-27.71-24h55.42A28 28 0 0 1 56 196Zm160 0a28 28 0 0 1-13.47-52.54l9.72 25.94a4 4 0 1 0 7.5-2.8L210 140.65a28 28 0 1 1 6 55.35Z" /></Svg>;
export default Moped;