/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CameraPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="camera-plus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-camera-plus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 136a4 4 0 0 1-4 4h-28v28a4 4 0 0 1-8 0v-28H96a4 4 0 0 1 0-8h28v-28a4 4 0 0 1 8 0v28h28a4 4 0 0 1 4 4Zm64-56v112a20 20 0 0 1-20 20H48a20 20 0 0 1-20-20V80a20 20 0 0 1 20-20h29.86L90.3 41.34a12 12 0 0 1 10-5.34h55.44a12 12 0 0 1 10 5.34L178.14 60H208a20 20 0 0 1 20 20Zm-8 0a12 12 0 0 0-12-12h-32a4 4 0 0 1-3.33-1.78l-13.62-20.44a4 4 0 0 0-3.33-1.78h-55.44A4 4 0 0 0 97 45.78L83.33 66.22A4 4 0 0 1 80 68H48a12 12 0 0 0-12 12v112a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12Z" /></Svg>;
export default CameraPlus;