/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MoneyWavy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="money-wavy-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-money-wavy-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243.18 61.72a6 6 0 0 0-5.81-.3c-43.66 21.32-74.69 11.39-107.54.88-33.67-10.77-68.48-21.9-116.46 1.54A6 6 0 0 0 10 69.23v120a6 6 0 0 0 8.63 5.39c43.66-21.32 74.69-11.39 107.54-.88 19 6.09 38.46 12.3 60.42 12.3 16.85 0 35.21-3.66 56-13.84a6 6 0 0 0 3.37-5.39v-120a6 6 0 0 0-2.78-5.09ZM234 183c-41.9 19.21-72.17 9.53-104.17-.71C110.78 176.18 91.37 170 69.41 170c-14.49 0-30.08 2.7-47.41 9.92V73c41.9-19.21 72.17-9.53 104.17.71 31.61 10.13 64.24 20.57 107.83 2.4ZM128 98a30 30 0 1 0 30 30 30 30 0 0 0-30-30Zm0 48a18 18 0 1 1 18-18 18 18 0 0 1-18 18ZM54 96v48a6 6 0 0 1-12 0V96a6 6 0 1 1 12 0Zm148 64v-48a6 6 0 0 1 12 0v48a6 6 0 0 1-12 0Z" /></Svg>;
export default MoneyWavy;