/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Star = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.28 97.87A14.18 14.18 0 0 0 224.76 88l-60.25-4.87-23.22-56.2a14.37 14.37 0 0 0-26.58 0L91.49 83.11 31.24 88a14.18 14.18 0 0 0-12.52 9.89A14.43 14.43 0 0 0 23 113.32l46 39.61-14 59.25a14.4 14.4 0 0 0 5.59 15 14.1 14.1 0 0 0 15.91.6l51.5-31.66 51.58 31.71a14.1 14.1 0 0 0 15.91-.6 14.4 14.4 0 0 0 5.59-15l-14-59.25L233 113.32a14.43 14.43 0 0 0 4.28-15.45Zm-12.14 6.37-48.69 42a6 6 0 0 0-1.92 5.92l14.88 62.79a2.35 2.35 0 0 1-.95 2.57 2.24 2.24 0 0 1-2.6.1L131.14 184a6 6 0 0 0-6.28 0l-54.72 33.61a2.24 2.24 0 0 1-2.6-.1 2.35 2.35 0 0 1-1-2.57l14.88-62.79a6 6 0 0 0-1.92-5.92l-48.69-42a2.37 2.37 0 0 1-.73-2.65 2.28 2.28 0 0 1 2.07-1.65l63.92-5.16a6 6 0 0 0 5.06-3.69l24.63-59.6a2.35 2.35 0 0 1 4.38 0l24.63 59.6a6 6 0 0 0 5.06 3.69l63.92 5.16a2.28 2.28 0 0 1 2.07 1.65 2.37 2.37 0 0 1-.68 2.66Z" /></Svg>;
export default Star;