/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyGbp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-gbp-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-gbp-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M190 208a6 6 0 0 1-6 6H56a6 6 0 0 1 0-12h4a30 30 0 0 0 30-30v-38H56a6 6 0 0 1 0-12h34V84a50 50 0 0 1 81.81-38.58 6 6 0 1 1-7.64 9.26A38 38 0 0 0 102 84v38h34a6 6 0 0 1 0 12h-34v38a41.88 41.88 0 0 1-12.63 30H184a6 6 0 0 1 6 6Z" /></Svg>;
export default CurrencyGbp;