/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TelegramLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="telegram-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-telegram-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M223.41 32.09 80 134.87 21 123.3a6.23 6.23 0 0 1-1-11.92l202.63-79.31a1 1 0 0 1 .78.02ZM80 200a8 8 0 0 0 13.76 5.56l30.61-31.76L80 134.87Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M228.88 26.19a9 9 0 0 0-9.16-1.57L17.06 103.93a14.22 14.22 0 0 0 2.43 27.21L72 141.45V200a15.92 15.92 0 0 0 10 14.83 15.91 15.91 0 0 0 17.51-3.73l25.32-26.26L165 220a15.88 15.88 0 0 0 10.51 4 16.3 16.3 0 0 0 5-.79 15.85 15.85 0 0 0 10.67-11.63L231.77 35a9 9 0 0 0-2.89-8.81ZM78.15 126.35l-49.61-9.73 139.2-54.48ZM88 200v-47.48l24.79 21.74Zm87.53 8-82.68-72.5 119-85.29Z" /></Svg>;
export default TelegramLogo;