/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyXEyes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-x-eyes-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-x-eyes-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84Zm56.49-92.49a12 12 0 0 1-17 17L162 131l-5.51 5.52a12 12 0 0 1-17-17L145 114l-5.52-5.51a12 12 0 0 1 17-17L162 97l5.51-5.52a12 12 0 0 1 17 17L179 114Zm-68 17a12 12 0 0 1-17 0L94 131l-5.51 5.52a12 12 0 0 1-17-17L77 114l-5.52-5.51a12 12 0 0 1 17-17L94 97l5.51-5.52a12 12 0 0 1 17 17L111 114l5.52 5.51a12 12 0 0 1-.03 16.98ZM144 180a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default SmileyXEyes;