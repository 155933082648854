/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Panorama = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="panorama-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-panorama-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.75 51.32a15.87 15.87 0 0 0-13.86-2.77l-3.48.94C205.61 54.56 170.61 64 128 64s-77.61-9.44-96.41-14.51l-3.48-.94A16 16 0 0 0 8 64v128a16 16 0 0 0 16 16 16.22 16.22 0 0 0 4.18-.55l3.18-.86C50.13 201.49 85.17 192 128 192s77.87 9.49 96.69 14.59l3.18.86A16 16 0 0 0 248 192V64a15.9 15.9 0 0 0-6.25-12.68ZM204 96a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm-76 80c-45 0-82.72 10.23-100.87 15.14L24 192v-39.3l46.34-46.35a8 8 0 0 1 11.32 0L152.28 177c-7.79-.65-15.91-1-24.28-1Zm100.87 15.14a448.7 448.7 0 0 0-51-11.2l-35.26-35.26L157 130.34a8 8 0 0 1 11.31 0l60.89 60.88Z" /></Svg>;
export default Panorama;