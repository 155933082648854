/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleChromeLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-chrome-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-chrome-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 12a90 90 0 0 1 77.33 44H128a46.07 46.07 0 0 0-44.93 36.17l-26.16-45.3A89.91 89.91 0 0 1 128 38Zm34 90a34 34 0 1 1-34-34 34 34 0 0 1 34 34Zm-124 0a89.4 89.4 0 0 1 11.5-44l38.66 67c.06.1.13.18.19.27A45.94 45.94 0 0 0 142 171.83l-26.17 45.34A90.13 90.13 0 0 1 38 128Zm91.16 90 38.68-67 .21-.41A45.9 45.9 0 0 0 159 94h52.37a90 90 0 0 1-82.16 124Z" /></Svg>;
export default GoogleChromeLogo;