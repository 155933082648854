/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartDonut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-donut-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-donut-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a96 96 0 0 1-96 96v-56a40 40 0 0 0 0-80V32a96 96 0 0 1 96 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a8 8 0 0 0-8 8v56a8 8 0 0 0 8 8 32 32 0 1 1-27.72 16 8 8 0 0 0-2.93-10.93l-48.5-28A8 8 0 0 0 37.92 76 104 104 0 1 0 128 24ZM48.09 91.1 83 111.26A48.09 48.09 0 0 0 80 128c0 1.53.08 3 .22 4.52L41.28 143a88.16 88.16 0 0 1 6.81-51.9Zm-2.67 67.31 39-10.44A48.1 48.1 0 0 0 120 175.32v40.31a88.2 88.2 0 0 1-74.58-57.22ZM136 215.63v-40.31a48 48 0 0 0 0-94.65V40.36a88 88 0 0 1 0 175.27Z" /></Svg>;
export default ChartDonut;