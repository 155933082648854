/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Mountains = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mountains-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mountains-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 52a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm49.44 55.92a8 8 0 0 0-13.77 0l-33 55.75-21-35.67H50.35L8 200h240Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M164 80a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0-40a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm90.88 155.92-54.56-92.08A15.87 15.87 0 0 0 186.55 96a15.85 15.85 0 0 0-13.76 7.84L146.63 148l-44.84-76.1a16 16 0 0 0-27.58 0L1.11 195.94A8 8 0 0 0 8 208h240a8 8 0 0 0 6.88-12.08ZM88 80l23.57 40H64.43ZM22 192l33-56h66l33 56Zm150.57 0-16.66-28.28L186.55 112 234 192Z" /></Svg>;
export default Mountains;