/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Speedometer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speedometer-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speedometer-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.87 90.86a4 4 0 0 0-6.17-.62l-75.42 75.42A8 8 0 0 1 129 154.35l92.7-92.69a8 8 0 0 0-11.32-11.32L197 63.73A112.05 112.05 0 0 0 22.34 189.25 16.09 16.09 0 0 0 37.46 200h181.07a16 16 0 0 0 15.11-10.71 112.28 112.28 0 0 0-11.77-98.43ZM57.44 166.41a8 8 0 0 1-6.25 9.43 7.89 7.89 0 0 1-1.6.16 8 8 0 0 1-7.83-6.41A88.06 88.06 0 0 1 143.59 65.38a8 8 0 0 1-2.82 15.75 72.07 72.07 0 0 0-83.33 85.28Z" /></Svg>;
export default Speedometer;