/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyGbp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-gbp-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-gbp-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm40 160H88a8 8 0 0 1 0-16 16 16 0 0 0 16-16v-16H88a8 8 0 0 1 0-16h16V96a40 40 0 0 1 60-34.64 8 8 0 0 1-8 13.85A24 24 0 0 0 120 96v24h16a8 8 0 0 1 0 16h-16v16a31.71 31.71 0 0 1-4.31 16H168a8 8 0 0 1 0 16Z" /></Svg>;
export default CurrencyGbp;