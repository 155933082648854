/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyKrw = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-krw-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-krw-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 124h-16.17l19.29-47.48a12 12 0 0 0-22.24-9l-23 56.51h-35.8l-23-56.51a12 12 0 0 0-22.24 0L93.92 124H58.08l-23-56.51a12 12 0 0 0-22.24 9L32.17 124H16a12 12 0 0 0 0 24h25.92l23 56.52a12 12 0 0 0 22.24 0l23-56.52H146l23 56.52a12 12 0 0 0 22.24 0l23-56.52H240a12 12 0 0 0 0-24ZM76 168.12 67.83 148h16.34ZM119.83 124l8.17-20.11 8.17 20.11ZM180 168.12 171.83 148h16.34Z" /></Svg>;
export default CurrencyKrw;