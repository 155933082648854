/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Layout = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="layout-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-layout-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a12 12 0 0 0-12 12v144a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12ZM40 52h176a4 4 0 0 1 4 4v44H36V56a4 4 0 0 1 4-4Zm-4 148v-92h64v96H40a4 4 0 0 1-4-4Zm180 4H108v-96h112v92a4 4 0 0 1-4 4Z" /></Svg>;
export default Layout;