/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Robot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="robot-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-robot-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 50h-66V16a6 6 0 0 0-12 0v34H56a30 30 0 0 0-30 30v112a30 30 0 0 0 30 30h144a30 30 0 0 0 30-30V80a30 30 0 0 0-30-30Zm18 142a18 18 0 0 1-18 18H56a18 18 0 0 1-18-18V80a18 18 0 0 1 18-18h144a18 18 0 0 1 18 18ZM74 108a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm88 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm2 30H92a26 26 0 0 0 0 52h72a26 26 0 0 0 0-52Zm-22 12v28h-28v-28Zm-64 14a14 14 0 0 1 14-14h10v28H92a14 14 0 0 1-14-14Zm86 14h-10v-28h10a14 14 0 0 1 0 28Z" /></Svg>;
export default Robot;