/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ruler = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ruler-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ruler-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m238.15 70.54-52.69-52.68a20 20 0 0 0-28.29 0L17.85 157.17a20 20 0 0 0 0 28.29l52.69 52.68a20 20 0 0 0 28.29 0L238.15 98.83a20 20 0 0 0 0-28.29ZM84.68 218.34l-47-47L64 145l23.52 23.52a12 12 0 0 0 17-17L81 128l15-15 23.51 23.52a12 12 0 0 0 17-17L113 96l15-15 23.52 23.52a12 12 0 0 0 17-17L145 64l26.35-26.34 47 47Z" /></Svg>;
export default Ruler;