/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radioactive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radioactive-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radioactive-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 112a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm-32 16a12 12 0 0 0-12-12H44.47a83.88 83.88 0 0 1 31.2-54.11L95.38 96a12 12 0 1 0 20.78-12l-22-38a20 20 0 0 0-28.87-6.34 107.85 107.85 0 0 0-45.26 78.4 20.22 20.22 0 0 0 5.21 15.43A20 20 0 0 0 40 140h44a12 12 0 0 0 12-12Zm139.92-9.94a107.81 107.81 0 0 0-45.26-78.39A20 20 0 0 0 161.79 46l-21.95 38a12 12 0 0 0 20.78 12l19.71-34.13a83.88 83.88 0 0 1 31.2 54.13H172a12 12 0 0 0 0 24h44a20 20 0 0 0 14.71-6.51 20.22 20.22 0 0 0 5.21-15.43Zm-75.52 42.05a12 12 0 0 0-20.79 12L159.19 206a84.33 84.33 0 0 1-62.38 0l19.58-33.91a12 12 0 0 0-20.79-12l-21.82 37.8a20 20 0 0 0 8.91 28.15 108.24 108.24 0 0 0 90.62 0 20 20 0 0 0 8.91-28.15Z" /></Svg>;
export default Radioactive;