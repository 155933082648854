/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MetaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="meta-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-meta-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm-14.77 130.26c-5.63 9-14.33 13.74-25.16 13.74-20.73 0-34.81-25.31-49.72-52.11C115.47 112.33 101.93 88 90.61 88 78.18 88 64 116.88 64 142.21c0 8.26 1.5 15 4.34 19.56C71 166 74.68 168 79.93 168c6.08 0 13.52-7 22.74-21.51a8 8 0 0 1 13.49 8.59C99.84 180.71 88.22 184 79.93 184c-10.83 0-19.53-4.75-25.16-13.74-4.43-7.08-6.77-16.78-6.77-28a103.13 103.13 0 0 1 11-45.2C67.34 80.9 78.57 72 90.61 72c20.73 0 34.81 25.31 49.72 52.11 10.88 19.55 24.42 43.89 35.74 43.89 5.25 0 8.93-2 11.59-6.23 2.84-4.53 4.34-11.3 4.34-19.56C192 116.88 177.82 88 165.39 88c-3.4 0-7.35 2.31-11.74 6.88a8 8 0 1 1-11.54-11.09C149.74 75.86 157.35 72 165.39 72c12 0 23.27 8.9 31.62 25.06a103.13 103.13 0 0 1 11 45.15c-.01 11.27-2.35 20.97-6.78 28.05Z" /></Svg>;
export default MetaLogo;