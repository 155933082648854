/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shower-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shower-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M68 236a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm16-48a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm-64 0a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm32 0a16 16 0 1 0-16-16 16 16 0 0 0 16 16ZM256 40a12 12 0 0 1-12 12h-23l-25.81 25.79-21.45 125.54a20 20 0 0 1-33.86 10.8l-98-98a20 20 0 0 1 10.84-33.88l125.5-21.44 29.29-29.3A12 12 0 0 1 216 28h28a12 12 0 0 1 12 12Zm-86.68 46.68-105 17.94 87.07 87.07Z" /></Svg>;
export default Shower;