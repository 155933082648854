/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-simple-x__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-simple-x'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.66 173.66 203.31 192l18.35 18.34a8 8 0 0 1-11.32 11.32L192 203.31l-18.34 18.35a8 8 0 0 1-11.32-11.32L180.69 192l-18.35-18.34a8 8 0 0 1 11.32-11.32L192 180.69l18.34-18.35a8 8 0 0 1 11.32 11.32ZM232 128a8 8 0 0 1-8 8H96.25c3 53.73 35.33 80.6 36.77 81.77A8 8 0 0 1 128 232a104 104 0 1 1 104-104Zm-83.59-85.6C159.94 57.67 174 83.49 175.79 120h39.84a88.19 88.19 0 0 0-67.22-77.6ZM128 43c-9.54 9.92-29.46 35.42-31.77 77h63.54c-2.31-41.6-22.22-67.1-31.77-77Zm-87.63 77h39.84C82 83.49 96.06 57.67 107.59 42.4A88.19 88.19 0 0 0 40.37 120Zm39.84 16H40.37a88.19 88.19 0 0 0 67.22 77.6C96.06 198.33 82 172.51 80.21 136Z" /></Svg>;
export default GlobeSimpleX;