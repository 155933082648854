/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Yarn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="yarn-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-yarn-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a96 96 0 1 1-96-96 96 96 0 0 1 96 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 216h-48.61A103.95 103.95 0 1 0 128 232h104a8 8 0 1 0 0-16ZM128 40a87.51 87.51 0 0 1 43.93 11.77 222.06 222.06 0 0 0-27.88 15.09 222.23 222.23 0 0 0-45-22A87.52 87.52 0 0 1 128 40ZM78.56 55.24a206 206 0 0 1 51.11 21.57 225.76 225.76 0 0 0-19.57 16.55 181.54 181.54 0 0 0-52.37-18.27 88.67 88.67 0 0 1 20.83-19.85ZM48.72 89.82a165.82 165.82 0 0 1 49.67 15.51 228 228 0 0 0-15.63 19.17A142.65 142.65 0 0 0 41.28 113a87.5 87.5 0 0 1 7.44-23.18ZM40 129a126.07 126.07 0 0 1 33.63 9 222.36 222.36 0 0 0-19.07 38.45A87.51 87.51 0 0 1 40 129Zm26.42 61.81A209.36 209.36 0 0 1 187 62.74a89 89 0 0 1 16.22 19.57A183.89 183.89 0 0 0 87 205.82a88.56 88.56 0 0 1-20.57-15.01ZM125.66 216a87.66 87.66 0 0 1-23.83-4A167.84 167.84 0 0 1 210.28 96.79a87.35 87.35 0 0 1 5.38 23.55 144.59 144.59 0 0 0-90 95.66Zm89.82-78.44a88.19 88.19 0 0 1-72.67 77.22 128.64 128.64 0 0 1 72.67-77.25Z" /></Svg>;
export default Yarn;