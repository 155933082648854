/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartPieSlice = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-pie-slice-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-pie-slice-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 109.5v-72a4 4 0 0 0-5.33-3.77 100 100 0 0 0-65 112.5A4 4 0 0 0 35.6 149L98 113a4 4 0 0 0 2-3.5Zm-8-2.31-55.33 31.92A92 92 0 0 1 92 43.34ZM128 28a4 4 0 0 0-4 4v94.12l-80.8 47.06a4 4 0 0 0-1.43 5.49A100 100 0 1 0 128 28Zm0 192a92.47 92.47 0 0 1-77.22-42L130 131.88a4 4 0 0 0 2-3.46V36.09A92 92 0 0 1 128 220Z" /></Svg>;
export default ChartPieSlice;