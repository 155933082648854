/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Kanban = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="kanban-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-kanban-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 56v96a8 8 0 0 1-8 8h-40a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8h40a8 8 0 0 1 8 8Zm64-8h-40a8 8 0 0 0-8 8v52a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V56a8 8 0 0 0-8-8Zm4 80h-48a4 4 0 0 0-4 4v44a16 16 0 0 0 16 16h24a16 16 0 0 0 16-16v-44a4 4 0 0 0-4-4ZM80 48H40a8 8 0 0 0-8 8v52a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V56a8 8 0 0 0-8-8Zm4 80H36a4 4 0 0 0-4 4v76a16 16 0 0 0 16 16h24a16 16 0 0 0 16-16v-76a4 4 0 0 0-4-4Z" /></Svg>;
export default Kanban;