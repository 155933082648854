/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MathOperations = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="math-operations-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-math-operations-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 72a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h64a12 12 0 0 1 12 12Zm-12 100H84v-20a12 12 0 0 0-24 0v20H40a12 12 0 0 0 0 24h20v20a12 12 0 0 0 24 0v-20h20a12 12 0 0 0 0-24Zm48 4h64a12 12 0 0 0 0-24h-64a12 12 0 0 0 0 24Zm64 16h-64a12 12 0 0 0 0 24h64a12 12 0 0 0 0-24Zm-64.49-87.51a12 12 0 0 0 17 0L184 89l15.51 15.52a12 12 0 0 0 17-17L201 72l15.52-15.51a12 12 0 0 0-17-17L184 55l-15.51-15.49a12 12 0 0 0-17 17L167 72l-15.49 15.51a12 12 0 0 0 0 16.98Z" /></Svg>;
export default MathOperations;