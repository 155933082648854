/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNoteSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-note-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-note-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m209.15 60.17-80-24A4 4 0 0 0 124 40v118.75a44 44 0 1 0 8 25.25V45.38l74.85 22.45a4 4 0 0 0 2.3-7.66ZM88 220a36 36 0 1 1 36-36 36 36 0 0 1-36 36Z" /></Svg>;
export default MusicNoteSimple;