/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FediverseLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fediverse-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fediverse-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 96a27.84 27.84 0 0 0-10.51 2L171 59.94A28 28 0 1 0 120 44a28.65 28.65 0 0 0 .15 2.94L73.68 66.3a28 28 0 1 0-28.6 44.83l1.85 46.38a28 28 0 1 0 32.74 41.42L128 212.47a28 28 0 1 0 49.13-18.79l27.21-42.75A28 28 0 1 0 212 96Zm-140.81 8.36L113.72 129l-41.46 32.22a28 28 0 0 0-9.34-4.35l-1.85-46.38a28 28 0 0 0 10.12-6.13ZM149.57 72a27.8 27.8 0 0 0 8.94-2L189 108.06a27.86 27.86 0 0 0-4.18 9.22l-46.57 2.22ZM82.09 173.85 124 141.26l15.94 47.83a28.2 28.2 0 0 0-7.6 8L84 183.53a28 28 0 0 0-1.91-9.68ZM156 184h-.89l-16.18-48.53 46.65-2.22a27.94 27.94 0 0 0 5.28 9L163.65 185a28 28 0 0 0-7.65-1ZM126.32 61.7a28.44 28.44 0 0 0 7.68 6.54l-11.3 47.45-43.47-25.17A28 28 0 0 0 80 84a28.65 28.65 0 0 0-.15-2.94Z" /></Svg>;
export default FediverseLogo;