/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Avocado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="avocado-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-avocado-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 116a44 44 0 1 0 44 44 44.05 44.05 0 0 0-44-44Zm0 80a36 36 0 1 1 36-36 36 36 0 0 1-36 36Zm79.22-64-29.79-84.2A52 52 0 0 0 79 46.66l-29.55 83.51A84 84 0 1 0 207.22 132ZM128 236a76.06 76.06 0 0 1-71-103.08l29.5-83.59a44 44 0 0 1 83.35 1.05l29.82 84.28A76 76 0 0 1 128 236Z" /></Svg>;
export default Avocado;