/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stethoscope = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stethoscope-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stethoscope-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 160a32 32 0 1 0-39.93 31 8 8 0 0 0-.07 1 32 32 0 0 1-32 32h-24a32 32 0 0 1-32-32v-40.52c31.47-4 56-31.47 56-64.31V40a8 8 0 0 0-8-8h-24a8 8 0 0 0 0 16h16v39.17c0 26.58-21.25 48.49-47.36 48.83A48 48 0 0 1 56 88V48h16a8 8 0 0 0 0-16H48a8 8 0 0 0-8 8v48a64 64 0 0 0 56 63.49V192a48.05 48.05 0 0 0 48 48h24a48.05 48.05 0 0 0 48-48 8 8 0 0 0-.07-1A32 32 0 0 0 240 160Zm-32 8a8 8 0 1 1 8-8 8 8 0 0 1-8 8Z" /></Svg>;
export default Stethoscope;