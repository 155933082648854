/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrafficCone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="traffic-cone-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-traffic-cone-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 208h-18.31L153.42 34.75A16 16 0 0 0 138.31 24h-20.62a16 16 0 0 0-15.11 10.74L42.31 208H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16ZM95.43 104h65.14l16.7 48H78.73Z" /></Svg>;
export default TrafficCone;