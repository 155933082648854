/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Funnel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="funnel-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-funnel-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.9 61.38 152 136v58.65a8 8 0 0 1-3.56 6.66l-32 21.33A8 8 0 0 1 104 216v-80L34.1 61.38A8 8 0 0 1 40 48h176a8 8 0 0 1 5.9 13.38Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M230.6 49.53A15.81 15.81 0 0 0 216 40H40a16 16 0 0 0-11.81 26.76l.08.09L96 139.17V216a16 16 0 0 0 24.87 13.32l32-21.34a16 16 0 0 0 7.13-13.32v-55.49l67.74-72.32.08-.09a15.8 15.8 0 0 0 2.78-17.23ZM40 56Zm106.18 74.58A8 8 0 0 0 144 136v58.66L112 216v-80a8 8 0 0 0-2.16-5.47L40 56h176Z" /></Svg>;
export default Funnel;