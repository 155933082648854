/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberTwo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-two-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-two-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174 208a6 6 0 0 1-6 6H88a6 6 0 0 1-4.8-9.6l72-95.92a34 34 0 0 0-31.88-54.14 34.1 34.1 0 0 0-26.49 20.07 6 6 0 0 1-11-4.81 46.47 46.47 0 0 1 5.43-9.28 46 46 0 0 1 73.48 55.37L100 202h68a6 6 0 0 1 6 6Z" /></Svg>;
export default NumberTwo;