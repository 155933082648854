/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Binary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="binary-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-binary-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48v160a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M94 24c-22.37 0-38 19.74-38 48s15.63 48 38 48 38-19.74 38-48-15.63-48-38-48Zm0 80c-17.37 0-22-20.11-22-32s4.63-32 22-32 22 20.11 22 32-4.63 32-22 32Zm72 32c-22.37 0-38 19.74-38 48s15.63 48 38 48 38-19.74 38-48-15.63-48-38-48Zm0 80c-17.37 0-22-20.11-22-32s4.63-32 22-32 22 20.11 22 32-4.63 32-22 32ZM145 49.22a8 8 0 0 1 3.11-10.88l24-13.33A8 8 0 0 1 184 32v80a8 8 0 0 1-16 0V45.6l-12.12 6.73A8 8 0 0 1 145 49.22ZM104 144v80a8 8 0 0 1-16 0v-66.4l-12.12 6.73a8 8 0 0 1-7.76-14l24-13.33a8 8 0 0 1 11.88 7Z" /></Svg>;
export default Binary;