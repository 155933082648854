/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const QrCode = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="qr-code-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-qr-code-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 42H56a14 14 0 0 0-14 14v48a14 14 0 0 0 14 14h48a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14Zm2 62a2 2 0 0 1-2 2H56a2 2 0 0 1-2-2V56a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2Zm-2 34H56a14 14 0 0 0-14 14v48a14 14 0 0 0 14 14h48a14 14 0 0 0 14-14v-48a14 14 0 0 0-14-14Zm2 62a2 2 0 0 1-2 2H56a2 2 0 0 1-2-2v-48a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2Zm94-158h-48a14 14 0 0 0-14 14v48a14 14 0 0 0 14 14h48a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14Zm2 62a2 2 0 0 1-2 2h-48a2 2 0 0 1-2-2V56a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2Zm-64 72v-32a6 6 0 0 1 12 0v32a6 6 0 0 1-12 0Zm76-16a6 6 0 0 1-6 6h-26v42a6 6 0 0 1-6 6h-32a6 6 0 0 1 0-12h26v-58a6 6 0 0 1 12 0v10h26a6 6 0 0 1 6 6Zm0 32v16a6 6 0 0 1-12 0v-16a6 6 0 0 1 12 0Z" /></Svg>;
export default QrCode;