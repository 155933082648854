/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IceCream = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ice-cream-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ice-cream-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206 98.83V96a78 78 0 0 0-156 0v2.83A22 22 0 0 0 56 142h4.45l55.39 97a14 14 0 0 0 24.32 0l55.39-97H200a22 22 0 0 0 6-43.17ZM129.74 233a2 2 0 0 1-3.48 0l-52-91h24L140 215.06Zm6.26-91 22.89 40.06-12 20.91-34.84-61Zm29.8 28-16-28h32Zm34.2-40H56a10 10 0 0 1 0-20 6 6 0 0 0 6-6v-8a66 66 0 0 1 132 0v8a6 6 0 0 0 6 6 10 10 0 0 1 0 20Z" /></Svg>;
export default IceCream;