/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Barricade = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="barricade-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-barricade-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 60H32a20 20 0 0 0-20 20v72a20 20 0 0 0 20 20h20v28a12 12 0 0 0 24 0v-28h104v28a12 12 0 0 0 24 0v-28h20a20 20 0 0 0 20-20V80a20 20 0 0 0-20-20Zm-4 59-35-35h35Zm-43 29-64-64h38l64 64Zm-72 0L41 84h38l64 64Zm-69-35 35 35H36Z" /></Svg>;
export default Barricade;