/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Money = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="money-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-money-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 164a36 36 0 1 0-36-36 36 36 0 0 0 36 36Zm0-64a28 28 0 1 1-28 28 28 28 0 0 1 28-28Zm112-40H16a4 4 0 0 0-4 4v128a4 4 0 0 0 4 4h224a4 4 0 0 0 4-4V64a4 4 0 0 0-4-4ZM20 107.26A52.75 52.75 0 0 0 59.26 68h137.48A52.75 52.75 0 0 0 236 107.26v41.48A52.75 52.75 0 0 0 196.74 188H59.26A52.75 52.75 0 0 0 20 148.74ZM236 99a44.8 44.8 0 0 1-31-31h31ZM51 68a44.8 44.8 0 0 1-31 31V68Zm-31 89a44.8 44.8 0 0 1 31 31H20Zm185 31a44.8 44.8 0 0 1 31-31v31Z" /></Svg>;
export default Money;