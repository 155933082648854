/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Windmill = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="windmill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-windmill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 224h-41.06l-6.3-44.12 3.24 1.91a16 16 0 0 0 21.91-5.67l12-20.34a16 16 0 0 0-5.67-21.91l-35-20.61 40.69-69.13a16 16 0 0 0-5.67-21.91l-20.34-12a16 16 0 0 0-21.91 5.67l-20.61 35-69.16-40.67a16 16 0 0 0-21.91 5.67l-12 20.33a16 16 0 0 0 5.67 21.92l35 20.61-40.67 69.13a16 16 0 0 0 5.67 21.91l20.34 12a15.57 15.57 0 0 0 10.58 2L73.06 224H32a8 8 0 0 0 0 16h192a8 8 0 0 0 0-16Zm-24-76.34L188 168l-69.13-40.69 12-20.35ZM179.66 24 200 36l-40.69 69.14L139 93.17ZM56 44.35 68 24l69.14 40.7-12 20.35ZM76.34 168 56 156l40.69-69.14 20.36 12Zm12.88 56L98 162.8l12.77-21.7L159 169.5l7.79 54.5Z" /></Svg>;
export default Windmill;