/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleHalfTilt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-half-tilt-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-half-tilt-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204.37 51.6A108.08 108.08 0 1 0 236 128a108.09 108.09 0 0 0-31.63-76.4ZM176 197a83.43 83.43 0 0 1-16 8.75V113l16-16ZM68.6 68.58a84.08 84.08 0 0 1 109.7-7.88L60.72 178.33A84.08 84.08 0 0 1 68.6 68.58ZM96 177v28.69a83.63 83.63 0 0 1-18.3-10.39Zm24 34.62V153l16-16v74.64a84.68 84.68 0 0 1-16-.02Zm80-40.27v-86.7a84.24 84.24 0 0 1 0 86.7Z" /></Svg>;
export default CircleHalfTilt;