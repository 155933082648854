/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BowlSteam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bowl-steam-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bowl-steam-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M81.77 55c5.35-6.66 6.67-11.16 6.12-13.14-.42-1.49-2.41-2.26-2.43-2.26A8 8 0 0 1 88 24a8.11 8.11 0 0 1 2.38.36c1 .31 9.91 3.33 12.79 12.76 2.46 8.07-.55 17.45-8.94 27.89-5.35 6.66-6.67 11.16-6.12 13.14.42 1.49 2.37 2.24 2.39 2.25A8 8 0 0 1 88 96a8.11 8.11 0 0 1-2.38-.36c-1-.31-9.91-3.33-12.79-12.76-2.46-8.07.55-17.45 8.94-27.88Zm31.06 27.89c2.88 9.43 11.79 12.45 12.79 12.76A8.11 8.11 0 0 0 128 96a8 8 0 0 0 2.5-15.6s-2-.76-2.39-2.25c-.55-2 .77-6.48 6.12-13.14 8.39-10.44 11.4-19.82 8.94-27.89-2.88-9.43-11.78-12.45-12.79-12.76A8.11 8.11 0 0 0 128 24a8 8 0 0 0-2.54 15.59s2 .77 2.43 2.26c.55 2-.77 6.48-6.12 13.14-8.39 10.44-11.4 19.82-8.94 27.89Zm40 0c2.88 9.43 11.79 12.45 12.79 12.76A8.11 8.11 0 0 0 168 96a8 8 0 0 0 2.5-15.6s-2-.76-2.39-2.25c-.55-2 .77-6.48 6.12-13.14 8.39-10.44 11.4-19.82 8.94-27.89-2.88-9.43-11.78-12.45-12.79-12.76A8.11 8.11 0 0 0 168 24a8 8 0 0 0-2.54 15.59s2 .77 2.43 2.26c.55 2-.77 6.48-6.12 13.14-8.39 10.44-11.4 19.82-8.94 27.89ZM224 112H32a8 8 0 0 0-8 8 104.35 104.35 0 0 0 56 92.28V216a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-3.72A104.35 104.35 0 0 0 232 120a8 8 0 0 0-8-8Z" /></Svg>;
export default BowlSteam;