/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreePalm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tree-palm-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tree-palm-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 58.75 128 104a65.47 65.47 0 0 1 17.84-45.25 58.87 58.87 0 0 1 86.16 0Zm-55.44 39.43a64.2 64.2 0 0 0-48.56 6.34L221.81 176a63 63 0 0 0-6.39-48.22 63.73 63.73 0 0 0-38.86-29.6Zm-66.4-39.43a58.87 58.87 0 0 0-86.16 0L128 104a65.47 65.47 0 0 0-17.84-45.25ZM79.44 98.18a63.73 63.73 0 0 0-38.86 29.6A63 63 0 0 0 34.19 176L128 104.52a64.2 64.2 0 0 0-48.56-6.34Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M237.79 53.23a66.86 66.86 0 0 0-97.74 0 72.21 72.21 0 0 0-12.05 17 72.21 72.21 0 0 0-12-17 66.86 66.86 0 0 0-97.74 0 8 8 0 0 0 2.6 12.85L77 90.55a71.42 71.42 0 0 0-43.36 33.21 70.64 70.64 0 0 0-7.2 54.32A8 8 0 0 0 39 182.36l81-61.68V224a8 8 0 0 0 16 0V120.68l81 61.68a8 8 0 0 0 12.57-4.28 70.64 70.64 0 0 0-7.2-54.32A71.42 71.42 0 0 0 179 90.55l56.22-24.47a8 8 0 0 0 2.6-12.85ZM67.08 48a51.13 51.13 0 0 1 37.28 16.26 56.53 56.53 0 0 1 14.26 26.93L39 56.53A50.5 50.5 0 0 1 67.08 48ZM40 161.5a54.82 54.82 0 0 1 7.47-29.7 55.55 55.55 0 0 1 34-25.89A56.52 56.52 0 0 1 96.1 104a55.82 55.82 0 0 1 16.23 2.41Zm168.5-29.7a54.82 54.82 0 0 1 7.5 29.7l-72.3-55.1a56.3 56.3 0 0 1 64.83 25.4Zm-71.12-40.61a56.53 56.53 0 0 1 14.26-26.93A51.13 51.13 0 0 1 188.92 48 50.5 50.5 0 0 1 217 56.53Z" /></Svg>;
export default TreePalm;