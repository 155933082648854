/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmReel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-reel-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-reel-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 212h-36.2a107.94 107.94 0 1 0-67.8 24h104a12 12 0 0 0 0-24ZM44 128a84 84 0 1 1 84 84 84.09 84.09 0 0 1-84-84Zm64-44a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm20 108a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm24-64a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm-48 0a20 20 0 1 1-20-20 20 20 0 0 1 20 20Z" /></Svg>;
export default FilmReel;