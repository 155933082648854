/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rss = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M105.5 150.5A69.54 69.54 0 0 1 126 200a6 6 0 0 1-12 0 58 58 0 0 0-58-58 6 6 0 0 1 0-12 69.54 69.54 0 0 1 49.5 20.5ZM56 82a6 6 0 0 0 0 12 106 106 0 0 1 106 106 6 6 0 0 0 12 0A118 118 0 0 0 56 82Zm117.38.62A164.92 164.92 0 0 0 56 34a6 6 0 0 0 0 12 153 153 0 0 1 108.89 45.11A153 153 0 0 1 210 200a6 6 0 0 0 12 0 164.92 164.92 0 0 0-48.62-117.38ZM60 186a10 10 0 1 0 10 10 10 10 0 0 0-10-10Z" /></Svg>;
export default Rss;