/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HairDryer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hair-dryer-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hair-dryer-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M177.42 206.62 209 137.07A64 64 0 0 0 168 24a8.4 8.4 0 0 0-1.32.11L29.37 47A16 16 0 0 0 16 62.78v50.44A16 16 0 0 0 29.37 129L128 145.44V200a16 16 0 0 0 16 16 40 40 0 0 0 40 40h16a8 8 0 0 0 0-16h-16a24 24 0 0 1-24-24h2.85a16 16 0 0 0 14.57-9.38ZM192 88a24 24 0 1 1-24-24 24 24 0 0 1 24 24Zm-25.32 63.89a8.4 8.4 0 0 0 1.32.11 63.9 63.9 0 0 0 17.82-2.54l-23 50.54H144v-51.89Z" /></Svg>;
export default HairDryer;