/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-star-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-star-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M76.86 115.54a12 12 0 0 1 15.6-6.68l23.54 9.42V96a12 12 0 0 1 24 0v22.28l23.54-9.42a12 12 0 0 1 8.92 22.28L147 141.33l14.6 19.47a12 12 0 1 1-19.2 14.4L128 156l-14.4 19.2a12 12 0 1 1-19.2-14.4l14.6-19.47-25.46-10.19a12 12 0 0 1-6.68-15.6ZM228 56v56c0 54.29-26.32 87.22-48.4 105.29-23.71 19.39-47.44 26-48.44 26.29a12.1 12.1 0 0 1-6.32 0c-1-.28-24.73-6.9-48.44-26.29C54.32 199.22 28 166.29 28 112V56a20 20 0 0 1 20-20h160a20 20 0 0 1 20 20Zm-24 4H52v52c0 35.71 13.09 64.69 38.91 86.15A126.14 126.14 0 0 0 128 219.38a126.28 126.28 0 0 0 37.09-21.23C190.91 176.69 204 147.71 204 112Z" /></Svg>;
export default ShieldStar;