/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dresser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dresser-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dresser-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v40a4 4 0 0 0 4 4h168a4 4 0 0 0 4-4V40a16 16 0 0 0-16-16Zm-64 40h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm76 36H44a4 4 0 0 0-4 4v48a4 4 0 0 0 4 4h168a4 4 0 0 0 4-4v-48a4 4 0 0 0-4-4Zm-76 36h-15.73a8.18 8.18 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h15.73a8.18 8.18 0 0 1 8.25 7.47 8 8 0 0 1-8 8.53Zm76 36H44a4 4 0 0 0-4 4v40a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16v-40a4 4 0 0 0-4-4Zm-76 36h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Z" /></Svg>;
export default Dresser;