/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Broadcast = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="broadcast-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-broadcast-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 92a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm76-28a75.74 75.74 0 0 1-19.35 50.67 4 4 0 0 1-6-5.34 67.92 67.92 0 0 0 0-90.66 4 4 0 0 1 6-5.34A75.74 75.74 0 0 1 204 128ZM65.34 101.53a67.92 67.92 0 0 0 12 71.8 4 4 0 0 1-6 5.34 75.93 75.93 0 0 1 0-101.34 4 4 0 1 1 6 5.34 68 68 0 0 0-12 18.86ZM244 128a115.68 115.68 0 0 1-33.14 81.18 4 4 0 0 1-5.72-5.6 107.89 107.89 0 0 0 0-151.16 4 4 0 0 1 5.72-5.6A115.68 115.68 0 0 1 244 128ZM50.86 203.58a4 4 0 0 1-5.72 5.6 115.91 115.91 0 0 1 0-162.36 4 4 0 1 1 5.72 5.6 107.89 107.89 0 0 0 0 151.16Z" /></Svg>;
export default Broadcast;