/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListBullets = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-bullets-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-bullets-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM68 188a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-48a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-48a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm124 92h-88a8 8 0 0 1 0-16h88a8 8 0 0 1 0 16Zm0-48h-88a8 8 0 0 1 0-16h88a8 8 0 0 1 0 16Zm0-48h-88a8 8 0 0 1 0-16h88a8 8 0 0 1 0 16Z" /></Svg>;
export default ListBullets;