/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Perspective = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="perspective__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-perspective'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 120h-16V48a16 16 0 0 0-18.86-15.74l-160 29.09A16 16 0 0 0 32 77.09V120H16a8 8 0 0 0 0 16h16v42.91a16 16 0 0 0 13.14 15.74l160 29.09a16.47 16.47 0 0 0 2.86.26 16 16 0 0 0 16-16v-72h16a8 8 0 0 0 0-16ZM48 77.09 208 48v72H48ZM208 208 48 178.91V136h160Z" /></Svg>;
export default Perspective;