/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingCartSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-cart-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-cart-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m239.71 74.14-25.64 92.28A24.06 24.06 0 0 1 191 184H92.16A24.06 24.06 0 0 1 69 166.42L33.92 40H16a8 8 0 0 1 0-16h24a8 8 0 0 1 7.71 5.86L57.19 64H232a8 8 0 0 1 7.71 10.14ZM88 200a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm104 0a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default ShoppingCartSimple;