/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardropText = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-text-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-text-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 108a12 12 0 0 1-12 12H96a12 12 0 0 1 0-24h68a12 12 0 0 1 12 12Zm-12 28H96a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Zm72-12a104.11 104.11 0 0 1-104 104H48a20 20 0 0 1-20-20v-84a104 104 0 0 1 208 0Zm-24 0a80 80 0 0 0-160 0v80h80a80.09 80.09 0 0 0 80-80Z" /></Svg>;
export default ChatTeardropText;