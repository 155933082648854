/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FloppyDiskBack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="floppy-disk-back-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-floppy-disk-back-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H83.31a13.94 13.94 0 0 0-9.9 4.1L38.1 73.41a13.94 13.94 0 0 0-4.1 9.9V208a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14ZM86 46h84v34a2 2 0 0 1-2 2H88a2 2 0 0 1-2-2Zm124 162a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2V83.31a2 2 0 0 1 .59-1.41L74 54.48V80a14 14 0 0 0 14 14h80a14 14 0 0 0 14-14V46h26a2 2 0 0 1 2 2Zm-82-94a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm0 64a26 26 0 1 1 26-26 26 26 0 0 1-26 26Z" /></Svg>;
export default FloppyDiskBack;