/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderFemale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-female-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-female-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206 96a78 78 0 1 0-84 77.75V202H88a6 6 0 0 0 0 12h34v26a6 6 0 0 0 12 0v-26h34a6 6 0 0 0 0-12h-34v-28.25A78.09 78.09 0 0 0 206 96ZM62 96a66 66 0 1 1 66 66 66.08 66.08 0 0 1-66-66Z" /></Svg>;
export default GenderFemale;