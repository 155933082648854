/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seatbelt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seatbelt-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seatbelt-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 108a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm0-72a32 32 0 1 1-32 32 32 32 0 0 1 32-32Zm76 188a4 4 0 0 1-4 4H56a4 4 0 0 1-2.65-7l103-90.84a68 68 0 0 0-95.44 51 4 4 0 0 1-3.94 3.37 4.48 4.48 0 0 1-.64-.05 4 4 0 0 1-3.33-4.59 76 76 0 0 1 109.8-55.46L189.35 101a4 4 0 1 1 5.3 6L66.58 220H200a4 4 0 0 1 4 4Zm-13.56-75.33A75.52 75.52 0 0 1 204 192a4 4 0 0 1-8 0 67.55 67.55 0 0 0-12.13-38.77 4 4 0 1 1 6.57-4.56Z" /></Svg>;
export default Seatbelt;