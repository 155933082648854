/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SortAscending = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sort-ascending-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sort-ascending-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 128a12 12 0 0 1-12 12H48a12 12 0 0 1 0-24h68a12 12 0 0 1 12 12ZM48 76h132a12 12 0 0 0 0-24H48a12 12 0 0 0 0 24Zm52 104H48a12 12 0 0 0 0 24h52a12 12 0 0 0 0-24Zm132.49-20.49a12 12 0 0 0-17 0L196 179v-67a12 12 0 0 0-24 0v67l-19.51-19.52a12 12 0 0 0-17 17l40 40a12 12 0 0 0 17 0l40-40a12 12 0 0 0 0-16.97Z" /></Svg>;
export default SortAscending;