/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Prescription = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="prescription-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-prescription-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m177.66 188 25.17-25.17a4 4 0 0 0-5.66-5.66L172 182.34 121.66 132H124a48 48 0 0 0 0-96H72a4 4 0 0 0-4 4v152a4 4 0 0 0 8 0v-60h34.34l56 56-25.17 25.17a4 4 0 0 0 5.66 5.66L172 193.66l25.17 25.17a4 4 0 0 0 5.66-5.66ZM76 44h48a40 40 0 0 1 0 80H76Z" /></Svg>;
export default Prescription;