/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShirtFolded = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shirt-folded-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shirt-folded-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 44h-22.34l-14.83-14.83A4 4 0 0 0 160 28H96a4 4 0 0 0-2.83 1.17L78.34 44H56a12 12 0 0 0-12 12v152a12 12 0 0 0 12 12h144a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12Zm-72 28.79L103.47 36h49.06Zm32.62-34.51L172 49.66V104a4 4 0 0 1-2.3 3.63 3.93 3.93 0 0 1-4.21-.51l-32.2-27.82ZM84 49.66l11.38-11.38 27.33 41-32.17 27.8A4 4 0 0 1 84 104ZM52 208V56a4 4 0 0 1 4-4h20v52a11.89 11.89 0 0 0 6.91 10.87A12.08 12.08 0 0 0 88 116a11.88 11.88 0 0 0 7.65-2.8h.06L124 88.74V212H56a4 4 0 0 1-4-4Zm152 0a4 4 0 0 1-4 4h-68V88.74l28.32 24.46A11.9 11.9 0 0 0 168 116a12.08 12.08 0 0 0 5.12-1.15A11.89 11.89 0 0 0 180 104V52h20a4 4 0 0 1 4 4Z" /></Svg>;
export default ShirtFolded;