/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CastleTurret = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="castle-turret-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-castle-turret-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 220h-20V113.66l12.49-12.49a11.93 11.93 0 0 0 3.51-8.48V48a12 12 0 0 0-12-12h-24a4 4 0 0 0-4 4v28h-24V40a4 4 0 0 0-4-4h-32a4 4 0 0 0-4 4v28H84V40a4 4 0 0 0-4-4H56a12 12 0 0 0-12 12v44.69a11.93 11.93 0 0 0 3.51 8.48L60 113.66V220H40a4 4 0 0 0 0 8h176a4 4 0 0 0 0-8ZM66.83 109.17 53.17 95.51A4 4 0 0 1 52 92.69V48a4 4 0 0 1 4-4h20v28a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V44h24v28a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V44h20a4 4 0 0 1 4 4v44.69a4 4 0 0 1-1.17 2.82l-13.66 13.66A4 4 0 0 0 188 112v108h-32v-52a28 28 0 0 0-56 0v52H68V112a4 4 0 0 0-1.17-2.83ZM148 220h-40v-52a20 20 0 0 1 40 0Z" /></Svg>;
export default CastleTurret;