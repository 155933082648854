/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Subway = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subway-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subway-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 96v112a6 6 0 0 1-12 0V96a58.07 58.07 0 0 0-58-58h-48a58.07 58.07 0 0 0-58 58v112a6 6 0 0 1-12 0V96a70.08 70.08 0 0 1 70-70h48a70.08 70.08 0 0 1 70 70Zm-40 0v72a22 22 0 0 1-20.33 21.93l3.7 7.39a6 6 0 0 1-10.74 5.36L148.29 190h-40.58l-6.34 12.68a6 6 0 1 1-10.74-5.36l3.7-7.39A22 22 0 0 1 74 168V96a22 22 0 0 1 22-22h64a22 22 0 0 1 22 22Zm-96 0v50h84V96a10 10 0 0 0-10-10H96a10 10 0 0 0-10 10Zm32 62v20h20v-20Zm-22 20h10v-20H86v10a10 10 0 0 0 10 10Zm74-10v-10h-20v20h10a10 10 0 0 0 10-10Z" /></Svg>;
export default Subway;