/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SnapchatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="snapchat-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-snapchat-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M247.83 182.28a8 8 0 0 0-5.13-5.9c-.39-.14-28.95-10.88-43-49.23l19.3-7.72a8 8 0 1 0-6-14.86l-17.82 7.13A149 149 0 0 1 192 80a64 64 0 0 0-128 0 151.24 151.24 0 0 1-3.18 31.75L43 104.57a8 8 0 1 0-6 14.86l19.37 7.75a94 94 0 0 1-17.74 30.2c-12.52 14.14-25.27 19-25.36 19a8 8 0 0 0-2.77 13.36c7.1 6.67 17.67 7.71 27.88 8.72 6.31.62 12.83 1.27 16.39 3.23 3.37 1.86 6.85 6.62 10.21 11.22 5.4 7.41 11.53 15.8 21.24 18.28 9.07 2.33 18.35-.83 26.54-3.62 5.55-1.89 10.8-3.68 15.21-3.68s9.66 1.79 15.21 3.68c6.2 2.11 13 4.43 19.9 4.43a26.35 26.35 0 0 0 6.64-.81c9.7-2.48 15.83-10.87 21.23-18.28 3.36-4.6 6.84-9.36 10.21-11.22 3.56-2 10.08-2.61 16.39-3.23 10.21-1 20.78-2.05 27.88-8.72a8 8 0 0 0 2.4-7.46Zm-31.82.26c-7.91.78-16.08 1.59-22.53 5.13s-11 9.79-15.41 15.81c-4 5.48-8.15 11.16-12.28 12.21-4.46 1.15-10.76-1-17.42-3.27s-13.31-4.53-20.37-4.53-13.83 2.3-20.37 4.53-13 4.42-17.42 3.27c-4.13-1.05-8.27-6.73-12.28-12.21-4.39-6-8.93-12.24-15.41-15.81S47.9 183.32 40 182.54c-1.55-.15-3.15-.31-4.74-.49a97.34 97.34 0 0 0 14.69-13.29c8.37-9.27 17.72-23.23 23.74-43.13l.06-.13a8.63 8.63 0 0 0 .46-1.61A158.47 158.47 0 0 0 80 80a48 48 0 0 1 96 0 158.42 158.42 0 0 0 5.8 43.92 8.63 8.63 0 0 0 .46 1.61l.06.13c6 19.9 15.37 33.86 23.74 43.13a97.34 97.34 0 0 0 14.69 13.29c-1.59.15-3.18.31-4.75.46Z" /></Svg>;
export default SnapchatLogo;