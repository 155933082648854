/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Quotes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="quotes-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-quotes-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 60H40a12 12 0 0 0-12 12v64a12 12 0 0 0 12 12h64v12a36 36 0 0 1-36 36 4 4 0 0 0 0 8 44.05 44.05 0 0 0 44-44V72a12 12 0 0 0-12-12Zm4 80H40a4 4 0 0 1-4-4V72a4 4 0 0 1 4-4h60a4 4 0 0 1 4 4Zm112-80h-60a12 12 0 0 0-12 12v64a12 12 0 0 0 12 12h64v12a36 36 0 0 1-36 36 4 4 0 0 0 0 8 44.05 44.05 0 0 0 44-44V72a12 12 0 0 0-12-12Zm4 80h-64a4 4 0 0 1-4-4V72a4 4 0 0 1 4-4h60a4 4 0 0 1 4 4Z" /></Svg>;
export default Quotes;