/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListChecks = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-checks-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-checks-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 128a12 12 0 0 1-12 12h-88a12 12 0 0 1 0-24h88a12 12 0 0 1 12 12ZM128 76h88a12 12 0 0 0 0-24h-88a12 12 0 0 0 0 24Zm88 104h-88a12 12 0 0 0 0 24h88a12 12 0 0 0 0-24ZM79.51 39.51 56 63l-7.51-7.52a12 12 0 0 0-17 17l16 16a12 12 0 0 0 17 0l32-32a12 12 0 0 0-17-17Zm0 64L56 127l-7.51-7.52a12 12 0 1 0-17 17l16 16a12 12 0 0 0 17 0l32-32a12 12 0 0 0-17-17Zm0 64L56 191l-7.51-7.52a12 12 0 1 0-17 17l16 16a12 12 0 0 0 17 0l32-32a12 12 0 0 0-17-17Z" /></Svg>;
export default ListChecks;