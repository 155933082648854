/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bulldozer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bulldozer-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bulldozer-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 200h-8a8 8 0 0 1-8-8v-32a8 8 0 0 1 8-8h8a8 8 0 0 0 0-16h-8a24 24 0 0 0-24 24v8h-16.8a40.1 40.1 0 0 0-33.71-31.61l-36.05-86.54A16 16 0 0 0 114.67 40H24A16 16 0 0 0 8 56v96a40 40 0 0 0 32 64h120a40.07 40.07 0 0 0 39.2-32H216v8a24 24 0 0 0 24 24h8a8 8 0 0 0 0-16ZM64 56h50.67L148 136H64Zm-40 0h24v80h-8a39.72 39.72 0 0 0-16 3.35Zm136 128H40a8 8 0 0 1 0-16h120a8 8 0 0 1 0 16Z" /></Svg>;
export default Bulldozer;