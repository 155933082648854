/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SteeringWheel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="steering-wheel-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-steering-wheel-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 144a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm92-16A108 108 0 1 1 128 20a108.12 108.12 0 0 1 108 108Zm-70.45 28h41.63c.79-2.21 1.49-4.47 2.09-6.76a116 116 0 0 0-162.54 0q.9 3.44 2.09 6.76h41.63a20.07 20.07 0 0 1 18.73 13l16.06 42.93c.92 0 1.83.07 2.76.07s1.82 0 2.72-.07l16.1-43A20.09 20.09 0 0 1 165.55 156ZM44.41 119.73a139.85 139.85 0 0 1 167.18 0 84 84 0 0 0-167.18 0Zm53.08 86.51L87.68 180H62.1a84.46 84.46 0 0 0 35.39 26.24ZM193.9 180h-25.58l-9.84 26.25A84.35 84.35 0 0 0 193.9 180Z" /></Svg>;
export default SteeringWheel;