/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCpp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-cpp-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-cpp-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M44 120h168a4 4 0 0 0 4-4V88a8 8 0 0 0-2.34-5.66l-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v76a4 4 0 0 0 4 4Zm108-76 44 44h-44ZM48 180c0 11 7.18 20 16 20a14.07 14.07 0 0 0 10.07-4.51 8.19 8.19 0 0 1 10.88-.9 8 8 0 0 1 .83 11.81A30.06 30.06 0 0 1 64 216c-17.65 0-32-16.15-32-36s14.35-36 32-36a30 30 0 0 1 21.39 9.2 8.24 8.24 0 0 1 .73 11.08 8 8 0 0 1-11.9.38A14.18 14.18 0 0 0 64 160c-8.82 0-16 9-16 20Zm108 .53a8.18 8.18 0 0 1-8.25 7.47H136v11.73a8.17 8.17 0 0 1-7.47 8.25 8 8 0 0 1-8.53-8V188h-11.73a8.18 8.18 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h12v-11.73a8.17 8.17 0 0 1 7.47-8.25 8 8 0 0 1 8.53 8v12h12a8 8 0 0 1 7.98 8.51Zm68 0a8.18 8.18 0 0 1-8.25 7.47H204v11.73a8.17 8.17 0 0 1-7.47 8.25 8 8 0 0 1-8.53-8V188h-11.73a8.18 8.18 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h12v-11.73a8.17 8.17 0 0 1 7.47-8.25 8 8 0 0 1 8.53 8v12h12a8 8 0 0 1 7.98 8.51Z" /></Svg>;
export default FileCpp;