/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyJpy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-jpy-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-jpy-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M203.1 50.53 136.44 132H176a4 4 0 0 1 0 8h-44v24h44a4 4 0 0 1 0 8h-44v44a4 4 0 0 1-8 0v-44H80a4 4 0 0 1 0-8h44v-24H80a4 4 0 0 1 0-8h39.56L52.9 50.53a4 4 0 0 1 6.2-5.06l68.9 84.21 68.9-84.21a4 4 0 1 1 6.2 5.06Z" /></Svg>;
export default CurrencyJpy;