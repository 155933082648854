/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stool-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stool-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 64V40a12 12 0 0 0-12-12H72a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h11.32L60.05 223.38a4 4 0 0 0 3.33 4.62 4.46 4.46 0 0 0 .62 0 4 4 0 0 0 4-3.38L76.26 172h103.48l8.31 52.62A4 4 0 0 0 192 228a4.46 4.46 0 0 0 .63-.05 4 4 0 0 0 3.33-4.57L172.68 76H184a12 12 0 0 0 12-12ZM68 64V40a4 4 0 0 1 4-4h112a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H72a4 4 0 0 1-4-4Zm110.48 100h-101l13.9-88h73.16Z" /></Svg>;
export default Stool;