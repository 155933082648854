/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Share = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="share-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-share-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m232.49 112.49-48 48a12 12 0 0 1-17-17L195 116h-30a84 84 0 0 0-81.36 63 12 12 0 1 1-23.24-6A107.94 107.94 0 0 1 165 92h30l-27.49-27.52a12 12 0 0 1 17-17l48 48a12 12 0 0 1-.02 17.01ZM192 204H44V88a12 12 0 0 0-24 0v128a12 12 0 0 0 12 12h160a12 12 0 0 0 0-24Z" /></Svg>;
export default Share;