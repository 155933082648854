/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotesPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-plus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-plus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 48a8 8 0 0 1-8 8h-16v16a8 8 0 0 1-16 0V56h-16a8 8 0 0 1 0-16h16V24a8 8 0 0 1 16 0v16h16a8 8 0 0 1 8 8Zm-71.4 29.86-6.76-6.76A32.85 32.85 0 0 1 144 49.33a31.87 31.87 0 0 1 1.67-11.66 4 4 0 0 0-4.76-5.14L78.06 48.25A8 8 0 0 0 72 56v110.1A36 36 0 1 0 52.42 232C72.25 231.77 88 215.13 88 195.3v-93.05l70.74-17.69a4 4 0 0 0 1.86-6.7Zm50.11 24.31a31.91 31.91 0 0 1-7.14 1.63 4 4 0 0 0-3.57 4v26.3a36 36 0 1 0-19.58 65.9c19.83-.23 35.58-16.86 35.58-36.7V106a4 4 0 0 0-5.29-3.83Z" /></Svg>;
export default MusicNotesPlus;