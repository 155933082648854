/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FalloutShelter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fallout-shelter-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fallout-shelter-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 192a92 92 0 1 1 92-92 92.1 92.1 0 0 1-92 92Zm67.53-93.89A4 4 0 0 0 192 124h-56.53l27.86-41.78A4 4 0 0 0 160 76H96a4 4 0 0 0-3.33 6.22L120.53 124H64a4 4 0 0 0-3.33 6.22l32 48a4 4 0 0 0 6.66 0l28.67-43 28.67 43a4 4 0 0 0 6.66 0l32-48a4 4 0 0 0 .2-4.11Zm-43-42.11L128 120.79 103.47 84ZM96 168.79 71.47 132h49.06Zm64 0L135.47 132h49.06Z" /></Svg>;
export default FalloutShelter;