/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cookie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cookie-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cookie-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a96 96 0 1 1-96-96 48 48 0 0 0 48 48 48 48 0 0 0 48 48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M164.49 163.51a12 12 0 1 1-17 0 12 12 0 0 1 17 0Zm-81-8a12 12 0 1 0 17 0 12 12 0 0 0-16.98 0Zm9-39a12 12 0 1 0-17 0 12 12 0 0 0 17-.02Zm48-1a12 12 0 1 0 0 17 12 12 0 0 0 0-17ZM232 128A104 104 0 1 1 128 24a8 8 0 0 1 8 8 40 40 0 0 0 40 40 8 8 0 0 1 8 8 40 40 0 0 0 40 40 8 8 0 0 1 8 8Zm-16.31 7.39A56.13 56.13 0 0 1 168.5 87.5a56.13 56.13 0 0 1-47.89-47.19 88 88 0 1 0 95.08 95.08Z" /></Svg>;
export default Cookie;