/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyNgn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-ngn-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-ngn-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M143.55 136H160v23ZM96 120h16.45L96 97Zm136 8A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-32 0a8 8 0 0 0-8-8h-16V72a8 8 0 0 0-16 0v48h-27.88L94.51 67.35A8 8 0 0 0 80 72v48H64a8 8 0 0 0 0 16h16v48a8 8 0 0 0 16 0v-48h27.88l37.61 52.65A8 8 0 0 0 168 192a7.91 7.91 0 0 0 2.44-.38A8 8 0 0 0 176 184v-48h16a8 8 0 0 0 8-8Z" /></Svg>;
export default CurrencyNgn;