/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Key = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="key-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-key-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 76a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm48 22.74A84.3 84.3 0 0 1 160.11 180H160a83.52 83.52 0 0 1-23.65-3.38l-7.86 7.87A12 12 0 0 1 120 188h-12v12a12 12 0 0 1-12 12H84v12a12 12 0 0 1-12 12H40a20 20 0 0 1-20-20v-28.69a19.86 19.86 0 0 1 5.86-14.14l53.52-53.52A84 84 0 1 1 244 98.74Zm-41.57-45.17A59.48 59.48 0 0 0 158 36c-32 1-58 27.89-58 59.89a59.69 59.69 0 0 0 4.2 22.19 12 12 0 0 1-2.55 13.21L44 189v23h16v-12a12 12 0 0 1 12-12h12v-12a12 12 0 0 1 12-12h19l9.65-9.65a12 12 0 0 1 13.22-2.55A59.58 59.58 0 0 0 160 156h.08c32 0 58.87-26.07 59.89-58a59.55 59.55 0 0 0-17.54-44.43Z" /></Svg>;
export default Key;