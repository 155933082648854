/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AsteriskSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="asterisk-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-asterisk-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 128a72 72 0 1 1-72-72 72 72 0 0 1 72 72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m211 103.43-70.13 28 49.47 63.61a8 8 0 1 1-12.63 9.82L128 141l-49.68 63.91a8 8 0 0 1-12.63-9.82l49.47-63.61L45 103.43a8 8 0 0 1 6-14.86l69 27.61V40a8 8 0 0 1 16 0v76.18l69-27.61a8 8 0 1 1 6 14.86Z" /></Svg>;
export default AsteriskSimple;