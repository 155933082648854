/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Recycle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="recycle-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-recycle-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 208a4 4 0 0 1-4 4H40a20 20 0 0 1-17.31-30l39.14-67.62-23.43 6.27a4 4 0 1 1-2.07-7.72l32.77-8.77A4 4 0 0 1 74 107l8.79 32.77a4 4 0 0 1-2.82 4.9 3.77 3.77 0 0 1-1 .14 4 4 0 0 1-3.86-3l-6.3-23.47L29.65 186A12 12 0 0 0 40 204h48a4 4 0 0 1 4 4Zm141.28-26-23.14-40a4 4 0 0 0-6.93 4l23.14 40A12 12 0 0 1 216 204h-78.34l17.17-17.17a4 4 0 0 0-5.66-5.66l-24 24a4 4 0 0 0 0 5.66l24 24a4 4 0 0 0 5.66-5.66L137.66 212H216a20 20 0 0 0 17.31-30Zm-94.9-148 39.14 67.62-23.43-6.28a4 4 0 0 0-2.07 7.73l32.78 8.78a3.65 3.65 0 0 0 1 .14 4 4 0 0 0 3.86-3l8.79-32.79a4 4 0 1 0-7.72-2.07l-6.3 23.47L145.31 30a20 20 0 0 0-34.62 0L87.53 70a4 4 0 0 0 6.93 4l23.15-40a12 12 0 0 1 20.77 0Z" /></Svg>;
export default Recycle;