/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegments = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segments-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segments-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.81 75.79A27.91 27.91 0 0 1 216 84a28.49 28.49 0 0 1-5.67-.58l-30.57 56.77a28 28 0 1 1-44.43 6.49l-26.06-26.06A28.07 28.07 0 0 1 96 124a28.41 28.41 0 0 1-5.67-.58l-30.57 56.76a28 28 0 1 1-39.6 0 28 28 0 0 1 25.47-7.61L76.2 115.8a28.05 28.05 0 0 1 0-39.61 28 28 0 0 1 44.43 33.12l26.06 26.06a28.1 28.1 0 0 1 19-2.77l30.57-56.77a28 28 0 0 1 0-39.6 28 28 0 0 1 39.6 39.6Z" /></Svg>;
export default LineSegments;