/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCSharp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-c-sharp-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-c-sharp-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 180c0 11 7.18 20 16 20a14.07 14.07 0 0 0 10.07-4.51 8.19 8.19 0 0 1 10.88-.9 8 8 0 0 1 .83 11.81A30.06 30.06 0 0 1 64 216c-17.65 0-32-16.15-32-36s14.35-36 32-36a30 30 0 0 1 21.38 9.19 8.25 8.25 0 0 1 .74 11.09 8 8 0 0 1-11.9.38A14.24 14.24 0 0 0 64 160c-8.82 0-16 9-16 20Zm168-92v135.75a8.15 8.15 0 0 1-6.81 8.16A8 8 0 0 1 200 224V124a4 4 0 0 0-4-4H44a4 4 0 0 1-4-4V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-20 0-44-44v44Zm-28 80v16h7.73a8.17 8.17 0 0 1 8.25 7.47 8 8 0 0 1-8 8.53h-8v7.73a8.17 8.17 0 0 1-7.47 8.25 8 8 0 0 1-8.53-8v-8H136v7.73a8.17 8.17 0 0 1-7.47 8.25 8 8 0 0 1-8.53-8v-8h-7.73a8.17 8.17 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h8V168h-7.73a8.17 8.17 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h8v-7.73a8.17 8.17 0 0 1 7.47-8.25 8 8 0 0 1 8.53 8v8h16v-7.73a8.17 8.17 0 0 1 7.47-8.25 8 8 0 0 1 8.53 8v8h7.73a8.17 8.17 0 0 1 8.25 7.47 8 8 0 0 1-8 8.53Zm-16 0h-16v16h16Z" /></Svg>;
export default FileCSharp;