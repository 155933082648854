/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldChevron = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-chevron-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-chevron-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 44H48a12 12 0 0 0-12 12v56c0 51.16 24.73 82.12 45.47 99.1 22.4 18.32 44.55 24.5 45.48 24.76a4 4 0 0 0 2.1 0c.93-.26 23.08-6.44 45.48-24.76 20.74-17 45.47-47.94 45.47-99.1V56a12 12 0 0 0-12-12Zm-38.29 160.71A132.45 132.45 0 0 1 128 227.82a132.23 132.23 0 0 1-41.71-23.11A115.08 115.08 0 0 1 59.21 173L128 124.88 196.79 173a115.08 115.08 0 0 1-27.08 31.71ZM212 112c0 20-3.85 38-11.46 53.89l-70.25-49.17a4 4 0 0 0-4.58 0l-70.25 49.17C47.85 150 44 132 44 112V56a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4Z" /></Svg>;
export default ShieldChevron;