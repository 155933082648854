/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seatbelt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seatbelt-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seatbelt-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 68a36 36 0 1 1 36 36 36 36 0 0 1-36-36Zm36 52a72 72 0 0 0-72 72v32h144v-32a72 72 0 0 0-72-72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M172 68a44 44 0 1 0-44 44 44.05 44.05 0 0 0 44-44Zm-44 28a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm80 128a8 8 0 0 1-8 8H56a8 8 0 0 1-5.29-14l98.07-86.54a64 64 0 0 0-84 50.33A8 8 0 0 1 49 179.25a80 80 0 0 1 113.16-59.59L186.71 98a8 8 0 0 1 10.58 12L77.16 216H200a8 8 0 0 1 8 8Zm-14.27-77.62A79.6 79.6 0 0 1 208 192a8 8 0 0 1-16 0 63.67 63.67 0 0 0-11.41-36.49 8 8 0 0 1 13.14-9.13Z" /></Svg>;
export default Seatbelt;