/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FourK = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="four-k-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-four-k-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M20 44a12 12 0 0 1 12-12h192a12 12 0 0 1 0 24H32a12 12 0 0 1-12-12Zm204 156H32a12 12 0 0 0 0 24h192a12 12 0 0 0 0-24ZM148 68a12 12 0 0 0-12 12v96a12 12 0 0 0 24 0v-27.49l8.44-9.65 25.19 43.19A12 12 0 0 0 214.37 170l-29.26-50.14L213 87.9a12 12 0 1 0-18-15.8l-35 40V80a12 12 0 0 0-12-12ZM80 176v-12H36a12 12 0 0 1-9.47-19.37l56-72A12 12 0 0 1 104 80v60h4a12 12 0 0 1 0 24h-4v12a12 12 0 0 1-24 0Zm0-36v-25l-19.46 25Z" /></Svg>;
export default FourK;