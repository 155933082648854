/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserSwitch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-switch-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-switch-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M84 120a44 44 0 1 1 44 44 44 44 0 0 1-44-44Zm126.16 57.18a8.21 8.21 0 0 0-10.86 2.41 87.42 87.42 0 0 1-5.52 6.85A79.76 79.76 0 0 0 172 165.1a4 4 0 0 0-4.84.32 59.8 59.8 0 0 1-78.26 0 4 4 0 0 0-4.9-.32 79.71 79.71 0 0 0-21.79 21.31A87.66 87.66 0 0 1 40.37 136h15.4a8.2 8.2 0 0 0 6.69-3.28 8 8 0 0 0-.8-10.38l-24-24a8 8 0 0 0-11.32 0l-24 24a8 8 0 0 0-.8 10.38A8.2 8.2 0 0 0 8.23 136H24.3a104 104 0 0 0 188.18 52.67 8 8 0 0 0-2.32-11.49Zm45.23-52.24A8 8 0 0 0 248 120h-16.3A104 104 0 0 0 43.52 67.33a8 8 0 0 0 13 9.34A88 88 0 0 1 215.63 120H200a8 8 0 0 0-5.66 13.66l24 24a8 8 0 0 0 11.32 0l24-24a8 8 0 0 0 1.73-8.72Z" /></Svg>;
export default UserSwitch;