/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lego = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lego__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lego'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243.58 72.84 197.29 49.7C191.54 39.24 177.21 32 160 32c-22.43 0-40 12.3-40 28a20.77 20.77 0 0 0 1.06 6.53l-19.52 9.76A53.69 53.69 0 0 0 80 72c-22.43 0-40 12.3-40 28a20.77 20.77 0 0 0 1.06 6.53l-28.64 14.31A8 8 0 0 0 8 128v64a8 8 0 0 0 4.42 7.16l64 32a8 8 0 0 0 7.16 0l160-80A8 8 0 0 0 248 144V80a8 8 0 0 0-4.42-7.16ZM80 151.06 33.89 128 51 119.45c7.24 5.29 17.48 8.55 29 8.55 22.43 0 40-12.3 40-28a21.77 21.77 0 0 0-4.35-12.88L131 79.45c7.24 5.29 17.48 8.55 29 8.55 18.38 0 33.49-8.26 38.35-19.88L222.11 80ZM160 48c13.74 0 24 6.34 24 12s-10.26 12-24 12-24-6.34-24-12 10.26-12 24-12ZM80 88c13.74 0 24 6.34 24 12s-10.26 12-24 12c-9.67 0-17.61-3.14-21.47-7a8.29 8.29 0 0 0-.84-.93A6.62 6.62 0 0 1 56 100c0-5.66 10.26-12 24-12Zm-56 52.94 48 24v46.12l-48-24Zm64 70.12v-46.12l144-72v46.12Z" /></Svg>;
export default Lego;