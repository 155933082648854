/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Magnet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="magnet-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-magnet-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204.13 53.06A83.41 83.41 0 0 0 144.59 28h-.32a83.46 83.46 0 0 0-59.42 24.62L23.46 114.8a12 12 0 0 0 0 16.92l28.67 28.66a11.89 11.89 0 0 0 8.48 3.51h.07a12 12 0 0 0 8.52-3.61l60.89-62.42a20.1 20.1 0 0 1 28-.27 19.54 19.54 0 0 1 6 14 20.87 20.87 0 0 1-6 14.86l-62.33 60.3a12 12 0 0 0-.12 17.09l28.66 28.66a12 12 0 0 0 16.89.08L203 172.12c32.88-32.91 33.4-86.32 1.13-119.06ZM63.51 154.69a3.93 3.93 0 0 1-2.83 1.2 4 4 0 0 1-2.83-1.17l-28.69-28.66a4 4 0 0 1 0-5.64l26.63-27 34.15 34.18Zm72.08 72.18a4 4 0 0 1-5.63 0l-28.67-28.66a4 4 0 0 1 0-5.69l27.2-26.3 34.16 34.15Zm61.76-60.44-28.94 28.32-34.12-34.12 29.41-28.44a29 29 0 0 0 8.4-20.64 27.51 27.51 0 0 0-8.41-19.72 28.14 28.14 0 0 0-39.25.41l-28.91 29.63L61.4 87.75l29.12-29.49A75.51 75.51 0 0 1 144.27 36h.29a75.53 75.53 0 0 1 53.87 22.67c29.2 29.63 28.7 77.98-1.08 107.76Z" /></Svg>;
export default Magnet;