/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BellZ = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bell-z-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bell-z-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 140a12 12 0 0 1-12 12h-32a12 12 0 0 1-10-18.66L121.58 104H112a12 12 0 1 1 0-24h32a12 12 0 0 1 10 18.66L134.42 128H144a12 12 0 0 1 12 12Zm69.33 46A19.77 19.77 0 0 1 208 196h-36.18a44 44 0 0 1-87.64 0H48a19.77 19.77 0 0 1-17.31-10 20.08 20.08 0 0 1 .05-20.06C39.39 151 44 129.58 44 104a84 84 0 0 1 168 0c0 25.57 4.59 47 13.27 61.93a20.08 20.08 0 0 1 .07 20.07Zm-77.73 10h-39.2a20 20 0 0 0 39.2 0Zm53.74-24c-8.85-18-13.34-40.87-13.34-68a60 60 0 0 0-120 0c0 27.14-4.48 50-13.33 68Z" /></Svg>;
export default BellZ;