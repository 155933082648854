/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Visor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="visor-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-visor-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M183.05 56H72A72 72 0 0 0 .08 131.4c1.69 36.69 31.76 66.79 68.45 68.52 15.84.72 32-5.9 49.38-20.3a15.87 15.87 0 0 1 20.24 0C148.72 188.39 165 200 184 200a72 72 0 0 0 72-72.95C255.49 87.87 222.76 56 183.05 56ZM176 104H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Z" /></Svg>;
export default Visor;