/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceRotate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-rotate-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-rotate-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m204.24 220.24-24 24a6 6 0 0 1-8.48-8.48L185.51 222H80a22 22 0 0 1-22-22v-96a6 6 0 0 1 12 0v96a10 10 0 0 0 10 10h105.51l-13.75-13.76a6 6 0 0 1 8.48-8.48l24 24a6 6 0 0 1 0 8.48ZM80 70a6 6 0 0 0 4.24-10.24L70.49 46H176a10 10 0 0 1 10 10v96a6 6 0 0 0 12 0V56a22 22 0 0 0-22-22H70.49l13.75-13.76a6 6 0 0 0-8.48-8.48l-24 24a6 6 0 0 0 0 8.48l24 24A6 6 0 0 0 80 70Z" /></Svg>;
export default DeviceRotate;