/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeStand = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-stand-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-stand-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 104a80 80 0 1 1 80 80 80.09 80.09 0 0 1-80-80Zm146.46 69.28A96 96 0 0 1 66.72 37.54a8 8 0 1 0-11.54-11.08A112 112 0 0 0 128 215.71V232h-24a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16h-24v-16.28a111.21 111.21 0 0 0 69.54-30.9 8 8 0 1 0-11.08-11.54Z" /></Svg>;
export default GlobeStand;