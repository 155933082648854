/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pi-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pi-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 172a34 34 0 0 1-68 0V70H94v130a6 6 0 0 1-12 0V70H72a42 42 0 0 0-42 42 6 6 0 0 1-12 0 54.06 54.06 0 0 1 54-54h152a6 6 0 0 1 0 12h-50v102a22 22 0 0 0 44 0 6 6 0 0 1 12 0Z" /></Svg>;
export default Pi;