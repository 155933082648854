/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhosphorLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phosphor-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phosphor-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 36H72a4 4 0 0 0-4 4v128a76.08 76.08 0 0 0 76 76 4 4 0 0 0 4-4v-68h4a68 68 0 0 0 0-136ZM76 55.27 137.16 164H76Zm64 97.46L78.84 44H140ZM76.13 172H140v63.88A68.1 68.1 0 0 1 76.13 172Zm75.87-8h-4V44h4a60 60 0 0 1 0 120Z" /></Svg>;
export default PhosphorLogo;