/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EyeClosed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eye-closed-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eye-closed-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.42 162a12 12 0 1 1-20.84 12l-16.86-29.5a127.19 127.19 0 0 1-30.17 13.86l5.29 31.64a12 12 0 0 1-9.87 13.8 11.22 11.22 0 0 1-2 .17 12 12 0 0 1-11.82-10l-5.15-30.8a136.5 136.5 0 0 1-30.06 0l-5.1 30.83A12 12 0 0 1 96 204a11.22 11.22 0 0 1-2-.17A12 12 0 0 1 84.16 190l5.29-31.72a127.19 127.19 0 0 1-30.17-13.86L42.42 174a12 12 0 1 1-20.84-12L40 129.85a159.73 159.73 0 0 1-17.31-18.31 12 12 0 0 1 18.65-15.08C57.38 116.32 85.44 140 128 140s70.62-23.68 86.66-43.54a12 12 0 0 1 18.67 15.08A159.73 159.73 0 0 1 216 129.85Z" /></Svg>;
export default EyeClosed;