/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSuperscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-superscript__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-superscript'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 144a8 8 0 0 1-8 8h-48a8 8 0 0 1-6.4-12.8l43.17-57.55a16 16 0 1 0-27.86-15 8 8 0 0 1-15.09-5.33 32 32 0 1 1 55.74 29.92L208 136h32a8 8 0 0 1 8 8Zm-98.76-70a8 8 0 0 0-11.29.8L92 127.79l-45.95-53A8 8 0 0 0 34 85.24L81.41 140 34 194.76a8 8 0 0 0 12.1 10.48l46-53 45.95 53a8 8 0 1 0 12.1-10.48L102.59 140l47.46-54.76a8 8 0 0 0-.81-11.24Z" /></Svg>;
export default TextSuperscript;