/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBrushHousehold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-brush-household-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-brush-household-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.47 22.53a36 36 0 0 0-50.91 0l-.39.4-52.56 57.68-5.8-5.79a28 28 0 0 0-39.6 0L7.52 151.51a12 12 0 0 0 0 17l80 80a12 12 0 0 0 17 0l76.7-76.7a28 28 0 0 0 0-39.6l-5.8-5.8 57.68-52.56.4-.39a36 36 0 0 0-.03-50.93ZM96 223l-9-9 17.51-17.52a12 12 0 0 0-17-17L70 197l-11-11 17.52-17.52a12 12 0 0 0-17-17L42 169l-9-9 39-39 63 63ZM216.66 56.31l-66.73 60.81a12 12 0 0 0-.4 17.36l14.68 14.68a4 4 0 0 1 0 5.66L152 167l-63-63 12.2-12.2a4 4 0 0 1 5.66 0l14.68 14.68a12 12 0 0 0 17.36-.4l60.81-66.73a12 12 0 0 1 17 17Z" /></Svg>;
export default PaintBrushHousehold;