/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fish = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fish-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fish-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 76.05a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm49.23 65.68c-19 34.15-54.76 52.35-106.43 54.13l-23 53.72a4 4 0 0 1-3.7 2.42h-.26a4 4 0 0 1-3.59-2.89l-15.41-53.94L6.9 179.73a4 4 0 0 1-.47-7.52l53.72-23C62 97.6 80.16 61.81 114.3 42.85c45.5-25.27 103.78-11 106.76-9.27a4.08 4.08 0 0 1 1.42 1.42c.87 1.48 4.72 16.94 5.42 36.95.64 18.62-1.38 45.84-14.67 69.78Zm-40.46 29.77A48 48 0 0 1 132 124a48.05 48.05 0 0 1-47.47-40.71c-10.27 18-15.79 40.95-16.46 68.71a4 4 0 0 1-2.42 3.58l-45.73 19.56 45.26 13a4 4 0 0 1 2.74 2.74l12.93 45.25 19.6-45.72a4 4 0 0 1 3.58-2.43c27.78-.7 50.75-6.21 68.74-16.48ZM220 77.25a169.58 169.58 0 0 0-4.2-37 169.46 169.46 0 0 0-37-4.2c-18.81-.12-45.93 3-68.2 18.49a85.22 85.22 0 0 0-18.36 17.3 40 40 0 0 0 43.58 44 4 4 0 0 1 4.36 4.36 40 40 0 0 0 44.06 43.56 85.31 85.31 0 0 0 17.26-18.33c8.5-12.27 18.68-34.08 18.5-68.18Z" /></Svg>;
export default Fish;