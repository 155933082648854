/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RoadHorizon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="road-horizon-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-road-horizon-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.49 190a4 4 0 0 1-1.53 5.45 4.07 4.07 0 0 1-2 .51 4 4 0 0 1-3.49-2L157.66 68H132v12a4 4 0 0 1-8 0V68H98.34L27.49 194a4 4 0 0 1-3.49 2 4.07 4.07 0 0 1-2-.51 4 4 0 0 1-1.49-5.49L89.16 68H24a4 4 0 0 1 0-8h208a4 4 0 0 1 0 8h-65.16ZM128 116a4 4 0 0 0-4 4v16a4 4 0 0 0 8 0v-16a4 4 0 0 0-4-4Zm0 56a4 4 0 0 0-4 4v16a4 4 0 0 0 8 0v-16a4 4 0 0 0-4-4Z" /></Svg>;
export default RoadHorizon;