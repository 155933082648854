/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crane-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crane-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M226.06 20.57a4 4 0 0 0-3.94-.1L103 84H32a12 12 0 0 0-12 12v104a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-32a4.05 4.05 0 0 0-.17-1.15L108.77 90 220 30.67V160a4 4 0 0 1-4 4h-16a4 4 0 0 1-4-4v-8a4 4 0 0 0-8 0v8a12 12 0 0 0 12 12h16a12 12 0 0 0 12-12V24a4 4 0 0 0-1.94-3.43ZM101 92l21.6 72H60V92Zm-69 0h20v72H28V96a4 4 0 0 1 4-4Zm88 112H32a4 4 0 0 1-4-4v-28h96v28a4 4 0 0 1-4 4Z" /></Svg>;
export default Crane;