/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhosphorLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phosphor-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phosphor-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 34H72a6 6 0 0 0-6 6v128a78.09 78.09 0 0 0 78 78 6 6 0 0 0 6-6v-66h2a70 70 0 0 0 0-140ZM78 62.91 133.74 162H78Zm60 82.19L82.26 46H138ZM78.28 174H138v59.73A66.1 66.1 0 0 1 78.28 174ZM152 162h-2V46h2a58 58 0 0 1 0 116Z" /></Svg>;
export default PhosphorLogo;