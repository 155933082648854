/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crane-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crane-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.09 18.86a6 6 0 0 0-5.91-.15L102.5 82H32a14 14 0 0 0-14 14v104a14 14 0 0 0 14 14h88a14 14 0 0 0 14-14v-32a6.28 6.28 0 0 0-.25-1.72L111.16 91 218 34v126a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2v-8a6 6 0 0 0-12 0v8a14 14 0 0 0 14 14h16a14 14 0 0 0 14-14V24a6 6 0 0 0-2.91-5.14ZM99.54 94l20.4 68H62V94ZM32 94h18v68H30V96a2 2 0 0 1 2-2Zm88 108H32a2 2 0 0 1-2-2v-26h92v26a2 2 0 0 1-2 2Z" /></Svg>;
export default Crane;