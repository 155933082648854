/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartLineDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-line-down-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-line-down-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 208a4 4 0 0 1-4 4H32a4 4 0 0 1-4-4V48a4 4 0 0 1 8 0v14.34l60 60 29.17-29.17a4 4 0 0 1 5.66 0L196 158.34V128a4 4 0 0 1 8 0v40a4.13 4.13 0 0 1-.08.78 3.37 3.37 0 0 1-.1.34 2.8 2.8 0 0 1-.13.41 2.87 2.87 0 0 1-.2.39c-.05.1-.1.2-.16.3a4.19 4.19 0 0 1-1.11 1.11l-.31.16a3.48 3.48 0 0 1-.38.2 2.8 2.8 0 0 1-.41.13 3.37 3.37 0 0 1-.34.1 4.13 4.13 0 0 1-.78.08h-40a4 4 0 0 1 0-8h30.34L128 101.66l-29.17 29.17a4 4 0 0 1-5.66 0L36 73.66V204h188a4 4 0 0 1 4 4Z" /></Svg>;
export default ChartLineDown;