/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LessThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="less-than-or-equal__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-less-than-or-equal'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M40 104a8 8 0 0 1 5.23-7.5l152-56a8 8 0 0 1 5.53 15L71.14 104l131.62 48.49A8 8 0 0 1 200 168a8.13 8.13 0 0 1-2.77-.49l-152-56A8 8 0 0 1 40 104Zm160 88H48a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16Z" /></Svg>;
export default LessThanOrEqual;