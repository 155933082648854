/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SolarPanel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="solar-panel__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-solar-panel'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 104a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16H40a8 8 0 0 1-8-8Zm39.43-45.25a8 8 0 0 0 11.32-11.32L71.43 36.12a8 8 0 0 0-11.31 11.31ZM128 40a8 8 0 0 0 8-8V16a8 8 0 0 0-16 0v16a8 8 0 0 0 8 8Zm50.91 21.09a8 8 0 0 0 5.66-2.34l11.31-11.32a8 8 0 0 0-11.31-11.31l-11.32 11.31a8 8 0 0 0 5.66 13.66ZM192 104a8 8 0 0 0 8 8h16a8 8 0 0 0 0-16h-16a8 8 0 0 0-8 8Zm-104 8a8 8 0 0 0 8-8 32 32 0 0 1 64 0 8 8 0 0 0 16 0 48 48 0 0 0-96 0 8 8 0 0 0 8 8Zm150.91 108a8 8 0 0 1-6.91 4H24a8 8 0 0 1-7-11.94l40.69-72a8 8 0 0 1 7-4.06H191.3a8 8 0 0 1 7 4.06l40.69 72a8 8 0 0 1-.08 7.94Zm-52.27-68h-24.37l3.48 16h29.93Zm-37.26 16-3.48-16h-35.8l-3.48 16Zm-46.24 16-5.21 24h60.14l-5.21-24Zm-42.82-16h29.93l3.48-16H69.36Zm-22.61 40h43.84l5.22-24H51.28Zm180.58 0-13.57-24h-35.49l5.22 24Z" /></Svg>;
export default SolarPanel;