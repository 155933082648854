/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RssSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 192a6 6 0 0 1-12 0c0-80.5-65.5-146-146-146a6 6 0 0 1 0-12c87.12 0 158 70.88 158 158ZM64 106a6 6 0 0 0 0 12 74.09 74.09 0 0 1 74 74 6 6 0 0 0 12 0 86.1 86.1 0 0 0-86-86Zm4 72a10 10 0 1 0 10 10 10 10 0 0 0-10-10Z" /></Svg>;
export default RssSimple;