/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Screwdriver = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="screwdriver-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-screwdriver-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.57 88.43-53.23 53.23a8 8 0 0 1-5.65 2.34H152a8 8 0 0 0-8 8v16.83a8 8 0 0 1-1.62 4.83l-8 8a8 8 0 0 1-11.32 0l-48.72-48.72a8 8 0 0 1 0-11.32l8-8a8 8 0 0 1 4.83-1.62H104a8 8 0 0 0 8-8V87.31a8 8 0 0 1 2.34-5.65l53.23-53.23a42.42 42.42 0 0 1 60 0 42.42 42.42 0 0 1 0 60Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M205.66 50.32a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32-11.31l56-56a8 8 0 0 1 11.32-.01ZM248 58.41a50.13 50.13 0 0 1-14.77 35.66L180 147.3a15.86 15.86 0 0 1-11.31 4.7H152v16.83a16 16 0 0 1-3.25 9.66 8.08 8.08 0 0 1-.72.83l-8 8a16 16 0 0 1-22.62 0L98.7 168.6l-77 77.06a8 8 0 0 1-11.32-11.32l77.05-77.05-18.7-18.71a16 16 0 0 1 0-22.63l8-8a8 8 0 0 1 .82-.72 16.14 16.14 0 0 1 9.62-3.23H104V87.3a15.92 15.92 0 0 1 4.68-11.3l53.24-53.23A50.43 50.43 0 0 1 248 58.41Zm-16 0a34.43 34.43 0 0 0-58.77-24.35L120 87.3V104a16 16 0 0 1-16 16H87.28L80 127.27 128.72 176l7.28-7.28V152a16 16 0 0 1 16-16h16.69l53.23-53.24A34.21 34.21 0 0 0 232 58.41Z" /></Svg>;
export default Screwdriver;