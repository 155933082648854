/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilSimpleLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-simple-line-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-simple-line-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m230.15 70.54-44.69-44.68a20 20 0 0 0-28.28 0L33.86 149.17A19.86 19.86 0 0 0 28 163.31V208a20 20 0 0 0 20 20h168a12 12 0 0 0 0-24h-91L230.15 98.83a20 20 0 0 0 0-28.29ZM91 204H52v-39l84-84 39 39Zm101-101-39-39 18.34-18.34 39 39Z" /></Svg>;
export default PencilSimpleLine;