/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookOpen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="book-open-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-book-open-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 52h-72a36 36 0 0 0-32 19.54A36 36 0 0 0 96 52H24a4 4 0 0 0-4 4v144a4 4 0 0 0 4 4h72a28 28 0 0 1 28 28 4 4 0 0 0 8 0 28 28 0 0 1 28-28h72a4 4 0 0 0 4-4V56a4 4 0 0 0-4-4ZM96 196H28V60h68a28 28 0 0 1 28 28v121.4A35.93 35.93 0 0 0 96 196Zm132 0h-68a35.94 35.94 0 0 0-28 13.41V88a28 28 0 0 1 28-28h68Z" /></Svg>;
export default BookOpen;