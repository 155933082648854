/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CallBell = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="call-bell-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-call-bell-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M16 176a8 8 0 0 1 8-8h8v-16a96.12 96.12 0 0 1 88-95.66V40h-16a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16h-16v16.34A96.12 96.12 0 0 1 224 152v16h8a8 8 0 0 1 0 16H24a8 8 0 0 1-8-8Zm216 24H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16Z" /></Svg>;
export default CallBell;