/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sock = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sock-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sock-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 18h-88a14 14 0 0 0-14 14v77.51l-39.33 39.34a56.91 56.91 0 1 0 80.48 80.48l68.41-68.4a21.88 21.88 0 0 0 6.44-15.56V32a14 14 0 0 0-14-14Zm-88 12h88a2 2 0 0 1 2 2v18h-92V32a2 2 0 0 1 2-2Zm18.67 190.85a44.92 44.92 0 0 1-63.52-63.52l41.09-41.09A6 6 0 0 0 102 112V62h92v44.34A54.07 54.07 0 0 0 146 160a53.39 53.39 0 0 0 8.47 29Zm68.4-68.41-27.85 27.86A41.54 41.54 0 0 1 158 160a42.05 42.05 0 0 1 36-41.56v26.93a9.93 9.93 0 0 1-2.93 7.07Z" /></Svg>;
export default Sock;