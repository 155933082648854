/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NavigationArrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="navigation-arrow-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-navigation-arrow-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.65 108.1 60.72 42.83h-.13a14 14 0 0 0-17.81 17.76s0 .09 0 .13l65.32 175.93a13.77 13.77 0 0 0 13.18 9.35h.26a13.8 13.8 0 0 0 13.14-9.88v-.15l22.14-79.1L236 134.73h.15a14 14 0 0 0 .53-26.58Zm-4 15.1-82.26 23a6 6 0 0 0-4.16 4.16l-23 82.26a1.85 1.85 0 0 1-1.86 1.36 1.82 1.82 0 0 1-1.92-1.35.61.61 0 0 0 0-.12L54.11 56.62a2 2 0 0 1 2.51-2.51l175.91 65.26h.12a2 2 0 0 1 0 3.79Z" /></Svg>;
export default NavigationArrow;