/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Minus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="minus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-minus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 128a4 4 0 0 1-4 4H40a4 4 0 0 1 0-8h176a4 4 0 0 1 4 4Z" /></Svg>;
export default Minus;