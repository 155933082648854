/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Drop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 144a80 80 0 0 1-160 0c0-72 80-128 80-128s80 56 80 128Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M174 47.75a254.19 254.19 0 0 0-41.45-38.3 8 8 0 0 0-9.18 0A254.19 254.19 0 0 0 82 47.75C54.51 79.32 40 112.6 40 144a88 88 0 0 0 176 0c0-31.4-14.51-64.68-42-96.25ZM128 216a72.08 72.08 0 0 1-72-72c0-57.23 55.47-105 72-118 16.53 13 72 60.75 72 118a72.08 72.08 0 0 1-72 72Zm55.89-62.66a57.6 57.6 0 0 1-46.56 46.55 8.75 8.75 0 0 1-1.33.11 8 8 0 0 1-1.32-15.89c16.57-2.79 30.63-16.85 33.44-33.45a8 8 0 0 1 15.78 2.68Z" /></Svg>;
export default Drop;