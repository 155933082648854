/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 80a32 32 0 1 0-60 15.45l-20.86 25.66-20.32-46.71a32 32 0 1 0-45.64 0l-20.31 46.71L64 95.45a32 32 0 1 0-35 15.78l14 84.06A19.94 19.94 0 0 0 62.78 212h130.44A19.94 19.94 0 0 0 213 195.29l14-84.06A32.05 32.05 0 0 0 252 80Zm-32-8a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm-92-28a8 8 0 1 1-8 8 8 8 0 0 1 8-8ZM36 72a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm153.83 116H66.17l-10.88-65.22 23.4 28.79A12 12 0 0 0 88 156a12.87 12.87 0 0 0 1.63-.11 12 12 0 0 0 9.37-7.1L127.18 84h1.64L157 148.79a12 12 0 0 0 9.37 7.1 12.87 12.87 0 0 0 1.63.11 12 12 0 0 0 9.31-4.43l23.4-28.79Z" /></Svg>;
export default Crown;