/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Numpad = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="numpad-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-numpad-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M84 48a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm44-20a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm64 40a20 20 0 1 0-20-20 20 20 0 0 0 20 20ZM64 84a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm64 0a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm64 0a20 20 0 1 0 20 20 20 20 0 0 0-20-20ZM64 140a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm64 0a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm0 56a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm64-56a20 20 0 1 0 20 20 20 20 0 0 0-20-20Z" /></Svg>;
export default Numpad;