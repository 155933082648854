/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsInLineHorizontal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-in-line-horizontal__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-in-line-horizontal'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 40v176a8 8 0 0 1-16 0V40a8 8 0 0 1 16 0ZM69.66 90.34a8 8 0 0 0-11.32 11.32L76.69 120H16a8 8 0 0 0 0 16h60.69l-18.35 18.34a8 8 0 0 0 11.32 11.32l32-32a8 8 0 0 0 0-11.32ZM240 120h-60.69l18.35-18.34a8 8 0 0 0-11.32-11.32l-32 32a8 8 0 0 0 0 11.32l32 32a8 8 0 0 0 11.32-11.32L179.31 136H240a8 8 0 0 0 0-16Z" /></Svg>;
export default ArrowsInLineHorizontal;