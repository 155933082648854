/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-nine-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-nine-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188 96a60 60 0 1 0-60 60 59.21 59.21 0 0 0 7.81-.53l-26.27 46.64a12 12 0 0 0 20.92 11.78l49.54-88A59.57 59.57 0 0 0 188 96Zm-96 0a36 36 0 1 1 36 36 36 36 0 0 1-36-36Z" /></Svg>;
export default NumberNine;