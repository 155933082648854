/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-heart-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-heart-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36h-28V24a4 4 0 0 0-8 0v12H84V24a4 4 0 0 0-8 0v12H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12Zm4 172a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h28v12a4 4 0 0 0 8 0V44h88v12a4 4 0 0 0 8 0V44h28a4 4 0 0 1 4 4ZM152 92a28 28 0 0 0-24 13.59A28 28 0 0 0 76 120c0 14.51 8.56 29 25.44 43.07a143.52 143.52 0 0 0 24.77 16.51 4 4 0 0 0 3.58 0 143.52 143.52 0 0 0 24.77-16.51C171.44 149 180 134.51 180 120a28 28 0 0 0-28-28Zm-24 79.47c-8.46-4.64-44-25.67-44-51.47a20 20 0 0 1 40 0 4 4 0 0 0 8 0 20 20 0 0 1 40 0c0 25.8-35.54 46.83-44 51.47Z" /></Svg>;
export default CalendarHeart;