/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderIntersex = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-intersex-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-intersex-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 112a56 56 0 1 1-56-56 56 56 0 0 1 56 56Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 24h-40a8 8 0 0 0 0 16h20.69l-25.15 25.15A64 64 0 1 0 112 175.48V192H88a8 8 0 0 0 0 16h24v24a8 8 0 0 0 16 0v-24h24a8 8 0 0 0 0-16h-24v-16.52a63.92 63.92 0 0 0 45.84-98L200 51.31V72a8 8 0 0 0 16 0V32a8 8 0 0 0-8-8Zm-88 136a48 48 0 1 1 48-48 48.05 48.05 0 0 1-48 48Z" /></Svg>;
export default GenderIntersex;