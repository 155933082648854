/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ReplitLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="replit-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-replit-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M72 160h72v56a16 16 0 0 1-16 16H72a16 16 0 0 1-16-16v-40a16 16 0 0 1 16-16Zm56-136H72a16 16 0 0 0-16 16v40a16 16 0 0 0 16 16h72V40a16 16 0 0 0-16-16Zm88 72h-72v64h72a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16Z" /></Svg>;
export default ReplitLogo;