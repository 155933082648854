/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cricket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cricket-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cricket-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240.49 84.2 187.8 31.51a12 12 0 0 0-17 0L63.52 138.83a12 12 0 0 0 0 17L87 179.31l-49.83 49.86a4 4 0 0 0 5.66 5.66L92.69 185l23.51 23.51a12 12 0 0 0 17 0l107.29-107.34a12 12 0 0 0 0-16.97Zm-113 118.63a4 4 0 0 1-5.66 0l-23.49-23.52 32.49-32.48a4 4 0 0 0-5.66-5.66l-32.48 32.49-23.52-23.52a4 4 0 0 1 0-5.66L105.66 108H164v58.34ZM234.83 95.51 172 158.34V104a4 4 0 0 0-4-4h-54.34l62.83-62.83a4 4 0 0 1 5.66 0l52.68 52.68a4 4 0 0 1 0 5.66ZM60 84a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-40a16 16 0 1 1-16 16 16 16 0 0 1 16-16Z" /></Svg>;
export default Cricket;