/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-user-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-user-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 72v49.53l42.21-21.11a4 4 0 1 1 3.58 7.16l-48 24a4.05 4.05 0 0 1-1.79.42 4 4 0 0 1-4-4V72a4 4 0 0 1 8 0Zm-4 148a92 92 0 1 1 92-92 4 4 0 0 0 8 0 100 100 0 1 0-100 100 4 4 0 0 0 0-8Zm99.86 3a4 4 0 1 1-7.73 2.05c-3.29-12.37-14.86-21-28.13-21s-24.84 8.65-28.14 21a4 4 0 0 1-3.86 3 3.7 3.7 0 0 1-1-.14 4 4 0 0 1-2.84-4.89 36.24 36.24 0 0 1 20.34-23.72 28 28 0 1 1 31 0 36.2 36.2 0 0 1 20.36 23.7ZM192 196a20 20 0 1 0-20-20 20 20 0 0 0 20 20Z" /></Svg>;
export default ClockUser;