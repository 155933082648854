/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CoatHanger = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coat-hanger-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coat-hanger-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.57 171.2 141.33 96l23.46-17.6A8 8 0 0 0 168 72a40 40 0 1 0-80 0 8 8 0 0 0 16 0 24 24 0 0 1 47.69-3.78L14.43 171.2A16 16 0 0 0 24 200h208a16 16 0 0 0 9.6-28.8ZM32.73 184c20.87-13.41 56.76-32 95.27-32s74.4 18.59 95.27 32Z" /></Svg>;
export default CoatHanger;