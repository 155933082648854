/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Brain = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="brain-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-brain-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 76v-4a44 44 0 0 0-74.86-31.31 3.93 3.93 0 0 0-1.14 2.8v88.72a4 4 0 0 0 6.2 3.33 47.67 47.67 0 0 1 25.48-7.54 8.18 8.18 0 0 1 8.31 7.58 8 8 0 0 1-8 8.42 32 32 0 0 0-32 32v33.88a4 4 0 0 0 1.49 3.12 47.92 47.92 0 0 0 74.21-17.16 4 4 0 0 0-4.49-5.56A68.06 68.06 0 0 1 192 192h-7.73a8.18 8.18 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h8a51.6 51.6 0 0 0 24-5.88A52 52 0 0 0 212 76Zm-12 36h-4a36 36 0 0 1-36-36v-4a8 8 0 0 1 16 0v4a20 20 0 0 0 20 20h4a8 8 0 0 1 0 16ZM88 28a44.05 44.05 0 0 0-44 44v4a52 52 0 0 0-4 94.12A51.6 51.6 0 0 0 64 176h7.73a8.18 8.18 0 0 1 8.27 7.47 8 8 0 0 1-8 8.53h-8a67.48 67.48 0 0 1-15.21-1.73 4 4 0 0 0-4.5 5.55A47.93 47.93 0 0 0 118.51 213a4 4 0 0 0 1.49-3.12V176a32 32 0 0 0-32-32 8 8 0 0 1-8-8.42 8.18 8.18 0 0 1 8.32-7.58 47.67 47.67 0 0 1 25.48 7.54 4 4 0 0 0 6.2-3.33V43.49a4 4 0 0 0-1.14-2.81A43.85 43.85 0 0 0 88 28Zm8 48a36 36 0 0 1-36 36h-4a8 8 0 0 1 0-16h4a20 20 0 0 0 20-20v-4a8 8 0 0 1 16 0Z" /></Svg>;
export default Brain;