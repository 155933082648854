/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatCenteredSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-centered-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-centered-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 0 0-20.29 4.38A20 20 0 0 0 20 56v128a20 20 0 0 0 20 20h58.11l12.52 21.92a20 20 0 0 0 34.73 0L157.89 204h23l18.25 20.07a12 12 0 0 0 17.76-16.14ZM150.93 180a12 12 0 0 0-10.42 6.05L128 207.94l-12.51-21.89a12 12 0 0 0-10.42-6.05H44V60h6l109.1 120ZM236 56v118.14a12 12 0 0 1-24 0V60H109.33a12 12 0 1 1 0-24H216a20 20 0 0 1 20 20Z" /></Svg>;
export default ChatCenteredSlash;