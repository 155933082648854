/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileIni = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-ini-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-ini-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 152v55.73a8.17 8.17 0 0 1-7.47 8.27 8 8 0 0 1-8.53-8v-55.73a8.17 8.17 0 0 1 7.47-8.27 8 8 0 0 1 8.53 8Zm71.47-8a8.17 8.17 0 0 0-7.47 8.25V183l-25.31-35.4a8.26 8.26 0 0 0-8-3.48A8 8 0 0 0 72 152v55.73a8.17 8.17 0 0 0 7.47 8.27 8 8 0 0 0 8.53-8v-31l25.49 35.69a8 8 0 0 0 10.38 2.31 8.23 8.23 0 0 0 4.13-7.25V152a8 8 0 0 0-8.53-8Zm40 0a8.17 8.17 0 0 0-7.47 8.25v55.46a8.17 8.17 0 0 0 7.47 8.25 8 8 0 0 0 8.53-8V152a8 8 0 0 0-8.53-8ZM216 88v135.75a8.15 8.15 0 0 1-6.81 8.16A8 8 0 0 1 200 224V124a4 4 0 0 0-4-4H44a4 4 0 0 1-4-4V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-20 0-44-44v44Z" /></Svg>;
export default FileIni;