/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScribbleLoop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scribble-loop-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scribble-loop-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 128a81.4 81.4 0 0 1 25.69 4.28C151.56 154.87 137.33 176 112 176c-15.8 0-24.06-10.85-24.06-21.58 0-6.59 3-12.75 8.56-17.35 7.12-5.93 18.02-9.07 31.5-9.07Zm96-80v160a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Zm-18.11 98.59a120.21 120.21 0 0 0-36.08-25.21c-.9-14.35-5.75-27.54-13.89-37.55C145.38 70.86 130.19 64 112 64c-35.56 0-61.32 33.76-62.4 35.2a8 8 0 0 0 12.79 9.62C62.61 108.53 84.51 80 112 80c13.4 0 24 4.68 31.5 13.92a47.54 47.54 0 0 1 9.48 21.4A96.75 96.75 0 0 0 128 112c-17.27 0-31.71 4.42-41.74 12.78C77 132.47 71.94 143 71.94 154.42 71.94 172.64 86 192 112 192a54 54 0 0 0 43.53-21.23A70 70 0 0 0 169 138.89a106.24 106.24 0 0 1 25.13 18.52 8 8 0 1 0 11.78-10.82Z" /></Svg>;
export default ScribbleLoop;