/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileMd = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-md-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-md-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m213.66 82.34-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v76a4 4 0 0 0 4 4h152a4 4 0 0 1 4 4v100a8 8 0 0 0 9.19 7.91 8.15 8.15 0 0 0 6.81-8.16V88a8 8 0 0 0-2.34-5.66ZM152 88V44l44 44Zm-8 56h-16a8 8 0 0 0-8 8v56a8 8 0 0 0 8 8h15.32c19.66 0 36.21-15.48 36.67-35.13A36 36 0 0 0 144 144Zm-.49 56H136v-40h8a20 20 0 0 1 20 20.77c-.42 10.82-9.66 19.23-20.49 19.23ZM104 152v55.73a8.17 8.17 0 0 1-7.47 8.27 8 8 0 0 1-8.53-8v-30.62l-13.32 19a8.3 8.3 0 0 1-4.2 3.2 8 8 0 0 1-9-3L48 177.38v30.35a8.17 8.17 0 0 1-7.47 8.27 8 8 0 0 1-8.53-8v-55.69a8.27 8.27 0 0 1 4.56-7.53 8 8 0 0 1 10 2.63L68 178.05l21.27-30.39a8.28 8.28 0 0 1 8.06-3.55A8 8 0 0 1 104 152Z" /></Svg>;
export default FileMd;