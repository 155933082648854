/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Buildings = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="buildings-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-buildings-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.73 208H224V96a16 16 0 0 0-16-16h-44a4 4 0 0 0-4 4v124h-16V32.41a16.43 16.43 0 0 0-6.16-13 16 16 0 0 0-18.72-.69L39.12 72A16 16 0 0 0 32 85.34V208H16.27A8.18 8.18 0 0 0 8 215.47a8 8 0 0 0 8 8.53h224a8 8 0 0 0 8-8.53 8.18 8.18 0 0 0-8.27-7.47ZM76 184a8 8 0 0 1-8.53 8 8.18 8.18 0 0 1-7.47-8.28v-15.45a8.19 8.19 0 0 1 7.47-8.27 8 8 0 0 1 8.53 8Zm0-56a8 8 0 0 1-8.53 8 8.19 8.19 0 0 1-7.47-8.28v-15.45a8.19 8.19 0 0 1 7.47-8.27 8 8 0 0 1 8.53 8Zm40 56a8 8 0 0 1-8.53 8 8.18 8.18 0 0 1-7.47-8.26v-15.47a8.19 8.19 0 0 1 7.47-8.26 8 8 0 0 1 8.53 8Zm0-56a8 8 0 0 1-8.53 8 8.19 8.19 0 0 1-7.47-8.26v-15.47a8.19 8.19 0 0 1 7.47-8.26 8 8 0 0 1 8.53 8Z" /></Svg>;
export default Buildings;