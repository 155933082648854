/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Target = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="target-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-target-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.87 83.16A104.1 104.1 0 1 1 195.67 49l22.67-22.68a8 8 0 0 1 11.32 11.32L167.6 99.71l-37.71 37.71-23.95 23.95a40 40 0 0 0 62-35.67 8 8 0 1 1 16-.9 56 56 0 0 1-95.5 42.79 56 56 0 0 1 73.13-84.43l22.73-22.77a87.88 87.88 0 1 0 23.13 29.67 8 8 0 0 1 14.44-6.9Z" /></Svg>;
export default Target;