/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserSound = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-sound__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-sound'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 165.68a68 68 0 1 0-71.9 0c-20.65 6.76-39.23 19.39-54.17 37.17a8 8 0 0 0 12.25 10.3C50.25 189.19 77.91 176 108 176s57.75 13.19 77.88 37.15a8 8 0 1 0 12.25-10.3c-14.95-17.78-33.53-30.41-54.13-37.17ZM56 108a52 52 0 1 1 52 52 52.06 52.06 0 0 1-52-52Zm151.36-42.4a108.36 108.36 0 0 1 0 84.8 8 8 0 0 1-7.36 4.86 8 8 0 0 1-7.36-11.15 92.26 92.26 0 0 0 0-72.22 8 8 0 0 1 14.72-6.29ZM248 108a139 139 0 0 1-11.29 55.15 8 8 0 0 1-14.7-6.3 124.43 124.43 0 0 0 0-97.7 8 8 0 1 1 14.7-6.3A139 139 0 0 1 248 108Z" /></Svg>;
export default UserSound;