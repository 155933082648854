/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryWarningVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-warning-vertical-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-warning-vertical-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 136v-36a12 12 0 0 1 24 0v36a12 12 0 0 1-24 0Zm12 24a16 16 0 1 0 16 16 16 16 0 0 0-16-16ZM104 24h48a12 12 0 0 0 0-24h-48a12 12 0 0 0 0 24Zm100 36v168a28 28 0 0 1-28 28H80a28 28 0 0 1-28-28V60a28 28 0 0 1 28-28h96a28 28 0 0 1 28 28Zm-24 0a4 4 0 0 0-4-4H80a4 4 0 0 0-4 4v168a4 4 0 0 0 4 4h96a4 4 0 0 0 4-4Z" /></Svg>;
export default BatteryWarningVertical;