/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileJsx = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-jsx-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-jsx-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M143.84 195.8a17 17 0 0 1-7.43 12.41c-4.39 2.91-10 3.77-15.22 3.77a57.89 57.89 0 0 1-14.19-1.87 4 4 0 1 1 2.15-7.7c4.22 1.17 16.56 3.29 22.83-.88a8.94 8.94 0 0 0 3.91-6.75c.83-6.45-4.38-8.69-15.64-11.94-9.68-2.8-21.72-6.28-20.14-18.77a16.66 16.66 0 0 1 7.22-12.13c4.56-3.07 11-4.36 19.1-3.82a61.33 61.33 0 0 1 10.48 1.61 4 4 0 0 1-2.05 7.74c-4.29-1.13-16.81-3.12-23.06 1.11a8.51 8.51 0 0 0-3.75 6.49c-.66 5.17 3.89 7 14.42 10.08 9.79 2.85 23.17 6.69 21.37 20.65ZM72 148a4 4 0 0 0-4 4v38a14 14 0 0 1-28 0 4 4 0 0 0-8 0 22 22 0 0 0 44 0v-38a4 4 0 0 0-4-4Zm138.32.75a4 4 0 0 0-5.58.92L188 173.12l-16.75-23.45a4 4 0 0 0-6.51 4.66L183.08 180l-18.34 25.67a4 4 0 0 0 .93 5.58 4 4 0 0 0 5.58-.92L188 186.88l16.74 23.45A4 4 0 0 0 208 212a3.91 3.91 0 0 0 2.32-.75 4 4 0 0 0 .93-5.58L192.92 180l18.33-25.67a4 4 0 0 0-.93-5.58ZM212 88v24a4 4 0 0 1-8 0V92h-52a4 4 0 0 1-4-4V36H56a4 4 0 0 0-4 4v72a4 4 0 0 1-8 0V40a12 12 0 0 1 12-12h96a4 4 0 0 1 2.83 1.17l56 56A4 4 0 0 1 212 88Zm-13.66-4L156 41.65V84Z" /></Svg>;
export default FileJsx;