/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SteamLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="steam-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-steam-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm-10.61 161.92 62-50.55a36 36 0 1 0-51.14-50.23l-43 52.73L45.28 98A88 88 0 1 1 40 128a89.56 89.56 0 0 1 .8-11.88l34.57 34.57a28 28 0 0 0 42 35.23Zm4.19-67.37a36.18 36.18 0 0 0 23.87 23.87l-18.26 14.89a28.11 28.11 0 0 0-20.5-20.5ZM156 128a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm-56 24a12 12 0 1 1-12 12 12 12 0 0 1 12-12Z" /></Svg>;
export default SteamLogo;