/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneDisconnect = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-disconnect-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-disconnect-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 157a8 8 0 0 1-9.26 2.47L171.58 142a8 8 0 0 1-4.86-5.8l-6.21-29.74a7.94 7.94 0 0 0-5.14-5.9 84.39 84.39 0 0 0-55.1.13 7.93 7.93 0 0 0-5.12 6l-5.9 29.51a8 8 0 0 1-4.87 5.8l-49.09 17.42A8 8 0 0 1 26 157c-14.6-18.83-13.26-43.83 4-61.12 53.11-53.11 142.77-53.11 195.88 0 17.35 17.24 18.69 42.24 4.12 61.12Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M231.59 90.13C175.44 34 80.56 34 24.41 90.13c-20 20-21.92 49.49-4.69 71.71A16 16 0 0 0 32.35 168a15.8 15.8 0 0 0 5.75-1.08l49-17.37.29-.11a16 16 0 0 0 9.75-11.73l5.9-29.52a76.52 76.52 0 0 1 49.68-.11l6.21 29.75a16 16 0 0 0 9.72 11.59l.29.11 49 17.39a16 16 0 0 0 18.38-5.06c17.19-22.24 15.26-51.73-4.73-71.73ZM223.67 152l-.3-.12-48.82-17.33-6.21-29.74A16 16 0 0 0 158 93a92.56 92.56 0 0 0-60.34.13 16 16 0 0 0-10.32 12l-5.9 29.51-48.81 17.22c-.1 0-.17.13-.27.17-12.33-15.91-11-36.23 3.36-50.58 25-25 58.65-37.53 92.28-37.53s67.27 12.51 92.28 37.53c14.33 14.35 15.72 34.67 3.39 50.55Zm.32 48a8 8 0 0 1-8 8H40a8 8 0 0 1 0-16h176a8 8 0 0 1 8 8Z" /></Svg>;
export default PhoneDisconnect;