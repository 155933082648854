/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WebhooksLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webhooks-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webhooks-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 180h-73.29a56 56 0 1 1-104.6-37.46 12 12 0 1 1 21.37 10.92A31.64 31.64 0 0 0 32 168a32 32 0 0 0 64 0 12 12 0 0 1 12-12h84a12 12 0 0 1 0 24Zm0-68a55.9 55.9 0 0 0-18.45 3.12l-35.33-57.41a12 12 0 0 0-20.44 12.58l40.94 66.52a12 12 0 0 0 16.52 3.93 32 32 0 1 1 19.68 59.13 12 12 0 0 0 1.08 23.95 10.05 10.05 0 0 0 1.09 0A56 56 0 0 0 192 112ZM57.71 178.22a12 12 0 0 0 16.51-3.93l40.94-66.52a12 12 0 0 0-3.92-16.51 32 32 0 1 1 45.28-41.8 12 12 0 1 0 21.37-10.92 56 56 0 1 0-88.79 65.78l-35.32 57.39a12 12 0 0 0 3.93 16.51Z" /></Svg>;
export default WebhooksLogo;