/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-heart-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-heart-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32h-24v-8a8 8 0 0 0-16 0v8H88v-8a8 8 0 0 0-16 0v8H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM72 64v-8a8 8 0 0 1 16 0v8a8 8 0 0 1-16 0Zm82.56 103.07a143.52 143.52 0 0 1-24.77 16.51 4 4 0 0 1-3.58 0 143.52 143.52 0 0 1-24.77-16.51C84.56 153 76 138.51 76 124a28 28 0 0 1 52-14.41A28 28 0 0 1 180 124c0 14.51-8.56 29-25.44 43.07ZM184 64a8 8 0 0 1-16 0v-8a8 8 0 0 1 16 0Z" /></Svg>;
export default CalendarHeart;