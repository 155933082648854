/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-plus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-plus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M36 64a4 4 0 0 1 4-4h176a4 4 0 0 1 0 8H40a4 4 0 0 1-4-4Zm4 68h176a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8Zm104 56H40a4 4 0 0 0 0 8h104a4 4 0 0 0 0-8Zm88 0h-20v-20a4 4 0 0 0-8 0v20h-20a4 4 0 0 0 0 8h20v20a4 4 0 0 0 8 0v-20h20a4 4 0 0 0 0-8Z" /></Svg>;
export default ListPlus;