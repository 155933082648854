/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CellSignalX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cell-signal-x-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cell-signal-x-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212.24 195.76a6 6 0 1 1-8.48 8.48L184 184.48l-19.76 19.76a6 6 0 0 1-8.48-8.48L175.52 176l-19.76-19.76a6 6 0 0 1 8.48-8.48L184 167.52l19.76-19.76a6 6 0 0 1 8.48 8.48L192.48 176ZM160 118a6 6 0 0 0 6-6V72a6 6 0 0 0-12 0v40a6 6 0 0 0 6 6Zm40 0a6 6 0 0 0 6-6V32a6 6 0 0 0-12 0v80a6 6 0 0 0 6 6Zm-80-12a6 6 0 0 0-6 6v88a6 6 0 0 0 12 0v-88a6 6 0 0 0-6-6Zm-40 40a6 6 0 0 0-6 6v48a6 6 0 0 0 12 0v-48a6 6 0 0 0-6-6Zm-40 40a6 6 0 0 0-6 6v8a6 6 0 0 0 12 0v-8a6 6 0 0 0-6-6Z" /></Svg>;
export default CellSignalX;