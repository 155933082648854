/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleDriveLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-drive-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-drive-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241 141.65 172.59 29.51l-.07-.11a19.85 19.85 0 0 0-17-9.4h-55.08a19.85 19.85 0 0 0-17 9.4l-.07.11L15 141.65a20 20 0 0 0-.36 20.46L42 209.92A20 20 0 0 0 59.33 220h137.34A20 20 0 0 0 214 209.92l27.32-47.81a20 20 0 0 0-.32-20.46ZM211.88 140h-37.09L142 85.33l18.12-30.19ZM54.8 184l-11.44-20h23.45Zm40-20h66.4l19.2 32H75.6Zm14.4-24 18.8-31.34L146.8 140Zm80 24h23.45l-11.45 20ZM128 62l-10.81-18h21.62Zm-32.11-6.86L114 85.33 81.21 140H44.12Z" /></Svg>;
export default GoogleDriveLogo;