/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitCommit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-commit-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-commit-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 128a48 48 0 1 1-48-48 48 48 0 0 1 48 48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M248 120h-64.58a56 56 0 0 0-110.84 0H8a8 8 0 0 0 0 16h64.58a56 56 0 0 0 110.84 0H248a8 8 0 0 0 0-16Zm-120 48a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" /></Svg>;
export default GitCommit;