/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceTablet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-tablet-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-tablet-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 28H64a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20ZM52 68h152v120H52Zm12-32h128a12 12 0 0 1 12 12v12H52V48a12 12 0 0 1 12-12Zm128 184H64a12 12 0 0 1-12-12v-12h152v12a12 12 0 0 1-12 12Z" /></Svg>;
export default DeviceTablet;