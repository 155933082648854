/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlayCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="play-circle__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-play-circle'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm48.24-94.78-64-40A8 8 0 0 0 100 88v80a8 8 0 0 0 12.24 6.78l64-40a8 8 0 0 0 0-13.56ZM116 153.57v-51.14L156.91 128Z" /></Svg>;
export default PlayCircle;