/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandsClapping = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hands-clapping-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hands-clapping-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220.77 83.57 199.59 47a30.14 30.14 0 0 0-41.11-11 30.49 30.49 0 0 0-4.92 3.52L144 23a30.16 30.16 0 0 0-49.55-3.78 30.18 30.18 0 0 0-28.59.8 29.95 29.95 0 0 0-12.78 37.45A29.68 29.68 0 0 0 47.32 60a30 30 0 0 0-11 41l.3.52A30.77 30.77 0 0 0 31 104a30 30 0 0 0-11 41l35.33 61a84.48 84.48 0 0 0 115.12 30.75 83.51 83.51 0 0 0 39.27-51c.39-1.45.73-2.89 1-4.35a84.13 84.13 0 0 0 10.05-97.83Zm-53.06-23.12a6 6 0 0 1 11-1.45l21.18 36.57a59.85 59.85 0 0 1 7.34 39 85.15 85.15 0 0 0-5.9-12.57l-33-57a5.94 5.94 0 0 1-.62-4.55Zm-55.61-24a6 6 0 0 1 7.38-4.25 6 6 0 0 1 3.65 2.8l22.33 38.55A30.7 30.7 0 0 0 140 76a30.22 30.22 0 0 0-4.9 3.52L112.7 41a6 6 0 0 1-.6-4.55Zm-37 8a6 6 0 0 1 7.37-4.25 6.05 6.05 0 0 1 3.62 2.8l3.77 6.58A29.92 29.92 0 0 0 84.34 52a30.39 30.39 0 0 0-4.88 3.5L75.68 49a5.93 5.93 0 0 1-.6-4.55ZM158.44 216a60.37 60.37 0 0 1-82.23-22l-35.33-61a6 6 0 0 1 2.2-8.2 6 6 0 0 1 8.22 2.2l20.29 35a12 12 0 0 0 20.85-12L57.15 89a6 6 0 0 1 10.41-6l31.86 55a12 12 0 0 0 20.85-12L94.2 81.05V81a6 6 0 0 1 10.44-6l36.57 63A12 12 0 0 0 162 126l-12.55-21.61A6 6 0 0 1 160.21 99l20.27 35a60 60 0 0 1-22.04 82Z" /></Svg>;
export default HandsClapping;