/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radioactive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radioactive-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radioactive-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 128H40a8.1 8.1 0 0 1-8-8.86 95.94 95.94 0 0 1 40.27-69.67A8 8 0 0 1 83.82 52l26 44.94A36 36 0 0 0 92 128Zm54 31.18a36 36 0 0 1-36 0l-25.83 44.73a8 8 0 0 0 3.56 11.26 96.24 96.24 0 0 0 80.54 0 8 8 0 0 0 3.56-11.26Zm78-40a95.94 95.94 0 0 0-40.24-69.67A8 8 0 0 0 172.18 52l-25.95 45A36 36 0 0 1 164 126.12c0 .62.05 1.23.05 1.85h52a8.1 8.1 0 0 0 7.95-8.83Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M116 128a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm-16 0a8 8 0 0 0-8-8H40.07a.19.19 0 0 1-.07-.16A87.83 87.83 0 0 1 76.89 56l26 45a8 8 0 1 0 13.86-8l-26-45a16 16 0 0 0-23.1-5.07 103.83 103.83 0 0 0-43.58 75.49 16.21 16.21 0 0 0 4.17 12.37A16 16 0 0 0 40 136h52a8 8 0 0 0 8-8Zm131.93-9.58a103.83 103.83 0 0 0-43.58-75.49 16 16 0 0 0-23.1 5.07L139.3 93a8 8 0 0 0 13.86 8l26-45A87.87 87.87 0 0 1 216 119.86v.14h-52a8 8 0 0 0 0 16h52a16 16 0 0 0 11.76-5.21 16.21 16.21 0 0 0 4.17-12.37Zm-79 36.76a8 8 0 1 0-13.86 8l25.84 44.73a88.22 88.22 0 0 1-73.81 0l25.83-44.73a8 8 0 1 0-13.86-8l-25.82 44.73a16 16 0 0 0 7.12 22.52 104.24 104.24 0 0 0 87.26 0 16 16 0 0 0 7.12-22.52Z" /></Svg>;
export default Radioactive;