/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Wine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wine-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wine-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m201.5 104.8-21.78-73.93a4 4 0 0 0-3.83-2.87H80.11a4 4 0 0 0-3.83 2.87L54.5 104.8a59.51 59.51 0 0 0 16.32 60.62A83.39 83.39 0 0 0 124 187.91V236H88a4 4 0 1 0 0 8h80a4 4 0 1 0 0-8h-36v-48.09a83.39 83.39 0 0 0 53.18-22.49 59.51 59.51 0 0 0 16.32-60.62ZM83.1 36h89.8l20.93 71.06c.43 1.49.8 3 1.1 4.47-23.64 10.47-50.76.18-65.12-7.1-28.22-14.29-49.48-14.1-63.88-10.14Zm96.62 123.57a75.68 75.68 0 0 1-103.44 0 51.53 51.53 0 0 1-14.11-52.51l1-3.46c12.66-4.87 33.45-7 63 8C137 117 154.33 124 172.8 124a62.78 62.78 0 0 0 23.2-4.22 51.26 51.26 0 0 1-16.28 39.79Z" /></Svg>;
export default Wine;