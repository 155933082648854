/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Scooter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scooter-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scooter-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 138a34.32 34.32 0 0 0-4.89.36L173.69 38.1A6 6 0 0 0 168 34h-32a6 6 0 0 0 0 12h27.68l18.24 54.73L125.16 170H77.94a34 34 0 1 0-1.44 12H128a6 6 0 0 0 4.64-2.2l53.76-65.62 9.33 28A34 34 0 1 0 212 138ZM44 194a22 22 0 1 1 22-22 22 22 0 0 1-22 22Zm168 0a22 22 0 1 1 22-22 22 22 0 0 1-22 22Z" /></Svg>;
export default Scooter;