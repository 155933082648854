/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GraphicsCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="graphics-card-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-graphics-card-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 56H16v128h216a8 8 0 0 0 8-8V64a8 8 0 0 0-8-8ZM80 152a32 32 0 1 1 32-32 32 32 0 0 1-32 32Zm96 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 48H16a8 8 0 0 0-8 8v152a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h112a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16Zm0 128H24V64h208Zm-56-16a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm-24-40a23.74 23.74 0 0 1 2.35-10.34l32 32A23.74 23.74 0 0 1 176 144a24 24 0 0 1-24-24Zm48 0a23.74 23.74 0 0 1-2.35 10.34l-32-32A23.74 23.74 0 0 1 176 96a24 24 0 0 1 24 24ZM80 160a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm-24-40a23.74 23.74 0 0 1 2.35-10.34l32 32A23.74 23.74 0 0 1 80 144a24 24 0 0 1-24-24Zm48 0a23.74 23.74 0 0 1-2.35 10.34l-32-32A23.74 23.74 0 0 1 80 96a24 24 0 0 1 24 24Z" /></Svg>;
export default GraphicsCard;