/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShootingStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shooting-star-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shooting-star-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.45 70.69a11.42 11.42 0 0 0-10-7.9l-38.16-3L174.59 25a11.51 11.51 0 0 0-21.18 0l-14.68 34.78-38.15 3a11.39 11.39 0 0 0-6.53 20.06l29 24.48-8.86 36.59a11.35 11.35 0 0 0 4.34 11.83 11.51 11.51 0 0 0 12.78.58L164 136.66l32.69 19.67a11.5 11.5 0 0 0 12.77-.58 11.33 11.33 0 0 0 4.34-11.83l-8.8-36.59 29-24.48a11.32 11.32 0 0 0 3.45-12.16Zm-43.08 29.87a6 6 0 0 0-2 6l9.41 38.86-34.68-20.9a6 6 0 0 0-6.19 0l-34.72 20.89 9.41-38.86a6 6 0 0 0-2-6l-30.66-25.9 40.37-3.19a6 6 0 0 0 5.06-3.65l15.63-37 15.63 37a6 6 0 0 0 5.06 3.65l40.37 3.19ZM86.69 121.79l-58.45 58.45a6 6 0 0 1-8.48-8.48l58.45-58.45a6 6 0 0 1 8.48 8.48Zm10.82 52.7a6 6 0 0 1 0 8.49l-53.27 53.26a6 6 0 0 1-8.48-8.48L89 174.49a6 6 0 0 1 8.51 0Zm73-1a6 6 0 0 1 0 8.48l-54.29 54.28a6 6 0 0 1-8.48-8.48L162 173.48a6 6 0 0 1 8.53 0Z" /></Svg>;
export default ShootingStar;