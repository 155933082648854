/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitPullRequest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-pull-request-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-pull-request-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 64a28 28 0 1 0-32 27.71v72.58a28 28 0 1 0 8 0V91.71A28 28 0 0 0 100 64Zm-48 0a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm40 128a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm112-27.71v-53.66a19.89 19.89 0 0 0-5.86-14.15L153.66 52H192a4 4 0 0 0 0-8h-48a4 4 0 0 0-4 4v48a4 4 0 0 0 8 0V57.66l44.49 44.48a12 12 0 0 1 3.51 8.49v53.66a28 28 0 1 0 8 0ZM200 212a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default GitPullRequest;