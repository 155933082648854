/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radio = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 168a4 4 0 0 1-4 4H64a4 4 0 0 1 0-8h32a4 4 0 0 1 4 4Zm-4-36H64a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Zm132-52v112a12 12 0 0 1-12 12H40a12 12 0 0 1-12-12V72a4 4 0 0 1 2.85-3.81l160-48a4 4 0 0 1 2.3 7.66L59.25 68H216a12 12 0 0 1 12 12Zm-8 0a4 4 0 0 0-4-4H36v116a4 4 0 0 0 4 4h176a4 4 0 0 0 4-4Zm-24 56a36 36 0 1 1-36-36 36 36 0 0 1 36 36Zm-8 0a28 28 0 1 0-28 28 28 28 0 0 0 28-28Zm-92-36H64a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Z" /></Svg>;
export default Radio;