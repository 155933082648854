/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Mountains = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mountains-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mountains-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m254.88 195.92-54.56-92.08A15.87 15.87 0 0 0 186.55 96a15.85 15.85 0 0 0-13.76 7.84l-15.64 26.39a4 4 0 0 0 0 4.07l26.8 45.47a8.13 8.13 0 0 1-1.89 10.55 8 8 0 0 1-11.8-2.26L101.79 71.88a16 16 0 0 0-27.58 0L1.11 195.94a8 8 0 0 0 1 9.52A8.23 8.23 0 0 0 8.23 208h239.54a8.29 8.29 0 0 0 6.09-2.55 8 8 0 0 0 1.02-9.53ZM64.43 120 88 80l23.57 40ZM140 52a24 24 0 1 1 24 24 24 24 0 0 1-24-24Z" /></Svg>;
export default Mountains;