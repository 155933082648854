/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Subtract = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subtract-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subtract-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174.63 81.35a80 80 0 1 0-93.28 93.28 80 80 0 1 0 93.28-93.28ZM96 160a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64Z" /></Svg>;
export default Subtract;