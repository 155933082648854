/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandSwipeLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-swipe-left-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-swipe-left-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 128v50.93c0 25.59-8.48 39.93-8.84 40.65A8 8 0 0 1 200 224H64a8 8 0 0 1-6.9-3.95L26.15 160a16 16 0 0 1 6.53-22.23c7.66-4 17.1-.84 21.4 6.62l21 36.44a6.09 6.09 0 0 0 6 3.09h.12a8.19 8.19 0 0 0 6.8-8.18V56a16 16 0 0 1 16.77-16c8.61.4 15.23 7.82 15.23 16.43V128a8 8 0 0 0 8.53 8 8.17 8.17 0 0 0 7.47-8.25V112a16 16 0 0 1 16.77-16c8.61.4 15.23 7.82 15.23 16.43V136a8 8 0 0 0 8.53 8 8.18 8.18 0 0 0 7.47-8.25v-7.28c0-8.61 6.62-16 15.23-16.43A16 16 0 0 1 216 128Zm32-80h-52.69l18.35-18.34a8 8 0 1 0-11.32-11.32l-32 32a8 8 0 0 0 0 11.32l32 32a8 8 0 0 0 11.32-11.32L195.31 64H248a8 8 0 0 0 0-16Z" /></Svg>;
export default HandSwipeLeft;