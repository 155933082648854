/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitDiff = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-diff-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-diff-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M118.18 213.08c-.11.14-.24.27-.36.4l-.16.18-.17.15a4.83 4.83 0 0 1-.42.37 3.92 3.92 0 0 1-.32.25l-.3.22-.38.23a2.91 2.91 0 0 1-.3.17l-.37.19-.34.15-.36.13a2.84 2.84 0 0 1-.38.13l-.36.1c-.14 0-.26.07-.4.09l-.42.07-.35.05a7 7 0 0 1-.79 0H64a8 8 0 0 1 0-16h28.69L55 162.34a23.85 23.85 0 0 1-7-17V95a32 32 0 1 1 16 0v50.38a8 8 0 0 0 2.34 5.62L104 188.69V160a8 8 0 0 1 16 0v48a7 7 0 0 1 0 .8v.32c0 .11 0 .3-.07.46a2.83 2.83 0 0 1-.09.37c0 .13-.06.26-.1.39s-.08.23-.12.35l-.14.39-.15.31c-.06.13-.12.27-.19.4s-.11.18-.16.28l-.24.39-.21.28ZM208 161v-50.37a23.85 23.85 0 0 0-7-17L163.31 56H192a8 8 0 0 0 0-16h-48.78c-.14 0-.28 0-.41.06h-.37l-.43.11-.33.08-.4.14-.34.13-.35.16-.36.18a3.14 3.14 0 0 0-.31.18c-.12.07-.25.14-.36.22a3.55 3.55 0 0 0-.31.23 3.81 3.81 0 0 0-.32.24c-.15.12-.28.24-.42.37l-.17.15-.16.18c-.12.13-.25.26-.36.4l-.26.35-.21.28-.24.39c-.05.1-.11.19-.16.28s-.13.27-.19.4l-.15.31-.14.39c0 .12-.09.23-.12.35s-.07.26-.1.39a2.83 2.83 0 0 0-.09.37c0 .16 0 .31-.07.46s0 .21-.05.32a7 7 0 0 0 0 .8V96a8 8 0 0 0 16 0V67.31L189.66 105a8 8 0 0 1 2.34 5.66V161a32 32 0 1 0 16 0Z" /></Svg>;
export default GitDiff;