/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Truck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m255.43 117-14-35a15.93 15.93 0 0 0-14.85-10H192v-8a8 8 0 0 0-8-8H32a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h17a32 32 0 0 0 62 0h50a32 32 0 0 0 62 0h17a16 16 0 0 0 16-16v-64a8.13 8.13 0 0 0-.57-3ZM80 208a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm-48-72V72h144v64Zm160 72a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm0-96V88h34.58l9.6 24Z" /></Svg>;
export default Truck;