/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GraphicsCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="graphics-card-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-graphics-card-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 48H16a8 8 0 0 0-8 8v152a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h112a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16Zm-20 72a35.81 35.81 0 0 1-5.53 19.16l-49.63-49.63A36 36 0 0 1 212 120Zm-96 0a35.81 35.81 0 0 1-5.53 19.16L60.84 89.53A36 36 0 0 1 116 120Zm-36 36a36 36 0 0 1-30.47-55.16l49.63 49.63A35.81 35.81 0 0 1 80 156Zm60-36a35.81 35.81 0 0 1 5.53-19.16l49.63 49.63A36 36 0 0 1 140 120Z" /></Svg>;
export default GraphicsCard;