/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const At = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="at-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-at-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128v1.52c-.34 14.26-5.63 30.48-28 30.48-23.14 0-28-17.4-28-32V88a8 8 0 0 0-8.53-8 8.17 8.17 0 0 0-7.47 8.27v4a48 48 0 1 0 6.73 64.05 40.19 40.19 0 0 0 3.38 5C175.48 168 185.71 176 204 176a54.81 54.81 0 0 0 9.22-.75 4 4 0 0 1 4.09 6 104.05 104.05 0 0 1-91.4 50.75c-54.78-1.1-99.71-45.14-101.83-99.89A104 104 0 1 1 232 128Zm-136 0a32 32 0 1 0 32-32 32 32 0 0 0-32 32Z" /></Svg>;
export default At;