/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-heart-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-heart-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.76 137.88a28.66 28.66 0 0 0-12-5.39C236.19 114.94 244 97.37 244 80c0-28.67-23.09-52-51.46-52A51.77 51.77 0 0 0 156 42.85 51.77 51.77 0 0 0 119.46 28C91.09 28 68 51.33 68 80c0 10.15 2.72 20.3 8.22 30.7a35.9 35.9 0 0 0-11.73 7.84L43 140H20a20 20 0 0 0-20 20v40a20 20 0 0 0 20 20h100a11.89 11.89 0 0 0 2.91-.36l64-16a11.4 11.4 0 0 0 1.79-.6l38.82-16.54c.23-.09.45-.19.67-.3a28.61 28.61 0 0 0 4.57-48.32ZM119.46 52a27.18 27.18 0 0 1 25.44 16.56 12 12 0 0 0 22.2 0A27.18 27.18 0 0 1 192.54 52C207.42 52 220 64.82 220 80c0 18.06-15 38.84-43.38 60.19l-4.62 1.07V140a32 32 0 0 0-32-32h-37.29C95.42 97.76 92 88.77 92 80c0-15.18 12.58-28 27.46-28ZM24 164h12v32H24Zm193.68.61-37.51 16L118.52 196H60v-39l21.46-21.46a11.93 11.93 0 0 1 8.48-3.54H140a8 8 0 0 1 0 16h-28a12 12 0 0 0 0 24h32a12.19 12.19 0 0 0 2.69-.3l67-15.41.47-.12a4.61 4.61 0 0 1 5.82 4.44 4.58 4.58 0 0 1-2.3 4Z" /></Svg>;
export default HandHeart;