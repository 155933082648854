/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radio = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 64H86.51L194.3 31.67a8 8 0 0 0-4.6-15.33l-160 48A8 8 0 0 0 24 72v120a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16ZM104 176H64a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm0-32H64a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm0-32H64a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm64 56a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" /></Svg>;
export default Radio;