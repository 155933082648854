/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Panorama = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="panorama__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-panorama'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.75 51.32a15.88 15.88 0 0 0-13.86-2.77l-3.48.94C205.61 54.56 170.61 64 128 64s-77.61-9.44-96.41-14.51l-3.48-.94A16 16 0 0 0 8 64v128a16 16 0 0 0 16 16 16.22 16.22 0 0 0 4.18-.55l3.18-.86C50.13 201.49 85.17 192 128 192s77.87 9.49 96.69 14.59l3.18.86A16 16 0 0 0 248 192V64a15.9 15.9 0 0 0-6.25-12.68ZM27.42 64.93C46.94 70.2 83.27 80 128 80s81.06-9.8 100.58-15.07L232 64v118.76l-58.07-58.07a16 16 0 0 0-22.63 0l-20 20-44-44a16 16 0 0 0-22.62 0L24 141.37V64Zm186.42 122.28a391.22 391.22 0 0 0-49-9L142.63 156l20-20Zm-186.71 3.93L24 192v-28l52-52 64.25 64.25q-6-.24-12.25-.25c-45 0-82.72 10.23-100.87 15.14ZM192 108a12 12 0 1 1 12 12 12 12 0 0 1-12-12Z" /></Svg>;
export default Panorama;