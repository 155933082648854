/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Goggles = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="goggles-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-goggles-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 60H76a76.08 76.08 0 0 0-76 76v4a24 24 0 0 0 13.74 21.69c3.15 8.71 10.51 16.75 21.52 23.27 11.52 6.81 25.6 11 36.74 11a44.06 44.06 0 0 0 42.32-32h27.36A44.06 44.06 0 0 0 184 196c18.53 0 50.62-12.81 58.31-34.33A24 24 0 0 0 256 140v-4a76.08 76.08 0 0 0-76-76ZM76 84h104a52.07 52.07 0 0 1 51.13 42.6 65 65 0 0 0-10.39-7.6c-11.52-6.81-25.6-11-36.74-11a44.06 44.06 0 0 0-42.32 32h-27.36A44.06 44.06 0 0 0 72 108c-13.4 0-33.9 6.71-47.13 18.56A52.08 52.08 0 0 1 76 84Zm-4 88c-14.13 0-36-12.15-36-20 0-2.74 3.55-7.61 11.48-12.3 9-5.32 18.8-7.7 24.52-7.7a20 20 0 0 1 0 40Zm136.52-7.7c-9 5.32-18.8 7.7-24.52 7.7a20 20 0 0 1 0-40c14.13 0 36 12.15 36 20 0 2.74-3.55 7.61-11.48 12.3Z" /></Svg>;
export default Goggles;