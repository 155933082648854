/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderSimpleMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-simple-minus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-simple-minus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 68h-84l-26.67-20a20.12 20.12 0 0 0-12-4H40a20 20 0 0 0-20 20v136a20 20 0 0 0 20 20h176.89A19.13 19.13 0 0 0 236 200.89V88a20 20 0 0 0-20-20Zm-4 128H44V68h48l28.8 21.6A12 12 0 0 0 128 92h84Zm-108-64h48a12 12 0 0 1 0 24h-48a12 12 0 0 1 0-24Z" /></Svg>;
export default FolderSimpleMinus;