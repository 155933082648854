/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Users = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="users-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-users-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M112.6 158.43a58 58 0 1 0-57.2 0 93.83 93.83 0 0 0-50.19 38.29 6 6 0 0 0 10.05 6.56 82 82 0 0 1 137.48 0 6 6 0 0 0 10-6.56 93.83 93.83 0 0 0-50.14-38.29ZM38 108a46 46 0 1 1 46 46 46.06 46.06 0 0 1-46-46Zm211 97a6 6 0 0 1-8.3-1.74A81.8 81.8 0 0 0 172 166a6 6 0 0 1 0-12 46 46 0 1 0-17.08-88.73 6 6 0 1 1-4.46-11.14 58 58 0 0 1 50.14 104.3 93.83 93.83 0 0 1 50.19 38.29A6 6 0 0 1 249 205Z" /></Svg>;
export default Users;