/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PathIcon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="path-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-path-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 164a36.07 36.07 0 0 0-33.94 24H72a28 28 0 0 1 0-56h96a44 44 0 0 0 0-88H72a12 12 0 0 0 0 24h96a20 20 0 0 1 0 40H72a52 52 0 0 0 0 104h94.06A36 36 0 1 0 200 164Zm0 48a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default PathIcon;