/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LessThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="less-than-or-equal-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-less-than-or-equal-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M36 104a12 12 0 0 1 7.85-11.26l152-56a12 12 0 1 1 8.3 22.52L82.71 104l121.44 44.74A12 12 0 0 1 200 172a11.85 11.85 0 0 1-4.15-.74l-152-56A12 12 0 0 1 36 104Zm164 84H48a12 12 0 0 0 0 24h152a12 12 0 0 0 0-24Z" /></Svg>;
export default LessThanOrEqual;