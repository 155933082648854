/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookOpenUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="book-open-user-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-book-open-user-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 72h-72a43.86 43.86 0 0 0-32 13.85A43.86 43.86 0 0 0 96 72H24a12 12 0 0 0-12 12v116a12 12 0 0 0 12 12h72a20 20 0 0 1 20 20 12 12 0 0 0 24 0 20 20 0 0 1 20-20h72a12 12 0 0 0 12-12V84a12 12 0 0 0-12-12ZM96 188H36V96h60a20 20 0 0 1 20 20v76.81A43.79 43.79 0 0 0 96 188Zm124 0h-60a43.71 43.71 0 0 0-20 4.83V116a20 20 0 0 1 20-20h60ZM86.4 40.79a52 52 0 0 1 83.2 0 12 12 0 0 1-19.19 14.42 28 28 0 0 0-44.82 0A12 12 0 0 1 86.4 40.79Z" /></Svg>;
export default BookOpenUser;