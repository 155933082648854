/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BowlingBall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bowling-ball-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bowling-ball-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a96 96 0 1 1-96-96 96 96 0 0 1 96 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm16-100a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm20-12a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm20 28a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default BowlingBall;