/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Peace = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="peace__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-peace'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm88 104a87.48 87.48 0 0 1-11.64 43.7L136 123.84V40.37A88.11 88.11 0 0 1 216 128Zm-96-87.63v83.47L51.64 171.7A88 88 0 0 1 120 40.37ZM60.84 184.79 120 143.37v72.26a87.85 87.85 0 0 1-59.16-30.84ZM136 215.63v-72.26l59.16 41.42A87.85 87.85 0 0 1 136 215.63Z" /></Svg>;
export default Peace;