/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberTwo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-two-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-two-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 208a4 4 0 0 1-4 4H88a4 4 0 0 1-3.2-6.4l71.94-95.92a36 36 0 1 0-57.49-43.34A36.62 36.62 0 0 0 95 73.61a4 4 0 0 1-7.33-3.21 44.42 44.42 0 0 1 5.2-8.87 44 44 0 0 1 70.28 53L96 204h72a4 4 0 0 1 4 4Z" /></Svg>;
export default NumberTwo;