/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThreadsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="threads-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-threads-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M185.22 125.25a62 62 0 0 0-11.78-7c-3.53-29.6-23-38.82-31.83-41.5-19-5.74-40.73 1.09-50.6 15.9a6 6 0 1 0 10 6.66c6.94-10.41 23.25-15.28 37.14-11.07 7.22 2.18 18.39 8.34 22.39 25.61a78.74 78.74 0 0 0-18.11-2.08c-13.53 0-26.16 3.46-35.55 9.77C96 128.85 90 139.66 90 152c0 22 18 33.52 35.71 33.52a46 46 0 0 0 32.91-14.19c6.58-6.85 14.35-19.11 15.29-39.26a44.59 44.59 0 0 1 4.07 2.75c10.48 7.92 16 19.4 16 33.18 0 20.16-21 50-66 50-27.07 0-46.92-9.19-59-27.33C59 175.75 54 154.66 54 128s5-47.75 15-62.67C81.08 47.19 100.93 38 128 38c33.85 0 55.57 13.67 66.4 41.8a6 6 0 1 0 11.2-4.31C193 42.65 166.85 26 128 26c-31.33 0-54.54 11-69 32.67C47.72 75.6 42 98.93 42 128s5.72 52.4 17 69.33C73.46 219 96.67 230 128 230c29.43 0 47.81-11.19 58.05-20.58C198.54 198 206 182.49 206 168c0-17.69-7.19-32.48-20.78-42.75ZM150 163a33.94 33.94 0 0 1-24.26 10.51c-16.41.01-23.74-10.8-23.74-21.51 0-13.59 12.64-28.21 40.41-28.21a65.33 65.33 0 0 1 19.58 3v1.24c.01 14.69-4.15 26.79-11.99 34.97Z" /></Svg>;
export default ThreadsLogo;