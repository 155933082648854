/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-circle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-circle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90ZM114 80a14 14 0 1 1 14 14 14 14 0 0 1-14-14Zm68 32a6 6 0 0 1-6 6h-42v16.18l31 46.49a6 6 0 1 1-10 6.66l-27-40.51-27 40.51a6 6 0 1 1-10-6.66l31-46.49V118H80a6 6 0 0 1 0-12h96a6 6 0 0 1 6 6Z" /></Svg>;
export default PersonSimpleCircle;