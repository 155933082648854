/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShirtFolded = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shirt-folded-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shirt-folded-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 36h-23l-12.51-12.49A12 12 0 0 0 156 20h-56a12 12 0 0 0-8.49 3.51L79 36H56a20 20 0 0 0-20 20v152a20 20 0 0 0 20 20h144a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20ZM96 53l7.33-7.33 15.49 26.53L96 94.48Zm41.18 19.2 15.49-26.56L160 53v41.48ZM60 60h12v44a20 20 0 0 0 34.08 14.21l9.92-9.71V204H60Zm136 144h-56v-95.5l9.92 9.69A20 20 0 0 0 184 104V60h12Z" /></Svg>;
export default ShirtFolded;