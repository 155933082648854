/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M171 50.37a250.18 250.18 0 0 0-40.73-37.65 4 4 0 0 0-4.58 0A250.18 250.18 0 0 0 85 50.37C58.17 81.21 44 113.58 44 144a84 84 0 0 0 168 0c0-30.42-14.17-62.79-41-93.63ZM128 220a76.08 76.08 0 0 1-76-76c0-35.9 21.15-67.8 38.9-88.23A254 254 0 0 1 128 21a254 254 0 0 1 37.1 34.81C182.85 76.2 204 108.1 204 144a76.08 76.08 0 0 1-76 76Z" /></Svg>;
export default DropSimple;