/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RedditLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="reddit-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-reddit-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246 104a30 30 0 0 0-50.64-21.76C178 72.75 157 67.17 135.05 66.18l5.89-35.35 21.16 3.26a22 22 0 1 0 2.17-11.81l-27.36-4.21a6 6 0 0 0-6.83 4.93l-7.18 43.09c-22.64.74-44.41 6.38-62.26 16.15a30 30 0 1 0-40 44.66A57.07 57.07 0 0 0 18 144c0 21.26 11.74 41.09 33.05 55.84C71.68 214.13 99 222 128 222s56.32-7.87 76.95-22.16C226.26 185.09 238 165.26 238 144a57.07 57.07 0 0 0-2.63-17.1A30.16 30.16 0 0 0 246 104Zm-62-82a10 10 0 1 1-10 10 10 10 0 0 1 10-10Zm41.15 97.51a6 6 0 0 0-2.46 7.51A45.65 45.65 0 0 1 226 144c0 36.39-44 66-98 66s-98-29.61-98-66a45.63 45.63 0 0 1 3.3-16.94 6 6 0 0 0-2.45-7.55 18 18 0 1 1 23.71-26.1 6 6 0 0 0 7.79 1.7 3.79 3.79 0 0 0 .34-.2C80.7 84 103.89 78 128 78c24.1 0 47.28 6 65.29 16.9l.16.11a6 6 0 0 0 8-1.6 18 18 0 1 1 23.71 26.1Zm-59.85 53.68a6 6 0 0 1-2.49 8.11 74.22 74.22 0 0 1-69.62 0 6 6 0 0 1 5.62-10.6 62.2 62.2 0 0 0 58.38 0 6 6 0 0 1 8.11 2.49ZM88 142a14 14 0 1 1 14-14 14 14 0 0 1-14 14Zm94-14a14 14 0 1 1-14-14 14 14 0 0 1 14 14Z" /></Svg>;
export default RedditLogo;