/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Island = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="island-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-island-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.12 226.5a4 4 0 0 1-5.62.62c-.39-.31-39.78-31.12-101.5-31.12S26.89 226.81 26.5 227.13a4 4 0 0 1-5-6.25c1.63-1.31 39.56-31.08 101-32.79a116.11 116.11 0 0 1-4.45-41.4c1.79-22.37 11.85-52.74 48.61-74.16-6.46-2.24-16.29-4.83-26.49-4.49-20.74.67-34.28 13.1-40.25 36.94a4 4 0 1 1-7.8-1.98c8.64-34.54 30.74-42.45 47.75-43 11.46-.36 22.22 2.44 29.34 4.91-3.63-8.39-10.93-21-23.4-26.37-11.15-4.77-24.56-2.87-39.86 5.67a4 4 0 1 1-3.9-7c17.6-9.82 33.4-11.85 47-6 14.6 6.28 23 20.15 27.23 29.74 4.21-8.4 11.87-19.69 23.84-23.59 10.55-3.43 22.1-.49 34.33 8.76a4 4 0 1 1-4.82 6.38c-10-7.57-19.1-10.11-27-7.54-11.14 3.61-18 16.42-21 23.46A67.51 67.51 0 0 1 206 76.53c24.81 14.18 30 40.17 30 59.47a4 4 0 0 1-8 0c0-43.89-27.5-55.67-42.53-58.83 10.67 15 11.46 29.37 9.92 39.44a54.76 54.76 0 0 1-8.18 21.7 4 4 0 0 1-3.27 1.69 3.92 3.92 0 0 1-2.3-.73 4 4 0 0 1-1-5.57c.85-1.22 19.26-28.4-5.57-56.73-30 15.86-46.55 39.42-49.1 70.06a108.78 108.78 0 0 0 4.84 41c63 .91 102 31.5 103.65 32.83a4 4 0 0 1 .66 5.64ZM28 140a24 24 0 1 1 24 24 24 24 0 0 1-24-24Zm8 0a16 16 0 1 0 16-16 16 16 0 0 0-16 16Z" /></Svg>;
export default Island;