/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const XLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="x-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-x-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.37 213.85 147.13 112.9 211 42.69a4 4 0 0 0-6-5.38l-62.32 68.59-43.3-68.05A4 4 0 0 0 96 36H48a4 4 0 0 0-3.37 6.15l64.24 100.95L45 213.31a4 4 0 1 0 6 5.38l62.36-68.59 43.3 68.05A4 4 0 0 0 160 220h48a4 4 0 0 0 3.37-6.15ZM162.2 212 55.29 44H93.8l106.91 168Z" /></Svg>;
export default XLogo;