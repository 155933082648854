/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StackMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stack-minus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stack-minus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 200a12 12 0 0 1-12 12h-40a12 12 0 0 1 0-24h40a12 12 0 0 1 12 12Zm-22-82.37-90 52.48-89.95-52.48A12 12 0 0 0 26 138.37l96 56a12 12 0 0 0 12.1 0l96-56a12 12 0 1 0-12.1-20.74ZM20 80a12 12 0 0 1 6-10.37l96-56a12.06 12.06 0 0 1 12.1 0l96 56a12 12 0 0 1 0 20.74l-96 56a12 12 0 0 1-12.1 0l-96-56A12 12 0 0 1 20 80Zm35.82 0L128 122.11 200.18 80 128 37.89ZM138 212.3l-10 5.81-89.95-52.48A12 12 0 0 0 26 186.37l96 56a12 12 0 0 0 12.1 0l16-9.34A12 12 0 1 0 138 212.3Z" /></Svg>;
export default StackMinus;