/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CaretRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="caret-right-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-caret-right-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m176 128-80 80V48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m181.66 122.34-80-80A8 8 0 0 0 88 48v160a8 8 0 0 0 13.66 5.66l80-80a8 8 0 0 0 0-11.32ZM104 188.69V67.31L164.69 128Z" /></Svg>;
export default CaretRight;