/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EscalatorDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="escalator-down-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-escalator-down-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 160v40a8 8 0 0 1-8 8h-56L72 104H32a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8h56l96 104h40a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M170.34 85.66a8 8 0 0 1 11.32-11.32L192 84.69V48a8 8 0 0 1 16 0v36.69l10.34-10.35a8 8 0 0 1 11.32 11.32l-24 24a8 8 0 0 1-11.32 0ZM240 160v40a16 16 0 0 1-16 16h-56a8 8 0 0 1-5.88-2.57L68.5 112H32a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h56a8 8 0 0 1 5.88 2.57L187.5 144H224a16 16 0 0 1 16 16Zm-16 0h-40a8 8 0 0 1-5.88-2.57L84.5 56H32v40h40a8 8 0 0 1 5.88 2.57L171.5 200H224Z" /></Svg>;
export default EscalatorDown;