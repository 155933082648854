/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WaveTriangle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wave-triangle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wave-triangle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m76 56 52 72H24Zm156 72H128l52 72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m238.48 132.68-52 72a8 8 0 0 1-13 0L76 69.66l-45.51 63a8 8 0 1 1-13-9.36l52-72a8 8 0 0 1 13 0l97.51 135 45.51-63a8 8 0 1 1 13 9.36Z" /></Svg>;
export default WaveTriangle;