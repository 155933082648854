/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EnvelopeSimpleOpen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="envelope-simple-open__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-envelope-simple-open'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m228.44 89.34-96-64a8 8 0 0 0-8.88 0l-96 64A8 8 0 0 0 24 96v104a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V96a8 8 0 0 0-3.56-6.66ZM128 41.61l81.91 54.61-67 47.78h-29.8l-67-47.78ZM40 200v-88.47l65.9 47a8 8 0 0 0 4.65 1.49h34.9a8 8 0 0 0 4.65-1.49l65.9-47V200Z" /></Svg>;
export default EnvelopeSimpleOpen;