/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListBullets = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-bullets-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-bullets-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M84 64a4 4 0 0 1 4-4h128a4 4 0 0 1 0 8H88a4 4 0 0 1-4-4Zm132 60H88a4 4 0 0 0 0 8h128a4 4 0 0 0 0-8Zm0 64H88a4 4 0 0 0 0 8h128a4 4 0 0 0 0-8ZM44 120a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm0-64a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm0 128a8 8 0 1 0 8 8 8 8 0 0 0-8-8Z" /></Svg>;
export default ListBullets;