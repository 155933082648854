/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartStraightBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-straight-break-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-straight-break-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M113.29 55.31A58 58 0 0 0 32.93 139l89.37 90.66a8 8 0 0 0 11.4 0L223 139a58 58 0 0 0-82-82.1l-24.4 23 26.4 26.42a8 8 0 0 1 0 11.32l-20.69 20.69A8 8 0 1 1 111 127l15-15-26.5-26.58a8 8 0 0 1 .22-11.53l13.55-12.78a4 4 0 0 0 0-5.8Z" /></Svg>;
export default HeartStraightBreak;