/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TruckTrailer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-trailer-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-trailer-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M16 72h104v112h-16a24 24 0 0 0-48 0 24 24 0 0 0-48 0V80a8 8 0 0 1 8-8Zm200 32v-8a48 48 0 0 0-48-48h-8a8 8 0 0 0-8 8v128h48a24 24 0 0 1 48 0v-48a32 32 0 0 0-32-32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M224 96.8V96a56.06 56.06 0 0 0-56-56h-8a16 16 0 0 0-16 16v120h-16V72a8 8 0 0 0-8-8H16A16 16 0 0 0 0 80v104a32 32 0 0 0 56 21.13A32 32 0 0 0 111 192h82a32 32 0 0 0 63-8v-48a40.07 40.07 0 0 0-32-39.2ZM160 56h8a40 40 0 0 1 40 40v8a8 8 0 0 0 8 8 24 24 0 0 1 24 24v20.31a31.71 31.71 0 0 0-16-4.31 32.06 32.06 0 0 0-31 24h-33Zm-48 24v96h-1a32 32 0 0 0-55-13.13 31.9 31.9 0 0 0-40-6.56V80ZM32 200a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm48 0a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm144 0a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default TruckTrailer;