/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WechatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wechat-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wechat-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 140a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm44-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm51.34 83.47a16 16 0 0 1-19.87 19.87l-24.71-7.27a80 80 0 0 1-108.33-40.65 79 79 0 0 1-25.19-7.35l-24.71 7.27a16 16 0 0 1-19.87-19.87l7.27-24.71a80 80 0 1 1 145.65-66.17 80 80 0 0 1 62.49 114.17ZM81.3 166.3a79.94 79.94 0 0 1 70.38-93.87 64 64 0 0 0-112.13 61.76 8 8 0 0 1 .63 6L32 168l27.76-8.17a8 8 0 0 1 6 .63 63.45 63.45 0 0 0 15.54 5.84Zm135.15 15.89a64 64 0 1 0-26.26 26.26 8 8 0 0 1 6-.63L224 216l-8.17-27.76a8 8 0 0 1 .62-6.05Z" /></Svg>;
export default WechatLogo;