/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SortDescending = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sort-descending-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sort-descending-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M44 128a4 4 0 0 1 4-4h72a4 4 0 0 1 0 8H48a4 4 0 0 1-4-4Zm4-60h56a4 4 0 0 0 0-8H48a4 4 0 0 0 0 8Zm136 120H48a4 4 0 0 0 0 8h136a4 4 0 0 0 0-8Zm42.83-102.83-40-40a4 4 0 0 0-5.66 0l-40 40a4 4 0 0 0 5.66 5.66L180 57.66V144a4 4 0 0 0 8 0V57.66l33.17 33.17a4 4 0 1 0 5.66-5.66Z" /></Svg>;
export default SortDescending;