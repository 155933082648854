/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const QuestionMark = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="question-mark-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-question-mark-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188 96c0 27.51-24.77 50.09-56 51.89V160a4 4 0 0 1-8 0v-16a4 4 0 0 1 4-4c28.67 0 52-19.74 52-44s-23.33-44-52-44-52 19.74-52 44a4 4 0 0 1-8 0c0-28.67 26.92-52 60-52s60 23.33 60 52Zm-60 100a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z" /></Svg>;
export default QuestionMark;