/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudSnow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-snow-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-snow-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 196a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm28 4a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm48-16a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm-96 40a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm88 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm75.87-136.45a76 76 0 0 0-151.78.73A8.18 8.18 0 0 1 72 96h-.6a8.14 8.14 0 0 1-7.4-8.61 92.48 92.48 0 0 1 2.33-16.51 4 4 0 0 0-5-4.78A52.09 52.09 0 0 0 24 116.36C24.2 145.07 48.12 168 76.84 168H156a76.08 76.08 0 0 0 75.87-80.45Z" /></Svg>;
export default CloudSnow;