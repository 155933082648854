/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Books = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="books-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-books-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m231.65 194.55-33.19-157.8a16 16 0 0 0-19-12.39l-46.81 10.06a16.08 16.08 0 0 0-12.3 19l33.19 157.8A16 16 0 0 0 169.16 224a16.25 16.25 0 0 0 3.38-.36l46.81-10.06a16.09 16.09 0 0 0 12.3-19.03ZM136 50.15v-.09l46.8-10 3.33 15.87L139.33 66Zm10 47.38-3.35-15.9 46.82-10.06 3.34 15.9Zm70 100.41-46.8 10-3.33-15.87 46.8-10.07 3.33 15.85v.09ZM104 32H56a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM56 48h48v16H56Zm48 160H56v-16h48v16Z" /></Svg>;
export default Books;