/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StackSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stack-simple__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stack-simple'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m12 111 112 64a8 8 0 0 0 7.94 0l112-64a8 8 0 0 0 0-13.9l-112-64a8 8 0 0 0-7.94 0l-112 64a8 8 0 0 0 0 13.9Zm116-61.79L223.87 104 128 158.79 32.13 104ZM246.94 140a8 8 0 0 1-2.94 11l-112 64a8 8 0 0 1-7.94 0L12 151a8 8 0 0 1 8-13.95l108 61.74 108-61.74a8 8 0 0 1 10.94 2.95Z" /></Svg>;
export default StackSimple;