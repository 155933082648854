/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrainRegional = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="train-regional-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-train-regional-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.58 116.84-22.4-82.1A20 20 0 0 0 185.89 20H70.11a20 20 0 0 0-19.29 14.74l-22.4 82.1a12.1 12.1 0 0 0 0 6.32l22.4 82.1A20 20 0 0 0 70.11 220H72l-9.6 12.8a12 12 0 1 0 19.2 14.4L102 220h52l20.4 27.2a12 12 0 0 0 19.2-14.4L184 220h1.89a20 20 0 0 0 19.29-14.74l22.4-82.1a12.1 12.1 0 0 0 0-6.32ZM73.17 44h109.66L201 110.53l-73 13.27-73-13.27Zm-16.58 91.21L116 146v50H73.17ZM182.83 196H140v-50l59.41-10.8ZM84 88a12 12 0 0 1 12-12h64a12 12 0 0 1 0 24H96a12 12 0 0 1-12-12Z" /></Svg>;
export default TrainRegional;