/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotSubsetOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-subset-of-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-subset-of-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216.07 31.12a12 12 0 0 0-16.95.81l-3.7 4.07H128a91.95 91.95 0 0 0-70.2 151.39l-18.68 20.54a12 12 0 1 0 17.76 16.14l18.67-20.53A91.48 91.48 0 0 0 128 220h72a12 12 0 0 0 0-24h-72a67.51 67.51 0 0 1-36.12-10.43l125-137.5a12 12 0 0 0-.81-16.95ZM60 128a68.07 68.07 0 0 1 68-68h45.6L74.13 169.42A67.62 67.62 0 0 1 60 128Z" /></Svg>;
export default NotSubsetOf;