/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileTsx = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-tsx-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-tsx-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212.88 155.48 195.37 180l17.51 24.51a6 6 0 1 1-9.76 7L188 190.31l-15.12 21.16a6 6 0 1 1-9.76-7L180.63 180l-17.51-24.51a6 6 0 0 1 9.76-7L188 169.67l15.12-21.17a6 6 0 1 1 9.76 7Zm-89.83 17.74c-10.86-3.13-13.41-4.69-13-7.91a6.59 6.59 0 0 1 2.88-5.08c5.6-3.79 17.66-1.83 21.45-.84a6 6 0 0 0 3.06-11.6c-2-.53-20.1-5-31.21 2.48a18.61 18.61 0 0 0-8.08 13.54c-1.8 14.19 12.26 18.25 21.57 20.94 12.12 3.5 14.78 5.33 14.2 9.76a6.85 6.85 0 0 1-3 5.34c-5.61 3.73-17.48 1.64-21.19.62a6 6 0 0 0-3.25 11.53 59.41 59.41 0 0 0 14.68 2c5.49 0 11.54-.95 16.36-4.14a18.89 18.89 0 0 0 8.31-13.81c2.01-15.66-12.91-19.97-22.78-22.83ZM80 146H40a6 6 0 0 0 0 12h14v50a6 6 0 0 0 12 0v-50h14a6 6 0 0 0 0-12Zm134-58v24a6 6 0 1 1-12 0V94h-50a6 6 0 0 1-6-6V38H56a2 2 0 0 0-2 2v72a6 6 0 1 1-12 0V40a14 14 0 0 1 14-14h96a6 6 0 0 1 4.25 1.76l56 56A6 6 0 0 1 214 88Zm-20.49-6L158 46.48V82Z" /></Svg>;
export default FileTsx;