/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyNervous = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-nervous-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-nervous-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90ZM82 108a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm72 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm28 60a6 6 0 0 1-6 6c-9 0-13.51-6-16.8-10.4-3-4.06-4.46-5.6-7.2-5.6s-4.15 1.54-7.2 5.6C141.51 168 137 174 128 174s-13.51-6-16.8-10.4c-3-4.06-4.46-5.6-7.2-5.6s-4.15 1.54-7.2 5.6C93.51 168 89 174 80 174a6 6 0 0 1 0-12c2.74 0 4.15-1.54 7.2-5.6C90.49 152 95 146 104 146s13.51 6 16.8 10.4c3 4.06 4.46 5.6 7.2 5.6s4.15-1.54 7.2-5.6C138.49 152 143 146 152 146s13.51 6 16.8 10.4c3 4.06 4.46 5.6 7.2 5.6a6 6 0 0 1 6 6Z" /></Svg>;
export default SmileyNervous;