/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Carrot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="carrot-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-carrot-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 66h-33.52l29.76-29.76a6 6 0 1 0-8.48-8.48L190 57.52V24a6 6 0 0 0-12 0v36.15a62 62 0 0 0-77.8 8C60.17 107.4 30.05 201.45 27.38 210a14 14 0 0 0 18.67 18.67c8.5-2.67 102.62-32.81 141.79-72.77a62 62 0 0 0 8-77.84H232a6 6 0 0 0 0-12Zm-52.69 81.41C170 157 157 166 142.69 174.24l-26.46-26.47a6 6 0 1 0-8.49 8.49l24 24c-41.69 22-89 36.82-89.73 37a6.57 6.57 0 0 0-1.06.44 2 2 0 0 1-2.7-2.7 6.57 6.57 0 0 0 .45-1c.3-1 28.85-92.28 65.78-133l35.27 35.26a6 6 0 1 0 8.48-8.48l-35.1-35.1a50 50 0 0 1 66.18 74.74Z" /></Svg>;
export default Carrot;