/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SkypeLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="skype-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-skype-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 176a48 48 0 0 1-77.31 38A88.07 88.07 0 0 1 42 109.31 48 48 0 0 1 109.31 42 88.07 88.07 0 0 1 214 146.69 47.77 47.77 0 0 1 224 176Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M168 152c0 17.64-17.94 32-40 32s-40-14.36-40-32a8 8 0 0 1 16 0c0 8.67 11 16 24 16s24-7.33 24-16c0-9.48-8.61-13-26.88-18.26-15.75-4.54-35.34-10.19-35.34-29.74 0-18.24 16.43-32 38.22-32 15.72 0 29.18 7.3 35.12 19a8 8 0 1 1-14.27 7.22C145.64 91.94 137.65 88 128 88c-12.67 0-22.22 6.88-22.22 16 0 7 9 10.1 23.77 14.36C145.78 123 168 129.45 168 152Zm64 24a56.06 56.06 0 0 1-56 56 55.38 55.38 0 0 1-31.17-9.47A96.09 96.09 0 0 1 33.47 111.17 55.38 55.38 0 0 1 24 80a56.06 56.06 0 0 1 56-56 55.38 55.38 0 0 1 31.17 9.47 96.09 96.09 0 0 1 111.36 111.36A55.38 55.38 0 0 1 232 176Zm-16 0a39.6 39.6 0 0 0-8.32-24.42 8 8 0 0 1-1.49-6.58A80.07 80.07 0 0 0 111 49.81a8 8 0 0 1-6.58-1.49A39.6 39.6 0 0 0 80 40a40 40 0 0 0-40 40 39.6 39.6 0 0 0 8.32 24.42 8 8 0 0 1 1.49 6.58A80.07 80.07 0 0 0 145 206.19a8 8 0 0 1 6.58 1.49A39.6 39.6 0 0 0 176 216a40 40 0 0 0 40-40Z" /></Svg>;
export default SkypeLogo;