/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceMobile = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-mobile-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-mobile-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 20H80a20 20 0 0 0-20 20v176a20 20 0 0 0 20 20h96a20 20 0 0 0 20-20V40a20 20 0 0 0-20-20ZM68 60h120v136H68Zm12-32h96a12 12 0 0 1 12 12v12H68V40a12 12 0 0 1 12-12Zm96 200H80a12 12 0 0 1-12-12v-12h120v12a12 12 0 0 1-12 12Z" /></Svg>;
export default DeviceMobile;