/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CarBattery = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="car-battery-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-car-battery-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 140a12 12 0 0 1-12 12h-4v4a12 12 0 0 1-24 0v-4h-4a12 12 0 0 1 0-24h4v-4a12 12 0 0 1 24 0v4h4a12 12 0 0 1 12 12Zm-100-12H68a12 12 0 0 0 0 24h32a12 12 0 0 0 0-24Zm144-36v92a20 20 0 0 1-20 20H32a20 20 0 0 1-20-20V92a20 20 0 0 1 20-20h12V56a20 20 0 0 1 20-20h32a20 20 0 0 1 20 20v16h24V56a20 20 0 0 1 20-20h32a20 20 0 0 1 20 20v16h12a20 20 0 0 1 20 20Zm-80-20h24V60h-24Zm-96 0h24V60H68Zm152 24H36v84h184Z" /></Svg>;
export default CarBattery;