/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Scooter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scooter-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scooter-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 140a31.29 31.29 0 0 0-6.24.62l-34-101.88A4 4 0 0 0 168 36h-32a4 4 0 0 0 0 8h29.12l19 57.14-58 70.86H76a32 32 0 1 0-1 8h53a4 4 0 0 0 3.09-1.46l56.06-68.43 11 33A32 32 0 1 0 212 140ZM44 196a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm168 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default Scooter;