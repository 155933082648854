/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lego = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lego-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lego-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m245.36 77.28-41.62-20.83C201.38 40.24 183 28 160 28c-24.67 0-44 14.06-44 32a25 25 0 0 0 2.69 11.24l-9.4 4.7C101.56 71 91.35 68 80 68c-24.67 0-44 14.06-44 32a25 25 0 0 0 2.69 11.24l-28.06 14A12 12 0 0 0 4 136v56a12 12 0 0 0 6.63 10.73l64 32a12 12 0 0 0 10.74 0l160-80A12 12 0 0 0 252 144V88a12 12 0 0 0-6.64-10.72ZM80 154.58 42.83 136l15.81-7.9A58.2 58.2 0 0 0 80 132c24.67 0 44-14.06 44-32a25.39 25.39 0 0 0-.4-4.38l15-7.52A58.2 58.2 0 0 0 160 92c14.72 0 27.53-5 35.47-12.85l17.7 8.85ZM160 52c12.91 0 20 6 20 8s-7.09 8-20 8-20-6-20-8 7.09-8 20-8ZM80 92c12.91 0 20 6 20 8s-7.09 8-20 8-20-6-20-8 7.09-8 20-8Zm-52 63.42 40 20v29.16l-40-20Zm64 49.16v-29.16l136-68v29.16Z" /></Svg>;
export default Lego;