/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextColumns = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-columns-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-columns-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 64a4 4 0 0 1-4 4H40a4 4 0 0 1 0-8h72a4 4 0 0 1 4 4Zm-4 36H40a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Zm0 40H40a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Zm0 40H40a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Zm32-112h72a4 4 0 0 0 0-8h-72a4 4 0 0 0 0 8Zm72 32h-72a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Zm0 40h-72a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Zm0 40h-72a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Z" /></Svg>;
export default TextColumns;