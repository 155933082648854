/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Butterfly = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="butterfly-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-butterfly-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.1 51.71C226.09 45.27 218.64 42 209 42c-16.33 0-37.41 11.06-56.4 29.59A132 132 0 0 0 134 93.94V56a6 6 0 0 0-12 0v37.94a132 132 0 0 0-18.54-22.35C84.49 53.06 63.4 42 47.07 42c-9.69 0-17.14 3.27-22.15 9.71-5.53 7.11-7.71 17.69-6.66 32.34.91 12.73 4.12 26.53 6.81 37.13 6.28 24.74 20.77 33 31.78 35.68A42 42 0 1 0 128 201.62a42 42 0 1 0 71.16-44.76c11-2.63 25.5-10.94 31.78-35.68 6.61-25.97 11.78-54.53.16-69.47ZM92 210a30.12 30.12 0 0 1-3.34-60 6 6 0 0 0-1.31-12 41.71 41.71 0 0 0-20.28 8c-9 .31-24.12-3.16-30.37-27.76-3.25-12.81-11.89-46.83-2.31-59.15C37.05 55.66 41.2 54 47.07 54c12.88 0 31.72 10.28 48 26.18C111.69 96.39 122 114.59 122 127.67V180a30 30 0 0 1-30 30Zm127.31-91.77C213.07 142.83 198 146.29 189 146a41.62 41.62 0 0 0-20.28-8 6 6 0 1 0-1.36 12A30.11 30.11 0 1 1 134 180v-52.33c0-13.08 10.32-31.28 26.93-47.49C177.23 64.28 196.07 54 209 54c5.87 0 10 1.66 12.68 5.08 9.53 12.32.89 46.34-2.36 59.15Z" /></Svg>;
export default Butterfly;