/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartPieSlice = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-pie-slice-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-pie-slice-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M102 109.5v-72a6 6 0 0 0-8-5.66 102 102 0 0 0-66.3 114.75 6 6 0 0 0 8.9 4.11l62.4-36a6 6 0 0 0 3-5.2ZM90 106l-51.66 29.8Q38 131.91 38 128a90.1 90.1 0 0 1 52-81.58Zm38-80a6 6 0 0 0-6 6v93l-79.8 46.46a6 6 0 0 0-2.15 8.22A102 102 0 1 0 128 26Zm0 192a90.48 90.48 0 0 1-74.38-39.31L131 133.61a6 6 0 0 0 3-5.19V38.2a90 90 0 0 1-6 179.8Z" /></Svg>;
export default ChartPieSlice;