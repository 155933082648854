/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VideoCameraSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="video-camera-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-video-camera-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M256 80.23v95.45a8.33 8.33 0 0 1-3.86 7.17 8 8 0 0 1-8.58-.19l-33.78-22.52a4 4 0 0 1-1.78-3.33V99.19a4 4 0 0 1 1.78-3.32l33.78-22.53a8 8 0 0 1 9.73.66 8.23 8.23 0 0 1 2.71 6.23ZM53.92 34.62a8 8 0 1 0-11.84 10.76L51.73 56H32a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h150.64l19.44 21.38a8 8 0 1 0 11.84-10.76ZM185 155.07a4 4 0 0 0 7-2.7V72a16 16 0 0 0-16-16h-72a4 4 0 0 0-3 6.69Z" /></Svg>;
export default VideoCameraSlash;