/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-six-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-six-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 108a51.66 51.66 0 0 0-24.74 6.27L139.49 50a4 4 0 0 0-7-3.92L83 134.05A52 52 0 1 0 128 108Zm0 96a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Z" /></Svg>;
export default NumberSix;