/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-three-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-three-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 160a52 52 0 0 1-86.67 38.76 4 4 0 1 1 5.34-6A44 44 0 1 0 120 116a4 4 0 0 1-3.2-6.4L160 52H88a4 4 0 0 1 0-8h80a4 4 0 0 1 3.2 6.4l-43.61 58.15A52.08 52.08 0 0 1 172 160Z" /></Svg>;
export default NumberThree;