/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-x-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-x-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M146.34 98.34 164.69 80l-18.35-18.34a8 8 0 0 1 11.32-11.32L176 68.69l18.34-18.35a8 8 0 0 1 11.32 11.32L187.32 80l18.34 18.34a8 8 0 0 1-11.32 11.32L176 91.31l-18.34 18.35a8 8 0 0 1-11.32-11.32Zm68 68.12-47.11-21.11-.13-.06a16 16 0 0 0-15.17 1.4 8.12 8.12 0 0 0-.75.56L126.87 168c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16 16 0 0 0 1.32-15.06v-.12L89.54 41.64a16 16 0 0 0-16.62-9.52A56.26 56.26 0 0 0 24 88c0 79.4 64.6 144 144 144a56.26 56.26 0 0 0 55.88-48.92 16 16 0 0 0-9.51-16.62Z" /></Svg>;
export default PhoneX;