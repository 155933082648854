/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugsConnected = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plugs-connected-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plugs-connected-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.83 21.17a4 4 0 0 0-5.66 0L173.94 76.4l-8.2-8.2a28 28 0 0 0-39.6 0L100 94.34l-9.17-9.17a4 4 0 0 0-5.66 5.66l9.17 9.17-26.14 26.14a28 28 0 0 0 0 39.6l8.2 8.2-55.23 55.23a4 4 0 0 0 5.66 5.66l55.23-55.23 8.2 8.2a28 28 0 0 0 39.6 0L156 161.66l9.17 9.17a4 4 0 0 0 5.66-5.66l-9.17-9.17 26.14-26.14a28 28 0 0 0 0-39.6l-8.2-8.2 55.23-55.23a4 4 0 0 0 0-5.66Zm-110.63 161a20 20 0 0 1-28.28 0l-22.06-22.09a20 20 0 0 1 0-28.28L100 105.66 150.34 156Zm57.94-57.94L156 150.34 105.66 100l26.14-26.14a20 20 0 0 1 28.28 0l22.06 22.06a20 20 0 0 1 0 28.28ZM92.29 33.49a4 4 0 1 1 7.42-3l8 20a4 4 0 0 1-2.22 5.2A3.91 3.91 0 0 1 104 56a4 4 0 0 1-3.71-2.51Zm-64 61a4 4 0 0 1 5.2-2.22l20 8A4 4 0 0 1 52 108a3.91 3.91 0 0 1-1.49-.29l-20-8a4 4 0 0 1-2.22-5.2Zm199.42 67A4 4 0 0 1 224 164a3.91 3.91 0 0 1-1.49-.29l-20-8a4 4 0 1 1 3-7.42l20 8a4 4 0 0 1 2.2 5.2Zm-64 61a4 4 0 0 1-2.22 5.2 3.91 3.91 0 0 1-1.49.31 4 4 0 0 1-3.71-2.51l-8-20a4 4 0 0 1 7.42-3Z" /></Svg>;
export default PlugsConnected;