/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MedalMilitary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="medal-military-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-medal-military-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M207 40H49a17 17 0 0 0-17 17v49.21a17 17 0 0 0 10 15.47l62.6 28.45a48 48 0 1 0 46.88 0L214 121.68a17 17 0 0 0 10-15.47V57a17 17 0 0 0-17-17ZM96 56h64v72.67l-32 14.54-32-14.54Zm32 168a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" /></Svg>;
export default MedalMilitary;