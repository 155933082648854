/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tilde = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tilde-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tilde-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.12 128.09c-13.82 17.18-27.26 26.24-41.11 27.7a38.9 38.9 0 0 1-4 .21c-18 0-33.48-12.64-48.53-24.9-15.57-12.68-30.29-24.66-46.64-22.93-11.62 1.22-23.3 9.32-35.71 24.76a4 4 0 0 1-6.24-5c13.81-17.2 27.25-26.26 41.11-27.72 19.67-2.08 36.38 11.53 52.54 24.69 15.57 12.68 30.29 24.66 46.64 22.93 11.62-1.22 23.3-9.32 35.71-24.76a4 4 0 1 1 6.24 5Z" /></Svg>;
export default Tilde;