/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Voicemail = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="voicemail-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-voicemail-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 74a54 54 0 0 0-33.89 96H89.89A54 54 0 1 0 56 182h144a54 54 0 0 0 0-108ZM14 128a42 42 0 1 1 42 42 42 42 0 0 1-42-42Zm186 42a42 42 0 1 1 42-42 42 42 0 0 1-42 42Z" /></Svg>;
export default Voicemail;