/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FastForwardCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fast-forward-circle__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fast-forward-circle'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm68.8-94.4-48-36A8 8 0 0 0 136 92v72a8 8 0 0 0 12.8 6.4l48-36a8 8 0 0 0 0-12.8ZM152 148v-40l26.67 20Zm-19.2-26.4-48-36A8 8 0 0 0 72 92v72a8 8 0 0 0 12.8 6.4l48-36a8 8 0 0 0 0-12.8ZM88 148v-40l26.67 20Z" /></Svg>;
export default FastForwardCircle;