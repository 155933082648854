/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNoteSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-note-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-note-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 184a40 40 0 1 1-40-40 40 40 0 0 1 40 40Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m210.3 56.34-80-24A8 8 0 0 0 120 40v108.26A48 48 0 1 0 136 184V50.75l69.7 20.91a8 8 0 1 0 4.6-15.32ZM88 216a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" /></Svg>;
export default MusicNoteSimple;