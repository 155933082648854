/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vibrate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vibrate-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vibrate-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 28H92a28 28 0 0 0-28 28v144a28 28 0 0 0 28 28h72a28 28 0 0 0 28-28V56a28 28 0 0 0-28-28Zm4 172a4 4 0 0 1-4 4H92a4 4 0 0 1-4-4V56a4 4 0 0 1 4-4h72a4 4 0 0 1 4 4Zm64-100v56a12 12 0 0 1-24 0v-56a12 12 0 0 1 24 0Zm-184 0v56a12 12 0 0 1-24 0v-56a12 12 0 0 1 24 0Z" /></Svg>;
export default Vibrate;