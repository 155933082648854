/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bomb = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bomb-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bomb-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 36a4 4 0 0 0-4 4c0 1.47-.42 36-28 36-13.94 0-23.08-12.79-32.75-26.33C172.83 35.09 162.06 20 144 20c-14.86 0-25.82 7.79-31.71 22.51A63.73 63.73 0 0 0 108.22 60H88a12 12 0 0 0-12 12v12.08A84 84 0 0 0 112 244h1.51A84 84 0 0 0 148 84.08V72a12 12 0 0 0-12-12h-19.76c.92-9.42 5.45-32 27.76-32 13.94 0 23.08 12.79 32.75 26.33C187.17 68.91 197.94 84 216 84c14.86 0 25.82-7.79 31.71-22.51A64.27 64.27 0 0 0 252 40a4 4 0 0 0-4-4ZM140 72v14.66a4 4 0 0 0 2.4 3.66 76 76 0 1 1-60.8 0 4 4 0 0 0 2.4-3.66V72a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4Zm-35.34 132.05A4 4 0 0 1 104 212a5 5 0 0 1-.66-.05 53.46 53.46 0 0 1-43.29-43.29 4 4 0 0 1 7.9-1.32 45.31 45.31 0 0 0 36.71 36.71Z" /></Svg>;
export default Bomb;