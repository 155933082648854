/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiHigh = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-high-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-high-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m244.35 92.8-104 125.43A15.93 15.93 0 0 1 128 224a15.93 15.93 0 0 1-12.31-5.77L11.65 92.8a15.65 15.65 0 0 1-3.54-11.89 15.93 15.93 0 0 1 6.17-10.81A186.67 186.67 0 0 1 128 32a186.67 186.67 0 0 1 113.72 38.1 15.93 15.93 0 0 1 6.17 10.81 15.65 15.65 0 0 1-3.54 11.89Z" /></Svg>;
export default WifiHigh;