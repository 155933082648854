/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MegaphoneSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="megaphone-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-megaphone-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m228.54 86.66-176.06-54A16 16 0 0 0 32 48v144a16 16 0 0 0 16 16 16 16 0 0 0 4.52-.65L136 181.73V192a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-29.9l28.54-8.75A16.09 16.09 0 0 0 240 138v-36a16.09 16.09 0 0 0-11.46-15.34ZM184 192h-32v-15.18l32-9.82Zm40-54h-.11L152 160.08V79.91L223.89 102h.11v36Z" /></Svg>;
export default MegaphoneSimple;