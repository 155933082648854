/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pill = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pill-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pill-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M186.84 85.17a4 4 0 0 1 0 5.66l-24 24a4 4 0 1 1-5.66-5.66l24-24a4 4 0 0 1 5.66 0Zm26.75 26.91L112.08 213.57a49.26 49.26 0 0 1-69.67-69.65L143.92 42.43a49.26 49.26 0 0 1 69.67 69.65ZM154.35 160 96 101.66l-47.94 47.91a41.26 41.26 0 0 0 58.36 58.35Zm53.59-111.92a41.28 41.28 0 0 0-58.36 0L101.65 96 160 154.34l47.93-47.91a41.32 41.32 0 0 0 .01-58.35Z" /></Svg>;
export default Pill;