/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderNonbinary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-nonbinary-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-nonbinary-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M134 98.27V58.6l30.91 18.54a6 6 0 1 0 6.18-10.28L139.66 48l31.43-18.85a6 6 0 1 0-6.18-10.29L128 41 91.09 18.86a6 6 0 1 0-6.18 10.29L116.34 48 84.91 66.86a6 6 0 1 0 6.18 10.28L122 58.6v39.67a70 70 0 1 0 12 0ZM128 226a58 58 0 1 1 58-58 58.07 58.07 0 0 1-58 58Z" /></Svg>;
export default GenderNonbinary;