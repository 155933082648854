/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bread = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bread-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bread-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 80a44.05 44.05 0 0 0-44-44H48a44 44 0 0 0-20 83.17V200a20 20 0 0 0 20 20h152a20 20 0 0 0 20-20v-80.83A44 44 0 0 0 244 80ZM52 123.3a12 12 0 0 0-4-23.3 20 20 0 0 1 0-40h92a20 20 0 0 1 0 40 12 12 0 0 0-4 23.3V196H52ZM200 100a12 12 0 0 0-4 23.3V196h-36v-76.83A43.92 43.92 0 0 0 179.17 60H200a20 20 0 0 1 0 40Z" /></Svg>;
export default Bread;