/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-x-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-x-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 44a84.11 84.11 0 0 0-76.41 49.12A60.71 60.71 0 0 0 72 92a60 60 0 0 0 0 120h88a84 84 0 0 0 0-168Zm0 160H72a52 52 0 1 1 8.55-103.3A83.66 83.66 0 0 0 76 128a4 4 0 0 0 8 0 76 76 0 1 1 76 76Zm26.83-89.17L165.66 136l21.17 21.17a4 4 0 0 1-5.66 5.66L160 141.66l-21.17 21.17a4 4 0 0 1-5.66-5.66L154.34 136l-21.17-21.17a4 4 0 0 1 5.66-5.66L160 130.34l21.17-21.17a4 4 0 1 1 5.66 5.66Z" /></Svg>;
export default CloudX;