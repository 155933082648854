/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hurricane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hurricane-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hurricane-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 98a30 30 0 1 0 30 30 30 30 0 0 0-30-30Zm0 48a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm27.91-99.37 9.89-37.09a6 6 0 0 0-7.06-7.41 193.94 193.94 0 0 0-58 24.82C62.32 51.66 42 86.6 42 128a86.1 86.1 0 0 0 58.09 81.37l-9.89 37.08a6 6 0 0 0 7.06 7.42 193.94 193.94 0 0 0 58-24.82C193.68 204.34 214 169.4 214 128a86.09 86.09 0 0 0-58.09-81.37Zm-6.86 172.13a187.63 187.63 0 0 1-44.55 20.67l8.68-32.57a6 6 0 0 0-4.26-7.34A74.06 74.06 0 0 1 54 128c0-37.53 17.82-68.07 53-90.77a187.93 187.93 0 0 1 44.5-20.66l-8.68 32.57a6 6 0 0 0 4.26 7.34A74.06 74.06 0 0 1 202 128c0 37.53-17.82 68.07-52.95 90.76Z" /></Svg>;
export default Hurricane;