/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyRub = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-rub-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-rub-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148 36H88a4 4 0 0 0-4 4v100H56a4 4 0 0 0 0 8h28v24H56a4 4 0 0 0 0 8h28v36a4 4 0 0 0 8 0v-36h52a4 4 0 0 0 0-8H92v-24h56a56 56 0 0 0 0-112Zm0 104H92V44h56a48 48 0 0 1 0 96Z" /></Svg>;
export default CurrencyRub;