/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tractor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tractor__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tractor'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 165.41V134a15.89 15.89 0 0 0-11.4-15.32l-.21-.06-36.39-9.91V72a8 8 0 0 0-16 0v32.38l-24-6.5V56h8a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16h8v32h-8a8 8 0 0 0 0 16h28a68.07 68.07 0 0 1 68 68v12a8 8 0 0 0 8 8h32.23A36 36 0 1 0 240 165.41ZM68 88h-4V56h72v66.77A83.92 83.92 0 0 0 68 88Zm84 26.45L224 134v20.1a36 36 0 0 0-45.94 21.9H152ZM212 208a20 20 0 1 1 20-20 20 20 0 0 1-20 20ZM68 120a52 52 0 1 0 52 52 52.06 52.06 0 0 0-52-52Zm0 88a36 36 0 1 1 36-36 36 36 0 0 1-36 36Zm12-36a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default Tractor;