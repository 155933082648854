/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DoorOpen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="door-open-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-door-open-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 40v184h-32V32h24a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 216h-24V40a16 16 0 0 0-16-16H64a16 16 0 0 0-16 16v176H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16Zm-40 0h-16V40h16ZM64 40h96v176H64Zm80 92a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default DoorOpen;