/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowUDownRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-u-down-right-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-u-down-right-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m224 168-48 48v-96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m229.66 162.34-48-48A8 8 0 0 0 168 120v40H88a48 48 0 0 1 0-96h88a8 8 0 0 0 0-16H88a64 64 0 0 0 0 128h80v40a8 8 0 0 0 13.66 5.66l48-48a8 8 0 0 0 0-11.32ZM184 196.69v-57.38L212.69 168Z" /></Svg>;
export default ArrowUDownRight;