/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bread = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bread-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bread-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238 80a38 38 0 0 0-38-38H48a38 38 0 0 0-14 73.32V200a14 14 0 0 0 14 14h152a14 14 0 0 0 14-14v-84.68A38.07 38.07 0 0 0 238 80ZM46 200v-82h2a6 6 0 0 0 0-12 26 26 0 0 1 0-52h96a26 26 0 0 1 0 52 6 6 0 0 0 0 12h2v82a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2Zm154-94a6 6 0 0 0 0 12h2v82a2 2 0 0 1-2 2h-42.16a14.71 14.71 0 0 0 .16-2v-84.68A38 38 0 0 0 171.68 54H200a26 26 0 0 1 0 52Z" /></Svg>;
export default Bread;