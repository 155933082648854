/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 0 0-6 5.38l36 39.5L78.63 84H32a12 12 0 0 0-12 12v64a12 12 0 0 0 12 12h46.63l70.91 55.16a4.07 4.07 0 0 0 2.46.84 3.92 3.92 0 0 0 1.76-.41A4 4 0 0 0 156 224v-59.25l49 53.94a4 4 0 1 0 5.92-5.38ZM28 160V96a4 4 0 0 1 4-4h44v72H32a4 4 0 0 1-4-4Zm120 55.82L84 166V90l2.35-1.83L148 156Zm41-69.3a28 28 0 0 0 0-37 4 4 0 1 1 6-5.29 36 36 0 0 1 0 47.59 4 4 0 1 1-6-5.29Zm-80-81.07a4 4 0 0 1 .7-5.61l39.85-31A4 4 0 0 1 156 32v74.83a4 4 0 0 1-8 0V40.18l-33.39 26a4 4 0 0 1-5.61-.73ZM244 128a75.88 75.88 0 0 1-19.35 50.67 4 4 0 0 1-6-5.34 68 68 0 0 0 0-90.66 4 4 0 0 1 6-5.34A75.88 75.88 0 0 1 244 128Z" /></Svg>;
export default SpeakerSlash;