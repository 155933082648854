/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CreditCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="credit-card-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-credit-card-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 50H32a14 14 0 0 0-14 14v128a14 14 0 0 0 14 14h192a14 14 0 0 0 14-14V64a14 14 0 0 0-14-14ZM32 62h192a2 2 0 0 1 2 2v26H30V64a2 2 0 0 1 2-2Zm192 132H32a2 2 0 0 1-2-2v-90h196v90a2 2 0 0 1-2 2Zm-18-26a6 6 0 0 1-6 6h-32a6 6 0 0 1 0-12h32a6 6 0 0 1 6 6Zm-64 0a6 6 0 0 1-6 6h-16a6 6 0 0 1 0-12h16a6 6 0 0 1 6 6Z" /></Svg>;
export default CreditCard;