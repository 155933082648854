/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-vertical-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-vertical-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168.49 191.51a12 12 0 0 1 0 17l-32 32a12 12 0 0 1-17 0l-32-32a12 12 0 0 1 17-17L116 203V53l-11.51 11.49a12 12 0 1 1-17-17l32-32a12 12 0 0 1 17 0l32 32a12 12 0 0 1-17 17L140 53v150l11.51-11.52a12 12 0 0 1 16.98.03Z" /></Svg>;
export default ArrowsVertical;