/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FootballHelmet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="football-helmet-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-football-helmet-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 160h-50.2l-7.09-24H216a8 8 0 0 0 8-8v-4A100 100 0 0 0 122.58 24C68.24 24.77 24 69.61 24 124a100 100 0 0 0 43.62 82.6 8 8 0 0 0 4.52 1.4H120a16 16 0 0 0 15.62-19.47 5.44 5.44 0 0 0-.15-.54l-3.56-12h21.93l10.79 36.53A16.1 16.1 0 0 0 180 224h36a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16ZM84 176a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm43.16-16L120 136h22l7.09 24ZM216 208h-36l-9.46-32H216Z" /></Svg>;
export default FootballHelmet;