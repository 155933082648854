/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Gradient = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gradient-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gradient-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28 104a4 4 0 0 1 4-4h80a4 4 0 0 1 0 8H32a4 4 0 0 1-4-4Zm196-4h-80a4 4 0 0 0 0 8h80a4 4 0 0 0 0-8ZM72 140H32a4 4 0 0 0 0 8h40a4 4 0 0 0 0-8Zm152 0h-40a4 4 0 0 0 0 8h40a4 4 0 0 0 0-8Zm-124 4a4 4 0 0 0 4 4h48a4 4 0 0 0 0-8h-48a4 4 0 0 0-4 4Zm-44 36H32a4 4 0 0 0 0 8h24a4 4 0 0 0 0-8Zm56 0H88a4 4 0 0 0 0 8h24a4 4 0 0 0 0-8Zm56 0h-24a4 4 0 0 0 0 8h24a4 4 0 0 0 0-8Zm56 0h-24a4 4 0 0 0 0 8h24a4 4 0 0 0 0-8ZM32 68h192a4 4 0 0 0 0-8H32a4 4 0 0 0 0 8Z" /></Svg>;
export default Gradient;