/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Oven = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="oven__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-oven'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm0 176H48V48h160v160ZM72 76a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm44 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm44 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm24 28H72a8 8 0 0 0-8 8v72a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8v-72a8 8 0 0 0-8-8Zm-8 72H80v-56h96Z" /></Svg>;
export default Oven;