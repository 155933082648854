/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CashRegister = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cash-register-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cash-register-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m239.76 158.06-22.48-89.94A16 16 0 0 0 201.75 56H136V40a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v16h-9.75a16 16 0 0 0-15.53 12.12l-22.48 89.94A7.93 7.93 0 0 0 16 160v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a7.93 7.93 0 0 0-.24-1.94ZM168 88h16a8 8 0 0 1 0 16h-16a8 8 0 0 1 0-16Zm0 32h16a8 8 0 0 1 0 16h-16a8 8 0 0 1 0-16Zm-32-32a8 8 0 0 1 0 16h-16a8 8 0 0 1 0-16Zm8 40a8 8 0 0 1-8 8h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 8 8ZM80 40h40v16H80Zm-8 48h16a8 8 0 0 1 0 16H72a8 8 0 0 1 0-16Zm0 32h16a8 8 0 0 1 0 16H72a8 8 0 0 1 0-16Zm152 72H32v-24h192Z" /></Svg>;
export default CashRegister;