/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartScatter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-scatter-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-scatter-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 208a6 6 0 0 1-6 6H32a6 6 0 0 1-6-6V48a6 6 0 0 1 12 0v154h186a6 6 0 0 1 6 6Zm-98-50a10 10 0 1 0-10-10 10 10 0 0 0 10 10Zm-24-56a10 10 0 1 0-10-10 10 10 0 0 0 10 10Zm-32 72a10 10 0 1 0-10-10 10 10 0 0 0 10 10Zm96-48a10 10 0 1 0-10-10 10 10 0 0 0 10 10Zm24-40a10 10 0 1 0-10-10 10 10 0 0 0 10 10Zm-8 68a10 10 0 1 0 10 10 10 10 0 0 0-10-10Z" /></Svg>;
export default ChartScatter;