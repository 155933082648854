/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Option = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="option-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-option-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 192a4 4 0 0 1-4 4h-63.06a11.94 11.94 0 0 1-10.73-6.63L98.63 86.21A4 4 0 0 0 95.06 84H32a4 4 0 0 1 0-8h63.06a11.94 11.94 0 0 1 10.73 6.63l51.58 103.16a4 4 0 0 0 3.57 2.21H224a4 4 0 0 1 4 4ZM152 84h72a4 4 0 0 0 0-8h-72a4 4 0 0 0 0 8Z" /></Svg>;
export default Option;