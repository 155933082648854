/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Spade = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spade-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spade-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M179.84 51.39a284.14 284.14 0 0 0-49.16-32.76 6 6 0 0 0-5.36 0 284.14 284.14 0 0 0-49.16 32.76C42.88 79.13 26 107.59 26 136a54 54 0 0 0 75.24 49.65l-11 36.63A6 6 0 0 0 96 230h64a6 6 0 0 0 5.75-7.72l-11-36.63A54 54 0 0 0 230 136c0-28.41-16.88-56.87-50.16-84.61ZM176 178a42 42 0 0 1-27.6-10.34 6 6 0 0 0-9.69 6.24l13.23 44.1h-47.88l13.23-44.1a6 6 0 0 0-9.69-6.24A42 42 0 0 1 38 136c0-53.73 74.77-97 90-105.22C143.24 39 218 82.2 218 136a42 42 0 0 1-42 42Z" /></Svg>;
export default Spade;