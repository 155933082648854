/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hoodie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hoodie-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hoodie-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.31 120.53 183 39.12A16 16 0 0 0 169.73 32H86.27A16 16 0 0 0 73 39.12l-54.31 81.41a16 16 0 0 0-2.13 13.09L38 212.21A16 16 0 0 0 53.43 224H80a16 16 0 0 0 16-16v-16h64v16a16 16 0 0 0 16 16h26.57A16 16 0 0 0 218 212.21l21.44-78.59a16 16 0 0 0-2.13-13.09ZM80 208H53.43L32 129.41l32-48V176a16 16 0 0 0 16 16Zm40-72a8 8 0 0 1-16 0V97.14a8 8 0 1 1 16 0Zm32-8a8 8 0 0 1-16 0V97.14a8 8 0 1 1 16 0Zm-24-49.29L83.35 52.39 86.27 48h83.46l2.92 4.39ZM202.57 208H176v-16a16 16 0 0 0 16-16V81.41l32 48Z" /></Svg>;
export default Hoodie;