/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pentagon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pentagon-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pentagon-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m228 84.38-87.9-68.29-.26-.2a19.92 19.92 0 0 0-23.66 0l-.26.2L28 84.38a20 20 0 0 0-7.09 22l32 107.51.08.26A19.93 19.93 0 0 0 72 228h112a19.93 19.93 0 0 0 19-13.87l.08-.26 32-107.51A20 20 0 0 0 228 84.38ZM181 204H75L44.62 101.87 128 37.09l83.38 64.78Z" /></Svg>;
export default Pentagon;