/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rabbit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rabbit-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rabbit-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 160a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm32-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm68 44a56 56 0 0 1-92 42.89 56 56 0 0 1-75-83 75.47 75.47 0 0 1 5.59-18.74 241.48 241.48 0 0 1-19.8-58.23C35.55 54.21 33.54 31.35 42.21 17A25.89 25.89 0 0 1 65.06 4c22.63 0 40.6 27.11 53.48 80.6a74.88 74.88 0 0 1 18.92 0C150.34 31.11 168.31 4 190.94 4a25.89 25.89 0 0 1 22.85 13c8.67 14.34 6.66 37.2 3.45 53.86a241.48 241.48 0 0 1-19.8 58.23 75.6 75.6 0 0 1 5.56 18.75A55.76 55.76 0 0 1 220 188Zm-59.47-96.68a75.84 75.84 0 0 1 18.72 12.56c.78.71 1.53 1.43 2.28 2.17a211 211 0 0 0 12.14-39.73c3.9-20.23 2.34-32.33-.42-36.9-.82-1.35-1.25-1.42-2.31-1.42-6.17 0-19.43 17.4-30.41 63.32Zm-86.06 14.73c.74-.73 1.5-1.46 2.28-2.17a75.84 75.84 0 0 1 18.72-12.56C84.49 45.4 71.23 28 65.06 28c-1.08 0-1.49.07-2.31 1.42-6.21 10.28-2.02 43.42 11.72 76.63ZM196 188a31.9 31.9 0 0 0-11.86-24.87 12 12 0 0 1-4.38-8.17 52 52 0 0 0-103.52 0 12 12 0 0 1-4.38 8.17 32 32 0 0 0 38.47 51.08l-6-5a12 12 0 0 1 15.34-18.46l8.33 6.95 8.33-6.93a12 12 0 1 1 15.34 18.46l-6 5A32 32 0 0 0 196 188Z" /></Svg>;
export default Rabbit;