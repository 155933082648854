/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Code = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="code-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-code-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M67.84 92.61 25.37 128l42.47 35.39a6 6 0 1 1-7.68 9.22l-48-40a6 6 0 0 1 0-9.22l48-40a6 6 0 0 1 7.68 9.22Zm176 30.78-48-40a6 6 0 1 0-7.68 9.22L230.63 128l-42.47 35.39a6 6 0 1 0 7.68 9.22l48-40a6 6 0 0 0 0-9.22Zm-81.79-89a6 6 0 0 0-7.69 3.61l-64 176a6 6 0 0 0 3.64 7.64 6.15 6.15 0 0 0 2 .36 6 6 0 0 0 5.64-3.95l64-176a6 6 0 0 0-3.59-7.69Z" /></Svg>;
export default Code;