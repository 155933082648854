/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IceCream = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ice-cream-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ice-cream-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 116a32.14 32.14 0 0 0-16.37-27.92 84 84 0 0 0-167.26 0 32 32 0 0 0 12.51 59.77l53.75 94.07a20 20 0 0 0 34.74 0l53.75-94.07A32 32 0 0 0 228 116Zm-169.61-7.84A12 12 0 0 0 68 96.4V96a60 60 0 0 1 120 0v.4a12 12 0 0 0 9.61 11.76A8 8 0 0 1 196 124H60a8 8 0 0 1-1.61-15.84Zm113 39.84-16.5 28.88-16.5-28.88Zm-86.78 0h26.14l30.32 53.06L128 223.94Z" /></Svg>;
export default IceCream;