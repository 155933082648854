/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartBar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-bar-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-bar-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 202h-10V40a6 6 0 0 0-6-6h-56a6 6 0 0 0-6 6v42H96a6 6 0 0 0-6 6v42H48a6 6 0 0 0-6 6v66H32a6 6 0 0 0 0 12h192a6 6 0 0 0 0-12ZM158 46h44v156h-44Zm-56 48h44v108h-44Zm-48 48h36v60H54Z" /></Svg>;
export default ChartBar;