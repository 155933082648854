/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HouseLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="house-line-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-house-line-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 212h-20v-85.66l9.17 9.17a4 4 0 1 0 5.66-5.66l-98.34-98.34a12 12 0 0 0-17 0l-98.32 98.34a4 4 0 1 0 5.66 5.66l9.17-9.17V212H16a4 4 0 0 0 0 8h224a4 4 0 0 0 0-8ZM44 118.34l81.17-81.17a4 4 0 0 1 5.66 0L212 118.34V212h-56v-60a4 4 0 0 0-4-4h-48a4 4 0 0 0-4 4v60H44ZM148 212h-40v-56h40Z" /></Svg>;
export default HouseLine;