/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-check-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-check-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M139 158.25a66 66 0 1 0-62 0c-22 6.23-41.88 19.16-57.61 37.89a6 6 0 0 0 9.18 7.72C49.11 179.44 77.31 166 108 166s58.9 13.44 79.41 37.86a6 6 0 1 0 9.18-7.72C180.86 177.41 161 164.48 139 158.25ZM54 100a54 54 0 1 1 54 54 54.06 54.06 0 0 1-54-54Zm198.24 32.24-32 32a6 6 0 0 1-8.48 0l-16-16a6 6 0 0 1 8.48-8.48L216 151.51l27.76-27.75a6 6 0 1 1 8.48 8.48Z" /></Svg>;
export default UserCheck;