/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PottedPlant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="potted-plant-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-potted-plant-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m124.24 140.24 21.09-21.09a58 58 0 0 0 26.49 6.79 47.55 47.55 0 0 0 24.85-6.94c23.07-14 35.42-46.53 33-87.09a6 6 0 0 0-5.64-5.64c-40.56-2.38-73.12 10-87.09 33-9.22 15.22-9.2 33.71-.14 51.35L120 127.51l-14.64-14.63c6.32-13 6.15-26.6-.67-37.86C94.21 57.72 70 48.44 39.85 50.21a6 6 0 0 0-5.64 5.64C32.44 86 41.72 110.2 59 120.69A36.23 36.23 0 0 0 78 126a43.4 43.4 0 0 0 18.93-4.6l14.58 14.6-10 10H56a6 6 0 0 0 0 12h11.18l13.57 61a13.91 13.91 0 0 0 13.67 11h67.17a13.91 13.91 0 0 0 13.66-11l13.57-61H200a6 6 0 0 0 0-12h-81.52l5.76-5.76Zm23-74.7c11-18.22 37.24-28.33 70.72-27.5.83 33.47-9.28 59.68-27.5 70.72-12.3 7.44-27.09 6.92-41.79-1.43-8.36-14.7-8.88-29.49-1.43-41.79Zm-53.66 44c-10 5.56-20 5.87-28.34.84C52.78 102.87 45.75 85 46 62c23-.29 40.88 6.78 48.42 19.24 5.03 8.3 4.72 18.32-.84 28.34ZM176.52 158l-13 58.43a2 2 0 0 1-1.95 1.57H94.42a2 2 0 0 1-2-1.57L79.48 158Z" /></Svg>;
export default PottedPlant;