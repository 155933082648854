/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrophoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.38 229.92a8 8 0 0 1-11.3-.54l-30.92-34A78.83 78.83 0 0 1 136 207.59V240a8 8 0 0 1-16 0v-32.4A80.11 80.11 0 0 1 48 128a8 8 0 0 1 16 0 64.07 64.07 0 0 0 64 64 63.41 63.41 0 0 0 32.21-8.68l-11.1-12.2A48 48 0 0 1 80 128V95.09L42.08 53.38a8 8 0 0 1 11.84-10.76l160 176a8 8 0 0 1-.54 11.3Zm-24.19-63.13a7.88 7.88 0 0 0 3.51.82 8 8 0 0 0 7.19-4.49A79.16 79.16 0 0 0 208 128a8 8 0 0 0-16 0 63.32 63.32 0 0 1-6.48 28.09 8 8 0 0 0 3.67 10.7Zm-27.33-29.22a8 8 0 0 0 13.88-4.57 49.49 49.49 0 0 0 .26-5V64a48 48 0 0 0-92-19.13 8 8 0 0 0 1.41 8.57Z" /></Svg>;
export default MicrophoneSlash;