/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EyedropperSample = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyedropper-sample-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyedropper-sample-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 67.24a39.77 39.77 0 0 0-12.51-28.52c-15.58-14.72-40.78-14.22-56.2 1.21l-16.81 16.91a28 28 0 0 0-35.64 3.29l-9 9a20 20 0 0 0-.73 27.49L48.9 144.84A43.76 43.76 0 0 0 37 185.28l-7.5 17.19a17.66 17.66 0 0 0 3.71 19.65 19.9 19.9 0 0 0 22.15 4.19l16.31-7.13a43.89 43.89 0 0 0 39.45-12.09l48.24-48.26a20 20 0 0 0 27.47-.73l9-9a28.06 28.06 0 0 0 3.25-35.71l17.24-17.34A39.69 39.69 0 0 0 228 67.24Zm-85.65 74.66-6.06 6.1H79.73l34.34-34.36Zm-48.2 48.21a20 20 0 0 1-20 5 11.93 11.93 0 0 0-8.32.47l-8.83 3.8 3.69-8.38a12 12 0 0 0 .37-8.64 20 20 0 0 1-.65-10.36h51.88Zm105.14-111-25.37 25.52a12 12 0 0 0 0 17l4.88 4.88a4 4 0 0 1 0 5.66l-6.14 6.15-4.88-4.89-45.23-45.29-4.88-4.89 6.14-6.14a4 4 0 0 1 5.66 0l4.88 4.89a12 12 0 0 0 8.49 3.51 12 12 0 0 0 8.5-3.51l24.94-25.08c6.3-6.3 16.48-6.63 22.71-.74a16 16 0 0 1 .3 23Z" /></Svg>;
export default EyedropperSample;