/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ComputerTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="computer-tower-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-computer-tower-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 24H64a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm-64 168a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm32-80H96a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Zm0-32H96a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Z" /></Svg>;
export default ComputerTower;