/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudSnow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-snow-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-snow-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M84 196a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm32 8a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm48-16a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm-96 40a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm88 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm72-136a72.08 72.08 0 0 1-72 72H76a48 48 0 1 1 11.51-94.61A72.08 72.08 0 0 1 228 92Zm-8 0a64.06 64.06 0 0 0-128-3.77 4 4 0 0 1-8-.46 71.63 71.63 0 0 1 1.42-10.65A40 40 0 1 0 76 156h80a64.07 64.07 0 0 0 64-64Z" /></Svg>;
export default CloudSnow;