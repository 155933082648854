/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Checkerboard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="checkerboard-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-checkerboard-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12Zm-14.34 88L132 62.34V44h18.34L212 105.66V124ZM132 73.66 182.34 124H132ZM212 48v46.34L161.66 44H208a4 4 0 0 1 4 4ZM48 44h76v80H44V48a4 4 0 0 1 4-4Zm57.66 168L44 150.34V132h18.34L124 193.66V212ZM124 182.34 73.66 132H124ZM44 208v-46.34L94.34 212H48a4 4 0 0 1-4-4Zm164 4h-76v-80h80v76a4 4 0 0 1-4 4Z" /></Svg>;
export default Checkerboard;