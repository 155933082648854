/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SelectionPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="selection-plus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-selection-plus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148 40a4 4 0 0 1-4 4h-32a4 4 0 0 1 0-8h32a4 4 0 0 1 4 4Zm-4 172h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Zm68-164v24a4 4 0 0 0 8 0V48a12 12 0 0 0-12-12h-24a4 4 0 0 0 0 8h24a4 4 0 0 1 4 4Zm4 60a4 4 0 0 0-4 4v32a4 4 0 0 0 8 0v-32a4 4 0 0 0-4-4ZM40 148a4 4 0 0 0 4-4v-32a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4Zm32 64H48a4 4 0 0 1-4-4v-24a4 4 0 0 0-8 0v24a12 12 0 0 0 12 12h24a4 4 0 0 0 0-8Zm0-176H48a12 12 0 0 0-12 12v24a4 4 0 0 0 8 0V48a4 4 0 0 1 4-4h24a4 4 0 0 0 0-8Zm168 176h-20v-20a4 4 0 0 0-8 0v20h-20a4 4 0 0 0 0 8h20v20a4 4 0 0 0 8 0v-20h20a4 4 0 0 0 0-8Z" /></Svg>;
export default SelectionPlus;