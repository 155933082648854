/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-medium-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-medium-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM72 144a8 8 0 0 1-4.89 7.37A7.86 7.86 0 0 1 64 152H52a8 8 0 0 1 0-16h4V88h-4a8 8 0 0 1 0-16h12a8 8 0 0 1 6.91 4L92 112.12 113.09 76a8 8 0 0 1 6.91-4h12a8 8 0 0 1 0 16h-4v48h4a8 8 0 0 1 0 16h-12a7.86 7.86 0 0 1-3.11-.63A8 8 0 0 1 112 144v-34.41L98.91 132a8 8 0 0 1-13.82 0L72 109.59Zm128 40H88a8 8 0 0 1 0-16h112a8 8 0 0 1 0 16Zm0-32h-40a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm0-32h-40a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Z" /></Svg>;
export default ArticleMedium;