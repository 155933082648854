/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandDeposit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-deposit-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-deposit-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 198.65V240a8 8 0 0 1-16 0v-41.35A74.84 74.84 0 0 0 192 144v58.35a8 8 0 0 1-14.69 4.38l-10.68-16.31c-.08-.12-.16-.25-.23-.38a12 12 0 0 0-20.89 11.83l22.13 33.79a8 8 0 0 1-13.39 8.76l-22.26-34-.24-.38c-.38-.66-.73-1.33-1.05-2H56a8 8 0 0 1-8-8V96a16 16 0 0 1 16-16h48v48a8 8 0 0 0 16 0V80h48a16 16 0 0 1 16 16v27.62a90.89 90.89 0 0 1 40 75.03ZM128 35.31l18.34 18.35a8 8 0 0 0 11.32-11.32l-32-32a8 8 0 0 0-11.32 0l-32 32a8 8 0 0 0 11.32 11.32L112 35.31V80h16Z" /></Svg>;
export default HandDeposit;