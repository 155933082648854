/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WindowsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="windows-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-windows-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 140h-80a4 4 0 0 0-4 4v57.45a4 4 0 0 0 3.28 3.94l80 14.55a4.37 4.37 0 0 0 .72.06 4 4 0 0 0 2.56-.93A4 4 0 0 0 212 216v-72a4 4 0 0 0-4-4Zm-4 71.21-72-13.09V148h72ZM96 140H32a4 4 0 0 0-4 4v40a4 4 0 0 0 3.28 3.94l64 11.63a3.51 3.51 0 0 0 .72.07 4 4 0 0 0 4-4V144a4 4 0 0 0-4-4Zm-4 50.84-56-10.18V148h56ZM210.56 36.93a4 4 0 0 0-3.28-.87l-80 14.55a4 4 0 0 0-3.28 3.94V112a4 4 0 0 0 4 4h80a4 4 0 0 0 4-4V40a4 4 0 0 0-1.44-3.07ZM204 108h-72V57.88l72-13.09ZM95.28 56.43l-64 11.63A4 4 0 0 0 28 72v40a4 4 0 0 0 4 4h64a4 4 0 0 0 4-4V60.36a4 4 0 0 0-4.72-3.93ZM92 108H36V75.34l56-10.18Z" /></Svg>;
export default WindowsLogo;