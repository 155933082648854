/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Quotes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="quotes-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-quotes-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 58H40a14 14 0 0 0-14 14v64a14 14 0 0 0 14 14h62v10a34 34 0 0 1-34 34 6 6 0 0 0 0 12 46.06 46.06 0 0 0 46-46V72a14 14 0 0 0-14-14Zm2 80H40a2 2 0 0 1-2-2V72a2 2 0 0 1 2-2h60a2 2 0 0 1 2 2Zm114-80h-60a14 14 0 0 0-14 14v64a14 14 0 0 0 14 14h62v10a34 34 0 0 1-34 34 6 6 0 0 0 0 12 46.06 46.06 0 0 0 46-46V72a14 14 0 0 0-14-14Zm2 80h-62a2 2 0 0 1-2-2V72a2 2 0 0 1 2-2h60a2 2 0 0 1 2 2Z" /></Svg>;
export default Quotes;