/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Plug = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plug__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plug'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.66 66.34a8 8 0 0 0-11.32 0L192 100.69 155.31 64l34.35-34.34a8 8 0 1 0-11.32-11.32L144 52.69l-26.34-26.35a8 8 0 0 0-11.32 11.32l6.35 6.34-53 53a40 40 0 0 0 0 56.57l15.71 15.71-49.06 49.06a8 8 0 0 0 11.32 11.32l49.09-49.09 15.71 15.71a40 40 0 0 0 56.57 0l53-53 6.34 6.35a8 8 0 0 0 11.32-11.32L203.31 112l34.35-34.34a8 8 0 0 0 0-11.32ZM147.72 185a24 24 0 0 1-33.95 0L71 142.23a24 24 0 0 1 0-33.95l53-53L200.69 132Z" /></Svg>;
export default Plug;