/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PintGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pint-glass-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pint-glass-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M203 29.35a4 4 0 0 0-3-1.35H56a4 4 0 0 0-4 4.48l23.15 193A12 12 0 0 0 87.1 236h81.8a12 12 0 0 0 11.92-10.57L204 32.48a4 4 0 0 0-1-3.13ZM195.49 36l-3.84 32H64.35l-3.84-32Zm-22.62 188.48a4 4 0 0 1-4 3.52H87.1a4 4 0 0 1-4-3.52L65.31 76h125.38Z" /></Svg>;
export default PintGlass;