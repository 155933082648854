/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boat-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boat-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.79 114.38 204 109.12V56a12 12 0 0 0-12-12h-60V24a4 4 0 0 0-8 0v20H64a12 12 0 0 0-12 12v53.12l-15.8 5.26a12 12 0 0 0-8.2 11.39V152c0 24.46 17.32 46.77 50.09 64.52A233.22 233.22 0 0 0 127 235.88a4 4 0 0 0 1.94 0 233.22 233.22 0 0 0 48.94-19.36C210.68 198.77 228 176.46 228 152v-26.23a12 12 0 0 0-8.21-11.39ZM60 56a4 4 0 0 1 4-4h128a4 4 0 0 1 4 4v50.45l-66.74-22.24a3.89 3.89 0 0 0-2.52 0L60 106.45Zm160 96c0 49.32-82.08 73.16-92 75.86-9.92-2.7-92-26.54-92-75.86v-26.23a4 4 0 0 1 2.73-3.8L124 93.55V168a4 4 0 0 0 8 0V93.55L217.26 122a4 4 0 0 1 2.74 3.8Z" /></Svg>;
export default Boat;