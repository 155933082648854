/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CubeFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cube-focus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cube-focus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 48v40a8 8 0 0 1-16 0V56h-32a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8ZM72 200H40v-32a8 8 0 0 0-16 0v40a8 8 0 0 0 8 8h40a8 8 0 0 0 0-16Zm152-40a8 8 0 0 0-8 8v32h-32a8 8 0 0 0 0 16h40a8 8 0 0 0 8-8v-40a8 8 0 0 0-8-8ZM32 96a8 8 0 0 0 8-8V56h32a8 8 0 0 0 0-16H32a8 8 0 0 0-8 8v40a8 8 0 0 0 8 8Zm145.92-12.69L132 57.05a8 8 0 0 0-7.94 0L78.08 83.31a4 4 0 0 0 0 7L128 118.79l49.92-28.53a4 4 0 0 0 0-6.95ZM64 107.53V160a8 8 0 0 0 4 7l46 26.27a4 4 0 0 0 6-3.47v-57.16l-50-28.58a4 4 0 0 0-6 3.47ZM192 160v-52.47a4 4 0 0 0-6-3.47l-50 28.58v57.11a4 4 0 0 0 6 3.47L188 167a8 8 0 0 0 4-7Z" /></Svg>;
export default CubeFocus;