/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyKrw = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-krw-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-krw-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm72 120h-18.58l-14 35a8 8 0 0 1-14.86 0L128 117.54 103.43 179a8 8 0 0 1-14.86 0l-14-35H56a8 8 0 0 1 0-16h12.18L56.57 99a8 8 0 1 1 14.86-6L96 154.46 120.57 93a8 8 0 0 1 14.86 0L160 154.46 184.57 93a8 8 0 1 1 14.86 6l-11.61 29H200a8 8 0 0 1 0 16Z" /></Svg>;
export default CurrencyKrw;