/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TriangleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="triangle-dashed-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-triangle-dashed-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 216a4 4 0 0 1-4 4h-48a4 4 0 0 1 0-8h48a4 4 0 0 1 4 4Zm77.34-25.91-4.66-8.09a4 4 0 1 0-6.93 4l4.65 8.08a11.45 11.45 0 0 1 0 11.72 12.47 12.47 0 0 1-10.94 6.2H192a4 4 0 0 0 0 8h23.46a20.36 20.36 0 0 0 17.86-10.2 19.52 19.52 0 0 0 .02-19.71ZM64 212H40.54a12.47 12.47 0 0 1-10.94-6.2 11.45 11.45 0 0 1 0-11.72l4.65-8.08a4 4 0 1 0-6.93-4l-4.66 8.09a19.52 19.52 0 0 0 0 19.71A20.36 20.36 0 0 0 40.54 220H64a4 4 0 0 0 0-8Zm138.18-64a4 4 0 0 0 3.47-6l-23-40a4 4 0 1 0-6.93 4l23 40a4 4 0 0 0 3.46 2Zm-56.3-109.78a20.75 20.75 0 0 0-35.76 0L96.42 62a4 4 0 1 0 6.94 4l13.69-23.79a12.76 12.76 0 0 1 21.9 0L152.64 66a4 4 0 0 0 6.94-4Zm-67 62.31a4 4 0 0 0-5.49 1.47l-23 40a4 4 0 0 0 6.94 4l23-40a4 4 0 0 0-1.48-5.47Z" /></Svg>;
export default TriangleDashed;