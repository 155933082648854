/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImagesSquare = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="images-square__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-images-square'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H80a16 16 0 0 0-16 16v16H48a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-16h16a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM80 48h128v69.38l-16.7-16.7a16 16 0 0 0-22.62 0L93.37 176H80Zm96 160H48V80h16v96a16 16 0 0 0 16 16h96Zm32-32h-92l64-64 28 28v36Zm-88-64a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-32a8 8 0 1 1-8 8 8 8 0 0 1 8-8Z" /></Svg>;
export default ImagesSquare;