/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Visor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="visor-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-visor-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188 104a12 12 0 0 1-12 12H80a12 12 0 0 1 0-24h96a12 12 0 0 1 12 12Zm67.91 27.63A76.29 76.29 0 0 1 182.43 204h-1.69c-14.44 0-29.37-5.78-45.58-17.64a12.08 12.08 0 0 0-14.32 0C104 198.68 88.56 204.44 73.57 204A76.29 76.29 0 0 1 .09 131.63 76 76 0 0 1 76 52h104a76 76 0 0 1 75.91 79.63ZM217.62 92.1A51.56 51.56 0 0 0 180 76H76a52 52 0 0 0-51.94 54.51A52.19 52.19 0 0 0 74.32 180c9.42.29 20-4 32.35-13a36 36 0 0 1 42.66 0c12.36 9 22.91 13.29 32.35 13a52.19 52.19 0 0 0 50.26-49.48 51.63 51.63 0 0 0-14.32-38.42Z" /></Svg>;
export default Visor;