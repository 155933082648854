/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SkypeLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="skype-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-skype-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 152c0 20.19-19.33 36-44 36-21.26 0-39.46-12.35-43.27-29.38a12 12 0 0 1 23.42-5.24C109.3 158.49 117.21 164 128 164c9.44 0 20-5.13 20-12 0-6.37-6.29-9.32-24-14.42-16.14-4.65-38.23-11-38.23-33.58 0-20.52 18.15-36 42.22-36 17.24 0 32.06 8.14 38.69 21.24a12 12 0 1 1-21.41 10.84C142.8 95.17 136 92 128 92c-10.22 0-18.22 5.27-18.22 12 0 1.09 0 2.21 3.28 4.17 4.18 2.48 11 4.45 17.6 6.35 8.75 2.52 17.8 5.13 25.38 9.86C169.23 132.61 172 143.94 172 152Zm64 24a60.07 60.07 0 0 1-60 60 59.36 59.36 0 0 1-32-9.27 101.21 101.21 0 0 1-16 1.27A100.11 100.11 0 0 1 28 128a101.21 101.21 0 0 1 1.27-16A59.36 59.36 0 0 1 20 80a60.07 60.07 0 0 1 60-60 59.36 59.36 0 0 1 32.05 9.27A101.21 101.21 0 0 1 128 28a100.11 100.11 0 0 1 100 100 101.21 101.21 0 0 1-1.27 16 59.36 59.36 0 0 1 9.27 32Zm-24 0a35.63 35.63 0 0 0-7.49-22 12 12 0 0 1-2.23-9.87 76.07 76.07 0 0 0-90.43-90.43 12 12 0 0 1-9.85-2.21A35.63 35.63 0 0 0 80 44a36 36 0 0 0-36 36 35.63 35.63 0 0 0 7.49 22 12 12 0 0 1 2.23 9.87 76.07 76.07 0 0 0 90.43 90.43 12 12 0 0 1 9.87 2.23A35.63 35.63 0 0 0 176 212a36 36 0 0 0 36-36Z" /></Svg>;
export default SkypeLogo;