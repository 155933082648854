/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FigmaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="figma-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-figma-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184.46 96A36 36 0 0 0 168 28H96a36 36 0 0 0-16.46 68 36 36 0 0 0 1.56 64.76A40 40 0 1 0 140 196v-45.41A36 36 0 1 0 184.46 96ZM196 64a28 28 0 0 1-28 28h-28V36h28a28 28 0 0 1 28 28Zm-56 36h5.41a36.41 36.41 0 0 0-5.41 5.41ZM68 64a28 28 0 0 1 28-28h36v56H96a28 28 0 0 1-28-28Zm64 132a32 32 0 1 1-32-32h32Zm0-40H96a28 28 0 0 1 0-56h36Zm36 0a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default FigmaLogo;