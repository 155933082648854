/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-plus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-plus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M166 104a6 6 0 0 1-6 6h-26v26a6 6 0 0 1-12 0v-26H96a6 6 0 0 1 0-12h26V72a6 6 0 0 1 12 0v26h26a6 6 0 0 1 6 6Zm48 0c0 30.91-14.34 63.74-41.47 94.94a252.32 252.32 0 0 1-41.09 38 6 6 0 0 1-6.88 0 252.32 252.32 0 0 1-41.09-38C56.34 167.74 42 134.91 42 104a86 86 0 0 1 172 0Zm-12 0a74 74 0 0 0-148 0c0 59.62 59 108.93 74 120.51 15-11.58 74-60.89 74-120.51Z" /></Svg>;
export default MapPinPlus;