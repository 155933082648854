/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-star-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-star-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M36 64a4 4 0 0 1 4-4h176a4 4 0 0 1 0 8H40a4 4 0 0 1-4-4Zm4 68h56a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8Zm72 56H40a4 4 0 0 0 0 8h72a4 4 0 0 0 0-8Zm122.55-47.3-24.46 20.19 7.46 30.11a4 4 0 0 1-1.49 4.17 4.06 4.06 0 0 1-2.4.79 4 4 0 0 1-2-.55L184 179.18l-27.64 16.27a4 4 0 0 1-5.91-4.41l7.46-30.15-24.46-20.19a4 4 0 0 1 2.24-7.08l32.24-2.49 12.4-28.71a4 4 0 0 1 7.34 0l12.4 28.71 32.24 2.49a4 4 0 0 1 2.24 7.08Zm-12.74.14L197 138.92a4 4 0 0 1-3.36-2.4L184 114.1l-9.68 22.42a4 4 0 0 1-3.36 2.4l-24.77 1.92L165 156.33a4 4 0 0 1 1.33 4l-5.78 23.36 21.45-12.6a4 4 0 0 1 4.06 0l21.47 12.64-5.78-23.36a4 4 0 0 1 1.33-4Z" /></Svg>;
export default ListStar;