/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StackOverflowLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stack-overflow-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stack-overflow-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-73.14 14.86a8 8 0 0 1 11.32 0l45.25 45.26a8 8 0 0 1-11.31 11.31l-45.26-45.25a8 8 0 0 1 0-11.32Zm-34.68 51.91a8 8 0 0 1 10.45-4.33l59.13 24.49a8 8 0 0 1-3.06 15.4 7.89 7.89 0 0 1-3.06-.62l-59.13-24.49a8 8 0 0 1-4.33-10.45ZM96 152h64a8 8 0 0 1 0 16H96a8 8 0 0 1 0-16Zm104 40a8 8 0 0 1-8 8H64a8 8 0 0 1-8-8v-48a8 8 0 0 1 16 0v40h112v-40a8 8 0 0 1 16 0Z" /></Svg>;
export default StackOverflowLogo;