/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudSun = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-sun__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-sun'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 72a76.2 76.2 0 0 0-20.26 2.73 55.63 55.63 0 0 0-9.41-11.54l9.51-13.57a8 8 0 1 0-13.11-9.18L121.22 54A55.9 55.9 0 0 0 96 48h-1.74l-2.89-16.29a8 8 0 1 0-15.75 2.77l2.88 16.34a56.1 56.1 0 0 0-23.27 14.85l-13.62-9.53a8 8 0 1 0-9.17 13.11L46 78.77A55.55 55.55 0 0 0 40 104v1.72l-16.29 2.88a8 8 0 0 0 1.38 15.88 8.24 8.24 0 0 0 1.39-.12l16.32-2.88a55.74 55.74 0 0 0 5.86 12.42A52 52 0 0 0 84 224h80a76 76 0 0 0 0-152ZM56 104a40 40 0 0 1 72.54-23.24 76.26 76.26 0 0 0-35.62 40 52.14 52.14 0 0 0-31 4.17A40 40 0 0 1 56 104Zm108 104H84a36 36 0 1 1 4.78-71.69c-.37 2.37-.63 4.79-.77 7.23a8 8 0 0 0 16 .92 58.91 58.91 0 0 1 1.88-11.81c0-.16.09-.32.12-.48A60.06 60.06 0 1 1 164 208Z" /></Svg>;
export default CloudSun;