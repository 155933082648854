/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CirclesThreePlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circles-three-plus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circles-three-plus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 80a40 40 0 1 1-40-40 40 40 0 0 1 40 40Zm56 40a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm-96 16a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm128 32h-24v-24a8 8 0 0 0-16 0v24h-24a8 8 0 0 0 0 16h24v24a8 8 0 0 0 16 0v-24h24a8 8 0 0 0 0-16Z" /></Svg>;
export default CirclesThreePlus;