/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CraneTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crane-tower-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crane-tower-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 82H107.71L85.37 37.32A6 6 0 0 0 80 34H48a6 6 0 0 0-6 6v42H24a6 6 0 0 0 0 12h18v116H24a6 6 0 0 0 0 12h104a6 6 0 0 0 0-12h-18V94h100v90a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2v-8a6 6 0 0 0-12 0v8a14 14 0 0 0 14 14h16a14 14 0 0 0 14-14V94h18a6 6 0 0 0 0-12ZM54 46h22.29l18 36H54Zm0 164v-52h44v52Zm44-64H54V94h44Z" /></Svg>;
export default CraneTower;