/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyes-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyes-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 34c-21.15 0-39.1 20.15-48 50.26C119.1 54.15 101.15 34 80 34c-30.28 0-54 41.29-54 94s23.72 94 54 94c21.15 0 39.1-20.15 48-50.26 8.9 30.11 26.85 50.26 48 50.26 30.28 0 54-41.29 54-94s-23.72-94-54-94ZM80 210c-18.24 0-34.19-24.1-39.83-56.53a30 30 0 1 0 0-50.94C45.81 70.1 61.76 46 80 46c22.77 0 42 37.55 42 82s-19.23 82-42 82Zm-42-82a18 18 0 1 1 18 18 18 18 0 0 1-18-18Zm138 82c-18.24 0-34.19-24.1-39.83-56.53a30 30 0 1 0 0-50.94C141.81 70.1 157.76 46 176 46c22.77 0 42 37.55 42 82s-19.23 82-42 82Zm-42-82a18 18 0 1 1 18 18 18 18 0 0 1-18-18Z" /></Svg>;
export default Eyes;