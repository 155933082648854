/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WarningDiamond = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="warning-diamond__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-warning-diamond'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 72a8 8 0 0 1 8 8v56a8 8 0 0 1-16 0V80a8 8 0 0 1 8-8Zm-12 100a12 12 0 1 0 12-12 12 12 0 0 0-12 12Zm124-44a15.85 15.85 0 0 1-4.67 11.28l-96.05 96.06a16 16 0 0 1-22.56 0l-96-96.06a16 16 0 0 1 0-22.56l96.05-96.06a16 16 0 0 1 22.56 0l96.05 96.06A15.85 15.85 0 0 1 240 128Zm-16 0-96-96-96 96 96 96Z" /></Svg>;
export default WarningDiamond;