/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PingPong = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ping-pong-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ping-pong-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 196.36a12 12 0 0 0-5-8.77l-41.87-29.89 20.29-20.29a35.68 35.68 0 0 0 9.91-32.18 95.43 95.43 0 0 0-34-55.55A98.33 98.33 0 0 0 122.63 28 96.3 96.3 0 0 0 28 122.63a98.09 98.09 0 0 0 21.67 62.72 95.43 95.43 0 0 0 55.55 34 35.66 35.66 0 0 0 32.18-9.92l20.29-20.28 29.9 41.85a12 12 0 0 0 8.78 5h1a12 12 0 0 0 8.48-3.52l26.64-26.64a12 12 0 0 0 3.51-9.48Zm-180-16a90.11 90.11 0 0 1-20-57.62A88.27 88.27 0 0 1 122.75 36a90.13 90.13 0 0 1 57.59 19.9 89.13 89.13 0 0 1 21.28 24.81L80.72 201.62a89 89 0 0 1-24.81-21.29Zm170.92 19.85-26.65 26.65A4 4 0 0 1 197 228a3.92 3.92 0 0 1-2.93-1.67l-32.64-45.69a4 4 0 0 0-2.92-1.66h-.33a4 4 0 0 0-2.83 1.17l-23.63 23.63a27.66 27.66 0 0 1-25 7.72 84.36 84.36 0 0 1-18.6-6L205.47 88.18a84.36 84.36 0 0 1 6 18.6 27.69 27.69 0 0 1-7.72 25l-23.63 23.63a4 4 0 0 0 .5 6.08l45.69 32.64a4 4 0 0 1 .51 6.08Z" /></Svg>;
export default PingPong;