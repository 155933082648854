/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Jeep = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="jeep-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-jeep-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 103.47a8.17 8.17 0 0 0-8.27-7.47H232a8 8 0 0 0-.18-1.68l-10.64-49.67A16.08 16.08 0 0 0 205.53 32H50.47a16.08 16.08 0 0 0-15.65 12.65L24.18 94.32A8 8 0 0 0 24 96h-7.73A8.17 8.17 0 0 0 8 103.47a8 8 0 0 0 8 8.53h8v88a16 16 0 0 0 16 16h24a16 16 0 0 0 16-16v-16h20a4 4 0 0 0 4-4v-51.73a8.17 8.17 0 0 1 7.47-8.25 8 8 0 0 1 8.53 8v52a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-51.75a8.17 8.17 0 0 1 7.47-8.25 8 8 0 0 1 8.53 8v52a4 4 0 0 0 4 4h20v16a16 16 0 0 0 16 16h24a16 16 0 0 0 16-16V112h8a8 8 0 0 0 8-8.53ZM68 144a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm120 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12ZM40.18 96l10.29-48h155.06l10.29 48Z" /></Svg>;
export default Jeep;