/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-slash-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-slash-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m238.2 87.69-104 125.43a8 8 0 0 1-12.3 0L17.8 87.69a7.79 7.79 0 0 1 1.31-11.21 180.75 180.75 0 0 1 217.78 0 7.79 7.79 0 0 1 1.31 11.21Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M53.92 34.62a8 8 0 1 0-11.84 10.76l4.55 5A187.93 187.93 0 0 0 14.28 70.1a15.93 15.93 0 0 0-6.17 10.81 15.65 15.65 0 0 0 3.54 11.89l104 125.43A15.93 15.93 0 0 0 128 224a15.93 15.93 0 0 0 12.31-5.77l28.1-33.88 33.67 37a8 8 0 1 0 11.84-10.76ZM128 208 24.09 82.74a171.5 171.5 0 0 1 33.82-19.95l99.63 109.6ZM244.35 92.8l-49.42 59.58a8 8 0 0 1-12.32-10.21l49.3-59.43A170.76 170.76 0 0 0 128 48a175.15 175.15 0 0 0-32.39 3 8 8 0 1 1-3-15.72A190.62 190.62 0 0 1 128 32a186.67 186.67 0 0 1 113.72 38.1 15.93 15.93 0 0 1 6.17 10.81 15.65 15.65 0 0 1-3.54 11.89Z" /></Svg>;
export default WifiSlash;