/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShirtFolded = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shirt-folded-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shirt-folded-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 42h-21.52l-14.23-14.24A6 6 0 0 0 160 26H96a6 6 0 0 0-4.25 1.76L77.52 42H56a14 14 0 0 0-14 14v152a14 14 0 0 0 14 14h144a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14Zm-72 27.18L107.21 38h41.58Zm32.93-27.76 9.07 9.07V104a2 2 0 0 1-3.25 1.56l-30.82-26.64ZM86 50.49l9.07-9.07 25 37.5-30.82 26.62A2 2 0 0 1 86 104ZM54 208V56a2 2 0 0 1 2-2h18v50a13.87 13.87 0 0 0 8.06 12.68A14.11 14.11 0 0 0 88 118a13.87 13.87 0 0 0 9-3.26l.08-.07 25-21.56V210H56a2 2 0 0 1-2-2Zm148 0a2 2 0 0 1-2 2h-66V93.11l25 21.56.08.07A13.87 13.87 0 0 0 168 118a14.08 14.08 0 0 0 6-1.35 13.87 13.87 0 0 0 8-12.65V54h18a2 2 0 0 1 2 2Z" /></Svg>;
export default ShirtFolded;