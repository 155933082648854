/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListDashes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-dashes-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-dashes-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 64a4 4 0 0 1 4-4h120a4 4 0 0 1 0 8H96a4 4 0 0 1-4-4Zm124 60H96a4 4 0 0 0 0 8h120a4 4 0 0 0 0-8Zm0 64H96a4 4 0 0 0 0 8h120a4 4 0 0 0 0-8ZM56 60H40a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Zm0 64H40a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Zm0 64H40a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Z" /></Svg>;
export default ListDashes;