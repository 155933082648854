/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Usb = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="usb-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-usb-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 64a24 24 0 1 1-24-24 24 24 0 0 1 24 24Zm40 32v64l48-32Zm-48 72h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m252.44 121.34-48-32A8 8 0 0 0 192 96v24H72V72h33a32 32 0 1 0 0-16H72a16 16 0 0 0-16 16v48H8a8 8 0 0 0 0 16h48v48a16 16 0 0 0 16 16h32v8a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v8H72v-48h120v24a8 8 0 0 0 12.44 6.66l48-32a8 8 0 0 0 0-13.32ZM136 48a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm-16 128h32v32h-32Zm88-30.95V111l25.58 17Z" /></Svg>;
export default Usb;