/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandSwipeLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-swipe-left-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-swipe-left-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 140v36c0 24.66-8.08 41.1-8.42 41.79a4 4 0 1 1-7.16-3.58c.07-.15 7.58-15.55 7.58-38.21v-36a16 16 0 0 0-32 0v4a4 4 0 0 1-8 0v-20a16 16 0 0 0-32 0v12a4 4 0 0 1-8 0V68a16 16 0 0 0-32 0v108a4 4 0 0 1-7.39 2.11l-18.68-30a.75.75 0 0 1-.07-.12 16 16 0 0 0-27.72 16l29.31 50a4 4 0 0 1-6.9 4L31.22 168a24 24 0 0 1 41.52-24.09L84 162V68a24 24 0 0 1 48 0v38.13a24 24 0 0 1 39.94 16.06A24 24 0 0 1 212 140Zm36-88h-62.35l25.18-25.17a4 4 0 1 0-5.66-5.66l-32 32a4 4 0 0 0 0 5.66l32 32a4 4 0 1 0 5.66-5.66L185.65 60H248a4 4 0 0 0 0-8Z" /></Svg>;
export default HandSwipeLeft;