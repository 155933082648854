/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Gif = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gif-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gif-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM112 144a32 32 0 0 1-64 0v-32a32 32 0 0 1 55.85-21.33 8 8 0 1 1-11.92 10.66A16 16 0 0 0 64 112v32a16 16 0 0 0 32 0v-8h-8a8 8 0 0 1 0-16h16a8 8 0 0 1 8 8Zm32 24a8 8 0 0 1-16 0V88a8 8 0 0 1 16 0Zm60-72h-28v24h20a8 8 0 0 1 0 16h-20v32a8 8 0 0 1-16 0V88a8 8 0 0 1 8-8h36a8 8 0 0 1 0 16Z" /></Svg>;
export default Gif;