/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CoffeeBean = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coffee-bean__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coffee-bean'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.75 44.25C195 27.47 170.37 20.79 142.46 25.44c-26.91 4.49-53.87 19.09-75.9 41.12s-36.63 49-41.12 75.9c-4.65 27.91 2 52.51 18.81 69.29C57.54 225.05 75.75 232 96.62 232a103.66 103.66 0 0 0 16.92-1.44c26.91-4.49 53.87-19.09 75.9-41.12s36.63-49 41.12-75.9c4.65-27.91-2.03-52.54-18.81-69.29ZM77.87 77.87c24.69-24.68 55.13-37.94 81.28-37.94a62.26 62.26 0 0 1 29 6.67 108.48 108.48 0 0 0-31.05 16.94c-19.2 15.16-31.63 36.32-36.94 62.89-9.72 48.58-44.7 65.18-67.48 70.84-23.97-28.51-13.28-80.92 25.19-119.4Zm100.26 100.26c-34.69 34.68-80.71 46.78-110.32 31.27a108.72 108.72 0 0 0 31.09-16.94c19.2-15.16 31.63-36.32 36.94-62.89 9.72-48.58 44.7-65.18 67.48-70.84 23.97 28.51 13.28 80.92-25.19 119.4Z" /></Svg>;
export default CoffeeBean;