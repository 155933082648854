/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HighHeel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="high-heel-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-high-heel-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 144a95.28 95.28 0 0 1 37.53 7.67 4 4 0 0 1 2.47 3.7V192a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16v-44a4 4 0 0 1 4-4Zm199 12.19-51-11.48L69.66 34.34a8 8 0 0 0-11.56.26C36.11 58.64 24 89 24 120a8 8 0 0 0 8 8 111.2 111.2 0 0 1 63.34 19.7 112.45 112.45 0 0 1 40.55 50.39 15.9 15.9 0 0 0 14.83 9.91H240a16 16 0 0 0 16-16v-4.73a31.72 31.72 0 0 0-25-31.08Z" /></Svg>;
export default HighHeel;