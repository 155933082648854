/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleNotch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-notch-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-notch-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 176A72 72 0 0 1 92 65.64a8 8 0 0 1 8 13.85 56 56 0 1 0 56 0 8 8 0 0 1 8-13.85A72 72 0 0 1 128 200Z" /></Svg>;
export default CircleNotch;