/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Robot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="robot-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-robot-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M72 104a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm96 16a16 16 0 1 0-16-16 16 16 0 0 0 16 16Zm68-40v112a36 36 0 0 1-36 36H56a36 36 0 0 1-36-36V80a36 36 0 0 1 36-36h60V16a12 12 0 0 1 24 0v28h60a36 36 0 0 1 36 36Zm-24 0a12 12 0 0 0-12-12H56a12 12 0 0 0-12 12v112a12 12 0 0 0 12 12h144a12 12 0 0 0 12-12Zm-12 82a30 30 0 0 1-30 30H86a30 30 0 0 1 0-60h84a30 30 0 0 1 30 30Zm-80-6v12h16v-12Zm-34 12h10v-12H86a6 6 0 0 0 0 12Zm90-6a6 6 0 0 0-6-6h-10v12h10a6 6 0 0 0 6-6Z" /></Svg>;
export default Robot;