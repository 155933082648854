/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPinSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-slash__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-slash'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M53.92 34.62a8 8 0 1 0-11.84 10.76L67.37 73.2A69.82 69.82 0 0 0 38 87.63a16 16 0 0 0-1.29 23.78L85 159.71l-42.66 42.63a8 8 0 0 0 11.32 11.32L96.29 171l48.29 48.29A16 16 0 0 0 155.9 224h1.13a15.93 15.93 0 0 0 11.64-6.33 89.75 89.75 0 0 0 11.58-20.27l21.84 24a8 8 0 1 0 11.84-10.76ZM155.9 208 48 100.08c10.23-8.25 21.2-12.36 32.66-12.27l87.16 95.88c-2.23 9.87-7.58 18.54-11.92 24.31Zm79.42-104-44.64 44.79a8 8 0 1 1-11.33-11.3L224 92.7 163.32 32 122.1 73.35a8 8 0 0 1-11.33-11.29L152 20.7a16 16 0 0 1 22.63 0l60.69 60.68a16 16 0 0 1 0 22.62Z" /></Svg>;
export default PushPinSlash;