/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tractor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tractor-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tractor-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 164v-30a19.87 19.87 0 0 0-14.25-19.15l-.31-.09-33.44-9.11V76a12 12 0 0 0-24 0v23.15l-16-4.33V60h4a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24h4v24h-4a12 12 0 0 0 0 24h28a64.07 64.07 0 0 1 64 64v12a12 12 0 0 0 12 12h28.8a40 40 0 1 0 71.2-32Zm-112-52.33A87.75 87.75 0 0 0 68 84V60h64ZM175.35 172H156v-52.32L220 137v11.8a40.57 40.57 0 0 0-8-.8 40.06 40.06 0 0 0-36.65 24ZM212 204a16 16 0 1 1 16-16 16 16 0 0 1-16 16ZM64 124a52 52 0 1 0 52 52 52.06 52.06 0 0 0-52-52Zm0 80a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm16-28a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default Tractor;