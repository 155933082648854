/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shovel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shovel-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shovel-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m242.83 61.17-48-48a4 4 0 0 0-5.66 5.66L210.34 40l-76.68 76.69-37.17-37.18a12 12 0 0 0-17 0l-56 56A11.93 11.93 0 0 0 20 144v80a12 12 0 0 0 12 12h80a12 12 0 0 0 8.49-3.51l56-56a12 12 0 0 0 0-17l-37.18-37.17L216 45.66l21.17 21.17a4 4 0 1 0 5.66-5.66Zm-72 104a4 4 0 0 1 0 5.66l-56 56A4 4 0 0 1 112 228H32a4 4 0 0 1-4-4v-80a4 4 0 0 1 1.17-2.83l56-56a4 4 0 0 1 5.66 0L128 122.34l-42.83 42.83a4 4 0 0 0 5.66 5.66L133.66 128Z" /></Svg>;
export default Shovel;