/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HardDrive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hard-drive-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hard-drive-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 60H32a20 20 0 0 0-20 20v96a20 20 0 0 0 20 20h192a20 20 0 0 0 20-20V80a20 20 0 0 0-20-20Zm-4 112H36V84h184Zm-56-44a16 16 0 1 1 16 16 16 16 0 0 1-16-16Z" /></Svg>;
export default HardDrive;