/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreasureChest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="treasure-chest-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-treasure-chest-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 36H72a60.07 60.07 0 0 0-60 60v96a20 20 0 0 0 20 20h192a20 20 0 0 0 20-20V96a60.07 60.07 0 0 0-60-60Zm36 60v4h-28V60.91A36.05 36.05 0 0 1 220 96Zm-92 60a12 12 0 0 0 12-12v-20h28v64H88v-64h28v20a12 12 0 0 0 12 12Zm12-56v-4a12 12 0 0 0-24 0v4H88V60h80v40ZM64 60.91V100H36v-4a36.05 36.05 0 0 1 28-35.09ZM36 124h28v64H36Zm156 64v-64h28v64Z" /></Svg>;
export default TreasureChest;