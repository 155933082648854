/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-nine-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-nine-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 44a52 52 0 1 0 24.74 97.73L116.51 206a4 4 0 0 0 7 3.92L173 122a52 52 0 0 0-45-78Zm0 96a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Z" /></Svg>;
export default NumberNine;