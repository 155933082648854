/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-simple__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-simple'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 72a56 56 0 1 0-64 55.42V232a8 8 0 0 0 16 0V127.42A56.09 56.09 0 0 0 184 72Zm-56 40a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" /></Svg>;
export default MapPinSimple;