/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Farm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="farm-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-farm-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 158a6 6 0 0 0 0-12 230.11 230.11 0 0 0-66.11 9.65 260.34 260.34 0 0 0-23.07-13.28A248.25 248.25 0 0 1 232 126a6 6 0 0 0 0-12c-6 0-12 .22-18 .62V80a6 6 0 0 0-2.4-4.8l-64-48a6 6 0 0 0-7.2 0l-64 48A6 6 0 0 0 74 80v38.77A264.25 264.25 0 0 0 24 114a6 6 0 0 0 0 12 249 249 0 0 1 195.17 93.75 6 6 0 0 0 4.69 2.25 6 6 0 0 0 4.67-9.75 265 265 0 0 0-18.69-20.94A190.58 190.58 0 0 1 232 190a6 6 0 0 0 0-12 199.1 199.1 0 0 0-33.21 2.79q-9.63-8.65-20-16.25A218.72 218.72 0 0 1 232 158Zm-106-23.44V102h36v21.46a259.11 259.11 0 0 0-33.93 12ZM86 83l58-43.5L202 83v32.71a260.51 260.51 0 0 0-28 4.73V96a6 6 0 0 0-6-6h-48a6 6 0 0 0-6 6v33.85a258.87 258.87 0 0 0-28-8.46Zm49.17 136.32a6 6 0 0 1-8.32 1.68A185.14 185.14 0 0 0 24 190a6 6 0 0 1 0-12 197.1 197.1 0 0 1 109.49 33 6 6 0 0 1 1.68 8.32Zm49.8-7.61a6 6 0 1 1-8.4 8.57A216.79 216.79 0 0 0 24 158a6 6 0 0 1 0-12 228.74 228.74 0 0 1 161 65.71Z" /></Svg>;
export default Farm;