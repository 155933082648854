/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FigmaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="figma-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-figma-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 96a40 40 0 0 0-24-72H96a40 40 0 0 0-24 72 40 40 0 0 0 1.37 65A44 44 0 1 0 144 196v-36a40 40 0 1 0 48-64Zm0-32a24 24 0 0 1-24 24h-24V40h24a24 24 0 0 1 24 24ZM72 64a24 24 0 0 1 24-24h32v48H96a24 24 0 0 1-24-24Zm24 88a24 24 0 0 1 0-48h32v48H96Zm32 44a28 28 0 1 1-28-28h28Zm40-44a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default FigmaLogo;