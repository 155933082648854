/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 1 0-6 5.38l31.52 34.63A137.72 137.72 0 0 0 27.21 109c-18.52 18.53-20.31 45.83-4.36 66.4a12 12 0 0 0 13.83 3.77l49-17.39.15-.05a12 12 0 0 0 7.31-8.79l5.9-29.51a3.92 3.92 0 0 1 2.51-3 79.44 79.44 0 0 1 11.4-3l92.07 101.26a4 4 0 1 0 5.92-5.38Zm48 75.6a12 12 0 0 0-7.72 9l-5.9 29.51a4 4 0 0 1-2.37 2.9l-49 17.38-.15.06a4 4 0 0 1-4.61-1.21c-13.42-17.29-11.9-40.25 3.69-55.84a130.06 130.06 0 0 1 49.39-30.99l24.44 26.88q-3.97.98-7.83 2.31Zm134.15 62.49a12 12 0 0 1-13.83 3.77l-9.31-3.3a4 4 0 1 1 2.68-7.54l9.37 3.32.15.06a4 4 0 0 0 4.61-1.21c13.42-17.29 11.9-40.25-3.69-55.84-26.47-26.46-63.79-40.48-102.41-38.47a4 4 0 1 1-.41-8c40.86-2.12 80.41 12.74 108.48 40.8 18.52 18.54 20.31 45.84 4.36 66.41Z" /></Svg>;
export default PhoneSlash;