/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaperPlaneTilt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paper-plane-tilt-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paper-plane-tilt-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.14 25.86a20 20 0 0 0-19.57-5.11l-.22.07L18.44 79a20 20 0 0 0-3.06 37.25L99 157l40.71 83.65a19.81 19.81 0 0 0 18 11.38c.57 0 1.15 0 1.73-.07a19.82 19.82 0 0 0 17.56-14.4l58.18-191.91a1.42 1.42 0 0 0 .07-.22 20 20 0 0 0-5.11-19.57Zm-73.23 195.21-34.37-70.64 46-45.95a12 12 0 0 0-17-17l-46 46-70.61-34.39L210 46Z" /></Svg>;
export default PaperPlaneTilt;