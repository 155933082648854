/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Park = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="park-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-park-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 196h-36v-32h28a4 4 0 0 0 3.88-5l-32-128a4 4 0 0 0-7.76 0l-32 128a4 4 0 0 0 3.88 5h28v32h-72v-24h12a4 4 0 0 0 0-8h-12v-24h12a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8h12v24H40a4 4 0 0 0 0 8h12v24H24a4 4 0 0 0 0 8h208a4 4 0 0 0 0-8ZM192 48.49 218.88 156h-53.76ZM60 140h48v24H60Zm0 32h48v24H60Zm56-80a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-40a16 16 0 1 1-16 16 16 16 0 0 1 16-16Z" /></Svg>;
export default Park;