/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EjectSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eject-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eject-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 200a6 6 0 0 1-6 6H32a6 6 0 0 1 0-12h192a6 6 0 0 1 6 6ZM27.39 150a14 14 0 0 1 1.77-15l81.66-100.8a22.1 22.1 0 0 1 34.36 0L226.84 135a14.09 14.09 0 0 1-10.93 23H40.09a14 14 0 0 1-12.7-8Zm10.83-5.16a2 2 0 0 0 1.87 1.16h175.82a2 2 0 0 0 1.87-1.18 2 2 0 0 0-.27-2.24L135.86 41.76a10.1 10.1 0 0 0-15.72 0L38.49 142.58a2 2 0 0 0-.27 2.24Z" /></Svg>;
export default EjectSimple;