/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CrownCross = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-cross-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-cross-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 58c-19.15 0-35.57 7.79-46 20.32V38h18a6 6 0 0 0 0-12h-18V8a6 6 0 0 0-12 0v18h-18a6 6 0 0 0 0 12h18v40.32C111.57 65.79 95.15 58 76 58a58.07 58.07 0 0 0-58 58c0 29.11 14.17 47.62 26.05 58a87.74 87.74 0 0 0 22 14v20a14 14 0 0 0 14 14h96a14 14 0 0 0 14-14v-19.95A87.74 87.74 0 0 0 212 174c11.88-10.39 26.05-28.9 26.05-58A58.07 58.07 0 0 0 180 58Zm2.1 120.31A6 6 0 0 0 178 184v24a2 2 0 0 1-2 2H80a2 2 0 0 1-2-2v-24a6 6 0 0 0-4.1-5.69C73.46 178.16 30 163.13 30 116a46.06 46.06 0 0 1 46-46c26.65 0 46 17.66 46 42v64a6 6 0 0 0 12 0v-64c0-24.34 19.35-42 46-42a46.06 46.06 0 0 1 46 46c0 46.9-42.12 61.71-43.9 62.31Z" /></Svg>;
export default CrownCross;