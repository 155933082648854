/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cigarette = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cigarette-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cigarette-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 124H32a20 20 0 0 0-20 20v32a20 20 0 0 0 20 20h192a20 20 0 0 0 20-20v-32a20 20 0 0 0-20-20ZM36 148h40v24H36Zm184 24H100v-24h120ZM201.67 55.89c7.53-12.71 7.12-19.63 5.07-21.1A12 12 0 0 1 216.67 13a25.9 25.9 0 0 1 13.8 15.79c3.6 10.92.86 24.17-8.14 39.38-7.53 12.7-7.12 19.63-5.07 21.1a12 12 0 0 1-9.93 21.84 26 26 0 0 1-13.8-15.79c-3.6-10.98-.86-24.23 8.14-39.43Zm-48 0c7.53-12.71 7.12-19.63 5.07-21.1A12 12 0 0 1 168.67 13a25.9 25.9 0 0 1 13.8 15.79c3.6 10.92.86 24.17-8.14 39.38-7.53 12.7-7.12 19.63-5.07 21.1a12 12 0 0 1-9.93 21.84 26 26 0 0 1-13.8-15.79c-3.6-10.98-.86-24.23 8.14-39.43Z" /></Svg>;
export default Cigarette;