/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BeerStein = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beer-stein-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beer-stein-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 72v136a8 8 0 0 1-8 8H56a8 8 0 0 1-8-8V72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M104 104v80a8 8 0 0 1-16 0v-80a8 8 0 0 1 16 0Zm40-8a8 8 0 0 0-8 8v80a8 8 0 0 0 16 0v-80a8 8 0 0 0-8-8Zm96 16v64a24 24 0 0 1-24 24h-16v8a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16V72c0-30.88 28.71-56 64-56 16.77 0 32.91 5.8 44.82 16H160a40 40 0 0 1 40 40v16h16a24 24 0 0 1 24 24ZM57 64h125.62A24 24 0 0 0 160 48h-14.26a8 8 0 0 1-5.53-2.22C131.06 37 117.87 32 104 32c-23.18 0-42.57 13.76-47 32Zm127 144V80H56v128h128Zm40-96a8 8 0 0 0-8-8h-16v80h16a8 8 0 0 0 8-8Z" /></Svg>;
export default BeerStein;