/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ranking = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ranking-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ranking-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M114.31 101.9a6 6 0 0 1 3.79-7.59l12-4A6 6 0 0 1 138 96v40a6 6 0 0 1-12 0v-31.68l-4.1 1.37a6 6 0 0 1-7.59-3.79ZM246 208a6 6 0 0 1-6 6H16a6 6 0 0 1 0-12h10v-98a14 14 0 0 1 14-14h42V56a14 14 0 0 1 14-14h64a14 14 0 0 1 14 14v74h42a14 14 0 0 1 14 14v58h10a6 6 0 0 1 6 6Zm-72-66v60h44v-58a2 2 0 0 0-2-2Zm-80 60h68V56a2 2 0 0 0-2-2H96a2 2 0 0 0-2 2Zm-56 0h44V102H40a2 2 0 0 0-2 2Z" /></Svg>;
export default Ranking;