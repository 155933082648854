/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FediverseLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fediverse-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fediverse-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 92a31.86 31.86 0 0 0-9.16 1.34l-27-33.68A32 32 0 1 0 116 44v.33L74.75 61.52a32 32 0 1 0-33.55 52.6l1.62 40.73a32 32 0 1 0 38.49 48.7l42.89 12a32 32 0 1 0 57.8-22.12l24.16-38a31.68 31.68 0 0 0 5.8.54 32 32 0 0 0 0-64ZM71.64 109.24l34.93 20.22L72 156.33a31.68 31.68 0 0 0-5.24-2.45l-1.62-40.73a32.32 32.32 0 0 0 6.5-3.91Zm81.16-33.6a33.2 33.2 0 0 0 4.36-1l27 33.68a32.81 32.81 0 0 0-2.31 5.08l-38.43 1.83Zm-66 99.62 35.2-27.37 13.26 39.79a32.6 32.6 0 0 0-4.55 4.77l-42.89-12a33 33 0 0 0-1.04-5.19Zm71.2 4.81-13.62-40.84L183 137.4a31.7 31.7 0 0 0 3 5.17l-24.16 38a31.64 31.64 0 0 0-3.84-.5ZM148 36a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm-22.75 30.48a33.46 33.46 0 0 0 4.21 3.58l-9.4 39.48-36.38-21.06A31.6 31.6 0 0 0 84 84v-.33ZM52 76a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm4 116a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm100 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm56-88a8 8 0 1 1 8-8 8 8 0 0 1-8 8Z" /></Svg>;
export default FediverseLogo;