/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cheers = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cheers-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cheers-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m215 217.8-21.5 5.77-12.35-46.06a36.06 36.06 0 0 0 21.66-42.84c-12.45-46.43-38.31-87.12-39.4-88.83a4 4 0 0 0-4.41-1.72l-26.94 6.73c.23-11.53-.09-18.84-.09-19a4 4 0 0 0-3-3.7l-32-8a4 4 0 0 0-4.34 1.72c-1.09 1.71-26.95 42.4-39.4 88.83a36.06 36.06 0 0 0 21.66 42.84l-12.35 46.03L41 193.8a4 4 0 0 0-2 7.73l48 12.86a3.77 3.77 0 0 0 1 .14 4 4 0 0 0 1-7.86l-18.77-5 12.35-46.07a35.8 35.8 0 0 0 40.18-26.34c1.2-4.92 2.25-9.87 3.17-14.81a275.26 275.26 0 0 0 7.25 38.89A36.06 36.06 0 0 0 168 180a35 35 0 0 0 5.38-.43l12.35 46.07-18.77 5a4 4 0 0 0 1 7.86 3.77 3.77 0 0 0 1-.14l48-12.86a4 4 0 0 0-1.96-7.7ZM158.13 52.59c2.79 4.6 9.11 15.4 16.07 29.74l-42 10.5c-.81-14.58-.61-26.31-.4-32.28v-1.39Zm-60.26-24 26.23 6.56c.09 4 .17 11.77-.17 21.87-.06 1.18-.15 3.09-.22 5.63s-.27 5.26-.45 8L80.94 60.11C88.24 44.88 95 33.38 97.87 28.59ZM80.76 147A28 28 0 0 1 61 112.75 299.88 299.88 0 0 1 77.5 67.5l45.14 11.28a320.22 320.22 0 0 1-7.59 48.46A28 28 0 0 1 80.76 147Zm60.19 4.2a282.84 282.84 0 0 1-8.2-50.3l44.93-11.2A302.43 302.43 0 0 1 195 136.75a28 28 0 0 1-54 14.49Zm47.47-109.41a4 4 0 0 1 1.79-5.37l16-8a4 4 0 1 1 3.58 7.16l-16 8a4 4 0 0 1-5.37-1.79ZM228 72a4 4 0 0 1-4 4h-16a4 4 0 0 1 0-8h16a4 4 0 0 1 4 4ZM36.42 22.21a4 4 0 0 1 5.37-1.79l16 8a4 4 0 0 1-3.58 7.16l-16-8a4 4 0 0 1-1.79-5.37ZM40 68H24a4 4 0 0 1 0-8h16a4 4 0 0 1 0 8Z" /></Svg>;
export default Cheers;