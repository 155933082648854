/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Highlighter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="highlighter-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-highlighter-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m136 176-40 40-72-24 64-64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M253.66 106.34a8 8 0 0 0-11.32 0L192 156.69l-82.34-82.35-2.35-2.34 50.35-50.34a8 8 0 1 0-11.32-11.32L96 60.69a16 16 0 0 0-2.82 18.81L72 100.69a16 16 0 0 0 0 22.62l4.69 4.69-58.35 58.34a8 8 0 0 0 3.13 13.25l72 24A7.88 7.88 0 0 0 96 224a8 8 0 0 0 5.66-2.34L136 187.31l4.69 4.69a16 16 0 0 0 22.62 0l21.18-21.18a16 16 0 0 0 18.82-2.82l50.35-50.34a8 8 0 0 0 0-11.32ZM93.84 206.85l-55-18.35L88 139.31 124.69 176ZM152 180.69l-10.34-10.35-48-48L83.31 112 104 91.31 172.69 160Z" /></Svg>;
export default Highlighter;