/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LetterCircleP = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="letter-circle-p-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-letter-circle-p-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 192a92 92 0 1 1 92-92 92.1 92.1 0 0 1-92 92Zm8-136h-32a4 4 0 0 0-4 4v80a4 4 0 0 0 8 0v-20h28a32 32 0 0 0 0-64Zm0 56h-28V92h28a24 24 0 0 1 0 48Z" /></Svg>;
export default LetterCircleP;