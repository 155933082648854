/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-plus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-plus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M34 64a6 6 0 0 1 6-6h176a6 6 0 0 1 0 12H40a6 6 0 0 1-6-6Zm6 70h176a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12Zm104 52H40a6 6 0 0 0 0 12h104a6 6 0 0 0 0-12Zm88 0h-18v-18a6 6 0 0 0-12 0v18h-18a6 6 0 0 0 0 12h18v18a6 6 0 0 0 12 0v-18h18a6 6 0 0 0 0-12Z" /></Svg>;
export default ListPlus;