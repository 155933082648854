/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shower-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shower-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M60 236a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm24-40a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm-64 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm32-32a8 8 0 1 0 8 8 8 8 0 0 0-8-8ZM252 40a4 4 0 0 1-4 4h-30.34l-29.93 29.93L165.86 202a12 12 0 0 1-8.17 9.44 12.09 12.09 0 0 1-3.69.56 12 12 0 0 1-8.46-3.52l-98-98A12 12 0 0 1 54 90.14l128-21.87 31.1-31.1A4 4 0 0 1 216 36h32a4 4 0 0 1 4 4Zm-72.89 36.89L55.37 98a4 4 0 0 0-2.19 6.78l98 98a4 4 0 0 0 6.78-2.17Z" /></Svg>;
export default Shower;