/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ForkKnife = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fork-knife-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fork-knife-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M76 88V40a4 4 0 0 1 8 0v48a4 4 0 0 1-8 0Zm136-48v184a4 4 0 0 1-8 0v-52h-52a4 4 0 0 1-4-4 264.27 264.27 0 0 1 7.11-55.94c9.47-39.22 27.21-65.41 51.31-75.74A4 4 0 0 1 212 40Zm-8 6.46c-41.75 23.87-47.19 99.29-47.9 117.54H204Zm-88-7.12a4 4 0 0 0-7.9 1.32l8 47.66a36 36 0 0 1-72 0l8-47.66a4 4 0 0 0-7.9-1.32l-8 48a4.89 4.89 0 0 0-.2.66 44.06 44.06 0 0 0 40 43.81V224a4 4 0 0 0 8 0v-92.19A44.06 44.06 0 0 0 124 88a4.89 4.89 0 0 0 0-.66Z" /></Svg>;
export default ForkKnife;