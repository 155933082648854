/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThermometerCold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="thermometer-cold-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-thermometer-cold-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 56a52 52 0 0 0-104 0v94.69a64 64 0 1 0 104 0Zm-52 172a40 40 0 0 1-30.91-65.39 12 12 0 0 0 2.91-7.83V56a28 28 0 0 1 56 0v98.77a12 12 0 0 0 2.77 7.68A40 40 0 0 1 108 228ZM246.14 81.52l-14.72 4.79 9.1 12.52a12 12 0 1 1-19.42 14.11l-9.1-12.52-9.1 12.52a12 12 0 1 1-19.42-14.11l9.1-12.52-14.72-4.79a12 12 0 1 1 7.41-22.82L200 63.48V48a12 12 0 0 1 24 0v15.48l14.73-4.78a12 12 0 1 1 7.41 22.82ZM132 188a24 24 0 1 1-36-20.78V132a12 12 0 0 1 24 0v35.22A24 24 0 0 1 132 188Z" /></Svg>;
export default ThermometerCold;