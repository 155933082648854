/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BuildingOffice = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="building-office-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-building-office-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 208h-16V96a8 8 0 0 0 0-16h-48V48a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16v160H24a8 8 0 0 0 0 16h224a8 8 0 0 0 0-16ZM80 72h16a8 8 0 0 1 0 16H80a8 8 0 0 1 0-16Zm-8 48a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16H80a8 8 0 0 1-8-8Zm64 88H88v-48h48Zm8-80h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-40h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm72 120h-32V96h32Z" /></Svg>;
export default BuildingOffice;