/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrolleySuitcase = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trolley-suitcase-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trolley-suitcase-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 224a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm128-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm24-32H56V75.31A15.86 15.86 0 0 0 51.31 64L29.66 42.34a8 8 0 0 0-11.32 11.32L40 75.31V176h-8a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16ZM72 144V72a16 16 0 0 1 16-16h32V40a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v16h32a16 16 0 0 1 16 16v72a16 16 0 0 1-16 16H88a16 16 0 0 1-16-16Zm64-88h32V40h-32Z" /></Svg>;
export default TrolleySuitcase;