/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NavigationArrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="navigation-arrow-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-navigation-arrow-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 110 59.93 44.67A12 12 0 0 0 44.69 60L110 235.93a11.83 11.83 0 0 0 11.28 8.07h.22a11.82 11.82 0 0 0 11.26-8.47v-.1l22.45-80.19 80.19-22.44h.1a12 12 0 0 0 .5-22.8Zm-2.79 15.12-82.3 23a4 4 0 0 0-2.78 2.77l-23 82.3a3.88 3.88 0 0 1-3.74 2.78 4 4 0 0 1-3.88-2.77L52.22 57.32a3.93 3.93 0 0 1 1-4.14A4 4 0 0 1 56 52a3.86 3.86 0 0 1 1.25.21l176.08 65.32a4 4 0 0 1-.09 7.59Z" /></Svg>;
export default NavigationArrow;