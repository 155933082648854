/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyes-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyes-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220.48 60C209.21 39.37 193.42 28 176 28s-33.21 11.37-44.48 32c-1.24 2.28-2.42 4.65-3.52 7.11-1.1-2.46-2.28-4.83-3.52-7.11C113.21 39.37 97.42 28 80 28S46.79 39.37 35.52 60C25.51 78.38 20 102.52 20 128s5.51 49.62 15.52 68c11.27 20.62 27.06 32 44.48 32s33.21-11.38 44.48-32c1.24-2.28 2.42-4.65 3.52-7.11 1.1 2.46 2.28 4.83 3.52 7.11 11.27 20.65 27.06 32 44.48 32s33.21-11.38 44.48-32c10-18.35 15.52-42.49 15.52-68s-5.51-49.62-15.52-68ZM152 116a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm-96 0a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm24 88c-12.08 0-25.13-15.72-31.7-40.84a35.55 35.55 0 0 0 7.7.84 36 36 0 0 0 0-72 35.55 35.55 0 0 0-7.7.84C54.87 67.72 67.92 52 80 52c17 0 36 31.21 36 76s-19 76-36 76Zm96 0c-12.08 0-25.13-15.72-31.7-40.84a35.55 35.55 0 0 0 7.7.84 36 36 0 0 0 0-72 35.55 35.55 0 0 0-7.7.84C150.87 67.72 163.92 52 176 52c17 0 36 31.21 36 76s-19 76-36 76Z" /></Svg>;
export default Eyes;