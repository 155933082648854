/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tire = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tire-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tire-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 174c14.44 0 22-23.14 22-46s-7.56-46-22-46-22 23.14-22 46 7.56 46 22 46Zm0-80c2.57 0 10 11.69 10 34s-7.43 34-10 34-10-11.69-10-34 7.43-34 10-34Zm64 124h-40.29c18.18-17 30.29-50.57 30.29-90 0-57.2-25.48-102-58-102H92c-32.52 0-58 44.8-58 102s25.48 102 58 102h140a6 6 0 0 0 0-12Zm-22-90c0 48.79-21.07 90-46 90s-46-41.21-46-90 21.07-90 46-90 46 41.21 46 90Zm-164 0c0-2.86.08-5.69.22-8.5L80 95.37l26.5 18.93c-.33 4.47-.5 9-.5 13.7a175.72 175.72 0 0 0 4 38.08l-26.55-19a6 6 0 0 0-7 0l-26.1 18.64A160.24 160.24 0 0 1 46 128Zm46-90h44.29c-13.8 12.92-24.09 35.35-28.29 62.65L83.49 83.12a6 6 0 0 0-7 0l-28.7 20.51C53.37 66.24 71.36 38 92 38Zm0 180c-15.58 0-29.64-16.08-38-40l26-18.58 35.53 25.39c5.22 13.92 12.34 25.35 20.76 33.24Z" /></Svg>;
export default Tire;