/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BellSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bell-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bell-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l17.75 19.56A77.45 77.45 0 0 0 50 104c0 35.74-8.42 63.2-14.08 72.94A14 14 0 0 0 48 198h42.5a38 38 0 0 0 75 0h18l20 22a6 6 0 0 0 8.88-8.08ZM128 218a26 26 0 0 1-25.29-20h50.58A26 26 0 0 1 128 218Zm-80-32a1.9 1.9 0 0 1-1.7-1 2 2 0 0 1 0-2c7.56-13 15.7-43.31 15.7-79a65.63 65.63 0 0 1 7.78-31.12L172.62 186Zm165.29-8.62a5.88 5.88 0 0 1-2.2.42 6 6 0 0 1-5.58-3.81c-7.2-18.31-11.49-44.48-11.49-70a66 66 0 0 0-98.57-57.42 6 6 0 1 1-5.93-10.43A78 78 0 0 1 206 104c0 35.7 8.16 59.24 10.66 65.61a6 6 0 0 1-3.39 7.77Z" /></Svg>;
export default BellSlash;