/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CompassRose = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="compass-rose-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-compass-rose-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m247 116.39-20.47-5.34A100.27 100.27 0 0 0 145 29.44L139.61 9a12 12 0 0 0-23.22 0l-5.34 20.47a100.27 100.27 0 0 0-81.61 81.61L9 116.39a12 12 0 0 0 0 23.22L29.44 145a100.27 100.27 0 0 0 81.61 81.61l5.34 20.39a12 12 0 0 0 23.22 0l5.39-20.44A100.27 100.27 0 0 0 226.56 145l20.44-5.39a12 12 0 0 0 0-23.22Zm-46.88-12.23-38.31-10-10-38.31a76.32 76.32 0 0 1 48.34 48.31Zm-82.8-3.78L128 59.54l10.65 40.84L128 111ZM128 145l10.65 10.65L128 196.46l-10.65-40.84Zm-27.62-27.62L111 128l-10.65 10.65L59.54 128Zm55.24 21.3L145 128l10.65-10.65L196.46 128Zm-51.46-82.8-10 38.31-38.31 10a76.32 76.32 0 0 1 48.31-48.34Zm-48.31 96 38.31 10 10 38.31a76.32 76.32 0 0 1-48.31-48.35Zm96 48.31 10-38.31 38.31-10a76.32 76.32 0 0 1-48.32 48.27Z" /></Svg>;
export default CompassRose;