/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IdentificationBadge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="identification-badge-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-identification-badge-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M151.11 166.13a38 38 0 1 0-46.22 0A65.75 65.75 0 0 0 75.2 188.4a6 6 0 0 0 9.6 7.2 54 54 0 0 1 86.4 0 6 6 0 0 0 9.6-7.2 65.75 65.75 0 0 0-29.69-22.27ZM128 110a26 26 0 1 1-26 26 26 26 0 0 1 26-26Zm72-84H56a14 14 0 0 0-14 14v176a14 14 0 0 0 14 14h144a14 14 0 0 0 14-14V40a14 14 0 0 0-14-14Zm2 190a2 2 0 0 1-2 2H56a2 2 0 0 1-2-2V40a2 2 0 0 1 2-2h144a2 2 0 0 1 2 2ZM90 64a6 6 0 0 1 6-6h64a6 6 0 0 1 0 12H96a6 6 0 0 1-6-6Z" /></Svg>;
export default IdentificationBadge;