/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WebcamSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webcam-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webcam-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m216.88 207.93-160-176a12 12 0 1 0-17.76 16.14l14.81 16.29A84 84 0 0 0 116 187.13V196H32a12 12 0 0 0 0 24h163.42l3.7 4.07a12 12 0 0 0 17.76-16.14ZM68 104a59.84 59.84 0 0 1 3.52-20.29l12.91 14.2A44 44 0 0 0 128 148c.64 0 1.28 0 1.92-.05l12.9 14.19A60 60 0 0 1 68 104Zm72 92v-8.87a84.41 84.41 0 0 0 20.42-5.63L173.6 196ZM88.89 42.35a12 12 0 0 1 6.37-15.73 84 84 0 0 1 106.65 117.32 12 12 0 0 1-10.56 6.29 11.84 11.84 0 0 1-5.7-1.45 12 12 0 0 1-4.84-16.26 60 60 0 0 0-76.19-83.8 12 12 0 0 1-15.73-6.37ZM172 103.64a12 12 0 0 1-11.9 12.1h-.1a12 12 0 0 1-12-11.91 20 20 0 0 0-17.94-19.73 12 12 0 1 1 2.42-23.87A43.92 43.92 0 0 1 172 103.64Z" /></Svg>;
export default WebcamSlash;