/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BezierCurve = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bezier-curve__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bezier-curve'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.07 144.41A96.68 96.68 0 0 0 181 88h59a8 8 0 0 0 0-16h-81a32 32 0 0 0-62 0H16a8 8 0 0 0 0 16h59a96.68 96.68 0 0 0-40.07 56.41A32 32 0 1 0 51.08 146 80.6 80.6 0 0 1 99 93.44a32 32 0 0 0 58.06 0A80.6 80.6 0 0 1 204.92 146a32 32 0 1 0 16.15-1.57ZM56 176a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm72-80a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm88 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default BezierCurve;