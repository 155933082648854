/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingCartSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-cart-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-cart-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m232 72-25.63 92.28A16 16 0 0 1 191 176H92.16a16 16 0 0 1-15.41-11.72L51.11 72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M104 216a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm88-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm47.71-125.86-25.64 92.28A24.06 24.06 0 0 1 191 184H92.16A24.06 24.06 0 0 1 69 166.42L33.92 40H16a8 8 0 0 1 0-16h24a8 8 0 0 1 7.71 5.86L57.19 64H232a8 8 0 0 1 7.71 10.14ZM221.47 80H61.64l22.81 82.14a8 8 0 0 0 7.71 5.86H191a8 8 0 0 0 7.71-5.86Z" /></Svg>;
export default ShoppingCartSimple;