/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Golf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="golf-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-golf-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 100a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-48 16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm88-20a92 92 0 1 1-92-92 92.1 92.1 0 0 1 92 92Zm-24 0a68 68 0 1 0-68 68 68.07 68.07 0 0 0 68-68Zm-32 101.86a108.61 108.61 0 0 1-72 0 12 12 0 0 0-8 22.63 131.83 131.83 0 0 0 32 7V244a12 12 0 0 0 24 0v-16.54a131.83 131.83 0 0 0 32-7 12 12 0 0 0-8-22.63Z" /></Svg>;
export default Golf;