/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugCharging = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plug-charging-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plug-charging-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 60h-52V16a4 4 0 0 0-8 0v44H92V16a4 4 0 0 0-8 0v44H32.55C28.13 60 28 63.59 28 64a4 4 0 0 0 4 4h20v92a36 36 0 0 0 36 36h36v44a4 4 0 0 0 8 0v-44h36a36 36 0 0 0 36-36V68h20a4 4 0 0 0 0-8Zm-28 100a28 28 0 0 1-28 28H88a28 28 0 0 1-28-28V68h136Zm-87.29-29.72a4 4 0 0 1-.46-3.68l12-32a4 4 0 0 1 7.5 2.8l-10 26.6H144a4 4 0 0 1 3.75 5.4l-12 32a4 4 0 1 1-7.5-2.8l10-26.6H112a4 4 0 0 1-3.29-1.72Z" /></Svg>;
export default PlugCharging;