/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrendDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trend-down-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trend-down-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128v64h-64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M235.06 120.61a8 8 0 0 0-8.72 1.73L200 148.69l-58.34-58.35a8 8 0 0 0-11.32 0L96 124.69 29.66 58.34a8 8 0 0 0-11.32 11.32l72 72a8 8 0 0 0 11.32 0L136 107.31 188.69 160l-26.35 26.34A8 8 0 0 0 168 200h64a8 8 0 0 0 8-8v-64a8 8 0 0 0-4.94-7.39ZM224 184h-36.69L224 147.31Z" /></Svg>;
export default TrendDown;