/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Unite = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="unite__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-unite'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174.63 81.37a80 80 0 1 0-93.26 93.26 80 80 0 1 0 93.26-93.26ZM224 160c0 1.52-.07 3-.18 4.51l-50-50A80 80 0 0 0 176 98a64.11 64.11 0 0 1 48 62ZM45.47 56.79l52.62 52.61a80.5 80.5 0 0 0-9.93 15.44L36.3 73a64 64 0 0 1 9.17-16.21ZM73 36.3l51.86 51.86a80.5 80.5 0 0 0-15.44 9.93L56.79 45.47A64 64 0 0 1 73 36.3Zm61.46 110.83-25.57-25.57a64.65 64.65 0 0 1 12.69-12.69l25.57 25.57a64.65 64.65 0 0 1-12.71 12.69ZM155.31 120 136 100.69A63.48 63.48 0 0 1 160 96a63.48 63.48 0 0 1-4.69 24Zm-54.62 16L120 155.31A63.48 63.48 0 0 1 96 160a63.48 63.48 0 0 1 4.69-24Zm45.91 21.91 52.61 52.62A64 64 0 0 1 183 219.7l-51.86-51.86a80.5 80.5 0 0 0 15.46-9.93Zm11.31-11.31a80.5 80.5 0 0 0 9.93-15.44L219.7 183a64 64 0 0 1-9.17 16.19Zm.09-66.55a80 80 0 0 0-16.49 2.13l-50-50C93 32.07 94.48 32 96 32a64.11 64.11 0 0 1 62 48.05ZM32 96c0-1.52.07-3 .18-4.51l50 50A80 80 0 0 0 80.05 158 64.11 64.11 0 0 1 32 96Zm66 80a80 80 0 0 0 16.49-2.13l50 50c-1.49.11-3 .18-4.51.18A64.11 64.11 0 0 1 98 176Z" /></Svg>;
export default Unite;