/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-x__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-x'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.66 98.34a8 8 0 0 1-11.32 11.32L200 91.31l-18.34 18.35a8 8 0 0 1-11.32-11.32L188.69 80l-18.35-18.34a8 8 0 0 1 11.32-11.32L200 68.69l18.34-18.35a8 8 0 0 1 11.32 11.32L211.31 80ZM128 192a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm44.71-33.47a76.05 76.05 0 0 0-89.42 0 8 8 0 0 0 9.42 12.94 60 60 0 0 1 70.58 0 8 8 0 1 0 9.42-12.94Zm-37.09-94.35a8 8 0 1 0 .76-16c-2.78-.13-5.6-.2-8.38-.2A172.35 172.35 0 0 0 18.92 87a8 8 0 1 0 10.16 12.37A156.25 156.25 0 0 1 128 64c2.53 0 5.09.06 7.62.18Zm-.16 48.07a8 8 0 1 0 1.08-16c-2.83-.19-5.7-.29-8.54-.29a122.74 122.74 0 0 0-77 26.77A8 8 0 0 0 56 137a7.93 7.93 0 0 0 5-1.73A106.87 106.87 0 0 1 128 112c2.48 0 5 .08 7.46.25Z" /></Svg>;
export default WifiX;