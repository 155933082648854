/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-vertical-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-vertical-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M134 24v208a6 6 0 0 1-12 0V24a6 6 0 0 1 12 0Z" /></Svg>;
export default LineVertical;