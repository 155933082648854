/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Meteor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="meteor-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-meteor-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 160a40 40 0 1 1-40-40 40 40 0 0 1 40 40Zm74.34-37.66-48 48a8 8 0 0 0 11.32 11.32l48-48a8 8 0 0 0-11.32-11.32Zm-20.68-12.68a8 8 0 0 0-11.32-11.32l-24 24a8 8 0 0 0 11.32 11.32Zm40-51.32a8 8 0 0 0-11.32 0l-16 16a8 8 0 0 0 11.32 11.32l16-16a8 8 0 0 0 0-11.32Zm-107.32 43.32a8 8 0 0 0 11.32 0l72-72a8 8 0 1 0-11.32-11.32l-72 72a8 8 0 0 0 0 11.32Zm13.26 97.94a56 56 0 0 1-79.2-79.2l82.75-82.74a8 8 0 1 0-11.32-11.32l-82.74 82.75a72 72 0 1 0 101.82 101.82 8 8 0 0 0-11.31-11.31Z" /></Svg>;
export default Meteor;