/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DevToLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dev-to-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dev-to-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 52H24A20 20 0 0 0 4 72v112a20 20 0 0 0 20 20h208a20 20 0 0 0 20-20V72a20 20 0 0 0-20-20Zm-4 128H28V76h200Zm-120-24v-56a12 12 0 0 1 12-12h20a12 12 0 0 1 0 24h-8v4a12 12 0 0 1 0 24v4h8a12 12 0 0 1 0 24h-20a12 12 0 0 1-12-12Zm52.46-52.7a12 12 0 1 1 23.08-6.6l4.46 15.62 4.46-15.62a12 12 0 0 1 23.08 6.6l-16 56a12 12 0 0 1-23.08 0ZM52 168h12a36 36 0 0 0 36-36v-8a36 36 0 0 0-36-36H52a12 12 0 0 0-12 12v56a12 12 0 0 0 12 12Zm12-56a12 12 0 0 1 12 12v8a12 12 0 0 1-12 12Z" /></Svg>;
export default DevToLogo;