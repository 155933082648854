/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WashingMachine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="washing-machine-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-washing-machine-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm2 174a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2h160a2 2 0 0 1 2 2ZM128 66a62 62 0 1 0 62 62 62.07 62.07 0 0 0-62-62Zm0 112a50 50 0 1 1 50-50 50.06 50.06 0 0 1-50 50Zm70-110a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-73.76 48.24-16 16a6 6 0 0 1-8.48-8.48l16-16a6 6 0 0 1 8.48 8.48Zm32-.48a6 6 0 0 1 0 8.48l-32 32a6 6 0 0 1-8.48-8.48l32-32a6 6 0 0 1 8.48 0Z" /></Svg>;
export default WashingMachine;