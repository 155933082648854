/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderFemale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-female-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-female-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204 96a76 76 0 1 0-80 75.89V204H88a4 4 0 0 0 0 8h36v28a4 4 0 0 0 8 0v-28h36a4 4 0 0 0 0-8h-36v-32.11A76.09 76.09 0 0 0 204 96ZM60 96a68 68 0 1 1 68 68 68.07 68.07 0 0 1-68-68Z" /></Svg>;
export default GenderFemale;