/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyEth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-eth-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-eth-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m225.44 120.59-88-112a12 12 0 0 0-18.88 0l-88 112a12 12 0 0 0 0 14.82l.6.76a3.72 3.72 0 0 0 .44.56l87 110.68a12 12 0 0 0 18.88 0l88-112a12 12 0 0 0-.04-14.82ZM140 50.7l57.12 72.7-57.12 26Zm-24 98.66-57.12-26L116 50.7Zm0 26.37v29.57l-36.15-46Zm24 0 36.15-16.43-36.15 46Z" /></Svg>;
export default CurrencyEth;