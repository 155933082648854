/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Broom = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="broom-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-broom-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.62 218.6C211.35 207.29 198 183 198 152v-17.3a14 14 0 0 0-8.82-13l-24.89-9.83a10 10 0 0 1-5.59-13L180 45.9a26 26 0 0 0-15-34.33c-12.95-4.83-27.88 1.84-33.31 15l-21 53.11a10 10 0 0 1-13 5.61L72.37 75a13.9 13.9 0 0 0-15.2 3.19C36.49 99.42 26 124.26 26 152a109.53 109.53 0 0 0 30.62 76.16A6 6 0 0 0 61 230h171a6 6 0 0 0 2.62-11.4ZM65.77 86.52a2 2 0 0 1 2.12-.43l25.4 10.29a22 22 0 0 0 28.63-12.32l21-53c3-7.13 11-10.81 18-8.21a14 14 0 0 1 8 18.54l-21.36 53.1a22.05 22.05 0 0 0 12.3 28.51l24.88 9.83a2 2 0 0 1 1.26 1.87V152c0 1.34 0 2.65.08 4L52.74 102.61a110.07 110.07 0 0 1 13.03-16.09ZM114.33 218a89.6 89.6 0 0 1-25.5-43.5 6 6 0 1 0-11.62 3 102.87 102.87 0 0 0 20.6 40.5H63.56A97.56 97.56 0 0 1 38 152a87.42 87.42 0 0 1 8.71-38.86l140.64 56.26c3.15 19.92 11.77 36.66 25 48.6Z" /></Svg>;
export default Broom;