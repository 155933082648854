/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-left-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-left-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a8 8 0 0 1-8 8h-96v64a8 8 0 0 1-13.66 5.66l-72-72a8 8 0 0 1 0-11.32l72-72A8 8 0 0 1 120 56v64h96a8 8 0 0 1 8 8Z" /></Svg>;
export default ArrowLeft;