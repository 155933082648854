/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNote = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-note-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-note-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m211.45 52.51-80-24A12 12 0 0 0 116 40v100.22A52 52 0 1 0 140 184v-79.87l64.55 19.36A12 12 0 0 0 220 112V64a12 12 0 0 0-8.55-11.49ZM88 212a28 28 0 1 1 28-28 28 28 0 0 1-28 28ZM196 95.87l-56-16.8V56.13l56 16.8Z" /></Svg>;
export default MusicNote;