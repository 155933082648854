/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlowerLotus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flower-lotus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flower-lotus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M245.83 121.63a15.53 15.53 0 0 0-9.52-7.33 73.55 73.55 0 0 0-22.17-2.22c4-19.85 1-35.55-2-44.86a16.17 16.17 0 0 0-18.8-10.88 85.53 85.53 0 0 0-28.55 12.12 94.58 94.58 0 0 0-27.11-33.25 16.05 16.05 0 0 0-19.26 0 94.58 94.58 0 0 0-27.16 33.25 85.53 85.53 0 0 0-28.55-12.12 16.14 16.14 0 0 0-18.79 10.88c-3 9.31-6 25-2.06 44.86a73.55 73.55 0 0 0-22.17 2.22 15.53 15.53 0 0 0-9.52 7.33 16 16 0 0 0-1.6 12.26c3.39 12.58 13.8 36.49 45.33 55.33S113.13 208 128.05 208s42.67 0 74-18.78c31.53-18.84 41.94-42.75 45.33-55.33a16 16 0 0 0-1.55-12.26ZM62.1 175.49C35.47 159.57 26.82 140.05 24 129.7a59.61 59.61 0 0 1 22.5-1.17 129.08 129.08 0 0 0 9.15 19.41 142.28 142.28 0 0 0 34 39.56 114.92 114.92 0 0 1-27.55-12.01ZM128 190.4c-9.33-6.94-32-28.23-32-71.23C96 76.7 118.38 55.24 128 48c9.62 7.26 32 28.72 32 71.19 0 42.98-22.67 64.27-32 71.21Zm104-60.68c-2.77 10.24-11.4 29.81-38.09 45.77a114.92 114.92 0 0 1-27.55 12 142.28 142.28 0 0 0 34-39.56 129.08 129.08 0 0 0 9.15-19.41 59.69 59.69 0 0 1 22.49 1.19Z" /></Svg>;
export default FlowerLotus;