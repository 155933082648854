/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceMobile = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-mobile-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-mobile-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 12H80a28 28 0 0 0-28 28v176a28 28 0 0 0 28 28h96a28 28 0 0 0 28-28V40a28 28 0 0 0-28-28ZM76 76h104v104H76Zm4-40h96a4 4 0 0 1 4 4v12H76V40a4 4 0 0 1 4-4Zm96 184H80a4 4 0 0 1-4-4v-12h104v12a4 4 0 0 1-4 4Z" /></Svg>;
export default DeviceMobile;