/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListBullets = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-bullets-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-bullets-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M76 64a12 12 0 0 1 12-12h128a12 12 0 0 1 0 24H88a12 12 0 0 1-12-12Zm140 52H88a12 12 0 0 0 0 24h128a12 12 0 0 0 0-24Zm0 64H88a12 12 0 0 0 0 24h128a12 12 0 0 0 0-24ZM44 112a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm0-64a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm0 128a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default ListBullets;