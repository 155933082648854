/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScribbleLoop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scribble-loop__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scribble-loop'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M253.93 154.63c-1.32-1.46-24.09-26.22-61-40.56-1.72-18.42-8.46-35.17-19.41-47.92C158.87 49 137.58 40 112 40c-51.52 0-85.11 46.18-86.51 48.15a8 8 0 0 0 13 9.31C38.8 97.05 68.81 56 112 56c20.77 0 37.86 7.11 49.41 20.57 7.42 8.64 12.44 19.69 14.67 32A140.87 140.87 0 0 0 140.6 104c-26.06 0-47.93 6.81-63.26 19.69C63.78 135.09 56 151 56 167.25a47.59 47.59 0 0 0 13.87 34.05c9.66 9.62 23.06 14.7 38.73 14.7 51.81 0 81.18-42.13 84.49-84.42a161.43 161.43 0 0 1 49 33.79 8 8 0 1 0 11.86-10.74Zm-94.46 21.64C150.64 187.09 134.66 200 108.6 200 83.32 200 72 183.55 72 167.25 72 144.49 93.47 120 140.6 120a124.34 124.34 0 0 1 36.78 5.68c-.45 18.76-6.92 37.1-17.91 50.59Z" /></Svg>;
export default ScribbleLoop;