/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleTaiChi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-tai-chi-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-tai-chi-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96 48a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm120 48H40a8 8 0 0 0 0 16h80v28.44l-77.35 69.61A8 8 0 0 0 53.35 222l76.2-68.58L176 173.28V216a8 8 0 0 0 16 0v-48a8 8 0 0 0-4.85-7.35L136 138.72V112h80a8 8 0 0 0 0-16Z" /></Svg>;
export default PersonSimpleTaiChi;