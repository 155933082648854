/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LightningSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightning-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightning-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M105.72 67.81a4 4 0 0 1 0-5.42l48.39-51.85a8 8 0 0 1 13.7 7L153.18 90.9l57.43 21.53a8.24 8.24 0 0 1 4.22 3.4 8 8 0 0 1-1 9.63l-25.27 27.07a4 4 0 0 1-5.88 0Zm27.76 54.32L53.92 34.62a8 8 0 1 0-11.84 10.76l39.26 43.18-39 41.83a8.15 8.15 0 0 0-2.34 4.92 8 8 0 0 0 5.16 8.18l57.63 21.61-14.63 73.33a8 8 0 0 0 13.69 7l61.86-66.28 38.37 42.2a8 8 0 1 0 11.84-10.76Z" /></Svg>;
export default LightningSlash;