/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugsConnected = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plugs-connected-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plugs-connected-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88.57 35a8 8 0 0 1 14.86-6l8 20a8 8 0 0 1-14.86 6ZM29 103.43l20 8a8 8 0 1 0 6-14.86l-20-8a8 8 0 0 0-6 14.86Zm198 49.14-20-8a8 8 0 1 0-6 14.86l20 8a8 8 0 0 0 6-14.86ZM159.43 201a8 8 0 0 0-14.86 6l8 20a8 8 0 1 0 14.86-6Zm78.48-182.48a8 8 0 0 0-11.5-.18L174 70.75l-5.38-5.38a32 32 0 0 0-45.28 0l-17.2 17.17a4 4 0 0 0 0 5.66l61.7 61.66a4 4 0 0 0 5.66 0l16.74-16.74a32.76 32.76 0 0 0 9.81-22.52 31.82 31.82 0 0 0-9.37-23.17l-5.38-5.37 52.2-52.17a8.22 8.22 0 0 0 .41-11.37ZM85.64 90.34a8 8 0 0 0-11.49.18 8.22 8.22 0 0 0 .41 11.37l6.11 6.11-15.33 15.31A31.82 31.82 0 0 0 56 146.47 32.75 32.75 0 0 0 65.77 169l5 4.94-52.28 52.19a8.21 8.21 0 0 0-.61 11.1 8 8 0 0 0 11.72.43L82 185.25l5.37 5.38a32.1 32.1 0 0 0 45.29 0L148 175.31l6.34 6.35a8 8 0 0 0 11.32-11.32Z" /></Svg>;
export default PlugsConnected;