/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotesMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-minus__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-minus'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 48a8 8 0 0 1-8 8h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 8 8Zm-16 40v76a36 36 0 1 1-16-29.92V88a8 8 0 0 1 16 0Zm-16 76a20 20 0 1 0-20 20 20 20 0 0 0 20-20ZM88 110.25V196a36 36 0 1 1-16-29.92V56a8 8 0 0 1 6.06-7.76l56-14a8 8 0 0 1 3.88 15.52L88 62.25v31.5l70.06-17.51a8 8 0 0 1 3.88 15.52ZM72 196a20 20 0 1 0-20 20 20 20 0 0 0 20-20Z" /></Svg>;
export default MusicNotesMinus;