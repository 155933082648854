/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14l20.56 22.62C44 95.22 36 119.84 36 144a92 92 0 0 0 153 68.9l10.16 11.17a12 12 0 0 0 17.76-16.14ZM128 212a68.07 68.07 0 0 1-68-68c0-17.74 5.54-36.12 16.49-54.82l96.32 106A67.84 67.84 0 0 1 128 212ZM92.61 47.44a12 12 0 0 1 .12-17 243.72 243.72 0 0 1 28.39-24.3 12 12 0 0 1 13.76 0C138.36 8.6 220 66.64 220 144a92.8 92.8 0 0 1-1 13.34 12 12 0 0 1-11.86 10.28 12.74 12.74 0 0 1-1.74-.13 12 12 0 0 1-10.15-13.6A69.36 69.36 0 0 0 196 144c0-52.93-49.49-97.91-68-112.88-4.64 3.73-11.2 9.33-18.41 16.44a12 12 0 0 1-16.98-.12Z" /></Svg>;
export default DropSlash;