/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Towel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="towel-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-towel-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M197.16 20H72a28 28 0 0 0-28 28v168a20 20 0 0 0 20 20h104a20 20 0 0 0 20-20V52a8 8 0 0 1 8-8h.35a8.33 8.33 0 0 1 7.7 8.48V148a12 12 0 0 0 24 0V52.48A32.13 32.13 0 0 0 197.16 20ZM72 44h93a32.24 32.24 0 0 0-1 8v120H68V48a4 4 0 0 1 4-4Zm-4 168v-16h96v16Z" /></Svg>;
export default Towel;