/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l14.37 15.8A97.42 97.42 0 0 0 34 124v84a14 14 0 0 0 14 14h84a98.24 98.24 0 0 0 56.82-18.18L203.56 220a6 6 0 0 0 8.88-8.08ZM132 210H48a2 2 0 0 1-2-2v-84a85.46 85.46 0 0 1 20.08-55.2L180.7 194.88A84.91 84.91 0 0 1 132 210Zm98-86a97.86 97.86 0 0 1-14.06 50.61 6 6 0 0 1-5.15 2.9 6 6 0 0 1-5.12-9.1 86 86 0 0 0-110.88-122 6 6 0 1 1-5.2-10.81A97.09 97.09 0 0 1 132 26a98.11 98.11 0 0 1 98 98Z" /></Svg>;
export default ChatTeardropSlash;