/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotSubsetOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-subset-of__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-subset-of'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.38 34.08a8 8 0 0 0-11.3.54L197.19 40H128a88 88 0 0 0-64.84 147.43l-21.08 23.19a8 8 0 1 0 11.84 10.76L75 198.2a87.5 87.5 0 0 0 53 17.8h72a8 8 0 0 0 0-16h-72a71.63 71.63 0 0 1-42.18-13.7l128.1-140.92a8 8 0 0 0-.54-11.3ZM56 128a72.08 72.08 0 0 1 72-72h54.64L74 175.53A71.69 71.69 0 0 1 56 128Z" /></Svg>;
export default NotSubsetOf;