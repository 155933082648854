/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-x-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-x-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M143.52 95.51 159 80l-15.48-15.52a12 12 0 1 1 17-17L176 63l15.52-15.52a12 12 0 0 1 17 17L193 80l15.52 15.51a12 12 0 1 1-17 17L176 97l-15.51 15.51a12 12 0 1 1-17-17Zm84.33 88.07A60.27 60.27 0 0 1 168 236C86.39 236 20 169.61 20 88a60.27 60.27 0 0 1 52.42-59.85A20.06 20.06 0 0 1 93.2 40l21.11 47.13a1.42 1.42 0 0 0 .08.18 20 20 0 0 1-1.66 18.83 11.76 11.76 0 0 1-.85 1.15L92.82 130c7.06 12.84 20.5 26.16 33.49 33.21l22.31-19a13.08 13.08 0 0 1 1.12-.84 19.94 19.94 0 0 1 19-1.75l.18.08L216 162.8a20.06 20.06 0 0 1 11.85 20.78Zm-24.31-.06-42-18.81-22.43 19.07a11.63 11.63 0 0 1-1.11.85 20 20 0 0 1-19.69 1.37c-19.48-9.4-38.89-28.68-48.31-48a20 20 0 0 1 1.28-19.64 12 12 0 0 1 .86-1.16L91.3 94.49l-18.82-42A36.29 36.29 0 0 0 44 88a124.15 124.15 0 0 0 124 124 36.29 36.29 0 0 0 35.54-28.48Z" /></Svg>;
export default PhoneX;