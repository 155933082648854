/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Nut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="nut-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-nut-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 84a44 44 0 1 0 44 44 44.05 44.05 0 0 0-44-44Zm0 80a36 36 0 1 1 36-36 36 36 0 0 1-36 36Zm93.76-94.34-88-48.18a12 12 0 0 0-11.52 0l-88 48.18A12 12 0 0 0 28 80.18v95.64a12 12 0 0 0 6.24 10.52l88 48.18a11.95 11.95 0 0 0 11.52 0l88-48.18a12 12 0 0 0 6.24-10.52V80.18a12 12 0 0 0-6.24-10.52ZM220 175.82a4 4 0 0 1-2.08 3.5l-88 48.18a4 4 0 0 1-3.84 0l-88-48.17a4 4 0 0 1-2.08-3.51V80.18a4 4 0 0 1 2.08-3.5l88-48.18a3.94 3.94 0 0 1 3.84 0l88 48.18a4 4 0 0 1 2.08 3.5Z" /></Svg>;
export default Nut;