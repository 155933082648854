/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LetterCircleP = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="letter-circle-p-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-letter-circle-p-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 116a20 20 0 0 1-20 20h-24V96h24a20 20 0 0 1 20 20Zm76 12A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-60-12a36 36 0 0 0-36-36h-32a8 8 0 0 0-8 8v80a8 8 0 0 0 16 0v-16h24a36 36 0 0 0 36-36Z" /></Svg>;
export default LetterCircleP;