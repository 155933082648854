/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeHemisphereEast = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-hemisphere-east-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-hemisphere-east-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M215 168.71a96.42 96.42 0 0 1-30.54 37l-9.36-9.37a8 8 0 0 0-3.63-2.09L150 188.59a8 8 0 0 1-5.88-8.9l2.38-16.2a8 8 0 0 1 4.84-6.22l30.46-12.66a8 8 0 0 1 8.47 1.49ZM159.89 105l22.17-25.8A8 8 0 0 0 184 74V50A96 96 0 0 0 50.49 184.65l9.92-6.52a8 8 0 0 0 3.59-6.64l.21-36.23a8.06 8.06 0 0 1 1.35-4.41l20.94-31.3a8 8 0 0 1 11.34-2l19.81 13a8.06 8.06 0 0 0 5.77 1.45l31.46-4.26a8 8 0 0 0 5.01-2.74Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 16a87.5 87.5 0 0 1 48 14.28V74l-22.17 25.74-31.47 4.26-.31-.22-19.67-12.86a16 16 0 0 0-22.51 4.18l-20.94 31.3a16 16 0 0 0-2.7 8.81L56 171.44l-3.27 2.15A88 88 0 0 1 128 40ZM62.29 186.47l2.52-1.65A16 16 0 0 0 72 171.53l.21-36.23L93.17 104a3.62 3.62 0 0 0 .32.22l19.67 12.87a15.94 15.94 0 0 0 11.35 2.77l31.49-4.27a16 16 0 0 0 10-5.41l22.17-25.76A16 16 0 0 0 192 74v-6.33A87.87 87.87 0 0 1 211.77 155l-16.14-14.76a16 16 0 0 0-16.93-3l-30.46 12.65a16.08 16.08 0 0 0-9.68 12.45l-2.39 16.19a16 16 0 0 0 11.77 17.81L169.4 202l2.36 2.37a87.88 87.88 0 0 1-109.47-17.9ZM185 195l-4.3-4.31a16 16 0 0 0-7.26-4.18L152 180.85l2.39-16.19L184.84 152 205 170.48A88.43 88.43 0 0 1 185 195Z" /></Svg>;
export default GlobeHemisphereEast;