/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Subway = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subway-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subway-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 176v-24h20v16a8 8 0 0 1-8 8Zm-16 0v-24h-24v24Zm36-88a8 8 0 0 0-8-8H88a8 8 0 0 0-8 8v48h96Zm-24-64h-48a72 72 0 0 0-72 72v112a8 8 0 0 0 8 8h36.58a4 4 0 0 0 3.58-2.21L91.06 192H88a24 24 0 0 1-24-24V88a24 24 0 0 1 24-24h80a24 24 0 0 1 24 24v80a24 24 0 0 1-24 24h-3.06l10.9 21.79a4 4 0 0 0 3.58 2.21H216a8 8 0 0 0 8-8V96a72 72 0 0 0-72-72Zm-4.94 168h-38.12l-9.1 18.21a4 4 0 0 0 3.58 5.79h49.16a4 4 0 0 0 3.58-5.79ZM80 168a8 8 0 0 0 8 8h12v-24H80Z" /></Svg>;
export default Subway;