/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPinSimpleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-simple-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-simple-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M87.25 40a4 4 0 0 1 4-4H192a4 4 0 0 1 0 8h-11.23l19.58 111a4 4 0 0 1-3.24 4.63 3.33 3.33 0 0 1-.7.07 4 4 0 0 1-3.93-3.31L172.64 44H91.25a4 4 0 0 1-4-4Zm123.44 179a4 4 0 0 1-5.65-.27L169.87 180H132v60a4 4 0 0 1-8 0v-60H40a4 4 0 0 1 0-8h12.64L70.52 70.72 45 42.69a4 4 0 0 1 6-5.38l160 176a4 4 0 0 1-.31 5.69Zm-48.1-47L77.32 78.2 60.77 172Z" /></Svg>;
export default PushPinSimpleSlash;