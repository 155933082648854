/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StarHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-half-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-half-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m231.66 111.81-5.06 4.36a4 4 0 1 1-5.22-6.06l5.05-4.36a4.37 4.37 0 0 0 1.32-4.77 4.29 4.29 0 0 0-3.81-3l-8.27-.67a4 4 0 1 1 .64-8l8.28.67a12.45 12.45 0 0 1 7.07 21.84Zm-55.19 39.89 4.08 17.21a4 4 0 0 0 3.88 3.08 3.69 3.69 0 0 0 .93-.11 4 4 0 0 0 3-4.81l-3.52-14.85 9.79-8.45a4 4 0 0 0-5.22-6.06l-11.63 10a4 4 0 0 0-1.31 3.99Zm21.34 55.37a4 4 0 0 0-7.79 1.84l1.32 5.58a4.38 4.38 0 0 1-1.71 4.65 4.26 4.26 0 0 1-4.82.18l-6.72-4.13a4 4 0 1 0-4.19 6.81l6.72 4.13a12.4 12.4 0 0 0 18.51-13.49Zm-22.14-113L160 92.79a4 4 0 0 1-3.38-2.46L132 30.73v-.09 156.2l9.34 5.74a4 4 0 0 1-4.2 6.82l-9.14-5.63-52.62 32.36a12.4 12.4 0 0 1-18.51-13.49l14.32-60.42-46.85-40.41A12.45 12.45 0 0 1 31.41 90l61.47-5 23.68-57.34a12.37 12.37 0 0 1 22.88 0L163.12 85l13.2 1.06a4 4 0 0 1-.65 8ZM124 186.84V30.64v.09l-24.66 59.6A4 4 0 0 1 96 92.79L32.06 98a4.29 4.29 0 0 0-3.81 3 4.37 4.37 0 0 0 1.32 4.77l48.68 42a4 4 0 0 1 1.28 3.95l-14.87 62.77a4.38 4.38 0 0 0 1.71 4.65 4.28 4.28 0 0 0 4.82.18Z" /></Svg>;
export default StarHalf;