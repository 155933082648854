/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PicnicTable = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="picnic-table-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-picnic-table-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 132h-49.53l-32-64H192a4 4 0 0 0 0-8H64a4 4 0 0 0 0 8h25.53l-32 64H8a4 4 0 0 0 0 8h45.53l-25.11 50.21a4 4 0 0 0 1.79 5.37A4.12 4.12 0 0 0 32 196a4 4 0 0 0 3.58-2.21L62.47 140h131.06l26.89 53.79A4 4 0 0 0 224 196a4.12 4.12 0 0 0 1.79-.42 4 4 0 0 0 1.79-5.37L202.47 140H248a4 4 0 0 0 0-8Zm-181.53 0 32-64h59.06l32 64Z" /></Svg>;
export default PicnicTable;