/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Heartbeat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heartbeat-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heartbeat-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M71.76 148H31.7a12 12 0 1 1 0-24h33.63l12.45-18.66a12 12 0 0 1 20 0l22 33 6-9a12 12 0 0 1 10-5.34h24a12 12 0 1 1 0 24h-17.6l-12.46 18.66a12 12 0 0 1-20 0l-22-33-6 9a12 12 0 0 1-9.96 5.34ZM177.91 36c-20.12 0-38 7.93-50.07 21.56C115.74 43.93 97.89 36 77.76 36a66 66 0 0 0-65.69 58.68A12 12 0 0 0 36 97.32 42 42 0 0 1 77.76 60c17.83 0 32.75 9.4 38.95 24.54a12 12 0 0 0 22.25 0C145.16 69.4 160.08 60 177.91 60A42.08 42.08 0 0 1 220 102c0 29.42-25.86 57.77-47.56 76.36a329 329 0 0 1-44.58 31.81c-10.87-6.45-35.37-22-56.51-42.73a12 12 0 1 0-16.84 17.12c30.39 29.81 66.15 49.2 67.66 50a12.06 12.06 0 0 0 11.39 0C138 232.14 244 174.34 244 102a66.12 66.12 0 0 0-66.09-66Z" /></Svg>;
export default Heartbeat;