/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextOutdent = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-outdent-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-outdent-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 128a6 6 0 0 1-6 6H112a6 6 0 0 1 0-12h104a6 6 0 0 1 6 6ZM112 70h104a6 6 0 0 0 0-12H112a6 6 0 0 0 0 12Zm104 116H40a6 6 0 0 0 0 12h176a6 6 0 0 0 0-12ZM72 142a6 6 0 0 0 4.24-10.24L40.49 96l35.75-35.76a6 6 0 0 0-8.48-8.48l-40 40a6 6 0 0 0 0 8.48l40 40A6 6 0 0 0 72 142Z" /></Svg>;
export default TextOutdent;