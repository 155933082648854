/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandWaving = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-waving__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-waving'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m220.17 100-17.31-30a28 28 0 0 0-38.24-10.25 27.69 27.69 0 0 0-9 8.34L138.2 38a28 28 0 0 0-48.48 0 28 28 0 0 0-41.57 36l1.59 2.76A27.67 27.67 0 0 0 38 80.41a28 28 0 0 0-10.24 38.25l40 69.32a87.47 87.47 0 0 0 53.43 41 88.56 88.56 0 0 0 22.92 3 88 88 0 0 0 76.06-132Zm-6.66 62.64A72 72 0 0 1 81.62 180l-40-69.32a12 12 0 0 1 20.78-12L81.63 132a8 8 0 1 0 13.85-8L62 66a12 12 0 1 1 20.78-12L114 108a8 8 0 1 0 13.85-8l-24.28-42a12 12 0 1 1 20.78-12l33.42 57.9a48 48 0 0 0-5.54 60.6 8 8 0 0 0 13.24-9 32 32 0 0 1 7.31-43.5 8 8 0 0 0 2.13-10.4L168.23 90A12 12 0 1 1 189 78l17.31 30a71.56 71.56 0 0 1 7.2 54.62ZM184.25 31.71A8 8 0 0 1 194 26a59.62 59.62 0 0 1 36.53 28l.33.57a8 8 0 1 1-13.85 8l-.33-.57a43.67 43.67 0 0 0-26.8-20.5 8 8 0 0 1-5.63-9.79ZM80.89 237a8 8 0 0 1-11.23 1.33A119.56 119.56 0 0 1 40.06 204a8 8 0 0 1 13.86-8 103.67 103.67 0 0 0 25.64 29.72A8 8 0 0 1 80.89 237Z" /></Svg>;
export default HandWaving;