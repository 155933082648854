/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SubsetProperOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subset-proper-of__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subset-proper-of'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 208a8 8 0 0 1-8 8h-72a88 88 0 0 1 0-176h72a8 8 0 0 1 0 16h-72a72 72 0 0 0 0 144h72a8 8 0 0 1 8 8Z" /></Svg>;
export default SubsetProperOf;