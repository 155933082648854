/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileImage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-image-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-image-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M158.66 219.56A8 8 0 0 1 152 232H24a8 8 0 0 1-6.73-12.33l36-56a8 8 0 0 1 13.46 0l9.76 15.18 20.85-31.29a8 8 0 0 1 13.32 0ZM216 88v128a16 16 0 0 1-16 16h-8a8 8 0 0 1 0-16h8V96h-48a8 8 0 0 1-8-8V40H56v88a8 8 0 0 1-16 0V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-56-8h28.69L160 51.31Z" /></Svg>;
export default FileImage;