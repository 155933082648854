/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Certificate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="certificate-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-certificate-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M126 136a6 6 0 0 1-6 6H72a6 6 0 0 1 0-12h48a6 6 0 0 1 6 6Zm-6-38H72a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm110 62.62V224a6 6 0 0 1-9 5.21l-25-14.3-25 14.3a6 6 0 0 1-9-5.21v-26H40a14 14 0 0 1-14-14V56a14 14 0 0 1 14-14h176a14 14 0 0 1 14 14v31.38a49.91 49.91 0 0 1 0 73.24ZM196 86a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm-34 100v-25.38a50 50 0 0 1 56-81.51V56a2 2 0 0 0-2-2H40a2 2 0 0 0-2 2v128a2 2 0 0 0 2 2Zm56-17.11a49.91 49.91 0 0 1-44 0v44.77l19-10.87a6 6 0 0 1 6 0l19 10.87Z" /></Svg>;
export default Certificate;