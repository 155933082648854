/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BeachBall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beach-ball-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beach-ball-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M161.23 93.36a191.07 191.07 0 0 0-82-48.09 96.4 96.4 0 0 1 68.68-11.2 191.91 191.91 0 0 1 13.32 59.29Zm60.7 14.7a192.2 192.2 0 0 0-59.29-13.29 191 191 0 0 1 48.09 82 96.37 96.37 0 0 0 11.2-68.67ZM32.27 135.19a96 96 0 0 0 88.54 88.54 191.56 191.56 0 0 0 40.5-129 191.61 191.61 0 0 0-129.04 40.46Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm81.7 71.3a199.77 199.77 0 0 0-40.94-8.06 199.77 199.77 0 0 0-8.06-40.94 88.57 88.57 0 0 1 49 49ZM216 128a87.83 87.83 0 0 1-4.28 27.12 200.28 200.28 0 0 0-29.16-49.93 183.12 183.12 0 0 1 32.31 8.75A88.14 88.14 0 0 1 216 128Zm-73.94-86.87a183.12 183.12 0 0 1 8.75 32.31 200.28 200.28 0 0 0-49.93-29.16 88.05 88.05 0 0 1 41.18-3.15ZM80.44 54a183.88 183.88 0 0 1 61.25 32.64A200.21 200.21 0 0 0 40.41 119.5 88.11 88.11 0 0 1 80.44 54Zm-39.77 84.86a184.08 184.08 0 0 1 112.88-36.41 184.08 184.08 0 0 1-36.41 112.88 88.18 88.18 0 0 1-76.47-76.47Zm95.83 76.73a200.21 200.21 0 0 0 32.87-101.28A183.88 183.88 0 0 1 202 175.56a88.11 88.11 0 0 1-65.5 40.03Z" /></Svg>;
export default BeachBall;