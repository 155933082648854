/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Globe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 187a113.4 113.4 0 0 1-20.39-35h40.82a116.94 116.94 0 0 1-10 20.77A108.61 108.61 0 0 1 128 207Zm-26.49-59a135.42 135.42 0 0 1 0-40h53a135.42 135.42 0 0 1 0 40ZM44 128a83.49 83.49 0 0 1 2.43-20h30.82a160.63 160.63 0 0 0 0 40H46.43A83.49 83.49 0 0 1 44 128Zm84-79a113.4 113.4 0 0 1 20.39 35h-40.8a116.94 116.94 0 0 1 10-20.77A108.61 108.61 0 0 1 128 49Zm50.73 59h30.82a83.52 83.52 0 0 1 0 40h-30.8a160.63 160.63 0 0 0 0-40Zm20.77-24h-25.79a140.82 140.82 0 0 0-15.5-34.36A84.51 84.51 0 0 1 199.52 84ZM97.79 49.64A140.82 140.82 0 0 0 82.29 84H56.48a84.51 84.51 0 0 1 41.31-34.36ZM56.48 172h25.81a140.82 140.82 0 0 0 15.5 34.36A84.51 84.51 0 0 1 56.48 172Zm101.73 34.36a140.82 140.82 0 0 0 15.5-34.36h25.81a84.51 84.51 0 0 1-41.31 34.36Z" /></Svg>;
export default Globe;