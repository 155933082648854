/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBrush = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-brush-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-brush-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 26c-20.8 0-44.11 11.41-69.3 33.9-18.08 16.16-33.7 35-44.4 49.1A58 58 0 0 0 34 164c0 32.07-20.43 46.39-21.35 47A6 6 0 0 0 16 222h76a58 58 0 0 0 55-76.3c14.08-10.67 32.92-26.32 49.08-44.4C218.59 76.11 230 52.8 230 32a6 6 0 0 0-6-6ZM92 210H30.65C37.92 200.85 46 185.78 46 164a46 46 0 1 1 46 46Zm29.49-95.91c3.6-4.67 7.88-10 12.71-15.69a78.17 78.17 0 0 1 23.4 23.4c-5.67 4.83-11 9.11-15.69 12.71a58.38 58.38 0 0 0-20.42-20.42Zm45.2-.3a90.24 90.24 0 0 0-24.48-24.48C163.05 66.46 191 42 217.56 38.44 214 65 189.54 93 166.69 113.79Z" /></Svg>;
export default PaintBrush;