/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M143.38 17.85a8 8 0 0 0-12.63 3.41l-22 60.41-24.16-23.41a8 8 0 0 0-11.93.89C51 87.53 40 116.08 40 144a88 88 0 0 0 176 0c0-59.45-50.79-108-72.62-126.15Z" /></Svg>;
export default FireSimple;