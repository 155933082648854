/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyMelting = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-melting-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-melting-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 140a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-48-48a8 8 0 1 0-8 8 8 8 0 0 0 8-8Zm74.21-35.2A100 100 0 0 0 53.46 194.67a4 4 0 1 0 6-5.34 92 92 0 1 1 137.16 0 4 4 0 0 0 6 5.34 100 100 0 0 0-4.41-137.87ZM152 172h-16c-12 0-25.28-4.92-35.42-13.16C89.89 150.15 84 139.19 84 128a43.89 43.89 0 0 1 .61-7.34 4 4 0 0 0-7.89-1.32A52.17 52.17 0 0 0 76 128c0 29.29 32.25 52 60 52h16a12 12 0 0 1 0 24H96a20 20 0 0 0 0 40 4 4 0 0 0 0-8 12 12 0 0 1 0-24h56a20 20 0 0 0 0-40Z" /></Svg>;
export default SmileyMelting;