/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotMemberOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-member-of__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-member-of'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.38 34.08a8 8 0 0 0-11.3.54L197.19 40H128a88 88 0 0 0-64.84 147.43l-21.08 23.19a8 8 0 1 0 11.84 10.76L75 198.2a87.5 87.5 0 0 0 53 17.8h72a8 8 0 0 0 0-16h-72a71.63 71.63 0 0 1-42.18-13.7l45.72-50.3H200a8 8 0 0 0 0-16h-53.92l67.84-74.62a8 8 0 0 0-.54-11.3ZM74 175.53A71.69 71.69 0 0 1 56.46 136h53.46ZM56.46 120A72.08 72.08 0 0 1 128 56h54.64l-58.18 64Z" /></Svg>;
export default NotMemberOf;