/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSuperscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-superscript-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-superscript-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246 144a6 6 0 0 1-6 6h-48a6 6 0 0 1-4.8-9.6l43.17-57.55A18 18 0 1 0 199 66a6 6 0 0 1-11.31-4 30.12 30.12 0 0 1 4.31-8 30 30 0 0 1 48 36.06L204 138h36a6 6 0 0 1 6 6Zm-98.07-68.53a6 6 0 0 0-8.46.6L92 130.84 44.53 76.07a6 6 0 1 0-9.06 7.86L84.06 140l-48.59 56.07a6 6 0 1 0 9.06 7.86L92 149.16l47.47 54.77a6 6 0 0 0 9.06-7.86L99.94 140l48.59-56.07a6 6 0 0 0-.6-8.46Z" /></Svg>;
export default TextSuperscript;