/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SecurityCamera = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="security-camera-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-security-camera-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 140a4 4 0 0 0-4 4v20h-48.69a4 4 0 0 1-2.82-1.17l-21.18-21.17 53.18-53.17a12 12 0 0 0 0-17l-56-56a12 12 0 0 0-17 0L5.76 161.76A6 6 0 0 0 10 172h41l36.48 36.49a12 12 0 0 0 17 0l61.18-61.18 21.17 21.17a11.9 11.9 0 0 0 8.48 3.52H244v20a4 4 0 0 0 8 0v-48a4 4 0 0 0-4-4ZM157.17 21.17a4.1 4.1 0 0 1 5.66 0l15.51 15.52L51 164H14.82ZM98.83 202.83a4.1 4.1 0 0 1-5.66 0L58.34 168 184 42.34l34.83 34.83a4 4 0 0 1 0 5.66Z" /></Svg>;
export default SecurityCamera;