/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104 104 0 0 0 128 24Zm0 184a80 80 0 1 1 53.34-139.63 8 8 0 0 1-10.67 11.92A64 64 0 1 0 191.5 136H128a8 8 0 0 1 0-16h72a8 8 0 0 1 8 8 80.09 80.09 0 0 1-80 80Z" /></Svg>;
export default GoogleLogo;