/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-half__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-half'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm8 16.37a86.4 86.4 0 0 1 16 3v169.3a86.4 86.4 0 0 1-16 3Zm32 9.26a87.81 87.81 0 0 1 16 10.54v135.66a87.81 87.81 0 0 1-16 10.54ZM40 128a88.11 88.11 0 0 1 80-87.63v175.26A88.11 88.11 0 0 1 40 128Zm160 50.54V77.46a87.82 87.82 0 0 1 0 101.08Z" /></Svg>;
export default CircleHalf;