/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Checkerboard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="checkerboard-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-checkerboard-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm-13.52 88L134 61.52V46h15.52L210 106.48V122ZM134 78.48 177.52 122H134ZM210 48v41.52L166.48 46H208a2 2 0 0 1 2 2ZM48 46h74v76H46V48a2 2 0 0 1 2-2Zm58.48 164L46 149.52V134h15.52L122 194.48V210ZM122 177.52 78.48 134H122ZM46 208v-41.52L89.52 210H48a2 2 0 0 1-2-2Zm162 2h-74v-76h76v74a2 2 0 0 1-2 2Z" /></Svg>;
export default Checkerboard;