/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EjectSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eject-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eject-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 200a12 12 0 0 1-12 12H32a12 12 0 1 1 0-24h192a12 12 0 0 1 12 12ZM22 152.57a20 20 0 0 1 2.52-21.32l81.64-100.82a28.08 28.08 0 0 1 43.68 0l81.65 100.82A20.1 20.1 0 0 1 215.91 164H40.09A19.9 19.9 0 0 1 22 152.57ZM48.3 140h159.4l-76.5-94.47a4.11 4.11 0 0 0-6.4 0Z" /></Svg>;
export default EjectSimple;