/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MagnifyingGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="magnifying-glass-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-magnifying-glass-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 112a56 56 0 1 1-56-56 56 56 0 0 1 56 56Zm61.66 117.66a8 8 0 0 1-11.32 0l-50.06-50.07a88 88 0 1 1 11.32-11.31l50.06 50.06a8 8 0 0 1 0 11.32ZM112 184a72 72 0 1 0-72-72 72.08 72.08 0 0 0 72 72Z" /></Svg>;
export default MagnifyingGlass;