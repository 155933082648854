/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FastForward = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fast-forward-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fast-forward-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m246.81 111.29-88.18-56.17A19.91 19.91 0 0 0 128 71.84v30L54.63 55.12A19.91 19.91 0 0 0 24 71.84v112.32a19.93 19.93 0 0 0 30.63 16.72L128 154.15v30a19.93 19.93 0 0 0 30.63 16.72l88.18-56.17a19.79 19.79 0 0 0 0-33.42ZM48 176.64V79.36L124.38 128Zm104 0V79.36L228.38 128Z" /></Svg>;
export default FastForward;