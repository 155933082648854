/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RainbowCloud = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rainbow-cloud-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rainbow-cloud-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 160a40 40 0 0 1-40 40h-48c-13.25 0-24-11.14-24-24.89s10.75-24.89 24-24.89a23.33 23.33 0 0 1 8.81 1.73A40 40 0 0 1 240 160Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M200 112a48.18 48.18 0 0 0-44.66 30.4 31 31 0 0 0-3.34-.18c-17.65 0-32 14.76-32 32.89S134.35 208 152 208h48a48 48 0 0 0 0-96Zm0 80h-48c-8.82 0-16-7.58-16-16.89s7.18-16.89 16-16.89a15 15 0 0 1 5.78 1.14 8 8 0 0 0 10.87-5.81A32 32 0 1 1 200 192ZM24 160v16a8 8 0 0 1-16 0v-16a104 104 0 0 1 176.5-74.56 8 8 0 0 1-11.15 11.47A88 88 0 0 0 24 160Zm32 0v16a8 8 0 0 1-16 0v-16a72 72 0 0 1 114.6-58 8 8 0 1 1-9.47 12.89A56 56 0 0 0 56 160Zm61.57-23.35A24 24 0 0 0 88 160v16a8 8 0 0 1-16 0v-16a40 40 0 0 1 49.27-38.92 8 8 0 1 1-3.7 15.57Z" /></Svg>;
export default RainbowCloud;