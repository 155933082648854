/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hoodie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hoodie-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hoodie-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m234 122.75-54.29-81.41a12 12 0 0 0-10-5.34H86.27a12 12 0 0 0-10 5.34L22 122.75a12 12 0 0 0-1.6 9.82l21.44 78.59A12 12 0 0 0 53.43 220H80a12 12 0 0 0 12-12v-20h72v20a12 12 0 0 0 12 12h26.57a12 12 0 0 0 11.57-8.84l21.44-78.59a12 12 0 0 0-1.58-9.82ZM80 180a4 4 0 0 1-4-4V62l32 18.86V136a4 4 0 0 0 8 0V85.57l10 5.88a4 4 0 0 0 4.06 0l10-5.88V128a4 4 0 0 0 8 0V80.85L180 62v114a4 4 0 0 1-4 4Zm2.94-134.22A4 4 0 0 1 86.27 44h83.46a4 4 0 0 1 3.33 1.78l5.27 7.91L128 83.36 77.67 53.69ZM84 208a4 4 0 0 1-4 4H53.43a4 4 0 0 1-3.85-2.95l-21.44-78.59a4 4 0 0 1 .53-3.27L68 68.2V176a12 12 0 0 0 12 12h4Zm143.86-77.54-21.44 78.59a4 4 0 0 1-3.85 2.95H176a4 4 0 0 1-4-4v-20h4a12 12 0 0 0 12-12V68.2l39.33 59a4 4 0 0 1 .53 3.26Z" /></Svg>;
export default Hoodie;