/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SubsetOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subset-of-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subset-of-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-32 152H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Zm-64-48h64a8 8 0 0 1 0 16h-64a40 40 0 0 1 0-80h64a8 8 0 0 1 0 16h-64a24 24 0 0 0 0 48Z" /></Svg>;
export default SubsetOf;