/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPin = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.91 82.79 173.22 22.1a14 14 0 0 0-19.81 0L98.93 76.77c-9.52-3.25-34-8.34-59.71 12.41A14 14 0 0 0 38.1 110l49.71 49.71-44.05 44a6 6 0 1 0 8.48 8.48l44.05-44.05L146 217.89a14 14 0 0 0 9.9 4.11h1a14 14 0 0 0 10.19-5.54c19.72-26.21 17.15-47.23 12.46-59.3l54.37-54.55a14 14 0 0 0-.01-19.82Zm-8.49 11.31-57.27 57.46a6 6 0 0 0-1.11 6.92c9.94 19.88-1.71 40.32-9.54 50.72a2 2 0 0 1-3 .2L46.58 101.51a2 2 0 0 1 .18-3c12.5-10.09 24.5-12.76 33.7-12.76a42.13 42.13 0 0 1 17.25 3.41 6 6 0 0 0 6.93-1.16l57.26-57.41a2 2 0 0 1 2.83 0l60.69 60.68a2 2 0 0 1 0 2.83Z" /></Svg>;
export default PushPin;