/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartPolar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-polar-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-polar-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 199.54v27.74a4 4 0 0 1-4.46 4 104.22 104.22 0 0 1-90.8-90.8 4 4 0 0 1 4-4.46h27.72A72.11 72.11 0 0 0 120 199.54ZM183.42 136H136v47.42A56.11 56.11 0 0 0 183.42 136ZM136 72.58V120h47.42A56.11 56.11 0 0 0 136 72.58ZM227.28 136h-27.74A72.11 72.11 0 0 1 136 199.54v27.74a4 4 0 0 0 4.46 4 104.22 104.22 0 0 0 90.8-90.8 4 4 0 0 0-3.98-4.48Zm-27.74-16h27.74a4 4 0 0 0 4-4.46 104.22 104.22 0 0 0-90.8-90.8 4 4 0 0 0-4.46 4v27.72A72.11 72.11 0 0 1 199.54 120Zm-84-95.26a104.22 104.22 0 0 0-90.8 90.8 4 4 0 0 0 4 4.46h27.72A72.11 72.11 0 0 1 120 56.46V28.72a4 4 0 0 0-4.46-3.98ZM72.58 120H120V72.58A56.11 56.11 0 0 0 72.58 120ZM120 183.42V136H72.58A56.11 56.11 0 0 0 120 183.42Z" /></Svg>;
export default ChartPolar;