/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Moon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="moon-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-moon-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.72 145.06a4 4 0 0 0-4-1A92.08 92.08 0 0 1 111.94 29.27a4 4 0 0 0-5-5 100.78 100.78 0 0 0-50.86 35.61 100 100 0 0 0 140 140 100.78 100.78 0 0 0 35.59-50.87 4 4 0 0 0-.95-3.95Zm-39.42 48.47A92 92 0 0 1 62.47 64.7a93 93 0 0 1 39.88-30.35 100.09 100.09 0 0 0 119.3 119.3 93 93 0 0 1-30.35 39.88Z" /></Svg>;
export default Moon;