/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Beanie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beanie-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beanie-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 160v-16a100.17 100.17 0 0 0-70.52-95.56 32 32 0 1 0-59 0A100.17 100.17 0 0 0 28 144v16a20 20 0 0 0-8 16v32a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20v-32a20 20 0 0 0-8-16ZM120 36a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm8 32a76.08 76.08 0 0 1 76 76v12H52v-12a76.08 76.08 0 0 1 76-76Zm-12 112v24H84v-24Zm24 0h32v24h-32Zm-96 0h16v24H44Zm168 24h-16v-24h16Z" /></Svg>;
export default Beanie;