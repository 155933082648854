/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CraneTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crane-tower-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crane-tower-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M127.73 208H112v-44a4 4 0 0 0-4-4H44a4 4 0 0 0-4 4v44H24.27a8.17 8.17 0 0 0-8.27 7.47 8 8 0 0 0 8 8.53h104a8 8 0 0 0 8-8.53 8.17 8.17 0 0 0-8.27-7.47Z" /><Path d="M239.73 80H108.94L87.16 36.42A8 8 0 0 0 80 32H48a8 8 0 0 0-8 8v40H24.27A8.17 8.17 0 0 0 16 87.47 8 8 0 0 0 24 96h16v44a4 4 0 0 0 4 4h64a4 4 0 0 0 4-4V96h96v88h-16v-7.73a8.18 8.18 0 0 0-7.47-8.25 8 8 0 0 0-8.53 8v8a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V96h16a8 8 0 0 0 8-8.53 8.17 8.17 0 0 0-8.27-7.47ZM56 80V48h19.06l16 32Z" /></Svg>;
export default CraneTower;