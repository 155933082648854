/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CheckFat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="check-fat-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-check-fat-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m240.47 71.09-24-23.58a12 12 0 0 0-17 0L104 141.83l-39.5-38.32a12 12 0 0 0-17 0l-24 24a12 12 0 0 0 0 17l71.61 72a12 12 0 0 0 17 0L240.49 88.08a12 12 0 0 0-.02-16.99Zm-5.63 11.34-128.41 128.4a4 4 0 0 1-5.65 0l-71.61-72a4 4 0 0 1 0-5.66l24-24A3.94 3.94 0 0 1 56 108a4.11 4.11 0 0 1 2.89 1.21l42.35 41.08a4 4 0 0 0 5.59 0l98.37-97.1a4 4 0 0 1 5.68 0l24 23.58a4 4 0 0 1-.04 5.66Z" /></Svg>;
export default CheckFat;