/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitlabLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gitlab-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gitlab-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.15 117.1 210.25 41a11.94 11.94 0 0 0-22.79-1.11L169.78 88H86.22L68.54 39.87A11.94 11.94 0 0 0 45.75 41l-19.9 76.1a57.19 57.19 0 0 0 22 61l73.27 51.76a11.91 11.91 0 0 0 13.74 0l73.27-51.76a57.19 57.19 0 0 0 22.02-61ZM58 57.5l15.13 41.26a8 8 0 0 0 7.51 5.24h94.72a8 8 0 0 0 7.51-5.24L198 57.5l13.07 50L128 166.21 44.9 107.5Zm-17.32 66.61L114.13 176l-20.72 14.65L57.09 165a41.06 41.06 0 0 1-16.41-40.89Zm87.32 91-20.73-14.65L128 185.8l20.73 14.64ZM198.91 165l-36.32 25.66L141.87 176l73.45-51.9a41.06 41.06 0 0 1-16.41 40.9Z" /></Svg>;
export default GitlabLogo;