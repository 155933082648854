/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Oven = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="oven-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-oven-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm2 174a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2h160a2 2 0 0 1 2 2ZM74 76a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm44 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm44 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm22 30H72a6 6 0 0 0-6 6v72a6 6 0 0 0 6 6h112a6 6 0 0 0 6-6v-72a6 6 0 0 0-6-6Zm-6 72H78v-60h100Z" /></Svg>;
export default Oven;