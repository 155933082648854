/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BluetoothSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-slash-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-slash-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m128 128 64 48-64 48Zm0-96v96l64-48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m221.92 210.62-160-176a8 8 0 0 0-11.84 10.76l70.84 77.93L59.2 169.6a8 8 0 1 0 9.6 12.8L120 144v80a8 8 0 0 0 12.8 6.4l50.83-38.12 26.45 29.1a8 8 0 1 0 11.84-10.76ZM136 208v-64l11.73 8.8 25.08 27.59ZM120 71.63V32a8 8 0 0 1 12.8-6.4l64 48a8 8 0 0 1 0 12.8l-33.53 25.15a8 8 0 0 1-9.6-12.8l25-18.75L136 48v23.63a8 8 0 0 1-16 0Z" /></Svg>;
export default BluetoothSlash;