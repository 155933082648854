/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CableCar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cable-car-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cable-car-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M251.81 29.89a12 12 0 0 0-13.92-9.7l-224 40A12 12 0 0 0 16 84a11.77 11.77 0 0 0 2.12-.19L116 66.33V92H64a36 36 0 0 0-36 36v64a36 36 0 0 0 36 36h128a36 36 0 0 0 36-36v-64a36 36 0 0 0-36-36h-52V62.05l102.11-18.24a12 12 0 0 0 9.7-13.92ZM108 156v-40h40v40Zm-44-40h20v40H52v-28a12 12 0 0 1 12-12Zm128 88H64a12 12 0 0 1-12-12v-12h152v12a12 12 0 0 1-12 12Zm12-76v28h-32v-40h20a12 12 0 0 1 12 12Z" /></Svg>;
export default CableCar;