/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleThrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-throw-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-throw-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 86a30 30 0 1 0-30-30 30 30 0 0 0 30 30Zm0-48a18 18 0 1 1-18 18 18 18 0 0 1 18-18ZM50 96a14 14 0 1 1 14 14 14 14 0 0 1-14-14Zm170.68 13.35a6 6 0 0 1-8.41 1c-1.17-.92-25.06-18.91-62.52.94a214.64 214.64 0 0 1-3.23 32.38l33.32 27.77a6 6 0 0 1 1.85 6.51l-16 48a6 6 0 0 1-11.38-3.8L169 178l-25.49-21.25a141 141 0 0 1-5.86 17.07C124.15 206 100 227.48 65.73 237.75A6.14 6.14 0 0 1 64 238a6 6 0 0 1-1.72-11.75c64.82-19.45 73.42-78.76 75.11-107.41C109 137.65 86.8 142 72.39 142c-11.71 0-18.29-2.86-18.88-3.13a6 6 0 1 1 5-10.92c.21.09 27.6 11.28 74.14-20.35 26.44-18 48.5-19 62.36-16.67 15.37 2.57 24.39 9.7 24.76 10a6 6 0 0 1 .91 8.42Z" /></Svg>;
export default PersonSimpleThrow;