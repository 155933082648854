/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-star-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-star-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32h-24v-8a8 8 0 0 0-16 0v8H88v-8a8 8 0 0 0-16 0v8H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM72 64v-8a8 8 0 0 1 16 0v8a8 8 0 0 1-16 0Zm106.55 60.7-24.46 20.19 7.46 30.11a4 4 0 0 1-1.49 4.17 4.05 4.05 0 0 1-2.39.79 4 4 0 0 1-2-.55L128 163.18l-27.64 16.27a4 4 0 0 1-5.91-4.45l7.46-30.15-24.46-20.15a4 4 0 0 1 2.24-7.08l32.24-2.49 12.4-28.71a4 4 0 0 1 7.34 0l12.4 28.71 32.24 2.49a4 4 0 0 1 2.24 7.08ZM184 64a8 8 0 0 1-16 0v-8a8 8 0 0 1 16 0Z" /></Svg>;
export default CalendarStar;