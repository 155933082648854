/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eject = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eject-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eject-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 152H48a20 20 0 0 0-20 20v20a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20v-20a20 20 0 0 0-20-20Zm-4 36H52v-12h152ZM48.27 136h159.46a20.27 20.27 0 0 0 14.61-34.3l-73.76-76.92a28.51 28.51 0 0 0-41.16 0L33.66 101.7A20.27 20.27 0 0 0 48.27 136Zm76.48-94.61a4.49 4.49 0 0 1 6.5 0L199 112H57Z" /></Svg>;
export default Eject;