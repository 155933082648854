/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DesktopTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="desktop-tower-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-desktop-tower-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 48v160a8 8 0 0 1-8 8h-80a8 8 0 0 1-8-8V48a8 8 0 0 1 8-8h80a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 72a8 8 0 0 1-8 8h-32a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8Zm-8 24h-32a8 8 0 0 0 0 16h32a8 8 0 0 0 0-16Zm40-48v160a16 16 0 0 1-16 16h-80a16 16 0 0 1-16-16v-16H96v16h16a8 8 0 0 1 0 16H64a8 8 0 0 1 0-16h16v-16H32a24 24 0 0 1-24-24V96a24 24 0 0 1 24-24h104V48a16 16 0 0 1 16-16h80a16 16 0 0 1 16 16ZM136 176V88H32a8 8 0 0 0-8 8v72a8 8 0 0 0 8 8Zm96 32V48h-80v160h80Zm-40-40a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z" /></Svg>;
export default DesktopTower;