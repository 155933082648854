/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pentagram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pentagram-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pentagram-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M60.18 129.52a4 4 0 0 1-6.15 2L22.66 109a15.78 15.78 0 0 1-5.82-18 16.33 16.33 0 0 1 15.59-11h38.31a4 4 0 0 1 3.81 5.24Zm-7 73.48A15.75 15.75 0 0 0 59 220.88a15.74 15.74 0 0 0 18.77 0l32.05-23.06a4 4 0 0 0 0-6.5l-38.44-27.6a4 4 0 0 0-6.14 2Zm90.05-183.74a15.93 15.93 0 0 0-30.45-.05L100 58.76a4 4 0 0 0 3.76 5.24h48.48a4 4 0 0 0 3.81-5.23ZM160 80H96a4 4 0 0 0-3.8 2.77l-19.2 59a4 4 0 0 0 1.47 4.48l51.17 36.82a4 4 0 0 0 4.68 0l51.17-36.82a4 4 0 0 0 1.47-4.48l-19.15-59A4 4 0 0 0 160 80Zm79.13 11a16.33 16.33 0 0 0-15.59-11h-38.28a4 4 0 0 0-3.81 5.24l14.37 44.29a4 4 0 0 0 6.14 2l31.41-22.6A15.75 15.75 0 0 0 239.16 91Zm-54.55 72.75-38.4 27.63a4 4 0 0 0 0 6.5l32 23A16 16 0 0 0 202.85 203l-12.09-37.27a4 4 0 0 0-6.15-2.01Z" /></Svg>;
export default Pentagram;