/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyBtc = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-btc__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-btc'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M178.48 115.7A44 44 0 0 0 152 40.19V24a8 8 0 0 0-16 0v16h-16V24a8 8 0 0 0-16 0v16H72a8 8 0 0 0 0 16h8v136h-8a8 8 0 0 0 0 16h32v16a8 8 0 0 0 16 0v-16h16v16a8 8 0 0 0 16 0v-16h8a48 48 0 0 0 18.48-92.3ZM176 84a28 28 0 0 1-28 28H96V56h52a28 28 0 0 1 28 28Zm-16 108H96v-64h64a32 32 0 0 1 0 64Z" /></Svg>;
export default CurrencyBtc;