/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Football = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="football-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-football-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.06 53.89a32.92 32.92 0 0 0-26.95-26.95c-32.38-5.49-93.39-8-138.27 36.9s-42.39 105.9-36.9 138.27a32.92 32.92 0 0 0 27 26.95A206.58 206.58 0 0 0 88.27 232c32.09 0 72.05-8 103.89-39.84 44.89-44.88 42.39-105.9 36.9-138.27ZM56.56 213.3a16.94 16.94 0 0 1-13.86-13.86 180.27 180.27 0 0 1-2.11-46.9l62.87 62.87a180.27 180.27 0 0 1-46.9-2.11Zm109.08-111.63L151.3 116l6.34 6.34a8 8 0 1 1-11.31 11.3l-6.33-6.33L127.31 140l6.34 6.34a8 8 0 1 1-11.3 11.31L116 151.3l-14.34 14.34a8 8 0 1 1-11.31-11.31L104.7 140l-6.34-6.34a8 8 0 0 1 11.31-11.3l6.34 6.34 12.68-12.7-6.34-6.34a8 8 0 0 1 11.3-11.31l6.35 6.35 14.34-14.34a8 8 0 1 1 11.31 11.31Zm49.77 1.79-62.87-62.87c4.76-.44 9.72-.69 14.91-.69a192 192 0 0 1 32 2.8 16.94 16.94 0 0 1 13.85 13.86 180.27 180.27 0 0 1 2.11 46.9Z" /></Svg>;
export default Football;