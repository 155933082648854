/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmReel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-reel__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-reel'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 216h-48.64A103.95 103.95 0 1 0 128 232h104a8 8 0 0 0 0-16ZM40 128a88 88 0 1 1 88 88 88.1 88.1 0 0 1-88-88Zm88-24a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-32a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm24 104a24 24 0 1 0-24 24 24 24 0 0 0 24-24Zm-32 0a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm56-24a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-32a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm-96-16a24 24 0 1 0 24 24 24 24 0 0 0-24-24Zm0 32a8 8 0 1 1 8-8 8 8 0 0 1-8 8Z" /></Svg>;
export default FilmReel;