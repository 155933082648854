/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyMelting = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-melting-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-melting-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 136a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-44-44a16 16 0 1 0-16 16 16 16 0 0 0 16-16Zm67.83-40.9A108 108 0 0 0 47.5 200a12 12 0 0 0 17.89-16 84 84 0 1 1 125.22 0 12 12 0 0 0 17.89 16 108 108 0 0 0-4.67-148.9ZM140 164h-4c-19.81 0-44-16.61-44-36a36.47 36.47 0 0 1 .5-6 12 12 0 0 0-23.67-4 60.67 60.67 0 0 0-.83 10c0 16.1 8 31.46 22.49 43.25C103.58 181.9 120.17 188 136 188h4a8 8 0 0 1 0 16h-40a32 32 0 0 0-32 32 12 12 0 0 0 24 0 8 8 0 0 1 8-8h40a32 32 0 0 0 0-64Z" /></Svg>;
export default SmileyMelting;