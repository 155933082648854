/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fire = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 144a80 80 0 0 1-160 0c0-30.57 14.42-58.26 31-80l33 32 26.27-72C159.86 41.92 208 88.15 208 144Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M183.89 153.34a57.6 57.6 0 0 1-46.56 46.55 8.75 8.75 0 0 1-1.33.11 8 8 0 0 1-1.32-15.89c16.57-2.79 30.63-16.85 33.44-33.45a8 8 0 0 1 15.78 2.68ZM216 144a88 88 0 0 1-176 0c0-27.92 11-56.47 32.66-84.85a8 8 0 0 1 11.93-.89l24.12 23.41 22-60.41a8 8 0 0 1 12.63-3.41C165.21 36 216 84.55 216 144Zm-16 0c0-46.09-35.79-85.92-58.21-106.33l-22.27 61.07a8 8 0 0 1-13.09 3L80.06 76.16C64.09 99.21 56 122 56 144a72 72 0 0 0 144 0Z" /></Svg>;
export default Fire;