/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBucket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-bucket-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-bucket-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 208a16 16 0 0 1-32 0c0-16 16-40 16-40s16 24 16 40Zm-16-76.52-24 8-79.83 79.83a16 16 0 0 1-22.63 0l-84.85-84.85a16 16 0 0 1 0-22.63L116.52 16Zm-93.86-29.62a20 20 0 1 0 0 28.28 20 20 0 0 0 0-28.28Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M238.66 163.56a8 8 0 0 0-13.32 0C223.57 166.23 208 190.09 208 208a24 24 0 0 0 48 0c0-17.91-15.57-41.77-17.34-44.44ZM232 216a8 8 0 0 1-8-8c0-6.8 4-16.32 8-24.08 4 7.76 8 17.34 8 24.08a8 8 0 0 1-8 8Zm2.53-76.93a8 8 0 0 0 3.13-13.24L122.17 10.34a8 8 0 0 0-11.31 0L70.25 51l-24.6-24.66a8 8 0 0 0-11.31 11.32l24.6 24.6L15 106.17a24 24 0 0 0 0 33.94L99.89 225a24 24 0 0 0 33.94 0l78.49-78.49Zm-32.19-5.24-79.83 79.83a8 8 0 0 1-11.31 0L26.34 128.8a8 8 0 0 1 0-11.31l43.91-43.92 29.12 29.12a28 28 0 1 0 11.31-11.32L81.57 62.26l35-34.95L217.19 128l-11.72 3.9a8.09 8.09 0 0 0-3.13 1.93Zm-86.83-26.31a13.26 13.26 0 1 1-.05.06s.05-.05.05-.06Z" /></Svg>;
export default PaintBucket;