/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IntersectThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersect-three-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersect-three-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M191.69 98.3c.2-2.07.31-4.17.31-6.3a64 64 0 0 0-128 0c0 2.13.11 4.23.31 6.3A64 64 0 1 0 128 208.88 64 64 0 1 0 191.69 98.3ZM72 92a56 56 0 0 1 112 0c0 1.07 0 2.13-.1 3.18a63.84 63.84 0 0 0-55.9 7.94 63.84 63.84 0 0 0-55.9-7.94C72 94.13 72 93.07 72 92Zm76 64a55.88 55.88 0 0 1-20 42.85A55.88 55.88 0 0 1 108 156c0-1.07 0-2.13.1-3.18a63.86 63.86 0 0 0 39.8 0c.1 1.05.1 2.11.1 3.18Zm-20-8a55.63 55.63 0 0 1-18.86-3.28A56.06 56.06 0 0 1 128 113.15a56.06 56.06 0 0 1 18.86 31.57A55.63 55.63 0 0 1 128 148Zm-26.3-6.58a56.18 56.18 0 0 1-28.56-38.14 55.87 55.87 0 0 1 48.21 5 64.06 64.06 0 0 0-19.65 33.14Zm33-33.09a55.87 55.87 0 0 1 48.21-5 56.18 56.18 0 0 1-28.56 38.14 64.06 64.06 0 0 0-19.7-33.14ZM92 212a56 56 0 0 1-26.3-105.42 64.26 64.26 0 0 0 34.61 43.12c-.2 2.07-.31 4.17-.31 6.3a63.87 63.87 0 0 0 21.35 47.67A55.71 55.71 0 0 1 92 212Zm72 0a55.71 55.71 0 0 1-29.35-8.33A63.87 63.87 0 0 0 156 156c0-2.13-.11-4.23-.31-6.3a64.26 64.26 0 0 0 34.61-43.12A56 56 0 0 1 164 212Z" /></Svg>;
export default IntersectThree;