/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-star-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-star-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36h-28V24a4 4 0 0 0-8 0v12H84V24a4 4 0 0 0-8 0v12H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12Zm4 172a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h28v12a4 4 0 0 0 8 0V44h88v12a4 4 0 0 0 8 0V44h28a4 4 0 0 1 4 4Zm-35.69-90.38-32.24-2.49-12.4-28.71a4 4 0 0 0-7.34 0l-12.4 28.71-32.24 2.49a4 4 0 0 0-2.24 7.08l24.46 20.19L94.45 175a4 4 0 0 0 5.91 4.41L128 163.18l27.64 16.27a4 4 0 0 0 2 .55 4.05 4.05 0 0 0 2.39-.79 4 4 0 0 0 1.49-4.17l-7.46-30.15 24.46-20.19a4 4 0 0 0-2.24-7.08Zm-29.26 22.71a4 4 0 0 0-1.33 4l5.78 23.36-21.5-12.6a4 4 0 0 0-4.06 0l-21.44 12.64 5.78-23.36a4 4 0 0 0-1.33-4l-18.76-15.53 24.81-1.92a4 4 0 0 0 3.36-2.4L128 98.1l9.68 22.42a4 4 0 0 0 3.36 2.4l24.77 1.92Z" /></Svg>;
export default CalendarStar;