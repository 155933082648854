/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-heart-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-heart-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34h-26V24a6 6 0 0 0-12 0v10H86V24a6 6 0 0 0-12 0v10H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm2 174a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2h26v10a6 6 0 0 0 12 0V46h84v10a6 6 0 0 0 12 0V46h26a2 2 0 0 1 2 2ZM152 90a30 30 0 0 0-24 12 30 30 0 0 0-54 18c0 35.3 49.22 60.32 51.32 61.37a6 6 0 0 0 5.36 0C132.78 180.32 182 155.3 182 120a30 30 0 0 0-30-30Zm-3.67 65.25A138 138 0 0 1 128 169.19a138.82 138.82 0 0 1-20.33-13.94C97.78 147 86 134.15 86 120a18 18 0 0 1 36 0 6 6 0 0 0 12 0 18 18 0 0 1 36 0c0 14.15-11.78 27-21.67 35.25Z" /></Svg>;
export default CalendarHeart;