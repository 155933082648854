/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SwimmingPool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="swimming-pool-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-swimming-pool-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 147.39a6 6 0 0 0 6-6V126h68v17.29a6 6 0 0 0 12 0V32a6 6 0 0 0-12 0v18H94V32a6 6 0 0 0-12 0v109.39a6 6 0 0 0 6 6ZM94 114V94h68v20Zm68-52v20H94V62ZM26 168a6 6 0 0 1 6-6c13.82 0 21 4.79 27.33 9 5.63 3.75 10.48 7 20.67 7s15-3.24 20.67-7c6.33-4.22 13.51-9 27.32-9s21 4.79 27.33 9c5.63 3.75 10.49 7 20.68 7s15-3.24 20.67-7c6.33-4.22 13.51-9 27.33-9a6 6 0 0 1 0 12c-10.18 0-15 3.24-20.67 7-6.34 4.22-13.52 9-27.33 9s-21-4.79-27.33-9c-5.63-3.75-10.49-7-20.68-7s-15 3.24-20.67 7c-6.33 4.22-13.51 9-27.32 9s-21-4.79-27.33-9c-5.67-3.76-10.49-7-20.67-7a6 6 0 0 1-6-6Zm204 40a6 6 0 0 1-6 6c-10.18 0-15 3.24-20.67 7-6.34 4.22-13.52 9-27.33 9s-21-4.79-27.33-9c-5.63-3.75-10.49-7-20.68-7s-15 3.24-20.67 7c-6.33 4.22-13.51 9-27.32 9s-21-4.79-27.33-9c-5.67-3.76-10.49-7-20.67-7a6 6 0 0 1 0-12c13.82 0 21 4.79 27.33 9 5.63 3.75 10.48 7 20.67 7s15-3.24 20.67-7c6.33-4.22 13.51-9 27.32-9s21 4.79 27.33 9c5.63 3.75 10.49 7 20.68 7s15-3.24 20.67-7c6.33-4.22 13.51-9 27.33-9a6 6 0 0 1 6 6Z" /></Svg>;
export default SwimmingPool;