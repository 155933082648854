/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListDashes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-dashes-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-dashes-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M90 64a6 6 0 0 1 6-6h120a6 6 0 0 1 0 12H96a6 6 0 0 1-6-6Zm126 58H96a6 6 0 0 0 0 12h120a6 6 0 0 0 0-12Zm0 64H96a6 6 0 0 0 0 12h120a6 6 0 0 0 0-12ZM56 58H40a6 6 0 0 0 0 12h16a6 6 0 0 0 0-12Zm0 64H40a6 6 0 0 0 0 12h16a6 6 0 0 0 0-12Zm0 64H40a6 6 0 0 0 0 12h16a6 6 0 0 0 0-12Z" /></Svg>;
export default ListDashes;