/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CheckFat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="check-fat-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-check-fat-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m241.87 69.66-24-23.56a14 14 0 0 0-19.77 0L104 139l-38.1-36.9a14 14 0 0 0-19.8 0l-24 24a14 14 0 0 0 0 19.79l71.62 72a14 14 0 0 0 19.8 0L241.91 89.5a14 14 0 0 0-.04-19.84ZM233.42 81 105 209.41a2 2 0 0 1-2.81 0l-71.62-72a2 2 0 0 1 0-2.82l24-24A2 2 0 0 1 56 110a2.12 2.12 0 0 1 1.5.64l42.35 41.08a6 6 0 0 0 8.39 0l98.37-97.11a2 2 0 0 1 2.87 0l24 23.56a2 2 0 0 1-.06 2.83Z" /></Svg>;
export default CheckFat;