/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Resize = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="resize-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-resize-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 114H48a6 6 0 0 0-6 6v88a6 6 0 0 0 6 6h88a6 6 0 0 0 6-6v-88a6 6 0 0 0-6-6Zm-6 88H54v-76h76Zm84-18v16a14 14 0 0 1-14 14h-24a6 6 0 0 1 0-12h24a2 2 0 0 0 2-2v-16a6 6 0 0 1 12 0Zm0-72v32a6 6 0 0 1-12 0v-32a6 6 0 0 1 12 0Zm0-56v16a6 6 0 0 1-12 0V56a2 2 0 0 0-2-2h-16a6 6 0 0 1 0-12h16a14 14 0 0 1 14 14Zm-64-8a6 6 0 0 1-6 6h-32a6 6 0 0 1 0-12h32a6 6 0 0 1 6 6ZM42 80V56a14 14 0 0 1 14-14h16a6 6 0 0 1 0 12H56a2 2 0 0 0-2 2v24a6 6 0 0 1-12 0Z" /></Svg>;
export default Resize;