/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Layout = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="layout-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-layout-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 36H40a20 20 0 0 0-20 20v144a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20Zm-4 24v32H44V60ZM44 116h48v80H44Zm72 80v-80h96v80Z" /></Svg>;
export default Layout;