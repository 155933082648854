/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SolarPanel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="solar-panel-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-solar-panel-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 100a12 12 0 0 1 12-12h8a12 12 0 0 1 0 24h-8a12 12 0 0 1-12-12Zm96-64a12 12 0 0 0 12-12v-8a12 12 0 0 0-24 0v8a12 12 0 0 0 12 12ZM65.77 54.74a12 12 0 1 0 17-17l-5.66-5.65a12 12 0 0 0-17 17Zm116 3.52a12 12 0 0 0 8.49-3.52l5.65-5.65a12 12 0 0 0-17-17l-5.66 5.65a12 12 0 0 0 8.49 20.49ZM192 100a12 12 0 0 0 12 12h8a12 12 0 0 0 0-24h-8a12 12 0 0 0-12 12ZM88 112a12 12 0 0 0 12-12 28 28 0 0 1 56 0 12 12 0 0 0 24 0 52 52 0 0 0-104 0 12 12 0 0 0 12 12Zm154.36 110.05A12 12 0 0 1 232 228H24a12 12 0 0 1-10.45-17.9l43-76A12 12 0 0 1 67 128h122a12 12 0 0 1 10.45 6.1l43 76a12 12 0 0 1-.09 11.95ZM182 152h-42v12h48.82Zm-108 0-6.78 12H116v-12Zm-29.43 52H116v-16H53.61Zm166.86 0-9-16H140v16Z" /></Svg>;
export default SolarPanel;