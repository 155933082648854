/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FourK = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="four-k-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-four-k-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M26 48a6 6 0 0 1 6-6h192a6 6 0 0 1 0 12H32a6 6 0 0 1-6-6Zm198 154H32a6 6 0 0 0 0 12h192a6 6 0 0 0 0-12ZM144 74a6 6 0 0 0-6 6v96a6 6 0 0 0 12 0v-29.75l15.42-17.62 29.4 50.37a6 6 0 0 0 5.19 3 5.91 5.91 0 0 0 3-.82 6 6 0 0 0 2.16-8.2l-31.41-53.88L204.52 84a6 6 0 0 0-9-7.9L150 128V80a6 6 0 0 0-6-6ZM90 176v-18H40a6 6 0 0 1-4.74-9.68l56-72A6 6 0 0 1 102 80v66h10a6 6 0 0 1 0 12h-10v18a6 6 0 0 1-12 0Zm0-30V97.49L52.27 146Z" /></Svg>;
export default FourK;