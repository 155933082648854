/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSuperscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-superscript-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-superscript-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 144a4 4 0 0 1-4 4h-48a4 4 0 0 1-3.2-6.4L232 84.05a20 20 0 0 0-18.78-31.85 20 20 0 0 0-16.08 13.14 4 4 0 1 1-7.55-2.67 28.34 28.34 0 0 1 4-7.52 28 28 0 1 1 44.72 33.7L200 140h40a4 4 0 0 1 4 4Zm-97.38-67a4 4 0 0 0-5.64.4L92 133.89 43 77.38a4 4 0 0 0-6 5.24L86.71 140 37 197.38a4 4 0 1 0 6 5.24l49-56.51 49 56.51a4 4 0 0 0 6-5.24L97.29 140 147 82.62a4 4 0 0 0-.38-5.62Z" /></Svg>;
export default TextSuperscript;