/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PawPrint = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paw-print__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paw-print'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 80a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 40a12 12 0 1 1 12-12 12 12 0 0 1-12 12ZM72 108a28 28 0 1 0-28 28 28 28 0 0 0 28-28Zm-28 12a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm48-32a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0-40a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm72 40a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0-40a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm23.12 100.86a35.3 35.3 0 0 1-16.87-21.14 44 44 0 0 0-84.5 0A35.25 35.25 0 0 1 69 148.82 40 40 0 0 0 88 224a39.48 39.48 0 0 0 15.52-3.13 64.09 64.09 0 0 1 48.87 0 40 40 0 0 0 34.73-72ZM168 208a24 24 0 0 1-9.45-1.93 80.14 80.14 0 0 0-61.19 0 24 24 0 0 1-20.71-43.26 51.22 51.22 0 0 0 24.46-30.67 28 28 0 0 1 53.78 0 51.27 51.27 0 0 0 24.53 30.71A24 24 0 0 1 168 208Z" /></Svg>;
export default PawPrint;