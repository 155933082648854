/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyes-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyes-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 32c-20.61 0-38.28 18.16-48 45.85C118.28 50.16 100.61 32 80 32c-31.4 0-56 42.17-56 96s24.6 96 56 96c20.61 0 38.28-18.16 48-45.85 9.72 27.69 27.39 45.85 48 45.85 31.4 0 56-42.17 56-96s-24.6-96-56-96Zm-69.08 154.39C99.43 200.12 89.62 208 80 208s-19.43-7.88-26.92-21.61a104.81 104.81 0 0 1-10.24-29.23 32 32 0 1 0 0-58.32 104.81 104.81 0 0 1 10.24-29.23C60.57 55.88 70.38 48 80 48s19.43 7.88 26.92 21.61C115.35 85.07 120 105.81 120 128s-4.65 42.93-13.08 58.39Zm96 0C195.43 200.12 185.62 208 176 208s-19.43-7.88-26.92-21.61a104.81 104.81 0 0 1-10.24-29.23 32 32 0 1 0 0-58.32 104.81 104.81 0 0 1 10.24-29.23C156.57 55.88 166.38 48 176 48s19.43 7.88 26.92 21.61C211.35 85.07 216 105.81 216 128s-4.65 42.93-13.08 58.39Z" /></Svg>;
export default Eyes;