/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fingerprint = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fingerprint__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fingerprint'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M72 128a134.63 134.63 0 0 1-14.16 60.47 8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2 8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128Zm56-8a8 8 0 0 0-8 8 184.12 184.12 0 0 1-23 89.1 8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8Zm0-32a40 40 0 0 0-40 40 8 8 0 0 0 16 0 24 24 0 0 1 48 0 214.09 214.09 0 0 1-20.51 92 8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40Zm0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33 8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0 282.24 282.24 0 0 1-5.29 54.45 8 8 0 0 0 6.3 9.4 8.22 8.22 0 0 0 1.55.15 8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128 104.12 104.12 0 0 0 128 24ZM94.4 152.17a8 8 0 0 0-9.4 6.25 151 151 0 0 1-17.21 45.44 8 8 0 0 0 13.86 8 166.67 166.67 0 0 0 19-50.25 8 8 0 0 0-6.25-9.44ZM128 56a72.85 72.85 0 0 0-9 .56 8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31 8 8 0 0 0 6.92 9 8.39 8.39 0 0 0 1 .06 8 8 0 0 0 7.92-7 266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56Zm57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13 7.88 7.88 0 0 0 2.55.42 8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z" /></Svg>;
export default Fingerprint;