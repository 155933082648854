/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ghost = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ghost-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ghost-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a96.11 96.11 0 0 0-96 96v96a8 8 0 0 0 13.07 6.19l24.26-19.85 24.27 19.85a8 8 0 0 0 10.13 0L128 202.34l24.27 19.85a8 8 0 0 0 10.13 0l24.27-19.85 24.26 19.85A8 8 0 0 0 224 216v-96a96.11 96.11 0 0 0-96-96Zm-28 104a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm56 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default Ghost;