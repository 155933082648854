/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlowArrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flow-arrow-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flow-arrow-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m248.49 71.51-32-32a12 12 0 0 0-17 17L211 68h-3c-52 0-64.8 30.71-75.08 55.38-8.82 21.17-15.45 37.05-42.75 40.09a44 44 0 1 0 .28 24.08c43.34-3.87 55.07-32 64.63-54.93C164.9 109 172 92 208 92h3l-11.52 11.51a12 12 0 0 0 17 17l32-32a12 12 0 0 0 .01-17ZM48 196a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default FlowArrow;