/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PingPong = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ping-pong-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ping-pong-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 196a16 16 0 0 0-6.65-11.7l-38-27.15 17-16.95a39.67 39.67 0 0 0 11-35.79 99.52 99.52 0 0 0-35.4-57.89A101.93 101.93 0 0 0 122.58 24 100.29 100.29 0 0 0 24 122.58a102.12 102.12 0 0 0 22.55 65.28 99.52 99.52 0 0 0 57.89 35.4 39.68 39.68 0 0 0 35.79-11l16.95-17 27.15 38A16 16 0 0 0 196 240c.44 0 .88.05 1.32.05a16 16 0 0 0 11.31-4.69l26.64-26.64A16 16 0 0 0 240 196Zm-42.6 28-32.63-45.69a8 8 0 0 0-5.85-3.32h-.66a8 8 0 0 0-5.66 2.34l-23.63 23.63a23.68 23.68 0 0 1-21.36 6.63 80.3 80.3 0 0 1-12.3-3.5l108.8-108.8a80.63 80.63 0 0 1 3.5 12.3 23.67 23.67 0 0 1-6.63 21.36l-23.68 23.6a8 8 0 0 0 1 12.17l45.7 32.64Z" /></Svg>;
export default PingPong;