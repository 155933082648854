/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugCharging = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plug-charging-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plug-charging-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 58h-50V16a6 6 0 0 0-12 0v42H94V16a6 6 0 0 0-12 0v42H32.55A6.1 6.1 0 0 0 26 64a6 6 0 0 0 6 6h18v90a38 38 0 0 0 38 38h34v42a6 6 0 0 0 12 0v-42h34a38 38 0 0 0 38-38V70h18a6 6 0 0 0 0-12Zm-30 102a26 26 0 0 1-26 26H88a26 26 0 0 1-26-26V70h132Zm-86.93-28.58a6 6 0 0 1-.69-5.53l12-32a6 6 0 1 1 11.24 4.22l-9 23.89H144a6 6 0 0 1 5.62 8.11l-12 32a6 6 0 0 1-11.24-4.22l9-23.89H112a6 6 0 0 1-4.93-2.58Z" /></Svg>;
export default PlugCharging;