/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MaskHappy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mask-happy-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mask-happy-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 48v55.77C216 174.6 176.6 232 128 232s-88-57.4-88-128.21V48a8 8 0 0 1 10.89-7.47C66 46.41 95.11 55.71 128 55.71s62-9.3 77.11-15.16A8 8 0 0 1 216 48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M217 34.8a15.94 15.94 0 0 0-14.82-1.71c-14.03 5.46-42.36 14.62-74.18 14.62s-60.16-9.16-74.21-14.62A16 16 0 0 0 32 48v55.77c0 35.84 9.65 69.65 27.18 95.18 18.16 26.46 42.6 41 68.82 41s50.66-14.57 68.82-41c17.53-25.51 27.18-59.32 27.18-95.16V48a16 16 0 0 0-7-13.2Zm-9 69c0 32.64-8.66 63.23-24.37 86.13C168.54 211.9 148.79 224 128 224s-40.54-12.1-55.63-34.08C56.66 167 48 136.43 48 103.79V48c15.11 5.87 45.58 15.71 80 15.71s64.9-9.84 80-15.71v55.81Zm-18 18.87a8 8 0 1 1-12 10.66c-2.68-3-8.85-5.33-14-5.33s-11.36 2.34-14 5.33a8 8 0 1 1-12-10.67c5.71-6.38 16.14-10.66 26-10.66s20.25 4.28 26 10.66ZM92 128c-5.19 0-11.36 2.34-14 5.33a8 8 0 1 1-12-10.67C71.75 116.28 82.18 112 92 112s20.25 4.28 26 10.66a8 8 0 1 1-12 10.67c-2.64-2.99-8.81-5.33-14-5.33Zm76.45 45.19a52.9 52.9 0 0 1-80.9 0 8 8 0 1 1 12.17-10.39 36.89 36.89 0 0 0 56.56 0 8 8 0 0 1 12.17 10.39Z" /></Svg>;
export default MaskHappy;