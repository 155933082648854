/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Heartbeat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heartbeat-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heartbeat-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M72 142H32a6 6 0 0 1 0-12h36.79L83 108.67a6 6 0 0 1 10 0l27 40.51 11-16.51a6 6 0 0 1 5-2.67h24a6 6 0 0 1 0 12h-20.79L125 163.33a6 6 0 0 1-10 0l-27-40.51-11 16.51a6 6 0 0 1-5 2.67ZM178 42c-21 0-39.26 9.47-50 25.34C117.26 51.47 99 42 78 42a60.07 60.07 0 0 0-60 60v2.19a6 6 0 0 0 12-.38V102a48.05 48.05 0 0 1 48-48c20.28 0 37.31 10.83 44.45 28.27a6 6 0 0 0 11.1 0C140.69 64.83 157.72 54 178 54a48.05 48.05 0 0 1 48 48c0 55.73-81.61 105.65-98 115.11-9.84-5.66-43.09-25.82-68.16-53.16a6 6 0 1 0-8.84 8.1c30.94 33.77 72.41 56.29 74.16 57.23a6 6 0 0 0 5.68 0 334.68 334.68 0 0 0 53.06-37C219.8 161.59 238 131.2 238 102a60.07 60.07 0 0 0-60-60Z" /></Svg>;
export default Heartbeat;