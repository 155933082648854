/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pants = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pants-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pants-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m221.89 214.26-22-176A14 14 0 0 0 186 26H70a14 14 0 0 0-13.89 12.26l-22 176A14 14 0 0 0 48 230h40.69a14 14 0 0 0 13.57-10.56L128 120l25.73 99.44A14 14 0 0 0 167.31 230H208a14 14 0 0 0 13.89-15.74Zm-26.67-116.6A34.07 34.07 0 0 1 166.54 70h25.23ZM70 38h116a2 2 0 0 1 2 1.75L190.27 58H65.73L68 39.75A2 2 0 0 1 70 38Zm-5.77 32h25.23a34.07 34.07 0 0 1-28.68 27.66Zm26.4 146.49a2 2 0 0 1-1.94 1.51H48a2 2 0 0 1-2-2.25l13.25-105.88A46.07 46.07 0 0 0 101.6 70H122v25.24Zm118.87.83a2 2 0 0 1-1.5.68h-40.69a2 2 0 0 1-1.95-1.56L134 95.24V70h20.4a46.07 46.07 0 0 0 42.35 39.87L210 215.75a2 2 0 0 1-.5 1.57Z" /></Svg>;
export default Pants;