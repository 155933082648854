/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagPennant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-pennant-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-pennant-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m242 98.33-184-64A6 6 0 0 0 50 40v176a6 6 0 0 0 12 0v-43.73l180-62.6a6 6 0 0 0 0-11.34ZM62 159.56V48.44L221.74 104Z" /></Svg>;
export default FlagPennant;