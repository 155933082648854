/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Flower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flower-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flower-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208.35 132.82a50.92 50.92 0 0 0-12.59-4.82 50.92 50.92 0 0 0 12.59-4.82 36 36 0 0 0-36-62.36 51.54 51.54 0 0 0-10.47 8.5A51.27 51.27 0 0 0 164 56a36 36 0 0 0-72 0 51.27 51.27 0 0 0 2.12 13.32 51.54 51.54 0 0 0-10.47-8.5 36 36 0 1 0-36 62.36A50.92 50.92 0 0 0 60.24 128a50.92 50.92 0 0 0-12.59 4.82 36 36 0 1 0 36 62.36 51.54 51.54 0 0 0 10.47-8.5A51.27 51.27 0 0 0 92 200a36 36 0 0 0 72 0 51.27 51.27 0 0 0-2.12-13.32 51.54 51.54 0 0 0 10.47 8.5 35.85 35.85 0 0 0 18 4.84 36.24 36.24 0 0 0 9.37-1.25 36 36 0 0 0 8.68-66Zm-32-65.07a28 28 0 0 1 28 48.5c-6.95 4-19.82 6.66-37.44 7.74l-3.16-.17a36 36 0 0 0-14.26-24.68c.49-1 1-1.9 1.44-2.84 9.74-14.71 18.47-24.53 25.42-28.55ZM128 156a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm0-128a28 28 0 0 1 28 28c0 8-4.14 20.5-12 36.3-.58.87-1.15 1.75-1.73 2.65a35.94 35.94 0 0 0-28.52 0c-.58-.9-1.15-1.78-1.73-2.65C104.14 76.5 100 64 100 56a28 28 0 0 1 28-28Zm-76.35 88.25a28 28 0 1 1 28-48.5c6.95 4 15.68 13.84 25.42 28.55.47.94 1 1.88 1.44 2.84a36 36 0 0 0-14.26 24.68l-3.16.17c-17.62-1.08-30.49-3.73-37.44-7.74Zm28 72a28 28 0 1 1-28-48.5c7-4 19.82-6.66 37.44-7.74l3.16.17a36 36 0 0 0 14.26 24.68c-.49 1-1 1.9-1.44 2.84-9.74 14.71-18.47 24.53-25.42 28.55ZM128 228a28 28 0 0 1-28-28c0-8 4.14-20.5 12-36.3.58-.87 1.15-1.75 1.73-2.65a35.94 35.94 0 0 0 28.52 0c.58.9 1.15 1.78 1.73 2.65 7.87 15.8 12 28.27 12 36.3A28 28 0 0 1 128 228Zm86.6-50a28 28 0 0 1-38.25 10.25c-6.95-4-15.68-13.84-25.42-28.55-.47-.94-1-1.88-1.44-2.84a36 36 0 0 0 14.26-24.68l3.16-.17c17.62 1.08 30.49 3.73 37.44 7.74A28 28 0 0 1 214.6 178Z" /></Svg>;
export default Flower;