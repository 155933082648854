/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Check = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="check-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-check-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m226.83 74.83-128 128a4 4 0 0 1-5.66 0l-56-56a4 4 0 0 1 5.66-5.66L96 194.34 221.17 69.17a4 4 0 1 1 5.66 5.66Z" /></Svg>;
export default Check;