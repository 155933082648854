/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsThreeVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-three-vertical-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-three-vertical-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 16H96a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V32a16 16 0 0 0-16-16Zm-32 192a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-68a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-68a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default DotsThreeVertical;