/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotMemberOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-member-of-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-member-of-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216.07 31.12a12 12 0 0 0-16.95.81l-3.7 4.07H128a91.95 91.95 0 0 0-70.2 151.39l-18.68 20.54a12 12 0 1 0 17.76 16.14l18.67-20.53A91.48 91.48 0 0 0 128 220h72a12 12 0 0 0 0-24h-72a67.51 67.51 0 0 1-36.12-10.43L133.31 140H200a12 12 0 0 0 0-24h-44.87l61.75-67.93a12 12 0 0 0-.81-16.95ZM74.13 169.42a67.75 67.75 0 0 1-13-29.42h39.79ZM61.08 116A68.1 68.1 0 0 1 128 60h45.6l-50.91 56Z" /></Svg>;
export default NotMemberOf;