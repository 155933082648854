/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Building = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="building-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-building-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 220h-20V36h4a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24h4v184H24a12 12 0 0 0 0 24h208a12 12 0 0 0 0-24ZM68 36h120v184h-24v-36a12 12 0 0 0-12-12h-48a12 12 0 0 0-12 12v36H68Zm72 184h-24v-24h24ZM84 64a12 12 0 0 1 12-12h12a12 12 0 0 1 0 24H96a12 12 0 0 1-12-12Zm52 0a12 12 0 0 1 12-12h12a12 12 0 0 1 0 24h-12a12 12 0 0 1-12-12Zm-52 40a12 12 0 0 1 12-12h12a12 12 0 0 1 0 24H96a12 12 0 0 1-12-12Zm52 0a12 12 0 0 1 12-12h12a12 12 0 0 1 0 24h-12a12 12 0 0 1-12-12Zm-52 40a12 12 0 0 1 12-12h12a12 12 0 0 1 0 24H96a12 12 0 0 1-12-12Zm52 0a12 12 0 0 1 12-12h12a12 12 0 0 1 0 24h-12a12 12 0 0 1-12-12Z" /></Svg>;
export default Building;