/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PenNib = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pen-nib__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pen-nib'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 92.68a15.86 15.86 0 0 0-4.69-11.31l-68.68-68.69a16 16 0 0 0-22.63 0l-28.43 28.43-58 21.77a16.06 16.06 0 0 0-10.22 12.35L32.11 214.68A8 8 0 0 0 40 224a8.4 8.4 0 0 0 1.32-.11l139.44-23.24a16 16 0 0 0 12.35-10.17l21.77-58L243.31 104A15.87 15.87 0 0 0 248 92.68Zm-69.87 92.19L63.32 204l47.37-47.37a28 28 0 1 0-11.32-11.32L52 192.7 71.13 77.86 126 57.29 198.7 130ZM112 132a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm96-15.32L139.31 48l24-24L232 92.68Z" /></Svg>;
export default PenNib;