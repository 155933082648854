/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Equalizer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="equalizer-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-equalizer-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M78 96a6 6 0 0 1-6 6H24a6 6 0 0 1 0-12h48a6 6 0 0 1 6 6Zm-6 26H24a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm0 32H24a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm0 32H24a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm80-64h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm0 32h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm0 32h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm80-96h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm-48-20h48a6 6 0 0 0 0-12h-48a6 6 0 0 0 0 12Zm48 52h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm0 32h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Zm0 32h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Z" /></Svg>;
export default Equalizer;