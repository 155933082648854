/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ProjectorScreen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="projector-screen-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-projector-screen-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 72V48a20 20 0 0 0-20-20H40a20 20 0 0 0-20 20v24a20 20 0 0 0 16 19.6V164h-4a12 12 0 0 0 0 24h84v23.22a24 24 0 1 0 24 0V188h84a12 12 0 0 0 0-24h-4V91.6A20 20 0 0 0 236 72ZM44 52h168v16H44Zm16 112V92h136v72Z" /></Svg>;
export default ProjectorScreen;