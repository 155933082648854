/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileIni = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-ini__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-ini'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 152v56a8 8 0 0 1-16 0v-56a8 8 0 0 1 16 0Zm72-8a8 8 0 0 0-8 8v31l-25.49-35.65A8 8 0 0 0 72 152v56a8 8 0 0 0 16 0v-31l25.49 35.69A8 8 0 0 0 120 216a7.91 7.91 0 0 0 2.44-.38A8 8 0 0 0 128 208v-56a8 8 0 0 0-8-8Zm40 0a8 8 0 0 0-8 8v56a8 8 0 0 0 16 0v-56a8 8 0 0 0-8-8Zm56-56v136a8 8 0 0 1-16 0V96h-48a8 8 0 0 1-8-8V40H56v72a8 8 0 0 1-16 0V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-56-8h28.69L160 51.31Z" /></Svg>;
export default FileIni;