/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Balloon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="balloon-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-balloon-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 18a86.1 86.1 0 0 0-86 86c0 23 9.24 48.52 24.71 68.27 12.37 15.79 27.23 26.42 43.05 31.07l-11.27 26.3A6 6 0 0 0 104 238h48a6 6 0 0 0 5.51-8.36l-11.27-26.3c15.82-4.65 30.68-15.28 43-31.07C204.76 152.52 214 127 214 104a86.1 86.1 0 0 0-86-86Zm-15.83 186 .58.14a2.05 2.05 0 0 1-.58-.14Zm30.73 22h-29.8l8.7-20.31a62.15 62.15 0 0 0 12.4 0ZM128 194c-33.52 0-74-40.15-74-90a74 74 0 0 1 148 0c0 49.85-40.48 90-74 90Zm49-92.08a6.74 6.74 0 0 1-1 .08 6 6 0 0 1-5.91-5A43.29 43.29 0 0 0 135 61.92a6 6 0 1 1 2-11.84A55.48 55.48 0 0 1 181.92 95a6 6 0 0 1-4.92 6.92Z" /></Svg>;
export default Balloon;