/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MoneyWavy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="money-wavy-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-money-wavy-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M242.12 63.39a4 4 0 0 0-3.88-.2c-44.37 21.68-75.77 11.64-109 1s-67.71-21.67-115 1.42a4 4 0 0 0-2.24 3.6v120a4 4 0 0 0 5.76 3.6c44.37-21.68 75.77-11.64 109-1 18.86 6 38.08 12.19 59.8 12.19 16.61 0 34.69-3.6 55.18-13.61a4 4 0 0 0 2.24-3.6v-120a4 4 0 0 0-1.86-3.4ZM236 184.27c-43.19 20.27-74.1 10.38-106.78-.08-18.86-6-38.08-12.18-59.8-12.18-15 0-31.28 3-49.42 10.94V71.73c43.19-20.27 74.1-10.38 106.78.08C158.7 82 191.67 92.57 236 73.05ZM128 100a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20 20 20 0 0 1-20 20ZM52 96v48a4 4 0 0 1-8 0V96a4 4 0 0 1 8 0Zm152 64v-48a4 4 0 0 1 8 0v48a4 4 0 0 1-8 0Z" /></Svg>;
export default MoneyWavy;