/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookOpenUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="book-open-user-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-book-open-user-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 80v120a8 8 0 0 1-8 8h-72a24 24 0 0 0-24 23.94 7.9 7.9 0 0 1-5.12 7.55A8 8 0 0 1 120 232a24 24 0 0 0-24-24H24a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8h64a32 32 0 0 1 32 32v63.73a8.17 8.17 0 0 0 7.47 8.25 8 8 0 0 0 8.53-8V104a32 32 0 0 1 32-32h64a8 8 0 0 1 8 8ZM88.81 56H89a47.92 47.92 0 0 1 36 17.4 4 4 0 0 0 6.08 0A47.92 47.92 0 0 1 167 56h.19a4 4 0 0 0 3.54-5.84 48 48 0 0 0-85.46 0A4 4 0 0 0 88.81 56Z" /></Svg>;
export default BookOpenUser;