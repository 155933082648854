/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Baby = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 136a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm72-16a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm-10.13 44.62a49 49 0 0 1-51.74 0 4 4 0 0 0-4.26 6.76 57 57 0 0 0 60.26 0 4 4 0 1 0-4.26-6.76ZM228 128A100 100 0 1 1 128 28a100.11 100.11 0 0 1 100 100Zm-8 0a92.11 92.11 0 0 0-90.06-92C116.26 54.07 116 71.83 116 72a12 12 0 0 0 24 0 4 4 0 0 1 8 0 20 20 0 0 1-40 0c0-.78.16-17.31 12-35.64A92 92 0 1 0 220 128Z" /></Svg>;
export default Baby;