/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandbagSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="handbag-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-handbag-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m235.92 198.59-14.26-120a12 12 0 0 0-12-10.59H172v-4a44 44 0 0 0-88 0v4H46.33a12 12 0 0 0-12 10.59l-14.26 120a12 12 0 0 0 2.93 9.35 12.11 12.11 0 0 0 9.08 4.06h191.84a12.11 12.11 0 0 0 9.06-4.06 12 12 0 0 0 2.94-9.35ZM92 64a36 36 0 0 1 72 0v4H92Zm135 138.63a4.08 4.08 0 0 1-3.08 1.37H32.08a4.08 4.08 0 0 1-3.08-1.37 3.9 3.9 0 0 1-1-3.09l14.25-120a4 4 0 0 1 4-3.54h163.42a4 4 0 0 1 4.05 3.54l14.25 120a3.9 3.9 0 0 1-.97 3.09Z" /></Svg>;
export default HandbagSimple;