/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeStand = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-stand-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-stand-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 176a72 72 0 1 0-72-72 72.08 72.08 0 0 0 72 72Zm0-120a48 48 0 1 1-48 48 48.05 48.05 0 0 1 48-48Zm12 159.35V228h20a12 12 0 0 1 0 24h-64a12 12 0 0 1 0-24h20v-12.64A112 112 0 0 1 53.88 27.84a12 12 0 1 1 17.59 16.32 88 88 0 0 0 124.37 124.37 12 12 0 1 1 16.32 17.59A111.51 111.51 0 0 1 148 215.35Z" /></Svg>;
export default GlobeStand;