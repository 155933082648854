/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Disc = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="disc-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-disc-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188.3 43.31a8 8 0 0 0-.65-.5c-.23-.16-.47-.31-.71-.45a103.85 103.85 0 1 0 1.36 1ZM128 152a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm88-24c0 2.47-.11 4.92-.31 7.34L168 126.92a39.83 39.83 0 0 0-11-26.41l27.78-39.67A87.8 87.8 0 0 1 216 128Z" /></Svg>;
export default Disc;