/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Strategy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="strategy-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-strategy-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M76 154a34 34 0 1 0 34 34 34 34 0 0 0-34-34Zm0 56a22 22 0 1 1 22-22 22 22 0 0 1-22 22ZM43.76 107.76 59.52 92 43.76 76.24a6 6 0 0 1 8.48-8.48L68 83.52l15.76-15.76a6 6 0 0 1 8.48 8.48L76.48 92l15.76 15.76a6 6 0 1 1-8.48 8.48L68 100.48l-15.76 15.76a6 6 0 0 1-8.48-8.48Zm184.48 96a6 6 0 1 1-8.48 8.48L204 196.48l-15.76 15.76a6 6 0 0 1-8.48-8.48L195.52 188l-15.76-15.76a6 6 0 0 1 8.48-8.48L204 179.52l15.76-15.76a6 6 0 0 1 8.48 8.48L212.48 188Zm-45.69-91.46c-6 21.66-24.55 40.38-45.09 45.52a6.14 6.14 0 0 1-1.46.18 6 6 0 0 1-1.46-11.82c16.29-4.07 31.62-19.67 36.44-37.09 3.33-12 3.39-30.24-15.22-48.85L150 54.48V80a6 6 0 0 1-12 0V40a6 6 0 0 1 6-6h40a6 6 0 0 1 0 12h-25.52l5.76 5.76C182 69.47 188.45 91 182.55 112.3Z" /></Svg>;
export default Strategy;