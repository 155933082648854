/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l23.7 26.07C50.51 94.93 42 119.77 42 144a86 86 0 0 0 147.28 60.33L203.56 220a6 6 0 0 0 8.88-8.08ZM128 218a74.09 74.09 0 0 1-74-74c0-21 7.27-42.76 21.59-64.73l105.59 116.15A73.81 73.81 0 0 1 128 218ZM91.4 49.07a6 6 0 0 1-.2-8.49 245.6 245.6 0 0 1 33.36-29.49 6 6 0 0 1 6.88 0 251.42 251.42 0 0 1 41.09 38C199.66 80.26 214 113.09 214 144a86.2 86.2 0 0 1-3.08 22.87 6 6 0 0 1-5.78 4.41 6.2 6.2 0 0 1-1.59-.21 6 6 0 0 1-4.2-7.38A74 74 0 0 0 202 144c0-59.63-59-108.94-74-120.52a253.83 253.83 0 0 0-28.12 25.38 6 6 0 0 1-8.48.21Z" /></Svg>;
export default DropSlash;