/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BugDroid = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bug-droid-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bug-droid-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m189 51.47 15.22-15.23a6 6 0 1 0-8.48-8.48L180 43.54a85.76 85.76 0 0 0-104 0L60.24 27.76a6 6 0 0 0-8.48 8.48L67 51.47A85.7 85.7 0 0 0 42 112v40a86 86 0 0 0 172 0v-40a85.7 85.7 0 0 0-25-60.53ZM128 38a74.09 74.09 0 0 1 74 74v10H54v-10a74.09 74.09 0 0 1 74-74Zm0 188a74.09 74.09 0 0 1-74-74v-18h148v18a74.09 74.09 0 0 1-74 74Zm18-134a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm-56 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Z" /></Svg>;
export default BugDroid;