/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RewindCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rewind-circle-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rewind-circle-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 192a92 92 0 1 1 92-92 92.1 92.1 0 0 1-92 92ZM117.79 88.42a4 4 0 0 0-4.19.38l-48 36a4 4 0 0 0 0 6.4l48 36a4 4 0 0 0 2.4.8 4.05 4.05 0 0 0 1.79-.42A4 4 0 0 0 120 164V92a4 4 0 0 0-2.21-3.58ZM112 156l-37.33-28L112 100Zm61.79-67.58a4 4 0 0 0-4.19.38l-48 36a4 4 0 0 0 0 6.4l48 36a4 4 0 0 0 2.4.8 4.05 4.05 0 0 0 1.79-.42A4 4 0 0 0 176 164V92a4 4 0 0 0-2.21-3.58ZM168 156l-37.33-28L168 100Z" /></Svg>;
export default RewindCircle;