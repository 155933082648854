/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dress = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dress-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dress-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M66.26 80.23a15.26 15.26 0 0 1-1.65-12.17 15.54 15.54 0 0 1 2-4.76L88 32.7V8a8 8 0 0 1 8.53-8A8.17 8.17 0 0 1 104 8.27v24.15l5.25 6.58a23.91 23.91 0 0 0 19.13 9 24.67 24.67 0 0 0 18.71-9.43l4.91-6.15V8a8 8 0 0 1 8.53-8A8.17 8.17 0 0 1 168 8.27V32.7l21.42 30.6a15.54 15.54 0 0 1 2 4.76 15.26 15.26 0 0 1-1.65 12.17 1.74 1.74 0 0 0-.11.18l-13.86 21.74a4 4 0 0 1-3.4 1.85H83.6a4 4 0 0 1-3.37-1.85L66.37 80.41a1.74 1.74 0 0 0-.11-.18Zm148.5 129.56a2.52 2.52 0 0 0-.15-.34l-40.92-87.15a4 4 0 0 0-3.63-2.3H85.94a4 4 0 0 0-3.63 2.3l-40.92 87.15a2.52 2.52 0 0 0-.15.34A16.19 16.19 0 0 0 41.6 223a16 16 0 0 0 14.4 9h144a16 16 0 0 0 14.39-9 16.19 16.19 0 0 0 .37-13.21Z" /></Svg>;
export default Dress;