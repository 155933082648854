/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cylinder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cylinder-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cylinder-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M179.14 24.08C165.28 16.29 147.12 12 128 12s-37.28 4.29-51.14 12.08C60.83 33.1 52 45.86 52 60v136c0 14.14 8.83 26.9 24.86 35.92C90.72 239.71 108.88 244 128 244s37.28-4.29 51.14-12.08c16-9 24.86-21.78 24.86-35.92V60c0-14.14-8.83-26.9-24.86-35.92ZM88.63 45c10.17-5.72 24.52-9 39.37-9s29.2 3.28 39.37 9c8 4.52 12.63 10 12.63 15s-4.6 10.48-12.63 15c-10.17 5.72-24.52 9-39.37 9s-29.2-3.28-39.37-9C80.6 70.48 76 65 76 60s4.6-10.48 12.63-15Zm78.74 166c-10.17 5.72-24.52 9-39.37 9s-29.2-3.28-39.37-9C80.6 206.48 76 201 76 196V95.4l.86.52C90.72 103.71 108.88 108 128 108s37.28-4.29 51.14-12.08l.86-.52V196c0 5-4.6 10.48-12.63 15Z" /></Svg>;
export default Cylinder;