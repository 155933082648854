/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CarBattery = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="car-battery-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-car-battery-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 72h-16V56a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v16h-32V56a16 16 0 0 0-16-16H64a16 16 0 0 0-16 16v16H32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16ZM64 56h32v16H64Zm40 88H72a8 8 0 0 1 0-16h32a8 8 0 0 1 0 16Zm80 0h-8v8a8 8 0 0 1-16 0v-8h-8a8 8 0 0 1 0-16h8v-8a8 8 0 0 1 16 0v8h8a8 8 0 0 1 0 16Zm8-72h-32V56h32Z" /></Svg>;
export default CarBattery;