/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FalloutShelter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fallout-shelter__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fallout-shelter'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm71.05-91.77A8 8 0 0 0 192 120h-49l23.71-35.56A8 8 0 0 0 160 72H96a8 8 0 0 0-6.66 12.44L113.05 120H64a8 8 0 0 0-6.66 12.44l32 48a8 8 0 0 0 13.32 0l25.34-38 25.34 38a8 8 0 0 0 13.32 0l32-48a8 8 0 0 0 .39-8.21Zm-54-36.23L128 113.58 111 88ZM96 161.58 79 136h34.1Zm64 0L143 136h34.1Z" /></Svg>;
export default FalloutShelter;