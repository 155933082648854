/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sphere = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sphere-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sphere-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm90 102c0 6.59-12.94 13.24-24 16.94a167.93 167.93 0 0 1-28.92 6.53c.62-7.6.94-15.46.94-23.47 0-26.27-3.44-51-9.68-69.78a85.57 85.57 0 0 0-8-17.91A90.16 90.16 0 0 1 218 128Zm-90-90c6.59 0 13.24 12.94 16.94 24 5.84 17.53 9.06 41 9.06 66 0 8.83-.4 17.15-1.11 24.89-8 .73-16.35 1.11-24.89 1.11-54.94 0-90-15.4-90-26a90.1 90.1 0 0 1 90-90ZM40.31 148.3a85.57 85.57 0 0 0 17.91 8C77 162.56 101.73 166 128 166c8 0 15.86-.32 23.45-.94C146.64 198.2 136 218 128 218a90.16 90.16 0 0 1-87.69-69.7Zm108 67.39a85.57 85.57 0 0 0 8-17.91 184 184 0 0 0 7.43-34 184 184 0 0 0 34-7.43 85.57 85.57 0 0 0 17.91-8 90.3 90.3 0 0 1-67.35 67.34Z" /></Svg>;
export default Sphere;