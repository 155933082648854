/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cricket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cricket-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cricket-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 104v64l-37.66 37.66a8 8 0 0 1-11.31 0L66.34 153a8 8 0 0 1 0-11.31L104 104ZM80 60a20 20 0 1 0-20 20 20 20 0 0 0 20-20Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m243.31 81.37-52.68-52.68a16 16 0 0 0-22.63 0L60.69 136a16 16 0 0 0 0 22.63l20.68 20.68-47 47a8 8 0 0 0 11.32 11.32l47-47 20.68 20.68a16 16 0 0 0 22.63 0L243.31 104a16 16 0 0 0 0-22.63ZM124.69 200 104 179.31l29.66-29.65a8 8 0 0 0-11.32-11.32L92.69 168 72 147.31 107.31 112H160v52.69ZM232 92.69l-56 56V104a8 8 0 0 0-8-8h-44.69l56-56L232 92.68ZM60 88a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0-40a12 12 0 1 1-12 12 12 12 0 0 1 12-12Z" /></Svg>;
export default Cricket;