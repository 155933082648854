/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SwimmingPool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="swimming-pool-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-swimming-pool-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 145.39a4 4 0 0 0 4-4V124h72v19.29a4 4 0 0 0 8 0V32a4 4 0 0 0-8 0v20H92V32a4 4 0 0 0-8 0v109.39a4 4 0 0 0 4 4ZM92 116V92h72v24Zm72-56v24H92V60ZM28 168a4 4 0 0 1 4-4c13.21 0 20.12 4.61 26.22 8.67 5.9 3.93 11 7.33 21.78 7.33s15.88-3.4 21.78-7.33c6.09-4.06 13-8.67 26.21-8.67s20.13 4.61 26.22 8.67c5.9 3.93 11 7.33 21.79 7.33s15.88-3.4 21.78-7.33c6.1-4.06 13-8.67 26.22-8.67a4 4 0 0 1 0 8c-10.79 0-15.88 3.4-21.78 7.33-6.1 4.06-13 8.67-26.22 8.67s-20.13-4.61-26.22-8.67c-5.9-3.93-11-7.33-21.79-7.33s-15.88 3.4-21.78 7.33c-6.09 4.06-13 8.67-26.21 8.67s-20.12-4.61-26.22-8.67C47.88 175.4 42.79 172 32 172a4 4 0 0 1-4-4Zm200 40a4 4 0 0 1-4 4c-10.79 0-15.88 3.4-21.78 7.33-6.1 4.06-13 8.67-26.22 8.67s-20.13-4.61-26.22-8.67c-5.9-3.93-11-7.33-21.79-7.33s-15.88 3.4-21.78 7.33c-6.09 4.06-13 8.67-26.21 8.67s-20.12-4.61-26.22-8.67C47.88 215.4 42.79 212 32 212a4 4 0 0 1 0-8c13.21 0 20.12 4.61 26.22 8.67 5.9 3.93 11 7.33 21.78 7.33s15.88-3.4 21.78-7.33c6.09-4.06 13-8.67 26.21-8.67s20.13 4.61 26.22 8.67c5.9 3.93 11 7.33 21.79 7.33s15.88-3.4 21.78-7.33c6.1-4.06 13-8.67 26.22-8.67a4 4 0 0 1 4 4Z" /></Svg>;
export default SwimmingPool;