/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Wallet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wallet__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wallet'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 64H56a8 8 0 0 1 0-16h136a8 8 0 0 0 0-16H56a24 24 0 0 0-24 24v128a24 24 0 0 0 24 24h160a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16Zm0 128H56a8 8 0 0 1-8-8V78.63A23.84 23.84 0 0 0 56 80h160Zm-48-60a12 12 0 1 1 12 12 12 12 0 0 1-12-12Z" /></Svg>;
export default Wallet;