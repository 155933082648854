/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Waves = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="waves-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-waves-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220.62 178.58a6 6 0 0 1-.79 8.45c-16.87 14-32 19-45.75 19-18.19 0-34.13-8.66-48.94-16.7-26-14.12-48.44-26.31-81.31 1a6 6 0 0 1-7.66-9.33c39.13-32.45 68.65-16.41 94.69-2.26s48.44 26.31 81.31-1a6 6 0 0 1 8.45.84Zm-8.45-56.81c-32.87 27.27-55.32 15.07-81.31 1S75.3 92.54 36.17 125a6 6 0 0 0 7.66 9.25c32.87-27.27 55.32-15.08 81.31-1 14.81 8 30.75 16.71 48.94 16.71 13.79 0 28.88-5 45.75-19a6 6 0 0 0-7.66-9.24ZM43.83 78.21c32.87-27.27 55.32-15.07 81.31-1C140 85.3 155.89 94 174.08 94c13.79 0 28.88-5 45.75-19a6 6 0 1 0-7.66-9.24c-32.87 27.27-55.32 15.07-81.31 1S75.3 36.52 36.17 69a6 6 0 1 0 7.66 9.24Z" /></Svg>;
export default Waves;