/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsInCardinal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-in-cardinal-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-in-cardinal-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M91.76 68.24a6 6 0 0 1 8.48-8.48L122 81.51V24a6 6 0 0 1 12 0v57.51l21.76-21.75a6 6 0 0 1 8.48 8.48l-32 32a6 6 0 0 1-8.48 0Zm40.48 87.52a6 6 0 0 0-8.48 0l-32 32a6 6 0 0 0 8.48 8.48L122 174.49V232a6 6 0 0 0 12 0v-57.51l21.76 21.75a6 6 0 0 0 8.48-8.48ZM232 122h-57.51l21.75-21.76a6 6 0 0 0-8.48-8.48l-32 32a6 6 0 0 0 0 8.48l32 32a6 6 0 0 0 8.48-8.48L174.49 134H232a6 6 0 0 0 0-12Zm-131.76 1.76-32-32a6 6 0 0 0-8.48 8.48L81.51 122H24a6 6 0 0 0 0 12h57.51l-21.75 21.76a6 6 0 1 0 8.48 8.48l32-32a6 6 0 0 0 0-8.48Z" /></Svg>;
export default ArrowsInCardinal;