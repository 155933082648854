/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudMoon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-moon-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-moon-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 74a74.41 74.41 0 0 0-14.17 1.36 70 70 0 0 0-54.11-63.59A6 6 0 0 0 96.53 19 58.06 58.06 0 0 1 27 88.53a6 6 0 0 0-7.19 7.19 70.22 70.22 0 0 0 33.3 44.95A50 50 0 0 0 92 222h80a74 74 0 0 0 0-148ZM34.22 101.76q2.88.24 5.78.24a70.08 70.08 0 0 0 70-70c0-1.94-.08-3.88-.24-5.8A57.64 57.64 0 0 1 146 78.71 74.32 74.32 0 0 0 102.2 123a50.36 50.36 0 0 0-10.2-1 49.74 49.74 0 0 0-29.86 9.92 58.24 58.24 0 0 1-27.92-30.16ZM172 210H92a38 38 0 1 1 7.08-75.34 75.84 75.84 0 0 0-1.07 9 6 6 0 0 0 12 .7 61.54 61.54 0 0 1 2-12.24c0-.15.08-.29.11-.43A62.06 62.06 0 1 1 172 210Z" /></Svg>;
export default CloudMoon;