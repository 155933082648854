/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CodepenLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="codepen-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-codepen-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234 92.55s-.05 0-.09-.07l-104-56a4 4 0 0 0-3.8 0l-104 56-.11.08A4 4 0 0 0 20 96v64a4 4 0 0 0 2 3.45.71.71 0 0 0 .09.07l104 56a4 4 0 0 0 3.8 0l104-56a.27.27 0 0 0 .08-.07 4 4 0 0 0 2-3.45V96a4 4 0 0 0-1.97-3.45Zm-6 60.75L181 128l47-25.3Zm-55.43-29.84L132 101.61V46.7L223.56 96Zm-44.57 24L91.87 128 128 108.54 164.13 128ZM124 46.7v54.91l-40.57 21.85L32.44 96Zm-96 56L75 128l-47 25.3Zm55.43 29.84L124 154.39v54.91L32.44 160ZM132 209.3v-54.91l40.57-21.85 51 27.46Z" /></Svg>;
export default CodepenLogo;