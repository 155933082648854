/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fish = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fish-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fish-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 76a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm48.72 67.64c-19.37 34.9-55.44 53.76-107.24 56.1l-22 51.41A8 8 0 0 1 80.1 256h-.51a8 8 0 0 1-7.19-5.78l-14.8-51.83-51.8-14.83a8 8 0 0 1-1-15.05l51.41-22c2.35-51.78 21.21-87.84 56.09-107.22 24.75-13.74 52.74-15.84 71.88-15.18 18.64.64 36 4.27 38.86 6a8 8 0 0 1 2.83 2.83c1.69 2.85 5.33 20.21 6 38.85.68 19.1-1.41 47.1-15.15 71.85Zm-4.3-100.07c-14.15-3-64.1-11-100.3 14.75a81.21 81.21 0 0 0-16 15.07 36 36 0 0 0 39.35 38.44 8 8 0 0 1 8.73 8.73 36 36 0 0 0 38.47 39.34 80.81 80.81 0 0 0 15-16c25.75-36.17 17.75-86.16 14.75-100.33Z" /></Svg>;
export default Fish;