/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Notebook = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="notebook-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-notebook-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M108 108a12 12 0 0 1 12-12h56a12 12 0 0 1 0 24h-56a12 12 0 0 1-12-12Zm68 28h-56a12 12 0 0 0 0 24h56a12 12 0 0 0 0-24Zm52-88v160a20 20 0 0 1-20 20H48a20 20 0 0 1-20-20V48a20 20 0 0 1 20-20h160a20 20 0 0 1 20 20ZM52 204h16V52H52ZM204 52H92v152h112Z" /></Svg>;
export default Notebook;