/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Golf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="golf-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-golf-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 96a80 80 0 1 1-80-80 80 80 0 0 1 80 80Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M176 100a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-44 20a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm84-24a88 88 0 1 1-88-88 88.1 88.1 0 0 1 88 88Zm-16 0a72 72 0 1 0-72 72 72.08 72.08 0 0 0 72-72Zm-34.86 96.53C152 197.56 139.85 200 128 200s-24-2.44-37.14-7.47a8 8 0 1 0-5.72 14.94 125.91 125.91 0 0 0 34.86 8.21V248a8 8 0 0 0 16 0v-32.32a125.91 125.91 0 0 0 34.86-8.21 8 8 0 1 0-5.72-14.94Z" /></Svg>;
export default Golf;