/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-star-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-star-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM64 72h128a8 8 0 0 1 0 16H64a8 8 0 0 1 0-16Zm0 48h32a8 8 0 0 1 0 16H64a8 8 0 0 1 0-16Zm40 64H64a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm94.55-40.71L180.69 158l5.44 22a4 4 0 0 1-1.49 4.17 4.05 4.05 0 0 1-2.39.79 4 4 0 0 1-2-.55L160 172.54l-20.22 11.91a4 4 0 0 1-5.91-4.41l5.44-22-17.86-14.75a4 4 0 0 1 2.24-7.07l23.58-1.82 9.06-21a4 4 0 0 1 7.34 0l9.06 21 23.58 1.82a4 4 0 0 1 2.24 7.07Z" /></Svg>;
export default ListStar;