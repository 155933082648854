/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Parallelogram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="parallelogram-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-parallelogram-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248.78 45.14A19.92 19.92 0 0 0 232 36H88.81a20 20 0 0 0-18.24 11.79l-64.8 144A20 20 0 0 0 24 220h143.19a20 20 0 0 0 18.24-11.79l64.8-144a19.9 19.9 0 0 0-1.45-19.07ZM164.6 196H30.2L91.4 60h134.4Z" /></Svg>;
export default Parallelogram;