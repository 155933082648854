/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tornado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tornado__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tornado'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 40a8 8 0 0 1-8 8H56a8 8 0 0 1 0-16h168a8 8 0 0 1 8 8Zm-48 32a8 8 0 0 0-8-8H32a8 8 0 0 0 0 16h144a8 8 0 0 0 8-8Zm-16 32a8 8 0 0 0-8-8H56a8 8 0 0 0 0 16h104a8 8 0 0 0 8-8Zm16 32a8 8 0 0 0-8-8H88a8 8 0 0 0 0 16h88a8 8 0 0 0 8-8Zm0 24h-64a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16Zm-24 32h-32a8 8 0 0 0 0 16h32a8 8 0 0 0 0-16Zm-32 32h-16a8 8 0 0 0 0 16h16a8 8 0 0 0 0-16Z" /></Svg>;
export default Tornado;