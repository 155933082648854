/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-plus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-plus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 104a12 12 0 0 1-12 12h-20v20a12 12 0 0 1-24 0v-20H96a12 12 0 0 1 0-24h20V72a12 12 0 0 1 24 0v20h20a12 12 0 0 1 12 12Zm48 0c0 32.38-14.85 66.57-42.94 98.87a259 259 0 0 1-42.18 39 12 12 0 0 1-13.76 0C117.64 239.4 36 181.36 36 104a92 92 0 0 1 184 0Zm-24 0a68 68 0 0 0-136 0c0 33.31 20 63.37 36.7 82.71a249.35 249.35 0 0 0 31.3 30.18 249.35 249.35 0 0 0 31.3-30.18C176 167.37 196 137.31 196 104Z" /></Svg>;
export default MapPinPlus;