/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCss = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-css-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-css-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M44 180c0 13.23 9 24 20 24a18.15 18.15 0 0 0 13.11-5.9 4 4 0 0 1 5.78 5.54A26.13 26.13 0 0 1 64 212c-15.44 0-28-14.36-28-32s12.56-32 28-32a26.13 26.13 0 0 1 18.89 8.36 4 4 0 0 1-5.78 5.54A18.15 18.15 0 0 0 64 156c-11 0-20 10.77-20 24Zm82.49-4.85c-10.53-3-15.08-4.91-14.43-10.08a8.57 8.57 0 0 1 3.75-6.49c6.26-4.23 18.77-2.24 23.07-1.11a4 4 0 0 0 2-7.74 61.11 61.11 0 0 0-10.47-1.61c-8.12-.54-14.54.75-19.1 3.82a16.63 16.63 0 0 0-7.22 12.13c-1.58 12.49 10.46 16 20.14 18.77 11.25 3.25 16.47 5.49 15.63 11.94a8.93 8.93 0 0 1-3.9 6.75c-6.28 4.17-18.61 2.05-22.83.88a4 4 0 1 0-2.15 7.7 57.79 57.79 0 0 0 14.21 1.89c5.18 0 10.83-.86 15.22-3.77a17 17 0 0 0 7.43-12.41c1.8-13.98-11.58-17.82-21.35-20.67Zm64 0c-10.53-3-15.08-4.91-14.43-10.08a8.57 8.57 0 0 1 3.75-6.49c6.26-4.23 18.77-2.24 23.07-1.11a4 4 0 0 0 2-7.74 61.33 61.33 0 0 0-10.48-1.61c-8.11-.54-14.54.75-19.09 3.82a16.63 16.63 0 0 0-7.22 12.13c-1.59 12.49 10.46 16 20.14 18.77 11.25 3.25 16.46 5.49 15.63 11.94a8.93 8.93 0 0 1-3.9 6.75c-6.28 4.17-18.61 2.05-22.83.88a4 4 0 1 0-2.15 7.7 57.7 57.7 0 0 0 14.21 1.89c5.17 0 10.83-.86 15.22-3.77a17 17 0 0 0 7.43-12.41c1.79-13.98-11.58-17.82-21.35-20.67ZM204 92h-52a4 4 0 0 1-4-4V36H56a4 4 0 0 0-4 4v72a4 4 0 0 1-8 0V40a12 12 0 0 1 12-12h96a4 4 0 0 1 2.83 1.17l56 56A4 4 0 0 1 212 88v24a4 4 0 0 1-8 0Zm-5.65-8L156 41.65V84Z" /></Svg>;
export default FileCss;