/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GrainsSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="grains-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-grains-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l13.1 14.41A86 86 0 0 0 48 58a6 6 0 0 0-6 6v80a86.1 86.1 0 0 0 86 86 85.8 85.8 0 0 0 61.28-25.67L203.56 220a6 6 0 0 0 8.88-8.08Zm17.83 37.42 68.6 75.45A85.9 85.9 0 0 0 128 168.5a86.18 86.18 0 0 0-74-54.27v-44a73.11 73.11 0 0 1 16.27 3.19ZM122 217.76A74.1 74.1 0 0 1 54 144v-17.76A74.1 74.1 0 0 1 122 200Zm12 0V200a74.05 74.05 0 0 1 13.12-42l34.06 37.47A73.81 73.81 0 0 1 134 217.76ZM214 64v80a86.2 86.2 0 0 1-3.08 22.87 6 6 0 0 1-5.78 4.41 6.26 6.26 0 0 1-1.59-.21 6 6 0 0 1-4.2-7.38A74.07 74.07 0 0 0 202 144v-17.76a72.92 72.92 0 0 0-27.46 7.76 6 6 0 1 1-5.44-10.7 84.85 84.85 0 0 1 32.9-9.07v-44a74.29 74.29 0 0 0-54 30.39 6 6 0 1 1-9.72-7 86.23 86.23 0 0 1 26.1-23.7c-9.65-21.59-29.26-34.77-36.41-39a101.52 101.52 0 0 0-24.92 21 6 6 0 1 1-9.16-7.75 110.31 110.31 0 0 1 31.4-25.47 6 6 0 0 1 5.36 0c1.33.67 30.79 15.69 44.49 45.93A85.68 85.68 0 0 1 208 58a6 6 0 0 1 6 6Z" /></Svg>;
export default GrainsSlash;