/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsCounterClockwise = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-counter-clockwise-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-counter-clockwise-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 104H40a8 8 0 0 1-8-8V48a8 8 0 0 1 13.66-5.66L64 60.7a95.42 95.42 0 0 1 66-26.76h.53a95.36 95.36 0 0 1 67.07 27.33 8 8 0 0 1-11.18 11.44 79.52 79.52 0 0 0-55.89-22.77h-.45A79.48 79.48 0 0 0 75.35 72l18.31 18.34A8 8 0 0 1 88 104Zm128 48h-48a8 8 0 0 0-5.66 13.66L180.65 184a79.48 79.48 0 0 1-54.72 22.09h-.45a79.52 79.52 0 0 1-55.89-22.77 8 8 0 1 0-11.18 11.44 95.36 95.36 0 0 0 67.07 27.33h.52a95.42 95.42 0 0 0 66-26.76l18.36 18.36A8 8 0 0 0 224 208v-48a8 8 0 0 0-8-8Z" /></Svg>;
export default ArrowsCounterClockwise;