/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Gradient = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gradient-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gradient-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M20 108a12 12 0 0 1 12-12h76a12 12 0 0 1 0 24H32a12 12 0 0 1-12-12Zm204-12h-76a12 12 0 0 0 0 24h76a12 12 0 0 0 0-24ZM68 136H32a12 12 0 0 0 0 24h36a12 12 0 0 0 0-24Zm156 0h-36a12 12 0 0 0 0 24h36a12 12 0 0 0 0-24ZM96 148a12 12 0 0 0 12 12h40a12 12 0 0 0 0-24h-40a12 12 0 0 0-12 12Zm-44 28H32a12 12 0 0 0 0 24h20a12 12 0 0 0 0-24Zm56 0H92a12 12 0 0 0 0 24h16a12 12 0 0 0 0-24Zm56 0h-16a12 12 0 0 0 0 24h16a12 12 0 0 0 0-24Zm60 0h-20a12 12 0 0 0 0 24h20a12 12 0 0 0 0-24ZM32 80h192a12 12 0 0 0 0-24H32a12 12 0 0 0 0 24Z" /></Svg>;
export default Gradient;