/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boot-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boot-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 116h-36V56a12 12 0 0 0-12-12H32a4 4 0 0 0-3.73 5.43c9.29 24.23 11.46 77.22-.11 117.46A3.82 3.82 0 0 0 28 168v32a12 12 0 0 0 12 12h26.11a12.08 12.08 0 0 0 5.37-1.27l12.62-6.31a4.09 4.09 0 0 1 1.79-.42h16.22a4.09 4.09 0 0 1 1.79.42l12.62 6.31a12.08 12.08 0 0 0 5.37 1.27h28.22a12.08 12.08 0 0 0 5.37-1.27l12.62-6.31a4.09 4.09 0 0 1 1.79-.42h16.22a4.09 4.09 0 0 1 1.79.42l12.62 6.31a12.08 12.08 0 0 0 5.37 1.27H232a12 12 0 0 0 12-12v-32a52.06 52.06 0 0 0-52-52ZM37.6 52H144a4 4 0 0 1 4 4v28h-36a4 4 0 0 0 0 8h36v24h-36a4 4 0 0 0 0 8h80a44.06 44.06 0 0 1 43.81 40H37.2c8.04-32.19 9.8-81.35.4-112ZM236 200a4 4 0 0 1-4 4h-26.11a4.09 4.09 0 0 1-1.79-.42l-12.62-6.31a12.08 12.08 0 0 0-5.37-1.27h-16.22a12.08 12.08 0 0 0-5.37 1.27l-12.62 6.31a4.09 4.09 0 0 1-1.79.42h-28.22a4.09 4.09 0 0 1-1.79-.42l-12.62-6.31a12.08 12.08 0 0 0-5.37-1.27H85.89a12.08 12.08 0 0 0-5.37 1.27l-12.62 6.31a4.09 4.09 0 0 1-1.79.42H40a4 4 0 0 1-4-4v-28h200Z" /></Svg>;
export default Boot;