/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FunnelSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="funnel-simple-x-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-funnel-simple-x-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 142H64a6 6 0 0 1 0-12h128a6 6 0 0 1 0 12Zm40-60H24a6 6 0 0 0 0 12h208a6 6 0 0 0 0-12Zm-104 96h-24a6 6 0 0 0 0 12h24a6 6 0 0 0 0-12Zm92.24-6.24a6 6 0 0 0-8.48 0L192 191.51l-19.76-19.75a6 6 0 0 0-8.48 8.48L183.51 200l-19.75 19.76a6 6 0 1 0 8.48 8.48L192 208.49l19.76 19.75a6 6 0 0 0 8.48-8.48L200.49 200l19.75-19.76a6 6 0 0 0 0-8.48Z" /></Svg>;
export default FunnelSimpleX;