/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Calculator = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calculator-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calculator-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 60H80a4 4 0 0 0-4 4v48a4 4 0 0 0 4 4h96a4 4 0 0 0 4-4V64a4 4 0 0 0-4-4Zm-4 48H84V68h88Zm28-80H56a12 12 0 0 0-12 12v176a12 12 0 0 0 12 12h144a12 12 0 0 0 12-12V40a12 12 0 0 0-12-12Zm4 188a4 4 0 0 1-4 4H56a4 4 0 0 1-4-4V40a4 4 0 0 1 4-4h144a4 4 0 0 1 4 4ZM96 148a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm40 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm40 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-80 40a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm40 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm40 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default Calculator;