/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrosoftOutlookLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microsoft-outlook-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microsoft-outlook-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M124 128a36 36 0 1 0-36 36 36 36 0 0 0 36-36Zm-48 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm148-28h-12V32a12 12 0 0 0-12-12h-96a12 12 0 0 0-12 12v24H36a20 20 0 0 0-20 20v104a20 20 0 0 0 20 20h32v16a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V112a12 12 0 0 0-12-12Zm-52.45 68L212 136.54v62.92ZM116 44h72v80.8l-28 21.78V76a20 20 0 0 0-20-20h-24ZM40 80h96v96H40Zm52 120h48a20 20 0 0 0 18.28-11.92L189 212H92Z" /></Svg>;
export default MicrosoftOutlookLogo;