/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bread = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bread-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bread-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 80a36 36 0 0 0-36-36H48a36 36 0 0 0-12 69.94V200a12 12 0 0 0 12 12h152a12 12 0 0 0 12-12v-86.06A36.07 36.07 0 0 0 236 80ZM44 200v-84.23a35.22 35.22 0 0 0 4 .23 4 4 0 0 0 0-8 28 28 0 0 1 0-56h96a28 28 0 0 1 0 56 4 4 0 0 0 0 8 35.22 35.22 0 0 0 4-.23V200a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4Zm156-92a4 4 0 0 0 0 8 35.22 35.22 0 0 0 4-.23V200a4 4 0 0 1-4 4h-44.7a11.8 11.8 0 0 0 .7-4v-86.06A36 36 0 0 0 166.59 52H200a28 28 0 0 1 0 56Z" /></Svg>;
export default Bread;