/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Keyhole = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="keyhole-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-keyhole-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 139.72 160 176H96l16-36.28a32 32 0 1 1 32 0Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm40-104a40 40 0 1 0-65.94 30.44l-13.38 30.33A8 8 0 0 0 96 184h64a8 8 0 0 0 7.32-11.23l-13.38-30.33A40.14 40.14 0 0 0 168 112Zm-31.32 31 11 25.05h-39.41l11-25.05a8 8 0 0 0-3.27-10.21 24 24 0 1 1 24 0 8 8 0 0 0-3.32 10.21Z" /></Svg>;
export default Keyhole;