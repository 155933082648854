/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PawPrint = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paw-print-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paw-print-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 84a24 24 0 1 0 24 24 24 24 0 0 0-24-24Zm0 40a16 16 0 1 1 16-16 16 16 0 0 1-16 16ZM68 108a24 24 0 1 0-24 24 24 24 0 0 0 24-24Zm-24 16a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm48-40a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-40a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm72 40a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-40a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm21.18 108.36a39.32 39.32 0 0 1-18.77-23.52 40 40 0 0 0-76.82 0 39.3 39.3 0 0 1-18.73 23.51A36 36 0 0 0 102 217.17a68.14 68.14 0 0 1 51.95 0 36 36 0 0 0 31.23-64.79ZM168 212a27.8 27.8 0 0 1-11-2.23 76.16 76.16 0 0 0-58.11 0A27.72 27.72 0 0 1 88 212a28 28 0 0 1-13.29-52.65 47.23 47.23 0 0 0 22.56-28.29 32 32 0 0 1 61.46 0 47.26 47.26 0 0 0 22.6 28.3A28 28 0 0 1 168 212Z" /></Svg>;
export default PawPrint;