/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pizza = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pizza-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pizza-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.6 63.47a13.91 13.91 0 0 0-6.35-8.67 199.48 199.48 0 0 0-206.5 0 14 14 0 0 0-4.7 19.2l96 157.27a14 14 0 0 0 24 0L236 74a13.81 13.81 0 0 0 1.6-10.53ZM61.07 118.2a26 26 0 1 1 26.1 42.8Zm91 70.32a26 26 0 0 1 26.91-44.1Zm33.2-54.4A37.65 37.65 0 0 0 168 130a38 38 0 0 0-22.25 68.8l-16 26.24a2 2 0 0 1-3.46 0l-32.7-53.55a38 38 0 1 0-38.93-63.79l-8.61-14.11a154.14 154.14 0 0 1 163.9 0Zm40.49-66.35-9.56 15.58a166.11 166.11 0 0 0-176.4 0l-9.51-15.58a1.88 1.88 0 0 1-.23-1.47 2 2 0 0 1 .94-1.24 187.46 187.46 0 0 1 194 0 2 2 0 0 1 .92 1.24 1.88 1.88 0 0 1-.21 1.47Z" /></Svg>;
export default Pizza;