/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThermometerSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="thermometer-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-thermometer-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 146.08V40a32 32 0 0 0-64 0v106.08a56 56 0 1 0 64 0ZM128 24a16 16 0 0 1 16 16v64h-32V40a16 16 0 0 1 16-16Z" /></Svg>;
export default ThermometerSimple;