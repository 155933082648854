/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Plugs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plugs-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plugs-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148.24 139.76a6 6 0 0 0-8.48 0L120 159.51 96.49 136l19.75-19.76a6 6 0 0 0-8.48-8.48L88 127.51l-19.76-19.75a6 6 0 0 0-8.48 8.48l7.75 7.76-24.72 24.73a30 30 0 0 0 0 42.42l6.78 6.79-29.81 29.82a6 6 0 1 0 8.48 8.48l29.82-29.81 6.79 6.78a30 30 0 0 0 42.42 0L132 188.49l7.76 7.75a6 6 0 0 0 8.48-8.48L128.49 168l19.75-19.76a6 6 0 0 0 0-8.48Zm-49.45 65a18 18 0 0 1-25.46 0l-22.06-22.09a18 18 0 0 1 0-25.46L76 132.49 123.51 180Zm137.45-185a6 6 0 0 0-8.48 0l-29.82 29.81-6.79-6.78a30 30 0 0 0-42.42 0L124 67.51l-7.76-7.75a6 6 0 0 0-8.48 8.48l80 80a6 6 0 0 0 8.48-8.48l-7.75-7.76 24.72-24.73a30 30 0 0 0 0-42.42l-6.78-6.79 29.81-29.82a6 6 0 0 0 0-8.48Zm-31.51 79L180 123.51 132.49 76l24.72-24.73a18 18 0 0 1 25.46 0l22.06 22.06a18 18 0 0 1 0 25.46Z" /></Svg>;
export default Plugs;