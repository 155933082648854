/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-four-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-four-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 164h-20V48a4 4 0 0 0-7.12-2.5l-96 120A4 4 0 0 0 64 172h92v36a4 4 0 0 0 8 0v-36h20a4 4 0 0 0 0-8Zm-28 0H72.32L156 59.4Z" /></Svg>;
export default NumberFour;