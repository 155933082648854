/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Globe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 190.61c-6.33-6.09-23-24.41-31.27-54.61h62.54c-8.27 30.2-24.94 48.52-31.27 54.61ZM94.82 156a140.42 140.42 0 0 1 0-56h66.36a140.42 140.42 0 0 1 0 56ZM128 37.39c6.33 6.09 23 24.41 31.27 54.61H96.73C105 61.8 121.67 43.48 128 37.39ZM169.41 100h46.23a92.09 92.09 0 0 1 0 56h-46.23a152.65 152.65 0 0 0 0-56Zm43.25-8h-45a129.39 129.39 0 0 0-29.19-55.4A92.25 92.25 0 0 1 212.66 92Zm-95.12-55.4A129.39 129.39 0 0 0 88.35 92h-45a92.25 92.25 0 0 1 74.19-55.4ZM40.36 100h46.23a152.65 152.65 0 0 0 0 56H40.36a92.09 92.09 0 0 1 0-56Zm3 64h45a129.39 129.39 0 0 0 29.19 55.4A92.25 92.25 0 0 1 43.34 164Zm95.12 55.4a129.39 129.39 0 0 0 29.17-55.4h45a92.25 92.25 0 0 1-74.19 55.4Z" /></Svg>;
export default Globe;