/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SneakerMove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sneaker-move-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sneaker-move-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m232.94 163.06-28.62-14.31A43.78 43.78 0 0 1 180 109.39V80a12 12 0 0 0-12-12 44.05 44.05 0 0 1-44-44 12 12 0 0 0-19.25-9.56l-77 58.41-.3.23a20 20 0 0 0-1.56 29.69l113.68 113.72a12 12 0 0 0 8.49 3.51H224a20 20 0 0 0 20-20v-19.06a19.88 19.88 0 0 0-11.06-17.88ZM220 196h-67L46.08 89.05l12.86-9.76 41.2 41.2a12 12 0 0 0 17-17L78.25 64.65l25.24-19.14A68.24 68.24 0 0 0 156 90.94v18.45a67.62 67.62 0 0 0 37.59 60.82L220 183.42ZM55.49 184H32a12 12 0 0 1 0-24h23.49a12 12 0 0 1 0 24Zm48 24a12 12 0 0 1-12 12H48a12 12 0 0 1 0-24h43.49a12 12 0 0 1 12 12Z" /></Svg>;
export default SneakerMove;