/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CubeFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cube-focus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cube-focus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 48v40a6 6 0 0 1-12 0V54h-34a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6ZM72 202H38v-34a6 6 0 0 0-12 0v40a6 6 0 0 0 6 6h40a6 6 0 0 0 0-12Zm152-40a6 6 0 0 0-6 6v34h-34a6 6 0 0 0 0 12h40a6 6 0 0 0 6-6v-40a6 6 0 0 0-6-6ZM32 94a6 6 0 0 0 6-6V54h34a6 6 0 0 0 0-12H32a6 6 0 0 0-6 6v40a6 6 0 0 0 6 6Zm155 71.21-56 32a6 6 0 0 1-6 0l-56-32a6 6 0 0 1-3-5.21V96a6 6 0 0 1 3-5.21l56-32a6 6 0 0 1 6 0l56 32a6 6 0 0 1 3 5.21v64a6 6 0 0 1-3 5.21ZM84.09 96 128 121.09 171.91 96 128 70.91ZM78 156.52l44 25.14v-50.18l-44-25.14Zm100 0v-50.18l-44 25.14v50.18Z" /></Svg>;
export default CubeFocus;