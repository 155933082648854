/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CompassRose = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="compass-rose-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-compass-rose-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m248 128-96 24-24 96-24-96-96-24 96-24 24-96 24 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m249.94 120.24-27.05-6.76a95.86 95.86 0 0 0-80.37-80.37l-6.76-27a8 8 0 0 0-15.52 0l-6.76 27.05a95.86 95.86 0 0 0-80.37 80.37l-27 6.76a8 8 0 0 0 0 15.52l27.05 6.76a95.86 95.86 0 0 0 80.37 80.37l6.76 27.05a8 8 0 0 0 15.52 0l6.76-27.05a95.86 95.86 0 0 0 80.37-80.37l27.05-6.76a8 8 0 0 0 0-15.52Zm-95.49 22.9L139.31 128l15.14-15.14L215 128Zm-52.9 0L41 128l60.57-15.14L116.69 128Zm104.22-33.94L158.6 97.4l-11.8-47.17a79.88 79.88 0 0 1 58.97 58.97Zm-62.63-7.65L128 116.69l-15.14-15.14L128 41ZM109.2 50.23 97.4 97.4l-47.17 11.8a79.88 79.88 0 0 1 58.97-58.97Zm-59 96.57 47.2 11.8 11.8 47.17a79.88 79.88 0 0 1-58.97-58.97Zm62.63 7.65L128 139.31l15.14 15.14L128 215Zm33.94 51.32 11.8-47.17 47.17-11.8a79.88 79.88 0 0 1-58.94 58.97Z" /></Svg>;
export default CompassRose;