/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Knife = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="knife-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-knife-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.7 29.3a31.83 31.83 0 0 0-45 0L15.52 203.56a12 12 0 0 0 5.78 20.19A164.85 164.85 0 0 0 58.42 228c33.71 0 67.41-10.42 99.1-30.87 32.32-20.86 51.16-44.7 51.94-45.7a12 12 0 0 0-1-15.89L191 118l43.7-43.71a31.86 31.86 0 0 0 0-44.99Zm-90.83 148.06C113 197.12 81.28 206 49.28 203.74L146 107l19.5 19.51 18 18a216.69 216.69 0 0 1-39.63 32.85Zm73.86-120L174 101l-11-11 43.7-43.72a7.8 7.8 0 0 1 11 11Z" /></Svg>;
export default Knife;