/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatsCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chats-circle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chats-circle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.93 186.58a78 78 0 0 0-61.77-112.16 78 78 0 1 0-142.09 64.16L18.58 164A14 14 0 0 0 36 181.42l25.46-7.49a78 78 0 0 0 26.39 7.63 78 78 0 0 0 106.77 40.37l25.38 7.49A14 14 0 0 0 237.42 212ZM62 161.5a6.05 6.05 0 0 0-1.69.24l-27.77 8.17a2 2 0 0 1-2.48-2.48l8.17-27.77a6.05 6.05 0 0 0-.47-4.53 66 66 0 1 1 27.08 27.08 6 6 0 0 0-2.84-.71Zm155.71 26.16 8.17 27.77a2 2 0 0 1-2.48 2.48l-27.77-8.17a6.06 6.06 0 0 0-4.53.47 66 66 0 0 1-90-28.4 77.92 77.92 0 0 0 71-94.68 66 66 0 0 1 46.07 96 6.05 6.05 0 0 0-.43 4.53Z" /></Svg>;
export default ChatsCircle;