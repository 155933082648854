/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8L49 50h-9a14 14 0 0 0-14 14v160a13.86 13.86 0 0 0 8.09 12.69 13.89 13.89 0 0 0 15-2L82.23 206H190.8l12.76 14a6 6 0 1 0 8.88-8.07ZM80 194a6 6 0 0 0-3.92 1.46l-34.79 30A2 2 0 0 1 38 224V64a2 2 0 0 1 2-2h19.89l120 132ZM230 64v122a6 6 0 0 1-12 0V64a2 2 0 0 0-2-2H105.79a6 6 0 0 1 0-12H216a14 14 0 0 1 14 14Z" /></Svg>;
export default ChatSlash;