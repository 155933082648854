/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Plugs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plugs-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plugs-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M146.83 141.17a4 4 0 0 0-5.66 0L120 162.34 93.66 136l21.17-21.17a4 4 0 0 0-5.66-5.66L88 130.34l-21.17-21.17a4 4 0 0 0-5.66 5.66l9.17 9.17-26.14 26.14a28 28 0 0 0 0 39.6l8.2 8.2-31.23 31.23a4 4 0 0 0 5.66 5.66l31.23-31.23 8.2 8.2a28 28 0 0 0 39.6 0L132 185.66l9.17 9.17a4 4 0 0 0 5.66-5.66L125.66 168l21.17-21.17a4 4 0 0 0 0-5.66Zm-46.63 65a20 20 0 0 1-28.28 0l-22.06-22.09a20 20 0 0 1 0-28.28L76 129.66 126.34 180Zm134.63-185a4 4 0 0 0-5.66 0L197.94 52.4l-8.2-8.2a28 28 0 0 0-39.6 0L124 70.34l-9.17-9.17a4 4 0 0 0-5.66 5.66l80 80a4 4 0 0 0 5.66-5.66l-9.17-9.17 26.14-26.14a28 28 0 0 0 0-39.6l-8.2-8.2 31.23-31.23a4 4 0 0 0 0-5.66ZM212 86.06a19.86 19.86 0 0 1-5.86 14.14L180 126.34 129.66 76l26.14-26.14a20 20 0 0 1 28.28 0l22.06 22.06A19.85 19.85 0 0 1 212 86.06Z" /></Svg>;
export default Plugs;