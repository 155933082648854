/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyMeh = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-meh-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-meh-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.13 104.13 0 0 0 128 24ZM92 96a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm76 72H88a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16Zm-4-48a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default SmileyMeh;