/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CornersOut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="corners-out-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-corners-out-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 48v40a4 4 0 0 1-8 0V52h-36a4 4 0 0 1 0-8h40a4 4 0 0 1 4 4ZM88 204H52v-36a4 4 0 0 0-8 0v40a4 4 0 0 0 4 4h40a4 4 0 0 0 0-8Zm120-40a4 4 0 0 0-4 4v36h-36a4 4 0 0 0 0 8h40a4 4 0 0 0 4-4v-40a4 4 0 0 0-4-4ZM88 44H48a4 4 0 0 0-4 4v40a4 4 0 0 0 8 0V52h36a4 4 0 0 0 0-8Z" /></Svg>;
export default CornersOut;