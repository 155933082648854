/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14L50 60a103.37 103.37 0 0 0-22 64v84a20 20 0 0 0 20 20h84a104.32 104.32 0 0 0 55.87-16.31l11.25 12.38a12 12 0 0 0 17.76-16.14ZM132 204H52v-80a79.51 79.51 0 0 1 14.46-45.86l105 115.49A79.08 79.08 0 0 1 132 204Zm104-80a102.9 102.9 0 0 1-10.36 45.29A12 12 0 0 1 204 158.83a79.25 79.25 0 0 0 8-34.83 80.07 80.07 0 0 0-107.84-75 12 12 0 0 1-8.35-22.5A104.07 104.07 0 0 1 236 124Z" /></Svg>;
export default ChatTeardropSlash;