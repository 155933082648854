/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneTransfer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-transfer-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-transfer-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 72a4 4 0 0 1 4-4h62.34l-25.17-25.17a4 4 0 0 1 5.66-5.66l32 32a4 4 0 0 1 0 5.66l-32 32a4 4 0 0 1-5.66-5.66L206.34 76H144a4 4 0 0 1-4-4Zm79.91 110.58A52.26 52.26 0 0 1 168 228C90.8 228 28 165.2 28 88a52.26 52.26 0 0 1 45.42-51.91 12 12 0 0 1 12.48 7.19L107 90.42a12 12 0 0 1-1 11.36 3.47 3.47 0 0 1-.29.38l-21.15 25.21a3.9 3.9 0 0 0-.18 3.69c7.83 16.05 24.65 32.73 40.89 40.57a3.9 3.9 0 0 0 3.7-.21l24.83-21.12a3.27 3.27 0 0 1 .37-.28 12 12 0 0 1 11.39-1.02l47.22 21.16a12 12 0 0 1 7.13 12.42Zm-10.35-5.12-47.21-21.16a3.93 3.93 0 0 0-3.57.27L134 177.69l-.37.28a12 12 0 0 1-11.79.87c-18-8.69-35.91-26.48-44.6-44.27a12 12 0 0 1 .76-11.75l.29-.39 21.19-25.2a4 4 0 0 0 .23-3.6L78.57 46.5A4 4 0 0 0 74.9 44a3.87 3.87 0 0 0-.48 0A44.23 44.23 0 0 0 36 88a132.14 132.14 0 0 0 132 132 44.23 44.23 0 0 0 44-38.42 4 4 0 0 0-2.44-4.12Z" /></Svg>;
export default PhoneTransfer;