/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPinSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l27.71 30.51C61.78 76 50.6 80 39.22 89.18A14 14 0 0 0 38.1 110l49.71 49.71-44.05 44a6 6 0 1 0 8.48 8.48l44.05-44.05L146 217.89a14 14 0 0 0 9.9 4.11h1a14 14 0 0 0 10.19-5.54 85.51 85.51 0 0 0 12.44-22.84l24 26.45a6 6 0 1 0 8.87-8.08Zm105.05 173.21a2 2 0 0 1-3 .2L46.58 101.51a2 2 0 0 1 .18-3c13.18-10.64 25.84-12.9 34.79-12.7l88.45 97.3c-2.17 10.63-7.89 19.96-12.51 26.1Zm76.42-106.62-44.65 44.78a6 6 0 1 1-8.5-8.47l44.65-44.79a2 2 0 0 0 0-2.84l-60.68-60.68a2 2 0 0 0-2.83 0l-41.22 41.35a6 6 0 0 1-8.5-8.47l41.23-41.36a14 14 0 0 1 19.81 0l60.69 60.69a14 14 0 0 1 0 19.79Z" /></Svg>;
export default PushPinSlash;