/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThreeD = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="three-d-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-three-d-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48v160H32V48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M96 148a20 20 0 0 0-20-20 8 8 0 0 1-6.55-12.59L88.63 88H56a8 8 0 0 1 0-16h48a8 8 0 0 1 6.55 12.59l-21 30A36 36 0 0 1 76 184a35.71 35.71 0 0 1-25.71-10.81A8 8 0 1 1 61.71 162 20 20 0 0 0 96 148Zm64-76a56 56 0 0 1 0 112h-24a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8Zm0 16h-16v80h16a40 40 0 0 0 0-80ZM32 56h192a8 8 0 0 0 0-16H32a8 8 0 0 0 0 16Zm192 144H32a8 8 0 0 0 0 16h192a8 8 0 0 0 0-16Z" /></Svg>;
export default ThreeD;