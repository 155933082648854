/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FootballHelmet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="football-helmet-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-football-helmet-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M94 164a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm136 12v32a14 14 0 0 1-14 14h-36a14.1 14.1 0 0 1-13.43-10l-11.22-38h-26.1l4.33 14.56c0 .13.07.27.1.4A14 14 0 0 1 120 206H72.14a6 6 0 0 1-3.39-1A98 98 0 0 1 26 124c0-53.27 43.35-97.22 96.61-98A98 98 0 0 1 222 124v4a6 6 0 0 1-6 6h-60l8.27 28H216a14 14 0 0 1 14 14Zm-108 15.71-15.54-52.26c0-.14-.07-.28-.1-.41A14 14 0 0 1 120 122h90a86 86 0 0 0-86-84h-1.24C76 38.66 38 77.22 38 124a86 86 0 0 0 36 70h46a2 2 0 0 0 2-2.29ZM151.79 162l-8.27-28H120a2 2 0 0 0-2 2.29l7.66 25.71ZM218 176a2 2 0 0 0-2-2h-48.15l10.21 34.57A2 2 0 0 0 180 210h36a2 2 0 0 0 2-2Z" /></Svg>;
export default FootballHelmet;