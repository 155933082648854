/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleCardboardLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-cardboard-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-cardboard-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 52H32a12 12 0 0 0-12 12v128a12 12 0 0 0 12 12h64a4 4 0 0 0 2.83-1.17l26.34-26.34a4.08 4.08 0 0 1 5.66 0l26.34 26.34A4 4 0 0 0 160 204h64a12 12 0 0 0 12-12V64a12 12 0 0 0-12-12Zm4 140a4 4 0 0 1-4 4h-62.34l-25.17-25.17a12 12 0 0 0-17 0L94.34 196H32a4 4 0 0 1-4-4V64a4 4 0 0 1 4-4h192a4 4 0 0 1 4 4ZM80 100a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm96-48a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default GoogleCardboardLogo;