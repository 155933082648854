/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tractor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tractor-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tractor-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 172a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm40 0a52 52 0 1 1-52-52 52.06 52.06 0 0 1 52 52Zm-24 0a28 28 0 1 0-28 28 28 28 0 0 0 28-28Zm152 16a36 36 0 0 1-71.77 4H144a8 8 0 0 1-8-8v-12a68.07 68.07 0 0 0-68-68H40a8 8 0 0 1 0-16h8V56h-8a8 8 0 0 1 0-16h120a8 8 0 0 1 0 16h-8v41.88l24 6.5V72a8 8 0 0 1 16 0v36.71l36.39 9.86.21.06A15.89 15.89 0 0 1 240 134v31.46a35.8 35.8 0 0 1 8 22.54Zm-20 0a16 16 0 1 0-16 16 16 16 0 0 0 16-16Z" /></Svg>;
export default Tractor;