/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212.92 17.69a8 8 0 0 0-6.86-1.45l-128 32A8 8 0 0 0 72 56v110.08A36 36 0 1 0 88 196v-85.75l112-28v51.83A36 36 0 1 0 216 164V24a8 8 0 0 0-3.08-6.31ZM52 216a20 20 0 1 1 20-20 20 20 0 0 1-20 20ZM88 93.75v-31.5l112-28v31.5ZM180 184a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default MusicNotes;