/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Equals = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="equals-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-equals-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 160a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h176a12 12 0 0 1 12 12ZM40 108h176a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Z" /></Svg>;
export default Equals;