/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Asterisk = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="asterisk-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-asterisk-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm59.43 129.07a8 8 0 0 1-4 14.93 7.92 7.92 0 0 1-4-1.07L136 141.86V192a8 8 0 0 1-16 0v-50.14l-43.43 25.07A8 8 0 0 1 65.65 164a8 8 0 0 1 2.92-10.93L112 128l-43.43-25.07a8 8 0 0 1 8-13.86L120 114.14V64a8 8 0 0 1 16 0v50.14l43.43-25.07a8 8 0 0 1 8 13.86L144 128Z" /></Svg>;
export default Asterisk;