/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segment-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segment-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M81 175a24 24 0 1 1-34 0 24 24 0 0 1 34 0ZM209 47a24 24 0 1 0 0 34 24 24 0 0 0 0-34Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M214.64 41.36a32 32 0 0 0-50.2 38.89l-84.19 84.19a32.06 32.06 0 0 0-38.89 4.94 32 32 0 1 0 50.2 6.37l84.19-84.19a32 32 0 0 0 38.89-50.2Zm-139.33 162a16 16 0 0 1-22.64-22.64 16 16 0 0 1 22.63 0 16 16 0 0 1 .01 22.61Zm128-128a16 16 0 1 1 0-22.63 16 16 0 0 1 .02 22.57Z" /></Svg>;
export default LineSegment;