/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MarkdownLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="markdown-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-markdown-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 44H24A20 20 0 0 0 4 64v128a20 20 0 0 0 20 20h208a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20Zm-4 144H28V68h200ZM48 148v-40a12 12 0 0 1 20.49-8.49L88 119l19.51-19.52A12 12 0 0 1 128 108v40a12 12 0 0 1-24 0v-11l-7.51 7.52a12 12 0 0 1-17 0L72 137v11a12 12 0 0 1-24 0Zm98.63-23.5a12 12 0 0 1 16.87-1.87l.5.4V108a12 12 0 0 1 24 0v15l.5-.4a12 12 0 0 1 15 18.74l-20 16a12 12 0 0 1-15 0l-20-16a12 12 0 0 1-1.87-16.84Z" /></Svg>;
export default MarkdownLogo;