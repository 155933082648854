/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Desk = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="desk-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-desk-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 64H8a8 8 0 0 0 0 16h8v112a8 8 0 0 0 16 0v-48h192v48a8 8 0 0 0 16 0V80h8a8 8 0 0 0 0-16ZM80 112H56a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16Zm56 8a8 8 0 0 1-16 0V88a8 8 0 0 1 16 0Zm64-8h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16Z" /></Svg>;
export default Desk;