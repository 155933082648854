/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fan = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fan-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fan-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.06 135.52a58 58 0 0 0-88-33.42c-.69-.41-1.41-.8-2.14-1.15l16.86-67.32a6 6 0 0 0-2.58-6.51A58 58 0 1 0 98 127.86v.14c0 .77 0 1.54.1 2.3l-66.73 19.06a6 6 0 0 0-4.37 5.49 58 58 0 1 0 115.88-.85c.7-.4 1.39-.83 2.06-1.29L194.81 201a6 6 0 0 0 6.94 1 58 58 0 0 0 29.31-66.51ZM110 128a18 18 0 1 1 18 18 18 18 0 0 1-18-18ZM78 76a46 46 0 0 1 67.08-40.9L129.32 98H128a30 30 0 0 0-27.33 17.66A45.84 45.84 0 0 1 78 76Zm30 121.3a46 46 0 0 1-69-37.64l62.39-17.82A30 30 0 0 0 128 158a29.29 29.29 0 0 0 3-.15 45.85 45.85 0 0 1-23 39.45Zm106.9-23.76a45.91 45.91 0 0 1-15 15.7l-46.63-45.12a29.91 29.91 0 0 0-.93-33.62 46 46 0 0 1 62.52 63Z" /></Svg>;
export default Fan;