/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListNumbers = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-numbers-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-numbers-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 128a12 12 0 0 1-12 12H116a12 12 0 0 1 0-24h100a12 12 0 0 1 12 12ZM116 76h100a12 12 0 0 0 0-24H116a12 12 0 0 0 0 24Zm100 104H116a12 12 0 0 0 0 24h100a12 12 0 0 0 0-24ZM44 59.31V104a12 12 0 0 0 24 0V40a12 12 0 0 0-17.36-10.73l-16 8a12 12 0 0 0 9.36 22Zm39.73 96.86a27.7 27.7 0 0 0-11.2-18.63A28.89 28.89 0 0 0 32.9 143a27.71 27.71 0 0 0-4.17 7.54 12 12 0 0 0 22.55 8.21 4 4 0 0 1 .58-1 4.78 4.78 0 0 1 6.5-.82 3.82 3.82 0 0 1 1.61 2.6 3.63 3.63 0 0 1-.77 2.77l-.13.17-28.68 38.35A12 12 0 0 0 40 220h32a12 12 0 0 0 0-24h-8l14.28-19.11a27.48 27.48 0 0 0 5.45-20.72Z" /></Svg>;
export default ListNumbers;