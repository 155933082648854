/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldCheckered = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-checkered-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-checkered-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 42H48a14 14 0 0 0-14 14v56c0 51.94 25.12 83.4 46.2 100.64 22.73 18.6 45.27 24.89 46.22 25.15a6 6 0 0 0 3.16 0c.95-.26 23.49-6.55 46.22-25.15C196.88 195.4 222 163.94 222 112V56a14 14 0 0 0-14-14Zm2 14v56c0 3.39-.12 6.72-.34 10H134V54h74a2 2 0 0 1 2 2ZM46 56a2 2 0 0 1 2-2h74v68H46.34c-.22-3.28-.34-6.61-.34-10Zm1.71 78H122v89.52a133.13 133.13 0 0 1-34.56-20.46C65.5 185 52.2 161.8 47.71 134Zm120.85 69.06A133.13 133.13 0 0 1 134 223.52V134h74.29c-4.49 27.8-17.79 51-39.73 69.06Z" /></Svg>;
export default ShieldCheckered;