/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seat__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seat'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 232a8 8 0 0 1-8 8H112a8 8 0 0 1 0-16h104a8 8 0 0 1 8 8Zm0-72v32a16 16 0 0 1-16 16h-93.89a15.93 15.93 0 0 1-14.32-8.85l-58.11-116a16.1 16.1 0 0 1 0-14.32l22.12-44A16 16 0 0 1 85 17.56l33.69 14.22.47.22a16 16 0 0 1 7.15 21.46 1.51 1.51 0 0 1-.11.22L112 80l31.78 64H208a16 16 0 0 1 16 16Zm-16 0h-64.23a15.91 15.91 0 0 1-14.31-8.85l-31.79-64a16.07 16.07 0 0 1 0-14.29l.12-.22L112 46.32 78.57 32.21a4.84 4.84 0 0 1-.47-.21L56 76l58.1 116H208Z" /></Svg>;
export default Seat;