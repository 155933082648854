/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bed-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bed-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 76H28V48a4 4 0 0 0-8 0v160a4 4 0 0 0 8 0v-36h216v36a4 4 0 0 0 8 0v-96a36 36 0 0 0-36-36ZM28 84h80v80H28Zm88 80V84h100a28 28 0 0 1 28 28v52Z" /></Svg>;
export default Bed;