/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiLow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-low__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-low'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 204a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm32.71-45.47a76.05 76.05 0 0 0-89.42 0 8 8 0 0 0 9.42 12.94 60 60 0 0 1 70.58 0 8 8 0 1 0 9.42-12.94Z" /></Svg>;
export default WifiLow;