/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StandardDefinition = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="standard-definition__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-standard-definition'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 72a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h24a56 56 0 0 0 0-112Zm64 56a40 40 0 0 1-40 40h-16V88h16a40 40 0 0 1 40 40ZM24 48a8 8 0 0 1 8-8h192a8 8 0 0 1 0 16H32a8 8 0 0 1-8-8Zm208 160a8 8 0 0 1-8 8H32a8 8 0 0 1 0-16h192a8 8 0 0 1 8 8Zm-128-56c0-9.48-8.61-13-26.88-18.26-15.75-4.54-35.34-10.19-35.34-29.74 0-18.24 16.43-32 38.22-32 15.72 0 29.18 7.3 35.12 19a8 8 0 1 1-14.27 7.22C97.64 91.93 89.65 88 80 88c-12.67 0-22.22 6.88-22.22 16 0 7 9 10.1 23.77 14.36C97.78 123 120 129.45 120 152c0 17.64-17.94 32-40 32s-40-14.36-40-32a8 8 0 0 1 16 0c0 8.67 11 16 24 16s24-7.33 24-16Z" /></Svg>;
export default StandardDefinition;