/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Spiral = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spiral-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spiral-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 144h-32a80 80 0 0 1-80 80 88 88 0 0 1-88-88 96 96 0 0 1 96-96 104 104 0 0 1 104 104Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M248 144a8 8 0 0 1-16 0 96.11 96.11 0 0 0-96-96 88.1 88.1 0 0 0-88 88 80.09 80.09 0 0 0 80 80 72.08 72.08 0 0 0 72-72 64.07 64.07 0 0 0-64-64 56.06 56.06 0 0 0-56 56 48.05 48.05 0 0 0 48 48 40 40 0 0 0 40-40 32 32 0 0 0-32-32 24 24 0 0 0-24 24 16 16 0 0 0 16 16 8 8 0 0 0 8-8 8 8 0 0 1 0-16 16 16 0 0 1 16 16 24 24 0 0 1-24 24 32 32 0 0 1-32-32 40 40 0 0 1 40-40 48.05 48.05 0 0 1 48 48 56.06 56.06 0 0 1-56 56 64.07 64.07 0 0 1-64-64 72.08 72.08 0 0 1 72-72 80.09 80.09 0 0 1 80 80 88.1 88.1 0 0 1-88 88 96.11 96.11 0 0 1-96-96A104.11 104.11 0 0 1 136 32a112.12 112.12 0 0 1 112 112Z" /></Svg>;
export default Spiral;