/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudMoon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-moon-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-moon-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M106.31 130.38Zm-3.93-112.76a64.06 64.06 0 0 1-76.76 76.76A64.12 64.12 0 0 0 63 138.93a44.08 44.08 0 0 1 43.33-8.54 68.13 68.13 0 0 1 45.47-47.32h.15c0-1 .07-2 .07-3a64 64 0 0 0-49.64-62.45Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M172 72a76.45 76.45 0 0 0-12.36 1 71.93 71.93 0 0 0-55.47-63.17 8 8 0 0 0-9.59 9.58A56.05 56.05 0 0 1 40 88a56.45 56.45 0 0 1-12.59-1.42 8 8 0 0 0-9.59 9.59 72.22 72.22 0 0 0 32.29 45.06A52 52 0 0 0 92 224h80a76 76 0 0 0 0-152ZM37.37 104H40a72.08 72.08 0 0 0 72-72v-2.67a55.63 55.63 0 0 1 32 48 76.28 76.28 0 0 0-43 43.4 52 52 0 0 0-39 8.86A56.22 56.22 0 0 1 37.37 104ZM172 208H92a36 36 0 1 1 4.78-71.69c-.37 2.37-.63 4.79-.77 7.23a8 8 0 0 0 16 .92 58.91 58.91 0 0 1 1.88-11.81c0-.16.09-.32.12-.48A60.06 60.06 0 1 1 172 208Z" /></Svg>;
export default CloudMoon;