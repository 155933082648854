/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SprayBottle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spray-bottle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spray-bottle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 211.47V224a8 8 0 0 1-8 8H88a8 8 0 0 1-8-8v-56.62a32 32 0 0 1 12-25l16-12.78a32 32 0 0 0 12-25V72h40a263.14 263.14 0 0 1 40 139.47Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M200 80a8 8 0 0 0 8-8 56.06 56.06 0 0 0-56-56H80a16 16 0 0 0-16 16v48a24 24 0 0 1-24 24 8 8 0 0 0 0 16 40 40 0 0 0 40-40h32v24.62a23.87 23.87 0 0 1-9 18.74l-16 12.79a39.79 39.79 0 0 0-15 31.23V224a16 16 0 0 0 16 16h104a16 16 0 0 0 16-16v-12.53A270.88 270.88 0 0 0 174 80ZM80 32h72a40.08 40.08 0 0 1 39.2 32H80Zm112 179.47V224H88v-56.62a23.87 23.87 0 0 1 9-18.74l16-12.79a39.79 39.79 0 0 0 15-31.23V80h27.52A254.86 254.86 0 0 1 192 211.47Z" /></Svg>;
export default SprayBottle;