/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagBanner = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-banner-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-banner-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.07 52.8A6 6 0 0 0 232 50H40a6 6 0 0 0-4.24 10.24L79.51 104l-43.75 43.76A6 6 0 0 0 40 158h136.78l-30.2 63.42a6 6 0 0 0 10.84 5.16l80-168a6 6 0 0 0-.35-5.78ZM182.5 146h-128l37.75-37.76a6 6 0 0 0 0-8.48L54.49 62h168Z" /></Svg>;
export default FlagBanner;