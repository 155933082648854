/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 80a24 24 0 1 0-42 15.83l-33 40.54-27.9-64.28a24 24 0 1 0-26.2 0L87 136.37 54 95.83A24 24 0 1 0 35.94 104l15 90a12 12 0 0 0 11.84 10h130.44a12 12 0 0 0 11.84-10l15-90A24 24 0 0 0 244 80ZM128 36a16 16 0 1 1-16 16 16 16 0 0 1 16-16ZM20 80a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm177.17 112.66a4 4 0 0 1-3.95 3.34H62.78a4 4 0 0 1-4-3.34l-15-90a23.41 23.41 0 0 0 4-1.8l37.12 45.66A4 4 0 0 0 88 148a3.27 3.27 0 0 0 .54 0 4 4 0 0 0 3.13-2.37l30.56-70.3a23.8 23.8 0 0 0 11.54 0l30.56 70.3a4 4 0 0 0 3.13 2.37 3.27 3.27 0 0 0 .54 0 4 4 0 0 0 3.1-1.48l37.09-45.64a23.41 23.41 0 0 0 4 1.8ZM220 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default Crown;