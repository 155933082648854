/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FrameCorners = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="frame-corners-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-frame-corners-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 88a12 12 0 0 1 12-12h32a12 12 0 0 1 12 12v32a12 12 0 0 1-24 0v-20h-20a12 12 0 0 1-12-12Zm-68 92h32a12 12 0 0 0 0-24H84v-20a12 12 0 0 0-24 0v32a12 12 0 0 0 12 12ZM236 56v144a20 20 0 0 1-20 20H40a20 20 0 0 1-20-20V56a20 20 0 0 1 20-20h176a20 20 0 0 1 20 20Zm-24 4H44v136h168Z" /></Svg>;
export default FrameCorners;