/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Intersect = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersect__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersect'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174.63 81.37a80 80 0 1 0-93.26 93.26 80 80 0 1 0 93.26-93.26ZM100.69 136 120 155.31A63.48 63.48 0 0 1 96 160a63.48 63.48 0 0 1 4.69-24Zm33.75 11.13-25.57-25.57a64.65 64.65 0 0 1 12.69-12.69l25.57 25.57a64.65 64.65 0 0 1-12.69 12.69ZM155.31 120 136 100.69A63.48 63.48 0 0 1 160 96a63.48 63.48 0 0 1-4.69 24ZM32 96a64 64 0 0 1 126-16 80.08 80.08 0 0 0-77.95 78A64.11 64.11 0 0 1 32 96Zm128 128a64.11 64.11 0 0 1-62-48 80.08 80.08 0 0 0 78-78 64 64 0 0 1-16 126Z" /></Svg>;
export default Intersect;