/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImageBroken = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="image-broken-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-image-broken-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231 112a12 12 0 0 0-10.81-1.65l-23.81 7.93a12 12 0 0 0-7.34 6.93l-13.67 34.18-34.18 13.67a12 12 0 0 0-6.93 7.34l-7.93 23.81a12 12 0 0 0 11.4 15.79H216a20 20 0 0 0 20-20v-78.27a12 12 0 0 0-5-9.73Zm-19 84h-57.62l.91-2.73 33.79-13.51a12 12 0 0 0 6.68-6.68l13.51-33.79 2.73-.91Zm4-160H40a20 20 0 0 0-20 20v144a20 20 0 0 0 20 20h55.57a12 12 0 0 0 11.43-8.21L123.21 163l35.09-14a12 12 0 0 0 6.7-6.7l14-35.09L227.79 91A12 12 0 0 0 236 79.57V56a20 20 0 0 0-20-20ZM102.2 150.16 86.92 196H44v-22.34l48-48 17.14 17.14a12 12 0 0 0-6.94 7.36ZM212 70.92 166.16 86.2a12 12 0 0 0-7.35 6.93l-14.2 35.48-11.22 4.49-27.25-27.24a20 20 0 0 0-28.28 0L44 139.72V60h168Z" /></Svg>;
export default ImageBroken;