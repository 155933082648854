/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const YinYang = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="yin-yang-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-yin-yang-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28ZM36 128a92.1 92.1 0 0 1 92-92 44 44 0 0 1 0 88 52 52 0 0 0-37.44 88A92.14 92.14 0 0 1 36 128Zm92 92a44 44 0 0 1 0-88 52 52 0 0 0 37.44-88A92 92 0 0 1 128 220Zm8-44a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-16-96a8 8 0 1 1 8 8 8 8 0 0 1-8-8Z" /></Svg>;
export default YinYang;