/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Clover = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clover-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clover-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.25 169.21C228.34 162.67 236 146.11 236 120s-7.66-42.68-22.75-49.22c-10.66-4.61-23.59-3.6-39.17 3.14 6.73-15.58 7.75-28.51 3.14-39.17C170.67 19.66 154.12 12 128 12s-42.67 7.66-49.22 22.75c-4.61 10.66-3.59 23.59 3.14 39.17-15.58-6.74-28.51-7.75-39.17-3.14C27.66 77.32 20 93.88 20 120s7.66 42.67 22.75 49.21A35.35 35.35 0 0 0 57 172.05q11.15 0 24.94-6c-6.73 15.59-7.75 28.52-3.14 39.17C85.33 220.34 101.88 228 128 228s42.67-7.66 49.22-22.76a34.2 34.2 0 0 0 1.53-4.33c7.57 13.18 14 27.41 17.61 42A12 12 0 0 0 208 252a12.28 12.28 0 0 0 2.92-.36 12 12 0 0 0 8.73-14.55c-6-24.11-18.23-46.91-31.51-66.33a50.24 50.24 0 0 0 10.86 1.29 35.35 35.35 0 0 0 14.25-2.84ZM100.81 44.29C103.09 39 113 36 128 36s24.91 3 27.19 8.29c1.12 2.57 3.44 13-13.53 38.81-4.63 7-9.59 13.51-13.66 18.52-4.07-5-9-11.48-13.66-18.52-16.97-25.84-14.65-36.24-13.53-38.81Zm-48.52 102.9C47 144.91 44 135 44 120s3-24.91 8.29-27.19a11.53 11.53 0 0 1 4.62-.81c5.56 0 16.09 2.45 34.19 14.34A228.36 228.36 0 0 1 109.62 120c-5 4.07-11.48 9-18.52 13.66-25.84 16.97-36.24 14.65-38.81 13.53Zm102.9 48.52C152.91 201 143 204 128 204s-24.91-3-27.19-8.29c-1.12-2.58-3.44-13 13.53-38.81 4.63-7 9.59-13.51 13.66-18.52 4.07 5 9 11.48 13.66 18.52 16.97 25.84 14.65 36.23 13.53 38.81Zm9.71-62.05c-7-4.63-13.51-9.59-18.52-13.66a228.36 228.36 0 0 1 18.52-13.66c25.84-17 36.24-14.65 38.81-13.53C209 95.09 212 105 212 120s-3 24.91-8.29 27.19c-2.57 1.12-12.97 3.44-38.81-13.53Z" /></Svg>;
export default Clover;