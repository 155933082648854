/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FaceMask = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="face-mask-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-face-mask-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 77.09V136c0 56-88 72-88 72s-88-16-88-72V77.09a8 8 0 0 1 5.27-7.52l80-29.09a8 8 0 0 1 5.46 0l80 29.09a8 8 0 0 1 5.27 7.52Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M176 104a8 8 0 0 1-8 8H88a8 8 0 0 1 0-16h80a8 8 0 0 1 8 8Zm-8 24H88a8 8 0 0 0 0 16h80a8 8 0 0 0 0-16Zm88-24v24a32 32 0 0 1-32 32h-5.19c-7.19 15.8-21.79 29.43-43.23 40.16a191.16 191.16 0 0 1-46.15 15.71 7.93 7.93 0 0 1-2.86 0 191.16 191.16 0 0 1-46.15-15.71C59 189.43 44.38 175.8 37.19 160H32a32 32 0 0 1-32-32v-24a32 32 0 0 1 32-32h.85a16 16 0 0 1 9.68-10l80-29.09a16.06 16.06 0 0 1 10.94 0l80 29.09a16 16 0 0 1 9.68 10h.85a32 32 0 0 1 32 32ZM32.55 144a58.74 58.74 0 0 1-.55-8V88a16 16 0 0 0-16 16v24a16 16 0 0 0 16 16ZM208 136V77.09L128 48 48 77.09V136c0 45 69.09 61.52 80 63.84 10.89-2.32 80-18.84 80-63.84Zm32-32a16 16 0 0 0-16-16v48a58.74 58.74 0 0 1-.55 8h.55a16 16 0 0 0 16-16Z" /></Svg>;
export default FaceMask;