/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Volleyball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="volleyball-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-volleyball-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm85 135.19a92 92 0 0 1-102.18 2.57L130.31 132h89.6a91.61 91.61 0 0 1-6.91 31.19ZM85.52 46.42A91.11 91.11 0 0 1 116 36.79 92 92 0 0 1 169.29 124h-39ZM219.91 124h-42.62a100.06 100.06 0 0 0-46-87.93A92.11 92.11 0 0 1 219.91 124ZM78.59 50.42l21.3 36.89a100.09 100.09 0 0 0-53.16 83.77A91.92 91.92 0 0 1 78.59 50.42ZM55 183.94a92 92 0 0 1 48.87-89.7L123.38 128l-44.79 77.58A92.75 92.75 0 0 1 55 183.94ZM128 220a91.37 91.37 0 0 1-42.48-10.42l21.3-36.89a100.07 100.07 0 0 0 99.1 4.16A92 92 0 0 1 128 220Z" /></Svg>;
export default Volleyball;