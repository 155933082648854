/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmStrip = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-strip-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-strip-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 42H40a14 14 0 0 0-14 14v144a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14ZM38 86h84v84H38Zm96-12V54h36v20Zm-12 0H86V54h36Zm0 108v20H86v-20Zm12 0h36v20h-36Zm0-12V86h84v84Zm84-114v18h-36V54h34a2 2 0 0 1 2 2ZM40 54h34v20H38V56a2 2 0 0 1 2-2Zm-2 146v-18h36v20H40a2 2 0 0 1-2-2Zm178 2h-34v-20h36v18a2 2 0 0 1-2 2Z" /></Svg>;
export default FilmStrip;