/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14L71.79 84A68 68 0 0 0 72 220h88a91.26 91.26 0 0 0 30.66-5.24l8.46 9.31a12 12 0 0 0 17.76-16.14ZM160 196H72a44 44 0 0 1-1.8-87.95A91.91 91.91 0 0 0 68 128a12 12 0 0 0 24 0 68.22 68.22 0 0 1 2.66-18.84l77.88 85.67A68.67 68.67 0 0 1 160 196Zm92-68a91.32 91.32 0 0 1-17.53 54 12 12 0 1 1-19.41-14.11 68 68 0 0 0-89.57-98.53 12 12 0 0 1-12.2-20.66A92 92 0 0 1 252 128Z" /></Svg>;
export default CloudSlash;