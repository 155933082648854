/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileTsx = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-tsx-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-tsx-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.25 154.33 192.92 180l18.33 25.67a4 4 0 0 1-.93 5.58 3.91 3.91 0 0 1-2.32.75 4 4 0 0 1-3.26-1.67L188 186.88l-16.75 23.45a4 4 0 0 1-6.51-4.66L183.08 180l-18.34-25.67a4 4 0 0 1 6.51-4.66L188 173.12l16.74-23.45a4 4 0 0 1 6.51 4.66Zm-88.76 20.82c-10.53-3-15.08-4.91-14.42-10.08a8.51 8.51 0 0 1 3.75-6.49c6.25-4.23 18.77-2.24 23.06-1.11a4 4 0 0 0 2.05-7.74 61.33 61.33 0 0 0-10.48-1.61c-8.12-.54-14.54.75-19.1 3.82a16.66 16.66 0 0 0-7.22 12.13c-1.58 12.49 10.46 16 20.14 18.77 11.26 3.25 16.47 5.49 15.64 11.94a8.94 8.94 0 0 1-3.91 6.75c-6.27 4.17-18.61 2.05-22.83.88a4 4 0 1 0-2.15 7.7 57.89 57.89 0 0 0 14.17 1.89c5.18 0 10.83-.86 15.22-3.77a17 17 0 0 0 7.43-12.41c1.8-13.98-11.58-17.82-21.35-20.67ZM80 148H40a4 4 0 0 0 0 8h16v52a4 4 0 0 0 8 0v-52h16a4 4 0 0 0 0-8Zm132-60v24a4 4 0 0 1-8 0V92h-52a4 4 0 0 1-4-4V36H56a4 4 0 0 0-4 4v72a4 4 0 0 1-8 0V40a12 12 0 0 1 12-12h96a4 4 0 0 1 2.83 1.17l56 56A4 4 0 0 1 212 88Zm-13.66-4L156 41.65V84Z" /></Svg>;
export default FileTsx;