/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyeglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyeglasses-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyeglasses-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 44a4 4 0 0 0 0 8 20 20 0 0 1 20 20v68a40 40 0 0 0-71.2 16h-41.6A40 40 0 0 0 36 140V72a20 20 0 0 1 20-20 4 4 0 0 0 0-8 28 28 0 0 0-28 28v92a40 40 0 0 0 80 0h40a40 40 0 0 0 80 0V72a28 28 0 0 0-28-28ZM68 196a32 32 0 1 1 32-32 32 32 0 0 1-32 32Zm120 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" /></Svg>;
export default Eyeglasses;