/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Clover = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clover__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clover'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.66 165.54C225.16 159.7 232 144.37 232 120s-6.84-39.7-20.34-45.55c-11.65-5-27.24-2.23-46.46 8.35 10.58-19.22 13.39-34.81 8.35-46.46C167.7 22.84 152.37 16 128 16s-39.7 6.84-45.55 20.34c-5 11.65-2.23 27.24 8.35 46.45C71.58 72.22 56 69.4 44.34 74.45 30.84 80.3 24 95.63 24 120s6.84 39.7 20.34 45.54A31 31 0 0 0 56.8 168c9.6 0 21-3.62 34-10.79-10.58 19.2-13.39 34.79-8.35 46.44C88.3 217.15 103.63 224 128 224s39.7-6.85 45.55-20.35a32.24 32.24 0 0 0 2.34-15c10.45 16.23 19.64 34.48 24.35 53.33A8 8 0 0 0 208 248a8.13 8.13 0 0 0 1.95-.24 8 8 0 0 0 5.82-9.7c-6.94-27.76-22.27-53.8-37.86-74.79Q189.68 168 199.2 168a31 31 0 0 0 12.46-2.46Zm-6.37-76.4C214.14 93 216 108 216 120s-1.86 27-10.7 30.86c-8.36 3.63-23.52-1.31-42.68-13.91a243.4 243.4 0 0 1-22.54-17c18.41-15.58 50.32-37.27 65.21-30.81ZM97.14 42.7C101 33.86 116 32 128 32s27 1.86 30.86 10.7c3.63 8.36-1.31 23.52-13.91 42.68a243.4 243.4 0 0 1-17 22.54C112.37 89.51 90.69 57.59 97.14 42.7ZM50.71 150.86C41.86 147 40 132 40 120s1.86-27 10.7-30.86A15.64 15.64 0 0 1 57 88c8.75 0 21.34 5.17 36.4 15.07a243.4 243.4 0 0 1 22.54 17c-18.43 15.55-50.35 37.25-65.23 30.79Zm108.15 46.43C155 206.14 140 208 128 208s-27-1.86-30.86-10.7c-3.63-8.36 1.31-23.52 13.91-42.68a243.4 243.4 0 0 1 17-22.54c15.58 18.41 37.26 50.33 30.81 65.21Z" /></Svg>;
export default Clover;