/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrayArrowDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tray-arrow-down-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tray-arrow-down-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 24v92h-24.69a19.86 19.86 0 0 0-14.14 5.86L147 168h-38l-18.17-18.14A19.86 19.86 0 0 0 76.69 144H52V52ZM52 204v-36h23l18.14 18.14a19.86 19.86 0 0 0 14.17 5.86h41.38a19.86 19.86 0 0 0 14.14-5.86L181 168h23v36Zm35.51-87.51a12 12 0 0 1 17-17L116 111V76a12 12 0 0 1 24 0v35l11.51-11.52a12 12 0 0 1 17 17l-32 32a12 12 0 0 1-17 0Z" /></Svg>;
export default TrayArrowDown;