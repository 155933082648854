/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Password = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="password-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-password-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M44 56v144a4 4 0 0 1-8 0V56a4 4 0 0 1 8 0Zm97.2 58.31-25.2 8.18V96a4 4 0 0 0-8 0v26.49l-25.2-8.18a4 4 0 0 0-2.47 7.61l25.2 8.18L90 151.54a4 4 0 1 0 6.47 4.7L112 134.81l15.57 21.43a4 4 0 0 0 3.24 1.65 4 4 0 0 0 3.23-6.35l-15.57-21.44 25.2-8.18a4 4 0 0 0-2.47-7.61Zm101 2.57a4 4 0 0 0-5-2.57l-25.2 8.18V96a4 4 0 0 0-8 0v26.49l-25.2-8.18a4 4 0 0 0-2.47 7.61l25.2 8.18L186 151.54a4 4 0 1 0 6.47 4.7L208 134.81l15.57 21.43a4 4 0 0 0 3.24 1.65 4 4 0 0 0 3.23-6.35l-15.57-21.44 25.2-8.18a4 4 0 0 0 2.57-5.04Z" /></Svg>;
export default Password;