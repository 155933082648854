/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Spiral = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spiral-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spiral-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 144a4 4 0 0 1-8 0A100.11 100.11 0 0 0 136 44a92.1 92.1 0 0 0-92 92 84.09 84.09 0 0 0 84 84 76.08 76.08 0 0 0 76-76 68.07 68.07 0 0 0-68-68 60.07 60.07 0 0 0-60 60 52.06 52.06 0 0 0 52 52 44.05 44.05 0 0 0 44-44 36 36 0 0 0-36-36 28 28 0 0 0-28 28 20 20 0 0 0 20 20 12 12 0 0 0 12-12 4 4 0 0 0-4-4 4 4 0 0 1 0-8 12 12 0 0 1 12 12 20 20 0 0 1-20 20 28 28 0 0 1-28-28 36 36 0 0 1 36-36 44.05 44.05 0 0 1 44 44 52.06 52.06 0 0 1-52 52 60.07 60.07 0 0 1-60-60 68.07 68.07 0 0 1 68-68 76.08 76.08 0 0 1 76 76 84.09 84.09 0 0 1-84 84 92.1 92.1 0 0 1-92-92A100.11 100.11 0 0 1 136 36a108.12 108.12 0 0 1 108 108Z" /></Svg>;
export default Spiral;