/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyKrw = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-krw__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-krw'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 128h-22.11l21.52-53a8 8 0 1 0-14.82-6l-24 59h-41.21l-24-59a8 8 0 0 0-14.82 0l-24 59H55.38l-24-59a8 8 0 0 0-14.82 6l21.52 53H16a8 8 0 0 0 0 16h28.61l24 59a8 8 0 0 0 14.82 0l24-59h41.24l24 59a8 8 0 0 0 14.82 0l24-59H240a8 8 0 0 0 0-16ZM76 178.75 61.88 144h28.24ZM113.88 128 128 93.26 142.12 128ZM180 178.75 165.88 144h28.24Z" /></Svg>;
export default CurrencyKrw;