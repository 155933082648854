/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrainSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="train-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-train-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 26H72a30 30 0 0 0-30 30v128a30 30 0 0 0 30 30h12l-16.8 22.4a6 6 0 1 0 9.6 7.2L99 214h58l22.2 29.6a6 6 0 0 0 9.6-7.2L172 214h12a30 30 0 0 0 30-30V56a30 30 0 0 0-30-30ZM72 38h112a18 18 0 0 1 18 18v66H54V56a18 18 0 0 1 18-18Zm112 164H72a18 18 0 0 1-18-18v-50h148v50a18 18 0 0 1-18 18Zm-2-30a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-88 0a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default TrainSimple;