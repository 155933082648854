/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lightbulb = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightbulb-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightbulb-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 232a12 12 0 0 1-12 12H88a12 12 0 0 1 0-24h80a12 12 0 0 1 12 12Zm40-128a91.51 91.51 0 0 1-35.17 72.35A12.26 12.26 0 0 0 180 186v2a20 20 0 0 1-20 20H96a20 20 0 0 1-20-20v-2a12 12 0 0 0-4.7-9.51A91.57 91.57 0 0 1 36 104.52C35.73 54.69 76 13.2 125.79 12A92 92 0 0 1 220 104Zm-24 0a68 68 0 0 0-69.65-68C89.56 36.88 59.8 67.55 60 104.38a67.71 67.71 0 0 0 26.1 53.19A35.87 35.87 0 0 1 100 184h56.1a36.13 36.13 0 0 1 13.9-26.51A67.68 67.68 0 0 0 196 104Zm-20.07-5.32a48.5 48.5 0 0 0-31.91-40 12 12 0 0 0-8 22.62 24.31 24.31 0 0 1 16.09 20 12 12 0 0 0 23.86-2.64Z" /></Svg>;
export default Lightbulb;