/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatsTeardrop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chats-teardrop-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chats-teardrop-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172.29 68.9A84 84 0 0 0 12 104v64a20 20 0 0 0 20 20h52.1a84.18 84.18 0 0 0 75.9 48h64a20 20 0 0 0 20-20v-64a84 84 0 0 0-71.71-83.1ZM36 104a60 60 0 1 1 60 60H36Zm184 108h-60a60.14 60.14 0 0 1-49-25.37 83.93 83.93 0 0 0 68.55-91.37A60 60 0 0 1 220 152Z" /></Svg>;
export default ChatsTeardrop;