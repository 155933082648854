/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CirclesFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circles-four-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circles-four-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 42a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm0 64a26 26 0 1 1 26-26 26 26 0 0 1-26 26Zm96 12a38 38 0 1 0-38-38 38 38 0 0 0 38 38Zm0-64a26 26 0 1 1-26 26 26 26 0 0 1 26-26Zm-96 84a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm0 64a26 26 0 1 1 26-26 26 26 0 0 1-26 26Zm96-64a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm0 64a26 26 0 1 1 26-26 26 26 0 0 1-26 26Z" /></Svg>;
export default CirclesFour;