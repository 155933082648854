/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Spiral = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spiral-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spiral-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M250 138a12 12 0 0 1-24 0 94.11 94.11 0 0 0-94-94 84.09 84.09 0 0 0-84 84 74.09 74.09 0 0 0 74 74 64.07 64.07 0 0 0 64-64 54.06 54.06 0 0 0-54-54 44.05 44.05 0 0 0-44 44 34 34 0 0 0 34 34 24 24 0 0 0 24-24 14 14 0 0 0-14-14 4 4 0 0 0-2.82 1.17A12 12 0 0 1 124 148a20 20 0 0 1-20-20 28 28 0 0 1 28-28 38 38 0 0 1 38 38 48.05 48.05 0 0 1-48 48 58.07 58.07 0 0 1-58-58 68.07 68.07 0 0 1 68-68 78.09 78.09 0 0 1 78 78 88.1 88.1 0 0 1-88 88 98.11 98.11 0 0 1-98-98A108.12 108.12 0 0 1 132 20a118.13 118.13 0 0 1 118 118Z" /></Svg>;
export default Spiral;