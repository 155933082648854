/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryWarning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-warning-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-warning-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 60H32a20 20 0 0 0-20 20v96a20 20 0 0 0 20 20h168a20 20 0 0 0 20-20V80a20 20 0 0 0-20-20Zm12 116a12 12 0 0 1-12 12H32a12 12 0 0 1-12-12V80a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12Zm40-80v64a4 4 0 0 1-8 0V96a4 4 0 0 1 8 0Zm-140 28V96a4 4 0 0 1 8 0v28a4 4 0 0 1-8 0Zm12 32a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default BatteryWarning;