/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitBranch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-branch-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-branch-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 64a30 30 0 1 0-36 29.4V112a10 10 0 0 1-10 10H96a21.84 21.84 0 0 0-10 2.42v-31a30 30 0 1 0-12 0v69.2a30 30 0 1 0 12 0V144a10 10 0 0 1 10-10h88a22 22 0 0 0 22-22V93.4A30.05 30.05 0 0 0 230 64ZM62 64a18 18 0 1 1 18 18 18 18 0 0 1-18-18Zm36 128a18 18 0 1 1-18-18 18 18 0 0 1 18 18ZM200 82a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default GitBranch;