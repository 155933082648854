/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberFive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-five-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-five-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40v176a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16V40a16 16 0 0 1 16-16h144a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M176 160a56 56 0 0 1-93.33 41.74 8 8 0 1 1 10.66-11.92 40 40 0 1 0 .77-60.3 8 8 0 0 1-13-7.66l15.06-75.43A8 8 0 0 1 104 40h64a8 8 0 0 1 0 16h-57.44l-10.32 51.6A56 56 0 0 1 176 160Z" /></Svg>;
export default NumberFive;