/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Golf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="golf-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-golf-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 100a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-40 24a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm80-28a84 84 0 1 1-84-84 84.09 84.09 0 0 1 84 84Zm-8 0a76 76 0 1 0-76 76 76.08 76.08 0 0 0 76-76Zm-37.43 100.26C153 201.47 140.34 204 128 204s-25-2.53-38.57-7.74a4 4 0 1 0-2.86 7.48 119.31 119.31 0 0 0 37.43 8.17V248a4 4 0 0 0 8 0v-36.09a119.31 119.31 0 0 0 37.43-8.17 4 4 0 0 0-2.86-7.48Z" /></Svg>;
export default Golf;