/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cherries = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cherries-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cherries-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 74a68.2 68.2 0 0 0-8.83.57 89.81 89.81 0 0 0-21.8-25.69c-37.18-30-88.07-23.13-90.22-22.82A6 6 0 0 0 52.67 37c18.72 12.47 26.87 33.31 30.05 53.22A70 70 0 1 0 141.59 205 70 70 0 1 0 176 74Zm-38.1-15.73a77.61 77.61 0 0 1 16.86 19A70.31 70.31 0 0 0 122.35 99a69.47 69.47 0 0 0-27.42-8.69c-3.22-22.13-11.2-40.14-23.46-53.1 16.76.22 44.72 3.51 66.43 21.06Zm8.2 62.73a70.74 70.74 0 0 0-13.77-15.12 58.17 58.17 0 0 1 28.23-17.74 113.53 113.53 0 0 1 8.78 33.31c-8.61-1.5-12.92-5.51-13.1-5.68A6 6 0 0 0 146.1 121ZM88 218a58 58 0 0 1-3.92-115.85 177.17 177.17 0 0 1-1.15 35.52c-9.66-1.25-14.5-5.73-14.69-5.91a6 6 0 0 0-8.48 8.48c.4.4 10 9.76 28.24 9.76s27.84-9.36 28.24-9.76a6 6 0 1 0-8.43-8.53c-.22.2-4.41 4.08-12.72 5.64a186.4 186.4 0 0 0 1.11-34.76A58 58 0 0 1 88 218Zm88-16a57.75 57.75 0 0 1-27.47-6.91 69.73 69.73 0 0 0 0-70.12c2.65 2.26 11.88 9 27.44 9 18.28 0 27.84-9.36 28.24-9.76a6 6 0 0 0 0-8.51 6 6 0 0 0-8.46 0c-.23.22-5 4.59-14.36 5.91a129.06 129.06 0 0 0-8.66-35.52c1.06-.06 2.14-.1 3.21-.1a58 58 0 0 1 0 116Z" /></Svg>;
export default Cherries;