/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SupersetProperOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="superset-proper-of-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-superset-proper-of-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 128a80 80 0 0 1-80 80H64V48h72a80 80 0 0 1 80 80Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M224 128a88.1 88.1 0 0 1-88 88H64a8 8 0 0 1 0-16h72a72 72 0 0 0 0-144H64a8 8 0 0 1 0-16h72a88.1 88.1 0 0 1 88 88Z" /></Svg>;
export default SupersetProperOf;