/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryFull = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-full-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-full-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 52H28A28 28 0 0 0 0 80v96a28 28 0 0 0 28 28h168a28 28 0 0 0 28-28V80a28 28 0 0 0-28-28Zm4 124a4 4 0 0 1-4 4H28a4 4 0 0 1-4-4V80a4 4 0 0 1 4-4h168a4 4 0 0 1 4 4Zm-16-72v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Zm-40 0v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Zm-40 0v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Zm-40 0v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Zm192 0v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Z" /></Svg>;
export default BatteryFull;