/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderLock = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-lock-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-lock-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 164h-12v-8a24 24 0 0 0-48 0v8h-12a4 4 0 0 0-4 4v40a4 4 0 0 0 4 4h72a4 4 0 0 0 4-4v-40a4 4 0 0 0-4-4Zm-52-8a16 16 0 0 1 32 0v8h-32Zm48 48h-64v-32h64Zm-4-128h-86.34l-28.49-28.49A12 12 0 0 0 92.69 44H40a12 12 0 0 0-12 12v144.62A11.4 11.4 0 0 0 39.38 212h73.18a4 4 0 0 0 0-8H39.38a3.39 3.39 0 0 1-3.38-3.38V84h180a4 4 0 0 1 4 4v16a4 4 0 0 0 8 0V88a12 12 0 0 0-12-12ZM40 52h52.69a4 4 0 0 1 2.82 1.17L118.34 76H36V56a4 4 0 0 1 4-4Z" /></Svg>;
export default FolderLock;