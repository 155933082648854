/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RssSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 192H64V40a152 152 0 0 1 152 152Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M224 192a8 8 0 0 1-16 0c0-79.4-64.6-144-144-144a8 8 0 0 1 0-16c88.22 0 160 71.78 160 160ZM64 104a8 8 0 0 0 0 16 72.08 72.08 0 0 1 72 72 8 8 0 0 0 16 0 88.1 88.1 0 0 0-88-88Zm4 72a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z" /></Svg>;
export default RssSimple;