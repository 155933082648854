/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinArea = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-area-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-area-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 80a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm-18.92 67.73C78.05 127.35 68 103.93 68 80a60 60 0 0 1 120 0c0 23.93-10.05 47.35-29.08 67.73A136.54 136.54 0 0 1 130 171.47a4 4 0 0 1-4 0 136.54 136.54 0 0 1-28.92-23.74ZM76 80c0 47.09 42.25 77 52 83.3 9.75-6.28 52-36.21 52-83.3a52 52 0 0 0-104 0Zm125.38 71.38a4 4 0 0 0-2.76 7.51c18.67 6.89 29.38 16 29.38 25.11 0 17.39-40.18 36-100 36S28 201.39 28 184c0-9.07 10.71-18.22 29.38-25.11a4 4 0 0 0-2.76-7.51C32.29 159.62 20 171.21 20 184c0 12.46 11.73 23.83 33 32 20.09 7.73 46.72 12 75 12s54.89-4.25 75-12c21.29-8.19 33-19.56 33-32 0-12.79-12.29-24.38-34.62-32.62Z" /></Svg>;
export default MapPinArea;