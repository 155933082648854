/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PicnicTable = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="picnic-table-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-picnic-table-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 192H32L96 64h64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M248 128h-47.06l-28-56H192a8 8 0 0 0 0-16H64a8 8 0 0 0 0 16h19.06l-28 56H8a8 8 0 0 0 0 16h39.06l-22.22 44.42a8 8 0 0 0 3.58 10.73A7.9 7.9 0 0 0 32 200a8 8 0 0 0 7.17-4.42L64.94 144h126.12l25.78 51.58A8 8 0 0 0 224 200a7.9 7.9 0 0 0 3.57-.85 8 8 0 0 0 3.58-10.73L208.94 144H248a8 8 0 0 0 0-16Zm-175.06 0 28-56h54.12l28 56Z" /></Svg>;
export default PicnicTable;