/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartPie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-pie-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-pie-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 32v96l-83.14 48A96 96 0 0 1 128 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm71.87 53.27L136 114.14V40.37a88 88 0 0 1 63.87 36.9ZM120 40.37v83l-71.89 41.5A88 88 0 0 1 120 40.37ZM128 216a88 88 0 0 1-71.87-37.27l151.76-87.61A88 88 0 0 1 128 216Z" /></Svg>;
export default ChartPie;