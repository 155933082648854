/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Guitar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="guitar-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-guitar-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m248.24 47.76-40-40a6 6 0 0 0-8.48 8.48l3.76 3.76-62.62 62.62C118.54 70.42 93.29 71 78.17 86.16a39.94 39.94 0 0 0-9 13.69 10.24 10.24 0 0 1-9.3 6.15c-14.06.48-26.27 5.5-35.3 14.53C13 132.18 8 149 10.72 167.79 13.32 186 22.78 204 37.37 218.63S70 242.68 88.21 245.28a70 70 0 0 0 9.88.72c14.79 0 27.81-5 37.35-14.57 9-9 14.05-21.23 14.53-35.3a10.24 10.24 0 0 1 6.18-9.35 39.94 39.94 0 0 0 13.69-8.95c15.12-15.12 15.74-40.37 3.54-62.73L236 52.48l3.76 3.76a6 6 0 0 0 8.48-8.48Zm-86.88 121.59a28.06 28.06 0 0 1-9.63 6.27 22.22 22.22 0 0 0-13.73 20.1c-.37 11-4.18 20.39-11 27.22-8.89 8.89-22 12.61-37 10.46-15.65-2.23-31.29-10.49-44.06-23.25s-21-28.41-23.25-44.06c-2.15-15 1.57-28.16 10.46-37.05 6.83-6.84 16.25-10.65 27.22-11a22.21 22.21 0 0 0 20.1-13.76 28.12 28.12 0 0 1 6.27-9.62c5.78-5.89 13.83-8.66 22.7-8.66A52.74 52.74 0 0 1 132 91.49l-22.6 22.63a30 30 0 1 0 32.48 32.48l22.64-22.6c8.11 16.82 7.48 34.68-3.16 45.35ZM151 105a73 73 0 0 1 7.17 8.34L139.5 132a30.18 30.18 0 0 0-15.5-15.5l18.62-18.62A72.16 72.16 0 0 1 151 105Zm-39 21a18 18 0 0 1 18 18 18 18 0 1 1-18-18Zm47.44-29.44a83.19 83.19 0 0 0-8.28-7.23L176 64.48 191.52 80l-24.84 24.83a84.5 84.5 0 0 0-7.23-8.28Zm40.55-25L184.49 56 212 28.48 227.52 44ZM92.25 195.75a6 6 0 1 1-8.48 8.48l-32-32a6 6 0 0 1 8.48-8.48Z" /></Svg>;
export default Guitar;