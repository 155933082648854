/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserSound = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-sound-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-sound-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152.5 164.53a72 72 0 1 0-89 0 124.08 124.08 0 0 0-48.69 35.75 12 12 0 0 0 18.38 15.44C46.88 199.42 71 180 108 180s61.12 19.42 74.81 35.72a12 12 0 1 0 18.38-15.44 124 124 0 0 0-48.69-35.75ZM60 108a48 48 0 1 1 48 48 48.05 48.05 0 0 1-48-48Zm192 0a143.09 143.09 0 0 1-11.61 56.73 12 12 0 1 1-22-9.46 120.48 120.48 0 0 0 0-94.54 12 12 0 1 1 22-9.46A143.09 143.09 0 0 1 252 108Zm-45-43.24a108.26 108.26 0 0 1 0 86.48 12 12 0 0 1-22-9.62 84.35 84.35 0 0 0 0-67.24 12 12 0 1 1 22-9.62Z" /></Svg>;
export default UserSound;