/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Clover = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clover-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clover-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M210.86 163.71C223.56 158.21 230 143.5 230 120s-6.44-38.21-19.14-43.71c-14.5-6.29-34 1.5-51 11.8 10.3-16.93 18.08-36.45 11.8-50.95C166.21 24.44 151.5 18 128 18s-38.21 6.44-43.71 19.14c-6.28 14.5 1.5 34 11.8 51C79.17 77.79 59.64 70 45.14 76.29 32.44 81.79 26 96.5 26 120s6.44 38.21 19.14 43.71A29.08 29.08 0 0 0 56.78 166c12.42 0 26.6-6.33 39.32-14.08-10.31 16.93-18.1 36.46-11.81 51C89.79 215.56 104.5 222 128 222s38.21-6.44 43.71-19.14c3-6.82 2.79-14.76.72-23 12.62 18.21 24.22 39.49 29.75 61.6A6 6 0 0 0 208 246a6.14 6.14 0 0 0 1.46-.18 6 6 0 0 0 4.36-7.28c-7.56-30.24-25.2-58.44-42.18-80.2 9.32 4.51 18.88 7.63 27.58 7.63a29.11 29.11 0 0 0 11.64-2.26ZM95.3 41.91C98.73 34 109.73 30 128 30s29.27 4 32.7 11.91c3.9 9-1.11 24.85-14.11 44.61A247.27 247.27 0 0 1 128 111a247.27 247.27 0 0 1-18.59-24.45c-13-19.79-18.01-35.64-14.11-44.64ZM49.91 152.7C42 149.27 38 138.27 38 120s4-29.27 11.91-32.7a17.46 17.46 0 0 1 7-1.29c9.26 0 22.27 5.28 37.65 15.4A246.07 246.07 0 0 1 119 120a246.07 246.07 0 0 1-24.45 18.59c-19.79 13-35.64 18.01-44.64 14.11Zm110.79 45.39C157.27 206 146.27 210 128 210s-29.27-4-32.7-11.91c-3.9-9 1.11-24.85 14.11-44.61A246.07 246.07 0 0 1 128 129a246.07 246.07 0 0 1 18.59 24.45c13 19.79 18.01 35.63 14.11 44.64Zm.78-59.5A246.07 246.07 0 0 1 137 120a246.07 246.07 0 0 1 24.45-18.59c19.76-13 35.61-18 44.61-14.11C214 90.73 218 101.73 218 120s-4 29.27-11.91 32.7c-9 3.9-24.85-1.11-44.61-14.11Z" /></Svg>;
export default Clover;