/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Share = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="share-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-share-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m228.24 108.24-48 48a6 6 0 0 1-8.48-8.48L209.51 110H165a89.94 89.94 0 0 0-87.17 67.5 6 6 0 0 1-11.62-3A101.94 101.94 0 0 1 165 98h44.53l-37.77-37.76a6 6 0 0 1 8.48-8.48l48 48a6 6 0 0 1 0 8.48ZM192 210H38V88a6 6 0 0 0-12 0v128a6 6 0 0 0 6 6h160a6 6 0 0 0 0-12Z" /></Svg>;
export default Share;