/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-nine-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-nine-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm-33.7 105.62L132.62 188a8 8 0 0 1-13.86-8l16.52-28.61a44.79 44.79 0 0 1-7.28.61 44.05 44.05 0 1 1 38.3-22.38ZM156 108a28 28 0 1 1-28-28 28 28 0 0 1 28 28Z" /></Svg>;
export default NumberNine;