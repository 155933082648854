/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BabyCarriage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-carriage-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-carriage-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 36h-8a12 12 0 0 0-12 12v60H51.77A36 36 0 0 0 16 76a4 4 0 0 0 0 8 28 28 0 0 1 28 28 76.08 76.08 0 0 0 76 76h40a76 76 0 0 0 0-152Zm67.87 72H155.4l53.71-43a67.76 67.76 0 0 1 18.76 43ZM152 44h8a67.72 67.72 0 0 1 43.2 15.52L148 103.68V48a4 4 0 0 1 4-4Zm8 136h-40a68.08 68.08 0 0 1-67.88-64h175.76A68.08 68.08 0 0 1 160 180Zm-60 44a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm104 0a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default BabyCarriage;