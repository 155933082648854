/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Exclude = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="exclude-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-exclude-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 160a80 80 0 0 1-158.64 14.64 80 80 0 0 0 93.28-93.28A80 80 0 0 1 240 160Zm-80-80a80.29 80.29 0 0 1 14.64 1.36 80 80 0 1 0-93.28 93.28A80 80 0 0 1 160 80Z" /></Svg>;
export default Exclude;