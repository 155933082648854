/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StopCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stop-circle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stop-circle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 32a96 96 0 1 0 96 96 96 96 0 0 0-96-96Zm32 128H96V96h64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm32-128H96a8 8 0 0 0-8 8v64a8 8 0 0 0 8 8h64a8 8 0 0 0 8-8V96a8 8 0 0 0-8-8Zm-8 64h-48v-48h48Z" /></Svg>;
export default StopCircle;