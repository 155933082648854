/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrosoftPowerpointLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microsoft-powerpoint-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microsoft-powerpoint-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M94 88H76a12 12 0 0 0-12 12v52a12 12 0 0 0 24 0v-4h6a30 30 0 0 0 0-60Zm0 36h-6v-12h6a6 6 0 0 1 0 12Zm42-104a108.16 108.16 0 0 0-76.73 32H40a20 20 0 0 0-20 20v108a20 20 0 0 0 20 20h15.5A108 108 0 1 0 136 20Zm0 24a84.12 84.12 0 0 1 83.13 72H164V72a20 20 0 0 0-20-20h-43.73A83.88 83.88 0 0 1 136 44ZM44 76h96v100H44Zm92 136a83.67 83.67 0 0 1-43.32-12H144a20 20 0 0 0 20-20v-40h55.13A84.12 84.12 0 0 1 136 212Z" /></Svg>;
export default MicrosoftPowerpointLogo;