/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lighthouse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lighthouse-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lighthouse-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 84a4 4 0 0 0-4 4v20h-18.77L180 55.6a4 4 0 0 0-1.34-2.6l-42.88-38.13-.1-.09a12 12 0 0 0-15.36 0l-.1.09L77.34 53A4 4 0 0 0 76 55.6L70.77 108H52V88a4 4 0 0 0-8 0v24a4 4 0 0 0 4 4h22l-9.92 98.86A12 12 0 0 0 72 228h112a12 12 0 0 0 11.94-13.16L186 116h22a4 4 0 0 0 4-4V88a4 4 0 0 0-4-4Zm-82.52-63.11a4 4 0 0 1 5 0l35 31.11h-75ZM83.62 60h88.76l4.81 48H132V88a4 4 0 0 0-8 0v20H78.81ZM187 218.69a3.94 3.94 0 0 1-3 1.31H72a4 4 0 0 1-4-4.36L72.39 172h111.22l4.39 43.62a3.93 3.93 0 0 1-1 3.07ZM182.81 164H73.19L78 116h100Z" /></Svg>;
export default Lighthouse;