/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsThreeOutline = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-three-outline-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-three-outline-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 128a24 24 0 1 1-24-24 24 24 0 0 1 24 24ZM48 104a24 24 0 1 0 24 24 24 24 0 0 0-24-24Zm160 0a24 24 0 1 0 24 24 24 24 0 0 0-24-24Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 96a32 32 0 1 0 32 32 32 32 0 0 0-32-32Zm0 48a16 16 0 1 1 16-16 16 16 0 0 1-16 16ZM48 96a32 32 0 1 0 32 32 32 32 0 0 0-32-32Zm0 48a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm160-48a32 32 0 1 0 32 32 32 32 0 0 0-32-32Zm0 48a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default DotsThreeOutline;