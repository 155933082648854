/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-focus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-focus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 40v36a6 6 0 0 1-12 0V46h-30a6 6 0 0 1 0-12h36a6 6 0 0 1 6 6Zm-6 134a6 6 0 0 0-6 6v30h-30a6 6 0 0 0 0 12h36a6 6 0 0 0 6-6v-36a6 6 0 0 0-6-6ZM76 210H46v-30a6 6 0 0 0-12 0v36a6 6 0 0 0 6 6h36a6 6 0 0 0 0-12ZM40 82a6 6 0 0 0 6-6V46h30a6 6 0 0 0 0-12H40a6 6 0 0 0-6 6v36a6 6 0 0 0 6 6Zm136 92a6 6 0 0 1-4.8-2.4 54 54 0 0 0-86.4 0 6 6 0 1 1-9.6-7.2 65.65 65.65 0 0 1 29.69-22.26 38 38 0 1 1 46.22 0 65.65 65.65 0 0 1 29.69 22.26 6 6 0 0 1-4.8 9.6Zm-48-36a26 26 0 1 0-26-26 26 26 0 0 0 26 26Z" /></Svg>;
export default UserFocus;