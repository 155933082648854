/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WashingMachine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="washing-machine-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-washing-machine-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-80 152a56 56 0 1 1 56-56 56 56 0 0 1-56 56Zm60-104a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm-54.34 29.66-32 32a8 8 0 0 1-11.32-11.32l32-32a8 8 0 0 1 11.32 11.32Zm32-3.32a8 8 0 0 1 0 11.32l-48 48a8 8 0 0 1-11.32-11.32l48-48a8 8 0 0 1 11.32 0Z" /></Svg>;
export default WashingMachine;