/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ToggleRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toggle-right-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toggle-right-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 52H80a76 76 0 0 0 0 152h96a76 76 0 0 0 0-152Zm0 128H80a52 52 0 0 1 0-104h96a52 52 0 0 1 0 104Zm0-92a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 56a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default ToggleRight;