/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowLineLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-line-left-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-line-left-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 56v144l-72-72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M224 120h-72V56a8 8 0 0 0-13.66-5.66l-72 72a8 8 0 0 0 0 11.32l72 72A8 8 0 0 0 152 200v-64h72a8 8 0 0 0 0-16Zm-88 60.69L83.31 128 136 75.31ZM48 40v176a8 8 0 0 1-16 0V40a8 8 0 0 1 16 0Z" /></Svg>;
export default ArrowLineLeft;