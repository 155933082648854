/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-focus-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-focus-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 112a32 32 0 1 1-32-32 32 32 0 0 1 32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M224 40v36a8 8 0 0 1-16 0V48h-28a8 8 0 0 1 0-16h36a8 8 0 0 1 8 8Zm-8 132a8 8 0 0 0-8 8v28h-28a8 8 0 0 0 0 16h36a8 8 0 0 0 8-8v-36a8 8 0 0 0-8-8ZM76 208H48v-28a8 8 0 0 0-16 0v36a8 8 0 0 0 8 8h36a8 8 0 0 0 0-16ZM40 84a8 8 0 0 0 8-8V48h28a8 8 0 0 0 0-16H40a8 8 0 0 0-8 8v36a8 8 0 0 0 8 8Zm136 92a8 8 0 0 1-6.41-3.19 52 52 0 0 0-83.2 0 8 8 0 1 1-12.8-9.62A67.94 67.94 0 0 1 101 141.51a40 40 0 1 1 53.94 0 67.94 67.94 0 0 1 27.43 21.68A8 8 0 0 1 176 176Zm-48-40a24 24 0 1 0-24-24 24 24 0 0 0 24 24Z" /></Svg>;
export default UserFocus;