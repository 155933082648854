/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GpsSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gps-slash__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gps-slash'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 128a8 8 0 0 1-8 8h-24.36a87 87 0 0 1-7.33 28 8 8 0 0 1-7.3 4.73 7.9 7.9 0 0 1-3.27-.71 8 8 0 0 1-4-10.57 72.06 72.06 0 0 0-88.81-97.69 8 8 0 1 1-5.13-15.15 87.21 87.21 0 0 1 20.2-4.24V16a8 8 0 0 1 16 0v24.37A88.13 88.13 0 0 1 215.63 120H240a8 8 0 0 1 8 8Zm-34.08 82.62a8 8 0 1 1-11.84 10.76L181 198.23a87.69 87.69 0 0 1-45 17.4V240a8 8 0 0 1-16 0v-24.37A88.13 88.13 0 0 1 40.37 136H16a8 8 0 0 1 0-16h24.37a88.31 88.31 0 0 1 22.77-51.46L42.08 45.38a8 8 0 1 1 11.84-10.76Zm-43.72-24.3L74 80.45a72 72 0 0 0 96.2 105.87Z" /></Svg>;
export default GpsSlash;