/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PictureInPicture = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="picture-in-picture-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-picture-in-picture-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 64v64h-88v72H40a8 8 0 0 1-8-8V64a8 8 0 0 1 8-8h176a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 48H40a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16ZM40 64h176v56h-80a8 8 0 0 0-8 8v64H40Zm176 128h-72v-56h72v56Z" /></Svg>;
export default PictureInPicture;