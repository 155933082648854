/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Joystick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="joystick__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-joystick'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 144h-72V95.19a40 40 0 1 0-16 0V144H48a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16ZM104 56a24 24 0 1 1 24 24 24 24 0 0 1-24-24Zm104 152H48v-48h160v48Zm-40-96h32a8 8 0 0 1 0 16h-32a8 8 0 0 1 0-16Z" /></Svg>;
export default Joystick;