/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiscordLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="discord-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-discord-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M108 136a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm56-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm76.07 76.56-67 29.71A20.15 20.15 0 0 1 146 214.9l-8.54-23.13c-3.13.14-6.27.24-9.45.24s-6.32-.1-9.45-.24L110 214.9a20.19 20.19 0 0 1-27.08 11.37l-67-29.71a19.93 19.93 0 0 1-11.3-23.15L34.15 57a20 20 0 0 1 16.22-14.81l36.06-5.93a20.26 20.26 0 0 1 22.79 14.84l4.41 17.41c4.74-.33 9.52-.51 14.37-.51s9.63.18 14.37.51l4.41-17.41a20.25 20.25 0 0 1 22.79-14.84l36.06 5.93A20 20 0 0 1 221.85 57l29.53 116.38a19.93 19.93 0 0 1-11.31 23.18ZM227.28 176 199.23 65.46l-30.07-4.94-2.84 11.17c2.9.58 5.78 1.2 8.61 1.92a12 12 0 1 1-5.86 23.27A168.43 168.43 0 0 0 128 92a168.43 168.43 0 0 0-41.07 4.88 12 12 0 0 1-5.86-23.27c2.83-.72 5.71-1.34 8.61-1.92l-2.83-11.17-30.08 4.94L28.72 176l60.22 26.7 5-13.57c-4.37-.76-8.67-1.65-12.88-2.71a12 12 0 0 1 5.86-23.28A168.43 168.43 0 0 0 128 168a168.43 168.43 0 0 0 41.07-4.88 12 12 0 0 1 5.86 23.28c-4.21 1.06-8.51 1.95-12.88 2.71l5 13.57Z" /></Svg>;
export default DiscordLogo;