/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceMobileCamera = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-mobile-camera-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-mobile-camera-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 12H80a28 28 0 0 0-28 28v176a28 28 0 0 0 28 28h96a28 28 0 0 0 28-28V40a28 28 0 0 0-28-28Zm4 204a4 4 0 0 1-4 4H80a4 4 0 0 1-4-4V40a4 4 0 0 1 4-4h96a4 4 0 0 1 4 4ZM144 68a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default DeviceMobileCamera;