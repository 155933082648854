/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FunnelSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="funnel-simple-x-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-funnel-simple-x-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 56v144a16 16 0 0 1-16 16h-8l-16-16-16 16H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M192 144H64a8 8 0 0 1 0-16h128a8 8 0 0 1 0 16Zm40-64H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16Zm-104 96h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16Zm93.66-5.66a8 8 0 0 0-11.32 0L192 188.69l-18.34-18.35a8 8 0 0 0-11.32 11.32L180.69 200l-18.35 18.34a8 8 0 0 0 11.32 11.32L192 211.31l18.34 18.35a8 8 0 0 0 11.32-11.32L203.31 200l18.35-18.34a8 8 0 0 0 0-11.32Z" /></Svg>;
export default FunnelSimpleX;