/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WechatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wechat-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wechat-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.07 186.76a80 80 0 0 0-62.49-114.17 80 80 0 1 0-145.65 66.17l-7.27 24.71a16 16 0 0 0 19.87 19.87l24.71-7.27a79 79 0 0 0 25.19 7.35 80 80 0 0 0 108.33 40.65l24.71 7.27a16 16 0 0 0 19.87-19.87ZM132 152a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm-52 0a80.32 80.32 0 0 0 1.3 14.3 63.45 63.45 0 0 1-15.49-5.85 8 8 0 0 0-6-.63L32 168l8.17-27.76a8 8 0 0 0-.63-6 64 64 0 0 1 112.14-61.81A80.12 80.12 0 0 0 80 152Zm108 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default WechatLogo;