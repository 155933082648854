/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SquareSplitVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="square-split-vertical-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-square-split-vertical-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 42H56a14 14 0 0 0-14 14v144a14 14 0 0 0 14 14h144a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14ZM56 54h144a2 2 0 0 1 2 2v66H54V56a2 2 0 0 1 2-2Zm144 148H56a2 2 0 0 1-2-2v-66h148v66a2 2 0 0 1-2 2Z" /></Svg>;
export default SquareSplitVertical;