/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const OfficeChair = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="office-chair-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-office-chair-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 128a4 4 0 0 1-4 4h-20.18A44.06 44.06 0 0 1 176 172h-44v32h28a28 28 0 0 1 28 28 4 4 0 0 1-8 0 20 20 0 0 0-20-20h-28v20a4 4 0 0 1-8 0v-20H96a20 20 0 0 0-20 20 4 4 0 0 1-8 0 28 28 0 0 1 28-28h28v-32H80a44.06 44.06 0 0 1-43.82-40H16a4 4 0 0 1 0-8h24a4 4 0 0 1 4 4 36 36 0 0 0 36 36h96a36 36 0 0 0 36-36 4 4 0 0 1 4-4h24a4 4 0 0 1 4 4Zm-173.06 7.86a12 12 0 0 1-2.82-9.56l13.72-96A12.06 12.06 0 0 1 93.72 20h68.56a12.06 12.06 0 0 1 11.88 10.3l13.72 96A12 12 0 0 1 176 140H80a12 12 0 0 1-9.06-4.14Zm6-5.24A3.93 3.93 0 0 0 80 132h96a3.93 3.93 0 0 0 3-1.38 4 4 0 0 0 .94-3.19l-13.72-96a4 4 0 0 0-4-3.43h-68.5a4 4 0 0 0-4 3.43l-13.72 96a4 4 0 0 0 1 3.19Z" /></Svg>;
export default OfficeChair;