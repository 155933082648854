/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatsTeardrop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chats-teardrop-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chats-teardrop-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168.16 74.42A78 78 0 0 0 18 104v64a14 14 0 0 0 14 14h56a78.15 78.15 0 0 0 72 48h64a14 14 0 0 0 14-14v-64a78 78 0 0 0-69.84-77.58ZM30 168v-64a66 66 0 1 1 66 66H32a2 2 0 0 1-2-2Zm196 48a2 2 0 0 1-2 2h-64a66.13 66.13 0 0 1-58.89-36.19 77.92 77.92 0 0 0 71-94.68A66 66 0 0 1 226 152Z" /></Svg>;
export default ChatsTeardrop;