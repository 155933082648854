/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lasso = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lasso__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lasso'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M205.73 59.93C184.85 47.08 157.24 40 128 40s-56.85 7.08-77.73 19.93C28.17 73.52 16 92 16 112s12.17 38.44 34.27 52c19 11.67 43.49 18.56 69.73 19.73a37.35 37.35 0 0 1-18.58 33c-14.64 8.86-34.62 9.52-49.72 1.64a8 8 0 1 0-7.4 14.18A66.4 66.4 0 0 0 75 240a67.31 67.31 0 0 0 34.74-9.5c17-10.27 26.29-26.86 26.29-46.7 26.24-1.17 50.76-8.06 69.73-19.73C227.83 150.44 240 132 240 112s-12.17-38.48-34.27-52.07ZM67.41 155.18c5.24-9.55 15.44-12 23.53-11 10.9 1.42 21.86 9.13 26.61 23.42-18.44-1.15-35.7-5.44-50.14-12.42Zm129.94-4.77c-16.95 10.43-39.17 16.53-63.13 17.43a54.37 54.37 0 0 0-11.39-23.07A47.17 47.17 0 0 0 93 128.35c-17-2.2-31.72 5.11-39.38 18.7C39.64 137 32 124.73 32 112c0-14.21 9.47-27.86 26.65-38.43C77.05 62.23 101.68 56 128 56s51 6.23 69.35 17.55C214.53 84.12 224 97.77 224 112s-9.47 27.84-26.65 38.41Z" /></Svg>;
export default Lasso;