/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seatbelt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seatbelt-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seatbelt-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 110a42 42 0 1 0-42-42 42 42 0 0 0 42 42Zm0-72a30 30 0 1 1-30 30 30 30 0 0 1 30-30Zm78 186a6 6 0 0 1-6 6H56a6 6 0 0 1-4-10.5l100.58-88.75a66 66 0 0 0-89.78 50.72 6 6 0 0 1-5.91 5.05 6.2 6.2 0 0 1-1-.07 6 6 0 0 1-5-6.88A78 78 0 0 1 162.49 122L188 99.5a6 6 0 1 1 7.94 9L71.87 218H200a6 6 0 0 1 6 6Zm-13.92-76.48A77.53 77.53 0 0 1 206 192a6 6 0 0 1-12 0 65.62 65.62 0 0 0-11.77-37.63 6 6 0 0 1 9.85-6.85Z" /></Svg>;
export default Seatbelt;