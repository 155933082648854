/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Planet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="planet-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-planet-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M245.11 60.68c-7.65-13.19-27.85-16.16-58.5-8.66a96 96 0 0 0-153.8 88.28C5.09 169 5.49 186 10.9 195.32 16 204.16 26.64 208 40.64 208a124.11 124.11 0 0 0 28.79-4 96 96 0 0 0 153.78-88.25c12.51-13 20.83-25.35 23.66-35.92 1.96-7.32 1.37-13.76-1.76-19.15Zm-13.69 15c-6.11 22.78-48.65 57.31-87.52 79.64-67.81 39-113.62 41.52-119.16 32-1.46-2.51-.65-7.24 2.22-13a80.06 80.06 0 0 1 10.28-15.05 95.53 95.53 0 0 0 6.23 14.18 4 4 0 0 0 4 2.12 122.14 122.14 0 0 0 16.95-3.32c21.23-5.55 46.63-16.48 71.52-30.78s47-30.66 62.45-46.15a122.74 122.74 0 0 0 11.31-12.87 4 4 0 0 0 .17-4.52 96.26 96.26 0 0 0-9.1-12.46c14.21-2.35 27.37-2.17 30.5 3.24.92 1.57.97 3.92.15 6.98Z" /></Svg>;
export default Planet;