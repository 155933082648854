/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Certificate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="certificate-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-certificate-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M124 136a4 4 0 0 1-4 4H72a4 4 0 0 1 0-8h48a4 4 0 0 1 4 4Zm-4-36H72a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm108 59.74V224a4 4 0 0 1-6 3.47l-26-14.86-26 14.86a4 4 0 0 1-6-3.47v-28H40a12 12 0 0 1-12-12V56a12 12 0 0 1 12-12h176a12 12 0 0 1 12 12v32.26a47.92 47.92 0 0 1 0 71.48ZM196 84a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm-32 104v-28.26a48 48 0 0 1 56-77.28V56a4 4 0 0 0-4-4H40a4 4 0 0 0-4 4v128a4 4 0 0 0 4 4Zm56-22.46a47.81 47.81 0 0 1-48 0v51.57l22-12.58a4 4 0 0 1 4 0l22 12.58Z" /></Svg>;
export default Certificate;