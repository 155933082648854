/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PoliceCar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="police-car-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-police-car-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 100h-9l-26.28-45.92A20 20 0 0 0 187.36 44H68.64a20 20 0 0 0-17.36 10.08L25 100h-9a12 12 0 0 0 0 24h4v76a20 20 0 0 0 20 20h28a20 20 0 0 0 20-20v-20h80v20a20 20 0 0 0 20 20h28a20 20 0 0 0 20-20v-76h4a12 12 0 0 0 0-24ZM71 68h114l18.28 32H52.68Zm-7 128H44v-16h20Zm148 0h-20v-16h20Zm0-40H44v-32h168ZM92 20a12 12 0 0 1 12-12h48a12 12 0 0 1 0 24h-48a12 12 0 0 1-12-12Z" /></Svg>;
export default PoliceCar;