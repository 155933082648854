/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryWarning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-warning-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-warning-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 56H32A24 24 0 0 0 8 80v96a24 24 0 0 0 24 24h168a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24Zm-92 32a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm8 88a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm140-80v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Z" /></Svg>;
export default BatteryWarning;