/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Truck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m253.57 117.78-14-35a13.93 13.93 0 0 0-13-8.8H190V64a6 6 0 0 0-6-6H32a14 14 0 0 0-14 14v112a14 14 0 0 0 14 14h18.6a30 30 0 0 0 58.8 0h53.2a30 30 0 0 0 58.8 0H240a14 14 0 0 0 14-14v-64a6 6 0 0 0-.43-2.22ZM190 86h36.58a2 2 0 0 1 1.86 1.26l10.7 26.74H190ZM30 72a2 2 0 0 1 2-2h146v68H30Zm50 138a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm82.6-24h-53.2a30 30 0 0 0-58.8 0H32a2 2 0 0 1-2-2v-34h148v15.48A30.1 30.1 0 0 0 162.6 186Zm29.4 24a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm50-26a2 2 0 0 1-2 2h-18.6a30.05 30.05 0 0 0-29.4-24c-.67 0-1.34 0-2 .07V126h52Z" /></Svg>;
export default Truck;