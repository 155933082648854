/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popsicle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popsicle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popsicle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 80v96a8 8 0 0 1-8 8H72a8 8 0 0 1-8-8V80a64 64 0 0 1 64-64 64 64 0 0 1 64 64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 8a72.08 72.08 0 0 0-72 72v96a16 16 0 0 0 16 16h32v40a24 24 0 0 0 48 0v-40h32a16 16 0 0 0 16-16V80a72.08 72.08 0 0 0-72-72Zm8 224a8 8 0 0 1-16 0v-40h16Zm48-56H72V80a56 56 0 0 1 112 0v96ZM120 72v80a8 8 0 0 1-16 0V72a8 8 0 0 1 16 0Zm32 0v80a8 8 0 0 1-16 0V72a8 8 0 0 1 16 0Z" /></Svg>;
export default Popsicle;