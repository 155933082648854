/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudFog = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-fog-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-fog-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 206H72a6 6 0 0 1 0-12h48a6 6 0 0 1 0 12Zm64-12h-24a6 6 0 0 0 0 12h24a6 6 0 0 0 0-12Zm-24 32h-56a6 6 0 0 0 0 12h56a6 6 0 0 0 0-12Zm70-126a74.09 74.09 0 0 1-74 74H76a50 50 0 1 1 10.2-99A74.08 74.08 0 0 1 230 100Zm-12 0a62.06 62.06 0 0 0-124-3.65 6 6 0 0 1-12-.7 75.84 75.84 0 0 1 1.07-9A38 38 0 1 0 76 162h80a62.07 62.07 0 0 0 62-62Z" /></Svg>;
export default CloudFog;