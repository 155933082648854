/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48v139.57a4 4 0 0 1-7 2.7L116.8 80H208V48h-24v8a8 8 0 0 1-8.52 8 8.18 8.18 0 0 1-7.48-8.27V48H87.71l-8.46-9.31a4 4 0 0 1 3-6.69H168v-8a8 8 0 0 1 8.52-8 8.18 8.18 0 0 1 7.48 8.27V32h24a16 16 0 0 1 16 16Zm-10.08 162.62A8 8 0 0 1 208 224H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16 8 8 0 0 1 5.93 2.62ZM73.55 80 48 51.89V80Z" /></Svg>;
export default CalendarSlash;