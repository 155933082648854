/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93A12 12 0 0 0 36 40a20 20 0 0 0-8 16v56c0 54.29 26.32 87.22 48.4 105.29 23.71 19.39 47.44 26 48.44 26.29a12.1 12.1 0 0 0 6.32 0c1.35-.37 30-8.42 55.86-32.82l12.1 13.31a12 12 0 0 0 17.76-16.14ZM128 219.38a126.38 126.38 0 0 1-37.09-21.23C65.09 176.69 52 147.71 52 112V62.24L170.87 193A126 126 0 0 1 128 219.38ZM228 56v56a144 144 0 0 1-8.23 49.16 12 12 0 0 1-11.28 7.92 11.86 11.86 0 0 1-4.08-.72 12 12 0 0 1-7.2-15.37A120.31 120.31 0 0 0 204 112V60h-94.67a12 12 0 1 1 0-24H208a20 20 0 0 1 20 20Z" /></Svg>;
export default ShieldSlash;