/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-x-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-x-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 204a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm69.66-124 21.17-21.17a4 4 0 1 0-5.66-5.66L200 74.34l-21.17-21.17a4 4 0 0 0-5.66 5.66L194.34 80l-21.17 21.17a4 4 0 0 0 5.66 5.66L200 85.66l21.17 21.17a4 4 0 1 0 5.66-5.66Zm-35.31 81.77a72 72 0 0 0-84.71 0 4 4 0 0 0 4.71 6.46 64.05 64.05 0 0 1 75.29 0 4 4 0 1 0 4.71-6.46ZM135.81 60.19a4 4 0 1 0 .38-8c-2.71-.13-5.47-.2-8.19-.2a168.33 168.33 0 0 0-106.54 38.1 4 4 0 1 0 5.08 6.19A160.22 160.22 0 0 1 128 60c2.59 0 5.22.06 7.81.19Zm-.08 48.07a4 4 0 1 0 .54-8c-2.74-.19-5.52-.28-8.27-.28a118.72 118.72 0 0 0-74.48 25.91 4 4 0 0 0 5 6.27A110.84 110.84 0 0 1 128 108c2.57 0 5.17.09 7.73.26Z" /></Svg>;
export default WifiX;