/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LightningSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightning-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightning-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14L75.9 88.52l-36.67 39.29a12 12 0 0 0 4.31 19.33l51.18 20.47-10.59 70.61a12 12 0 0 0 20.64 10l58.9-63.11 35.45 39a12 12 0 0 0 17.76-16.14Zm56.72 171.62 6.27-41.77a12 12 0 0 0-7.41-12.92l-43.72-17.49 23.35-25 55.38 60.92Zm-2.19-135.49a12 12 0 0 1-.59-17l40.41-43.25a12 12 0 0 1 20.64 10l-10.59 70.58 51.18 20.47a12 12 0 0 1 4.31 19.33l-16.82 18a12 12 0 0 1-17.55-16.38l4.86-5.21-43.72-17.49a12 12 0 0 1-7.41-12.92l6.27-41.77-14 15a12 12 0 0 1-16.99.64Z" /></Svg>;
export default LightningSlash;