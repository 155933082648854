/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileJsx = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-jsx-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-jsx-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M151.78 196.8a24.73 24.73 0 0 1-10.95 18c-6 4-13.27 5.15-19.73 5.15a63.75 63.75 0 0 1-16.23-2.21 12 12 0 1 1 6.46-23.11c6.81 1.85 15 1.61 16.39.06a2.48 2.48 0 0 0 .21-.71c-1.94-1.23-6.83-2.64-9.88-3.53-5.39-1.56-11-3.17-15.75-6.26-7.63-4.92-11.21-12.45-10.11-21.19a24.45 24.45 0 0 1 10.69-17.76c6.06-4.09 14.17-5.83 24.1-5.17A69 69 0 0 1 139 142a12 12 0 0 1-6.1 23.21c-6.36-1.63-13.62-1.51-16.07-.33a79.5 79.5 0 0 0 7.91 2.59c5.47 1.58 11.68 3.37 16.8 6.82 7.79 5.24 11.46 13.24 10.24 22.51ZM68 140a12 12 0 0 0-12 12v38a6 6 0 0 1-12 0 12 12 0 0 0-24 0 30 30 0 0 0 60 0v-38a12 12 0 0 0-12-12Zm138.75 40 15-21a12 12 0 0 0-19.53-14L192 159.34 181.76 145a12 12 0 0 0-19.53 14l15 21-15 21a12 12 0 1 0 19.53 14L192 200.62 202.23 215a12 12 0 0 0 19.53-14ZM36 108V40a20 20 0 0 1 20-20h96a12 12 0 0 1 8.49 3.51l56 56A12 12 0 0 1 220 88v20a12 12 0 1 1-24 0v-4h-48a12 12 0 0 1-12-12V44H60v64a12 12 0 1 1-24 0Zm124-28h23l-23-23Z" /></Svg>;
export default FileJsx;