/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChefHat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chef-hat-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chef-hat-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 112a56.06 56.06 0 0 0-56-56c-1.77 0-3.54.1-5.29.26a56 56 0 0 0-101.42 0C75.54 56.1 73.77 56 72 56a56 56 0 0 0-24 106.59V208a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-45.41A56.09 56.09 0 0 0 240 112Zm-87.76 30.06 8-32a8 8 0 0 1 15.52 3.88l-8 32A8 8 0 0 1 160 152a8.13 8.13 0 0 1-1.95-.24 8 8 0 0 1-5.81-9.7ZM120 112a8 8 0 0 1 16 0v32a8 8 0 0 1-16 0Zm-33.94-7.76a8 8 0 0 1 9.7 5.82l8 32a8 8 0 0 1-5.82 9.7 8.13 8.13 0 0 1-2 .24 8 8 0 0 1-7.75-6.06l-8-32a8 8 0 0 1 5.87-9.7ZM192 208H64v-40.58a55.49 55.49 0 0 0 8 .58h112a55.49 55.49 0 0 0 8-.58Z" /></Svg>;
export default ChefHat;