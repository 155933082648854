/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const OpenAiLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="open-ai-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-open-ai-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 114.56a54 54 0 0 0-58.67-74.73 54 54 0 0 0-94 13.46A54 54 0 0 0 34 141.44a54 54 0 0 0 35.56 73.65A54.54 54.54 0 0 0 83.59 217a52.86 52.86 0 0 0 9.06-.78 54 54 0 0 0 94-13.46 54 54 0 0 0 35.35-88.2ZM183.37 52.5a42 42 0 0 1 29.21 53.14 54.84 54.84 0 0 0-5.08-3.33L163 76.62a6 6 0 0 0-6 0l-47 27.13V80.66l41.5-24a41.73 41.73 0 0 1 31.87-4.16ZM146 138.39l-18 10.39-18-10.39v-20.78l18-10.39 18 10.39ZM78 72a42 42 0 0 1 72.92-28.43 56.18 56.18 0 0 0-5.42 2.74L101 72a6 6 0 0 0-3 5.19v54.27l-20-11.54ZM39.13 85.93a41.75 41.75 0 0 1 27.22-20A55.09 55.09 0 0 0 66 72v51.38a6 6 0 0 0 3 5.2l47 27.13-20 11.55-41.5-24a42 42 0 0 1-15.37-57.33Zm33.5 117.57a42 42 0 0 1-29.21-53.14 54.84 54.84 0 0 0 5.08 3.33L93 179.38a6 6 0 0 0 6 0l47-27.13v23.09l-41.5 24a41.73 41.73 0 0 1-31.87 4.16ZM178 184a42 42 0 0 1-72.92 28.43 56.18 56.18 0 0 0 5.42-2.74L155 184a6 6 0 0 0 3-5.19v-54.27l20 11.54Zm38.87-13.93a41.75 41.75 0 0 1-27.22 20A55.09 55.09 0 0 0 190 184v-51.38a6 6 0 0 0-3-5.2l-47-27.13 20-11.55 41.5 24a42 42 0 0 1 15.37 57.33Z" /></Svg>;
export default OpenAiLogo;