/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderIntersex = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-intersex-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-intersex-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 26h-40a6 6 0 0 0 0 12h25.52l-30 29.94A62 62 0 1 0 114 173.7V194H88a6 6 0 0 0 0 12h26v26a6 6 0 0 0 12 0v-26h26a6 6 0 0 0 0-12h-26v-20.3a62 62 0 0 0 45.28-96.5L202 46.48V72a6 6 0 0 0 12 0V32a6 6 0 0 0-6-6Zm-88 136a50 50 0 1 1 50-50 50.06 50.06 0 0 1-50 50Z" /></Svg>;
export default GenderIntersex;