/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BabyCarriage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-carriage-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-carriage-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 34h-8a14 14 0 0 0-14 14v58H53.52A38.05 38.05 0 0 0 16 74a6 6 0 0 0 0 12 26 26 0 0 1 26 26 78.09 78.09 0 0 0 78 78h40a78 78 0 0 0 0-156Zm65.71 72H161.1l47.82-38.25A65.66 65.66 0 0 1 225.71 106ZM152 46h8a65.67 65.67 0 0 1 40 13.53l-50 40V48a2 2 0 0 1 2-2Zm8 132h-40a66.09 66.09 0 0 1-65.73-60h171.46A66.09 66.09 0 0 1 160 178Zm-58 46a14 14 0 1 1-14-14 14 14 0 0 1 14 14Zm104 0a14 14 0 1 1-14-14 14 14 0 0 1 14 14Z" /></Svg>;
export default BabyCarriage;