/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-nine-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-nine-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M145.33 118A20 20 0 1 1 138 90.68a20 20 0 0 1 7.31 27.32ZM224 48v160a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Zm-78 28.82A36 36 0 1 0 127.94 144q.94 0 1.89-.06l-16.7 28a8 8 0 0 0 2.77 11 8 8 0 0 0 11-2.77L159.18 126A36.05 36.05 0 0 0 146 76.82Z" /></Svg>;
export default NumberSquareNine;