/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegments = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segments-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segments-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.47 30.53a36 36 0 0 0-50.92 0 36.06 36.06 0 0 0-1.2 49.66l-23.83 44.26a36.08 36.08 0 0 0-21 3.07l-16-16a36 36 0 0 0-57.94-41 36 36 0 0 0-1.2 49.66L45.5 164.45a36 36 0 0 0-31 10.1 36 36 0 1 0 52.12 1.26l23.83-44.26A35.21 35.21 0 0 0 96 132a36.07 36.07 0 0 0 15.51-3.5l16 16a36 36 0 1 0 59.14-8.68l23.85-44.27a36.32 36.32 0 0 0 5.5.45 36 36 0 0 0 25.46-61.45Zm-154 57a12 12 0 0 1 17 17 12 12 0 0 1-17-17Zm-39 121a12 12 0 1 1 0-17 12 12 0 0 1 0 16.97Zm120-40a12 12 0 1 1 0-17 12 12 0 0 1 .02 16.96Zm56-104a12 12 0 1 1 0-17 12 12 0 0 1 .03 16.95Z" /></Svg>;
export default LineSegments;