/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DesktopTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="desktop-tower-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-desktop-tower-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 76a12 12 0 0 1 12-12h24a12 12 0 0 1 0 24h-24a12 12 0 0 1-12-12Zm12 48h24a12 12 0 0 0 0-24h-24a12 12 0 0 0 0 24Zm72-76v160a20 20 0 0 1-20 20h-80a20 20 0 0 1-20-20v-16h-32v12h8a12 12 0 0 1 0 24H68a12 12 0 0 1 0-24h8v-12H32a28 28 0 0 1-28-28V96a28 28 0 0 1 28-28h100V48a20 20 0 0 1 20-20h80a20 20 0 0 1 20 20ZM132 168V92H32a4 4 0 0 0-4 4v68a4 4 0 0 0 4 4Zm96-116h-72v152h72Zm-36 108a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default DesktopTower;