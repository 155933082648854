/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeStand = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-stand-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-stand-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 104a72 72 0 1 1-72-72 72 72 0 0 1 72 72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M136 184a80 80 0 1 0-80-80 80.09 80.09 0 0 0 80 80Zm0-144a64 64 0 1 1-64 64 64.07 64.07 0 0 1 64-64Zm77.77 133.5a8 8 0 0 1-.23 11.32 111.21 111.21 0 0 1-69.54 30.9V232h24a8 8 0 0 1 0 16h-64a8 8 0 0 1 0-16h24v-16.29A112 112 0 0 1 55.18 26.46a8 8 0 1 1 11.54 11.08 96 96 0 0 0 135.74 135.74 8 8 0 0 1 11.31.22Z" /></Svg>;
export default GlobeStand;