/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Storefront = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="storefront-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-storefront-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28.15 95a3.81 3.81 0 0 0-.15 1v16a36 36 0 0 0 16 29.92V216a4 4 0 0 0 4 4h160a4 4 0 0 0 4-4v-74.08A36 36 0 0 0 228 112V96a3.81 3.81 0 0 0-.17-1.08L213.5 44.7A12 12 0 0 0 202 36H54a12 12 0 0 0-11.5 8.7Zm22-48.08A4 4 0 0 1 54 44h148a4 4 0 0 1 3.84 2.9L218.7 92H37.3ZM100 100h56v12a28 28 0 0 1-56 0Zm-64 12v-12h56v12a28 28 0 0 1-41.37 24.59 4 4 0 0 0-1.31-.76A28 28 0 0 1 36 112Zm168 100H52v-66.06a36 36 0 0 0 44-17.48 36 36 0 0 0 64 0 36 36 0 0 0 44 17.48Zm2.68-76.17a3.94 3.94 0 0 0-1.3.76A28 28 0 0 1 164 112v-12h56v12a28 28 0 0 1-13.32 23.83Z" /></Svg>;
export default Storefront;