/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LessThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="less-than-or-equal-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-less-than-or-equal-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M44 104a4 4 0 0 1 2.62-3.75l152-56a4 4 0 1 1 2.76 7.5L59.57 104l141.81 52.25A4 4 0 0 1 200 164a3.91 3.91 0 0 1-1.38-.25l-152-56A4 4 0 0 1 44 104Zm156 92H48a4 4 0 0 0 0 8h152a4 4 0 0 0 0-8Z" /></Svg>;
export default LessThanOrEqual;