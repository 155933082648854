/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ranking = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ranking-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ranking-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M108.62 103.79a12 12 0 0 1 7.59-15.17l12-4A12 12 0 0 1 144 96v40a12 12 0 0 1-24 0v-24a12 12 0 0 1-11.38-8.21ZM252 208a12 12 0 0 1-12 12H16a12 12 0 0 1 0-24h4v-92a20 20 0 0 1 20-20h36V56a20 20 0 0 1 20-20h64a20 20 0 0 1 20 20v68h36a20 20 0 0 1 20 20v52h4a12 12 0 0 1 12 12Zm-72-60v48h32v-48Zm-80 48h56V60h-56Zm-56 0h32v-88H44Z" /></Svg>;
export default Ranking;