/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartLineDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-line-down-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-line-down-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 208a6 6 0 0 1-6 6H32a6 6 0 0 1-6-6V48a6 6 0 0 1 12 0v13.52l58 58 27.76-27.75a6 6 0 0 1 8.48 0L194 153.52V128a6 6 0 0 1 12 0v40a5.62 5.62 0 0 1-.11 1.1v.06a7.46 7.46 0 0 1-.31 1.05v.07a6.13 6.13 0 0 1-.52 1v.06a6.25 6.25 0 0 1-1.68 1.68h-.06a6.13 6.13 0 0 1-1 .52h-.07a7.46 7.46 0 0 1-1.05.31h-.06a5.62 5.62 0 0 1-1.1.11H160a6 6 0 0 1 0-12h25.52L128 104.49l-27.76 27.75a6 6 0 0 1-8.48 0L38 78.48V202h186a6 6 0 0 1 6 6Z" /></Svg>;
export default ChartLineDown;