/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BoxingGlove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boxing-glove-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boxing-glove-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 20h-48a52.06 52.06 0 0 0-52 52v4H56a28 28 0 0 0-28 28v29.19a12 12 0 0 0 2.63 7.5c.1.12.2.24.31.35L68 177.67V216a12 12 0 0 0 12 12h112a12 12 0 0 0 12-12v-39.44l15.54-54.38a12.34 12.34 0 0 0 .46-3.3V72a52.06 52.06 0 0 0-52-52Zm44 98.88a4.11 4.11 0 0 1-.15 1.1l-15.7 54.92a4.11 4.11 0 0 0-.15 1.1v40a4 4 0 0 1-4 4H80a4 4 0 0 1-4-4v-40a4 4 0 0 0-1.19-2.84l-38.05-37.62a4 4 0 0 1-.76-2.35V104a20 20 0 0 1 20-20h12v20a4 4 0 0 0 8 0V72a44.05 44.05 0 0 1 44-44h48a44.05 44.05 0 0 1 44 44Zm-42.21 44.7L145 176l24.84 12.42a4 4 0 0 1-3.58 7.16L136 180.47l-30.21 15.11a4 4 0 1 1-3.58-7.16L127.05 176l-24.84-12.42a4 4 0 1 1 3.58-7.16L136 171.53l30.21-15.11a4 4 0 0 1 3.58 7.16Z" /></Svg>;
export default BoxingGlove;