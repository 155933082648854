/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeadCircuit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="head-circuit-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-head-circuit-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M203.91 230.51a12 12 0 0 1-10.42 13.4 12.87 12.87 0 0 1-1.49.09 12 12 0 0 1-11.89-10.51l-8-64a12 12 0 0 1 4.7-11.08 68.26 68.26 0 0 0 27.19-56c0-.81-.07-1.6-.11-2.4h-14.27l-23.1 27.72A23.69 23.69 0 0 1 168 136a24.07 24.07 0 1 1-19.92-23.64l26.7-32A12 12 0 0 1 184 76h13.91a68.19 68.19 0 0 0-58.22-39.9q-1.84-.1-3.69-.1v19.23a24 24 0 1 1-24 0V40.34A68.11 68.11 0 0 0 68 104a12 12 0 0 1-1.36 5.54l-21.13 40.61L69 160.9a12 12 0 0 1 7 10.91V204h44a12 12 0 0 1 0 24H72a20 20 0 0 1-20-20v-28.49l-20.57-9.42a3.26 3.26 0 0 1-.38-.18 20 20 0 0 1-9.43-25.76 7.49 7.49 0 0 1 .38-.81l22-42.37a92 92 0 0 1 97-88.84c47.7 2.51 85.93 41.92 87 89.71a92.33 92.33 0 0 1-31.25 71.26Z" /></Svg>;
export default HeadCircuit;