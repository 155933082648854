/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyDollar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-dollar-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-dollar-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 124h-20V52h12a36 36 0 0 1 36 36 4 4 0 0 0 8 0 44.05 44.05 0 0 0-44-44h-12V24a4 4 0 0 0-8 0v20h-12a44 44 0 0 0 0 88h12v72h-20a36 36 0 0 1-36-36 4 4 0 0 0-8 0 44.05 44.05 0 0 0 44 44h20v20a4 4 0 0 0 8 0v-20h20a44 44 0 0 0 0-88Zm-40 0a36 36 0 0 1 0-72h12v72Zm40 80h-20v-72h20a36 36 0 0 1 0 72Z" /></Svg>;
export default CurrencyDollar;