/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinSimpleArea = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-simple-area-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-simple-area-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M124 99.77V176a4 4 0 0 0 8 0V99.77a36 36 0 1 0-8 0ZM128 36a28 28 0 1 1-28 28 28 28 0 0 1 28-28Zm108 140c0 12.46-11.73 23.83-33 32-20.09 7.73-46.72 12-75 12s-54.89-4.25-75-12c-21.29-8.19-33-19.56-33-32 0-18.55 25.81-34.22 67.37-40.88a4 4 0 1 1 1.26 7.88C52.93 148.74 28 162.3 28 176c0 17.39 40.18 36 100 36s100-18.61 100-36c0-13.7-24.93-27.26-60.63-33a4 4 0 1 1 1.26-7.89C210.19 141.78 236 157.45 236 176Z" /></Svg>;
export default MapPinSimpleArea;