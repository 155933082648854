/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Voicemail = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="voicemail-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-voicemail-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 76a52 52 0 0 0-27.66 96H83.66A52 52 0 1 0 56 180h144a52 52 0 0 0 0-104ZM12 128a44 44 0 1 1 44 44 44.05 44.05 0 0 1-44-44Zm188 44a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Z" /></Svg>;
export default Voicemail;