/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiscoBall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="disco-ball__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-disco-ball'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 64.37V16a8 8 0 0 0-16 0v48.37a88 88 0 1 0 16 0ZM183.54 144h-31.77c-1.51-28.36-10.79-48.36-19.44-61.06A72.16 72.16 0 0 1 183.54 144Zm-95.3 16h47.52c-2 33.52-16.13 52.95-23.76 61.08-7.64-8.15-21.77-27.57-23.76-61.08Zm0-16c2-33.52 16.13-52.95 23.76-61.08 7.64 8.15 21.77 27.57 23.76 61.08Zm3.43-61.06C83 95.64 73.74 115.64 72.23 144H40.46a72.16 72.16 0 0 1 51.21-61.06ZM40.46 160h31.77c1.51 28.36 10.79 48.36 19.44 61.06A72.16 72.16 0 0 1 40.46 160Zm91.87 61.06c8.65-12.7 17.93-32.7 19.44-61.06h31.77a72.16 72.16 0 0 1-51.21 61.06ZM256 88a8 8 0 0 1-8 8h-8v8a8 8 0 0 1-16 0v-8h-8a8 8 0 0 1 0-16h8v-8a8 8 0 0 1 16 0v8h8a8 8 0 0 1 8 8ZM152 40a8 8 0 0 1 8-8h16V16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 0 16h-16v16a8 8 0 0 1-16 0V48h-16a8 8 0 0 1-8-8Z" /></Svg>;
export default DiscoBall;