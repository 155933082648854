/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sphere = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sphere__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sphere'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm88 104c0 6-17 16.23-48.77 21.17.51-7 .77-14.08.77-21.17 0-32.24-5.35-64.72-15.74-84.6A88.17 88.17 0 0 1 216 128Zm-88-88c8.15 0 24 31.06 24 88 0 8.24-.34 15.92-.93 23.07-7.15.59-14.83.93-23.07.93-56.94 0-88-15.85-88-24a88.1 88.1 0 0 1 88-88ZM43.4 152.26C63.28 162.65 95.76 168 128 168c7.09 0 14.19-.26 21.17-.77C144.23 199 134 216 128 216a88.17 88.17 0 0 1-84.6-63.74Zm108.86 60.34c6.29-12 10.73-28.67 13.26-47.08 18.41-2.53 35-7 47.08-13.26a88.4 88.4 0 0 1-60.34 60.34Z" /></Svg>;
export default Sphere;