/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Share = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="share-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-share-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m224 104-48 48V56Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m229.66 98.34-48-48A8 8 0 0 0 168 56v40h-3a103.94 103.94 0 0 0-100.75 78 8 8 0 0 0 15.5 4A88 88 0 0 1 165 112h3v40a8 8 0 0 0 13.66 5.66l48-48a8 8 0 0 0 0-11.32ZM184 132.69V75.31L212.69 104ZM200 216a8 8 0 0 1-8 8H32a8 8 0 0 1-8-8V88a8 8 0 0 1 16 0v120h152a8 8 0 0 1 8 8Z" /></Svg>;
export default Share;