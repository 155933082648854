/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 128.72A87.74 87.74 0 0 1 222.41 190a4 4 0 0 1-5.77-.16L103.78 65.67a4 4 0 0 1 .39-5.76A87.82 87.82 0 0 1 160.87 40c48.28.47 87.51 40.43 87.13 88.72ZM53.92 34.62a8 8 0 1 0-11.84 10.76l39.25 43.18-.06.11A64 64 0 0 0 8 153c.53 35.12 29.84 63 65 63h87a87.65 87.65 0 0 0 31.78-5.95l10.3 11.33a8 8 0 0 0 11.33.52 8.32 8.32 0 0 0 .29-11.52Z" /></Svg>;
export default CloudSlash;