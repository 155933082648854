/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lockers = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lockers-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lockers-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 48v152H40V48a8 8 0 0 1 8-8h160a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M192 72a8 8 0 0 1-8 8h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 8 8Zm-8 24h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16ZM72 80h24a8 8 0 0 0 0-16H72a8 8 0 0 0 0 16Zm24 16H72a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16Zm128-48v176a8 8 0 0 1-16 0v-16h-72v16a8 8 0 0 1-16 0v-16H48v16a8 8 0 0 1-16 0V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16ZM120 192V48H48v144Zm16 0h72V48h-72Z" /></Svg>;
export default Lockers;