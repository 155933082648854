/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitBranch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-branch-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-branch-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 64a36 36 0 1 0-48 33.94V112a4 4 0 0 1-4 4H96a27.8 27.8 0 0 0-4 .29V97.94a36 36 0 1 0-24 0v60.12a36 36 0 1 0 24 0V144a4 4 0 0 1 4-4h88a28 28 0 0 0 28-28V97.94A36.07 36.07 0 0 0 236 64ZM80 52a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm0 152a12 12 0 1 1 12-12 12 12 0 0 1-12 12ZM200 76a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default GitBranch;