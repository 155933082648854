/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TipJar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tip-jar-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tip-jar-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M182 50.48V32a14 14 0 0 0-14-14H88a14 14 0 0 0-14 14v18.48A38.05 38.05 0 0 0 42 88v112a38 38 0 0 0 38 38h96a38 38 0 0 0 38-38V88a38.05 38.05 0 0 0-32-37.52ZM170 32v18h-20V30h18a2 2 0 0 1 2 2Zm-52 18V30h20v20ZM88 30h18v20H86V32a2 2 0 0 1 2-2Zm114 170a26 26 0 0 1-26 26H80a26 26 0 0 1-26-26V88a26 26 0 0 1 26-26h96a26 26 0 0 1 26 26Zm-44-40a22 22 0 0 1-22 22h-2v10a6 6 0 0 1-12 0v-10h-10a6 6 0 0 1 0-12h24a10 10 0 0 0 0-20h-16a22 22 0 0 1 0-44h2V96a6 6 0 0 1 12 0v10h10a6 6 0 0 1 0 12h-24a10 10 0 0 0 0 20h16a22 22 0 0 1 22 22Z" /></Svg>;
export default TipJar;