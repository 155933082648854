/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlipVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flip-vertical-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flip-vertical-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m219.11 159.37-160 64A8 8 0 0 1 48 216v-64a8 8 0 0 1 8-8h160c8.71 0 11.14 12 3.11 15.37Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M56 120h160a16 16 0 0 0 6.23-30.74l-.14-.06-159.93-64A16 16 0 0 0 40 40v64a16 16 0 0 0 16 16Zm0-80 .15.06L216 104H56V40Zm160 96H56a16 16 0 0 0-16 16v64a16 16 0 0 0 22.15 14.78l159.93-64 .14-.06A16 16 0 0 0 216 136ZM56.15 215.93 56 216v-64h160Z" /></Svg>;
export default FlipVertical;