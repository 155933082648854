/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radio = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M102 104a6 6 0 0 1-6 6H64a6 6 0 0 1 0-12h32a6 6 0 0 1 6 6Zm-6 26H64a6 6 0 0 0 0 12h32a6 6 0 0 0 0-12Zm0 32H64a6 6 0 0 0 0 12h32a6 6 0 0 0 0-12Zm134-82v112a14 14 0 0 1-14 14H40a14 14 0 0 1-14-14V72a6 6 0 0 1 4.28-5.75l160-48a6 6 0 0 1 3.44 11.5L72.88 66H216a14 14 0 0 1 14 14Zm-12 0a2 2 0 0 0-2-2H38v114a2 2 0 0 0 2 2h176a2 2 0 0 0 2-2Zm-20 56a38 38 0 1 1-38-38 38 38 0 0 1 38 38Zm-12 0a26 26 0 1 0-26 26 26 26 0 0 0 26-26Z" /></Svg>;
export default Radio;