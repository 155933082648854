/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CameraPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="camera-plus-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-camera-plus-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 80v112a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V80a16 16 0 0 1 16-16h32l13.62-20.44a8 8 0 0 1 6.66-3.56h55.44a8 8 0 0 1 6.66 3.56L176 64h32a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M168 136a8 8 0 0 1-8 8h-24v24a8 8 0 0 1-16 0v-24H96a8 8 0 0 1 0-16h24v-24a8 8 0 0 1 16 0v24h24a8 8 0 0 1 8 8Zm64-56v112a24 24 0 0 1-24 24H48a24 24 0 0 1-24-24V80a24 24 0 0 1 24-24h27.72L87 39.12A16 16 0 0 1 100.28 32h55.44A16 16 0 0 1 169 39.12L180.28 56H208a24 24 0 0 1 24 24Zm-16 0a8 8 0 0 0-8-8h-32a8 8 0 0 1-6.66-3.56L155.72 48h-55.44L86.66 68.44A8 8 0 0 1 80 72H48a8 8 0 0 0-8 8v112a8 8 0 0 0 8 8h160a8 8 0 0 0 8-8Z" /></Svg>;
export default CameraPlus;