/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleNyTimes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-ny-times-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-ny-times-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 92a12 12 0 0 1 12-12h64a12 12 0 0 1 0 24h-64a12 12 0 0 1-12-12Zm76 28h-64a12 12 0 0 0 0 24h64a12 12 0 0 0 0-24Zm0 40H80a12 12 0 0 0 0 24h152a12 12 0 0 0 0-24Zm0 40H80a12 12 0 0 0 0 24h152a12 12 0 0 0 0-24ZM96 144a12 12 0 0 0 0-24h-4V68h24v4a12 12 0 0 0 24 0V56a12 12 0 0 0-12-12H32a12 12 0 0 0-12 12v16a12 12 0 0 0 24 0v-4h24v52h-4a12 12 0 0 0 0 24Z" /></Svg>;
export default ArticleNyTimes;