/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ToggleLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toggle-left-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toggle-left-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 60H80a68 68 0 0 0 0 136h96a68 68 0 0 0 0-136Zm0 128H80a60 60 0 0 1 0-120h96a60 60 0 0 1 0 120ZM80 92a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default ToggleLeft;