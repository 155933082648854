/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Webcam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webcam-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webcam-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 104a36 36 0 1 0-36 36 36 36 0 0 0 36-36Zm-64 0a28 28 0 1 1 28 28 28 28 0 0 1-28-28Zm124 100h-92v-24.11a76 76 0 1 0-8 0V204H32a4 4 0 0 0 0 8h192a4 4 0 0 0 0-8ZM60 104a68 68 0 1 1 68 68 68.07 68.07 0 0 1-68-68Z" /></Svg>;
export default Webcam;