/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberEight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-eight-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-eight-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M151.62 119.45a46 46 0 1 0-47.24 0 54 54 0 1 0 47.24 0ZM94 80a34 34 0 1 1 34 34 34 34 0 0 1-34-34Zm34 130a42 42 0 1 1 42-42 42 42 0 0 1-42 42Z" /></Svg>;
export default NumberEight;