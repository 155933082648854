/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CookingPot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cooking-pot-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cooking-pot-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M90 48V16a6 6 0 0 1 12 0v32a6 6 0 0 1-12 0Zm38 6a6 6 0 0 0 6-6V16a6 6 0 0 0-12 0v32a6 6 0 0 0 6 6Zm32 0a6 6 0 0 0 6-6V16a6 6 0 0 0-12 0v32a6 6 0 0 0 6 6Zm91.6 46.8L222 123v61a30 30 0 0 1-30 30H64a30 30 0 0 1-30-30v-61L4.4 100.8a6 6 0 0 1 7.2-9.6L34 108V80a6 6 0 0 1 6-6h176a6 6 0 0 1 6 6v28l22.4-16.8a6 6 0 0 1 7.2 9.6ZM210 86H46v98a18 18 0 0 0 18 18h128a18 18 0 0 0 18-18Z" /></Svg>;
export default CookingPot;