/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Flag = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M45.39 53.06a4 4 0 0 0-1.39 3V224a4 4 0 0 0 8 0v-46.13c29.41-24.39 55.08-11.69 82.23 1.73 16.5 8.17 33.33 16.5 51.13 16.5 13.14 0 26.81-4.55 41.26-17.06a4 4 0 0 0 1.38-3v-120a4 4 0 0 0-6.62-3c-30 26-56 13.07-83.61-.57-28.7-14.19-58.37-28.85-92.38.59ZM220 174.17c-29.41 24.4-55.08 11.7-82.23-1.73-26.82-13.27-54.5-27-85.77-4.66V57.92c29.41-24.4 55.08-11.7 82.23 1.73 26.82 13.27 54.5 27 85.77 4.66Z" /></Svg>;
export default Flag;