/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireTruck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-truck-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-truck-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m253.57 117.77-14-35a13.93 13.93 0 0 0-13-8.8H190V64a6 6 0 0 0-12 0v101.48A30.1 30.1 0 0 0 162.6 186h-53.2a30 30 0 0 0-58.8 0H32a2 2 0 0 1-2-2v-48a6 6 0 0 0-12 0v48a14 14 0 0 0 14 14h18.6a30 30 0 0 0 58.8 0h53.2a30 30 0 0 0 58.8 0H240a14 14 0 0 0 14-14v-64a6.1 6.1 0 0 0-.43-2.23ZM226.58 86a2 2 0 0 1 1.86 1.26l10.7 26.74H190V86ZM80 210a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm112 0a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm48-24h-18.6a30.05 30.05 0 0 0-29.4-24c-.67 0-1.34 0-2 .07V126h52v58a2 2 0 0 1-2 2ZM24 98a6 6 0 0 0 0 12h128a6 6 0 0 0 0-12h-18V70h18a6 6 0 0 0 0-12H24a6 6 0 0 0 0 12h18v28Zm98 0H94V70h28ZM54 70h28v28H54Z" /></Svg>;
export default FireTruck;