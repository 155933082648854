/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlipVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flip-vertical-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flip-vertical-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 118h160a14 14 0 0 0 5.46-26.9h-.11L61.4 27.1A14 14 0 0 0 42 40v64a14 14 0 0 0 14 14Zm-2-78a2 2 0 0 1 2-2 2 2 0 0 1 .79.16l.11.05 159.92 64A2 2 0 0 1 216 106H56a2 2 0 0 1-2-2Zm162 98H56a14 14 0 0 0-14 14v64a14 14 0 0 0 19.39 12.92l160-64 .11-.05A14 14 0 0 0 216 138Zm.82 15.83-159.92 64-.11.05A2 2 0 0 1 54 216v-64a2 2 0 0 1 2-2h160a2 2 0 0 1 .82 3.83Z" /></Svg>;
export default FlipVertical;