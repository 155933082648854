/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Numpad = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="numpad-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-numpad-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M78 48a14 14 0 1 1-14-14 14 14 0 0 1 14 14Zm50-14a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm64 28a14 14 0 1 0-14-14 14 14 0 0 0 14 14ZM64 90a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm64 0a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm64 0a14 14 0 1 0 14 14 14 14 0 0 0-14-14ZM64 146a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm64 0a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm0 56a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm64-56a14 14 0 1 0 14 14 14 14 0 0 0-14-14Z" /></Svg>;
export default Numpad;