/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LegoSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lego-smiley-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lego-smiley-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 80v96a24 24 0 0 1-24 24H72a24 24 0 0 1-24-24V80a24 24 0 0 1 24-24h112a24 24 0 0 1 24 24Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M100 124a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm56-24a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm-4.27 45.23a45 45 0 0 1-47.46 0 8 8 0 0 0-8.54 13.54 61 61 0 0 0 64.54 0 8 8 0 0 0-8.54-13.54ZM216 80v96a32.06 32.06 0 0 1-24 31v17a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16v-17a32.06 32.06 0 0 1-24-31V80a32 32 0 0 1 32-32h16V32a16 16 0 0 1 16-16h48a16 16 0 0 1 16 16v16h16a32 32 0 0 1 32 32ZM104 48h48V32h-48Zm72 176v-16H80v16Zm24-144a16 16 0 0 0-16-16H72a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h112a16 16 0 0 0 16-16Z" /></Svg>;
export default LegoSmiley;