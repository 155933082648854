/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Scales = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scales-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scales-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m237.57 133.77-32-80a6 6 0 0 0-6.86-3.63L134 64.52V40a6 6 0 0 0-12 0v27.19l-67.3 15a6 6 0 0 0-4.27 3.63l-32 80A6.1 6.1 0 0 0 18 168c0 21.86 23.31 30 38 30s38-8.14 38-30a6.1 6.1 0 0 0-.43-2.23L64.19 92.33 122 79.48V210h-18a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12h-18V76.81l56.21-12.49-27.78 69.45A6.1 6.1 0 0 0 162 136c0 21.86 23.31 30 38 30s38-8.14 38-30a6.1 6.1 0 0 0-.43-2.23ZM56 186a36.89 36.89 0 0 1-17.48-4.56c-5.37-3.13-8.15-7.18-8.49-12.37l26-64.91 26 64.91C81.06 182.85 62.58 186 56 186Zm144-32a36.89 36.89 0 0 1-17.48-4.56c-5.37-3.13-8.15-7.18-8.49-12.37l26-64.91 26 64.91C225.06 150.85 206.58 154 200 154Z" /></Svg>;
export default Scales;