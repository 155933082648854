/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hospital = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hospital-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hospital-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 210h-10v-82a14 14 0 0 0-14-14h-58V48a14 14 0 0 0-14-14H56a14 14 0 0 0-14 14v162H32a6 6 0 0 0 0 12h216a6 6 0 0 0 0-12Zm-24-84a2 2 0 0 1 2 2v82h-60v-84ZM54 48a2 2 0 0 1 2-2h96a2 2 0 0 1 2 2v162h-20v-50a6 6 0 0 0-6-6H80a6 6 0 0 0-6 6v50H54Zm68 162H86v-44h36ZM74 96a6 6 0 0 1 6-6h18V72a6 6 0 0 1 12 0v18h18a6 6 0 0 1 0 12h-18v18a6 6 0 0 1-12 0v-18H80a6 6 0 0 1-6-6Z" /></Svg>;
export default Hospital;