/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerSimpleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-simple-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-simple-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.92 210.62a8 8 0 1 1-11.84 10.76L168 175.09v48.6a8.29 8.29 0 0 1-3.91 7.18 8 8 0 0 1-9-.56L85.25 176H40a16 16 0 0 1-16-16V96a16 16 0 0 1 16-16h41.55L50.08 45.38a8 8 0 0 1 11.84-10.76ZM200.53 160a8.17 8.17 0 0 0 7.47-8.25v-47.48a8.17 8.17 0 0 0-7.47-8.27 8 8 0 0 0-8.53 8v48a8 8 0 0 0 8.53 8ZM161 119.87a4 4 0 0 0 7-2.7V32.24a8.25 8.25 0 0 0-2.88-6.39 8 8 0 0 0-10-.16l-43.29 33.64a4 4 0 0 0-.5 5.85ZM231.47 80a8.17 8.17 0 0 0-7.47 8.27v79.46a8.17 8.17 0 0 0 7.47 8.25 8 8 0 0 0 8.53-8V88a8 8 0 0 0-8.53-8Z" /></Svg>;
export default SpeakerSimpleSlash;