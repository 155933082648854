/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-medium-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-medium-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 204a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm63.45-84.36a128 128 0 0 0-158.9 0 12 12 0 0 0 14.9 18.81 104 104 0 0 1 129.1 0 12 12 0 0 0 14.9-18.81Zm-32.38 35.66a80.05 80.05 0 0 0-94.14 0 12 12 0 0 0 14.14 19.4 56 56 0 0 1 65.86 0 12 12 0 1 0 14.14-19.4Z" /></Svg>;
export default WifiMedium;