/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 0 0-6 5.38l24.81 27.24C52.7 94.85 44 119.75 44 144a84 84 0 0 0 145.32 57.4L205 218.69a4 4 0 1 0 5.92-5.38ZM128 220a76.08 76.08 0 0 1-76-76c0-22.1 7.85-44.95 23.33-68l108.59 119.46A75.83 75.83 0 0 1 128 220ZM92.78 47.62a4 4 0 0 1-.13-5.62 244.78 244.78 0 0 1 33.06-29.24 4 4 0 0 1 4.58 0A250.18 250.18 0 0 1 171 50.37c26.81 30.84 41 63.21 41 93.63a84.32 84.32 0 0 1-3 22.34 4 4 0 0 1-3.86 2.94 3.86 3.86 0 0 1-1.06-.14 4 4 0 0 1-2.8-4.92A75.89 75.89 0 0 0 204 144c0-62.29-63.08-113.25-76-123a251.38 251.38 0 0 0-29.56 26.48 4 4 0 0 1-5.66.14Z" /></Svg>;
export default DropSlash;