/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tent = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tent__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tent'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m255.31 188.75-64-144A8 8 0 0 0 184 40H72a8 8 0 0 0-7.27 4.69.21.21 0 0 0 0 .06v.12L.69 188.75A8 8 0 0 0 8 200h240a8 8 0 0 0 7.31-11.25ZM64 184H20.31L64 85.7Zm16 0V85.7l43.69 98.3Zm61.2 0L84.31 56h94.49l56.89 128Z" /></Svg>;
export default Tent;