/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyNervous = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-nervous-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-nervous-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84ZM76 108a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm72 0a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm30.92 64.94a12 12 0 0 1-16.78 2.52 38.18 38.18 0 0 1-7.74-8.26 35.12 35.12 0 0 0-2.4-3 35.12 35.12 0 0 0-2.4 3C146 172 140 180 128 180s-18-8-21.6-12.8a35.12 35.12 0 0 0-2.4-3 35.12 35.12 0 0 0-2.4 3 38.18 38.18 0 0 1-7.74 8.26 12 12 0 0 1-14.27-19.3 22.82 22.82 0 0 0 2.81-3.36C86 148 92 140 104 140s18 8 21.6 12.8a35.12 35.12 0 0 0 2.4 3 35.12 35.12 0 0 0 2.4-3C134 148 140 140 152 140s18 8 21.6 12.8a22.82 22.82 0 0 0 2.81 3.36 12 12 0 0 1 2.51 16.78Z" /></Svg>;
export default SmileyNervous;