/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShuffleSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shuffle-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shuffle-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 48v40a8 8 0 0 1-13.66 5.66L188 79.31 156.28 111A8 8 0 0 1 145 99.72L176.69 68l-14.35-14.34A8 8 0 0 1 168 40h40a8 8 0 0 1 8 8Zm-4.94 112.61a8 8 0 0 0-8.72 1.73L188 176.69 53.66 42.34a8 8 0 0 0-11.32 11.32L176.69 188l-14.35 14.34A8 8 0 0 0 168 216h40a8 8 0 0 0 8-8v-40a8 8 0 0 0-4.94-7.39ZM99.72 145l-57.38 57.34a8 8 0 0 0 11.32 11.32L111 156.28A8 8 0 0 0 99.72 145Z" /></Svg>;
export default ShuffleSimple;