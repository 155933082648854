/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseLeftClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-left-click-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-left-click-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24v88H56V80a56 56 0 0 1 56-56Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M144 16h-32a64.07 64.07 0 0 0-64 64v96a64.07 64.07 0 0 0 64 64h32a64.07 64.07 0 0 0 64-64V80a64.07 64.07 0 0 0-64-64Zm48 64v24h-56V32h8a48.05 48.05 0 0 1 48 48Zm-76.69 24-46-46a48.49 48.49 0 0 1 11.2-14.18L120 83.31V104ZM64 80c0-1.51.08-3 .22-4.47L92.69 104H64Zm56-48v28.69L94.59 35.28A47.73 47.73 0 0 1 112 32Zm24 192h-32a48.05 48.05 0 0 1-48-48v-56h128v56a48.05 48.05 0 0 1-48 48Z" /></Svg>;
export default MouseLeftClick;