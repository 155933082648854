/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScribbleLoop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scribble-loop-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scribble-loop-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252.9 152c-1.33-1.47-23.75-25.85-60.21-40.69-2.15-18.28-9.1-34.9-20.1-47.71C157.12 45.52 134.79 36 108 36c-53.56 0-88.31 47.79-89.76 49.82a12 12 0 0 0 19.53 14C38.05 99.38 66.65 60 108 60c19.85 0 35.45 6.45 46.38 19.18a61.35 61.35 0 0 1 12.4 24 143.6 143.6 0 0 0-30.17-3.18c-27 0-49.79 7.13-65.85 20.63C56.3 132.79 48 149.78 48 167.25 48 193.46 67.44 220 104.61 220c51.93 0 82.34-40.28 87.87-82.43a156.46 156.46 0 0 1 42.62 30.48A12 12 0 1 0 252.9 152Zm-100.53 21.74C144.09 183.9 129.09 196 104.61 196 82.08 196 72 181.56 72 167.25 72 146.42 92.22 124 136.61 124a119.85 119.85 0 0 1 32.64 4.62c-1.01 16.82-7.04 33.04-16.88 45.12Z" /></Svg>;
export default ScribbleLoop;