/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TumblrLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tumblr-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tumblr-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 120v48a8 8 0 0 0 8 8h32a8 8 0 0 1 8 8v48a8 8 0 0 1-8 8h-40a64.07 64.07 0 0 1-64-64v-56H64a8 8 0 0 1-8-8V72a8 8 0 0 1 8-8 40 40 0 0 0 40-40 8 8 0 0 1 8-8h32a8 8 0 0 1 8 8v40h40a8 8 0 0 1 8 8v40a8 8 0 0 1-8 8Z" /></Svg>;
export default TumblrLogo;