/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PipeWrench = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pipe-wrench-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pipe-wrench-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M218.87 56.44a1.59 1.59 0 0 1-.13-.13L173.83 14a14 14 0 0 0-19.71.06l-46 45.62-3-3a23 23 0 0 0-32.42 32.5l2.85 2.89-21.45 21.34a14 14 0 0 0 0 19.8l12.69 12.69a14 14 0 0 0 19.76 0l21.35-21.05 13.51 13.7a2 2 0 0 1 0 2.83l-64.68 65.41a23 23 0 1 0 32.5 32.47l88.67-89.36a14 14 0 0 0 0-19.78l-37.11-37.48 14.35-14.06a2 2 0 0 1 2.82 0l.07.07L195.42 114a14 14 0 0 0 19.74-.07l3.71-3.72a38 38 0 0 0 0-53.74Zm-140.77 81a2 2 0 0 1-2.83 0l-12.69-12.71a2 2 0 0 1-.58-1.42 2 2 0 0 1 .57-1.4L84 100.62l15.5 15.72Zm91.3 4-88.67 89.35a11 11 0 1 1-15.49-15.54l64.67-65.37a14 14 0 0 0 0-19.75l-48.7-49.4a11 11 0 0 1 15.5-15.54l72.7 73.39a2 2 0 0 1-.01 2.85Zm41-39.73-3.71 3.71a2 2 0 0 1-2.83 0l-.12-.12L166.31 70a14 14 0 0 0-19.61.1l-14.35 14.02-15.75-15.9 46-45.64a2 2 0 0 1 2.84 0l.12.13L210.45 65a26 26 0 0 1-.07 36.71Z" /></Svg>;
export default PipeWrench;