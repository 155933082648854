/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BluetoothSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.38 221.92a8 8 0 0 1-11.3-.54l-26.45-29.1-50.83 38.12a8 8 0 0 1-8.89.47 8.29 8.29 0 0 1-3.91-7.18V144l-51.2 38.4a8 8 0 0 1-11.16-1.55 8.26 8.26 0 0 1 1.81-11.43l61.47-46.11-70.84-77.93a8 8 0 0 1 11.84-10.76l160 176a8 8 0 0 1-.54 11.3ZM155 113.22a4 4 0 0 0 5.36.51L196.8 86.4a8 8 0 0 0 0-12.8l-64-48a8 8 0 0 0-10 .29 8.25 8.25 0 0 0-2.8 6.35v40.94a4 4 0 0 0 1 2.69Z" /></Svg>;
export default BluetoothSlash;