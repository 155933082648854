/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MaskHappy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mask-happy-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mask-happy-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188.47 124a6 6 0 1 1-8.94 8c-3.06-3.42-9.74-6-15.53-6s-12.47 2.58-15.53 6a6 6 0 0 1-8.94-8c5.35-6 15.19-10 24.47-10s19.12 4 24.47 10Zm-80.94 8a6 6 0 1 0 8.94-8c-5.35-6-15.19-10-24.47-10s-19.12 4-24.47 10a6 6 0 1 0 8.94 8c3.06-3.42 9.74-6 15.53-6s12.47 2.58 15.53 6Zm58.73 31.43a6 6 0 0 0-8.46.67 38.89 38.89 0 0 1-59.6 0 6 6 0 1 0-9.13 7.79 50.9 50.9 0 0 0 77.86 0 6 6 0 0 0-.67-8.46ZM222 48v55.77c0 35.45-9.53 68.86-26.83 94.06C177.4 223.74 153.54 238 128 238s-49.4-14.26-67.17-40.16C43.53 172.64 34 139.23 34 103.78V48a14 14 0 0 1 19.06-13c14.19 5.46 42.8 14.7 74.94 14.7s60.75-9.24 74.94-14.7A14 14 0 0 1 222 48Zm-12 0a2 2 0 0 0-.87-1.66 1.93 1.93 0 0 0-1.85-.21C192.31 52 162.1 61.7 128 61.7S63.69 52 48.72 46.14a2 2 0 0 0-1.85.21A2 2 0 0 0 46 48v55.76C46 171.17 82.79 226 128 226s82-54.83 82-122.22Z" /></Svg>;
export default MaskHappy;