/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapTrifold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-trifold-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-trifold-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.92 49.69a8 8 0 0 0-6.86-1.45l-61.13 15.28-61.35-30.68a8 8 0 0 0-5.52-.6l-64 16A8 8 0 0 0 24 56v144a8 8 0 0 0 9.94 7.76l61.13-15.28 61.35 30.68a8.15 8.15 0 0 0 3.58.84 8 8 0 0 0 1.94-.24l64-16A8 8 0 0 0 232 200V56a8 8 0 0 0-3.08-6.31ZM96 176a8 8 0 0 0-1.94.24L40 189.75V62.25l55.07-13.77.93.46Zm120 17.75-55.07 13.77-.93-.46V80a8 8 0 0 0 1.94-.23L216 66.25Z" /></Svg>;
export default MapTrifold;