/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bomb = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bomb-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bomb-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 32a8 8 0 0 0-8 8 52.66 52.66 0 0 1-3.57 17.39C232.38 67.22 225.7 72 216 72c-11.06 0-18.85-9.76-29.49-24.65C176 32.66 164.12 16 144 16c-16.39 0-29 8.89-35.43 25a66.07 66.07 0 0 0-3.9 15H88a16 16 0 0 0-16 16v9.59A88 88 0 0 0 112 248h1.59A88 88 0 0 0 152 81.59V72a16 16 0 0 0-16-16h-15.12a46.76 46.76 0 0 1 2.69-9.37C127.62 36.78 134.3 32 144 32c11.06 0 18.85 9.76 29.49 24.65C184 71.34 195.88 88 216 88c16.39 0 29-8.89 35.43-25A68.69 68.69 0 0 0 256 40a8 8 0 0 0-8-8ZM111.89 209.32A8 8 0 0 1 104 216a8.52 8.52 0 0 1-1.33-.11 57.5 57.5 0 0 1-46.57-46.57 8 8 0 1 1 15.78-2.64 41.29 41.29 0 0 0 33.43 33.43 8 8 0 0 1 6.58 9.21Z" /></Svg>;
export default Bomb;