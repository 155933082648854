/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sunglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sunglasses-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sunglasses-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 36a12 12 0 0 0 0 24 12 12 0 0 1 12 12v52H44V72a12 12 0 0 1 12-12 12 12 0 0 0 0-24 36 36 0 0 0-36 36v92a48 48 0 0 0 96 0v-16h24v16a48 48 0 0 0 96 0V72a36 36 0 0 0-36-36ZM68 188a24 24 0 0 1-24-24v-9l31.74 31.74A23.89 23.89 0 0 1 68 188Zm24-24a24.8 24.8 0 0 1-.44 4.59L71 148h21Zm96 24a24 24 0 0 1-24-24v-9l31.74 31.74A23.89 23.89 0 0 1 188 188Zm24-24a24.8 24.8 0 0 1-.44 4.59L191 148h21Z" /></Svg>;
export default Sunglasses;