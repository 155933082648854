/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BluetoothSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m219 213.31-160-176a4 4 0 0 0-6 5.38l74 81.42h-.14a5.17 5.17 0 0 0-.51.18l-.15.06h-.05a4 4 0 0 0-.56.35h-.05l-64 48a4 4 0 0 0 4.8 6.4L124 136v88a4 4 0 0 0 2.21 3.58 4.05 4.05 0 0 0 1.79.42 4 4 0 0 0 2.4-.8l53.74-40.3L213 218.69a4 4 0 1 0 5.92-5.38ZM132 216v-80l18.44 13.83L178.73 181Zm-8-144.37V32a4 4 0 0 1 6.4-3.2l64 48a4 4 0 0 1 0 6.4l-33.53 25.15a4 4 0 0 1-2.4.8 4 4 0 0 1-2.4-7.2l29.26-22L132 40v31.63a4 4 0 0 1-8 0Z" /></Svg>;
export default BluetoothSlash;