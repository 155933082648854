/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CameraPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="camera-plus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-camera-plus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 136a12 12 0 0 1-12 12h-20v20a12 12 0 0 1-24 0v-20H96a12 12 0 0 1 0-24h20v-20a12 12 0 0 1 24 0v20h20a12 12 0 0 1 12 12Zm64-56v112a28 28 0 0 1-28 28H48a28 28 0 0 1-28-28V80a28 28 0 0 1 28-28h25.58l10.06-15.09A20 20 0 0 1 100.28 28h55.44a20 20 0 0 1 16.64 8.91L182.42 52H208a28 28 0 0 1 28 28Zm-24 0a4 4 0 0 0-4-4h-32a12 12 0 0 1-10-5.34L153.58 52h-51.16L90 70.66A12 12 0 0 1 80 76H48a4 4 0 0 0-4 4v112a4 4 0 0 0 4 4h160a4 4 0 0 0 4-4Z" /></Svg>;
export default CameraPlus;