/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Metronome = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="metronome__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-metronome'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m187.14 114.84 26.78-29.46a8 8 0 0 0-11.84-10.76l-20.55 22.6-17.2-54.07A15.94 15.94 0 0 0 149.08 32h-42.17a15.94 15.94 0 0 0-15.25 11.15l-50.91 160A16 16 0 0 0 56 224h144a16 16 0 0 0 15.25-20.85ZM184.72 160h-38.64l28.62-31.48ZM106.91 48h42.17l20 62.9-44.62 49.1H71.27ZM56 208l10.18-32h123.63L200 208Z" /></Svg>;
export default Metronome;