/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SwimmingPool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="swimming-pool-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-swimming-pool-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 149.39a12 12 0 0 0 12-12V136h56v3.29a12 12 0 0 0 24 0V32a12 12 0 0 0-24 0v8h-56v-8a12 12 0 0 0-24 0v105.39a12 12 0 0 0 12 12ZM100 112v-12h56v12Zm56-48v12h-56V64ZM20 164a12 12 0 0 1 12-12c15.63 0 24 5.55 30.66 10 5.4 3.6 9 6 17.34 6s11.93-2.38 17.34-6c6.69-4.47 15-10 30.65-10s24 5.55 30.66 10c5.41 3.6 9 6 17.35 6s11.94-2.38 17.34-6c6.7-4.47 15-10 30.66-10a12 12 0 0 1 0 24c-8.37 0-11.94 2.38-17.35 6-6.69 4.47-15 10-30.65 10s-24-5.55-30.66-10c-5.41-3.6-9-6-17.35-6s-11.93 2.38-17.34 6c-6.69 4.47-15 10-30.65 10s-24-5.55-30.66-10c-5.4-3.6-9-6-17.34-6a12 12 0 0 1-12-12Zm216 44a12 12 0 0 1-12 12c-8.37 0-11.94 2.38-17.35 6-6.69 4.47-15 10-30.65 10s-24-5.55-30.66-10c-5.41-3.6-9-6-17.35-6s-11.93 2.38-17.34 6c-6.69 4.47-15 10-30.65 10s-24-5.55-30.66-10c-5.4-3.6-9-6-17.34-6a12 12 0 0 1 0-24c15.63 0 24 5.55 30.66 10 5.4 3.6 9 6 17.34 6s11.93-2.38 17.34-6c6.69-4.47 15-10 30.65-10s24 5.55 30.66 10c5.41 3.6 9 6 17.35 6s11.94-2.38 17.34-6c6.7-4.47 15-10 30.66-10a12 12 0 0 1 12 12Z" /></Svg>;
export default SwimmingPool;