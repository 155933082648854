/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-x-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-x-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 0 0 0 200 4 4 0 0 0 2.49-7.11c-.3-.24-23.57-19.41-33.78-56.88H128a4 4 0 0 0 0-8H94.82a140.42 140.42 0 0 1 0-56h66.36A137.89 137.89 0 0 1 164 128a4 4 0 0 0 8 0 149.23 149.23 0 0 0-2.59-28h46.23a91.6 91.6 0 0 1 4.36 28 4 4 0 0 0 8 0A100.11 100.11 0 0 0 128 28Zm-10.46 8.6A129.39 129.39 0 0 0 88.35 92h-45a92.25 92.25 0 0 1 74.19-55.4Zm0 182.8a92.25 92.25 0 0 1-74.2-55.4h45a129.39 129.39 0 0 0 29.2 55.4ZM86.59 156H40.36a92.09 92.09 0 0 1 0-56h46.23a152.65 152.65 0 0 0 0 56Zm10.14-64C105 61.8 121.67 43.48 128 37.39c6.33 6.09 23 24.41 31.27 54.61Zm70.92 0a129.39 129.39 0 0 0-29.19-55.4 92.25 92.25 0 0 1 74.2 55.4Zm51.18 78.83L197.66 192l21.17 21.17a4 4 0 0 1-5.66 5.66L192 197.66l-21.17 21.17a4 4 0 0 1-5.66-5.66L186.34 192l-21.17-21.17a4 4 0 0 1 5.66-5.66L192 186.34l21.17-21.17a4 4 0 0 1 5.66 5.66Z" /></Svg>;
export default GlobeX;