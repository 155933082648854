/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Robot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="robot-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-robot-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 48h-64V16a8 8 0 0 0-16 0v32H56a32 32 0 0 0-32 32v112a32 32 0 0 0 32 32h144a32 32 0 0 0 32-32V80a32 32 0 0 0-32-32Zm-28 48a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm-76 88H80a16 16 0 0 1 0-32h16Zm-12-64a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm60 64h-32v-32h32Zm32 0h-16v-32h16a16 16 0 0 1 0 32Z" /></Svg>;
export default Robot;