/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitBranch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-branch-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-branch-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 64a28 28 0 1 0-32 27.71V112a12 12 0 0 1-12 12H96a19.91 19.91 0 0 0-12 4V91.71a28 28 0 1 0-8 0v72.58a28 28 0 1 0 8 0V144a12 12 0 0 1 12-12h88a20 20 0 0 0 20-20V91.71A28 28 0 0 0 228 64ZM60 64a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm40 128a20 20 0 1 1-20-20 20 20 0 0 1 20 20ZM200 84a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default GitBranch;