/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WindowsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="windows-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-windows-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 138h-80a6 6 0 0 0-6 6v57.45a6 6 0 0 0 4.93 5.91l80 14.54a5.46 5.46 0 0 0 1.07.1 6 6 0 0 0 6-6v-72a6 6 0 0 0-6-6Zm-6 70.81-68-12.36V150h68ZM96 138H32a6 6 0 0 0-6 6v40a6 6 0 0 0 4.93 5.9l64 11.64a6.36 6.36 0 0 0 1.07.1 6 6 0 0 0 6-6V144a6 6 0 0 0-6-6Zm-6 50.45L38 179v-29h52ZM211.84 35.39a6 6 0 0 0-4.91-1.29l-80 14.54a6 6 0 0 0-4.93 5.91V112a6 6 0 0 0 6 6h80a6 6 0 0 0 6-6V40a6 6 0 0 0-2.16-4.61ZM202 106h-68V59.55l68-12.36ZM99.84 55.76a6 6 0 0 0-4.91-1.3l-64 11.64A6 6 0 0 0 26 72v40a6 6 0 0 0 6 6h64a6 6 0 0 0 6-6V60.36a6 6 0 0 0-2.16-4.6ZM90 106H38V77l52-9.46Z" /></Svg>;
export default WindowsLogo;