/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shower-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shower-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M62 236a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm22-42a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm-64 0a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm32-32a10 10 0 1 0 10 10 10 10 0 0 0-10-10ZM254 40a6 6 0 0 1-6 6h-29.51l-28.9 28.89-21.76 127.43a14 14 0 0 1-23.71 7.57l-98-98a14 14 0 0 1 7.6-23.71l127.4-21.76 30.65-30.65A6 6 0 0 1 216 34h32a6 6 0 0 1 6 6Zm-77.34 39.34L55.7 100a2 2 0 0 0-1.11 3.39l98 98a2 2 0 0 0 3.39-1.09Z" /></Svg>;
export default Shower;