/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitMerge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-merge-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-merge-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 116a28 28 0 0 0-27.62 23.44l-45.85-6.55a12 12 0 0 1-7.41-4.07L87.75 82.9a28 28 0 1 0-11.75.81v88.58a28 28 0 1 0 8 0V90.81L121 134a20 20 0 0 0 12.36 6.78l46.83 6.69A28 28 0 1 0 208 116ZM60 56a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm40 144a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm108-36a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default GitMerge;