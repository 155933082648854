/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pants = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pants-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pants-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M202.05 104H200a40 40 0 0 1-40-40H96a40 40 0 0 1-40 40h-2l8.06-65A8 8 0 0 1 70 32h116a8 8 0 0 1 7.94 7Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m223.88 214-22-176A16 16 0 0 0 186 24H70a16 16 0 0 0-15.88 14l-22 176A16 16 0 0 0 48 232h40.69a16 16 0 0 0 15.51-12.06l23.8-92 23.79 91.94A16 16 0 0 0 167.31 232H208a16 16 0 0 0 15.88-18ZM192.9 95.2A32.13 32.13 0 0 1 169 72h21ZM186 40l2 16H68l2-16ZM66 72h21a32.13 32.13 0 0 1-23.9 23.2Zm22.69 144H48l13-104.27A48.08 48.08 0 0 0 103.32 72H120v23Zm78.6-.06L136 95V72h16.68A48.08 48.08 0 0 0 195 111.73L208 216Z" /></Svg>;
export default Pants;