/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSubscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-subscript-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-subscript-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 208a12 12 0 0 1-12 12h-48a12 12 0 0 1-9.6-19.2l43.17-57.56a12 12 0 0 0-2.35-16.82 12 12 0 0 0-18.54 5.58 12 12 0 0 1-22.63-8 36.3 36.3 0 0 1 5.2-9.67 36 36 0 0 1 57.5 43.34L216 196h24a12 12 0 0 1 12 12ZM151.86 46.93a12 12 0 0 0-16.93 1.21L92 97.68 49.07 48.14a12 12 0 0 0-18.14 15.72L76.12 116l-45.19 52.14a12 12 0 0 0 18.14 15.72L92 134.32l42.93 49.54a12 12 0 1 0 18.14-15.72L107.88 116l45.19-52.14a12 12 0 0 0-1.21-16.93Z" /></Svg>;
export default TextSubscript;