/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cursor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cursor-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cursor-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m220.49 207.8-12.69 12.69a12 12 0 0 1-17 0l-56.57-56.57L115 214.08l-.13.33a15.84 15.84 0 0 1-14.61 9.59h-.78a15.82 15.82 0 0 1-14.41-11L32.8 52.92A15.95 15.95 0 0 1 52.92 32.8L213 85.07a16 16 0 0 1 1.41 29.8l-.33.13-50.16 19.27 56.57 56.56a12 12 0 0 1 0 16.97Z" /></Svg>;
export default Cursor;