/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SuitcaseRolling = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="suitcase-rolling-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-suitcase-rolling-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 96v80a12 12 0 0 1-24 0V96a12 12 0 0 1 24 0Zm24 0v80a12 12 0 0 0 24 0V96a12 12 0 0 0-24 0Zm72-32v144a20 20 0 0 1-20 20h-12v12a12 12 0 0 1-24 0v-12h-56v12a12 12 0 0 1-24 0v-12H64a20 20 0 0 1-20-20V64a20 20 0 0 1 20-20h20V28a28 28 0 0 1 28-28h32a28 28 0 0 1 28 28v16h20a20 20 0 0 1 20 20ZM108 44h40V28a4 4 0 0 0-4-4h-32a4 4 0 0 0-4 4Zm80 24H68v136h120Z" /></Svg>;
export default SuitcaseRolling;