/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Speedometer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speedometer-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speedometer-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m117.17 157.17 96-96a4 4 0 1 1 5.66 5.66l-96 96a4 4 0 0 1-5.66-5.66ZM128 84a67.94 67.94 0 0 1 21.72 3.54 4 4 0 1 0 2.55-7.54A76 76 0 0 0 52 152a79.27 79.27 0 0 0 .46 8.44 4 4 0 0 0 4 3.56h.45a4 4 0 0 0 3.54-4.42A68 68 0 0 1 128 84Zm96.17 18.82a4 4 0 1 0-7.11 3.64 100.34 100.34 0 0 1 5.26 78.84 4 4 0 0 1-3.79 2.7H37.46a4.07 4.07 0 0 1-3.8-2.74A100.05 100.05 0 0 1 173.54 62.94a4 4 0 1 0 3.64-7.12A107 107 0 0 0 129 44h-1A108.06 108.06 0 0 0 26.12 187.92 12.07 12.07 0 0 0 37.46 196h181.07a12 12 0 0 0 11.34-8 108.31 108.31 0 0 0-5.7-85.14Z" /></Svg>;
export default Speedometer;