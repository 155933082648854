/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleRun = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-run-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-run-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 56a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm103.28 74.08a8 8 0 0 0-10.6-4c-.25.12-26.71 10.72-72.18-20.19-52.29-35.54-88-7.77-89.51-6.57a8 8 0 1 0 10 12.48c.26-.21 25.12-19.5 64.07 3.27-4.25 13.35-12.76 31.82-25.25 47-18.56 22.48-41.11 32.56-67 30A8 8 0 0 0 31.2 208a92.29 92.29 0 0 0 9.34.47c27.38 0 52-12.38 71.63-36.18.57-.69 1.14-1.4 1.69-2.1C133.31 175.29 168 190.3 168 232a8 8 0 0 0 16 0c0-24.65-10.08-45.35-29.15-59.86a104.29 104.29 0 0 0-31.31-15.81A169.31 169.31 0 0 0 139 124c26.14 16.09 46.84 20 60.69 20 12.18 0 19.06-3 19.67-3.28a8 8 0 0 0 3.92-10.64Z" /></Svg>;
export default PersonSimpleRun;