/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tornado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tornado-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tornado-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 44a12 12 0 0 1-12 12H60a12 12 0 0 1 0-24h160a12 12 0 0 1 12 12Zm-56 44a12 12 0 0 0-12-12H44a12 12 0 0 0 0 24h120a12 12 0 0 0 12-12Zm0 44a12 12 0 0 0-12-12H76a12 12 0 0 0 0 24h88a12 12 0 0 0 12-12Zm-4 32h-40a12 12 0 0 0 0 24h40a12 12 0 0 0 0-24Zm-32 44h-16a12 12 0 0 0 0 24h16a12 12 0 0 0 0-24Z" /></Svg>;
export default Tornado;