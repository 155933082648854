/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Megaphone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="megaphone-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-megaphone-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 120a52.06 52.06 0 0 0-52-52h-39.68c-3.44-.21-52.6-4-99.46-43.3A20 20 0 0 0 28 40v160a19.8 19.8 0 0 0 11.54 18.12 19.86 19.86 0 0 0 21.32-2.81A192.92 192.92 0 0 1 144 174.47v26.2a20 20 0 0 0 8.9 16.64 11.35 11.35 0 0 0 1.39.8l14.44 7.06A20 20 0 0 0 198.37 213l11.09-41.82A52.07 52.07 0 0 0 252 120ZM52 191.63V48.4c36.17 28.07 72.17 38.1 92 41.66V150c-19.83 3.52-55.83 13.55-92 41.63Zm124.39 10.57-8.39-4.1V172h16.4ZM200 148h-32V92h32a28 28 0 1 1 0 56Z" /></Svg>;
export default Megaphone;