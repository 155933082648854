/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EscalatorUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="escalator-up-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-escalator-up-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 56v40a16 16 0 0 1-16 16h-36.5L93.88 213.43A8 8 0 0 1 88 216H32a16 16 0 0 1-16-16v-40a16 16 0 0 1 16-16h36.5l93.62-101.43A8 8 0 0 1 168 40h56a16 16 0 0 1 16 16Zm-34.34 90.34a8 8 0 0 0-11.32 0l-24 24a8 8 0 0 0 11.32 11.32L192 171.31V208a8 8 0 0 0 16 0v-36.69l10.34 10.35a8 8 0 0 0 11.32-11.32Z" /></Svg>;
export default EscalatorUp;