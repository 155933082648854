/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Kanban = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="kanban__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-kanban'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 48H40a8 8 0 0 0-8 8v152a16 16 0 0 0 16 16h40a16 16 0 0 0 16-16v-48h48v16a16 16 0 0 0 16 16h40a16 16 0 0 0 16-16V56a8 8 0 0 0-8-8ZM88 208H48v-80h40Zm0-96H48V64h40Zm64 32h-48V64h48Zm56 32h-40v-48h40Zm0-64h-40V64h40Z" /></Svg>;
export default Kanban;