/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Speedometer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speedometer-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speedometer-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m115.76 155.76 96-96a6 6 0 0 1 8.48 8.48l-96 96a6 6 0 0 1-8.48-8.48ZM128 86a65.9 65.9 0 0 1 21.08 3.44 6 6 0 0 0 3.83-11.38 78 78 0 0 0-102.43 82.6 6 6 0 0 0 6 5.34 5.12 5.12 0 0 0 .67 0 6 6 0 0 0 5.3-6.62A69 69 0 0 1 62 152a66.08 66.08 0 0 1 66-66Zm98 15.9a6 6 0 1 0-10.68 5.48 98.35 98.35 0 0 1 5.16 77.25 2 2 0 0 1-1.91 1.37H37.46a2.07 2.07 0 0 1-1.91-1.41A98.23 98.23 0 0 1 128 54h.9a97 97 0 0 1 43.71 10.72A6 6 0 1 0 178.1 54 108.92 108.92 0 0 0 129 42h-1A110.06 110.06 0 0 0 24.23 188.58 14.08 14.08 0 0 0 37.46 198h181.07a14.06 14.06 0 0 0 13.22-9.37A110.34 110.34 0 0 0 226 101.9Z" /></Svg>;
export default Speedometer;