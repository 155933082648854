/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SortDescending = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sort-descending-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sort-descending-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM72 72h40a8 8 0 0 1 0 16H72a8 8 0 0 1 0-16Zm0 48h48a8 8 0 0 1 0 16H72a8 8 0 0 1 0-16Zm96 64H72a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Zm29.66-82.34a8 8 0 0 1-11.32 0L176 91.31V136a8 8 0 0 1-16 0V91.31l-10.34 10.35a8 8 0 0 1-11.32-11.32l24-24a8 8 0 0 1 11.32 0l24 24a8 8 0 0 1 0 11.32Z" /></Svg>;
export default SortDescending;