/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-half-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-half-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 80v144S24 168 24 102a54 54 0 0 1 54-54c22.59 0 41.94 12.31 50 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M178 40c-20.65 0-38.73 8.88-50 23.89C116.73 48.88 98.65 40 78 40a62.07 62.07 0 0 0-62 62c0 70 103.79 126.67 108.21 129a8 8 0 0 0 7.58 0C136.21 228.67 240 172 240 102a62.07 62.07 0 0 0-62-62ZM32 102a46.06 46.06 0 0 1 46-46c18.91 0 34.86 9.79 42 25.65V210c-26.41-16.56-88-60.22-88-108Zm104 108V81.65C143.14 65.79 159.09 56 178 56a46.06 46.06 0 0 1 46 46c0 47.71-61.58 91.41-88 108Z" /></Svg>;
export default HeartHalf;