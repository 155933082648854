/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Gif = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gif-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gif-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M142 72v112a6 6 0 0 1-12 0V72a6 6 0 0 1 12 0Zm90-6h-56a6 6 0 0 0-6 6v112a6 6 0 0 0 12 0v-50h42a6 6 0 0 0 0-12h-42V78h50a6 6 0 0 0 0-12ZM96 122H72a6 6 0 0 0 0 12h18v18a26 26 0 0 1-52 0v-48a26 26 0 0 1 26-26c12.07 0 23.33 8.38 26.19 19.5a6 6 0 1 0 11.62-3C97.56 78 81.66 66 64 66a38 38 0 0 0-38 38v48a38 38 0 0 0 76 0v-24a6 6 0 0 0-6-6Z" /></Svg>;
export default Gif;