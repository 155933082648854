/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BeerStein = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beer-stein-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beer-stein-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 92h-20V72a36 36 0 0 0-36-36h-12.69C136 25.81 120.34 20 104 20c-33.08 0-60 23.33-60 52v136a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-12h20a20 20 0 0 0 20-20v-64a20 20 0 0 0-20-20ZM104 28c14.89 0 29.09 5.43 39 14.89a4 4 0 0 0 2.74 1.11H160a28 28 0 0 1 27.71 24H52.22c2.4-22.39 24.7-40 51.78-40Zm84 180a4 4 0 0 1-4 4H56a4 4 0 0 1-4-4V76h136Zm40-32a12 12 0 0 1-12 12h-20v-88h20a12 12 0 0 1 12 12Zm-128-72v80a4 4 0 0 1-8 0v-80a4 4 0 0 1 8 0Zm48 0v80a4 4 0 0 1-8 0v-80a4 4 0 0 1 8 0Z" /></Svg>;
export default BeerStein;