/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Intersection = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersection-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersection-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 120v80H56v-80a72 72 0 0 1 144 0Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 120v80a8 8 0 0 1-16 0v-80a64 64 0 0 0-128 0v80a8 8 0 0 1-16 0v-80a80 80 0 0 1 160 0Z" /></Svg>;
export default Intersection;