/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CrownSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.18 77.21A11.89 11.89 0 0 0 215 80.08l-37.81 40.76L138.9 35a12 12 0 0 0-21.82.05l-38.27 85.79L41 80.08a12 12 0 0 0-20.81 10v.15l22.69 103.9A12 12 0 0 0 54.71 204h146.58a12 12 0 0 0 11.8-9.83l22.69-103.9v-.15a11.89 11.89 0 0 0-6.6-12.91Zm-1.24 11.45-22.68 103.88v.16a4 4 0 0 1-3.94 3.3H54.71a4 4 0 0 1-3.94-3.3v-.16L28.06 88.66a4 4 0 0 1 7-3.26.3.3 0 0 0 .08.08l42 45.24a4 4 0 0 0 6.59-1.09l40.72-91.31a4 4 0 0 1 7.24 0l40.74 91.35a4 4 0 0 0 6.59 1.09l42-45.24a.3.3 0 0 0 .08-.08 4 4 0 0 1 7 3.26Z" /></Svg>;
export default CrownSimple;