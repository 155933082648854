/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crane__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crane'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.12 17.14a8 8 0 0 0-7.88-.2L102 80H32a16 16 0 0 0-16 16v104a16 16 0 0 0 16 16h88a16 16 0 0 0 16-16v-32a7.81 7.81 0 0 0-.34-2.3L113.54 92 216 37.33V160h-16v-8a8 8 0 0 0-16 0v8a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V24a8 8 0 0 0-3.88-6.86ZM98.05 96l19.2 64H64V96ZM48 96v64H32V96ZM32 200v-24h88v24Z" /></Svg>;
export default Crane;