/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugCharging = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plug-charging-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plug-charging-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48h-44V16a12 12 0 0 0-24 0v32h-56V16a12 12 0 0 0-24 0v32H32.55C24.4 48 20 54.18 20 60a12 12 0 0 0 12 12h12v92a44.05 44.05 0 0 0 44 44h28v32a12 12 0 0 0 24 0v-32h28a44.05 44.05 0 0 0 44-44V72h12a12 12 0 0 0 0-24Zm-36 116a20 20 0 0 1-20 20H88a20 20 0 0 1-20-20V72h120Zm-85.86-29.17a12 12 0 0 1-1.38-11l12-32a12 12 0 1 1 22.48 8.42L129.32 116H144a12 12 0 0 1 11.24 16.21l-12 32a12 12 0 0 1-22.48-8.42l5.92-15.79H112a12 12 0 0 1-9.86-5.17Z" /></Svg>;
export default PlugCharging;