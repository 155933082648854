/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Subway = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subway__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subway'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 96v112a8 8 0 0 1-16 0V96a56.06 56.06 0 0 0-56-56h-48a56.06 56.06 0 0 0-56 56v112a8 8 0 0 1-16 0V96a72.08 72.08 0 0 1 72-72h48a72.08 72.08 0 0 1 72 72Zm-40 0v72a24 24 0 0 1-19.29 23.53l2.45 4.89a8 8 0 0 1-14.32 7.16L147.06 192h-38.12l-5.78 11.58a8 8 0 0 1-14.32-7.16l2.45-4.89A24 24 0 0 1 72 168V96a24 24 0 0 1 24-24h64a24 24 0 0 1 24 24Zm-96 0v48h80V96a8 8 0 0 0-8-8H96a8 8 0 0 0-8 8Zm32 64v16h16v-16Zm-24 16h8v-16H88v8a8 8 0 0 0 8 8Zm72-8v-8h-16v16h8a8 8 0 0 0 8-8Z" /></Svg>;
export default Subway;