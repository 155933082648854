/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Paperclip = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paperclip-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paperclip-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm37.66 50.34a8 8 0 0 0-11.32 0L87.09 143A24 24 0 1 0 121 177l49.32-50.32a8 8 0 1 1 11.42 11.2l-49.37 50.38a40 40 0 1 1-56.62-56.51L143 63.09A24 24 0 1 1 177 97l-67.29 68.6a8 8 0 1 1-11.42-11.2l67.31-68.69a8 8 0 0 0 .06-11.37Z" /></Svg>;
export default Paperclip;