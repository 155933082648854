/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Golf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="golf-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-golf-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M175.47 197.14a8 8 0 0 1-4.61 10.33 125.91 125.91 0 0 1-34.86 8.21V248a8 8 0 0 1-16 0v-32.32a125.91 125.91 0 0 1-34.86-8.21 8 8 0 1 1 5.72-14.94C104 197.56 116.15 200 128 200s24-2.44 37.14-7.47a8 8 0 0 1 10.33 4.61ZM216 96a88 88 0 1 1-88-88 88.1 88.1 0 0 1 88 88Zm-72 36a12 12 0 1 0-12 12 12 12 0 0 0 12-12Zm32-32a12 12 0 1 0-12 12 12 12 0 0 0 12-12Z" /></Svg>;
export default Golf;