/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Password = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="password-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-password-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 56v144a12 12 0 0 1-24 0V56a12 12 0 0 1 24 0Zm86.73 50.7L120 111.48V96a12 12 0 0 0-24 0v15.48l-14.73-4.78a12 12 0 1 0-7.41 22.82l14.72 4.79-9.1 12.52a12 12 0 1 0 19.42 14.11l9.1-12.52 9.1 12.52a12 12 0 1 0 19.42-14.11l-9.1-12.52 14.72-4.79a12 12 0 1 0-7.41-22.82Zm115.12 7.7a12 12 0 0 0-15.12-7.7L220 111.48V96a12 12 0 0 0-24 0v15.48l-14.73-4.78a12 12 0 1 0-7.41 22.82l14.72 4.79-9.1 12.52a12 12 0 1 0 19.42 14.11l9.1-12.52 9.1 12.52a12 12 0 1 0 19.42-14.11l-9.1-12.52 14.72-4.79a12 12 0 0 0 7.71-15.12Z" /></Svg>;
export default Password;