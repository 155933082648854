/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Drone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drone-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drone-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M112 76a36 36 0 1 1-36-36 36 36 0 0 1 36 36Zm68 36a36 36 0 1 0-36-36 36 36 0 0 0 36 36ZM76 144a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm104 0a36 36 0 1 0 36 36 36 36 0 0 0-36-36Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M189.66 66.34a8 8 0 0 0-11.32 0L148.69 96h-41.38L77.66 66.34a8 8 0 0 0-11.32 11.32L96 107.31v41.38l-29.66 29.65a8 8 0 0 0 11.32 11.32L107.31 160h41.38l29.65 29.66a8 8 0 0 0 11.32-11.32L160 148.69v-41.38l29.66-29.65a8 8 0 0 0 0-11.32ZM112 112h32v32h-32Zm26.51-50.67a44 44 0 1 1 56.16 56.16 7.86 7.86 0 0 1-2.67.51 8 8 0 0 1-2.67-15.54 28 28 0 1 0-35.74-35.74 8 8 0 1 1-15.08-5.34ZM224 180a44 44 0 0 1-85.49 14.67 8 8 0 0 1 15.08-5.34 28 28 0 1 0 35.74-35.74 8 8 0 0 1 5.34-15.08A44.07 44.07 0 0 1 224 180Zm-106.51 14.67a44 44 0 1 1-56.16-56.16 8 8 0 0 1 5.34 15.08 28 28 0 1 0 35.74 35.74 8 8 0 0 1 15.08 5.34ZM32 76a44 44 0 0 1 85.49-14.67 8 8 0 1 1-15.08 5.34 28 28 0 1 0-35.74 35.74A8 8 0 0 1 64 118a7.86 7.86 0 0 1-2.67-.46A44.07 44.07 0 0 1 32 76Z" /></Svg>;
export default Drone;