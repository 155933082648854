/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sun = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sun-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sun-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M122 40V16a6 6 0 0 1 12 0v24a6 6 0 0 1-12 0Zm68 88a62 62 0 1 1-62-62 62.07 62.07 0 0 1 62 62Zm-12 0a50 50 0 1 0-50 50 50.06 50.06 0 0 0 50-50ZM59.76 68.24a6 6 0 1 0 8.48-8.48l-16-16a6 6 0 0 0-8.48 8.48Zm0 119.52-16 16a6 6 0 1 0 8.48 8.48l16-16a6 6 0 1 0-8.48-8.48ZM192 70a6 6 0 0 0 4.24-1.76l16-16a6 6 0 0 0-8.48-8.48l-16 16A6 6 0 0 0 192 70Zm4.24 117.76a6 6 0 0 0-8.48 8.48l16 16a6 6 0 0 0 8.48-8.48ZM46 128a6 6 0 0 0-6-6H16a6 6 0 0 0 0 12h24a6 6 0 0 0 6-6Zm82 82a6 6 0 0 0-6 6v24a6 6 0 0 0 12 0v-24a6 6 0 0 0-6-6Zm112-88h-24a6 6 0 0 0 0 12h24a6 6 0 0 0 0-12Z" /></Svg>;
export default Sun;