/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandPointing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-pointing-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-pointing-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 92a23.88 23.88 0 0 0-16.07 6.19A24 24 0 0 0 140 82.13V44a24 24 0 0 0-48 0v94l-11.25-18.06A24 24 0 0 0 39.2 144l4.68 8.25C61.21 182.8 72.66 203 85.66 216.33 99.28 230.3 113.86 236 136 236a84.09 84.09 0 0 0 84-84v-36a24 24 0 0 0-24-24Zm16 60a76.09 76.09 0 0 1-76 76c-40 0-51.35-20.08-85.16-79.71L46.15 140a16 16 0 0 1 27.71-16 .75.75 0 0 1 .07.12l18.68 30A4 4 0 0 0 100 152V44a16 16 0 0 1 32 0v68a4 4 0 0 0 8 0v-12a16 16 0 0 1 32 0v20a4 4 0 0 0 8 0v-4a16 16 0 0 1 32 0Z" /></Svg>;
export default HandPointing;