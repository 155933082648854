/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlipVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flip-vertical-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flip-vertical-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 120h160a16 16 0 0 0 6.23-30.74l-.14-.06-159.93-64A16 16 0 0 0 40 40v64a16 16 0 0 0 16 16Zm0-80 .15.06L216 104H56V40Zm175.67 108.82a15.85 15.85 0 0 1-9.45 17.92l-.14.06-159.93 64A16 16 0 0 1 40 216v-64a16 16 0 0 1 16-16h160a15.85 15.85 0 0 1 15.67 12.82Z" /></Svg>;
export default FlipVertical;