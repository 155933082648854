/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TelegramLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="telegram-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-telegram-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.49 23.16a13 13 0 0 0-13.23-2.26L15.6 100.21a18.22 18.22 0 0 0 3.12 34.86L68 144.74V200a20 20 0 0 0 34.4 13.88l22.67-23.51L162.35 223a20 20 0 0 0 32.7-10.54l40.62-176.55a13 13 0 0 0-4.18-12.75Zm-92.08 54.36-62.19 44.57-34.43-6.75ZM92 190.06v-28.71l15 13.15Zm81.16 10.52-73.88-64.77 106.31-76.18Z" /></Svg>;
export default TelegramLogo;