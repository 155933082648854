/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitPullRequest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-pull-request__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-pull-request'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 64a32 32 0 1 0-40 31v66a32 32 0 1 0 16 0V95a32.06 32.06 0 0 0 24-31Zm-48 0a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm32 128a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm120-31v-50.37a23.85 23.85 0 0 0-7-17L163.31 56H192a8 8 0 0 0 0-16h-48a8 8 0 0 0-8 8v48a8 8 0 0 0 16 0V67.31L189.66 105a8 8 0 0 1 2.34 5.66V161a32 32 0 1 0 16 0Zm-8 47a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default GitPullRequest;