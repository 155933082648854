/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderTransgender = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-transgender-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-transgender-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 36h-48a4 4 0 0 0 0 8h38.34L168 82.35l-21.17-21.17a4 4 0 1 0-5.66 5.65L162.34 88l-21.17 21.18a68.16 68.16 0 1 0 5.65 5.66L168 93.67l21.17 21.17a4 4 0 1 0 5.66-5.66L173.66 88 212 49.66V88a4 4 0 0 0 8 0V40a4 4 0 0 0-4-4Zm-77.57 166.45A60 60 0 1 1 156 160a60.07 60.07 0 0 1-17.57 42.45Z" /></Svg>;
export default GenderTransgender;