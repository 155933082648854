/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cylinder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cylinder__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cylinder'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 16c-40.37 0-72 19.33-72 44v136c0 24.67 31.63 44 72 44s72-19.33 72-44V60c0-24.67-31.63-44-72-44Zm0 16c26.49 0 56 11.5 56 28s-29.51 28-56 28-56-11.5-56-28 29.51-28 56-28Zm0 192c-29.83 0-56-13.08-56-28V88c13.1 9.85 33.14 16 56 16s42.9-6.2 56-16v108c0 14.92-26.17 28-56 28Z" /></Svg>;
export default Cylinder;