/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PokerChip = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="poker-chip-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-poker-chip-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 152a52 52 0 1 1 52-52 52.06 52.06 0 0 1-52 52Zm39.47-97.13A59.7 59.7 0 0 0 132 68.15V36.09a91.64 91.64 0 0 1 58.13 24.12ZM124 68.15a59.7 59.7 0 0 0-35.47 14.72L65.87 60.21A91.64 91.64 0 0 1 124 36.09ZM82.87 88.53A59.7 59.7 0 0 0 68.15 124H36.09a91.64 91.64 0 0 1 24.12-58.13ZM68.15 132a59.7 59.7 0 0 0 14.72 35.47l-22.66 22.66A91.64 91.64 0 0 1 36.09 132Zm20.38 41.13A59.7 59.7 0 0 0 124 187.85v32.06a91.64 91.64 0 0 1-58.13-24.12ZM132 187.85a59.7 59.7 0 0 0 35.47-14.72l22.66 22.66A91.64 91.64 0 0 1 132 219.91Zm41.13-20.38A59.7 59.7 0 0 0 187.85 132h32.06a91.64 91.64 0 0 1-24.12 58.13ZM187.85 124a59.7 59.7 0 0 0-14.72-35.47l22.66-22.66A91.64 91.64 0 0 1 219.91 124Z" /></Svg>;
export default PokerChip;