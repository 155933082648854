/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-x__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-x'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 0 0 0 208 8 8 0 0 0 5-14.23c-1.12-.91-20.88-17.32-31.06-49.77h26a8 8 0 0 0 0-16H98.08a140.17 140.17 0 0 1 0-48h59.88a138 138 0 0 1 2.04 24 8 8 0 0 0 16 0 154.7 154.7 0 0 0-1.84-24h38.51a87.61 87.61 0 0 1 3.33 24 8 8 0 0 0 16 0A104.11 104.11 0 0 0 128 24Zm-20.41 18.4A135.28 135.28 0 0 0 85.29 88H49.63a88.29 88.29 0 0 1 57.96-45.6Zm0 171.2a88.29 88.29 0 0 1-58-45.6h35.7a135.28 135.28 0 0 0 22.3 45.6ZM81.84 152H43.33a88.15 88.15 0 0 1 0-48h38.51a157.44 157.44 0 0 0 0 48ZM102 88a115.11 115.11 0 0 1 26-45 115.27 115.27 0 0 1 26 45Zm68.75 0a135.28 135.28 0 0 0-22.3-45.6 88.29 88.29 0 0 1 58 45.6Zm50.95 85.66L203.31 192l18.35 18.34a8 8 0 0 1-11.32 11.32L192 203.31l-18.34 18.35a8 8 0 0 1-11.32-11.32L180.69 192l-18.35-18.34a8 8 0 0 1 11.32-11.32L192 180.69l18.34-18.35a8 8 0 0 1 11.32 11.32Z" /></Svg>;
export default GlobeX;