/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilRuler = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-ruler-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-ruler-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28h-48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h48a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 176h-40v-24h16a12 12 0 0 0 0-24h-16v-16h16a12 12 0 0 0 0-24h-16v-16h16a12 12 0 0 0 0-24h-16V52h40ZM80.49 23.51a12 12 0 0 0-17 0l-32 32A12 12 0 0 0 28 64v144a20 20 0 0 0 20 20h48a20 20 0 0 0 20-20V64a12 12 0 0 0-3.51-8.49ZM52 92h40v72H52Zm20-43 19 19H53ZM52 204v-16h40v16Z" /></Svg>;
export default PencilRuler;