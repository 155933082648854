/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Usb = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="usb-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-usb-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m250.22 124.67-48-32A4 4 0 0 0 196 96v28H68V72a4 4 0 0 1 4-4h36.29a28 28 0 1 0 0-8H72a12 12 0 0 0-12 12v52H8a4 4 0 0 0 0 8h52v52a12 12 0 0 0 12 12h36v12a12 12 0 0 0 12 12h32a12 12 0 0 0 12-12v-32a12 12 0 0 0-12-12h-32a12 12 0 0 0-12 12v12H72a4 4 0 0 1-4-4v-52h128v28a4 4 0 0 0 2.11 3.53 4 4 0 0 0 4.11-.2l48-32a4 4 0 0 0 0-6.66ZM136 44a20 20 0 1 1-20 20 20 20 0 0 1 20-20Zm-20 132a4 4 0 0 1 4-4h32a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4h-32a4 4 0 0 1-4-4Zm88-23.47v-49.06L240.79 128Z" /></Svg>;
export default Usb;