/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Knife = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="knife-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-knife-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229 35a23.83 23.83 0 0 0-33.66 0L21.17 209.22a4 4 0 0 0 1.93 6.72A156.23 156.23 0 0 0 58.4 220c39.41 0 72.27-15.08 94.78-29.6 31.21-20.14 49.23-43 50-43.91a4 4 0 0 0-.32-5.29L179.67 118 229 68.63A23.84 23.84 0 0 0 229 35Zm-34.41 109.27a222.43 222.43 0 0 1-46 39.55c-37.85 24.35-77 33-116.55 25.81L146 95.67ZM223.38 63 174 112.36 151.66 90 201 40.62A15.8 15.8 0 0 1 223.38 63Z" /></Svg>;
export default Knife;