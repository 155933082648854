/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapTrifold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-trifold-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-trifold-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.38 46.54a12 12 0 0 0-10.29-2.18L161.4 59.28l-60-30a12 12 0 0 0-8.28-.91l-64 16A12 12 0 0 0 20 56v144a12 12 0 0 0 14.91 11.64l59.69-14.92 60 30a12 12 0 0 0 8.28.91l64-16A12 12 0 0 0 236 200V56a12 12 0 0 0-4.62-9.46ZM108 59.42l40 20v117.16l-40-20Zm-64 6 40-10v119.21l-40 10Zm168 125.21-40 10V81.37l40-10Z" /></Svg>;
export default MapTrifold;