/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LessThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="less-than-or-equal-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-less-than-or-equal-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-32 152H72a8 8 0 0 1 0-16h104a8 8 0 0 1 0 16Zm2.35-55.65a8 8 0 0 1-4.7 15.3l-104-32a8 8 0 0 1 0-15.3l104-32a8 8 0 0 1 4.7 15.3L99.2 104Z" /></Svg>;
export default LessThanOrEqual;