/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Browsers = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="browsers__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-browsers'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H72a16 16 0 0 0-16 16v16H40a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16v-16h16a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm-32 48v16H40V88Zm0 112H40v-80h144v80Zm32-32h-16V88a16 16 0 0 0-16-16H72V56h144Z" /></Svg>;
export default Browsers;