/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScanSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scan-smiley-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scan-smiley-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 40v28a12 12 0 0 1-24 0V52h-16a12 12 0 0 1 0-24h28a12 12 0 0 1 12 12Zm-12 136a12 12 0 0 0-12 12v16h-16a12 12 0 0 0 0 24h28a12 12 0 0 0 12-12v-28a12 12 0 0 0-12-12ZM68 204H52v-16a12 12 0 0 0-24 0v28a12 12 0 0 0 12 12h28a12 12 0 0 0 0-24ZM40 80a12 12 0 0 0 12-12V52h16a12 12 0 0 0 0-24H40a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12Zm88 128a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80Zm56-80a56 56 0 1 0-56 56 56.06 56.06 0 0 0 56-56Zm-80-24a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm48 0a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default ScanSmiley;