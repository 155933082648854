/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SnapchatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="snapchat-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-snapchat-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M251.75 181.48a11.88 11.88 0 0 0-7.66-8.84c-1.42-.54-25.86-10.18-39.35-43.23l15.68-6.27a12 12 0 1 0-8.91-22.28l-13.35 5.34A150.23 150.23 0 0 1 196 80a68 68 0 0 0-136 0 151.26 151.26 0 0 1-2.18 26.23l-13.36-5.34a12 12 0 1 0-8.91 22.28l15.68 6.27C37.74 162.46 13.31 172.09 12 172.6a12 12 0 0 0-4.17 20.05c8.09 7.6 19.85 8.76 30.23 9.79 5.62.55 12 1.18 14.85 2.75 2.59 1.42 5.94 6 8.9 10.07 5.51 7.56 12.38 17 23.47 19.8 10.23 2.61 20.11-.75 28.82-3.72 5-1.7 10.17-3.46 13.92-3.46s8.92 1.76 13.92 3.46c6.51 2.22 13.67 4.66 21.15 4.66a30.9 30.9 0 0 0 7.67-.94c11.09-2.84 18-12.24 23.47-19.8 3-4 6.31-8.65 8.9-10.07 2.85-1.57 9.23-2.2 14.85-2.75 10.38-1 22.14-2.19 30.23-9.79a12 12 0 0 0 3.54-11.17Zm-60.22 2.68c-7.27 4-12.29 10.88-16.72 17-3.25 4.45-7.3 10-10 10.7-3.3.85-9.32-1.2-15.14-3.18-6.53-2.23-13.93-4.75-21.65-4.75s-15.12 2.52-21.65 4.75c-5.82 2-11.84 4-15.14 3.18-2.74-.7-6.79-6.25-10-10.7-4.43-6.07-9.45-13-16.72-17-5.75-3.17-12.44-4.34-19.16-5.1a105.29 105.29 0 0 0 7.63-7.62c8.64-9.57 18.29-24 24.52-44.4a.14.14 0 0 0 0-.06 11.24 11.24 0 0 0 .63-2.13A162.57 162.57 0 0 0 84 80a44 44 0 0 1 88 0 162.57 162.57 0 0 0 5.92 44.88 12.64 12.64 0 0 0 .63 2.13.14.14 0 0 0 0 .06c6.23 20.44 15.88 34.83 24.52 44.4a105.29 105.29 0 0 0 7.63 7.62c-6.7.73-13.41 1.91-19.17 5.07Z" /></Svg>;
export default SnapchatLogo;