/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LastfmLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lastfm-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lastfm-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246 160a38 38 0 0 1-38 38h-17.43a54.21 54.21 0 0 1-48.94-31.16l-30.14-64.6A42.14 42.14 0 0 0 73.43 78H64a42 42 0 0 0-42 42v24a42 42 0 0 0 42 42h8a34 34 0 0 0 31.17-20.4 6 6 0 0 1 11 4.8A46 46 0 0 1 72 198h-8a54.06 54.06 0 0 1-54-54v-24a54.06 54.06 0 0 1 54-54h9.43a54.21 54.21 0 0 1 48.94 31.16l30.14 64.6A42.14 42.14 0 0 0 190.57 186H208a26 26 0 0 0 0-52h-20a34 34 0 0 1 0-68h20a30 30 0 0 1 30 30 6 6 0 0 1-12 0 18 18 0 0 0-18-18h-20a22 22 0 0 0 0 44h20a38 38 0 0 1 38 38Z" /></Svg>;
export default LastfmLogo;