/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Webcam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webcam-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webcam-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 104a40 40 0 1 0-40 40 40 40 0 0 0 40-40Zm-56 0a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm112 92h-84v-8.87a84 84 0 1 0-24 0V196H32a12 12 0 0 0 0 24h192a12 12 0 0 0 0-24ZM68 104a60 60 0 1 1 60 60 60.07 60.07 0 0 1-60-60Z" /></Svg>;
export default Webcam;