/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileJsx = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-jsx-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-jsx-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M145.83 196.05a18.89 18.89 0 0 1-8.31 13.81c-4.82 3.19-10.87 4.14-16.36 4.14a59.41 59.41 0 0 1-14.68-2 6 6 0 0 1 3.23-11.56c3.71 1 15.58 3.11 21.19-.62a6.85 6.85 0 0 0 3-5.34c.58-4.43-2.08-6.26-14.2-9.76-9.31-2.69-23.37-6.75-21.57-20.94a18.61 18.61 0 0 1 8.08-13.54c11.11-7.49 29.18-3 31.21-2.48a6 6 0 0 1-3.06 11.6c-3.79-1-15.85-2.95-21.45.84a6.59 6.59 0 0 0-2.88 5.08c-.41 3.22 2.14 4.78 13 7.91 9.89 2.89 24.81 7.2 22.8 22.86ZM72 146a6 6 0 0 0-6 6v38a12 12 0 0 1-24 0 6 6 0 0 0-12 0 24 24 0 0 0 48 0v-38a6 6 0 0 0-6-6Zm139.49 1.12a6 6 0 0 0-8.37 1.39L188 169.67l-15.12-21.17a6 6 0 0 0-9.76 7l17.51 24.5-17.51 24.5a6 6 0 1 0 9.76 7L188 190.31l15.12 21.16a6 6 0 1 0 9.76-7L195.37 180l17.51-24.51a6 6 0 0 0-1.39-8.38ZM214 88v24a6 6 0 1 1-12 0V94h-50a6 6 0 0 1-6-6V38H56a2 2 0 0 0-2 2v72a6 6 0 1 1-12 0V40a14 14 0 0 1 14-14h96a6 6 0 0 1 4.25 1.76l56 56A6 6 0 0 1 214 88Zm-20.49-6L158 46.48V82Z" /></Svg>;
export default FileJsx;