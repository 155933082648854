/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-break-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-break-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M178 44a57.59 57.59 0 0 0-41 17l-9 9-9-9a58 58 0 0 0-99 41c0 28.59 18 58.47 53.4 88.79a333.81 333.81 0 0 0 52.7 36.73 4 4 0 0 0 3.8 0 333.81 333.81 0 0 0 52.7-36.73C218 160.47 236 130.59 236 102a58.07 58.07 0 0 0-58-58Zm-50 175.42c-14-8-100-59.35-100-117.42a50 50 0 0 1 85.36-35.36l9 9-13.19 13.16a4 4 0 0 0 0 5.66l27.36 27.36-19.36 19.35a4 4 0 0 0 5.66 5.66L145 124.64a4 4 0 0 0 0-5.65l-27.34-27.36 13.16-13.16 11.8-11.81A50 50 0 0 1 228 102c0 58-86 109.46-100 117.42Z" /></Svg>;
export default HeartBreak;