/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Biohazard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="biohazard-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-biohazard-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.83 159.58a60.09 60.09 0 0 0-54.17-55.31 61.63 61.63 0 0 0-3-5.59 59.94 59.94 0 0 0-26.82-77.93l-.14-.08a8.1 8.1 0 0 0-1.14-.48 8 8 0 0 0-6.21 14.69h.07C149.6 35.57 168 45.73 168 68a40 40 0 0 1-2 12.53 63.83 63.83 0 0 0-76 0A40 40 0 0 1 88 68c0-22.35 18.53-32.51 19.65-33.1a8 8 0 0 0-7.33-14.22l-.15.08a60 60 0 0 0-26.85 78c-1.1 1.8-2.12 3.66-3 5.57a60.11 60.11 0 0 0-54.15 55.32 35.86 35.86 0 0 0-.14 4.87A8 8 0 0 0 32 164c0-1.36.07-2.71.19-4 .73-6.25 4.06-19.08 18.64-27.49a39.83 39.83 0 0 1 13.32-4.81c-.1 1.43-.16 2.88-.16 4.34a64.09 64.09 0 0 0 39 58.91 39.81 39.81 0 0 1-12.15 10.84c-19.07 11-36.88.36-38.39-.58l-.12-.08a8 8 0 0 0-8.71 13.42l.24.15a59.95 59.95 0 0 0 82.88-18.7h2.52a60 60 0 0 0 82.89 18.69l.23-.15a8 8 0 0 0-8.71-13.42l-.12.08c-1.51.94-19.32 11.59-38.39.58A39.84 39.84 0 0 1 153 190.9a64.09 64.09 0 0 0 39-58.9c0-1.46-.07-2.9-.16-4.33a39.84 39.84 0 0 1 13.33 4.8c14.47 8.35 17.86 21.06 18.63 27.32.13 1.39.2 2.79.2 4.21a8 8 0 0 0 16 .46 36 36 0 0 0-.17-4.88Zm-130.1 16.8A48.08 48.08 0 0 1 80 132c0-1.27.07-2.53.17-3.78l1 .25a40 40 0 0 1 28.54 47.91ZM128 134.11l-.11-.19h.22Zm0-26.11a39.91 39.91 0 0 1-29.93-13.49 47.84 47.84 0 0 1 59.84 0A39.88 39.88 0 0 1 128 108Zm18.29 68.37a39.9 39.9 0 0 1 29.55-48.13c.1 1.24.16 2.49.16 3.76a48.07 48.07 0 0 1-29.72 44.37Z" /></Svg>;
export default Biohazard;