/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CrownSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.75 90.81c0 .11 0 .21-.07.32L217 195a16 16 0 0 1-15.72 13H54.71A16 16 0 0 1 39 195L16.32 91.13c0-.11-.05-.21-.07-.32A16 16 0 0 1 44 77.39l33.67 36.29 35.8-80.29a1 1 0 0 0 0-.1 16 16 0 0 1 29.06 0 1 1 0 0 0 0 .1l35.8 80.29L212 77.39a16 16 0 0 1 27.71 13.42Z" /></Svg>;
export default CrownSimple;