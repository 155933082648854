/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PathIcon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="path-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-path-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 170a30.05 30.05 0 0 0-29.4 24H72a34 34 0 0 1 0-68h96a38 38 0 0 0 0-76H72a6 6 0 0 0 0 12h96a26 26 0 0 1 0 52H72a46 46 0 0 0 0 92h98.6a30 30 0 1 0 29.4-36Zm0 48a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default PathIcon;