/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-simple__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-simple'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M143.38 17.85a8 8 0 0 0-12.63 3.41l-22 60.41-24.16-23.41a8 8 0 0 0-11.93.89C51 87.53 40 116.08 40 144a88 88 0 0 0 176 0c0-59.45-50.79-108-72.62-126.15ZM128 216a72.08 72.08 0 0 1-72-72c0-22 8.09-44.79 24.06-67.84l26.37 25.58a8 8 0 0 0 13.09-3l22.27-61.07C164.21 58.08 200 97.91 200 144a72.08 72.08 0 0 1-72 72Z" /></Svg>;
export default FireSimple;