/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandPalm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-palm-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-palm-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188 84a32 32 0 0 0-8 1V60a32 32 0 0 0-43.21-30A32 32 0 0 0 76 44v1a32 32 0 0 0-40 31v76a92 92 0 0 0 184 0v-36a32 32 0 0 0-32-32Zm8 68a68 68 0 0 1-136 0V76a8 8 0 0 1 16 0v40a12 12 0 0 0 24 0V44a8 8 0 0 1 16 0v64a12 12 0 0 0 24 0V60a8 8 0 0 1 16 0v65.4a52.09 52.09 0 0 0-40 50.6 12 12 0 0 0 24 0 28 28 0 0 1 28-28 12 12 0 0 0 12-12v-20a8 8 0 0 1 16 0Z" /></Svg>;
export default HandPalm;