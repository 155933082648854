/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-x-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-x-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 0 0 0 204 6 6 0 0 0 3.76-10.67c-.1-.07-9.6-7.84-18.95-22.95A124 124 0 0 1 99.35 166H128a6 6 0 0 0 0-12H96.45a139.18 139.18 0 0 1 0-52h63.1a137.47 137.47 0 0 1 2.45 26 6 6 0 0 0 12 0 152.91 152.91 0 0 0-2.19-26h42.36a89.7 89.7 0 0 1 3.83 26 6 6 0 0 0 12 0A102.12 102.12 0 0 0 128 26Zm-15.46 13.33A132.58 132.58 0 0 0 86.81 90H46.43a90.29 90.29 0 0 1 66.11-50.67Zm0 177.34A90.29 90.29 0 0 1 46.43 166h40.38a132.58 132.58 0 0 0 25.73 50.67ZM84.19 154H41.83a90.17 90.17 0 0 1 0-52h42.36a155.43 155.43 0 0 0 0 52Zm15.16-64a124 124 0 0 1 13.46-30.37A109.19 109.19 0 0 1 128 40.18a109.19 109.19 0 0 1 15.19 19.45A124 124 0 0 1 156.65 90Zm69.84 0a132.58 132.58 0 0 0-25.73-50.67A90.29 90.29 0 0 1 209.57 90Zm51.05 82.24L200.49 192l19.75 19.76a6 6 0 1 1-8.48 8.48L192 200.49l-19.76 19.75a6 6 0 0 1-8.48-8.48L183.51 192l-19.75-19.76a6 6 0 0 1 8.48-8.48L192 183.51l19.76-19.75a6 6 0 0 1 8.48 8.48Z" /></Svg>;
export default GlobeX;