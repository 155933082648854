/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCloud = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-cloud-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-cloud-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m212.24 83.76-56-56A6 6 0 0 0 152 26H56a14 14 0 0 0-14 14v88a6 6 0 0 0 12 0V40a2 2 0 0 1 2-2h90v50a6 6 0 0 0 6 6h50v122a2 2 0 0 1-2 2h-24a6 6 0 0 0 0 12h24a14 14 0 0 0 14-14V88a6 6 0 0 0-1.76-4.24ZM158 46.48 193.52 82H158ZM108 130a50 50 0 0 0-46.66 32H60a34 34 0 0 0 0 68h48a50 50 0 0 0 0-100Zm0 88H60a22 22 0 0 1-1.65-43.94c-.06.47-.1.93-.15 1.4a6 6 0 1 0 12 1.08 38.57 38.57 0 0 1 1.1-6.54 5.71 5.71 0 0 0 .24-.86A38 38 0 1 1 108 218Z" /></Svg>;
export default FileCloud;