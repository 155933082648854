/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaperPlaneRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paper-plane-right-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paper-plane-right-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m229.89 117.43-168-95.88A12 12 0 0 0 44.7 36l31.08 92-31.07 92A12 12 0 0 0 56 236a12.13 12.13 0 0 0 5.93-1.57l167.94-96.08a12 12 0 0 0 0-20.92Zm-4 14L58 227.47a4 4 0 0 1-5.72-4.83v-.07L82.87 132H144a4 4 0 0 0 0-8H82.87L52.26 33.37A3.89 3.89 0 0 1 53.44 29 4.13 4.13 0 0 1 56 28a3.88 3.88 0 0 1 1.93.54l168 95.87a4 4 0 0 1 0 7Z" /></Svg>;
export default PaperPlaneRight;