/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sock = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sock-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sock-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 16h-88a16 16 0 0 0-16 16v76.69l-38.75 38.74a58.92 58.92 0 0 0 83.32 83.32L201 162.34a23.85 23.85 0 0 0 7-17V32a16 16 0 0 0-16-16Zm0 16v24h-88V32Zm-2.34 119-31.86 31.88a48 48 0 0 1 34.2-70.2v32.69a8 8 0 0 1-2.34 5.63Z" /></Svg>;
export default Sock;