/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bookmark = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bookmark-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bookmark-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 34H72a14 14 0 0 0-14 14v176a6 6 0 0 0 9.18 5.09l60.81-38 60.83 38A6 6 0 0 0 198 224V48a14 14 0 0 0-14-14ZM72 46h112a2 2 0 0 1 2 2v117.18l-54.83-34.27a6 6 0 0 0-6.36 0L70 165.17V48a2 2 0 0 1 2-2Zm59.17 132.91a6 6 0 0 0-6.36 0L70 213.17v-33.84l58-36.25 58 36.25v33.84Z" /></Svg>;
export default Bookmark;