/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyeglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyeglasses-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyeglasses-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 42a6 6 0 0 0 0 12 18 18 0 0 1 18 18v62.65A42 42 0 0 0 147.21 154h-38.42A42 42 0 0 0 38 134.65V72a18 18 0 0 1 18-18 6 6 0 0 0 0-12 30 30 0 0 0-30 30v92a42 42 0 0 0 84 2h36.1a42 42 0 0 0 83.9-2V72a30 30 0 0 0-30-30ZM68 194a30 30 0 1 1 30-30 30 30 0 0 1-30 30Zm120 0a30 30 0 1 1 30-30 30 30 0 0 1-30 30Z" /></Svg>;
export default Eyeglasses;