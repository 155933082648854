/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileySad = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-sad-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-sad-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84ZM76 108a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm104 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-3.26 57a12 12 0 0 1-19.48 14 36 36 0 0 0-58.52 0 12 12 0 0 1-19.48-14 60 60 0 0 1 97.48 0Z" /></Svg>;
export default SmileySad;