/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cow-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cow-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 128a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm32-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm95.48 12.66A20 20 0 0 1 232 132h-28v35.05A38 38 0 0 1 182 236H74a38 38 0 0 1-22-68.95V132H24a20 20 0 0 1-19.61-23.83 60.15 60.15 0 0 1 52-47.76A59.7 59.7 0 0 1 44 24a12 12 0 0 1 24 0 36 36 0 0 0 36 36h48a36 36 0 0 0 36-36 12 12 0 0 1 24 0 59.7 59.7 0 0 1-12.35 36.41 60.15 60.15 0 0 1 52 47.76 19.78 19.78 0 0 1-4.17 16.49ZM76 160h104v-56a20 20 0 0 0-20-20H96a20 20 0 0 0-20 20ZM56.5 84.64A36.15 36.15 0 0 0 29.26 108H52v-4a43.71 43.71 0 0 1 4.5-19.36ZM196 198a14 14 0 0 0-14-14H74a14 14 0 0 0 0 28h108a14 14 0 0 0 14-14Zm30.74-90a36.15 36.15 0 0 0-27.24-23.36A43.71 43.71 0 0 1 204 104v4Z" /></Svg>;
export default Cow;