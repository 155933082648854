/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cheese = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cheese-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cheese-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 36a4.05 4.05 0 0 0-1.15.17l-160 48A4 4 0 0 0 20 88v24a4 4 0 0 0 4 4h8a20.11 20.11 0 0 1 20 19.61 19.59 19.59 0 0 1-5.72 14.14A20.92 20.92 0 0 1 31.46 156H24a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h200a12 12 0 0 0 12-12V88a52.06 52.06 0 0 0-52-52Zm.57 8a44.06 44.06 0 0 1 43.24 40H51.25ZM196 104a28 28 0 1 1-53.29-12h50.58a28 28 0 0 1 2.71 12Zm-56 84H84v-4a28 28 0 0 1 56 0Zm88-4a4 4 0 0 1-4 4h-76v-4a36 36 0 0 0-72 0v4H28v-24h3.46A29 29 0 0 0 52 155.35a27.52 27.52 0 0 0 8-19.89A28.14 28.14 0 0 0 32 108h-4V92h106.07a36 36 0 1 0 67.86 0H228Z" /></Svg>;
export default Cheese;