/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartScatter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-scatter-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-scatter-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 208a4 4 0 0 1-4 4H32a4 4 0 0 1-4-4V48a4 4 0 0 1 8 0v156h188a4 4 0 0 1 4 4Zm-96-52a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm-24-56a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm-32 72a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm96-48a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm24-40a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm-8 88a8 8 0 1 0-8-8 8 8 0 0 0 8 8Z" /></Svg>;
export default ChartScatter;