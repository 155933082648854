/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandDeposit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-deposit-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-deposit-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M126 30.49V128a6 6 0 0 1-12 0V30.49L92.24 52.24a6 6 0 0 1-8.48-8.48l32-32a6 6 0 0 1 8.48 0l32 32a6 6 0 1 1-8.48 8.48Zm64 94.2V96a14 14 0 0 0-14-14h-16a6 6 0 0 0 0 12h16a2 2 0 0 1 2 2v86.14A26 26 0 0 0 133.49 209c0 .1.11.19.17.29l22.26 34a6 6 0 0 0 10-6.58l-22.12-33.84A14 14 0 0 1 168.13 189a2.75 2.75 0 0 0 .17.29l10.7 16.3a6 6 0 0 0 11-3.28v-62.74a76.83 76.83 0 0 1 28 59.08V240a6 6 0 0 0 12 0v-41.35a88.88 88.88 0 0 0-40-73.96ZM80 82H64a14 14 0 0 0-14 14v104a6 6 0 0 0 12 0V96a2 2 0 0 1 2-2h16a6 6 0 0 0 0-12Z" /></Svg>;
export default HandDeposit;