/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SneakerMove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sneaker-move__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sneaker-move'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m231.16 166.63-28.63-14.31A47.74 47.74 0 0 1 176 109.39V80a8 8 0 0 0-8-8 48.05 48.05 0 0 1-48-48 8 8 0 0 0-12.83-6.37L30.13 76l-.2.16a16 16 0 0 0-1.24 23.75L142.4 213.66a8 8 0 0 0 5.66 2.34H224a16 16 0 0 0 16-16v-19.06a15.92 15.92 0 0 0-8.84-14.31ZM224 200h-72.63L40 88.63l12.87-9.76 38.79 38.79A8 8 0 0 0 103 106.34L65.74 69.11l40-30.31A64.15 64.15 0 0 0 160 87.5v21.89a63.65 63.65 0 0 0 35.38 57.24L224 180.94ZM70.8 184H32a8 8 0 0 1 0-16h38.8a8 8 0 1 1 0 16Zm40 24a8 8 0 0 1-8 8H48a8 8 0 0 1 0-16h54.8a8 8 0 0 1 8 8Z" /></Svg>;
export default SneakerMove;