/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowLineDownRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-line-down-right__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-line-down-right'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 40a8 8 0 0 1-8 8H40a8 8 0 0 1 0-16h176a8 8 0 0 1 8 8Zm-32 56a8 8 0 0 0-8 8v76.69L85.66 82.34a8 8 0 0 0-11.32 11.32L172.69 192H96a8 8 0 0 0 0 16h96a8 8 0 0 0 8-8v-96a8 8 0 0 0-8-8Z" /></Svg>;
export default ArrowLineDownRight;