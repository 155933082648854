/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FalloutShelter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fallout-shelter-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fallout-shelter-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90Zm69.29-92.83A6 6 0 0 0 192 122h-52.79L165 83.33a6 6 0 0 0-5-9.33H96a6 6 0 0 0-5 9.33L116.79 122H64a6 6 0 0 0-5 9.33l32 48a6 6 0 0 0 10 0l27-40.51 27 40.51a6 6 0 0 0 10 0l32-48a6 6 0 0 0 .29-6.16ZM148.79 86 128 117.18 107.21 86ZM96 165.18 75.21 134h41.58Zm64 0L139.21 134h41.58Z" /></Svg>;
export default FalloutShelter;