/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seal-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seal-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 128c0 10.44-7.51 18.27-14.14 25.18-3.77 3.94-7.67 8-9.14 11.57-1.36 3.27-1.44 8.69-1.52 13.94-.15 9.76-.31 20.82-8 28.51s-18.75 7.85-28.51 8c-5.25.08-10.67.16-13.94 1.52-3.57 1.47-7.63 5.37-11.57 9.14C146.27 232.49 138.44 240 128 240s-18.27-7.51-25.18-14.14c-3.94-3.77-8-7.67-11.57-9.14-3.27-1.36-8.69-1.44-13.94-1.52-9.76-.15-20.82-.31-28.51-8s-7.85-18.75-8-28.51c-.08-5.25-.16-10.67-1.52-13.94-1.47-3.57-5.37-7.63-9.14-11.57C23.51 146.27 16 138.44 16 128s7.51-18.27 14.14-25.18c3.77-3.94 7.67-8 9.14-11.57 1.36-3.27 1.44-8.69 1.52-13.94.15-9.76.31-20.82 8-28.51s18.75-7.85 28.51-8c5.25-.08 10.67-.16 13.94-1.52 3.57-1.47 7.63-5.37 11.57-9.14C109.73 23.51 117.56 16 128 16s18.27 7.51 25.18 14.14c3.94 3.77 8 7.67 11.57 9.14 3.27 1.36 8.69 1.44 13.94 1.52 9.76.15 20.82.31 28.51 8s7.85 18.75 8 28.51c.08 5.25.16 10.67 1.52 13.94 1.47 3.57 5.37 7.63 9.14 11.57C232.49 109.73 240 117.56 240 128Z" /></Svg>;
export default Seal;