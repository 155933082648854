/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BeerStein = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beer-stein-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beer-stein-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 88h-16V72a40 40 0 0 0-40-40h-11.18c-11.91-10.2-28-16-44.82-16-35.29 0-64 25.12-64 56v136a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-8h16a24 24 0 0 0 24-24v-64a24 24 0 0 0-24-24Zm-112 96a8 8 0 0 1-16 0v-80a8 8 0 0 1 16 0Zm48 0a8 8 0 0 1-16 0v-80a8 8 0 0 1 16 0ZM57 64c4.46-18.24 23.85-32 47-32 13.87 0 27.06 5 36.21 13.78a8 8 0 0 0 5.53 2.22H160a24 24 0 0 1 22.62 16Zm167 112a8 8 0 0 1-8 8h-16v-80h16a8 8 0 0 1 8 8Z" /></Svg>;
export default BeerStein;