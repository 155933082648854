/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fire = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M179.94 152.67a53.51 53.51 0 0 1-43.28 43.27 3.68 3.68 0 0 1-.66.06 4 4 0 0 1-.66-7.94c18.18-3.06 33.63-18.51 36.72-36.73a4 4 0 0 1 7.88 1.34ZM212 144a84 84 0 0 1-168 0c0-27 10.71-54.75 31.84-82.43a4 4 0 0 1 6-.44l28.55 27.7 24.15-66.2a4 4 0 0 1 6.31-1.71C162.22 38.69 212 86.24 212 144Zm-8 0c0-50.82-41.73-93.91-63.94-113.23l-24.3 66.6a4 4 0 0 1-6.55 1.5L79.5 70C61.25 95.07 52 119.93 52 144a76 76 0 0 0 152 0Z" /></Svg>;
export default Fire;