/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleTaiChi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-tai-chi-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-tai-chi-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 84a36 36 0 1 0-36-36 36 36 0 0 0 36 36Zm0-48a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm100 72a12 12 0 0 1-12 12h-76v16.09L188.73 157a12 12 0 0 1 7.27 11v48a12 12 0 0 1-24 0v-40.09l-41.67-17.86L56 224.92a12 12 0 0 1-16-17.84l76-68.42V120H40a12 12 0 0 1 0-24h176a12 12 0 0 1 12 12Z" /></Svg>;
export default PersonSimpleTaiChi;