/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Handshake = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="handshake-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-handshake-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252.51 108.8 227 57.75a14 14 0 0 0-18.78-6.27l-25.56 12.78-53.13-14.06a6.1 6.1 0 0 0-3.06 0L73.34 64.26 47.79 51.48A14 14 0 0 0 29 57.75L3.49 108.8a14 14 0 0 0 6.26 18.78l27.15 13.58 55.61 39.72a6 6 0 0 0 2 .94l64 16a6.08 6.08 0 0 0 1.49.18 6 6 0 0 0 4.24-1.76l55.31-55.31 26.7-13.35a14 14 0 0 0 6.26-18.78Zm-53 35.16-35.8-28.68a6 6 0 0 0-8 .45c-18.65 18.79-39.5 16.42-52.79 7.92a2 2 0 0 1-.94-1.5 1.9 1.9 0 0 1 .51-1.55L146.43 78h33.86l28.41 56.82Zm-185.4-28.27a2 2 0 0 1 .11-1.52l25.52-51.06a2 2 0 0 1 1.8-1.1 2 2 0 0 1 .89.21l22.21 11.1L37.32 128l-22.21-11.1a2 2 0 0 1-1-1.21Zm144.05 69.67-59.6-14.9-50.9-36.36 29.18-58.35L128 62.21l14.8 3.92a5.92 5.92 0 0 0-3 1.57l-45.7 44.35a14 14 0 0 0 2.39 21.72c20.22 12.92 44.75 10.49 63.8-5.89L191 152.5Zm83.73-69.67a2 2 0 0 1-1 1.16L218.68 128l-27.32-54.68 22.21-11.1a2 2 0 0 1 1.53-.11 2 2 0 0 1 1.16 1l25.52 51.06a2 2 0 0 1 .11 1.52Zm-112 101.76a6 6 0 0 1-7.27 4.37l-41.73-10.43a5.88 5.88 0 0 1-2-.94l-26.37-18.81a6 6 0 1 1 7-9.77L84.91 200l40.61 10.15a6 6 0 0 1 4.36 7.3Z" /></Svg>;
export default Handshake;