/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyedropper = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyedropper-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyedropper-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 67.34a33.81 33.81 0 0 0-10.64-24.25c-13.24-12.53-34.68-12.09-47.82 1.09L142.82 65l-.63-.63a22 22 0 0 0-31.11 0l-9 9a14 14 0 0 0 0 19.81l3.47 3.47-52.41 52.45a37.79 37.79 0 0 0-9.84 36.73l-8.31 19a11.68 11.68 0 0 0 2.46 13 13.91 13.91 0 0 0 9.87 4.17 14.15 14.15 0 0 0 5.68-1.18l18-7.9a37.92 37.92 0 0 0 35.84-10.07l52.44-52.46 3.47 3.48a14 14 0 0 0 19.8 0l9-9a22 22 0 0 0 0-31.12l-.66-.66L212 91.85a33.76 33.76 0 0 0 10-24.51Zm-123.61 127a26 26 0 0 1-26 6.47 6 6 0 0 0-4.16.24l-20 8.75a2 2 0 0 1-2.09-.31l9.12-20.9a5.94 5.94 0 0 0 .19-4.31 25.88 25.88 0 0 1 6.26-26.72l52.44-52.45 36.76 36.78Zm105.16-111-25.38 25.56a6 6 0 0 0 0 8.47l4.88 4.89a10 10 0 0 1 0 14.15l-9 9a2 2 0 0 1-2.82 0l-60.69-60.7a2 2 0 0 1 0-2.83l9-9a10 10 0 0 1 14.14 0l4.89 4.89a6 6 0 0 0 4.24 1.75 6 6 0 0 0 4.25-1.77L172 52.66c8.58-8.58 22.52-9 31.08-.85a22 22 0 0 1 .44 31.57Z" /></Svg>;
export default Eyedropper;