/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Note = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="note-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-note-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h108.69a15.92 15.92 0 0 0 11.31-4.69L219.31 168a15.92 15.92 0 0 0 4.69-11.31V48a16 16 0 0 0-16-16ZM96 88h64a8 8 0 0 1 0 16H96a8 8 0 0 1 0-16Zm32 80H96a8 8 0 0 1 0-16h32a8 8 0 0 1 0 16Zm-32-32a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Zm64 68.69V160h44.7Z" /></Svg>;
export default Note;