/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardrop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 28a96.11 96.11 0 0 0-96 96v84a12 12 0 0 0 12 12h84a96 96 0 0 0 0-192Zm0 184H48a4 4 0 0 1-4-4v-84a88 88 0 1 1 88 88Z" /></Svg>;
export default ChatTeardrop;