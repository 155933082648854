/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BoxArrowUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="box-arrow-up-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-box-arrow-up-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m223.16 68.42-16-32A8 8 0 0 0 200 32H56a8 8 0 0 0-7.16 4.42l-16 32A8.08 8.08 0 0 0 32 72v136a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V72a8.08 8.08 0 0 0-.84-3.58Zm-57.5 73.24a8 8 0 0 1-11.32 0L136 123.31V184a8 8 0 0 1-16 0v-60.69l-18.34 18.35a8 8 0 0 1-11.32-11.32l32-32a8 8 0 0 1 11.32 0l32 32a8 8 0 0 1 0 11.32ZM52.94 64l8-16h134.12l8 16Z" /></Svg>;
export default BoxArrowUp;