/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Mountains = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mountains-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mountains-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164 76a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-40a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm87.44 162-54.56-92.08a11.91 11.91 0 0 0-10.33-5.92 11.89 11.89 0 0 0-10.32 5.88l-29.61 50-48.28-81.97a12 12 0 0 0-20.68 0L4.55 198A4 4 0 0 0 8 204h240a4 4 0 0 0 3.44-6ZM84.55 78a4 4 0 0 1 6.9 0l27.12 46H57.43ZM15 196l37.71-64h70.58L161 196Zm155.29 0-19-32.29L183.11 110a4 4 0 0 1 6.88 0l51 86Z" /></Svg>;
export default Mountains;