/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cow-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cow-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 192a8 8 0 0 1-8 8H80a8 8 0 0 1 0-16h16a8 8 0 0 1 8 8Zm72-8h-16a8 8 0 0 0 0 16h16a8 8 0 0 0 0-16Zm68.39-61.88A16 16 0 0 1 232 128h-32v32a40 40 0 0 1-24 72H80a40 40 0 0 1-24-72v-32H24a16 16 0 0 1-15.69-19 56.13 56.13 0 0 1 54.91-45h1.64A55.83 55.83 0 0 1 48 24a8 8 0 0 1 16 0 40 40 0 0 0 40 40h48a40 40 0 0 0 40-40 8 8 0 0 1 16 0 55.83 55.83 0 0 1-16.86 40h1.64a56.13 56.13 0 0 1 54.91 45 15.82 15.82 0 0 1-3.3 13.12ZM144 124a12 12 0 1 0 12-12 12 12 0 0 0-12 12Zm-56 0a12 12 0 1 0 12-12 12 12 0 0 0-12 12Zm-32-12v-8a39.81 39.81 0 0 1 8-24h-.8A40.09 40.09 0 0 0 24 112Zm144 80a24 24 0 0 0-24-24H80a24 24 0 0 0 0 48h96a24 24 0 0 0 24-24Zm32-80a40.08 40.08 0 0 0-39.2-32h-.8a39.81 39.81 0 0 1 8 24v8Z" /></Svg>;
export default Cow;