/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagPennant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-pennant-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-pennant-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 104a8 8 0 0 1-5.37 7.56L64 173.69V216a8 8 0 0 1-16 0V40a8 8 0 0 1 10.63-7.56l184 64A8 8 0 0 1 248 104Z" /></Svg>;
export default FlagPennant;