/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SkipBack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="skip-back-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-skip-back-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M201.75 30.52a20 20 0 0 0-20.3.53L68 102V40a12 12 0 0 0-24 0v176a12 12 0 0 0 24 0v-62l113.45 71A20 20 0 0 0 212 208.12V47.88a19.86 19.86 0 0 0-10.25-17.36ZM188 200.73 71.7 128 188 55.27Z" /></Svg>;
export default SkipBack;