/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hurricane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hurricane-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hurricane-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m158.32 45.36 9.41-35.3a8 8 0 0 0-9.41-9.88 195.87 195.87 0 0 0-58.65 25.09C60.63 50.37 40 85.89 40 128a88.11 88.11 0 0 0 57.68 82.64l-9.41 35.3a8 8 0 0 0 9.41 9.88 195.87 195.87 0 0 0 58.65-25.09C195.37 205.63 216 170.11 216 128a88.1 88.1 0 0 0-57.68-82.64ZM128 152a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default Hurricane;