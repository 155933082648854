/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dress = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dress-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dress-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m211 211.27-.06-.12-46.41-98.86 21.68-34v-.1a11.94 11.94 0 0 0 0-12.34l-.15-.24L164 34V8a4 4 0 0 0-8 0v25.82l-6.13 7.67a28 28 0 0 1-43.74 0L100 33.82V8a4 4 0 0 0-8 0v26L69.86 65.59l-.15.24a11.94 11.94 0 0 0 0 12.34.93.93 0 0 1 .05.1l21.68 34L45 211.15l-.06.12A12 12 0 0 0 56 228h144a12 12 0 0 0 11-16.73ZM76.54 74a4 4 0 0 1 0-4l19.67-28.1 3.68 4.59a36 36 0 0 0 56.22 0l3.68-4.59 19.7 28.1a4 4 0 0 1 0 4l-21.65 34H98.19Zm126.79 144.2A3.94 3.94 0 0 1 200 220H56a3.94 3.94 0 0 1-3.33-1.8 3.89 3.89 0 0 1-.36-3.72L98.54 116h58.92l46.23 98.48a3.89 3.89 0 0 1-.36 3.72Z" /></Svg>;
export default Dress;