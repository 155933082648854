/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitFork = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-fork-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-fork-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 64a28 28 0 1 0-32 27.71V112a12 12 0 0 1-12 12H80a12 12 0 0 1-12-12V91.71a28 28 0 1 0-8 0V112a20 20 0 0 0 20 20h44v32.29a28 28 0 1 0 8 0V132h44a20 20 0 0 0 20-20V91.71A28 28 0 0 0 220 64ZM44 64a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm104 128a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm44-108a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default GitFork;