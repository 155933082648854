/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleNyTimes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-ny-times-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-ny-times-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 98h104a6 6 0 0 1 0 12H128a6 6 0 0 1 0-12Zm104 32H128a6 6 0 0 0 0 12h104a6 6 0 0 0 0-12Zm0 32H80a6 6 0 0 0 0 12h152a6 6 0 0 0 0-12Zm0 32H80a6 6 0 0 0 0 12h152a6 6 0 0 0 0-12ZM96 142a6 6 0 0 0 0-12H86V62h36v10a6 6 0 0 0 12 0V56a6 6 0 0 0-6-6H32a6 6 0 0 0-6 6v16a6 6 0 0 0 12 0V62h36v68H64a6 6 0 0 0 0 12Z" /></Svg>;
export default ArticleNyTimes;