/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CowboyHat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cowboy-hat-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cowboy-hat-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 120a8 8 0 0 0-6.78 3.76A179.9 179.9 0 0 1 195.41 143l-1.63-8.57-15.46-81.36a16 16 0 0 0-25.72-9.55l-.13.1L128 64l-24.47-20.38-.13-.1a16 16 0 0 0-25.72 9.53l-15.45 81.33-1.64 8.62a179.27 179.27 0 0 1-13.81-19.25A8 8 0 0 0 40 120a40 40 0 0 0 0 80h176a40 40 0 0 0 0-80ZM76.68 144h102.63l2.54 13.35a113.28 113.28 0 0 1-27.35 19c-15.4 7.42-26.44 7.65-26.5 7.65-.33 0-25.49-.4-53.86-26.6Z" /></Svg>;
export default CowboyHat;