/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pen-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pen-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224.49 76.2 179.8 31.51a12 12 0 0 0-17 0L39.51 154.83a12 12 0 0 0-3.51 8.48V208a12 12 0 0 0 12 12h44.69a11.93 11.93 0 0 0 8.48-3.51l88.67-88.67 5.73 23-38.39 38.4a4 4 0 1 0 5.65 5.66l40-40a4 4 0 0 0 1.06-3.8l-7.46-29.8 28.06-28.06a12 12 0 0 0 0-17.02ZM44 208v-38.34L86.35 212H48a4 4 0 0 1-4-4Zm52 2.34L45.66 160 136 69.66 186.35 120ZM218.83 87.51 192 114.34 141.66 64l26.83-26.83a4 4 0 0 1 5.66 0l44.68 44.69a4 4 0 0 1 0 5.65Z" /></Svg>;
export default Pen;