/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldCheckered = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-checkered-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-checkered-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H48a20 20 0 0 0-20 20v56c0 54.29 26.32 87.22 48.4 105.29 23.71 19.39 47.44 26 48.44 26.29a12.1 12.1 0 0 0 6.32 0c1-.28 24.73-6.9 48.44-26.29 22.08-18.07 48.4-51 48.4-105.29V56a20 20 0 0 0-20-20Zm-4 76c0 1.34 0 2.68-.06 4H140V60h64ZM52 60h64v56H52.06c0-1.32-.06-2.66-.06-4Zm3 80h61v74.29a127 127 0 0 1-25.09-16.14C72.22 182.61 60.2 163.13 55 140Zm110.1 58.15a127 127 0 0 1-25.1 16.14V140h61c-5.2 23.13-17.22 42.61-35.91 58.15Z" /></Svg>;
export default ShieldCheckered;