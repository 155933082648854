/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LampPendant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lamp-pendant-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lamp-pendant-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174 76.05V72a14 14 0 0 0-14-14h-26V16a6 6 0 0 0-12 0v42H96a14 14 0 0 0-14 14v4a109.76 109.76 0 0 0-64 100 6 6 0 0 0 6 6h66v2a38 38 0 0 0 76 0v-2h66a6 6 0 0 0 6-6 109.76 109.76 0 0 0-64-99.95ZM154 184a26 26 0 0 1-52 0v-2h52ZM30.18 170a97.76 97.76 0 0 1 60.13-84.49A6 6 0 0 0 94 80v-8a2 2 0 0 1 2-2h64a2 2 0 0 1 2 2v8a6 6 0 0 0 3.69 5.54A97.76 97.76 0 0 1 225.82 170Z" /></Svg>;
export default LampPendant;