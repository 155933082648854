/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaperPlaneRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paper-plane-right-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paper-plane-right-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.86 110.48 65.8 14.58a20 20 0 0 0-28.65 24.06L67.33 128l-30.18 89.36A20 20 0 0 0 56 244a20.1 20.1 0 0 0 9.81-2.58l.09-.06 168-96.07a20 20 0 0 0 0-34.81ZM63.19 215.26 88.61 140H144a12 12 0 0 0 0-24H88.61L63.18 40.72l152.76 87.17Z" /></Svg>;
export default PaperPlaneRight;