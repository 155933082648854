/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pipe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pipe-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pipe-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 100h-16V60h16a12 12 0 0 0 0-24h-20a20 20 0 0 0-16-8h-24a20 20 0 0 0-16 8h-12A108.12 108.12 0 0 0 36 144v12a20 20 0 0 0-8 16v24a20 20 0 0 0 8 16v20a12 12 0 0 0 24 0v-16h40v16a12 12 0 0 0 24 0v-20a20 20 0 0 0 8-16v-24a20 20 0 0 0-8-16v-12a20 20 0 0 1 20-20h12a20 20 0 0 0 16 8h24a20 20 0 0 0 16-8h20a12 12 0 0 0 0-24Zm-124 76v16H52v-16Zm-8-32v8H60v-8a84.09 84.09 0 0 1 84-84h8v40h-8a44.05 44.05 0 0 0-44 44Zm76-92h16v56h-16Z" /></Svg>;
export default Pipe;