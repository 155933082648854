/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MediumLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="medium-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-medium-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 128a56 56 0 1 1-56-56 56 56 0 0 1 56 56Zm56-56c-13.25 0-24 25.07-24 56s10.75 56 24 56 24-25.07 24-56-10.75-56-24-56Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M72 64a64 64 0 1 0 64 64 64.07 64.07 0 0 0-64-64Zm0 112a48 48 0 1 1 48-48 48.05 48.05 0 0 1-48 48ZM184 64c-5.68 0-16.4 2.76-24.32 21.25C154.73 96.8 152 112 152 128s2.73 31.2 7.68 42.75C167.6 189.24 178.32 192 184 192s16.4-2.76 24.32-21.25C213.27 159.2 216 144 216 128s-2.73-31.2-7.68-42.75C200.4 66.76 189.68 64 184 64Zm0 112c-5.64 0-16-18.22-16-48s10.36-48 16-48 16 18.22 16 48-10.36 48-16 48Zm64-104v112a8 8 0 0 1-16 0V72a8 8 0 0 1 16 0Z" /></Svg>;
export default MediumLogo;