/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextAa = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-aa-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-aa-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 156c0 6.5-7.33 12-16 12s-16-5.5-16-12 7.33-12 16-12 16 5.5 16 12Zm32-100v144a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16Zm-88.63 116.88-44-104a8 8 0 0 0-14.74 0l-44 104a8 8 0 0 0 14.74 6.24L66.84 152h50.32l11.47 27.12a8 8 0 0 0 14.74-6.24ZM216 124c0-15.44-14.36-28-32-28a34.86 34.86 0 0 0-20.78 6.68 8 8 0 0 0 9.56 12.83A18.84 18.84 0 0 1 184 112c8.56 0 15.8 5.36 16 11.76v8a35.24 35.24 0 0 0-16-3.76c-17.64 0-32 12.56-32 28s14.36 28 32 28a35.13 35.13 0 0 0 16.93-4.26A8 8 0 0 0 216 176ZM73.61 136h36.78L92 92.53Z" /></Svg>;
export default TextAa;