/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bone-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bone-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.74 58.31a39.89 39.89 0 0 0-23.35-13.7 40 40 0 1 0-74.86 25.87l-66 66.05a40 40 0 1 0-25.87 74.86 40 40 0 1 0 74.86-25.86l66.05-66.06a40 40 0 0 0 49.22-61.16Zm-18.64 36.2a16 16 0 0 1-20.54 3 20 20 0 0 0-24.84 2.76l-70.4 70.4a20 20 0 0 0-2.75 24.84 16 16 0 1 1-29.41 6A12 12 0 0 0 56.3 187.7a11.61 11.61 0 0 0-1.85.14 16 16 0 1 1 6-29.4 20 20 0 0 0 24.83-2.76l70.4-70.4a20 20 0 0 0 2.76-24.84 16 16 0 1 1 29.4-6 12 12 0 0 0 13.71 13.71 16 16 0 0 1 14.55 26.36Z" /></Svg>;
export default Bone;