/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilSimpleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-simple-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-simple-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.32 73.37-44.69-44.68a16 16 0 0 0-22.63 0l-44.36 44.36a4 4 0 0 0-.14 5.52l58.73 64.6a4 4 0 0 0 5.79.13l47.3-47.3a16 16 0 0 0 0-22.63ZM192 108.69 147.32 64l24-24L216 84.69Zm21.92 101.93a8 8 0 1 1-11.84 10.76l-47.68-52.46-50.4 50.39A15.86 15.86 0 0 1 92.69 224H48a16 16 0 0 1-16-16v-44.69A15.89 15.89 0 0 1 36.68 152l53.6-53.6-48-52.82a8.18 8.18 0 0 1-.37-10.75 8 8 0 0 1 12-.21Z" /></Svg>;
export default PencilSimpleSlash;