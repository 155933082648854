/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radioactive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radioactive-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radioactive-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 132H40a12 12 0 0 1-8.81-3.91 12.2 12.2 0 0 1-3.14-9.31A99.82 99.82 0 0 1 70 46.2 12 12 0 0 1 87.29 50l25.94 45a4 4 0 0 1-6.92 4L80.36 54a4 4 0 0 0-5.78-1.27A91.83 91.83 0 0 0 36 119.5a4.2 4.2 0 0 0 1.08 3.2A3.9 3.9 0 0 0 40 124h52a4 4 0 0 1 0 8Zm136-13.22a99.82 99.82 0 0 0-42-72.58 12 12 0 0 0-17.28 3.8l-25.95 45a4 4 0 0 0 6.92 4l25.95-45a4 4 0 0 1 5.78-1.27A91.83 91.83 0 0 1 220 119.5a4.2 4.2 0 0 1-1.08 3.2A3.9 3.9 0 0 1 216 124h-52a4 4 0 0 0 0 8h52a12 12 0 0 0 8.81-3.91 12.2 12.2 0 0 0 3.19-9.31Zm-78.48 38.4a4 4 0 0 0-6.93 4l25.82 44.73a4 4 0 0 1 .35 3.22 3.9 3.9 0 0 1-2.12 2.41 92.24 92.24 0 0 1-77.18 0 3.9 3.9 0 0 1-2.12-2.41 4 4 0 0 1 .35-3.22l25.82-44.73a4 4 0 0 0-6.93-4l-25.87 44.73a12 12 0 0 0 5.34 16.89 100.24 100.24 0 0 0 83.9 0 12 12 0 0 0 5.34-16.89ZM128 136a8 8 0 1 0-8-8 8 8 0 0 0 8 8Z" /></Svg>;
export default Radioactive;