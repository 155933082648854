/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlowerLotus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flower-lotus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flower-lotus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244.1 122.63a13.56 13.56 0 0 0-8.3-6.4 72.62 72.62 0 0 0-24.17-2c4.67-20.63 1.62-36.91-1.45-46.4a14.16 14.16 0 0 0-16.48-9.53 84.21 84.21 0 0 0-29.76 13.11 92.6 92.6 0 0 0-27.52-34.6 14 14 0 0 0-16.85 0 92.7 92.7 0 0 0-27.51 34.6A84.16 84.16 0 0 0 62.29 58.3a14.15 14.15 0 0 0-16.47 9.54c-3.07 9.49-6.12 25.77-1.45 46.4a72.62 72.62 0 0 0-24.17 2 13.56 13.56 0 0 0-8.3 6.4 14 14 0 0 0-1.4 10.74C13.81 145.66 24 169 54.92 187.51S113.29 206 128 206s42.12 0 73.06-18.49 41.11-41.85 44.42-54.14a14 14 0 0 0-1.38-10.74ZM168.48 82.9A73.1 73.1 0 0 1 196.22 70a2.2 2.2 0 0 1 2.54 1.5c3.94 12.22 7.81 37.5-10.59 69.5a129.75 129.75 0 0 1-28 33.37C167.85 161 174 142.93 174 119.17a116.13 116.13 0 0 0-5.52-36.27ZM57.24 71.53A2.2 2.2 0 0 1 59.78 70a73.1 73.1 0 0 1 27.74 12.9A116.13 116.13 0 0 0 82 119.17c0 23.76 6.15 41.85 13.81 55.17a129.58 129.58 0 0 1-28-33.37C49.43 109 53.3 83.72 57.24 71.53Zm3.84 105.67c-27.3-16.31-36.15-36.42-39-47a2.08 2.08 0 0 1 .21-1.61 1.71 1.71 0 0 1 1-.8A62.16 62.16 0 0 1 48 126.72 126.25 126.25 0 0 0 57.43 147a141 141 0 0 0 41 44.72 114.83 114.83 0 0 1-37.35-14.52ZM128 192.86c-8.68-6.2-34-28.2-34-73.69 0-43.36 22.94-65.34 32.8-72.78a2 2 0 0 1 2.4 0c9.86 7.44 32.8 29.42 32.8 72.78 0 45.77-25.19 67.5-34 73.69Zm105.9-62.62c-2.85 10.54-11.7 30.65-39 47a114.83 114.83 0 0 1-37.38 14.47 141 141 0 0 0 41-44.72 126.25 126.25 0 0 0 9.48-20.27 62.16 62.16 0 0 1 24.73 1.11 1.71 1.71 0 0 1 1 .8 2.08 2.08 0 0 1 .19 1.61Z" /></Svg>;
export default FlowerLotus;