/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyNgn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-ngn-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-ngn-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 112v98l-51.51-66H64V46l51.51 66Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 136h-16v-16h16a8 8 0 0 0 0-16h-16V46a8 8 0 0 0-16 0v58h-64.58L70.31 41.08A8 8 0 0 0 56 46v58H40a8 8 0 0 0 0 16h16v16H40a8 8 0 0 0 0 16h16v58a8 8 0 0 0 16 0v-58h64.58l49.11 62.92A8 8 0 0 0 192 218a7.8 7.8 0 0 0 2.6-.44A8 8 0 0 0 200 210v-58h16a8 8 0 0 0 0-16Zm-32-16v16h-39.61l-12.49-16ZM72 69.25 99.12 104H72ZM72 136v-16h39.61l12.49 16Zm112 50.75L156.88 152H184Z" /></Svg>;
export default CurrencyNgn;