/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cricket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cricket-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cricket-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.9 82.79 189.21 30.1a14 14 0 0 0-19.79 0L62.1 137.42a14 14 0 0 0 0 19.79l22.1 22.1-48.44 48.45a6 6 0 1 0 8.48 8.48l48.45-48.44 22.1 22.1a14 14 0 0 0 19.79 0L241.9 102.58a14 14 0 0 0 0-19.79ZM126.1 201.42a2 2 0 0 1-2.83 0l-22.1-22.11 31.07-31.07a6 6 0 0 0-8.48-8.48l-31.07 31.07-22.11-22.1a2 2 0 0 1 0-2.83l35.9-35.9H162v55.52ZM233.42 94.1 174 153.52V104a6 6 0 0 0-6-6h-49.52l59.42-59.42a2 2 0 0 1 2.83 0l52.69 52.69a2 2 0 0 1 0 2.83ZM60 86a26 26 0 1 0-26-26 26 26 0 0 0 26 26Zm0-40a14 14 0 1 1-14 14 14 14 0 0 1 14-14Z" /></Svg>;
export default Cricket;