/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CursorClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cursor-click-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cursor-click-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.66 201 201 213.66a8 8 0 0 1-11.31 0l-51.31-51.31a8 8 0 0 0-13 2.46l-17.82 46.41a8 8 0 0 1-14.85-.71L40.41 50.44a8 8 0 0 1 10-10l160.1 52.24a8 8 0 0 1 .71 14.85l-46.41 17.82a8 8 0 0 0-2.46 13l51.31 51.31a8 8 0 0 1 0 11.34Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M88 24v-8a8 8 0 0 1 16 0v8a8 8 0 0 1-16 0Zm-72 80h8a8 8 0 0 0 0-16h-8a8 8 0 0 0 0 16Zm108.42-64.84a8 8 0 0 0 10.74-3.58l8-16a8 8 0 0 0-14.31-7.16l-8 16a8 8 0 0 0 3.57 10.74Zm-96 81.69-16 8a8 8 0 0 0 7.16 14.31l16-8a8 8 0 1 0-7.16-14.31ZM219.31 184a16 16 0 0 1 0 22.63l-12.68 12.68a16 16 0 0 1-22.63 0L132.7 168 115 214.09c0 .1-.08.21-.13.32a15.83 15.83 0 0 1-14.6 9.59h-.79a15.83 15.83 0 0 1-14.41-11L32.8 52.92A16 16 0 0 1 52.92 32.8L213 85.07a16 16 0 0 1 1.41 29.8l-.32.13L168 132.69ZM208 195.31 156.69 144a16 16 0 0 1 4.93-26l.32-.14 45.95-17.64L48 48l52.2 159.86 17.65-46c0-.11.08-.22.13-.33a16 16 0 0 1 11.69-9.34 16.72 16.72 0 0 1 3-.28 16 16 0 0 1 11.3 4.69l51.34 51.4Z" /></Svg>;
export default CursorClick;