/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrophoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m212.44 220-160-176a6 6 0 0 0-8.88 8L82 94.32V128a46 46 0 0 0 67.56 40.64l13.75 15.12A65.26 65.26 0 0 1 128 194a66.08 66.08 0 0 1-66-66 6 6 0 0 0-12 0 78.09 78.09 0 0 0 72 77.75V240a6 6 0 0 0 12 0v-34.23a76.93 76.93 0 0 0 37.48-13L203.56 228a6 6 0 0 0 8.88-8.08ZM128 162a34 34 0 0 1-34-34v-20.48l47.12 51.84A33.82 33.82 0 0 1 128 162Zm59.32-5a65.38 65.38 0 0 0 6.68-29 6 6 0 0 1 12 0 77.33 77.33 0 0 1-7.9 34.25 6 6 0 1 1-10.78-5.25ZM85.8 45.67A46 46 0 0 1 174 64v64a45.17 45.17 0 0 1-.25 4.81 6 6 0 0 1-6 5.38h-.63a6 6 0 0 1-5.34-6.59 35.41 35.41 0 0 0 .22-3.6V64a34 34 0 0 0-65.2-13.55 6 6 0 0 1-11-4.78Z" /></Svg>;
export default MicrophoneSlash;