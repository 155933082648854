/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lifebuoy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lifebuoy-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lifebuoy-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24ZM96 128a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm88.28-67.6-28.49 28.5a47.84 47.84 0 0 0-55.58 0L71.72 60.4a87.83 87.83 0 0 1 112.56 0ZM71.72 195.6l28.49-28.5a47.84 47.84 0 0 0 55.58 0l28.49 28.5a87.83 87.83 0 0 1-112.56 0Z" /></Svg>;
export default Lifebuoy;