/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatsCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chats-circle-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chats-circle-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.07 186.76a80 80 0 0 0-62.5-114.17 80 80 0 1 0-145.64 66.17l-7.27 24.71a16 16 0 0 0 19.87 19.87l24.71-7.27a80.39 80.39 0 0 0 25.18 7.35 80 80 0 0 0 108.34 40.65l24.71 7.27a16 16 0 0 0 19.87-19.86Zm-16.25 1.47L224 216l-27.76-8.17a8 8 0 0 0-6 .63 64.05 64.05 0 0 1-85.87-24.88 79.93 79.93 0 0 0 70.33-93.87 64 64 0 0 1 41.75 92.48 8 8 0 0 0-.63 6.04Z" /></Svg>;
export default ChatsCircle;