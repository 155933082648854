/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const QuestionMark = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="question-mark-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-question-mark-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 96c0 29.47-24.21 54.05-56 59.06v.94a12 12 0 0 1-24 0v-12a12 12 0 0 1 12-12c24.26 0 44-16.15 44-36s-19.74-36-44-36-44 16.15-44 36a12 12 0 0 1-24 0c0-33.08 30.5-60 68-60s68 26.92 68 60Zm-68 92a20 20 0 1 0 20 20 20 20 0 0 0-20-20Z" /></Svg>;
export default QuestionMark;