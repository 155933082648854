/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Strategy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="strategy-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-strategy-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 144a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 56a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm-32-72a12 12 0 0 1-8.49-20.49L51 96 39.51 84.49a12 12 0 0 1 17-17L68 79l11.51-11.49a12 12 0 0 1 17 17L85 96l11.52 11.51a12 12 0 0 1-17 17L68 113l-11.51 11.49A12 12 0 0 1 48 128Zm184.49 75.51a12 12 0 0 1-17 17L204 209l-11.51 11.52a12 12 0 0 1-17-17L187 192l-11.52-11.51a12 12 0 0 1 17-17L204 175l11.51-11.52a12 12 0 0 1 17 17L221 192Zm-43.4-92.62c-5.21 23-23.33 43.53-45.09 51.22a12 12 0 1 1-8-22.63c14.07-5 26.27-18.91 29.67-33.9 2.37-10.46.4-20.84-5.68-30.54v9a12 12 0 0 1-24 0V44a12 12 0 0 1 12-12h40a12 12 0 0 1 0 24H176c12.77 16.61 17.42 35.76 13.09 54.89Z" /></Svg>;
export default Strategy;