/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinkBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="link-break-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-link-break-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 56a34 34 0 0 0-48-.05l-11.66 12.19a6 6 0 1 1-8.68-8.28l11.71-12.28.1-.11a46 46 0 0 1 65.06 65.06l-.11.1-12.28 11.71a6 6 0 1 1-8.28-8.68L200.09 104a34 34 0 0 0-.09-48Zm-84.38 131.9L104 200.09A34 34 0 0 1 55.91 152l12.23-11.67a6 6 0 0 0-8.28-8.68l-12.28 11.72-.11.1a46 46 0 0 0 65.06 65.06l.1-.11 11.71-12.28a6 6 0 1 0-8.68-8.28ZM216 154h-24a6 6 0 0 0 0 12h24a6 6 0 0 0 0-12ZM40 102h24a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12Zm120 84a6 6 0 0 0-6 6v24a6 6 0 0 0 12 0v-24a6 6 0 0 0-6-6ZM96 70a6 6 0 0 0 6-6V40a6 6 0 0 0-12 0v24a6 6 0 0 0 6 6Z" /></Svg>;
export default LinkBreak;