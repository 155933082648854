/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleDriveLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-drive-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-drive-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.19 145.86 165.74 33.64A11.94 11.94 0 0 0 155.56 28h-55.13a11.93 11.93 0 0 0-10.17 5.64L21.83 145.82a12 12 0 0 0-.25 12.32L48.91 206a12 12 0 0 0 10.41 6h137.35a12 12 0 0 0 10.42-6l27.32-47.81a12 12 0 0 0-.22-12.33Zm-8.06 2.14h-55.87l-37.6-62.67 27.39-45.65Zm-131.07 0L128 93.11 160.93 148Zm70.67 8 28.8 48H61.47l28.79-48ZM128 77.56 103.06 36h49.87ZM95.94 39.68l27.39 45.65L85.74 148H29.87ZM29.58 156h51.36l-26.31 43.85Zm171.79 43.85L175.06 156h51.36Z" /></Svg>;
export default GoogleDriveLogo;