/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-minus__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-minus'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 56a8 8 0 0 1 8-8h48a8 8 0 0 1 0 16h-48a8 8 0 0 1-8-8Zm58.08 37.33a103.93 103.93 0 1 1-80.76-67.89 8 8 0 0 1-2.64 15.78A88.07 88.07 0 0 0 40 128a87.62 87.62 0 0 0 22.24 58.41 79.66 79.66 0 0 1 36.06-28.75 48 48 0 1 1 59.4 0 79.66 79.66 0 0 1 36.06 28.75A88 88 0 0 0 211 98.67a8 8 0 0 1 15.09-5.34ZM128 152a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm0 64a87.57 87.57 0 0 0 53.92-18.5 64 64 0 0 0-107.84 0A87.57 87.57 0 0 0 128 216Z" /></Svg>;
export default UserCircleMinus;