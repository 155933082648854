/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Yarn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="yarn-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-yarn-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M69.4 144.89a220.94 220.94 0 0 0-18.77 42.6 4 4 0 0 1-7 1.19 103.44 103.44 0 0 1-18.83-48.11 4 4 0 0 1 4.13-4.47 119 119 0 0 1 40.47 8.79ZM44 77.14a180.06 180.06 0 0 1 63 19.12 226.9 226.9 0 0 1 22.6-19.49 206.42 206.42 0 0 0-69.28-25.6 4 4 0 0 0-3.42 1A105.3 105.3 0 0 0 41.08 71 4 4 0 0 0 44 77.14Zm195.58 144.21A7.91 7.91 0 0 0 232 216h-48.64a104.25 104.25 0 0 0 46.89-69 4 4 0 0 0-5.27-4.52 120.6 120.6 0 0 0-74.1 73.52H134a136.55 136.55 0 0 1 94.78-91.37 4 4 0 0 0 2.92-4.15 102.59 102.59 0 0 0-3.58-20.56 4 4 0 0 0-4.89-2.8A164.53 164.53 0 0 0 103 225a4 4 0 0 0 3.08 4.69A103.9 103.9 0 0 0 128 232h104a8 8 0 0 0 7.58-10.65Zm-211-101.27a134.51 134.51 0 0 1 49.39 11 224.44 224.44 0 0 1 17.55-22.68 164.28 164.28 0 0 0-62.16-16.12 4 4 0 0 0-4 2.75 103 103 0 0 0-4.63 20.61 4 4 0 0 0 3.84 4.44Zm57.26-79.42A222.81 222.81 0 0 1 144 66.8a221.3 221.3 0 0 1 38.8-19.67 4 4 0 0 0 .7-7.08 103.86 103.86 0 0 0-98.2-6.85 4 4 0 0 0 .54 7.46ZM216 82.51a4 4 0 0 0 2.4-5.87 105 105 0 0 0-12.82-17.81 4 4 0 0 0-4.21-1.19A208.81 208.81 0 0 0 62.21 205.51a4 4 0 0 0 1.44 4.13 104.25 104.25 0 0 0 18.55 11.72 4 4 0 0 0 5.71-2.75A180.61 180.61 0 0 1 216 82.51Z" /></Svg>;
export default Yarn;