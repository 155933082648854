/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapTrifold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-trifold-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-trifold-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.69 51.27a6 6 0 0 0-5.15-1.09L160.7 65.64l-62-31a6 6 0 0 0-4.14-.45l-64 16A6 6 0 0 0 26 56v144a6 6 0 0 0 7.46 5.82l61.84-15.46 62 31a6 6 0 0 0 4.14.45l64-16A6 6 0 0 0 230 200V56a6 6 0 0 0-2.31-4.73ZM102 49.71l52 26v130.58l-52-26Zm-64 11 52-13v131.61l-52 13Zm180 134.61-52 13V76.68l52-13Z" /></Svg>;
export default MapTrifold;