/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThumbsUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="thumbs-up-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-thumbs-up-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.49 81.44A22 22 0 0 0 216 74h-58V56a38 38 0 0 0-38-38 6 6 0 0 0-5.37 3.32L76.29 98H32a14 14 0 0 0-14 14v88a14 14 0 0 0 14 14h172a22 22 0 0 0 21.83-19.27l12-96a22 22 0 0 0-5.34-17.29ZM30 200v-88a2 2 0 0 1 2-2h42v92H32a2 2 0 0 1-2-2ZM225.92 97.24l-12 96A10 10 0 0 1 204 202H86v-96.58l37.58-75.17A26 26 0 0 1 146 56v24a6 6 0 0 0 6 6h64a10 10 0 0 1 9.92 11.24Z" /></Svg>;
export default ThumbsUp;