/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderNeuter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-neuter__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-neuter'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 104a80 80 0 1 0-88 79.6V232a8 8 0 0 0 16 0v-48.4a80.11 80.11 0 0 0 72-79.6Zm-80 64a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64Z" /></Svg>;
export default GenderNeuter;