/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bookmarks = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bookmarks-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bookmarks-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 28H96a12 12 0 0 0-12 12v20H64a12 12 0 0 0-12 12v152a4 4 0 0 0 6.33 3.25L112 188.92l53.69 38.33a3.94 3.94 0 0 0 2.31.75 4.08 4.08 0 0 0 1.83-.44A4 4 0 0 0 172 224v-47.08l25.68 18.33a3.94 3.94 0 0 0 2.32.75 4.08 4.08 0 0 0 1.83-.44A4 4 0 0 0 204 192V40a12 12 0 0 0-12-12Zm-28 188.23-49.68-35.48a4 4 0 0 0-4.65 0L60 216.23V72a4 4 0 0 1 4-4h96a4 4 0 0 1 4 4Zm32-32-24-17.14V72a12 12 0 0 0-12-12H92V40a4 4 0 0 1 4-4h96a4 4 0 0 1 4 4Z" /></Svg>;
export default Bookmarks;