/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlipHorizontal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flip-horizontal-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flip-horizontal-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M106.38 28.26a11.76 11.76 0 0 0-13.44 7.08v.08L29 195.32A12 12 0 0 0 40 212h64a12 12 0 0 0 12-12V40a11.75 11.75 0 0 0-9.62-11.74ZM108 200a4 4 0 0 1-4 4H40a3.93 3.93 0 0 1-3.33-1.79 4 4 0 0 1-.32-3.84l64-159.94A4 4 0 0 1 108 40Zm119.07-4.6-64-160v-.08A12 12 0 0 0 140 40v160a12 12 0 0 0 12 12h64a12 12 0 0 0 11.08-16.6Zm-7.75 6.81A3.93 3.93 0 0 1 216 204h-64a4 4 0 0 1-4-4V40a3.81 3.81 0 0 1 3.2-3.92 4.64 4.64 0 0 1 .9-.09 3.69 3.69 0 0 1 3.57 2.42l64 160a3.9 3.9 0 0 1-.35 3.8Z" /></Svg>;
export default FlipHorizontal;