/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Intersect = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersect-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersect-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 96a72 72 0 0 1-72 72 73.37 73.37 0 0 1-7.6-.4 73.37 73.37 0 0 1-.4-7.6 72 72 0 0 1 72-72 73.37 73.37 0 0 1 7.6.4 73.37 73.37 0 0 1 .4 7.6Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M174.63 81.37a80 80 0 1 0-93.26 93.26 80 80 0 1 0 93.26-93.26ZM32 96a64 64 0 0 1 126-16 80.08 80.08 0 0 0-77.95 78A64.11 64.11 0 0 1 32 96Zm128 0a64.07 64.07 0 0 1-64 64 64.07 64.07 0 0 1 64-64Zm0 128a64.11 64.11 0 0 1-62-48 80.08 80.08 0 0 0 78-78 64 64 0 0 1-16 126Z" /></Svg>;
export default Intersect;