/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pipe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pipe-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pipe-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 48v64h-24a32 32 0 0 0-32 32v24H48v-24a96 96 0 0 1 96-96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 104h-24V56h24a8 8 0 0 0 0-16h-26.17A16 16 0 0 0 192 32h-16a16 16 0 0 0-13.83 8H144A104.11 104.11 0 0 0 40 144v18.16A16 16 0 0 0 32 176v16a16 16 0 0 0 8 13.84V232a8 8 0 0 0 16 0v-24h48v24a8 8 0 0 0 16 0v-26.16a16 16 0 0 0 8-13.84v-16a16 16 0 0 0-8-13.84V144a24 24 0 0 1 24-24h18.17a16 16 0 0 0 13.83 8h16a16 16 0 0 0 13.83-8H232a8 8 0 0 0 0-16Zm-120 72v16H48v-16Zm-8-32v16H56v-16a88.1 88.1 0 0 1 88-88h16v48h-16a40 40 0 0 0-40 40Zm72-32V48h16v64Z" /></Svg>;
export default Pipe;