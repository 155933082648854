/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Gauge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gauge-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gauge-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M205.65 74.08A109.26 109.26 0 0 0 128 42h-.39C67.17 42.21 18 92.06 18 153.13V176a14 14 0 0 0 14 14h192a14 14 0 0 0 14-14v-24a109.3 109.3 0 0 0-32.35-77.92ZM226 176a2 2 0 0 1-2 2H115.78l57.07-78.47a6 6 0 0 0-9.7-7.06L100.94 178H32a2 2 0 0 1-2-2v-22.87a102.36 102.36 0 0 1 .62-11.13H56a6 6 0 0 0 0-12H32.71C42.6 88.4 78.53 56.86 122 54.19V80a6 6 0 0 0 12 0V54.19A98.05 98.05 0 0 1 223.53 130H200a6 6 0 0 0 0 12h25.5c.33 3.3.5 6.64.5 10Z" /></Svg>;
export default Gauge;