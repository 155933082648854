/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hockey = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hockey-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hockey-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 148h-91.25L41.14 40.23a12 12 0 0 0-18.28 15.54l95.16 112 .06.07L156.46 213a20 20 0 0 0 15.24 7H224a20 20 0 0 0 20-20v-32a20 20 0 0 0-20-20Zm-70.85 24H180v24h-6.45ZM220 196h-16v-24h16Zm-66.34-83.77 61.2-72a12 12 0 0 1 18.28 15.54l-61.2 72a12 12 0 1 1-18.28-15.54Zm-44.14 70.45a12 12 0 0 0-16.91 1.37L82.45 196H76v-24.07A12 12 0 0 0 74.7 148H32a20 20 0 0 0-20 20v32a20 20 0 0 0 20 20h52.3a20 20 0 0 0 15.24-7l11.35-13.36a12 12 0 0 0-1.37-16.96ZM36 172h16v24H36Z" /></Svg>;
export default Hockey;