/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BellSimpleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bell-simple-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bell-simple-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.84 192a1.85 1.85 0 0 1-3 .28L83.27 43.19a4 4 0 0 1 .8-6A79.55 79.55 0 0 1 129.17 24c43.83.66 78.63 37.1 78.83 80.92.14 34.88 8.31 61.54 13.82 71a15.89 15.89 0 0 1 .02 16.08ZM160 216H96.22a8.19 8.19 0 0 0-8.22 7.47 8 8 0 0 0 8 8.53h63.74a8.19 8.19 0 0 0 8.26-7.47 8 8 0 0 0-8-8.53ZM53.84 34.62A8 8 0 1 0 42 45.38l16.79 18.47A79.42 79.42 0 0 0 47.93 104c0 35.09-8.15 62-13.7 71.73a16.42 16.42 0 0 0 .09 16.68A15.78 15.78 0 0 0 47.91 200h134.71l19.45 21.38a8 8 0 0 0 11.85-10.76Z" /></Svg>;
export default BellSimpleSlash;