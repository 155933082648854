/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Equalizer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="equalizer__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-equalizer'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 96a8 8 0 0 1-8 8H24a8 8 0 0 1 0-16h48a8 8 0 0 1 8 8Zm-8 24H24a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm0 32H24a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm0 32H24a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm80-64h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm0 32h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm0 32h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm80-96h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm-48-16h48a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16Zm48 48h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm0 32h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Zm0 32h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16Z" /></Svg>;
export default Equalizer;