/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-user-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-user-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.73 221.94A8 8 0 0 1 224 232h-64a8 8 0 0 1-7.73-10 40 40 0 0 1 17.11-23.33 32 32 0 1 1 45.24 0 40 40 0 0 1 17.11 23.27ZM232 88v32a8 8 0 0 1-16 0V88H40v112h80.56a8 8 0 0 1 0 16H39.38A15.4 15.4 0 0 1 24 200.62V56a16 16 0 0 1 16-16h52.69A15.86 15.86 0 0 1 104 44.69L131.31 72H216a16 16 0 0 1 16 16ZM108.69 72l-16-16H40v16Z" /></Svg>;
export default FolderUser;