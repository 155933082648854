/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Receipt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="receipt-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-receipt-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M68 100a12 12 0 0 1 12-12h96a12 12 0 0 1 0 24H80a12 12 0 0 1-12-12Zm12 52h96a12 12 0 0 0 0-24H80a12 12 0 0 0 0 24Zm156-96v152a12 12 0 0 1-17.37 10.73L192 205.42l-26.63 13.31a12 12 0 0 1-10.74 0L128 205.42l-26.63 13.31a12 12 0 0 1-10.74 0L64 205.42l-26.63 13.31A12 12 0 0 1 20 208V56a20 20 0 0 1 20-20h176a20 20 0 0 1 20 20Zm-24 4H44v128.58l14.63-7.31a12 12 0 0 1 10.74 0L96 194.58l26.63-13.31a12 12 0 0 1 10.74 0L160 194.58l26.63-13.31a12 12 0 0 1 10.74 0l14.63 7.31Z" /></Svg>;
export default Receipt;