/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSki = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-ski-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-ski-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 86a26 26 0 1 0-26-26 26 26 0 0 0 26 26Zm0-40a14 14 0 1 1-14 14 14 14 0 0 1 14-14ZM38.32 85.76l111.47 32.51 6 6A6 6 0 0 0 160 126h40a6 6 0 0 0 0-12h-37.51l-30.25-30.25a6 6 0 0 0-8.48 0l-11.17 11.17-70.91-20.68a6 6 0 1 0-3.36 11.52ZM128 96.48l4.14 4.14-6.41-1.87Zm107.33 106.1a67.79 67.79 0 0 1-56.7 8.69L22.32 165.75a6 6 0 1 1 3.36-11.52l77.13 22.46L132.55 147l-46.2-13.2a6 6 0 0 1 3.3-11.54l56 16a6 6 0 0 1 2.59 10L116 180.52l66 19.23a55.79 55.79 0 0 0 46.68-7.15 6 6 0 1 1 6.66 10Z" /></Svg>;
export default PersonSimpleSki;