/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SortAscending = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sort-ascending-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sort-ascending-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM72 72h96a8 8 0 0 1 0 16H72a8 8 0 0 1 0-16Zm40 112H72a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm8-48H72a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Zm77.66 29.66-24 24a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L160 164.69V120a8 8 0 0 1 16 0v44.69l10.34-10.35a8 8 0 0 1 11.32 11.32Z" /></Svg>;
export default SortAscending;