/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Microphone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 180a52.06 52.06 0 0 0 52-52V64a52 52 0 0 0-104 0v64a52.06 52.06 0 0 0 52 52ZM100 64a28 28 0 0 1 56 0v64a28 28 0 0 1-56 0Zm40 155.22V240a12 12 0 0 1-24 0v-20.78A92.14 92.14 0 0 1 36 128a12 12 0 0 1 24 0 68 68 0 0 0 136 0 12 12 0 0 1 24 0 92.14 92.14 0 0 1-80 91.22Z" /></Svg>;
export default Microphone;