/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FunnelSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="funnel-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-funnel-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M198 136a6 6 0 0 1-6 6H64a6 6 0 0 1 0-12h128a6 6 0 0 1 6 6Zm34-54H24a6 6 0 0 0 0 12h208a6 6 0 0 0 0-12Zm-80 96h-48a6 6 0 0 0 0 12h48a6 6 0 0 0 0-12Z" /></Svg>;
export default FunnelSimple;