/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberZero = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-zero__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-zero'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M183.25 63.2C170.25 42.79 151.15 32 128 32S85.75 42.79 72.75 63.2C62 80.18 56 103.19 56 128s6 47.82 16.75 64.8c13 20.41 32.1 31.2 55.25 31.2s42.25-10.79 55.25-31.2c10.8-17 16.75-40 16.75-64.8s-5.95-47.82-16.75-64.8ZM128 208c-38.68 0-56-40.18-56-80s17.32-80 56-80 56 40.18 56 80-17.32 80-56 80Z" /></Svg>;
export default NumberZero;