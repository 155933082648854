/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneDisconnect = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-disconnect-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-disconnect-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.19 91.55c-55.39-55.4-149-55.4-204.38 0-19.25 19.27-21.11 47.67-4.52 69.06a14 14 0 0 0 16.1 4.39l49-17.38.22-.09a13.93 13.93 0 0 0 8.53-10.25l5.9-29.52a1.93 1.93 0 0 1 1.21-1.47 78.53 78.53 0 0 1 51.18-.11 1.93 1.93 0 0 1 1.22 1.45l6.21 29.75a14 14 0 0 0 8.5 10.14l.23.08 49 17.4a14 14 0 0 0 16.1-4.42c16.61-21.36 14.75-49.76-4.5-69.03Zm-5 61.71a2 2 0 0 1-2.29.58l-.22-.09-49-17.38a2 2 0 0 1-1.08-1.37l-6.22-29.74a14 14 0 0 0-9.06-10.35 90.53 90.53 0 0 0-59 .13 14 14 0 0 0-9 10.45L83.37 135a2 2 0 0 1-1.15 1.44l-48.94 17.31-.22.09a2 2 0 0 1-2.29-.58c-13-16.74-11.56-38.12 3.53-53.22 25.39-25.4 59.55-38.1 93.7-38.1s68.31 12.7 93.7 38.1c15.09 15.1 16.51 36.48 3.53 53.22ZM222 200a6 6 0 0 1-6 6H40a6 6 0 0 1 0-12h176a6 6 0 0 1 6 6Z" /></Svg>;
export default PhoneDisconnect;