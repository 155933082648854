/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Van = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="van-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-van-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252.55 108.09 207 55a14 14 0 0 0-10.74-5H32a14 14 0 0 0-14 14v112a14 14 0 0 0 14 14h18.6a30 30 0 0 0 58.8 0h53.2a30 30 0 0 0 58.8 0H240a14 14 0 0 0 14-14v-64a6 6 0 0 0-1.45-3.91Zm-54.7-45.32L234.94 106H174V62h22.26a2 2 0 0 1 1.59.77ZM102 106V62h60v44ZM32 62h58v44H30V64a2 2 0 0 1 2-2Zm48 140a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm112 0a18 18 0 1 1 18-18 18 18 0 0 1-18 18Zm48-24h-18.6a30 30 0 0 0-58.8 0h-53.2a30 30 0 0 0-58.8 0H32a2 2 0 0 1-2-2v-58h212v58a2 2 0 0 1-2 2Z" /></Svg>;
export default Van;