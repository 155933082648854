/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Syringe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="syringe-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-syringe-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.66 77.6a8 8 0 0 1-11.32 0L208 59.25 179.3 88l34.35 34.35a8 8 0 0 1-11.32 11.32l-6.33-6.4-84 84a16 16 0 0 1-11.35 4.73H51.26L29.6 237.66a8 8 0 0 1-11.72-.43 8.21 8.21 0 0 1 .61-11.1l21.45-21.46v-49.39A16 16 0 0 1 44.63 144l15.18-15.18a4 4 0 0 1 5.66 0l28.83 28.81a8 8 0 1 0 11.32-11.32l-28.84-28.84a4 4 0 0 1 0-5.66l11-11a4 4 0 0 1 5.66 0l28.84 28.84a8 8 0 1 0 11.32-11.32l-28.81-28.87a4 4 0 0 1 0-5.66l23.87-23.86-6.35-6.35a8 8 0 0 1 .18-11.49 8.22 8.22 0 0 1 11.37.41L168 76.63l28.69-28.7-18.36-18.35a8 8 0 0 1 .17-11.49 8.23 8.23 0 0 1 11.38.41l47.78 47.78a8 8 0 0 1 0 11.32Z" /></Svg>;
export default Syringe;