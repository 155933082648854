/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextColumns = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-columns-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-columns-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 64a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h68a12 12 0 0 1 12 12Zm-12 28H40a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Zm0 40H40a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Zm0 40H40a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Zm40-96h68a12 12 0 0 0 0-24h-68a12 12 0 0 0 0 24Zm68 16h-68a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Zm0 40h-68a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Zm0 40h-68a12 12 0 0 0 0 24h68a12 12 0 0 0 0-24Z" /></Svg>;
export default TextColumns;