/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandEye = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-eye-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-eye-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 168a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm76-52v36a84 84 0 0 1-168 0V76a24 24 0 0 1 40-17.87V44a24 24 0 0 1 47.93-1.8A24 24 0 0 1 172 60v38.13A24 24 0 0 1 212 116Zm-8 0a16 16 0 0 0-32 0v4a4 4 0 0 1-8 0V60a16 16 0 0 0-32 0v44a4 4 0 0 1-8 0V44a16 16 0 0 0-32 0v68a4 4 0 0 1-8 0V76a16 16 0 0 0-32 0v76a76 76 0 0 0 152 0Zm-24.42 50.21a4 4 0 0 1 0 3.58c-.7 1.4-17.5 34.21-51.58 34.21s-50.88-32.81-51.58-34.21a4 4 0 0 1 0-3.58c.7-1.4 17.5-34.21 51.58-34.21s50.88 32.81 51.58 34.21Zm-8.16 1.8c-3.83-6.43-18.55-28-43.42-28s-39.6 21.55-43.42 28c3.84 6.44 18.55 28 43.42 28s39.6-21.56 43.42-28.01Z" /></Svg>;
export default HandEye;