/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberFive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-five-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-five-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 160a52 52 0 0 1-86.67 38.76 4 4 0 1 1 5.34-6 44 44 0 1 0 .84-66.33 4 4 0 0 1-6.51-3.79l15.09-75.42A4 4 0 0 1 104 44h64a4 4 0 0 1 0 8h-60.72l-12.51 62.53A52 52 0 0 1 172 160Z" /></Svg>;
export default NumberFive;