/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBrushHousehold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-brush-household-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-brush-household-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.23 26.77a30.05 30.05 0 0 0-42.43 0l-.32.34-54.81 64.05-12.11-12.1a22 22 0 0 0-31.11 0l-76.69 76.69a6 6 0 0 0 0 8.49l80 80a6 6 0 0 0 8.49 0l76.69-76.69a22 22 0 0 0 0-31.11l-12.1-12.11 64-54.81.34-.32a30.05 30.05 0 0 0 .05-42.43ZM96 231.51 76.49 212l23.76-23.76a6 6 0 0 0-8.49-8.49L68 203.51 52.49 188l23.76-23.76a6 6 0 0 0-8.49-8.49L44 179.51 24.49 160 72 112.48 143.52 184ZM220.89 60.56l-68.78 58.87a6 6 0 0 0-2.1 4.33 6 6 0 0 0 1.76 4.47l16.68 16.69a10 10 0 0 1 0 14.15L152 175.51 80.49 104l16.44-16.45a10 10 0 0 1 14.15 0l16.69 16.68a6 6 0 0 0 8.8-.34l58.87-68.78a18 18 0 0 1 25.45 25.45Z" /></Svg>;
export default PaintBrushHousehold;