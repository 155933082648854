/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSki = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-ski-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-ski-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 92a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm0-40a8 8 0 1 1-8 8 8 8 0 0 1 8-8ZM28.48 80.64a12 12 0 0 1 14.88-8.16l67.5 19.68 8.66-8.65a12 12 0 0 1 17 0L165 112h35a12 12 0 0 1 0 24h-40a12 12 0 0 1-8.48-3.51l-4.89-4.89-110-32.07a12 12 0 0 1-8.15-14.89Zm210.17 130.93A73.77 73.77 0 0 1 177 221L20.65 175.51a12 12 0 1 1 6.71-23l79.92 23.27 13.81-13.81-36.39-10.44a12 12 0 1 1 6.6-23.07l56 16a12 12 0 0 1 5.19 20l-18.93 18.94 50.1 14.6a49.81 49.81 0 0 0 41.68-6.39 12 12 0 0 1 13.31 20Z" /></Svg>;
export default PersonSimpleSki;