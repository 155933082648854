/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandbagSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="handbag-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-handbag-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M223.92 208H32.08a8 8 0 0 1-8-8.93l14.25-120a8.06 8.06 0 0 1 8-7.07h163.34a8.06 8.06 0 0 1 8 7.07l14.25 120a8 8 0 0 1-8 8.93Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m239.89 198.12-14.26-120a16 16 0 0 0-16-14.12H176a48 48 0 0 0-96 0H46.33a16 16 0 0 0-16 14.12l-14.26 120A16 16 0 0 0 20 210.6a16.13 16.13 0 0 0 12 5.4h191.92a16.13 16.13 0 0 0 12.08-5.4 16 16 0 0 0 3.89-12.48ZM128 32a32 32 0 0 1 32 32H96a32 32 0 0 1 32-32ZM32 200 46.33 80h163.42l14.17 120Z" /></Svg>;
export default HandbagSimple;