/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CastleTurret = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="castle-turret-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-castle-turret-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 212h-12v-95l10.14-10.14A19.86 19.86 0 0 0 220 92.69V48a20 20 0 0 0-20-20H56a20 20 0 0 0-20 20v44.69a19.86 19.86 0 0 0 5.86 14.14L52 117v95H40a12 12 0 0 0 0 24h176a12 12 0 0 0 0-24ZM72.49 103.51 60 91V52h28v24a12 12 0 0 0 24 0V52h32v24a12 12 0 0 0 24 0V52h28v39l-12.49 12.48A12 12 0 0 0 180 112v100h-16v-44a36 36 0 0 0-72 0v44H76V112a12 12 0 0 0-3.51-8.49ZM140 212h-24v-44a12 12 0 0 1 24 0Z" /></Svg>;
export default CastleTurret;