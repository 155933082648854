/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Handshake = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="handshake-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-handshake-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m254.3 107.91-25.52-51.06a16 16 0 0 0-21.47-7.15l-24.87 12.43-52.39-13.86a8.14 8.14 0 0 0-4.1 0L73.56 62.13 48.69 49.7a16 16 0 0 0-21.47 7.15L1.7 107.9a16 16 0 0 0 7.15 21.47l27 13.51 55.49 39.63a8.06 8.06 0 0 0 2.71 1.25l64 16a8 8 0 0 0 7.6-2.1l40-40 15.08-15.08 26.42-13.21a16 16 0 0 0 7.15-21.46Zm-54.89 33.37L165 113.72a8 8 0 0 0-10.68.61C136.51 132.27 116.66 130 104 122l43.24-42h31.81l27.21 54.41Zm-41.87 41.86-58.12-14.53-49.2-35.14 28-56L128 64.28l9.8 2.59-45 43.68-.08.09a16 16 0 0 0 2.72 24.81c20.56 13.13 45.37 11 64.91-5L188 152.66Zm-25.72 34.8a8 8 0 0 1-7.75 6.06 8.13 8.13 0 0 1-1.95-.24l-41.71-10.43a7.89 7.89 0 0 1-2.71-1.25l-26.35-18.82a8 8 0 0 1 9.3-13l25.11 17.94L126 208.24a8 8 0 0 1 5.82 9.7Z" /></Svg>;
export default Handshake;