/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Parallelogram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="parallelogram-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-parallelogram-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M242.07 49.48A12 12 0 0 0 232 44H88.81a12 12 0 0 0-11 7.08l-64.8 144A12 12 0 0 0 24 212h143.19a12 12 0 0 0 10.95-7.08l64.8-144a12 12 0 0 0-.87-11.44Zm-6.43 8.16-64.8 144a4 4 0 0 1-3.65 2.36H24a4 4 0 0 1-3.65-5.64l64.8-144A4 4 0 0 1 88.81 52H232a4 4 0 0 1 3.65 5.64Z" /></Svg>;
export default Parallelogram;