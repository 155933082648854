/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-star-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-star-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28h-20v-4a12 12 0 0 0-24 0v4H92v-4a12 12 0 0 0-24 0v4H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 176H52V52h16a12 12 0 0 0 24 0h72a12 12 0 0 0 24 0h16Zm-27.08-94.35-27.42-2.12L139 83.25a12 12 0 0 0-22 0l-10.5 24.28-27.42 2.12a12 12 0 0 0-6.72 21.22l20.58 17-6.25 25.26a12 12 0 0 0 17.73 13.22L128 172.46l23.58 13.88a12 12 0 0 0 17.73-13.22l-6.25-25.26 20.58-17a12 12 0 0 0-6.72-21.22Zm-35 24.51a12 12 0 0 0-4 12.13l1.21 4.89-5.07-3a12.06 12.06 0 0 0-12.18 0l-5.07 3 1.21-4.89a12 12 0 0 0-4-12.13l-3.47-2.87 5-.39a12 12 0 0 0 10.09-7.21l2.33-5.4 2.33 5.4a12 12 0 0 0 10.09 7.21l5 .39Z" /></Svg>;
export default CalendarStar;