/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextB = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-b-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-b-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 160a40 40 0 0 1-40 40H80V48h68a36 36 0 0 1 0 72h12a40 40 0 0 1 40 40Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M178.48 115.7A44 44 0 0 0 148 40H80a8 8 0 0 0-8 8v152a8 8 0 0 0 8 8h80a48 48 0 0 0 18.48-92.3ZM88 56h60a28 28 0 0 1 0 56H88Zm72 136H88v-64h72a32 32 0 0 1 0 64Z" /></Svg>;
export default TextB;