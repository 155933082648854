/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Terminal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="terminal-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-terminal-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 128a4 4 0 0 1-1.34 3l-72 64a4 4 0 1 1-5.32-6L106 128 37.34 67a4 4 0 0 1 5.32-6l72 64a4 4 0 0 1 1.34 3Zm100 60h-96a4 4 0 0 0 0 8h96a4 4 0 0 0 0-8Z" /></Svg>;
export default Terminal;