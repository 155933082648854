/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandFist = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-fist-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-fist-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 82h-18V64a30 30 0 0 0-54-18 30 30 0 0 0-54 18 30 30 0 0 0-48 24v40a102 102 0 0 0 204 0v-16a30 30 0 0 0-30-30Zm-48-36a18 18 0 0 1 18 18v18h-34a14.71 14.71 0 0 0-2 .16V64a18 18 0 0 1 18-18ZM86 64a18 18 0 0 1 36 0v40a18 18 0 0 1-36 0ZM38 88a18 18 0 0 1 36 0v16a18 18 0 0 1-36 0Zm180 40a90 90 0 0 1-180 0 30 30 0 0 0 42-6 30 30 0 0 0 45.12 3.3A30.18 30.18 0 0 0 140 139.51 45.92 45.92 0 0 0 122 176a6 6 0 0 0 12 0 34 34 0 0 1 34-34 6 6 0 0 0 0-12h-16a18 18 0 0 1-18-18V96a2 2 0 0 1 2-2h64a18 18 0 0 1 18 18Z" /></Svg>;
export default HandFist;