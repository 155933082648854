/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pentagram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pentagram__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pentagram'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.18 91.05A15.75 15.75 0 0 0 224 80h-61l-19.77-60.74a15.93 15.93 0 0 0-30.45-.05L93.06 80H32a16 16 0 0 0-9.37 29l49.46 35.58L53.15 203A15.75 15.75 0 0 0 59 220.88a15.74 15.74 0 0 0 18.77 0L128 184.75l50.23 36.13A16 16 0 0 0 202.85 203l-19-58.46 49.49-35.61a15.74 15.74 0 0 0 5.84-17.88ZM128 24.15 146.12 80h-36.24ZM32 96h55.87L77.3 128.56Zm36.34 112 17.39-53.59 28.54 20.54Zm22.57-69.57L104.69 96h46.62l13.75 42.38L128 165ZM187.6 208l-45.9-33 28.54-20.54Zm-8.93-79.38L168.13 96H224Z" /></Svg>;
export default Pentagram;