/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartBarHorizontal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-bar-horizontal-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-bar-horizontal-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 92h-36V56a12 12 0 0 0-12-12H60v-4a12 12 0 0 0-24 0v176a12 12 0 0 0 24 0v-4h84a12 12 0 0 0 12-12v-36h68a12 12 0 0 0 12-12v-48a12 12 0 0 0-12-12Zm-60-24v24H60V68Zm-32 120H60v-24h72Zm80-48H60v-24h152Z" /></Svg>;
export default ChartBarHorizontal;