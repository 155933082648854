/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-half-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-half-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168.74 52.86a4 4 0 0 0-1.48-7.86c-17.17 3.21-31.07 12.57-39.26 26.12C118 54.21 99.46 44 78 44a58.07 58.07 0 0 0-58 58c0 28.59 18 58.47 53.4 88.79a333.81 333.81 0 0 0 52.7 36.73 4 4 0 0 0 1.9.48 4 4 0 0 0 1.89-.49 328.8 328.8 0 0 0 40.54-26.87 4 4 0 1 0-4.88-6.33A343.7 343.7 0 0 1 132 217.09V80.85c6.24-14.59 19.58-24.78 36.74-27.99ZM124 217.08c-21.73-13-96-61.16-96-115.08a50.06 50.06 0 0 1 50-50c20.83 0 38.39 11 46 28.8ZM232.11 100h-.44a4 4 0 0 1-4-3.56 49.88 49.88 0 0 0-21.92-36 4 4 0 0 1 4.44-6.65 57.88 57.88 0 0 1 25.43 41.79 4 4 0 0 1-3.51 4.42Zm-5.23 37.81c-5.5 11-13.55 22.32-23.94 33.56a4 4 0 0 1-5.88-5.43c9.88-10.68 17.5-21.35 22.66-31.71a4 4 0 1 1 7.16 3.58Z" /></Svg>;
export default HeartHalf;