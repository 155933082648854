/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WaveSawtooth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wave-sawtooth-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wave-sawtooth-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m238.29 138.22-104 64A12 12 0 0 1 116 192V85.47l-85.71 52.75a12 12 0 0 1-12.58-20.44l104-64A12 12 0 0 1 140 64v106.53l85.71-52.75a12 12 0 1 1 12.58 20.44Z" /></Svg>;
export default WaveSawtooth;