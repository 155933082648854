/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Jeep = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="jeep-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-jeep-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 90h-11.15l-9.63-44.93A14.06 14.06 0 0 0 205.53 34H50.47a14.06 14.06 0 0 0-13.69 11.07L27.15 90H16a6 6 0 0 0 0 12h10v98a14 14 0 0 0 14 14h24a14 14 0 0 0 14-14v-26h100v26a14 14 0 0 0 14 14h24a14 14 0 0 0 14-14v-98h10a6 6 0 0 0 0-12ZM48.51 47.58a2 2 0 0 1 2-1.58h155.02a2 2 0 0 1 2 1.58L216.58 90H39.42ZM66 200a2 2 0 0 1-2 2H40a2 2 0 0 1-2-2v-26h28Zm150 2h-24a2 2 0 0 1-2-2v-26h28v26a2 2 0 0 1-2 2Zm2-40h-68v-34a6 6 0 0 0-12 0v34h-20v-34a6 6 0 0 0-12 0v34H38v-60h180ZM58 132a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm120 0a10 10 0 1 1 10 10 10 10 0 0 1-10-10Z" /></Svg>;
export default Jeep;