/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hammer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hammer-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hammer-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m249.92 113.4-67.45-67.91a94.12 94.12 0 0 0-133 .06L35.68 59.78a6 6 0 0 0 8.63 8.35L58 54a82 82 0 0 1 20.55-15l49 49-97.46 97.4a14 14 0 0 0 0 19.81l20.69 20.69a14 14 0 0 0 19.8 0L168 128.46l33.42 33.42a14 14 0 0 0 19.8 0l28.68-28.69a14 14 0 0 0 .02-19.79ZM62.1 217.41a2 2 0 0 1-2.83 0l-20.69-20.69a2 2 0 0 1 0-2.83L108 124.46 131.51 148Zm101.66-101.68L140 139.49 116.48 116l23.76-23.76a6 6 0 0 0 0-8.49L90.54 34A82.07 82.07 0 0 1 174 54l45.62 45.93L188 131.49l-15.76-15.76a6 6 0 0 0-8.48 0Zm77.66 9-28.69 28.7a2 2 0 0 1-2.83 0L196.49 140l31.56-31.57 13.37 13.46a2 2 0 0 1 0 2.81Z" /></Svg>;
export default Hammer;