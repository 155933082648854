/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneTransfer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-transfer-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-transfer-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M138 72a6 6 0 0 1 6-6h57.51l-21.75-21.76a6 6 0 0 1 8.48-8.48l32 32a6 6 0 0 1 0 8.48l-32 32a6 6 0 0 1-8.48-8.48L201.51 78H144a6 6 0 0 1-6-6Zm83.89 110.83A54.25 54.25 0 0 1 168 230C89.7 230 26 166.3 26 88a54.25 54.25 0 0 1 47.17-53.89 14 14 0 0 1 14.56 8.39l21.1 47.11a14 14 0 0 1-1.12 13.27 6 6 0 0 1-.42.57l-21.07 25.06a1.89 1.89 0 0 0 0 1.67c7.66 15.69 24.1 32 40 39.65a1.88 1.88 0 0 0 1.68-.06l24.69-21a4.81 4.81 0 0 1 .56-.42 14 14 0 0 1 13.28-1.22l47.24 21.17a14 14 0 0 1 8.22 14.53ZM210 181.32a2 2 0 0 0-1.21-2l-47.25-21.17a2 2 0 0 0-1.61.1l-24.67 21c-.18.15-.37.29-.56.42a14 14 0 0 1-13.77 1c-18.36-8.87-36.66-27-45.53-45.19a14 14 0 0 1 .91-13.73 4.73 4.73 0 0 1 .43-.57l21.05-25.09a2 2 0 0 0 0-1.67L76.74 47.31A2 2 0 0 0 74.9 46h-.23A42.24 42.24 0 0 0 38 88c0 71.68 58.32 130 130 130a42.24 42.24 0 0 0 42-36.68Z" /></Svg>;
export default PhoneTransfer;