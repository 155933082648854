/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Baseball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baseball-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baseball-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm67.31 161.67c-1-1.12-2-2.26-2.9-3.42a6 6 0 0 0-9.36 7.5c1.19 1.49 2.43 2.94 3.71 4.37a89.84 89.84 0 0 1-117.52 0c1.28-1.43 2.52-2.88 3.71-4.37a6 6 0 0 0-9.36-7.5c-.93 1.16-1.91 2.3-2.9 3.42a89.8 89.8 0 0 1 0-119.34c1 1.12 2 2.26 2.9 3.42A6 6 0 0 0 68.28 74 6 6 0 0 0 73 64.25a101.97 101.97 0 0 0-3.71-4.37 89.84 89.84 0 0 1 117.52 0 101.97 101.97 0 0 0-3.71 4.37 6 6 0 1 0 9.36 7.5c.93-1.16 1.91-2.3 2.9-3.42a89.8 89.8 0 0 1 0 119.34ZM93.92 145a101.43 101.43 0 0 1-4.4 17 6 6 0 1 1-11.31-4 89.91 89.91 0 0 0 3.87-15 6 6 0 1 1 11.84 2Zm0-34a6 6 0 0 1-4.92 6.92 6.74 6.74 0 0 1-1 .08 6 6 0 0 1-5.91-5 89.91 89.91 0 0 0-3.87-15 6 6 0 1 1 11.31-4 101.43 101.43 0 0 1 4.39 17Zm83.87 47a6 6 0 1 1-11.31 4 101.43 101.43 0 0 1-4.4-17 6 6 0 1 1 11.84-2 89.91 89.91 0 0 0 3.87 15Zm0-60a89.91 89.91 0 0 0-3.87 15 6 6 0 0 1-5.91 5 6.74 6.74 0 0 1-1-.08 6 6 0 0 1-4.93-6.91 101.43 101.43 0 0 1 4.4-17 6 6 0 1 1 11.31 4Z" /></Svg>;
export default Baseball;