/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-minus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-minus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 56a4 4 0 0 1 4-4h48a4 4 0 0 1 0 8h-48a4 4 0 0 1-4-4Zm50.31 38.67A100 100 0 1 1 128 28a100.69 100.69 0 0 1 16.66 1.38 4 4 0 0 1-1.32 7.89A93.4 93.4 0 0 0 128 36a92 92 0 0 0-65.17 156.87 75.61 75.61 0 0 1 44.51-34 44 44 0 1 1 41.32 0 75.61 75.61 0 0 1 44.51 34 92 92 0 0 0 21.6-95.54 4 4 0 1 1 7.54-2.66ZM128 156a36 36 0 1 0-36-36 36 36 0 0 0 36 36Zm0 64a91.61 91.61 0 0 0 59.14-21.58 68 68 0 0 0-118.27 0A91.56 91.56 0 0 0 128 220Z" /></Svg>;
export default UserCircleMinus;