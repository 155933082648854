/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyXEyes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-x-eyes-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-x-eyes-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90Zm60.24-117.76L176.49 112l11.75 11.76a6 6 0 1 1-8.48 8.48L168 120.49l-11.76 11.75a6 6 0 0 1-8.48-8.48L159.51 112l-11.75-11.76a6 6 0 0 1 8.48-8.48L168 103.51l11.76-11.75a6 6 0 0 1 8.48 8.48Zm-80 0L96.49 112l11.75 11.76a6 6 0 1 1-8.48 8.48L88 120.49l-11.76 11.75a6 6 0 0 1-8.48-8.48L79.51 112l-11.75-11.76a6 6 0 0 1 8.48-8.48L88 103.51l11.76-11.75a6 6 0 0 1 8.48 8.48ZM138 180a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default SmileyXEyes;