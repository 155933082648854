/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pants = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pants-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pants-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.85 213.52-22-176A20 20 0 0 0 186 20H70a20 20 0 0 0-19.85 17.52l-22 176A20 20 0 0 0 48 236h40.69a19.94 19.94 0 0 0 19.38-15L128 144l19.91 76.94a20 20 0 0 0 19.4 15.06H208a20 20 0 0 0 19.85-22.48ZM182.47 44l2 16H71.53l2-16ZM85.6 212H52.53l11.2-89.6A44.11 44.11 0 0 0 95.81 84H116v10.47Zm84.81 0L140 94.47V84h20.19a44.09 44.09 0 0 0 32.08 38.39l11.2 89.61Z" /></Svg>;
export default Pants;