/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingCart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-cart-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-cart-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m224 64-12.16 66.86A16 16 0 0 1 196.1 144H70.55L56 64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M230.14 58.87A8 8 0 0 0 224 56H62.68L56.6 22.57A8 8 0 0 0 48.73 16H24a8 8 0 0 0 0 16h18l25.56 140.29a24 24 0 0 0 5.33 11.27 28 28 0 1 0 44.4 8.44h45.42a27.75 27.75 0 0 0-2.71 12 28 28 0 1 0 28-28H91.17a8 8 0 0 1-7.87-6.57L80.13 152h116a24 24 0 0 0 23.61-19.71l12.16-66.86a8 8 0 0 0-1.76-6.56ZM104 204a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm96 0a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm4-74.57a8 8 0 0 1-7.9 6.57H77.22L65.59 72h148.82Z" /></Svg>;
export default ShoppingCart;