/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowLineRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-line-right-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-line-right-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 40v176a8 8 0 0 1-16 0V40a8 8 0 0 1 16 0ZM117.66 50.34A8 8 0 0 0 104 56v64H32a8 8 0 0 0 0 16h72v64a8 8 0 0 0 13.66 5.66l72-72a8 8 0 0 0 0-11.32Z" /></Svg>;
export default ArrowLineRight;