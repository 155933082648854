/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SteamLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="steam-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-steam-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm-12.88 154.62 61.21-49.93a32 32 0 1 0-45-45l-45.85 56.14L40.68 99a91.6 91.6 0 1 1-2.46 8.86l42.23 42.23a24 24 0 0 0 34.67 32.51Zm8.91-73.4A32 32 0 0 0 154.78 140L124 165.09V164a24 24 0 0 0-24-24h-1.09ZM156 132a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm-56 16a16 16 0 1 1-16 16 16 16 0 0 1 16-16Z" /></Svg>;
export default SteamLogo;