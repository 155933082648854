/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlugsConnected = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plugs-connected-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plugs-connected-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.24 19.76a6 6 0 0 0-8.48 0l-53.82 53.81-6.79-6.78a30 30 0 0 0-42.42 0L100 91.51l-7.76-7.75a6 6 0 0 0-8.48 8.48l7.75 7.76-24.72 24.73a30 30 0 0 0 0 42.42l6.78 6.79-53.81 53.82a6 6 0 1 0 8.48 8.48l53.82-53.81 6.79 6.78a30 30 0 0 0 42.42 0L156 164.49l7.76 7.75a6 6 0 0 0 8.48-8.48l-7.75-7.76 24.72-24.73a30 30 0 0 0 0-42.42l-6.78-6.79 53.81-53.82a6 6 0 0 0 0-8.48Zm-113.45 161a18 18 0 0 1-25.46 0l-22.06-22.09a18 18 0 0 1 0-25.46L100 108.49 147.51 156Zm57.94-57.94L156 147.51 108.49 100l24.72-24.73a18 18 0 0 1 25.46 0l22.06 22.06a18 18 0 0 1 0 25.46Zm-90.3-88.59a6 6 0 0 1 11.14-4.46l8 20a6 6 0 1 1-11.14 4.46Zm-64 59.54a6 6 0 0 1 7.8-3.34l20 8a6 6 0 1 1-4.46 11.14l-20-8a6 6 0 0 1-3.34-7.8Zm203.14 68.46a6 6 0 0 1-7.8 3.34l-20-8a6 6 0 0 1 4.46-11.14l20 8a6 6 0 0 1 3.34 7.8Zm-64 59.54a6 6 0 1 1-11.14 4.46l-8-20a6 6 0 0 1 11.14-4.46Z" /></Svg>;
export default PlugsConnected;