/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SprayBottle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spray-bottle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spray-bottle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 78a6 6 0 0 0 6-6 54.06 54.06 0 0 0-54-54H80a14 14 0 0 0-14 14v48a26 26 0 0 1-26 26 6 6 0 0 0 0 12 38 38 0 0 0 38-38v-2h36v26.62a25.87 25.87 0 0 1-9.76 20.3l-16 12.79A37.81 37.81 0 0 0 74 167.38V224a14 14 0 0 0 14 14h104a14 14 0 0 0 14-14v-12.53A268.92 268.92 0 0 0 170.57 78ZM78 32a2 2 0 0 1 2-2h72a42.06 42.06 0 0 1 41.57 36H78Zm116 179.47V224a2 2 0 0 1-2 2H88a2 2 0 0 1-2-2v-56.62a25.87 25.87 0 0 1 9.76-20.3l16-12.79A37.81 37.81 0 0 0 126 104.62V78h30.64A256.84 256.84 0 0 1 194 211.47Z" /></Svg>;
export default SprayBottle;