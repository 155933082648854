/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HourglassSimpleLow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hourglass-simple-low-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hourglass-simple-low-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m209.8 198-73.12-70 73.12-70 .09-.09A14 14 0 0 0 200 34H56a14 14 0 0 0-9.9 23.9l.09.09 73.12 70L46.2 198l-.09.09A14 14 0 0 0 56 222h144a14 14 0 0 0 9.9-23.9ZM54.56 49.38A2 2 0 0 1 56 46h144a2 2 0 0 1 1.45 3.38L128 119.69ZM128 136.31 163.19 170H92.81Zm73.84 72.46A1.91 1.91 0 0 1 200 210H56a2 2 0 0 1-1.45-3.38L80.28 182h95.44l25.72 24.62a1.91 1.91 0 0 1 .4 2.15Z" /></Svg>;
export default HourglassSimpleLow;