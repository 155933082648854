/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartStraightBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-straight-break-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-straight-break-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.62 58.38a56.06 56.06 0 0 0-79.16 0L128 72.24l-14.44-13.9a56 56 0 0 0-79.2 79.21l89.37 90.66a6 6 0 0 0 8.55 0l89.33-90.63a56.06 56.06 0 0 0 .01-79.2Zm-8.51 70.75L128 215.45 42.89 129.1a44 44 0 0 1 62.23-62.24l.08.08 14.16 13.64-11.51 11.08a6 6 0 0 0-.08 8.56L135.53 128l-11.76 11.76a6 6 0 1 0 8.49 8.48l16-16a6 6 0 0 0 0-8.49l-27.68-27.69 30.24-29.12a.3.3 0 0 0 .08-.08 44 44 0 1 1 62.21 62.27Z" /></Svg>;
export default HeartStraightBreak;