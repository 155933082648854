/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tractor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tractor-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tractor-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238 166.12V134a13.91 13.91 0 0 0-10-13.41h-.15L190 110.24V72a6 6 0 0 0-12 0v35l-28-7.58V54h10a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12h10v36H40a6 6 0 0 0 0 12h28a70.08 70.08 0 0 1 70 70v12a6 6 0 0 0 6 6h34.06A34 34 0 1 0 238 166.12ZM68 90h-6V54h76v75.34A82 82 0 0 0 68 90Zm82 82v-60.16l74.63 20.21A2 2 0 0 1 226 134v23a34 34 0 0 0-46.5 21H150Zm62 38a22 22 0 1 1 22-22 22 22 0 0 1-22 22ZM68 122a50 50 0 1 0 50 50 50.06 50.06 0 0 0-50-50Zm0 88a38 38 0 1 1 38-38 38 38 0 0 1-38 38Zm10-38a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default Tractor;