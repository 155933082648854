/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PinterestLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pinterest-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pinterest-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 112c0 21.53-7.5 41.18-21.12 55.34C186.26 180.47 169.17 188 152 188c-21.35 0-33.52-8.76-39.76-15.57l-12.35 52.49A4 4 0 0 1 96 228a4.2 4.2 0 0 1-.92-.11 4 4 0 0 1-3-4.81l32-136a4 4 0 0 1 7.78 1.84l-17.28 73.45C117 166.42 127 180 152 180c29.49 0 60-25.44 60-68a76 76 0 1 0-141.83 38 4 4 0 0 1-6.93 4A84 84 0 1 1 220 112Z" /></Svg>;
export default PinterestLogo;