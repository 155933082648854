/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bulldozer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bulldozer-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bulldozer-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 204h-8a12 12 0 0 1-12-12v-32a12 12 0 0 1 12-12h8a4 4 0 0 0 0-8h-8a20 20 0 0 0-20 20v12h-24.23a36.06 36.06 0 0 0-33.06-31.89l-37-88.73A12 12 0 0 0 114.67 44H24a12 12 0 0 0-12 12v97.41A36 36 0 0 0 40 212h120a36 36 0 0 0 35.77-32H220v12a20 20 0 0 0 20 20h8a4 4 0 0 0 0-8ZM118.36 54.46 154 140H60V52h54.67a4 4 0 0 1 3.69 2.46ZM24 52h28v88H40a35.76 35.76 0 0 0-20 6.08V56a4 4 0 0 1 4-4Zm136 152H40a28 28 0 0 1 0-56h120a28 28 0 0 1 0 56Zm4-28a4 4 0 0 1-4 4H40a4 4 0 0 1 0-8h120a4 4 0 0 1 4 4Z" /></Svg>;
export default Bulldozer;