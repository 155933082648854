/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Password = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="password__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-password'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 56v144a8 8 0 0 1-16 0V56a8 8 0 0 1 16 0Zm92 54.5-20 6.5V96a8 8 0 0 0-16 0v21l-20-6.5a8 8 0 0 0-5 15.22l20 6.49-12.34 17a8 8 0 1 0 12.94 9.4l12.34-17 12.34 17a8 8 0 1 0 12.94-9.4l-12.34-17 20-6.49A8 8 0 0 0 140 110.5Zm106 5.14a8 8 0 0 0-10-5.14l-20 6.5V96a8 8 0 0 0-16 0v21l-20-6.49a8 8 0 0 0-4.95 15.22l20 6.49-12.34 17a8 8 0 1 0 12.94 9.4l12.34-17 12.34 17a8 8 0 1 0 12.94-9.4l-12.34-17 20-6.49a8 8 0 0 0 5.07-10.09Z" /></Svg>;
export default Password;