/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SubtractSquare = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subtract-square__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subtract-square'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 160V96a8 8 0 0 0-8-8h-48V40a8 8 0 0 0-8-8H40a8 8 0 0 0-8 8v120a8 8 0 0 0 8 8h48v48a8 8 0 0 0 8 8h120a8 8 0 0 0 8-8v-56Zm-60.69 48-40-40h33.38l40 40Zm4.69-51.31v-33.38l40 40v33.38Zm40-16L171.31 104H208ZM48 48h104v104H48Zm56 123.31L140.69 208H104Z" /></Svg>;
export default SubtractSquare;