/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TagSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tag-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tag-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246.66 123.56 201 55.13A15.94 15.94 0 0 0 187.72 48H40a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h147.72a16 16 0 0 0 13.28-7.12l45.63-68.44a8 8 0 0 0 .03-8.88Z" /></Svg>;
export default TagSimple;