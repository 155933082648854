/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandPeace = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-peace__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-peace'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212.24 30A28 28 0 0 0 161 36.77l-13 48.32-12.95-48.32A28 28 0 1 0 81 51.26l9.38 35-8.73-1.68a28 28 0 0 0-24.85 47.8 27.86 27.86 0 0 0-8.8 20.49V160a80 80 0 0 0 80 80h.61c43.78-.33 79.39-36.62 79.39-80.9v-3.34a55.88 55.88 0 0 0-11.77-34.27L215 51.26A27.8 27.8 0 0 0 212.24 30ZM97.61 38a12 12 0 0 1 22 2.9l14.77 55.15a28 28 0 0 0-14 4.77 2.26 2.26 0 0 0-.16-.26A27.65 27.65 0 0 0 108 90.35L96.42 47.12A11.94 11.94 0 0 1 97.61 38Zm-33.36 71.6a12 12 0 0 1 14.25-9.34l20.71 4a12 12 0 0 1 9.36 14.16 12 12 0 0 1-14.25 9.34l-20.75-4a12 12 0 0 1-9.32-14.15Zm0 40.72a12 12 0 0 1 14-9.37l10.11 2a12 12 0 0 1 9.36 14.15 12 12 0 0 1-14.2 9.35l-10-2a12 12 0 0 1-9.34-14.16ZM192 159.1c0 35.53-28.49 64.64-63.5 64.9a64.08 64.08 0 0 1-61.56-44.78 30.74 30.74 0 0 0 3.48.95l10 2a28.33 28.33 0 0 0 5.61.57 28 28 0 0 0 24.16-42.14c.79-.43 1.57-.89 2.32-1.4l.16.26a27.82 27.82 0 0 0 17.78 12l6.32 1.26a36 36 0 0 0 9.53 32.49A8 8 0 0 0 157.71 174a20 20 0 0 1-3.31-23.51 8 8 0 0 0-5.46-11.66l-15.34-3.07a12 12 0 0 1-9.35-14.15 12 12 0 0 1 14.18-9.35l21.41 4.28A40.1 40.1 0 0 1 192 155.76Zm7.59-112-16.62 62a55.55 55.55 0 0 0-20-8.28l-2.5-.5 15.93-59.41a12 12 0 1 1 23.18 6.21Z" /></Svg>;
export default HandPeace;