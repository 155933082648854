/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsThreeVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-three-vertical-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-three-vertical-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 60a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm8 60a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm0 68a8 8 0 1 0 8 8 8 8 0 0 0-8-8Z" /></Svg>;
export default DotsThreeVertical;