/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Webcam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webcam-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webcam-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M166 104a38 38 0 1 0-38 38 38 38 0 0 0 38-38Zm-64 0a26 26 0 1 1 26 26 26 26 0 0 1-26-26Zm122 98h-90v-20.25a78 78 0 1 0-12 0V202H32a6 6 0 0 0 0 12h192a6 6 0 0 0 0-12ZM62 104a66 66 0 1 1 66 66 66.08 66.08 0 0 1-66-66Z" /></Svg>;
export default Webcam;