/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GrainsSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="grains-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-grains-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14l4.37 4.81A12 12 0 0 0 36 64v80a92 92 0 0 0 153 68.9l10.16 11.17a12 12 0 0 0 17.76-16.14Zm3.31 117.16a68.16 68.16 0 0 1 55.62 61.82 68.16 68.16 0 0 1-55.62-61.82ZM128 170.61a92.22 92.22 0 0 0-68-45.83V77.05a68.53 68.53 0 0 1 6.94 1.63l70.83 77.9a92.45 92.45 0 0 0-9.77 14.03Zm12.19 40.29a68.26 68.26 0 0 1 13.92-36.34l18.7 20.57a67.74 67.74 0 0 1-32.62 15.77ZM220 64v80a92.8 92.8 0 0 1-1 13.34 12 12 0 0 1-11.86 10.28 12.74 12.74 0 0 1-1.74-.13 12 12 0 0 1-10.15-13.6c.23-1.59.4-3.19.52-4.8-.76.14-1.53.29-2.29.45a12 12 0 1 1-5.08-23.45c2.49-.54 5-1 7.56-1.31V77.07a68 68 0 0 0-38.79 21.72 12 12 0 1 1-17.92-16 91.73 91.73 0 0 1 17.37-15.09c-8.2-14.93-21.31-25-28.63-29.76a97.84 97.84 0 0 0-15.56 12.6 12 12 0 0 1-17-16.95 116 116 0 0 1 27.19-20.32 12 12 0 0 1 10.74 0c1.33.66 29.36 15 44.74 43.74A91.83 91.83 0 0 1 208 52a12 12 0 0 1 12 12Z" /></Svg>;
export default GrainsSlash;