/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bluetooth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m192 80-64 48V32Zm-64 144 64-48-64-48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M196.8 169.6 141.33 128l55.47-41.6a8 8 0 0 0 0-12.8l-64-48A8 8 0 0 0 120 32v80L68.8 73.6a8 8 0 0 0-9.6 12.8l55.47 41.6-55.47 41.6a8 8 0 1 0 9.6 12.8L120 144v80a8 8 0 0 0 12.8 6.4l64-48a8 8 0 0 0 0-12.8ZM136 48l42.67 32L136 112Zm0 160v-64l42.67 32Z" /></Svg>;
export default Bluetooth;