/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pulse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pulse-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pulse-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 128a4 4 0 0 1-4 4h-29.53l-38.89 77.79A4 4 0 0 1 160 212h-.2a4 4 0 0 1-3.54-2.58l-60.59-159-36 79.28A4 4 0 0 1 56 132H24a4 4 0 0 1 0-8h29.42l38.94-85.65a4 4 0 0 1 7.38.23l60.76 159.48 35.92-71.85A4 4 0 0 1 200 124h32a4 4 0 0 1 4 4Z" /></Svg>;
export default Pulse;