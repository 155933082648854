/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CassetteTape = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cassette-tape-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cassette-tape-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 44H32a20 20 0 0 0-20 20v128a20 20 0 0 0 20 20h192a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20Zm-4 144h-37l-12.6-16.8a8 8 0 0 0-6.4-3.2H92a8 8 0 0 0-6.4 3.2L73 188H36V68h184ZM82 152h92a34 34 0 0 0 0-68H82a34 34 0 0 0 0 68Zm0-44a10 10 0 1 1-10 10 10 10 0 0 1 10-10Zm102 10a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-42.5 10h-27a34.08 34.08 0 0 0 0-20h27a34.08 34.08 0 0 0 0 20Z" /></Svg>;
export default CassetteTape;