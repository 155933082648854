/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IceCream = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ice-cream-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ice-cream-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 97.37V96a80 80 0 0 0-160 0v1.37A24 24 0 0 0 56 144h3.29l54.82 95.94a16 16 0 0 0 27.78 0L196.71 144H200a24 24 0 0 0 8-46.63Zm-61.11 101.57L115.5 144h19.29l21.75 38.06ZM77.71 144h19.36l40.61 71.06L128 232Zm88 21.94L153.21 144h25.08Z" /></Svg>;
export default IceCream;