/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pipe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pipe-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pipe-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 106h-26V54h26a6 6 0 0 0 0-12h-27.37A14 14 0 0 0 192 34h-16a14 14 0 0 0-12.63 8H144A102.12 102.12 0 0 0 42 144v19.37A14 14 0 0 0 34 176v16a14 14 0 0 0 8 12.63V232a6 6 0 0 0 12 0v-26h52v26a6 6 0 0 0 12 0v-27.37a14 14 0 0 0 8-12.63v-16a14 14 0 0 0-8-12.63V144a26 26 0 0 1 26-26h19.37a14 14 0 0 0 12.63 8h16a14 14 0 0 0 12.63-8H232a6 6 0 0 0 0-12Zm-120 68a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2v-16a2 2 0 0 1 2-2Zm-6-30v18H54v-18a90.1 90.1 0 0 1 90-90h18v52h-18a38 38 0 0 0-38 38Zm86-30h-16a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v64a2 2 0 0 1-2 2Z" /></Svg>;
export default Pipe;