/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserSwitch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-switch-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-switch-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213 174.26a12 12 0 0 0-16.76 2.74q-1.47 2.06-3.05 4a76 76 0 0 0-30-28.37 48 48 0 1 0-70.28.08 76.8 76.8 0 0 0-30.06 28.25 83.62 83.62 0 0 1-18.3-43.55 12 12 0 0 0 16-17.88l-20-20a12 12 0 0 0-17 0l-20 20a12 12 0 0 0 16.83 17.1 107.88 107.88 0 0 0 37.72 73.61 12.33 12.33 0 0 0 1.88 1.57 107.82 107.82 0 0 0 136.47-.26 13.09 13.09 0 0 0 1.28-1.06 107.66 107.66 0 0 0 18-19.46 12 12 0 0 0-2.73-16.77ZM128 96a24 24 0 1 1-24 24 24 24 0 0 1 24-24Zm0 116a83.52 83.52 0 0 1-46.94-14.37 52 52 0 0 1 93.88 0A84.07 84.07 0 0 1 128 212Zm124.49-75.51-20 20a12 12 0 0 1-17 0l-20-20a12 12 0 0 1 16-17.88A84 84 0 0 0 59.74 79a12 12 0 1 1-19.48-14 108 108 0 0 1 195.4 54.4 12 12 0 0 1 16.83 17.1Z" /></Svg>;
export default UserSwitch;