/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SubtitlesSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subtitles-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subtitles-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M53.92 34.62a8 8 0 0 0-11.48-.37 8.23 8.23 0 0 0-.14 11.38L44.46 48H32a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h157.92l12.16 13.38a8 8 0 0 0 11.33.51 8.31 8.31 0 0 0 .3-11.51ZM104 128h13.19l14.54 16h-27.46a8.18 8.18 0 0 1-8.27-7.47 8 8 0 0 1 8-8.53Zm-48 0h16a8 8 0 0 1 8 8.53 8.18 8.18 0 0 1-8.27 7.47H56.27a8.18 8.18 0 0 1-8.27-7.47 8 8 0 0 1 8-8.53Zm96 48H56.27a8.18 8.18 0 0 1-8.27-7.47 8 8 0 0 1 8-8.53h90.28l11.9 13.09A8 8 0 0 1 152 176Zm88-112v128a16 16 0 0 1-5.19 11.78 4 4 0 0 1-5.7-.24L175 144h25a8 8 0 0 0 8-8.53 8.17 8.17 0 0 0-8.25-7.47h-39.3L93.79 54.69a4 4 0 0 1 3-6.69H224a16 16 0 0 1 16 16Z" /></Svg>;
export default SubtitlesSlash;