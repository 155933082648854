/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Screencast = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="screencast-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-screencast-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 56v144a12 12 0 0 1-12 12h-72a4 4 0 0 1 0-8h72a4 4 0 0 0 4-4V56a4 4 0 0 0-4-4H40a4 4 0 0 0-4 4v40a4 4 0 0 1-8 0V56a12 12 0 0 1 12-12h176a12 12 0 0 1 12 12ZM32 188a4 4 0 0 0 0 8 12 12 0 0 1 12 12 4 4 0 0 0 8 0 20 20 0 0 0-20-20Zm0-32a4 4 0 0 0 0 8 44.05 44.05 0 0 1 44 44 4 4 0 0 0 8 0 52.06 52.06 0 0 0-52-52Zm0-32a4 4 0 0 0 0 8 76.08 76.08 0 0 1 76 76 4 4 0 0 0 8 0 84.09 84.09 0 0 0-84-84Z" /></Svg>;
export default Screencast;