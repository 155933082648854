/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boat-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boat-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.06 110.59 208 106.23V56a16 16 0 0 0-16-16h-56V24a8 8 0 0 0-16 0v16H64a16 16 0 0 0-16 16v50.23l-13.06 4.36A16 16 0 0 0 24 125.77V152c0 61.54 97.89 86.72 102.06 87.76a8 8 0 0 0 3.88 0C134.11 238.72 232 213.54 232 152v-26.23a16 16 0 0 0-10.94-15.18ZM136 168a8 8 0 0 1-16 0v-63.13a8 8 0 0 1 16 0Zm56-67.1-61.47-20.49a8 8 0 0 0-5.06 0L64 100.9V56h128Z" /></Svg>;
export default Boat;