/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-simple-x-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-simple-x-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M162.64 26.61a6 6 0 0 0-6.32.65L85.94 82H40a14 14 0 0 0-14 14v64a14 14 0 0 0 14 14h45.94l70.38 54.74A6 6 0 0 0 166 224V32a6 6 0 0 0-3.36-5.39ZM154 211.73l-62.32-48.47A6 6 0 0 0 88 162H40a2 2 0 0 1-2-2V96a2 2 0 0 1 2-2h48a6 6 0 0 0 3.68-1.26L154 44.27Zm98.24-64a6 6 0 1 1-8.48 8.48L224 136.48l-19.76 19.76a6 6 0 0 1-8.48-8.48L215.52 128l-19.76-19.76a6 6 0 0 1 8.48-8.48L224 119.52l19.76-19.76a6 6 0 0 1 8.48 8.48L232.48 128Z" /></Svg>;
export default SpeakerSimpleX;