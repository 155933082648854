/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segment-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segment-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.81 83.79a28 28 0 0 1-33.12 4.83l-90.07 90.07a28 28 0 1 1-44.43-6.48 28 28 0 0 1 33.12-4.83l90.07-90.07a28 28 0 1 1 44.43 6.48Z" /></Svg>;
export default LineSegment;