/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lamp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lamp-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lamp-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m243.68 150.42-48-112A4 4 0 0 0 192 36H64a4 4 0 0 0-3.68 2.42l-48 112A4 4 0 0 0 16 156h108v56H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8h-28v-56h64v36a4 4 0 0 0 8 0v-36h36a4 4 0 0 0 3.68-5.58ZM22.07 148 66.64 44h122.72l44.57 104Z" /></Svg>;
export default Lamp;