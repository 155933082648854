/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pulse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pulse-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pulse-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238 128a6 6 0 0 1-6 6h-28.29l-38.34 76.68A6 6 0 0 1 160 214h-.3a6 6 0 0 1-5.31-3.85L95.51 55.57l-34.05 74.91A6 6 0 0 1 56 134H24a6 6 0 0 1 0-12h28.14l38.4-84.48a6 6 0 0 1 11.07.34l59.13 155.24 33.89-67.78A6 6 0 0 1 200 122h32a6 6 0 0 1 6 6Z" /></Svg>;
export default Pulse;