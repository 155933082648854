/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VideoConference = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="video-conference-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-video-conference-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M40 48a8 8 0 0 0-8 8v144a8 8 0 0 0 8 8h120V48Zm56 96a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm0 80h-48V56h48ZM40 56h112v144H40Zm176 144h-48v-64h48v64ZM180 88a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm24 80a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-68.25-2a39.76 39.76 0 0 0-17.19-23.34 32 32 0 1 0-45.12 0A39.84 39.84 0 0 0 56.25 166a8 8 0 0 0 15.5 4c2.64-10.25 13.06-18 24.25-18s21.62 7.73 24.25 18a8 8 0 1 0 15.5-4ZM80 120a16 16 0 1 1 16 16 16 16 0 0 1-16-16Z" /></Svg>;
export default VideoConference;