/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SupersetProperOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="superset-proper-of-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-superset-proper-of-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 128a86.1 86.1 0 0 1-86 86H64a6 6 0 0 1 0-12h72a74 74 0 0 0 0-148H64a6 6 0 0 1 0-12h72a86.1 86.1 0 0 1 86 86Z" /></Svg>;
export default SupersetProperOf;