/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Basket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="basket-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-basket-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 128v40a12 12 0 0 1-24 0v-40a12 12 0 0 1 24 0Zm28.06-1.19-4 40a12 12 0 0 0 10.75 13.13c.4 0 .81.06 1.2.06a12 12 0 0 0 11.93-10.81l4-40a12 12 0 0 0-23.88-2.38Zm-80.12 0a12 12 0 0 0-23.88 2.38l4 40A12 12 0 0 0 80 180c.39 0 .8 0 1.2-.06a12 12 0 0 0 10.75-13.13Zm156-37.22-15.07 113A20.06 20.06 0 0 1 209 220H47a20.06 20.06 0 0 1-19.82-17.36l-15.07-113A12 12 0 0 1 24 76h42.55L119 16.1a12 12 0 0 1 18.06 0L189.45 76H232a12 12 0 0 1 11.89 13.59ZM98.45 76h59.1L128 42.22Zm119.84 24H37.71l12.8 96h155Z" /></Svg>;
export default Basket;