/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RadioButton = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-button-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-button-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm0 192a92 92 0 1 1 92-92 92.1 92.1 0 0 1-92 92Zm0-144a52 52 0 1 0 52 52 52.06 52.06 0 0 0-52-52Zm0 96a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Z" /></Svg>;
export default RadioButton;