/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GpsFix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gps-fix-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gps-fix-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 120h-24.37A88.13 88.13 0 0 0 136 40.37V16a8 8 0 0 0-16 0v24.37A88.13 88.13 0 0 0 40.37 120H16a8 8 0 0 0 0 16h24.37A88.13 88.13 0 0 0 120 215.63V240a8 8 0 0 0 16 0v-24.37A88.13 88.13 0 0 0 215.63 136H240a8 8 0 0 0 0-16Zm-112 80a72 72 0 1 1 72-72 72.08 72.08 0 0 1-72 72Zm40-72a40 40 0 1 1-40-40 40 40 0 0 1 40 40Z" /></Svg>;
export default GpsFix;