/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SubtitlesSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subtitles-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subtitles-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 128a12 12 0 0 1 12-12h16a12 12 0 0 1 0 24H60a12 12 0 0 1-12-12Zm168.88 79.93a12 12 0 1 1-17.76 16.14l-11-12.07H32a20 20 0 0 1-20-20V64a20 20 0 0 1 20-20h4.68a12 12 0 0 1 20.2-12.07ZM166.33 188l-10.91-12H60a12 12 0 0 1 0-24h73.6l-10.91-12H116a12 12 0 0 1-10.1-18.47L57.24 68H36v120ZM224 44H116.6a12 12 0 0 0 0 24H220v114.94a12 12 0 0 0 24 0V64a20 20 0 0 0-20-20Zm-28 96a12 12 0 0 0 0-24h-13.94a12 12 0 0 0 0 24Z" /></Svg>;
export default SubtitlesSlash;