/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneCall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-call-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-call-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M146.2 46.45a6 6 0 0 1 7.35-4.25 84.24 84.24 0 0 1 60.25 60.25 6 6 0 0 1-4.25 7.35 5.94 5.94 0 0 1-1.55.2 6 6 0 0 1-5.8-4.45 72.34 72.34 0 0 0-51.75-51.75 6 6 0 0 1-4.25-7.35Zm-3.75 39.35C157 89.68 166.32 99 170.2 113.55A6 6 0 0 0 176 118a5.94 5.94 0 0 0 1.55-.2 6 6 0 0 0 4.25-7.35c-5-18.71-17.54-31.25-36.25-36.25a6 6 0 1 0-3.1 11.6Zm79.44 97A54.25 54.25 0 0 1 168 230C89.7 230 26 166.3 26 88a54.25 54.25 0 0 1 47.17-53.89 14 14 0 0 1 14.56 8.39l21.1 47.1a14 14 0 0 1-1.12 13.28 6 6 0 0 1-.42.57l-21.07 25.06a1.89 1.89 0 0 0 0 1.67c7.66 15.68 24.1 32 40 39.65a1.88 1.88 0 0 0 1.68-.06l24.69-21a4.81 4.81 0 0 1 .56-.42 14 14 0 0 1 13.28-1.22l47.24 21.17a14 14 0 0 1 8.22 14.53ZM210 181.32a2 2 0 0 0-1.21-2l-47.25-21.17a1.92 1.92 0 0 0-1.6.1l-24.68 21c-.18.15-.37.29-.56.42a14 14 0 0 1-13.77 1c-18.36-8.87-36.66-27-45.53-45.19a14 14 0 0 1 .91-13.73 4.73 4.73 0 0 1 .43-.57l21.05-25.09a2 2 0 0 0 0-1.67L76.74 47.31A2 2 0 0 0 74.9 46h-.23A42.24 42.24 0 0 0 38 88c0 71.68 58.32 130 130 130a42.24 42.24 0 0 0 42-36.68Z" /></Svg>;
export default PhoneCall;