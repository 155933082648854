/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-plus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-plus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28 64a12 12 0 0 1 12-12h176a12 12 0 0 1 0 24H40a12 12 0 0 1-12-12Zm12 76h176a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Zm104 40H40a12 12 0 0 0 0 24h104a12 12 0 0 0 0-24Zm88 0h-12v-12a12 12 0 0 0-24 0v12h-12a12 12 0 0 0 0 24h12v12a12 12 0 0 0 24 0v-12h12a12 12 0 0 0 0-24Z" /></Svg>;
export default ListPlus;