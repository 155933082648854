/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pentagon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pentagon-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pentagon-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M223.14 90.74 135.19 22.4l-.09-.07a12 12 0 0 0-14.19 0l-.09.07-87.95 68.34a12 12 0 0 0-4.3 13.26l32 107.7A12 12 0 0 0 72 220h112a12 12 0 0 0 11.44-8.41l32-107.53a12 12 0 0 0-4.3-13.32Zm-3.36 11-32 107.54A4 4 0 0 1 184 212H72a4 4 0 0 1-3.79-2.69l-32-107.7a4 4 0 0 1 1.44-4.45l.09-.07 87.94-68.33a4 4 0 0 1 4.65 0l87.94 68.33.09.07a4 4 0 0 1 1.42 4.53Z" /></Svg>;
export default Pentagon;