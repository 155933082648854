/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StackOverflowLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stack-overflow-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stack-overflow-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 152.09V216a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8v-63.91a8 8 0 0 1 16 0V208h144v-55.91a8 8 0 0 1 16 0Zm-128 32h80a8 8 0 1 0 0-16H88a8 8 0 1 0 0 16Zm4.88-53 77.27 20.68a7.89 7.89 0 0 0 2.08.28 8 8 0 0 0 2.07-15.71L97 115.61A8 8 0 1 0 92.88 131Zm18.45-49.93 69.28 40a8 8 0 0 0 10.93-2.93 8 8 0 0 0-2.93-10.91l-69.28-40.05a8 8 0 1 0-8 13.84Zm87.33 13A8 8 0 1 0 210 82.84l-56.57-56.5a8 8 0 0 0-11.32 11.3Z" /></Svg>;
export default StackOverflowLogo;