/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PlusMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plus-minus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plus-minus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m208.49 64.49-144 144a12 12 0 0 1-17-17l144-144a12 12 0 0 1 17 17ZM60 112a12 12 0 0 0 24 0V84h28a12 12 0 0 0 0-24H84V32a12 12 0 0 0-24 0v28H32a12 12 0 0 0 0 24h28Zm164 60h-80a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24Z" /></Svg>;
export default PlusMinus;