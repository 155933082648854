/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrophoneStage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-stage-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-stage-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M115.06 46.36a4 4 0 0 0-6.11.54A71.54 71.54 0 0 0 96 88a73.29 73.29 0 0 0 .63 9.42l-69.51 94.8A15.93 15.93 0 0 0 28.71 213L43 227.29a15.93 15.93 0 0 0 20.78 1.59l94.81-69.53a73.29 73.29 0 0 0 9.41.65 71.54 71.54 0 0 0 41.09-12.93 4 4 0 0 0 .54-6.11Zm2.61 103.28-16 16a8 8 0 1 1-11.31-11.31l16-16a8 8 0 0 1 11.31 11.31Zm109.4-20.56a4 4 0 0 1-6.12.54l-94.57-94.57a4 4 0 0 1 .54-6.12 71.93 71.93 0 0 1 100.15 100.15Z" /></Svg>;
export default MicrophoneStage;