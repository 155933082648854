/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Empty = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="empty-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-empty-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m203.63 62.65 13.25-14.58a12 12 0 0 0-17.76-16.14l-13.24 14.56A100 100 0 0 0 52.37 193.35l-13.25 14.58a12 12 0 1 0 17.76 16.14l13.24-14.56A100 100 0 0 0 203.63 62.65ZM52 128a75.94 75.94 0 0 1 117.58-63.57l-100.91 111A75.6 75.6 0 0 1 52 128Zm76 76a75.52 75.52 0 0 1-41.58-12.43l100.91-111A75.94 75.94 0 0 1 128 204Z" /></Svg>;
export default Empty;