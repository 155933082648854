/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Baby = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 138a10 10 0 1 1 10-10 10 10 0 0 1-10 10Zm72-20a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm-11.2 44.92a47 47 0 0 1-49.6 0 6 6 0 0 0-6.4 10.16 59 59 0 0 0 62.4 0 6 6 0 1 0-6.4-10.16ZM230 128A102 102 0 1 1 128 26a102.12 102.12 0 0 1 102 102Zm-12 0a90.11 90.11 0 0 0-87.07-89.95C118.3 55.23 118 71.85 118 72a10 10 0 0 0 20 0 6 6 0 0 1 12 0 22 22 0 0 1-44 0c0-.75.15-15.82 10.14-33.22A90 90 0 1 0 218 128Z" /></Svg>;
export default Baby;