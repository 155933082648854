/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PoliceCar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="police-car-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-police-car-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96 24a8 8 0 0 1 8-8h48a8 8 0 0 1 0 16h-48a8 8 0 0 1-8-8Zm152 88a8 8 0 0 1-8 8h-8v80a16 16 0 0 1-16 16h-24a16 16 0 0 1-16-16v-8H80v8a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16v-80h-8a8 8 0 0 1 0-16h11.36l27.39-47.94A16 16 0 0 1 68.64 48h118.72a16 16 0 0 1 13.89 8.06L228.64 104H240a8 8 0 0 1 8 8ZM88 144a8 8 0 0 0-8-8H56a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8Zm120 0a8 8 0 0 0-8-8h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8Zm2.21-40-22.85-40H68.64l-22.85 40Z" /></Svg>;
export default PoliceCar;