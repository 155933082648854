/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rabbit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rabbit-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rabbit-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 188a44 44 0 0 1-80 25.3 44 44 0 1 1-63.71-59.48 64 64 0 0 1 127.42 0A43.9 43.9 0 0 1 208 188Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M112 164a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm44-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm60 36a52 52 0 0 1-88 37.52 52 52 0 1 1-71.28-75.72A71.58 71.58 0 0 1 63 129C44 91.1 32.53 40.76 45.64 19.08A22 22 0 0 1 65.06 8c14.12 0 26 11.89 36.44 36.36 6.22 14.62 10.85 31.32 14 44.74a71.8 71.8 0 0 1 25 0c3.13-13.42 7.76-30.12 14-44.74C164.9 19.89 176.82 8 190.94 8a22 22 0 0 1 19.42 11.08C223.47 40.76 212 91.1 193 129a71.58 71.58 0 0 1 6.26 20.76A51.77 51.77 0 0 1 216 188Zm-60.11-94.37a71.72 71.72 0 0 1 26.88 19.64 218.45 218.45 0 0 0 14.83-46.19c3.49-18.13 3.15-33-.93-39.72a6 6 0 0 0-5.73-3.36c-6.61 0-14.52 9.7-21.72 26.62-5.93 13.94-10.35 30.12-13.33 43.01Zm-82.66 19.64a71.72 71.72 0 0 1 26.88-19.64c-3-12.89-7.4-29.07-13.33-43C79.58 33.7 71.67 24 65.06 24a6 6 0 0 0-5.73 3.36C55.25 34.1 54.91 49 58.4 67.08a218.45 218.45 0 0 0 14.83 46.19ZM200 188a35.87 35.87 0 0 0-13.34-28 8 8 0 0 1-2.92-5.45 56 56 0 0 0-111.48 0 8 8 0 0 1-2.92 5.45 36 36 0 1 0 47.28 54.21l-9.74-8.09a8 8 0 1 1 10.24-12.3L128 202.9l10.88-9.05a8 8 0 0 1 10.24 12.3l-9.74 8.09A36 36 0 0 0 200 188Z" /></Svg>;
export default Rabbit;