/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSwim = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-swim-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-swim-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 72a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm66.89 112.19c-31.83 26.39-53.72 14.51-79.07.74-26.61-14.44-56.76-30.81-96.93 2.49a8 8 0 1 0 10.22 12.31c31.83-26.39 53.72-14.5 79.07-.74 15.11 8.2 31.35 17 49.93 17 14.14 0 29.64-5.11 47-19.5a8 8 0 0 0-10.22-12.32Zm-176-36.76a8 8 0 1 0 10.22 12.31c31.83-26.38 53.72-14.5 79.07-.74 15.11 8.2 31.35 17 49.93 17 14.14 0 29.64-5.11 47-19.5a8 8 0 1 0-10.22-12.31 75.79 75.79 0 0 1-19.28 12.06l-53.84-53.82A103.34 103.34 0 0 0 64.24 72H40a8 8 0 0 0 0 16h24.24a87.66 87.66 0 0 1 41.88 10.56l-29.63 29.61c-12.67 1.19-26.42 6.67-41.6 19.25Z" /></Svg>;
export default PersonSimpleSwim;