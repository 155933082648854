/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EscalatorUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="escalator-up-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-escalator-up-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 42h-56a6 6 0 0 0-4.41 1.93L69.37 146H32a14 14 0 0 0-14 14v40a14 14 0 0 0 14 14h56a6 6 0 0 0 4.41-1.93L186.63 110H224a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14Zm2 54a2 2 0 0 1-2 2h-40a6 6 0 0 0-4.41 1.93L85.37 202H32a2 2 0 0 1-2-2v-40a2 2 0 0 1 2-2h40a6 6 0 0 0 4.41-1.93L170.63 54H224a2 2 0 0 1 2 2Zm2.24 75.76a6 6 0 1 1-8.48 8.48L206 166.49V208a6 6 0 0 1-12 0v-41.51l-13.76 13.75a6 6 0 0 1-8.48-8.48l24-24a6 6 0 0 1 8.48 0Z" /></Svg>;
export default EscalatorUp;