/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shapes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shapes__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shapes'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M71.59 61.47a8 8 0 0 0-15.18 0l-40 120A8 8 0 0 0 24 192h80a8 8 0 0 0 7.59-10.53ZM35.1 176 64 89.3 92.9 176ZM208 76a52 52 0 1 0-52 52 52.06 52.06 0 0 0 52-52Zm-88 0a36 36 0 1 1 36 36 36 36 0 0 1-36-36Zm104 68h-88a8 8 0 0 0-8 8v56a8 8 0 0 0 8 8h88a8 8 0 0 0 8-8v-56a8 8 0 0 0-8-8Zm-8 56h-72v-40h72Z" /></Svg>;
export default Shapes;