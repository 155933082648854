/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Scooter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scooter__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scooter'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 136c-1.18 0-2.35.06-3.51.17l-32.9-98.7A8 8 0 0 0 168 32h-32a8 8 0 0 0 0 16h26.23l17.44 52.31L124.21 168H79.77a36 36 0 1 0-1.83 16H128a8 8 0 0 0 6.19-2.93l51.46-62.81 7.66 23A36 36 0 1 0 212 136ZM44 192a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm168 0a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default Scooter;