/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HardHat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hard-hat-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hard-hat-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 148.4V136a100.41 100.41 0 0 0-64-93.3V40a20 20 0 0 0-20-20h-32a20 20 0 0 0-20 20v2.7A100.41 100.41 0 0 0 28 136v12.4A20 20 0 0 0 12 168v24a20 20 0 0 0 20 20h192a20 20 0 0 0 20-20v-24a20 20 0 0 0-16-19.6ZM204 136v12h-40V69.07A76.35 76.35 0 0 1 204 136Zm-64-92v104h-24V44ZM92 69.07V148H52v-12a76.35 76.35 0 0 1 40-66.93ZM220 188H36v-16h184Z" /></Svg>;
export default HardHat;