/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tipi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tipi-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tipi-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m242.11 209.53-99.86-156 19.86-31a12 12 0 1 0-20.22-13L128 31.24 114.11 9.53a12 12 0 0 0-20.22 12.94l19.86 31-99.86 156A12 12 0 0 0 24 228h208a12 12 0 0 0 10.11-18.47ZM96.17 204 128 154.26 159.83 204Zm92.16 0-50.22-78.47a12 12 0 0 0-20.22 0L67.67 204H45.93L128 75.76 210.07 204Z" /></Svg>;
export default Tipi;