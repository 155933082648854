/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Jar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="jar-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-jar-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 52.23V32a12 12 0 0 0-12-12H88a12 12 0 0 0-12 12v20.23A36 36 0 0 0 44 88v112a36 36 0 0 0 36 36h96a36 36 0 0 0 36-36V88a36 36 0 0 0-32-35.77ZM172 32v20h-24V28h20a4 4 0 0 1 4 4Zm-56 20V28h24v24ZM88 28h20v24H84V32a4 4 0 0 1 4-4Zm116 172a28 28 0 0 1-28 28H80a28 28 0 0 1-28-28V88a28 28 0 0 1 28-28h96a28 28 0 0 1 28 28Z" /></Svg>;
export default Jar;