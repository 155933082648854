/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Playlist = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="playlist-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-playlist-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M34 64a6 6 0 0 1 6-6h176a6 6 0 0 1 0 12H40a6 6 0 0 1-6-6Zm6 70h120a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12Zm72 52H40a6 6 0 0 0 0 12h72a6 6 0 0 0 0-12Zm133.75-60.28a6 6 0 0 1-7.48 4L206 120.06V192a30 30 0 1 1-12-24v-56a6 6 0 0 1 7.72-5.75l40 12a6 6 0 0 1 4.03 7.47ZM194 192a18 18 0 1 0-18 18 18 18 0 0 0 18-18Z" /></Svg>;
export default Playlist;