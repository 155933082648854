/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-dashed-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-dashed-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M98.19 36.54a6 6 0 0 1 4.31-7.31 102 102 0 0 1 51 0 6 6 0 1 1-3 11.61 90 90 0 0 0-45 0 6 6 0 0 1-7.31-4.3Zm-64.3 71.54a5.88 5.88 0 0 0 1.6.22 6 6 0 0 0 5.78-4.4A90 90 0 0 1 63.78 65a6 6 0 1 0-8.56-8.41A102.12 102.12 0 0 0 29.7 100.7a6 6 0 0 0 4.19 7.38ZM150.5 215.15a90 90 0 0 1-45 0 6 6 0 1 0-3 11.61 102 102 0 0 0 51 0 6 6 0 0 0-3-11.62Zm64.22-111.24a6 6 0 0 0 5.78 4.4 5.88 5.88 0 0 0 1.6-.22 6 6 0 0 0 4.18-7.38 102 102 0 0 0-25.49-44.18 6 6 0 0 0-8.55 8.41 90.11 90.11 0 0 1 22.48 38.97Zm11.58 51.39a101.75 101.75 0 0 1-30.08 48.53 6 6 0 0 1-9.37-1.74 66 66 0 0 0-117.7 0 6 6 0 0 1-4.26 3.18 5.74 5.74 0 0 1-1.09.1 6 6 0 0 1-4-1.54 101.74 101.74 0 0 1-30.1-48.53 6 6 0 1 1 11.56-3.21 89.79 89.79 0 0 0 21.2 37.59 77.56 77.56 0 0 1 40-31.43 46 46 0 1 1 51 0 77.56 77.56 0 0 1 40 31.43 89.7 89.7 0 0 0 21.2-37.58 6 6 0 1 1 11.56 3.2ZM128 154a34 34 0 1 0-34-34 34 34 0 0 0 34 34Z" /></Svg>;
export default UserCircleDashed;