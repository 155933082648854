/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vignette = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vignette-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vignette-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a12 12 0 0 0-12 12v144a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12Zm4 156a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4V56a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4ZM128 76c-37.5 0-68 23.33-68 52s30.5 52 68 52 68-23.33 68-52-30.5-52-68-52Zm0 96c-33.08 0-60-19.74-60-44s26.92-44 60-44 60 19.74 60 44-26.92 44-60 44Z" /></Svg>;
export default Vignette;