/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UmbrellaSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="umbrella-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-umbrella-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 127A108.44 108.44 0 0 0 54.46 56.69 107.53 107.53 0 0 0 20 127a12 12 0 0 0 12 13h92v60a28 28 0 0 0 56 0 4 4 0 0 0-8 0 20 20 0 0 1-40 0v-60h92a12 12 0 0 0 12-13Zm-9 3.74a4 4 0 0 1-3 1.3H32a4 4 0 0 1-4-4.38 100.43 100.43 0 0 1 168.1-65 99.53 99.53 0 0 1 31.88 65 4 4 0 0 1-1.05 3.04Z" /></Svg>;
export default UmbrellaSimple;