/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaperPlaneTilt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paper-plane-tilt-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paper-plane-tilt-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M225.88 30.12a13.83 13.83 0 0 0-13.7-3.58h-.11L20.14 84.77A14 14 0 0 0 18 110.85l85.56 41.64L145.12 238a13.87 13.87 0 0 0 12.61 8c.4 0 .81 0 1.21-.05a13.9 13.9 0 0 0 12.29-10.09l58.2-191.93v-.11a13.83 13.83 0 0 0-3.55-13.7Zm-8 10.4-58.15 191.91v.11a2 2 0 0 1-3.76.26l-40.68-83.58 49-49a6 6 0 1 0-8.49-8.49l-49 49L23.15 100a2 2 0 0 1 .31-3.74h.11l191.91-58.18a1.94 1.94 0 0 1 1.92.52 2 2 0 0 1 .52 1.92Z" /></Svg>;
export default PaperPlaneTilt;