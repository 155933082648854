/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextHSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-h-six-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-h-six-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 72v120a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16V56h184a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M152 56v120a8 8 0 0 1-16 0v-52H48v52a8 8 0 0 1-16 0V56a8 8 0 0 1 16 0v52h88V56a8 8 0 0 1 16 0Zm96 124a36 36 0 1 1-67.34-17.68c.07-.14.14-.28.22-.42l32.25-54a8 8 0 0 1 13.74 8.2l-16.69 28c.6 0 1.21-.05 1.82-.05A36 36 0 0 1 248 180Zm-16 0a20 20 0 1 0-20 20 20 20 0 0 0 20-20Z" /></Svg>;
export default TextHSix;