/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotSubsetOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-subset-of-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-subset-of-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 80h31.37l-68.54 78.33A48 48 0 0 1 128 80Zm96-32v160a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Zm-34.73 10a8 8 0 0 0-11.29.75L173.37 64H128a64 64 0 0 0-47.81 106.49L66 186.73a8 8 0 0 0 12 10.54L92.22 181A63.62 63.62 0 0 0 128 192h48a8 8 0 0 0 0-16h-48a47.63 47.63 0 0 1-25.13-7.13L190 69.27a8 8 0 0 0-.73-11.27Z" /></Svg>;
export default NotSubsetOf;