/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IntersectThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersect-three-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersect-three-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M195.88 96c.07-1.31.12-2.63.12-4a68 68 0 0 0-136 0c0 1.33 0 2.65.12 4A68 68 0 1 0 128 213.65 68 68 0 1 0 195.88 96ZM128 193.47a51.89 51.89 0 0 1-16-35.38 67.55 67.55 0 0 0 31.9 0 51.89 51.89 0 0 1-15.9 35.38Zm-28.77-58.18A52.19 52.19 0 0 1 77.92 106a51.88 51.88 0 0 1 36.79 3.28 68.17 68.17 0 0 0-15.48 26.01Zm42.06-26.06a51.88 51.88 0 0 1 36.79-3.23 52.19 52.19 0 0 1-21.31 29.34 68.17 68.17 0 0 0-15.48-26.11ZM128 40a52.06 52.06 0 0 1 52 49.91 67.72 67.72 0 0 0-52 8.44 67.72 67.72 0 0 0-51.95-8.44A52.06 52.06 0 0 1 128 40ZM40 156a52 52 0 0 1 23.23-43.29A68.36 68.36 0 0 0 96.12 152c-.07 1.31-.12 2.63-.12 4a67.74 67.74 0 0 0 18.71 46.77A52 52 0 0 1 40 156Zm124 52a51.65 51.65 0 0 1-22.71-5.23A67.74 67.74 0 0 0 160 156c0-1.33-.05-2.65-.12-4a68.36 68.36 0 0 0 32.89-39.33A52 52 0 0 1 164 208Z" /></Svg>;
export default IntersectThree;