/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RedditLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="reddit-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-reddit-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 104a32 32 0 0 0-52.94-24.19c-16.75-8.9-36.76-14.28-57.66-15.53l5.19-31.17 17.72 2.72a24 24 0 1 0 2.87-15.74l-26-4a8 8 0 0 0-9.11 6.59l-6.87 41.48c-21.84.94-42.82 6.38-60.26 15.65a32 32 0 0 0-42.59 47.74A59 59 0 0 0 16 144c0 21.93 12 42.35 33.91 57.49C70.88 216 98.61 224 128 224s57.12-8 78.09-22.51C228 186.35 240 165.93 240 144a59 59 0 0 0-2.35-16.45A32.16 32.16 0 0 0 248 104Zm-64-80a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm40.13 93.78a8 8 0 0 0-3.29 10A43.58 43.58 0 0 1 224 144c0 16.53-9.59 32.27-27 44.33C178.67 201 154.17 208 128 208s-50.67-7-69-19.67c-17.41-12.06-27-27.8-27-44.33a43.75 43.75 0 0 1 3.14-16.17 8 8 0 0 0-3.27-10 16 16 0 1 1 21.07-23.24 8 8 0 0 0 10.45 2.23l.36-.22C81.45 85.9 104.25 80 128 80c23.73 0 46.53 5.9 64.23 16.6l.42.25a8 8 0 0 0 10.39-2.26 16 16 0 1 1 21.07 23.19ZM88 144a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm96-16a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-16.93 44.25a8 8 0 0 1-3.32 10.82 76.18 76.18 0 0 1-71.5 0 8 8 0 1 1 7.5-14.14 60.18 60.18 0 0 0 56.5 0 8 8 0 0 1 10.82 3.32Z" /></Svg>;
export default RedditLogo;