/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SkipForward = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="skip-forward-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-skip-forward-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 36a4 4 0 0 0-4 4v76.44L70.31 37.84a12 12 0 0 0-12.18-.32A11.69 11.69 0 0 0 52 47.88v160.24a11.69 11.69 0 0 0 6.13 10.36 12 12 0 0 0 12.18-.32L196 139.56V216a4 4 0 0 0 8 0V40a4 4 0 0 0-4-4Zm-5.82 95.26L66.06 211.38a4 4 0 0 1-4.06.11 3.8 3.8 0 0 1-2-3.37V47.88a3.8 3.8 0 0 1 2-3.37 4 4 0 0 1 2-.51 4 4 0 0 1 2.11.62l128.12 80.12a3.83 3.83 0 0 1 0 6.52Z" /></Svg>;
export default SkipForward;