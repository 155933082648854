/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiscordLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="discord-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-discord-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M102 140a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm62-10a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm73.64 61.08-67 29.71a14.43 14.43 0 0 1-5.77 1.21 14.13 14.13 0 0 1-13.25-9.18L143 189.43c-4.93.37-9.92.58-15 .58s-10.06-.21-15-.58l-8.63 23.39A14.13 14.13 0 0 1 91.13 222a14.43 14.43 0 0 1-5.77-1.21l-67-29.71a14 14 0 0 1-7.93-16.2L40 58.5a14.07 14.07 0 0 1 11.34-10.39l36.06-5.92a14.19 14.19 0 0 1 16 10.39l3.69 14.53a197.5 197.5 0 0 1 41.82 0l3.69-14.53a14.19 14.19 0 0 1 16-10.39l36.06 5.92A14.07 14.07 0 0 1 216 58.5l29.53 116.38a14 14 0 0 1-7.89 16.2Zm-3.7-13.25L204.41 61.45a2.08 2.08 0 0 0-1.7-1.5L166.65 54a2.13 2.13 0 0 0-2.42 1.5l-3.36 13.24a169.28 169.28 0 0 1 16.75 3.76A6 6 0 0 1 176 84.31a5.71 5.71 0 0 1-1.62-.23A174.26 174.26 0 0 0 128 78a174.26 174.26 0 0 0-46.38 6.08 6 6 0 1 1-3.24-11.55 169.28 169.28 0 0 1 16.75-3.76l-3.36-13.24A2.12 2.12 0 0 0 89.35 54l-36.06 6a2.08 2.08 0 0 0-1.7 1.5L22.06 177.83a2 2 0 0 0 1.16 2.28l67 29.7a2.19 2.19 0 0 0 1.76 0 2.07 2.07 0 0 0 1.14-1.17l7.58-20.55a171.46 171.46 0 0 1-22.33-4.64 6 6 0 1 1 3.24-11.55A174.26 174.26 0 0 0 128 178a174.26 174.26 0 0 0 46.38-6.08 6 6 0 1 1 3.24 11.55 171.46 171.46 0 0 1-22.33 4.64l7.58 20.55a2.07 2.07 0 0 0 1.14 1.17 2.19 2.19 0 0 0 1.76 0l67-29.7a2 2 0 0 0 1.17-2.3Z" /></Svg>;
export default DiscordLogo;