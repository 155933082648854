/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cardholder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cardholder-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cardholder-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 44H48a28 28 0 0 0-28 28v112a28 28 0 0 0 28 28h160a28 28 0 0 0 28-28V72a28 28 0 0 0-28-28ZM48 68h160a4 4 0 0 1 4 4v16h-52a12 12 0 0 0-12 12 20 20 0 0 1-40 0 12 12 0 0 0-12-12H44V72a4 4 0 0 1 4-4Zm160 120H48a4 4 0 0 1-4-4v-72h41.66a44 44 0 0 0 84.68 0H212v72a4 4 0 0 1-4 4Z" /></Svg>;
export default Cardholder;