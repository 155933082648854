/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BabyCarriage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-carriage-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-carriage-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 112h-88V48a8 8 0 0 1 8-8h8a72 72 0 0 1 72 72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M160 32h-8a16 16 0 0 0-16 16v56H55.2A40.07 40.07 0 0 0 16 72a8 8 0 0 0 0 16 24 24 0 0 1 24 24 80.09 80.09 0 0 0 80 80h40a80 80 0 0 0 0-160Zm63.48 72h-56.67l41.86-33.49A63.73 63.73 0 0 1 223.48 104ZM160 48a63.59 63.59 0 0 1 36.69 11.61L152 95.35V48Zm0 128h-40a64.09 64.09 0 0 1-63.5-56h167a64.09 64.09 0 0 1-63.5 56Zm-56 48a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm104 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default BabyCarriage;