/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartPie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-pie-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-pie-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28Zm77.58 50.59L132 121.07v-85a92.07 92.07 0 0 1 73.58 42.52ZM124 36.09v89.6l-77.58 44.79A92 92 0 0 1 124 36.09ZM128 220a92 92 0 0 1-77.58-42.59l159.16-91.89A92 92 0 0 1 128 220Z" /></Svg>;
export default ChartPie;