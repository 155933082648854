/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderMale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-male-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-male-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 36h-48a4 4 0 0 0 0 8h38.35l-51.53 51.52a76 76 0 1 0 5.66 5.66L212 49.66V88a4 4 0 0 0 8 0V40a4 4 0 0 0-4-4Zm-63.93 164.11a68 68 0 1 1 0-96.18 68.08 68.08 0 0 1 0 96.18Z" /></Svg>;
export default GenderMale;