/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FloppyDiskBack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="floppy-disk-back-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-floppy-disk-back-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H83.31a12 12 0 0 0-8.48 3.51L39.52 74.83A11.9 11.9 0 0 0 36 83.31V208a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12ZM84 44h88v36a4 4 0 0 1-4 4H88a4 4 0 0 1-4-4Zm128 164a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4V83.31a4 4 0 0 1 1.17-2.82L76 49.66V80a12 12 0 0 0 12 12h80a12 12 0 0 0 12-12V44h28a4 4 0 0 1 4 4Zm-84-92a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default FloppyDiskBack;