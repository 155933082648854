/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerLow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-low-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-low-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M157.27 21.22a12 12 0 0 0-12.64 1.31L75.88 76H32a20 20 0 0 0-20 20v64a20 20 0 0 0 20 20h43.88l68.75 53.47A12 12 0 0 0 164 224V32a12 12 0 0 0-6.73-10.78ZM36 100h32v56H36Zm104 99.46-48-37.33V93.87l48-37.33ZM212 128a44 44 0 0 1-11 29.11 12 12 0 0 1-18-15.88 20 20 0 0 0 0-26.44 12 12 0 0 1 18-15.85A43.94 43.94 0 0 1 212 128Z" /></Svg>;
export default SpeakerLow;