/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleWalk = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-walk-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-walk-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 48a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm88 88c-28.64 0-41.81-13.3-55.75-27.37-3.53-3.57-7.18-7.26-11-10.58-37-32.14-96.22 22.73-98.72 25.08a8 8 0 0 0 10.95 11.66A163.88 163.88 0 0 1 84 113c13.78-7.38 25.39-10.23 34.7-8.58L64.66 228.81a8 8 0 0 0 4.15 10.52A7.84 7.84 0 0 0 72 240a8 8 0 0 0 7.34-4.81l33.59-77.27 31.07 22.2V232a8 8 0 0 0 16 0v-56a8 8 0 0 0-3.35-6.51l-37.2-26.57L132.88 112c2.64 2.44 5.26 5.07 8 7.84C155.05 134.19 172.69 152 208 152a8 8 0 0 0 0-16Z" /></Svg>;
export default PersonSimpleWalk;