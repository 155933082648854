/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eject = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eject-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eject-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 156H48a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12Zm4 36a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4v-24a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4ZM48.23 132h159.54a12 12 0 0 0 11.23-7.33 11.68 11.68 0 0 0-2.33-12.8L143 34.37a20.75 20.75 0 0 0-29.92 0l-73.78 77.5a11.68 11.68 0 0 0-2.3 12.8A12 12 0 0 0 48.23 132Zm-3.13-14.61 73.73-77.51a12.78 12.78 0 0 1 18.34 0l73.73 77.51a3.66 3.66 0 0 1 .77 4.12 4.1 4.1 0 0 1-3.9 2.49H48.23a4.1 4.1 0 0 1-3.9-2.49 3.66 3.66 0 0 1 .77-4.12Z" /></Svg>;
export default Eject;