/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Resize = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="resize-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-resize-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 108H48a12 12 0 0 0-12 12v88a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-88a12 12 0 0 0-12-12Zm-12 88H60v-64h64Zm96-8v12a20 20 0 0 1-20 20h-20a12 12 0 0 1 0-24h16v-8a12 12 0 0 1 24 0Zm0-72v24a12 12 0 0 1-24 0v-24a12 12 0 0 1 24 0Zm0-60v16a12 12 0 0 1-24 0V60h-12a12 12 0 0 1 0-24h16a20 20 0 0 1 20 20Zm-68-8a12 12 0 0 1-12 12h-24a12 12 0 0 1 0-24h24a12 12 0 0 1 12 12ZM36 76V56a20 20 0 0 1 20-20h12a12 12 0 0 1 0 24h-8v16a12 12 0 0 1-24 0Z" /></Svg>;
export default Resize;