/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GearSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gear-six-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gear-six-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 84a44 44 0 1 0 44 44 44.05 44.05 0 0 0-44-44Zm0 80a36 36 0 1 1 36-36 36 36 0 0 1-36 36Zm106-56a4 4 0 0 0-2-2.7l-30.89-17.6q-.47-.82-1-1.62l-.01-34.88a3.94 3.94 0 0 0-1.42-3 107.8 107.8 0 0 0-35.41-19.94 4 4 0 0 0-3.23.29L129 45.87h-2L96 28.51a4 4 0 0 0-3.23-.3 108.05 108.05 0 0 0-35.39 20 4 4 0 0 0-1.41 3l-.16 34.9-1 1.62L23.9 105.3A4 4 0 0 0 22 108a102.76 102.76 0 0 0 0 40 4 4 0 0 0 1.95 2.7l30.89 17.6q.47.83 1 1.62l.12 34.87a3.94 3.94 0 0 0 1.42 3 107.8 107.8 0 0 0 35.41 19.94 4 4 0 0 0 3.23-.29L127 210.13h2l31 17.36a4 4 0 0 0 3.23.3 108.05 108.05 0 0 0 35.39-20 4 4 0 0 0 1.41-3l.16-34.9 1-1.62 30.91-17.57a4 4 0 0 0 2-2.71A102.76 102.76 0 0 0 234 108Zm-7.48 36.67-30.22 17.17a4 4 0 0 0-1.51 1.53c-.61 1.09-1.25 2.17-1.91 3.24a3.92 3.92 0 0 0-.61 2.1l-.16 34.15a99.8 99.8 0 0 1-29.7 16.77l-30.4-17a4.06 4.06 0 0 0-2-.51H126.16a4.1 4.1 0 0 0-2.05.51l-30.45 17a100.23 100.23 0 0 1-29.77-16.73l-.12-34.12a3.93 3.93 0 0 0-.61-2.11c-.66-1-1.3-2.14-1.91-3.23a4 4 0 0 0-1.51-1.53l-30.25-17.23a94.78 94.78 0 0 1 0-33.34L59.7 94.16a4 4 0 0 0 1.51-1.53c.61-1.09 1.25-2.17 1.91-3.23a4 4 0 0 0 .61-2.11l.16-34.15a99.8 99.8 0 0 1 29.7-16.77l30.4 17a4.1 4.1 0 0 0 2.05.51h3.84a4 4 0 0 0 2.05-.51l30.45-17a100.23 100.23 0 0 1 29.73 16.73l.12 34.12a3.93 3.93 0 0 0 .61 2.11c.66 1 1.3 2.14 1.91 3.23a4 4 0 0 0 1.51 1.53l30.25 17.23a94.78 94.78 0 0 1 .03 33.34Z" /></Svg>;
export default GearSix;