/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MoneyWavy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="money-wavy-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-money-wavy-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M246.36 56.55a12 12 0 0 0-11.63-.6c-41.48 20.29-71.4 10.71-103.07.56-33.18-10.62-70.78-22.66-120.93 1.86A12 12 0 0 0 4 69.16v120.1a12 12 0 0 0 17.27 10.79c41.48-20.29 71.4-10.71 103.07-.56 18.83 6 39.08 12.51 62.24 12.51 17.66 0 37-3.77 58.69-14.37a12 12 0 0 0 6.73-10.79V66.74a12 12 0 0 0-5.64-10.19ZM228 179.12c-38 16.16-66.41 7.07-96.34-2.51-18.83-6-39.08-12.52-62.24-12.52A124.86 124.86 0 0 0 28 171.24V76.88c38-16.16 66.41-7.08 96.34 2.51 29.26 9.37 61.95 19.84 103.66 5.37ZM128 96a32 32 0 1 0 32 32 32.06 32.06 0 0 0-32-32Zm0 40a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm-64-36v40a12 12 0 1 1-24 0v-40a12 12 0 1 1 24 0Zm128 56v-40a12 12 0 1 1 24 0v40a12 12 0 1 1-24 0Z" /></Svg>;
export default MoneyWavy;