/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Basket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="basket__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-basket'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 120v56a8 8 0 0 1-16 0v-56a8 8 0 0 1 16 0Zm36.84-.8-5.6 56a8 8 0 0 0 7.16 8.8 7.32 7.32 0 0 0 .81 0 8 8 0 0 0 7.95-7.2l5.6-56a8 8 0 0 0-15.92-1.6Zm-89.68 0a8 8 0 0 0-15.92 1.6l5.6 56a8 8 0 0 0 8 7.2 7.32 7.32 0 0 0 .81 0 8 8 0 0 0 7.16-8.76Zm156.77-30.14-15.07 113.06A16.06 16.06 0 0 1 209 216H47a16.06 16.06 0 0 1-15.86-13.88L16.07 89.06A8 8 0 0 1 24 80h44.37L122 18.73a8 8 0 0 1 12 0L187.63 80H232a8 8 0 0 1 7.93 9.06ZM89.63 80h76.74L128 36.15Zm133.23 16H33.14L47 200h162Z" /></Svg>;
export default Basket;