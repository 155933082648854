/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Confetti = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="confetti-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-confetti-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M110.08 54a13.8 13.8 0 0 0-22.73 5.05l-52.5 144.42A13.82 13.82 0 0 0 47.76 222a14 14 0 0 0 4.77-.85l144.38-52.5a13.8 13.8 0 0 0 5-22.73ZM48.43 209.87a1.79 1.79 0 0 1-2.3-2.3l15.18-41.77 28.89 28.88Zm54.21-19.71-36.8-36.81L80.51 113 143 175.49ZM194 156.07a1.74 1.74 0 0 1-1.14 1.3L155.44 171 85 100.55l13.63-37.36a1.72 1.72 0 0 1 1.3-1.14 1.58 1.58 0 0 1 .41 0 1.72 1.72 0 0 1 1.25.53l91.88 91.88a1.73 1.73 0 0 1 .53 1.61ZM162 72a35.52 35.52 0 0 1 3.63-14.68C170.57 47.44 179.93 42 192 42c7.47 0 12.53-2.74 15.48-8.38A24.18 24.18 0 0 0 210 24a6 6 0 0 1 6-6 6 6 0 0 1 6 6c0 10.38-6.27 30-30 30-7.47 0-12.53 2.74-15.48 8.38A24 24 0 0 0 174 72a6 6 0 0 1-6 6 6 6 0 0 1-6-6Zm-24-32V16a6 6 0 0 1 12 0v24a6 6 0 0 1-12 0Zm98.24 83.76a6 6 0 1 1-8.48 8.48l-16-16a6 6 0 0 1 8.48-8.48Zm5.66-46.07-24 8a6 6 0 1 1-3.8-11.38l24-8a6 6 0 0 1 3.8 11.38Z" /></Svg>;
export default Confetti;