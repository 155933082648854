/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dresser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dresser-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dresser-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40v176a8 8 0 0 1-8 8H56a8 8 0 0 1-8-8V40a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M144 192a8 8 0 0 1-8 8h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 8 8ZM120 72h16a8 8 0 0 0 0-16h-16a8 8 0 0 0 0 16Zm16 48h-16a8 8 0 0 0 0 16h16a8 8 0 0 0 0-16Zm80-80v176a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16V40a16 16 0 0 1 16-16h144a16 16 0 0 1 16 16ZM56 152h144v-48H56Zm0-112v48h144V40Zm144 176v-48H56v48h144Z" /></Svg>;
export default Dresser;