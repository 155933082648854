/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Couch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="couch-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-couch-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 108.7V72a12 12 0 0 0-12-12H32a12 12 0 0 0-12 12v36.7a12 12 0 0 0-8 11.3v48a12 12 0 0 0 12 12h12v20a4 4 0 0 0 8 0v-20h168v20a4 4 0 0 0 8 0v-20h12a12 12 0 0 0 12-12v-48a12 12 0 0 0-8-11.3ZM228 72v36h-12a12 12 0 0 0-12 12v20h-72V68h92a4 4 0 0 1 4 4ZM32 68h92v72H52v-20a12 12 0 0 0-12-12H28V72a4 4 0 0 1 4-4Zm204 100a4 4 0 0 1-4 4H24a4 4 0 0 1-4-4v-48a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v24a4 4 0 0 0 4 4h160a4 4 0 0 0 4-4v-24a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4Z" /></Svg>;
export default Couch;