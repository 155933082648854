/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseRightClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-right-click-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-right-click-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 20h-32a60.07 60.07 0 0 0-60 60v96a60.07 60.07 0 0 0 60 60h32a60.07 60.07 0 0 0 60-60V80a60.07 60.07 0 0 0-60-60Zm52 60v28h-42.34l40.75-40.75A51.55 51.55 0 0 1 196 80Zm-4.51-21.15L142.34 108H132V81.66l43.22-43.22a52.5 52.5 0 0 1 16.27 20.41Zm-23.19-24.8L132 70.34V28h12a51.61 51.61 0 0 1 24.3 6.05ZM112 28h12v80H60V80a52.06 52.06 0 0 1 52-52Zm32 200h-32a52.06 52.06 0 0 1-52-52v-60h136v60a52.06 52.06 0 0 1-52 52Z" /></Svg>;
export default MouseRightClick;