/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VinylRecord = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vinyl-record-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vinyl-record-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84Zm0-128a44.05 44.05 0 0 0-44 44 12 12 0 0 1-24 0 68.07 68.07 0 0 1 68-68 12 12 0 0 1 0 24Zm68 44a68.07 68.07 0 0 1-68 68 12 12 0 0 1 0-24 44.05 44.05 0 0 0 44-44 12 12 0 0 1 24 0Zm-68 28a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default VinylRecord;