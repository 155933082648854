/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BellSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bell-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bell-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.84 192a1.85 1.85 0 0 1-3 .28L83.27 43.19a4 4 0 0 1 .8-6A79.55 79.55 0 0 1 129.17 24c43.83.66 78.63 37.1 78.83 80.92.14 34.88 8.31 61.54 13.82 71a15.89 15.89 0 0 1 .02 16.08Zm-7.92 18.62a8 8 0 0 1-11.85 10.76L182.62 200h-15.46a40 40 0 0 1-78.41 0H47.91a15.78 15.78 0 0 1-13.59-7.59 16.42 16.42 0 0 1-.09-16.68c5.55-9.73 13.7-36.64 13.7-71.73a79.42 79.42 0 0 1 10.86-40.15L42 45.38a8 8 0 1 1 11.84-10.76ZM150.59 200h-45.27a24 24 0 0 0 45.27 0Z" /></Svg>;
export default BellSlash;