/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImageSquare = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="image-square-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-image-square-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40H48a8 8 0 0 0-8 8v160a8 8 0 0 0 8 8h8.69l109.65-109.66a8 8 0 0 1 11.32 0L216 144.69V48a8 8 0 0 0-8-8ZM96 112a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM48 48h160v77.38l-24.69-24.7a16 16 0 0 0-22.62 0L53.37 208H48Zm160 160H76l96-96 36 36v60ZM96 120a24 24 0 1 0-24-24 24 24 0 0 0 24 24Zm0-32a8 8 0 1 1-8 8 8 8 0 0 1 8-8Z" /></Svg>;
export default ImageSquare;