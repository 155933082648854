/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TeaBag = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tea-bag-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tea-bag-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 164a20 20 0 0 1-20-20V64a52 52 0 0 0-104 0v12H76.53a12.06 12.06 0 0 0-10.29 5.83L45.71 116a12 12 0 0 0-1.71 6.22V216a12 12 0 0 0 12 12h96a12 12 0 0 0 12-12v-93.78a12 12 0 0 0-1.71-6.18l-20.53-34.21A12.06 12.06 0 0 0 131.47 76H108V64a44 44 0 0 1 88 0v80a28 28 0 0 0 28 28 4 4 0 0 0 0-8Zm-92.53-80a4 4 0 0 1 3.43 1.94l20.53 34.22a4 4 0 0 1 .57 2.06V216a4 4 0 0 1-4 4H56a4 4 0 0 1-4-4v-93.78a4 4 0 0 1 .57-2.06L73.1 85.94A4 4 0 0 1 76.53 84H100v52a4 4 0 0 0 8 0V84Z" /></Svg>;
export default TeaBag;