/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CassetteTape = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cassette-tape-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cassette-tape-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156.3 96a31.92 31.92 0 0 0 0 32H99.7a31.92 31.92 0 0 0 0-32ZM72 96a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm168-32v128a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16V64a16 16 0 0 1 16-16h192a16 16 0 0 1 16 16Zm-54 128-15.6-20.8a8 8 0 0 0-6.4-3.2H92a8 8 0 0 0-6.4 3.2L70 192Zm30-80a32 32 0 0 0-32-32H72a32 32 0 0 0 0 64h112a32 32 0 0 0 32-32Zm-32-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default CassetteTape;