/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CirclesFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circles-four-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circles-four-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 44a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm96 8a36 36 0 1 0-36-36 36 36 0 0 0 36 36Zm0-64a28 28 0 1 1-28 28 28 28 0 0 1 28-28Zm-96 88a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm96-64a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default CirclesFour;