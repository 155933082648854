/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrainRegional = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="train-regional-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-train-regional-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m219.86 119-22.39-82.16A12 12 0 0 0 185.89 28H70.11a12 12 0 0 0-11.58 8.84L36.14 119a3.94 3.94 0 0 0 0 2.1l22.39 82.11A12 12 0 0 0 70.11 212H88l-19.2 25.6a4 4 0 1 0 6.4 4.8L98 212h60l22.8 30.4a4 4 0 0 0 6.4-4.8L168 212h17.89a12 12 0 0 0 11.58-8.84l22.39-82.11a3.94 3.94 0 0 0 0-2.05ZM66.25 39a4 4 0 0 1 3.86-3h115.78a4 4 0 0 1 3.86 3L211 116.84l-83 15.09-83-15.09Zm0 162.1-20.72-76L124 139.34V204H70.11a4 4 0 0 1-3.86-2.95Zm123.5 0a4 4 0 0 1-3.86 2.95H132v-64.71l78.47-14.27ZM92 88a4 4 0 0 1 4-4h64a4 4 0 0 1 0 8H96a4 4 0 0 1-4-4Z" /></Svg>;
export default TrainRegional;