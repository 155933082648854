/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Queue = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="queue-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-queue-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28 64a12 12 0 0 1 12-12h176a12 12 0 0 1 0 24H40a12 12 0 0 1-12-12Zm104 52H40a12 12 0 0 0 0 24h92a12 12 0 0 0 0-24Zm0 64H40a12 12 0 0 0 0 24h92a12 12 0 0 0 0-24Zm120-20a12 12 0 0 1-5.64 10.18l-64 40A12 12 0 0 1 164 200v-80a12 12 0 0 1 18.36-10.18l64 40A12 12 0 0 1 252 160Zm-34.64 0L188 141.65v36.7Z" /></Svg>;
export default Queue;