/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Files = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="files-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-files-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m213.66 66.34-40-40A8 8 0 0 0 168 24H88a16 16 0 0 0-16 16v16H56a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h112a16 16 0 0 0 16-16v-16h16a16 16 0 0 0 16-16V72a8 8 0 0 0-2.34-5.66ZM136 192H88a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Zm0-32H88a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Zm64 24h-16v-80a8 8 0 0 0-2.34-5.66l-40-40A8 8 0 0 0 136 56H88V40h76.69L200 75.31Z" /></Svg>;
export default Files;