/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HourglassSimpleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hourglass-simple-medium-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hourglass-simple-medium-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M214 193.68 145.35 128 214 62.32l.18-.18A20 20 0 0 0 200 28H56a20 20 0 0 0-14.13 34.14l.18.18 68.6 65.68-68.6 65.68-.18.18A20 20 0 0 0 56 228h144a20 20 0 0 0 14.14-34.14ZM107.75 92h40.5L128 111.39ZM190 52l-16.69 16H82.69L66 52ZM66 204l50-47.9V168a12 12 0 0 0 24 0v-11.9l50 47.9Z" /></Svg>;
export default HourglassSimpleMedium;