/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CableCar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cable-car-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cable-car-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M245.91 31a6 6 0 0 0-7-4.85L15 66.1A6 6 0 0 0 16 78a6.53 6.53 0 0 0 1.07-.09L122 59.17V98H64a30 30 0 0 0-30 30v64a30 30 0 0 0 30 30h128a30 30 0 0 0 30-30v-64a30 30 0 0 0-30-30h-58V57l107.05-19.09a6 6 0 0 0 4.86-6.91ZM102 162v-52h52v52Zm-38-52h26v52H46v-34a18 18 0 0 1 18-18Zm128 100H64a18 18 0 0 1-18-18v-18h164v18a18 18 0 0 1-18 18Zm18-82v34h-44v-52h26a18 18 0 0 1 18 18Z" /></Svg>;
export default CableCar;