/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GraphicsCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="graphics-card-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-graphics-card-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 44H16A12 12 0 0 0 4 56v152a12 12 0 0 0 24 0v-12h16v12a12 12 0 0 0 24 0v-12h16v12a12 12 0 0 0 24 0v-12h16v12a12 12 0 0 0 24 0v-12h84a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20Zm-4 128H28V68h200Zm-52-12a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm0-56a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm-96 56a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm0-56a16 16 0 1 1-16 16 16 16 0 0 1 16-16Z" /></Svg>;
export default GraphicsCard;