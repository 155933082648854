/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyEth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-eth-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-eth-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m220.72 124.29-88-112a6 6 0 0 0-9.44 0l-88 112a6 6 0 0 0 0 7.42l88 112a6 6 0 0 0 9.44 0l88-112a6 6 0 0 0 0-7.42ZM134 33.35l72.56 92.35-72.56 33Zm-12 125.33-72.56-33L122 33.35Zm0 13.18v50.79l-62.08-79Zm12 0 62.08-28.21-62.08 79Z" /></Svg>;
export default CurrencyEth;