/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EarSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ear-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ear-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216.88 207.93a12 12 0 1 1-17.76 16.14l-33.35-36.68a28.39 28.39 0 0 1-5.77.61 28 28 0 0 1-28-28 45.87 45.87 0 0 1 .85-8.82l-35.77-39.35A12 12 0 0 1 76 104a52 52 0 0 1 1.75-13.43L65.52 77.11A67.7 67.7 0 0 0 60 104c0 25 7.58 32.3 16.35 40.76S96 163.71 96 188a32 32 0 0 0 32 32c9 0 16.19-3.7 22.75-11.64a12 12 0 0 1 18.5 15.28C158.09 237.15 144.21 244 128 244a56.06 56.06 0 0 1-56-56c0-14.09-4.63-18.56-12.31-26C49.13 151.86 36 139.19 36 104a91.3 91.3 0 0 1 12.25-45.88l-9.13-10a12 12 0 1 1 17.76-16.19ZM99.34 42.31A67.39 67.39 0 0 1 128 36a68.07 68.07 0 0 1 68 68 12 12 0 0 0 24 0A92 92 0 0 0 89.21 20.56a12 12 0 0 0 10.13 21.75Zm65.09 81a11.54 11.54 0 0 0 2.72.32 12 12 0 0 0 11.68-9.29A45.34 45.34 0 0 0 180 104a52.06 52.06 0 0 0-52-52c-1.7 0-3.42.08-5.11.25a12 12 0 0 0 2.33 23.89c.92-.09 1.86-.14 2.78-.14a28 28 0 0 1 28 28 21.66 21.66 0 0 1-.54 4.88 12 12 0 0 0 8.97 14.4Z" /></Svg>;
export default EarSlash;