/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyKzt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-kzt-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-kzt-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206 96a6 6 0 0 1-6 6h-66v114a6 6 0 0 1-12 0V102H56a6 6 0 0 1 0-12h144a6 6 0 0 1 6 6ZM56 62h144a6 6 0 0 0 0-12H56a6 6 0 0 0 0 12Z" /></Svg>;
export default CurrencyKzt;