/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClipboardText = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clipboard-text-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clipboard-text-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 164a12 12 0 0 1-12 12H96a12 12 0 0 1 0-24h64a12 12 0 0 1 12 12Zm-12-52H96a12 12 0 0 0 0 24h64a12 12 0 0 0 0-24Zm60-64v168a20 20 0 0 1-20 20H56a20 20 0 0 1-20-20V48a20 20 0 0 1 20-20h34.53a51.88 51.88 0 0 1 74.94 0H200a20 20 0 0 1 20 20ZM100.29 60h55.42a28 28 0 0 0-55.42 0ZM196 52h-17.41A52.13 52.13 0 0 1 180 64v8a12 12 0 0 1-12 12H88a12 12 0 0 1-12-12v-8a52.13 52.13 0 0 1 1.41-12H60v160h136Z" /></Svg>;
export default ClipboardText;