/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Scan = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scan-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scan-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 40v40a6 6 0 0 1-12 0V46h-34a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6ZM80 210H46v-34a6 6 0 0 0-12 0v40a6 6 0 0 0 6 6h40a6 6 0 0 0 0-12Zm136-40a6 6 0 0 0-6 6v34h-34a6 6 0 0 0 0 12h40a6 6 0 0 0 6-6v-40a6 6 0 0 0-6-6ZM40 86a6 6 0 0 0 6-6V46h34a6 6 0 0 0 0-12H40a6 6 0 0 0-6 6v40a6 6 0 0 0 6 6Zm40-12h96a6 6 0 0 1 6 6v96a6 6 0 0 1-6 6H80a6 6 0 0 1-6-6V80a6 6 0 0 1 6-6Zm6 96h84V86H86Z" /></Svg>;
export default Scan;