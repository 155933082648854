/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyEth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-eth-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-eth-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m219.15 125.53-88-112a4 4 0 0 0-6.3 0l-88 112a4 4 0 0 0 0 4.94l88 112a4 4 0 0 0 6.3 0l88-112a4 4 0 0 0 0-4.94ZM132 27.57l77.71 98.9L132 161.79Zm-8 134.22-77.71-35.32L124 27.57Zm0 8.79v57.85l-70.72-90Zm8 0 70.72-32.15-70.72 90Z" /></Svg>;
export default CurrencyEth;