/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookOpenText = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="book-open-text-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-book-open-text-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 50h-72a38 38 0 0 0-32 17.55A38 38 0 0 0 96 50H24a6 6 0 0 0-6 6v144a6 6 0 0 0 6 6h72a26 26 0 0 1 26 26 6 6 0 0 0 12 0 26 26 0 0 1 26-26h72a6 6 0 0 0 6-6V56a6 6 0 0 0-6-6ZM96 194H30V62h66a26 26 0 0 1 26 26v116.31A37.86 37.86 0 0 0 96 194Zm130 0h-66a37.87 37.87 0 0 0-26 10.32V88a26 26 0 0 1 26-26h66ZM160 90h40a6 6 0 0 1 0 12h-40a6 6 0 0 1 0-12Zm46 38a6 6 0 0 1-6 6h-40a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6Zm0 32a6 6 0 0 1-6 6h-40a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6Z" /></Svg>;
export default BookOpenText;