/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.31 73.37-44.68-44.69a16 16 0 0 0-22.63 0L36.69 152A15.86 15.86 0 0 0 32 163.31V208a16 16 0 0 0 16 16h44.69a15.86 15.86 0 0 0 11.31-4.69L227.31 96a16 16 0 0 0 0-22.63ZM192 108.68 147.31 64l24-24L216 84.68Z" /></Svg>;
export default PencilSimple;