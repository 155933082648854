/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Broom = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="broom-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-broom-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.29 216.7C212.86 205.69 200 182.12 200 152v-17.31a15.94 15.94 0 0 0-10.09-14.87l-28.65-11.46A8 8 0 0 1 156.79 98l22.32-56.67C184 28.79 178 14.21 165.34 9.51a24 24 0 0 0-30.7 13.71l-22.39 56.86a8 8 0 0 1-10.41 4.5l-28.73-11.5a15.91 15.91 0 0 0-17.38 3.66C34.68 98.4 24 123.71 24 152a111.53 111.53 0 0 0 31.15 77.53A8.06 8.06 0 0 0 61 232h171a8 8 0 0 0 8-7.51 8.21 8.21 0 0 0-4.71-7.79Zm-120.18-.7a87.52 87.52 0 0 1-24.26-41.71 8.21 8.21 0 0 0-9.25-6.18 8 8 0 0 0-6.32 9.89 105.33 105.33 0 0 0 18.36 38h-29.2A95.62 95.62 0 0 1 40 152a85.92 85.92 0 0 1 7.73-36.3l137.8 55.13c3 18.06 10.55 33.5 21.89 45.19Z" /></Svg>;
export default Broom;