/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const QuestionMark = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="question-mark__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-question-mark'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 96c0 28.51-24.47 52.11-56 55.56V160a8 8 0 0 1-16 0v-16a8 8 0 0 1 8-8c26.47 0 48-17.94 48-40s-21.53-40-48-40-48 17.94-48 40a8 8 0 0 1-16 0c0-30.88 28.71-56 64-56s64 25.12 64 56Zm-64 96a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default QuestionMark;