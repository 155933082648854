/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FunnelSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="funnel-simple-x-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-funnel-simple-x-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 148H64a12 12 0 0 1 0-24h128a12 12 0 0 1 0 24Zm40-72H24a12 12 0 0 0 0 24h208a12 12 0 0 0 0-24Zm-104 96h-24a12 12 0 0 0 0 24h24a12 12 0 0 0 0-24Zm96.49-4.49a12 12 0 0 0-17 0L192 183l-15.51-15.52a12 12 0 0 0-17 17L175 200l-15.52 15.51a12 12 0 0 0 17 17L192 217l15.51 15.52a12 12 0 0 0 17-17L209 200l15.52-15.51a12 12 0 0 0-.03-16.98Z" /></Svg>;
export default FunnelSimpleX;