/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CrownSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.63 70a19.82 19.82 0 0 0-23.55 4.71l-29.52 31.82-33.34-74.77-.06-.14a20 20 0 0 0-36.32 0l-.06.14-33.34 74.76L46.92 74.7a20 20 0 0 0-34.6 16.81c0 .16.06.31.09.47l22.66 103.78A20 20 0 0 0 54.71 212h146.58a20 20 0 0 0 19.64-16.24L243.59 92c0-.16.07-.31.09-.47A19.82 19.82 0 0 0 232.63 70Zm-34.57 118H57.94l-18.88-86.49 32.14 34.65a12 12 0 0 0 19.8-3.27l37-83.07 37 83.07a12 12 0 0 0 19.76 3.27l32.14-34.65Z" /></Svg>;
export default CrownSimple;