/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Swatches = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="swatches__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-swatches'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 180a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm152-23.81V208a16 16 0 0 1-16 16H76a46.36 46.36 0 0 1-7.94-.68 44 44 0 0 1-35.43-50.95l25-143.13a15.94 15.94 0 0 1 18.47-13L130.84 26a16 16 0 0 1 12.92 18.52l-12.08 69L199.49 89a16 16 0 0 1 20.45 9.52L239 150.69a18.35 18.35 0 0 1 1 5.5Zm-137 28.68 25-143.13L73.46 32l-25 143.1a28 28 0 0 0 22.44 32.47A27.29 27.29 0 0 0 91.46 203 27.84 27.84 0 0 0 103 184.87ZM116.78 195 224 156.11 204.92 104l-76.42 27.7-9.78 55.92a44.63 44.63 0 0 1-1.94 7.38ZM224 173.12 127.74 208H224Z" /></Svg>;
export default Swatches;