/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hurricane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hurricane-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hurricane-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 92a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 48a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm35.37-97 8.14-27.65A12 12 0 0 0 157.89.19a194 194 0 0 0-60.12 23.55C57.36 48.28 36 84.33 36 128a92.15 92.15 0 0 0 56.63 85l-8.14 27.65A12 12 0 0 0 96 256a11.58 11.58 0 0 0 2.11-.19 194 194 0 0 0 60.12-23.55C198.64 207.72 220 171.67 220 128a92.15 92.15 0 0 0-56.63-85Zm-17 168.35a168.74 168.74 0 0 1-32.74 15.28l5.28-18a12 12 0 0 0-8.42-15A68 68 0 0 1 60 128c0-35.12 16.7-63.18 49.65-83.39a168.84 168.84 0 0 1 32.74-15.29l-5.28 18a12 12 0 0 0 8.42 15A68 68 0 0 1 196 128c0 35.12-16.7 63.17-49.65 83.39Z" /></Svg>;
export default Hurricane;