/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandWithdraw = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-withdraw-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-withdraw-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 56h-16V16a8 8 0 0 1 16 0Zm64 67.62V72a16 16 0 0 0-16-16h-48v60.69l18.34-18.35a8 8 0 0 1 11.32 11.32l-32 32a8 8 0 0 1-11.32 0l-32-32a8 8 0 0 1 11.32-11.32L112 116.69V56H64a16 16 0 0 0-16 16v128a8 8 0 0 0 8 8h74.7c.32.67.67 1.34 1.05 2l.24.38 22.26 34a8 8 0 0 0 13.39-8.76l-22.13-33.79A12 12 0 0 1 166.4 190c.07.13.15.26.23.38l10.68 16.31a8 8 0 0 0 14.69-4.38V144a74.84 74.84 0 0 1 24 54.69V240a8 8 0 0 0 16 0v-41.35a90.89 90.89 0 0 0-40-75.03Z" /></Svg>;
export default HandWithdraw;