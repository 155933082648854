/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Printer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="printer-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-printer-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M214.67 76H196V40a4 4 0 0 0-4-4H64a4 4 0 0 0-4 4v36H41.33C29.57 76 20 85 20 96v80a4 4 0 0 0 4 4h36v36a4 4 0 0 0 4 4h128a4 4 0 0 0 4-4v-36h36a4 4 0 0 0 4-4V96c0-11-9.57-20-21.33-20ZM68 44h120v32H68Zm120 168H68v-56h120Zm40-40h-32v-20a4 4 0 0 0-4-4H64a4 4 0 0 0-4 4v20H28V96c0-6.62 6-12 13.33-12h173.34C222 84 228 89.38 228 96Zm-32-56a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default Printer;