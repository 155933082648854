/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BaseballHelmet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baseball-helmet-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baseball-helmet-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 132a24 24 0 1 0 24 24 24 24 0 0 0-24-24Zm0 40a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm160-48h-28.08A100 100 0 0 0 20 128v24a68.07 68.07 0 0 0 68 68h40a68.07 68.07 0 0 0 68-68v-20h52a4 4 0 0 0 0-8Zm-60 28a60.07 60.07 0 0 1-60 60h-8a68.07 68.07 0 0 0 36-60v-20h32Zm-36-28a4 4 0 0 0-4 4v24a60 60 0 0 1-120 0v-24a92 92 0 0 1 183.91-4Z" /></Svg>;
export default BaseballHelmet;