/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RssSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM76 192a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm60 0a8 8 0 0 1-8-8 56.06 56.06 0 0 0-56-56 8 8 0 0 1 0-16 72.08 72.08 0 0 1 72 72 8 8 0 0 1-8 8Zm48 0a8 8 0 0 1-8-8A104.11 104.11 0 0 0 72 80a8 8 0 0 1 0-16 120.13 120.13 0 0 1 120 120 8 8 0 0 1-8 8Z" /></Svg>;
export default RssSimple;