/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-medium-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-medium-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 132a12 12 0 0 1-12 12H24a12 12 0 0 1 0-24h4V68h-4a12 12 0 0 1 0-24h16a12 12 0 0 1 10 5.33l30 45 30-45A12 12 0 0 1 120 44h16a12 12 0 0 1 0 24h-4v52h4a12 12 0 0 1 0 24h-20a12 12 0 0 1-8-20.93V95.63l-18 27a12 12 0 0 1-20 0l-18-27v27.44a12 12 0 0 1 4 8.93Zm120-28h56a12 12 0 0 0 0-24h-56a12 12 0 0 0 0 24Zm56 16h-56a12 12 0 0 0 0 24h56a12 12 0 0 0 0-24Zm0 40H80a12 12 0 0 0 0 24h152a12 12 0 0 0 0-24Zm0 40H80a12 12 0 0 0 0 24h152a12 12 0 0 0 0-24Z" /></Svg>;
export default ArticleMedium;