/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fire = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M143.38 17.85a8 8 0 0 0-12.63 3.41l-22 60.41-24.16-23.41a8 8 0 0 0-11.93.89C51 87.53 40 116.08 40 144a88 88 0 0 0 176 0c0-59.45-50.79-108-72.62-126.15Zm40.51 135.49a57.6 57.6 0 0 1-46.56 46.55 7.65 7.65 0 0 1-1.33.11 8 8 0 0 1-1.32-15.89c16.57-2.79 30.63-16.85 33.44-33.45a8 8 0 0 1 15.78 2.68Z" /></Svg>;
export default Fire;