/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CubeFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cube-focus-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cube-focus-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 96v64l-56 32-56-32V96l56-32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 48v40a8 8 0 0 1-16 0V56h-32a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8ZM72 200H40v-32a8 8 0 0 0-16 0v40a8 8 0 0 0 8 8h40a8 8 0 0 0 0-16Zm152-40a8 8 0 0 0-8 8v32h-32a8 8 0 0 0 0 16h40a8 8 0 0 0 8-8v-40a8 8 0 0 0-8-8ZM32 96a8 8 0 0 0 8-8V56h32a8 8 0 0 0 0-16H32a8 8 0 0 0-8 8v40a8 8 0 0 0 8 8Zm156 71-56 32a8 8 0 0 1-7.94 0L68 167a8 8 0 0 1-4-7V96a8 8 0 0 1 4-7l56-32a8 8 0 0 1 7.94 0l56 32a8 8 0 0 1 4 7v64a8 8 0 0 1-3.94 7ZM88.12 96 128 118.79 167.88 96 128 73.21ZM80 155.36l40 22.85v-45.57l-40-22.85Zm96 0v-45.57l-40 22.85v45.57Z" /></Svg>;
export default CubeFocus;