/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ghost = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ghost-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ghost-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M110 116a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm46-10a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm66 14v96a6 6 0 0 1-9.8 4.64l-25.53-20.89-25.54 20.89a6 6 0 0 1-7.6 0L128 199.75l-25.53 20.89a6 6 0 0 1-7.6 0l-25.54-20.89-25.53 20.89A6 6 0 0 1 34 216v-96a94 94 0 0 1 188 0Zm-12 0a82 82 0 0 0-164 0v83.34l19.53-16a6 6 0 0 1 7.6 0l25.54 20.89 25.53-20.89a6 6 0 0 1 7.6 0l25.53 20.89 25.54-20.89a6 6 0 0 1 7.6 0l19.53 16Z" /></Svg>;
export default Ghost;