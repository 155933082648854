/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-line-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-line-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.32 73.37-44.69-44.68a16 16 0 0 0-22.63 0L36.69 152A15.86 15.86 0 0 0 32 163.31V208a16 16 0 0 0 16 16h168a8 8 0 0 0 0-16H115.32l112-112a16 16 0 0 0 0-22.63ZM79.32 188l90.34-90.34 16.69 16.68L96 204.69Zm79-101.66L68 176.69 51.31 160l90.35-90.34ZM48 179.31 76.69 208H48Z" /></Svg>;
export default PencilLine;