/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListMagnifyingGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-magnifying-glass-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-magnifying-glass-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M36 64a4 4 0 0 1 4-4h176a4 4 0 0 1 0 8H40a4 4 0 0 1-4-4Zm4 68h72a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8Zm88 56H40a4 4 0 0 0 0 8h88a4 4 0 0 0 0-8Zm106.83 6.83a4 4 0 0 1-5.66 0l-22.72-22.72a36.06 36.06 0 1 1 5.66-5.66l22.72 22.72a4 4 0 0 1 0 5.66ZM184 172a28 28 0 1 0-28-28 28 28 0 0 0 28 28Z" /></Svg>;
export default ListMagnifyingGlass;