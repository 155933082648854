/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilePy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-py-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-py-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m213.66 82.34-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v76a4 4 0 0 0 4 4h128a4 4 0 0 1 4 4v104a4 4 0 0 0 4 4h20a16 16 0 0 0 16-16V88a8 8 0 0 0-2.34-5.66ZM152 88V44l44 44Zm-88 56H48a8 8 0 0 0-8 8v55.73a8.17 8.17 0 0 0 7.47 8.27 8 8 0 0 0 8.53-8v-8h7.4c15.24 0 28.14-11.92 28.59-27.15A28 28 0 0 0 64 144Zm-.35 40H56v-24h8a12 12 0 0 1 12 13.16A12.25 12.25 0 0 1 63.65 184Zm91-27.48L136 186.29v21.44a8.17 8.17 0 0 1-7.47 8.25 8 8 0 0 1-8.53-8v-21.69l-18.61-29.77a8.22 8.22 0 0 1 2.16-11.17 8 8 0 0 1 11.23 2.41L128 168.91l13.22-21.15a8 8 0 0 1 11.23-2.41 8.22 8.22 0 0 1 2.16 11.17Z" /></Svg>;
export default FilePy;