/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cookie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cookie-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cookie-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M161.66 166.34a8 8 0 1 1-11.32 0 8 8 0 0 1 11.32 0Zm-75.32-8a8 8 0 1 0 11.32 0 8 8 0 0 0-11.32 0Zm3.32-56a8 8 0 1 0 0 11.32 8 8 0 0 0 0-11.32Zm36.68 16a8 8 0 1 0 11.32 0 8 8 0 0 0-11.32 0ZM228 128A100 100 0 1 1 128 28a4 4 0 0 1 4 4 44.05 44.05 0 0 0 44 44 4 4 0 0 1 4 4 44.05 44.05 0 0 0 44 44 4 4 0 0 1 4 4Zm-8.08 3.84a52.08 52.08 0 0 1-47.78-48 52.08 52.08 0 0 1-48-47.78 92 92 0 1 0 95.76 95.76Z" /></Svg>;
export default Cookie;