/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WheelchairMotion = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wheelchair-motion-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wheelchair-motion-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 84a36 36 0 1 0-36-36 36 36 0 0 0 36 36Zm0-48a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm29.28 96.39a12 12 0 0 1 2.49 10l-16 80A12 12 0 0 1 180 232a11.87 11.87 0 0 1-2.36-.23 12 12 0 0 1-9.42-14.12L181.36 152H124a12 12 0 0 1-10.4-18l17.92-31.2a76.1 76.1 0 0 0-79.88 10.55 12 12 0 0 1-15.28-18.49 100 100 0 0 1 118.19-6.68 12 12 0 0 1 3.85 16L144.74 128H196a12 12 0 0 1 9.28 4.39Zm-41.47 44.52a64 64 0 1 1-73.64-68.16 12 12 0 0 1 3.66 23.72 40 40 0 1 0 46.05 42.62 12 12 0 1 1 23.93 1.82Z" /></Svg>;
export default WheelchairMotion;