/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Log = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="log-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-log-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 136a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm36 0c0 42.62-22.84 76-52 76H56c-29.16 0-52-33.38-52-76s22.84-76 52-76h35l36.48-36.49A12 12 0 0 1 136 20h32a12 12 0 0 1 0 24h-27l-16 16h75c29.16 0 52 33.38 52 76ZM56 188h105.77a83.3 83.3 0 0 1-9.09-20H80a12 12 0 0 1 0-24h68.27c-.18-2.63-.27-5.29-.27-8 0-20.3 5.19-38.5 13.77-52H56c-7.85 0-16.23 7.51-21.76 20H104a12 12 0 0 1 0 24H28.35a90.3 90.3 0 0 0-.35 8c0 30.64 14.76 52 28 52Zm172-52c0-30.64-14.76-52-28-52s-28 21.36-28 52 14.76 52 28 52 28-21.36 28-52Z" /></Svg>;
export default Log;