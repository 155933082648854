/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListPlus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-plus-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-plus-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 64v128H40V64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M32 64a8 8 0 0 1 8-8h176a8 8 0 0 1 0 16H40a8 8 0 0 1-8-8Zm8 72h176a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16Zm104 48H40a8 8 0 0 0 0 16h104a8 8 0 0 0 0-16Zm88 0h-16v-16a8 8 0 0 0-16 0v16h-16a8 8 0 0 0 0 16h16v16a8 8 0 0 0 16 0v-16h16a8 8 0 0 0 0-16Z" /></Svg>;
export default ListPlus;