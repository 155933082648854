/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-circle__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-circle'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88ZM112 80a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm72 32a8 8 0 0 1-8 8h-40v13.58l30.66 46a8 8 0 0 1-13.32 8.88l-25.34-38-25.34 38a8 8 0 1 1-13.32-8.88l30.66-46V120H80a8 8 0 0 1 0-16h96a8 8 0 0 1 8 8Z" /></Svg>;
export default PersonSimpleCircle;