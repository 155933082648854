/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-four-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-four-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 156h-12V48a12 12 0 0 0-21.37-7.5l-96 120A12 12 0 0 0 64 180h84v28a12 12 0 0 0 24 0v-28h12a12 12 0 0 0 0-24Zm-36 0H89l59-73.79Z" /></Svg>;
export default NumberFour;