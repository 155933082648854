/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsCounterClockwise = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-counter-clockwise-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-counter-clockwise-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 102H40a6 6 0 0 1-6-6V48a6 6 0 0 1 12 0v33.52l18-18a93.45 93.45 0 0 1 66-27.53h.52a93.39 93.39 0 0 1 65.67 26.71 6 6 0 0 1-8.38 8.58A82 82 0 0 0 72.53 72L54.48 90H88a6 6 0 0 1 0 12Zm128 52h-48a6 6 0 0 0 0 12h33.52l-18.05 18a81.51 81.51 0 0 1-57.53 24h-.46a81.5 81.5 0 0 1-57.29-23.34 6 6 0 0 0-8.38 8.58A93.39 93.39 0 0 0 125.48 220h.52a93.45 93.45 0 0 0 66-27.53l18-18.05V208a6 6 0 0 0 12 0v-48a6 6 0 0 0-6-6Z" /></Svg>;
export default ArrowsCounterClockwise;