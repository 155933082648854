/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinkSimpleHorizontalBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="link-simple-horizontal-break-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-link-simple-horizontal-break-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 128a48 48 0 0 1-48 48H64a48 48 0 0 1-48-48 48 48 0 0 1 48-48h128a48 48 0 0 1 48 48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M24 128a40 40 0 0 0 40 40h40a8 8 0 0 1 0 16H64a56 56 0 0 1 0-112h40a8 8 0 0 1 0 16H64a40 40 0 0 0-40 40Zm168-56h-40a8 8 0 0 0 0 16h40a40 40 0 0 1 0 80h-40a8 8 0 0 0 0 16h40a56 56 0 0 0 0-112Z" /></Svg>;
export default LinkSimpleHorizontalBreak;