/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThreeD = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="three-d-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-three-d-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 148a24 24 0 0 0-24-24 4 4 0 0 1-3.28-6.29L96.32 84H56a4 4 0 0 1 0-8h48a4 4 0 0 1 3.28 6.29L83.12 116.8a32 32 0 1 1-30 53.6 4 4 0 1 1 5.72-5.6A24 24 0 0 0 100 148Zm60-72a52 52 0 0 1 0 104h-24a4 4 0 0 1-4-4V80a4 4 0 0 1 4-4Zm0 8h-20v88h20a44 44 0 0 0 0-88ZM32 52h192a4 4 0 0 0 0-8H32a4 4 0 0 0 0 8Zm192 152H32a4 4 0 0 0 0 8h192a4 4 0 0 0 0-8Z" /></Svg>;
export default ThreeD;