/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Barn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="barn-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-barn-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 84a12 12 0 0 1 12-12h32a12 12 0 0 1 0 24h-32a12 12 0 0 1-12-12Zm152 116a12 12 0 0 1-12 12H16a12 12 0 0 1 0-24h4v-48.69A12 12 0 0 1 6.24 121l40-56a11.91 11.91 0 0 1 2.94-2.9l67.56-46.65.17-.11a19.94 19.94 0 0 1 22.18 0l.17.11 67.56 46.65a11.91 11.91 0 0 1 2.94 2.9l40 56A12 12 0 0 1 236 139.31V188h4a12 12 0 0 1 12 12ZM44 188h20v-64a12 12 0 0 1 12-12h104a12 12 0 0 1 12 12v64h20v-78.55l-20.55-28.76L128 36.87 64.55 80.69 44 109.45Zm124-52h-54.1l54.1 38.07Zm-80 52h58.1L88 147.12Z" /></Svg>;
export default Barn;