/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hurricane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hurricane-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hurricane-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 100a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm25.49-100.06L163.87 9a4 4 0 0 0-4.71-4.94 191.41 191.41 0 0 0-57.32 24.54C64 53 44 87.32 44 128a84.08 84.08 0 0 0 58.51 80.06L92.13 247a4 4 0 0 0 4.71 4.94 191.28 191.28 0 0 0 57.32-24.55C192 203 212 168.68 212 128a84.08 84.08 0 0 0-58.51-80.06ZM150 220.51a190.13 190.13 0 0 1-48.39 21.87l9.61-36a4 4 0 0 0-2.84-4.9A76.06 76.06 0 0 1 52 128c0-38.29 18.16-69.41 54-92.51a190.13 190.13 0 0 1 48.39-21.87l-9.61 36a4 4 0 0 0 2.84 4.9A76.06 76.06 0 0 1 204 128c0 38.29-18.16 69.41-54 92.51Z" /></Svg>;
export default Hurricane;