/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Microscope = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microscope__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microscope'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 208h-20.06A88.05 88.05 0 0 0 144 64.37V32a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v104a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V80.46A72 72 0 0 1 181.25 208H32a8 8 0 0 0 0 16h192a8 8 0 0 0 0-16Zm-96-72H80V32h48v104Zm-56 48a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Z" /></Svg>;
export default Microscope;