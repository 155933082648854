/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BracketsAngle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="brackets-angle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-brackets-angle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M90.12 46.44 38.22 128l51.9 81.56a12 12 0 1 1-20.24 12.88l-56-88a12 12 0 0 1 0-12.88l56-88a12 12 0 0 1 20.24 12.88Zm152 75.12-56-88a12 12 0 1 0-20.24 12.88l51.9 81.56-51.9 81.56a12 12 0 1 0 20.24 12.88l56-88a12 12 0 0 0 0-12.88Z" /></Svg>;
export default BracketsAngle;