/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSubscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-subscript-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-subscript-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 72v136H56a16 16 0 0 1-16-16V56h184a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M248 208a8 8 0 0 1-8 8h-48a8 8 0 0 1-6.4-12.8l43.17-57.56a16 16 0 1 0-27.86-15 8 8 0 0 1-15.09-5.34 32.18 32.18 0 0 1 4.63-8.59 32 32 0 0 1 51.11 38.52L208 200h32a8 8 0 0 1 8 8ZM149.24 50a8 8 0 0 0-11.29.81L92 103.78l-45.95-53A8 8 0 0 0 34 61.24L81.41 116 34 170.76a8 8 0 0 0 12.1 10.48l46-53 45.95 53a8 8 0 1 0 12.1-10.48L102.59 116l47.46-54.76a8 8 0 0 0-.81-11.24Z" /></Svg>;
export default TextSubscript;