/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Mosque = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mosque-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mosque-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 130a21.84 21.84 0 0 0-10 2.41V128c0-40.71-29.31-60.22-52.87-75.9C146.57 42.41 134 34 134 24a6 6 0 0 0-12 0c0 10-12.57 18.41-27.13 28.1C71.31 67.78 42 87.29 42 128v4.41A22 22 0 0 0 10 152v56a6 6 0 0 0 6 6h64a6 6 0 0 0 6-6v-32a10 10 0 0 1 20 0v32a6 6 0 0 0 6 6h32a6 6 0 0 0 6-6v-32a10 10 0 0 1 20 0v32a6 6 0 0 0 6 6h64a6 6 0 0 0 6-6v-56a22 22 0 0 0-22-22ZM101.52 62.09c10.37-6.9 20.38-13.56 26.48-21.57 6.1 8 16.11 14.67 26.48 21.57C175.41 76 199 91.71 201.73 122H54.27C57 91.71 80.59 76 101.52 62.09ZM22 152a10 10 0 0 1 20 0v50H22Zm138 2a22 22 0 0 0-22 22v26h-20v-26a22 22 0 0 0-44 0v26H54v-68h148v68h-20v-26a22 22 0 0 0-22-22Zm74 48h-20v-50a10 10 0 0 1 20 0Z" /></Svg>;
export default Mosque;