/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerHifi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-hifi-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-hifi-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 28H64a12 12 0 0 0-12 12v176a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12V40a12 12 0 0 0-12-12Zm4 188a4 4 0 0 1-4 4H64a4 4 0 0 1-4-4V40a4 4 0 0 1 4-4h128a4 4 0 0 1 4 4ZM120 76a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm8 40a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default SpeakerHifi;