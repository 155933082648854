/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Funnel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="funnel-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-funnel-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227 51.15A11.85 11.85 0 0 0 216 44H40a12 12 0 0 0-8.88 20.07l.05.05L100 137.59V216a12 12 0 0 0 18.66 10l32-21.33a12 12 0 0 0 5.35-10v-57.08l68.86-73.52A11.85 11.85 0 0 0 227 51.15Zm-8 7.5-69.9 74.62A4 4 0 0 0 148 136v58.65a4 4 0 0 1-1.78 3.33l-32 21.33A4 4 0 0 1 108 216v-80a4 4 0 0 0-1.08-2.74L37.05 58.67A4 4 0 0 1 40 52h176a4 4 0 0 1 3 6.65Z" /></Svg>;
export default Funnel;