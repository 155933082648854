/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCSharp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-c-sharp-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-c-sharp-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m216.49 79.51-56-56A12 12 0 0 0 152 20H56a20 20 0 0 0-20 20v68a12 12 0 0 0 24 0V44h76v48a12 12 0 0 0 12 12h48v120a12 12 0 0 0 24 0V88a12 12 0 0 0-3.51-8.49ZM160 57l23 23h-23ZM40 180c0 8.67 5.5 16 12 16a10.27 10.27 0 0 0 7.33-3.43 12 12 0 1 1 17.34 16.6A34 34 0 0 1 52 220c-19.85 0-36-17.94-36-40s16.15-40 36-40a34 34 0 0 1 24.67 10.83 12 12 0 1 1-17.34 16.6A10.27 10.27 0 0 0 52 164c-6.5 0-12 7.33-12 16Zm132-8a12 12 0 0 0 0-24h-4v-4a12 12 0 0 0-24 0v4h-12v-4a12 12 0 0 0-24 0v4h-4a12 12 0 0 0 0 24h4v12h-4a12 12 0 0 0 0 24h4v4a12 12 0 0 0 24 0v-4h12v4a12 12 0 0 0 24 0v-4h4a12 12 0 0 0 0-24h-4v-12Zm-28 12h-12v-12h12Z" /></Svg>;
export default FileCSharp;