/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StandardDefinition = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="standard-definition-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-standard-definition-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 74a6 6 0 0 0-6 6v96a6 6 0 0 0 6 6h24a54 54 0 0 0 0-108Zm66 54a42 42 0 0 1-42 42h-18V86h18a42 42 0 0 1 42 42ZM26 48a6 6 0 0 1 6-6h192a6 6 0 0 1 0 12H32a6 6 0 0 1-6-6Zm204 160a6 6 0 0 1-6 6H32a6 6 0 0 1 0-12h192a6 6 0 0 1 6 6Zm-124-56c0-11.21-10.7-15.1-28.33-20.18-15.89-4.58-33.89-9.77-33.89-27.82 0-17.1 15.57-30 36.22-30 15 0 27.74 6.88 33.34 18a6 6 0 0 1-10.71 5.42C99.08 90.36 90.41 86 80 86c-13.81 0-24.22 7.74-24.22 18 0 8.41 9.52 11.76 25.21 16.29C97.48 125 118 131 118 152c0 16.54-17 30-38 30s-38-13.46-38-30a6 6 0 0 1 12 0c0 9.76 11.91 18 26 18s26-8.24 26-18Z" /></Svg>;
export default StandardDefinition;