/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Microphone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 172a44.05 44.05 0 0 0 44-44V64a44 44 0 0 0-88 0v64a44.05 44.05 0 0 0 44 44ZM92 64a36 36 0 0 1 72 0v64a36 36 0 0 1-72 0Zm40 139.89V240a4 4 0 0 1-8 0v-36.11A76.09 76.09 0 0 1 52 128a4 4 0 0 1 8 0 68 68 0 0 0 136 0 4 4 0 0 1 8 0 76.09 76.09 0 0 1-72 75.89Z" /></Svg>;
export default Microphone;