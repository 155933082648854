/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileRs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-rs-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-rs-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m216.49 79.51-56-56A12 12 0 0 0 152 20H56a20 20 0 0 0-20 20v68a12 12 0 0 0 24 0V44h76v48a12 12 0 0 0 12 12h48v108h-8a12 12 0 0 0 0 24h12a20 20 0 0 0 20-20V88a12 12 0 0 0-3.51-8.49ZM160 57l23 23h-23Zm-96 83H48a12 12 0 0 0-12 12v56a12 12 0 0 0 24 0v-4h4a33.9 33.9 0 0 0 3.65-.21l6 10.26A12 12 0 0 0 94.37 202l-5.64-9.66A32 32 0 0 0 64 140Zm-4 24h4a8 8 0 0 1 0 16h-4Zm103.78 32.82a24.75 24.75 0 0 1-10.95 18.06c-6 4-13.27 5.15-19.73 5.15a63.75 63.75 0 0 1-16.23-2.21 12 12 0 0 1 6.46-23.12c6.81 1.86 15 1.61 16.39.06a2.48 2.48 0 0 0 .21-.71c-1.94-1.23-6.83-2.64-9.88-3.52-5.39-1.56-11-3.18-15.75-6.27-7.62-4.92-11.21-12.45-10.11-21.2a24.45 24.45 0 0 1 10.69-17.75c6.06-4.09 14.17-5.83 24.1-5.18A68.53 68.53 0 0 1 151 142a12 12 0 0 1-6.1 23.21c-6.36-1.63-13.62-1.51-16.07-.33a79.5 79.5 0 0 0 7.91 2.59c5.48 1.58 11.68 3.37 16.8 6.82 7.79 5.26 11.46 13.26 10.24 22.53Z" /></Svg>;
export default FileRs;