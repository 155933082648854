/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BabyCarriage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-carriage-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-carriage-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M215.58 54.55a7.53 7.53 0 0 0-1.32-1.27A79.68 79.68 0 0 0 160 32h-8a16 16 0 0 0-16 16v56H55.2A40.07 40.07 0 0 0 16 72a8 8 0 0 0 0 16 24 24 0 0 1 24 24 80.09 80.09 0 0 0 80 80h40a79.94 79.94 0 0 0 55.58-137.45Zm-6.91 16A63.73 63.73 0 0 1 223.48 104h-56.67ZM160 48a63.59 63.59 0 0 1 36.69 11.61L152 95.35V48Zm-56 176a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm104 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default BabyCarriage;