/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l29.52 32.51a139.32 139.32 0 0 0-47.27 31.08c-19.25 19.26-21.11 47.65-4.52 69a14 14 0 0 0 16.1 4.41l49-17.37.22-.09a13.93 13.93 0 0 0 8.53-10.25l5.9-29.51a2 2 0 0 1 1.21-1.47 80.07 80.07 0 0 1 10-2.75L203.56 220a6 6 0 0 0 8.88-8.08Zm45.85 75a14 14 0 0 0-9 10.45L83.37 151a2 2 0 0 1-1.15 1.43l-48.94 17.34-.22.08a2 2 0 0 1-2.29-.58c-13-16.73-11.56-38.11 3.53-53.19A127.91 127.91 0 0 1 81.75 86l21.34 23.48c-1.62.52-3.22.99-4.8 1.52Zm136.42 65.61a14 14 0 0 1-16.1 4.41l-9.28-3.29a6 6 0 1 1 4-11.31l9.38 3.33.22.08a2 2 0 0 0 2.29-.58c13-16.73 11.56-38.11-3.53-53.19C195.64 90 158.86 76.2 120.83 78.19a6 6 0 1 1-.63-12c41.44-2.15 81.52 12.93 110 41.39 19.24 19.27 21.1 47.66 4.51 69.05Z" /></Svg>;
export default PhoneSlash;