/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatCircleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-circle-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-circle-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14l7.81 8.59a108 108 0 0 0-15.08 120.57L21 209.66A20 20 0 0 0 46.34 235l32.43-10.81a108.08 108.08 0 0 0 112.55-8.66l7.8 8.58a12 12 0 0 0 17.76-16.14ZM128 212a83.91 83.91 0 0 1-42-11.27 12 12 0 0 0-9.82-1l-29.79 9.93 9.93-29.79a12 12 0 0 0-1-9.82 84 84 0 0 1 7.94-95.49l111.84 123A83.83 83.83 0 0 1 128 212Zm108-84a107.22 107.22 0 0 1-8.65 42.4 12 12 0 0 1-22.07-9.4A84.07 84.07 0 0 0 102.51 48a12 12 0 0 1-7.27-22.87A108.08 108.08 0 0 1 236 128Z" /></Svg>;
export default ChatCircleSlash;