/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Axe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="axe-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-axe-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M254.93 96.43A20 20 0 0 0 238.56 83a122 122 0 0 1-39.86-12.46l3.92-3.92a32 32 0 0 0-45.26-45.26l-6.69 6.69a20 20 0 0 0-30.15-2.21L81.84 64.52a20 20 0 0 0 2.21 30.16l-74.69 74.7a32 32 0 0 0 45.25 45.26l71.92-71.93A122 122 0 0 1 139 182.57a20 20 0 0 0 34 11.58L250.13 117a19.86 19.86 0 0 0 4.8-20.57Zm-80.6-58.1a8 8 0 1 1 11.32 11.32l-7.58 7.57a135.65 135.65 0 0 1-11.94-10.68ZM37.64 197.67a8 8 0 0 1-11.31-11.32l76.21-76.21a135.65 135.65 0 0 1 10.68 11.94Zm123.88-26c-9-48.5-39.27-78.44-59.38-93.47l32-32c15 20.11 45 50.34 93.45 59.38Z" /></Svg>;
export default Axe;