/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleHike = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-hike__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-hike'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 80a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm0-48a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm48 112v88a8 8 0 0 1-16 0v-80.34c-25.75-2.25-34.35-15.52-42-27.36-2.85-4.39-5.56-8.57-9.13-12.19l-13.4 30.81 37.2 26.57A8 8 0 0 1 160 176v56a8 8 0 0 1-16 0v-51.88l-31.07-22.2-33.59 77.27A8 8 0 0 1 72 240a7.84 7.84 0 0 1-3.19-.67 8 8 0 0 1-4.14-10.52L122.19 96.5a8 8 0 0 1 11-3.92 40.92 40.92 0 0 1 8 5.47c6.37 5.52 10.51 11.91 14.16 17.55 7.68 11.84 13.22 20.4 36.6 20.4a8 8 0 0 1 8.05 8Zm-128 8a8 8 0 0 0 7.35-4.85l24-56a8 8 0 0 0-4.2-10.5l-28-12a8 8 0 0 0-10.5 4.2l-24 56a8 8 0 0 0 4.2 10.5l28 12A8 8 0 0 0 72 152Zm-17.49-24.2L72.2 86.5l13.3 5.7-17.7 41.29Z" /></Svg>;
export default PersonSimpleHike;