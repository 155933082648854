/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Atom = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="atom-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-atom-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M193.83 128a195.73 195.73 0 0 0 19.9-33.65c10.74-23.88 11-42.66.8-52.88s-29-9.94-52.88.8A195.73 195.73 0 0 0 128 62.17a195.73 195.73 0 0 0-33.65-19.9c-23.88-10.74-42.66-11-52.88-.8s-9.94 29 .8 52.88A195.73 195.73 0 0 0 62.17 128a195.73 195.73 0 0 0-19.9 33.65c-10.74 23.88-11 42.66-.8 52.88 5 5 12 7.47 20.63 7.47 9.1 0 20-2.76 32.25-8.27a195.73 195.73 0 0 0 33.65-19.9 195.73 195.73 0 0 0 33.65 19.9c12.25 5.51 23.15 8.27 32.25 8.27 8.64 0 15.65-2.49 20.63-7.47 10.23-10.22 9.94-29-.8-52.88a195.73 195.73 0 0 0-19.9-33.65ZM206 50c9.28 9.28 2.36 36.29-19.8 68a306.2 306.2 0 0 0-22.78-25.45A306.2 306.2 0 0 0 138 69.76c31.75-22.15 58.77-29.08 68-19.76Zm-27.19 78A289.17 289.17 0 0 1 155 155a289.17 289.17 0 0 1-27 23.88A289.17 289.17 0 0 1 101 155a290.62 290.62 0 0 1-23.88-27A297.06 297.06 0 0 1 128 77.14 290.74 290.74 0 0 1 155 101a289.17 289.17 0 0 1 23.85 27ZM50 50c2.68-2.69 6.84-4 12.17-4 13.11 0 33.3 8 55.87 23.81a302.94 302.94 0 0 0-25.5 22.73A306.2 306.2 0 0 0 69.76 118C47.6 86.25 40.68 59.24 50 50Zm0 156c-9.28-9.28-2.35-36.29 19.8-68a306.2 306.2 0 0 0 22.78 25.45A306.2 306.2 0 0 0 118 186.24C86.25 208.4 59.24 215.32 50 206Zm156 0c-9.28 9.28-36.29 2.35-68-19.81a304.26 304.26 0 0 0 25.45-22.77A306.2 306.2 0 0 0 186.24 138c22.16 31.75 29.08 58.76 19.76 68Zm-68-78a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default Atom;