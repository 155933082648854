/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M210.46 20.85a4 4 0 0 0-3.43-.73l-128 32A4 4 0 0 0 76 56v118.87A32 32 0 1 0 84 196v-88.88l120-30v65.75a32 32 0 1 0 8 21.13V24a4 4 0 0 0-1.54-3.15ZM52 220a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm128-32a24 24 0 1 1 24-24 24 24 0 0 1-24 24ZM84 98.88V59.12l120-30v39.76Z" /></Svg>;
export default MusicNotes;