/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserList = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-list-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-list-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M154 80a6 6 0 0 1 6-6h88a6 6 0 0 1 0 12h-88a6 6 0 0 1-6-6Zm94 42h-88a6 6 0 0 0 0 12h88a6 6 0 0 0 0-12Zm0 48h-64a6 6 0 0 0 0 12h64a6 6 0 0 0 0-12Zm-98.19 20.5a6 6 0 1 1-11.62 3C131.7 168.29 107.23 150 80 150s-51.7 18.29-58.19 43.49a6 6 0 1 1-11.62-3c5.74-22.28 23-40.07 44.67-48a46 46 0 1 1 50.28 0c21.65 7.94 38.94 25.73 44.67 48.01ZM80 138a34 34 0 1 0-34-34 34 34 0 0 0 34 34Z" /></Svg>;
export default UserList;