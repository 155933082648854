/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CourtBasketball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="court-basketball-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-court-basketball-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 44H32a20 20 0 0 0-20 20v128a20 20 0 0 0 20 20h192a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20Zm-4 112h-4a28 28 0 0 1 0-56h4ZM36 100h4a28 28 0 0 1 0 56h-4Zm0 80h4a52 52 0 0 0 0-104h-4v-8h80v120H36Zm104 8V68h80v8h-4a52 52 0 0 0 0 104h4v8Z" /></Svg>;
export default CourtBasketball;