/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-dashed-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-dashed-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100.13 36a4 4 0 0 1 2.87-4.83 100.09 100.09 0 0 1 50 0 4 4 0 0 1-1 7.88 4.36 4.36 0 0 1-1-.13 92 92 0 0 0-46 0 4 4 0 0 1-4.87-2.92Zm-65.71 70.16a4 4 0 0 0 4.92-2.79 91.92 91.92 0 0 1 23-39.82 4 4 0 0 0-5.71-5.61 100.15 100.15 0 0 0-25 43.3 4 4 0 0 0 2.79 4.92ZM151 217.09a92 92 0 0 1-46 0 4 4 0 0 0-2 7.75 100 100 0 0 0 50 0 4 4 0 1 0-2-7.74Zm65.65-113.71a4 4 0 1 0 7.7-2.14 100.08 100.08 0 0 0-25-43.31 4 4 0 1 0-5.71 5.61 92 92 0 0 1 23.01 39.84Zm7.73 51.39a99.77 99.77 0 0 1-29.5 47.57 4 4 0 0 1-6.24-1.16 68 68 0 0 0-121.28 0 4 4 0 0 1-2.83 2.12 3.61 3.61 0 0 1-.73.07 4 4 0 0 1-2.68-1 99.8 99.8 0 0 1-29.5-47.58 4 4 0 1 1 7.71-2.13 91.89 91.89 0 0 0 23.47 40.25 75.66 75.66 0 0 1 44.54-34.08 44 44 0 1 1 41.32 0 75.66 75.66 0 0 1 44.54 34.08 91.83 91.83 0 0 0 23.47-40.28 4 4 0 0 1 7.71 2.14ZM128 156a36 36 0 1 0-36-36 36 36 0 0 0 36 36Z" /></Svg>;
export default UserCircleDashed;