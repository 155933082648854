/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-user-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-user-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 72v43.06l36.42-18.22a8 8 0 1 1 7.16 14.32l-48 24A8 8 0 0 1 120 128V72a8 8 0 0 1 16 0Zm-8 144a88 88 0 1 1 88-88 8 8 0 0 0 16 0 104 104 0 1 0-104 104 8 8 0 0 0 0-16Zm86.62-17.38a32 32 0 1 0-45.24 0A40 40 0 0 0 152.27 222a8 8 0 0 0 7.73 10h64a8 8 0 0 0 7.73-10.06 40 40 0 0 0-17.11-23.32Z" /></Svg>;
export default ClockUser;