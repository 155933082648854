/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Images = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="images-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-images-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H72a16 16 0 0 0-16 16v16H40a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16v-16h16a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm-44 32a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm12 128H40V88h16v80a16 16 0 0 0 16 16h112Zm32-32H72v-47.31l30.34-30.35a8 8 0 0 1 11.32 0L163.31 140 189 114.34a8 8 0 0 1 11.31 0L216 130.07V168Z" /></Svg>;
export default Images;