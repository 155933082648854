/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Motorcycle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="motorcycle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-motorcycle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 112h-1.69l-3.5-11.8c1.71-.15 3.44-.24 5.19-.24a12 12 0 0 0 0-24h-12.37l-8.12-27.41A12 12 0 0 0 176 40h-32a12 12 0 0 0 0 24h23l3.56 12H152c-19.44 0-35.32 4.62-45.92 13.37a12 12 0 0 1-12.56 1.52c-13.13-6-57.19-25-61.65-26.89l-5.27-2.24S22.4 60 20 60a12 12 0 0 0-4.61 23.08c1.72.73 43.67 18.5 68.2 29.66a36.25 36.25 0 0 0 15 3.23 35.78 35.78 0 0 0 22.78-8.09c5.74-4.73 15.58-7.48 27.89-7.84a84 84 0 0 0-24.4 48H94.48a48 48 0 1 0 0 24H136a12 12 0 0 0 12-12 60 60 0 0 1 31.8-52.94l3.5 11.8A48 48 0 1 0 208 112ZM48 172h20.77a24 24 0 1 1 0-24H48a12 12 0 0 0 0 24Zm160 12a24 24 0 0 1-17.4-40.5l5.89 19.91A12 12 0 0 0 208 172a12.28 12.28 0 0 0 3.41-.49 12 12 0 0 0 8.1-14.92l-5.91-19.92A24 24 0 0 1 208 184Z" /></Svg>;
export default Motorcycle;