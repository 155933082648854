/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StackSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stack-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stack-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m14 107.47 112 64a4 4 0 0 0 4 0l112-64a4 4 0 0 0 0-6.94l-112-64a4 4 0 0 0-4 0l-112 64a4 4 0 0 0 0 6.94Zm114-62.86L231.94 104 128 163.39 24.06 104ZM243.47 142a4 4 0 0 1-1.49 5.45l-112 64a4 4 0 0 1-4 0l-112-64a4 4 0 0 1 4-6.94l110 62.86 110-62.86a4 4 0 0 1 5.49 1.49Z" /></Svg>;
export default StackSimple;