/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChalkboardTeacher = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chalkboard-teacher-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chalkboard-teacher-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 84a12 12 0 0 1 12-12h120a12 12 0 0 1 12 12v88a12 12 0 0 1-24 0V96H68a12 12 0 0 1-12-12Zm180-28v144a20 20 0 0 1-20 20h-66.74a12 12 0 0 1-11.4-8.26 36 36 0 0 0-67.74 0A12 12 0 0 1 58.74 220H40a20 20 0 0 1-20-20V56a20 20 0 0 1 20-20h176a20 20 0 0 1 20 20ZM104 164a16 16 0 1 0-16-16 16 16 0 0 0 16 16ZM212 60H44v136h6.92a60.18 60.18 0 0 1 21.76-23.16 40 40 0 1 1 62.64 0A60.18 60.18 0 0 1 157.08 196H212Z" /></Svg>;
export default ChalkboardTeacher;