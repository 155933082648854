/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartLineDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-line-down-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-line-down-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm-16 152H56a8 8 0 0 1-8-8V72a8 8 0 0 1 16 0v20.69l32 32 34.34-34.35a8 8 0 0 1 11.32 0L176 124.69V104a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8h-40a8 8 0 0 1 0-16h20.69L136 107.31l-34.34 34.35a8 8 0 0 1-11.32 0L64 115.31V176h136a8 8 0 0 1 0 16Z" /></Svg>;
export default ChartLineDown;