/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleNyTimes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-ny-times-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-ny-times-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 104v96H80v-32h48v-64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 96h104a8 8 0 0 1 0 16H128a8 8 0 0 1 0-16Zm104 32H128a8 8 0 0 0 0 16h104a8 8 0 0 0 0-16Zm0 32H80a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16Zm0 32H80a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16ZM96 144a8 8 0 0 0 0-16h-8V64h32v8a8 8 0 0 0 16 0V56a8 8 0 0 0-8-8H32a8 8 0 0 0-8 8v16a8 8 0 0 0 16 0v-8h32v64h-8a8 8 0 0 0 0 16Z" /></Svg>;
export default ArticleNyTimes;