/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeHemisphereWest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-hemisphere-west-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-hemisphere-west-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm90 102a89.55 89.55 0 0 1-7.46 35.86l-46.69-28.71a13.94 13.94 0 0 0-5.46-2l-22.82-3.07a14.06 14.06 0 0 0-14.51 7.92h-9.92a2 2 0 0 1-1.8-1.13l-3.8-7.86a13.94 13.94 0 0 0-9.66-7.59l-10.71-2.3L94.4 103a2 2 0 0 1 1.74-1h16.71a13.9 13.9 0 0 0 6.76-1.75l12.25-6.75a14.73 14.73 0 0 0 2.62-1.88l26.91-24.33a13.93 13.93 0 0 0 2.83-17.21L161 44.25A90.16 90.16 0 0 1 218 128Zm-73.4-88.46 9.15 16.39a2 2 0 0 1-.41 2.46l-26.91 24.33a1.84 1.84 0 0 1-.37.27l-12.25 6.76a2 2 0 0 1-1 .25H96.14A14 14 0 0 0 84 97l-10.82 18.91a2 2 0 0 1-.19-.35L61.5 84.89a2 2 0 0 1 0-1.48l11.18-26.35a89.9 89.9 0 0 1 71.92-17.52ZM38 128a89.52 89.52 0 0 1 11.38-43.77 13.85 13.85 0 0 0 .89 4.87l11.49 30.67a13.94 13.94 0 0 0 10.16 8.78l21.44 4.6a2 2 0 0 1 1.38 1.09l3.8 7.86a14.07 14.07 0 0 0 12.6 7.9h4.56l-8.49 19a14 14 0 0 0 2.51 15.2l.1.11 19.68 20.26a2 2 0 0 1 .46 1.7L127.7 218A90.1 90.1 0 0 1 38 128Zm102.08 89.19 1.67-8.6a14.07 14.07 0 0 0-3.47-12.16l-.1-.11-19.68-20.26a2 2 0 0 1-.33-2.14l13.7-30.73A2 2 0 0 1 134 142l22.82 3.08a2 2 0 0 1 .78.27l47.4 29.2a90.18 90.18 0 0 1-64.92 42.64Z" /></Svg>;
export default GlobeHemisphereWest;