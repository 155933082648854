/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RewindCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rewind-circle__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rewind-circle'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88ZM115.58 84.84a8 8 0 0 0-8.38.76l-48 36a8 8 0 0 0 0 12.8l48 36a8 8 0 0 0 4.8 1.6 8 8 0 0 0 8-8V92a8 8 0 0 0-4.42-7.16ZM104 148l-26.67-20L104 108Zm75.58-63.16a8 8 0 0 0-8.38.76l-48 36a8 8 0 0 0 0 12.8l48 36a8 8 0 0 0 4.8 1.6 8 8 0 0 0 8-8V92a8 8 0 0 0-4.42-7.16ZM168 148l-26.67-20L168 108Z" /></Svg>;
export default RewindCircle;