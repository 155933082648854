/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileTxt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-txt-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-txt-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 118a6 6 0 0 0 6-6V40a2 2 0 0 1 2-2h90v50a6 6 0 0 0 6 6h50v18a6 6 0 0 0 12 0V88a6 6 0 0 0-1.76-4.24l-56-56A6 6 0 0 0 152 26H56a14 14 0 0 0-14 14v72a6 6 0 0 0 6 6Zm110-71.52L193.52 82H158Zm-5.12 109L135.37 180l17.51 24.51a6 6 0 1 1-9.76 7L128 190.32l-15.12 21.17a6 6 0 0 1-9.76-7L120.63 180l-17.51-24.51a6 6 0 1 1 9.76-7L128 169.68l15.12-21.17a6 6 0 0 1 9.76 7ZM90 152a6 6 0 0 1-6 6H70v50a6 6 0 0 1-12 0v-50H44a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6Zm128 0a6 6 0 0 1-6 6h-14v50a6 6 0 0 1-12 0v-50h-14a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6Z" /></Svg>;
export default FileTxt;