/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seat-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seat-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 232a12 12 0 0 1-12 12H112a12 12 0 0 1 0-24h104a12 12 0 0 1 12 12Zm0-72v28a20 20 0 0 1-20 20h-95.9a19.9 19.9 0 0 1-17.89-11.06L38.1 85a20.12 20.12 0 0 1 0-17.9L60.22 23a20 20 0 0 1 26.72-9l29.81 14.27.18.09a20 20 0 0 1 8.95 26.83l-.17.33-13.21 24.56L142.25 140H208a20 20 0 0 1 20 20Zm-24 4h-64.23a19.9 19.9 0 0 1-17.89-11.06l-31.79-64a20.07 20.07 0 0 1 0-17.86c.06-.11.12-.22.17-.33l12.2-22.62L79.91 37.3 60.47 76l54.1 108H204Z" /></Svg>;
export default Seat;