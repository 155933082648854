/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudFog = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-fog-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-fog-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 232a8 8 0 0 1-8 8h-56a8 8 0 0 1 0-16h56a8 8 0 0 1 8 8Zm-40-32a8 8 0 0 0-8-8H72a8 8 0 0 0 0 16h48a8 8 0 0 0 8-8Zm56-8h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16Zm47.87-96.45a76 76 0 0 0-151.78.73A8.18 8.18 0 0 1 72 104h-.6a8.14 8.14 0 0 1-7.4-8.61 92.48 92.48 0 0 1 2.33-16.51 4 4 0 0 0-5-4.78A52.09 52.09 0 0 0 24 124.36C24.2 153.07 48.12 176 76.84 176H156a76.08 76.08 0 0 0 75.87-80.45Z" /></Svg>;
export default CloudFog;