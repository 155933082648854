/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-star-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-star-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34h-26V24a6 6 0 0 0-12 0v10H86V24a6 6 0 0 0-12 0v10H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm2 174a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2h26v10a6 6 0 0 0 12 0V46h84v10a6 6 0 0 0 12 0V46h26a2 2 0 0 1 2 2Zm-33.54-92.37-31-2.4-11.95-27.61a6 6 0 0 0-11 0l-11.91 27.61-31 2.4a6 6 0 0 0-3.36 10.61l23.49 19.39-7.16 28.93a6 6 0 0 0 8.87 6.61L128 165.5l26.62 15.67a6 6 0 0 0 8.87-6.61l-7.16-28.93 23.49-19.39a6 6 0 0 0-3.36-10.61Zm-30.68 23.15a6 6 0 0 0-2 6.07l4.63 18.74L131 153.37a6 6 0 0 0-6.08 0l-17.37 10.22 4.63-18.74a6 6 0 0 0-2-6.07l-14.9-12.33 19.83-1.53a6 6 0 0 0 5-3.61l7.89-18.17 7.84 18.17a6 6 0 0 0 5 3.61l19.83 1.53Z" /></Svg>;
export default CalendarStar;