/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartBar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-bar-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-bar-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 204h-12V40a4 4 0 0 0-4-4h-56a4 4 0 0 0-4 4v44H96a4 4 0 0 0-4 4v44H48a4 4 0 0 0-4 4v68H32a4 4 0 0 0 0 8h192a4 4 0 0 0 0-8ZM156 44h48v160h-48Zm-56 48h48v112h-48Zm-48 48h40v64H52Z" /></Svg>;
export default ChartBar;