/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bulldozer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bulldozer-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bulldozer-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 196h-8a4 4 0 0 1-4-4v-32a4 4 0 0 1 4-4h8a12 12 0 0 0 0-24h-8a28 28 0 0 0-28 28v4h-9.68a44.13 44.13 0 0 0-34-31.2l-35.2-84.49A20 20 0 0 0 110.67 36H24A20 20 0 0 0 4 56v101.7A44 44 0 0 0 44 220h112a44.06 44.06 0 0 0 42.32-32H208v4a28 28 0 0 0 28 28h8a12 12 0 0 0 0-24Zm-106-64H68V60h40ZM44 60v72a43.85 43.85 0 0 0-16 3V60Zm112 136H44a20 20 0 0 1 0-40h112a20 20 0 0 1 0 40Z" /></Svg>;
export default Bulldozer;