/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSki = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-ski-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-ski-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 60a20 20 0 1 1-20-20 20 20 0 0 1 20 20Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M172 88a28 28 0 1 0-28-28 28 28 0 0 0 28 28Zm0-40a12 12 0 1 1-12 12 12 12 0 0 1 12-12ZM32.32 77.76a8 8 0 0 1 9.92-5.44L112 92.67l10.32-10.33a8 8 0 0 1 11.32 0L163.31 112H200a8 8 0 0 1 0 16h-40a8 8 0 0 1-5.66-2.34l-5.61-5.66-111-32.36a8 8 0 0 1-5.41-9.88Zm204.12 126.48a69.78 69.78 0 0 1-58.37 8.95L21.76 167.67a8 8 0 1 1 4.48-15.36l76 22.13L128.73 148 85.8 135.69a8 8 0 0 1 4.4-15.39l56 16a8 8 0 0 1 3.46 13.35l-29.9 29.9 62.79 18.28a53.78 53.78 0 0 0 45-6.9 8 8 0 1 1 8.88 13.31Z" /></Svg>;
export default PersonSimpleSki;