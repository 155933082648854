/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneList = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-list-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-list-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m213.59 168.3-47.24-21.17a14 14 0 0 0-13.28 1.22 4.81 4.81 0 0 0-.56.42l-24.69 21a1.88 1.88 0 0 1-1.68.06c-15.87-7.66-32.31-24-40-39.65a1.89 1.89 0 0 1 0-1.67l21.07-25.06a6.13 6.13 0 0 0 .42-.58 14 14 0 0 0 1.12-13.27L87.73 42.49a14 14 0 0 0-14.56-8.38A54.25 54.25 0 0 0 26 88c0 78.3 63.7 142 142 142a54.25 54.25 0 0 0 53.89-47.17 14 14 0 0 0-8.3-14.53ZM168 218C96.32 218 38 159.68 38 88a42.23 42.23 0 0 1 36.67-42h.23a2 2 0 0 1 1.84 1.31l21.1 47.11a2 2 0 0 1 0 1.67l-21.11 25.06a4.73 4.73 0 0 0-.43.57 14 14 0 0 0-.91 13.73c8.87 18.16 27.17 36.32 45.53 45.19a14 14 0 0 0 13.77-1c.19-.13.38-.27.56-.42l24.68-21a1.92 1.92 0 0 1 1.6-.1l47.25 21.17a2 2 0 0 1 1.21 2A42.24 42.24 0 0 1 168 218ZM138 64a6 6 0 0 1 6-6h64a6 6 0 0 1 0 12h-64a6 6 0 0 1-6-6Zm0 40a6 6 0 0 1 6-6h64a6 6 0 0 1 0 12h-64a6 6 0 0 1-6-6Z" /></Svg>;
export default PhoneList;