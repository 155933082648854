/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartDonut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-donut-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-donut-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a12 12 0 0 0-12 12v56a12 12 0 0 0 12 12 28 28 0 1 1-24.26 14 12 12 0 0 0-4.39-16.4l-48.5-28A12 12 0 0 0 34.46 74 108 108 0 1 0 128 20ZM50 96.81l28.2 16.28A52.08 52.08 0 0 0 76 128v1.5l-31.43 8.42A83.21 83.21 0 0 1 44 128a84.35 84.35 0 0 1 6-31.19Zm.83 64.3 31.43-8.43A52.2 52.2 0 0 0 116 178.59v32.54a84.26 84.26 0 0 1-65.19-50.02Zm89.19 50v-32.52A52 52 0 0 0 140 77.4V44.85a84 84 0 0 1 0 166.28Z" /></Svg>;
export default ChartDonut;