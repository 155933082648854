/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandsClapping = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hands-clapping-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hands-clapping-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M199 187.76a71.67 71.67 0 0 0-8.66-47.76l-20.2-35a18 18 0 0 0-31.55 17.26L114.71 81a18 18 0 1 0-31.17 18l-5.73-10L65.1 67a18 18 0 1 1 31.18-18L102 59a18 18 0 1 1 31.17-18l24.23 42a18 18 0 0 1 31.2-18l21.11 36.57A72 72 0 0 1 199 187.76Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M160.22 24V8a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0Zm35.88 17a7.91 7.91 0 0 0 4.17 1.17 8 8 0 0 0 6.84-3.83l8-13.11a8 8 0 0 0-13.68-8.33l-8 13.1a8 8 0 0 0 2.67 11Zm47.51 12.59a8 8 0 0 0-10.08-5.16l-15.06 4.85a8 8 0 0 0 2.46 15.62 8.15 8.15 0 0 0 2.46-.39l15.05-4.85a8 8 0 0 0 5.17-10.11ZM217 97.58a80.22 80.22 0 0 1-10.22 94c-.34 1.73-.72 3.46-1.19 5.18A80.17 80.17 0 0 1 58.77 216L23.5 155a26 26 0 0 1 19.24-38.79l-3-5.2a26 26 0 0 1 19.2-38.78l-.7-1.23a26 26 0 0 1 37.23-34.47 26.06 26.06 0 0 1 44.83.47l12.26 21.2a26.07 26.07 0 0 1 43.25 2.8ZM109.07 55l25 43.17a26 26 0 0 1 17.33-10L126.42 45a10 10 0 1 0-17.35 10Zm-36.95 8 6.46 11.17a26.05 26.05 0 0 1 17.32-10L89.45 53a10 10 0 1 0-17.33 10Zm111.54 81-20.22-35a10 10 0 0 0-17.74 9.25L158.3 140a8 8 0 0 1-13.87 8l-36.5-63a10 10 0 1 0-17.35 10l26.05 45a8 8 0 0 1-13.87 8L71 93a10 10 0 0 0-17.33 10l35.22 61A8 8 0 0 1 75 172l-20.28-35a10 10 0 0 0-17.34 10l35.27 61a64.12 64.12 0 0 0 117.42-15.44 63.52 63.52 0 0 0-6.41-48.56Zm19.41-38.42L181.93 69a10 10 0 0 0-17.38 10l33 57.05a80.2 80.2 0 0 1 9.45 25.46 64.23 64.23 0 0 0-3.93-55.93Z" /></Svg>;
export default HandsClapping;