/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandArrowUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-arrow-up-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-arrow-up-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.76 137.88a28.39 28.39 0 0 0-24.63-4.88L172 141.26V140a32 32 0 0 0-32-32H89.94a35.74 35.74 0 0 0-25.45 10.54L43 140H20a20 20 0 0 0-20 20v40a20 20 0 0 0 20 20h100a11.89 11.89 0 0 0 2.91-.36l64-16a11.4 11.4 0 0 0 1.79-.6l38.82-16.54c.23-.09.45-.19.67-.3a28.61 28.61 0 0 0 4.57-48.32ZM24 164h12v32H24Zm193.68.61-37.51 16L118.52 196H60v-39l21.46-21.46a11.93 11.93 0 0 1 8.48-3.54H140a8 8 0 0 1 0 16h-28a12 12 0 0 0 0 24h32a12.19 12.19 0 0 0 2.69-.3l67-15.41.47-.12a4.6 4.6 0 0 1 5.81 4.44 4.58 4.58 0 0 1-2.29 4ZM151.51 64.48a12 12 0 0 1 0-17l32-32a12 12 0 0 1 17 0l32 32a12 12 0 1 1-17 17L204 53v51a12 12 0 0 1-24 0V53l-11.51 11.48a12 12 0 0 1-16.98 0Z" /></Svg>;
export default HandArrowUp;