/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CreditCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="credit-card-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-credit-card-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 52H32a12 12 0 0 0-12 12v128a12 12 0 0 0 12 12h192a12 12 0 0 0 12-12V64a12 12 0 0 0-12-12ZM32 60h192a4 4 0 0 1 4 4v28H28V64a4 4 0 0 1 4-4Zm192 136H32a4 4 0 0 1-4-4v-92h200v92a4 4 0 0 1-4 4Zm-20-28a4 4 0 0 1-4 4h-32a4 4 0 0 1 0-8h32a4 4 0 0 1 4 4Zm-64 0a4 4 0 0 1-4 4h-16a4 4 0 0 1 0-8h16a4 4 0 0 1 4 4Z" /></Svg>;
export default CreditCard;