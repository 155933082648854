/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GridNine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="grid-nine-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-grid-nine-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 50H40a14 14 0 0 0-14 14v128a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V64a14 14 0 0 0-14-14Zm-114 96v-36h52v36Zm52 12v36h-52v-36ZM38 110h52v36H38Zm64-12V62h52v36Zm64 12h52v36h-52Zm52-46v34h-52V62h50a2 2 0 0 1 2 2ZM40 62h50v36H38V64a2 2 0 0 1 2-2Zm-2 130v-34h52v36H40a2 2 0 0 1-2-2Zm178 2h-50v-36h52v34a2 2 0 0 1-2 2Z" /></Svg>;
export default GridNine;