/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tornado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tornado-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tornado-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 40a6 6 0 0 1-6 6H56a6 6 0 0 1 0-12h168a6 6 0 0 1 6 6Zm-48 32a6 6 0 0 0-6-6H32a6 6 0 0 0 0 12h144a6 6 0 0 0 6-6Zm-16 32a6 6 0 0 0-6-6H56a6 6 0 0 0 0 12h104a6 6 0 0 0 6-6Zm16 32a6 6 0 0 0-6-6H88a6 6 0 0 0 0 12h88a6 6 0 0 0 6-6Zm2 26h-64a6 6 0 0 0 0 12h64a6 6 0 0 0 0-12Zm-24 32h-32a6 6 0 0 0 0 12h32a6 6 0 0 0 0-12Zm-32 32h-16a6 6 0 0 0 0 12h16a6 6 0 0 0 0-12Z" /></Svg>;
export default Tornado;