/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NetworkSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="network-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-network-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M98.08 59.41A8 8 0 0 1 96 54V40a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-16.39a8 8 0 0 1-5.92-2.62ZM53.92 34.62a8 8 0 1 0-11.84 10.76L102.64 112H24a8 8 0 0 0 0 16h32v32h-8a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-8v-32h45.19l84.89 93.38a8 8 0 1 0 11.84-10.76ZM232 112h-68a8 8 0 0 0 0 16h20v22.83a8 8 0 1 0 16 0V128h32a8 8 0 0 0 0-16Z" /></Svg>;
export default NetworkSlash;