/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shower-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shower-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m184 72-22.09 129.31a8 8 0 0 1-13.54 4.34l-98-98a8 8 0 0 1 4.34-13.54Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M64 236a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm20-44a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm-64 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm32-32a12 12 0 1 0 12 12 12 12 0 0 0-12-12ZM256 40a8 8 0 0 1-8 8h-28.69l-27.85 27.86-21.66 126.79a16 16 0 0 1-27.09 8.66l-98-98a16 16 0 0 1 8.69-27.1l126.74-21.67 30.2-30.2A8 8 0 0 1 216 32h32a8 8 0 0 1 8 8Zm-81.79 41.79L56 102l98 98Z" /></Svg>;
export default Shower;