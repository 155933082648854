/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BugDroid = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bug-droid-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bug-droid-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m191.83 51.48 13.83-13.82a8 8 0 0 0-11.32-11.32L179.79 40.9a87.81 87.81 0 0 0-103.58 0L61.66 26.34a8 8 0 0 0-11.32 11.32l13.83 13.82A87.72 87.72 0 0 0 40 112v40a88 88 0 0 0 176 0v-40a87.72 87.72 0 0 0-24.17-60.52ZM128 40a72.08 72.08 0 0 1 72 72v8H56v-8a72.08 72.08 0 0 1 72-72Zm16 52a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm-56 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Z" /></Svg>;
export default BugDroid;