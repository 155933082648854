/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TagChevron = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tag-chevron-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tag-chevron-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m240 128-45.62 68.44a8 8 0 0 1-6.66 3.56H32l48-72-48-72h155.72a8 8 0 0 1 6.66 3.56Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M246.66 123.56 201 55.12A16 16 0 0 0 187.72 48H32a8 8 0 0 0-6.66 12.44L70.39 128l-45 67.56A8 8 0 0 0 32 208h155.72a16 16 0 0 0 13.28-7.12l45.63-68.44a8 8 0 0 0 .03-8.88ZM187.72 192H47l39.71-59.56a8 8 0 0 0 0-8.88L47 64h140.72l42.67 64Z" /></Svg>;
export default TagChevron;