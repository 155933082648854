/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-check-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-check-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152.5 156.54a72 72 0 1 0-89 0 124 124 0 0 0-48.69 35.74 12 12 0 0 0 18.38 15.44C46.88 191.42 71 172 108 172s61.12 19.42 74.81 35.72a12 12 0 1 0 18.38-15.44 123.89 123.89 0 0 0-48.69-35.74ZM60 100a48 48 0 1 1 48 48 48.05 48.05 0 0 1-48-48Zm192.49 36.49-32 32a12 12 0 0 1-17 0l-16-16a12 12 0 0 1 17-17L212 143l23.51-23.52a12 12 0 1 1 17 17Z" /></Svg>;
export default UserCheck;