/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Van = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="van-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-van-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m254.07 106.79-45.54-53.06A16 16 0 0 0 196.26 48H32a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h17a32 32 0 0 0 62 0h50a32 32 0 0 0 62 0h17a16 16 0 0 0 16-16v-64a8 8 0 0 0-1.93-5.21ZM32 104V64h56v40Zm48 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm80-96h-56V64h56Zm32 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm-16-96V64h20.26l34.33 40Z" /></Svg>;
export default Van;