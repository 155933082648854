/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Paragraph = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paragraph-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paragraph-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H96a68 68 0 0 0 0 136h36v36a12 12 0 0 0 24 0V60h16v148a12 12 0 0 0 24 0V60h12a12 12 0 0 0 0-24Zm-76 112H96a44 44 0 0 1 0-88h36Z" /></Svg>;
export default Paragraph;