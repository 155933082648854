/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileJpg = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-jpg-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-jpg-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 146h-16a6 6 0 0 0-6 6v56a6 6 0 0 0 12 0v-10h10a26 26 0 0 0 0-52Zm0 40h-10v-28h10a14 14 0 0 1 0 28Zm94-2v16.87a6 6 0 0 1-1.67 4.15A28.06 28.06 0 0 1 192 214c-16.54 0-30-15.25-30-34s13.46-34 30-34a27.43 27.43 0 0 1 15.36 4.77 6 6 0 0 1-6.72 9.95A15.25 15.25 0 0 0 192 158c-9.93 0-18 9.87-18 22s8.07 22 18 22a15.75 15.75 0 0 0 10-3.73V190h-2a6 6 0 0 1 0-12h8a6 6 0 0 1 6 6ZM78 152v38a24 24 0 0 1-48 0 6 6 0 0 1 12 0 12 12 0 0 0 24 0v-38a6 6 0 0 1 12 0Zm134.24-68.24-56-56A6 6 0 0 0 152 26H56a14 14 0 0 0-14 14v72a6 6 0 0 0 12 0V40a2 2 0 0 1 2-2h90v50a6 6 0 0 0 6 6h50v18a6 6 0 0 0 12 0V88a6 6 0 0 0-1.76-4.24ZM158 82V46.48L193.52 82Z" /></Svg>;
export default FileJpg;