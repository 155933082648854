/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderMale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-male__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-male'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 32h-48a8 8 0 0 0 0 16h28.69l-42.07 42.07a80 80 0 1 0 11.31 11.31L208 59.32V88a8 8 0 0 0 16 0V40a8 8 0 0 0-8-8Zm-66.76 165.29a64 64 0 1 1 0-90.53 64.1 64.1 0 0 1 0 90.53Z" /></Svg>;
export default GenderMale;