/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropHalfBottom = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-half-bottom-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-half-bottom-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M134.88 6.17a12 12 0 0 0-13.76 0 259 259 0 0 0-42.18 39C50.85 77.43 36 111.63 36 144a92 92 0 0 0 184 0c0-77.36-81.64-135.4-85.12-137.83ZM191.61 168H64.39a67.1 67.1 0 0 1-3.9-16h135a67.1 67.1 0 0 1-3.88 16ZM96.7 61.29A249.35 249.35 0 0 1 128 31.11a249.35 249.35 0 0 1 31.3 30.18c14 16.19 30.27 39.89 35.2 66.71h-133c4.93-26.82 21.19-50.52 35.2-66.71ZM128 212a67.78 67.78 0 0 1-48.12-20h96.24A67.78 67.78 0 0 1 128 212Z" /></Svg>;
export default DropHalfBottom;