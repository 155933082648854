/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderNonbinary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-nonbinary-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-nonbinary-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 93V69.2l21.83 13.09a12 12 0 1 0 12.34-20.58L151.32 48l22.85-13.71a12 12 0 0 0-12.34-20.58L128 34 94.17 13.71a12 12 0 0 0-12.34 20.58L104.68 48 81.83 61.71a12 12 0 1 0 12.34 20.58L116 69.2V93a76 76 0 1 0 24 0Zm-12 127a52 52 0 1 1 52-52 52.06 52.06 0 0 1-52 52Z" /></Svg>;
export default GenderNonbinary;