/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinuxLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="linux-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-linux-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M161.22 209.74a4 4 0 0 1-3.31 6.26H98.1a4 4 0 0 1-3.31-6.26 40 40 0 0 1 66.43 0Zm68.93 3.37a8.29 8.29 0 0 1-6.43 2.89h-39.16a4 4 0 0 1-3.76-2.65 56 56 0 0 0-105.59 0 4 4 0 0 1-3.76 2.65H32.23a8.2 8.2 0 0 1-6.42-2.93 8 8 0 0 1-.06-10.07c.06-.07 7.64-9.78 15.12-28.72C47.77 156.8 56 127.64 56 88a72 72 0 0 1 144 0c0 39.64 8.23 68.8 15.13 86.28 7.48 18.94 15.06 28.65 15.13 28.74a8 8 0 0 1-.11 10.09ZM88 100a12 12 0 1 0 12-12 12 12 0 0 0-12 12Zm79.16 32.42a8 8 0 0 0-10.73-3.58L128 143.06l-28.42-14.22a8 8 0 0 0-7.15 14.32l32 16a8 8 0 0 0 7.15 0l32-16a8 8 0 0 0 3.58-10.74ZM168 100a12 12 0 1 0-12 12 12 12 0 0 0 12-12Z" /></Svg>;
export default LinuxLogo;