/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Asclepius = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="asclepius-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-asclepius-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M91.82 60H92a12 12 0 0 0 0-24H60a44.05 44.05 0 0 0-44 44v8a12 12 0 0 0 12 12h20a44.06 44.06 0 0 0 43.82-40ZM48 76h-7.6A20 20 0 0 1 60 60h7.6A20 20 0 0 1 48 76Zm176 3v1a44.05 44.05 0 0 1-44 44h-40v72h8a12 12 0 0 0 12-12 11.87 11.87 0 0 0-4-8.95 12 12 0 1 1 16-17.88A36 36 0 0 1 148 220h-8v12a12 12 0 0 1-24 0v-12H92a12 12 0 0 1 0-24h24v-72h-16a12 12 0 0 0-4 23.32A12 12 0 1 1 88 170a36 36 0 0 1 12-70h16V24a12 12 0 0 1 24 0v76h40a20 20 0 0 0 20-20v-1a19.05 19.05 0 0 0-19-19h-17a12 12 0 0 1 0-24h17a43.05 43.05 0 0 1 43 43Z" /></Svg>;
export default Asclepius;