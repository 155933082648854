/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Martini = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="martini-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-martini-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243.09 35.41A12 12 0 0 0 232 28H24a12 12 0 0 0-8.48 20.49L116 149v55H88a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24h-28v-55L240.48 48.49a12 12 0 0 0 2.61-13.08ZM203 52l-12 12H65L53 52Zm-75 75L89 88h78Z" /></Svg>;
export default Martini;