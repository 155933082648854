/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Grains = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="grains-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-grains-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 58a85.48 85.48 0 0 0-32.84 6.53C161.45 34.31 132 19.3 130.68 18.63a6 6 0 0 0-5.36 0c-1.33.67-30.77 15.68-44.48 45.9A85.51 85.51 0 0 0 48 58a6 6 0 0 0-6 6v80a86 86 0 0 0 172 0V64a6 6 0 0 0-6-6Zm-86 159.76A74.1 74.1 0 0 1 54 144v-17.76A74.1 74.1 0 0 1 122 200Zm0-61.51a86.1 86.1 0 0 0-68-42v-44A74.11 74.11 0 0 1 122 144ZM91.58 69.91c9.66-21.6 29.27-34.78 36.42-39 7.16 4.25 26.76 17.43 36.41 39A86.5 86.5 0 0 0 128 112.5a86.47 86.47 0 0 0-36.42-42.59ZM202 144a74.1 74.1 0 0 1-68 73.76V200a74.1 74.1 0 0 1 68-73.76Zm0-29.77a86.1 86.1 0 0 0-68 42V144a74.11 74.11 0 0 1 68-73.76Z" /></Svg>;
export default Grains;