/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-circle-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-circle-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128a104 104 0 0 1-152.88 91.82l-34.05 11.35a16 16 0 0 1-20.24-20.24l11.35-34.05A104 104 0 1 1 232 128Z" /></Svg>;
export default ChatCircle;