/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MaskSad = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mask-sad-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mask-sad-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.29 31.5a20 20 0 0 0-18.52-2.14C187 34.72 159.17 43.7 128 43.7s-59-9-72.77-14.34A20 20 0 0 0 28 48v55.77c0 36.64 9.9 71.25 27.88 97.45C74.81 228.81 100.42 244 128 244s53.19-15.19 72.12-42.76C218.1 175 228 140.43 228 103.79V48a20 20 0 0 0-8.71-16.5ZM204 103.79c0 31.84-8.41 61.63-23.67 83.87C166 208.51 147.43 220 128 220s-38-11.49-52.33-32.34C60.41 165.42 52 135.63 52 103.79v-50c16.59 5.95 44.67 13.94 76 13.94s59.41-8 76-13.94Zm-45.62 83.72a12 12 0 0 1-16.4 4.37 27.13 27.13 0 0 0-28 0 12 12 0 1 1-12-20.76 51.14 51.14 0 0 1 52 0 12 12 0 0 1 4.4 16.39Zm-20.49-74a12 12 0 0 1 16.59-3.61 19.15 19.15 0 0 0 9.52 2.78 19.64 19.64 0 0 0 7.27-1.59 12 12 0 1 1 9.46 22.06 41.58 41.58 0 0 1-39.23-3.06 12 12 0 0 1-3.61-16.58Zm-23.39 16.58a41.54 41.54 0 0 1-39.23 3 12 12 0 1 1 9.46-22 19.64 19.64 0 0 0 7.27 1.59 19.22 19.22 0 0 0 9.54-2.78 12 12 0 1 1 13 20.19Z" /></Svg>;
export default MaskSad;