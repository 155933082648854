/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmReel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-reel-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-reel-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 220h-64.82a100 100 0 1 0-39.18 8h104a4 4 0 0 0 0-8ZM36 128a92 92 0 1 1 92 92 92.1 92.1 0 0 1-92-92Zm92-28a20 20 0 1 0-20-20 20 20 0 0 0 20 20Zm0-32a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm20 108a20 20 0 1 0-20 20 20 20 0 0 0 20-20Zm-32 0a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm60-28a20 20 0 1 0-20-20 20 20 0 0 0 20 20Zm0-32a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm-96-8a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm0 32a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default FilmReel;