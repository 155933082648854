/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const EggCrack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="egg-crack-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-egg-crack-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 152a88.11 88.11 0 0 1-87.8 88c-50.66.12-90.21-43-88.12-93.62 1.21-29.21 11.71-60.54 29.23-86.82C87.5 32.29 109.43 16 128 16c13.25 0 28.23 8.32 42.34 23a4 4 0 0 1 .09 5.44L122 98.67a8 8 0 0 0 4 13.09l24.61 6.15-6.51 32.52a8 8 0 0 0 6.28 9.41 7.7 7.7 0 0 0 1.62.16 8 8 0 0 0 7.83-6.43l8-40a8 8 0 0 0-5.9-9.33l-19.16-4.79 36.89-41.33a4 4 0 0 1 6.29.41c.24.34.47.68.7 1C205.3 87.54 216 121.23 216 152Z" /></Svg>;
export default EggCrack;