/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SprayBottle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spray-bottle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spray-bottle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 84a12 12 0 0 0 12-12 60.07 60.07 0 0 0-60-60H80a20 20 0 0 0-20 20v48a20 20 0 0 1-20 20 12 12 0 0 0 0 24 44.06 44.06 0 0 0 43.82-40H108v20.62a19.92 19.92 0 0 1-7.51 15.62L84.51 133A43.8 43.8 0 0 0 68 167.38V224a20 20 0 0 0 20 20h104a20 20 0 0 0 20-20v-12.53A274.77 274.77 0 0 0 180.68 84ZM84 36h68a36 36 0 0 1 33.94 24H84Zm104 175.47V220H92v-52.62a19.92 19.92 0 0 1 7.51-15.62l16-12.78A43.8 43.8 0 0 0 132 104.62V84h21.24A250.93 250.93 0 0 1 188 211.47Z" /></Svg>;
export default SprayBottle;