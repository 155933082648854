/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireTruck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-truck-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-truck-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m251.71 118.51-14-35A12 12 0 0 0 226.58 76H188V64a4 4 0 0 0-8 0v102.71A28.05 28.05 0 0 0 164.29 188h-56.58a28 28 0 0 0-55.42 0H32a4 4 0 0 1-4-4v-48a4 4 0 0 0-8 0v48a12 12 0 0 0 12 12h20.29a28 28 0 0 0 55.42 0h56.58a28 28 0 0 0 55.42 0H240a12 12 0 0 0 12-12v-64a4 4 0 0 0-.29-1.49ZM226.58 84a4 4 0 0 1 3.72 2.51L242.09 116H188V84ZM80 212a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm112 0a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm48-24h-20.29A28 28 0 0 0 188 164.29V124h56v60a4 4 0 0 1-4 4ZM24 100a4 4 0 0 0 0 8h128a4 4 0 0 0 0-8h-20V68h20a4 4 0 0 0 0-8H24a4 4 0 0 0 0 8h20v32Zm100 0H92V68h32ZM52 68h32v32H52Z" /></Svg>;
export default FireTruck;