/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-star-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-star-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M34 64a6 6 0 0 1 6-6h176a6 6 0 0 1 0 12H40a6 6 0 0 1-6-6Zm6 70h56a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12Zm72 52H40a6 6 0 0 0 0 12h72a6 6 0 0 0 0-12Zm123.82-43.76-23.49 19.39 7.16 28.93a6 6 0 0 1-8.87 6.61L184 181.5l-26.62 15.67a6 6 0 0 1-8.87-6.61l7.16-28.93-23.49-19.39a6 6 0 0 1 3.36-10.61l31-2.4 11.91-27.61a6 6 0 0 1 11 0l11.91 27.61 31 2.4a6 6 0 0 1 3.36 10.61Zm-19.1.21-19.83-1.53a6 6 0 0 1-5-3.61L184 119.14l-7.84 18.17a6 6 0 0 1-5 3.61l-19.83 1.53 14.94 12.33a6 6 0 0 1 2 6.07l-4.63 18.74L181 169.37a6 6 0 0 1 6.08 0l17.37 10.22-4.63-18.74a6 6 0 0 1 2-6.07Z" /></Svg>;
export default ListStar;