/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatCircleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-circle-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-circle-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 0 0-6 5.38l12.83 14.07a100 100 0 0 0-17.3 119.74l-11.9 35.69a12 12 0 0 0 15.18 15.18l35.69-11.9a100 100 0 0 0 112.75-10.85L205 218.69a4 4 0 1 0 5.92-5.38ZM128 220a92 92 0 0 1-46.07-12.35 4 4 0 0 0-3.27-.33l-37.38 12.46a4 4 0 0 1-5.06-5.06l12.46-37.38a4 4 0 0 0-.33-3.27A92 92 0 0 1 63.22 62.69l123.64 136A91.75 91.75 0 0 1 128 220Zm100-92a100.28 100.28 0 0 1-11.9 47.36 4 4 0 0 1-3.53 2.1 3.91 3.91 0 0 1-1.89-.48 4 4 0 0 1-1.63-5.42A92 92 0 0 0 92.33 43.17a4 4 0 0 1-3.11-7.37A100.05 100.05 0 0 1 228 128Z" /></Svg>;
export default ChatCircleSlash;