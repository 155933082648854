/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pen-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pen-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M225.91 74.79 181.22 30.1a14 14 0 0 0-19.8 0L38.1 153.41a13.94 13.94 0 0 0-4.1 9.9V208a14 14 0 0 0 14 14h44.69a13.94 13.94 0 0 0 9.9-4.1l86.16-86.16 4.61 18.42-37.6 37.6a6 6 0 1 0 8.49 8.48l40-40a6 6 0 0 0 1.58-5.7l-7.18-28.7 27.26-27.25a14 14 0 0 0 0-19.8ZM46 208v-33.51L81.52 210H48a2 2 0 0 1-2-2Zm50-.49L48.48 160 136 72.49 183.52 120ZM217.42 86.1 192 111.52 144.49 64l25.41-25.41a2 2 0 0 1 2.83 0l44.69 44.68a2 2 0 0 1 0 2.83Z" /></Svg>;
export default Pen;