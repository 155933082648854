/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListMagnifyingGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-magnifying-glass-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-magnifying-glass-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 64a8 8 0 0 1 8-8h176a8 8 0 0 1 0 16H40a8 8 0 0 1-8-8Zm8 72h72a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16Zm88 48H40a8 8 0 0 0 0 16h88a8 8 0 0 0 0-16Zm109.66 2.34L217.36 166A40 40 0 1 0 206 177.36l20.3 20.3a8 8 0 0 0 11.32-11.32Z" /></Svg>;
export default ListMagnifyingGlass;