/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Highlighter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="highlighter-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-highlighter-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252.49 107.51a12 12 0 0 0-17 0L192 151l-79-79 43.52-43.51a12 12 0 0 0-17-17L93.17 57.86a20 20 0 0 0-4.72 20.72L69.17 97.86a20 20 0 0 0 0 28.28L71 128l-55.49 55.51a12 12 0 0 0 4.7 19.87l72 24A11.8 11.8 0 0 0 96 228a12 12 0 0 0 8.49-3.52L136 193l1.86 1.86a20 20 0 0 0 28.28 0l19.27-19.27a20.27 20.27 0 0 0 6.59 1.13 19.86 19.86 0 0 0 14.14-5.86l46.35-46.34a12 12 0 0 0 0-17.01ZM92.76 202.27l-46.55-15.51L88 145l31 31ZM152 175l-55.51-55.48L89 112l15-15 63 63Z" /></Svg>;
export default Highlighter;