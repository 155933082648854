/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WheelchairMotion = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wheelchair-motion-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wheelchair-motion-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 78a30 30 0 1 0-30-30 30 30 0 0 0 30 30Zm0-48a18 18 0 1 1-18 18 18 18 0 0 1 18-18Zm-10 138a62 62 0 1 1-62-62 6 6 0 0 1 0 12 50 50 0 1 0 50 50 6 6 0 0 1 12 0Zm38.64-35.8a6 6 0 0 1 1.24 5l-16 80A6 6 0 0 1 184 222a6.08 6.08 0 0 1-1.19-.12 6 6 0 0 1-4.7-7.06L192.68 142H128a6 6 0 0 1-5.2-9l21.07-36.68a82.05 82.05 0 0 0-92.05 8.41 6 6 0 1 1-7.64-9.25 94 94 0 0 1 111.1-6.28 6 6 0 0 1 1.92 8L138.37 130H200a6 6 0 0 1 4.64 2.2Z" /></Svg>;
export default WheelchairMotion;