/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ContactlessPayment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="contactless-payment-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-contactless-payment-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M93.54 102.13a55.39 55.39 0 0 1 0 51.74A4 4 0 0 1 90 156a4.07 4.07 0 0 1-1.87-.46 4 4 0 0 1-1.67-5.41 46.73 46.73 0 0 0 0-44.26 4 4 0 1 1 7.08-3.74Zm50.58-33.66a4 4 0 0 0-1.65 5.41 114.67 114.67 0 0 1 0 108.24 4 4 0 1 0 7.06 3.76 122.65 122.65 0 0 0 0-115.76 4 4 0 0 0-5.41-1.65Zm-28 16a4 4 0 0 0-1.65 5.41 81 81 0 0 1 0 76.24 4 4 0 1 0 7.06 3.76 89 89 0 0 0 0-83.76 4 4 0 0 0-5.41-1.65ZM228 128A100 100 0 1 1 128 28a100.11 100.11 0 0 1 100 100Zm-8 0a92 92 0 1 0-92 92 92.1 92.1 0 0 0 92-92Z" /></Svg>;
export default ContactlessPayment;