/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 28H72a36 36 0 0 0-36 36v144a20 20 0 0 0 20 20h28a20 20 0 0 0 20-20v-16h48v16a20 20 0 0 0 20 20h28a20 20 0 0 0 20-20V64a36 36 0 0 0-36-36ZM60 168v-56h136v56ZM72 52h112a12 12 0 0 1 12 12v24H60V64a12 12 0 0 1 12-12Zm8 152H60v-12h20Zm96 0v-12h20v12Zm-68-64a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm72 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm76-60v24a12 12 0 0 1-24 0V80a12 12 0 0 1 24 0ZM24 80v24a12 12 0 0 1-24 0V80a12 12 0 0 1 24 0Z" /></Svg>;
export default Bus;