/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotOutline = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dot-outline-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dot-outline-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 98a30 30 0 1 0 30 30 30 30 0 0 0-30-30Zm0 48a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default DotOutline;