/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dog = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dog-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dog-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M108 136a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm56-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm68.24 26.18a20.42 20.42 0 0 1-8.41 1.85 19.59 19.59 0 0 1-3.83-.39V184a44.05 44.05 0 0 1-44 44H80a44.05 44.05 0 0 1-44-44v-36.37a19 19 0 0 1-3.85.39 20.31 20.31 0 0 1-8.39-1.84 19.71 19.71 0 0 1-11.4-21.9l16.42-88a20 20 0 0 1 24.51-15.69l.47.13 52 15.27h44.54l52-15.27.47-.13a20 20 0 0 1 24.51 15.72l16.42 88a19.71 19.71 0 0 1-11.46 21.87Zm-60-91.63L217 112.42l-12.56-67.33ZM39 112.42l44.76-57.87-32.2-9.46ZM196 184v-59.52L146.11 60h-36.22L60 124.48V184a20 20 0 0 0 20 20h36v-7l-12.48-12.49a12 12 0 0 1 17-17L128 175l7.51-7.52a12 12 0 0 1 17 17L140 197v7h36a20 20 0 0 0 20-20Z" /></Svg>;
export default Dog;