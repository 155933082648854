/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderNeuter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-neuter-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-neuter-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M167.84 108.35a40 40 0 1 1-36.19-36.19 40 40 0 0 1 36.19 36.19ZM216 40v176a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16V40a16 16 0 0 1 16-16h144a16 16 0 0 1 16 16Zm-32 72a56 56 0 1 0-64 55.42v32.31a8.18 8.18 0 0 0 7.47 8.25 8 8 0 0 0 8.53-8v-32.56A56.09 56.09 0 0 0 184 112Z" /></Svg>;
export default GenderNeuter;