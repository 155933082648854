/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tornado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tornado-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tornado-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 40a4 4 0 0 1-4 4H56a4 4 0 0 1 0-8h168a4 4 0 0 1 4 4Zm-48 32a4 4 0 0 0-4-4H32a4 4 0 0 0 0 8h144a4 4 0 0 0 4-4Zm-16 32a4 4 0 0 0-4-4H56a4 4 0 0 0 0 8h104a4 4 0 0 0 4-4Zm16 32a4 4 0 0 0-4-4H88a4 4 0 0 0 0 8h88a4 4 0 0 0 4-4Zm4 28h-64a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Zm-24 32h-32a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8Zm-32 32h-16a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Z" /></Svg>;
export default Tornado;