/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Planet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="planet-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-planet-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.66 62.68c-6.73-11.58-26.58-13.8-56-6.3A92 92 0 0 0 37 141.7c-21.38 21.69-29.44 40-22.68 51.62 4.13 7.11 13 10.71 26 10.71A115.25 115.25 0 0 0 65 200.9c1.74-.39 3.52-.82 5.32-1.28A92 92 0 0 0 220 128a92.84 92.84 0 0 0-1-13.73c12.77-13 21.31-25.28 24-35.47 1.69-6.26 1.23-11.68-1.34-16.12ZM128 44a84.13 84.13 0 0 1 82.38 67.56c-16.17 15.69-38.8 32.48-64.49 47.24-27.31 15.69-52.81 26.25-73.61 32A84 84 0 0 1 128 44ZM21.25 189.3C17 182 23.75 167.7 39 151.24a92 92 0 0 0 24 41.85c-22.14 4.91-37.53 3.49-41.75-3.79ZM128 212a83.58 83.58 0 0 1-48-15.1c21.58-6.58 45.83-17.37 69.85-31.16 24.25-13.94 45.61-29.46 61.83-44.44.18 2.21.3 4.44.3 6.7A84.1 84.1 0 0 1 128 212ZM235.28 76.73c-2.13 8-8.58 17.65-18.25 28.06a92.07 92.07 0 0 0-24.12-41.92c21.85-4.82 37.59-3.46 41.83 3.83 1.46 2.53 1.65 5.9.54 10.03Z" /></Svg>;
export default Planet;