/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagBanner = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-banner__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-banner'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.76 51.73A8 8 0 0 0 232 48H40a8 8 0 0 0-5.66 13.66L76.69 104l-42.35 42.34A8 8 0 0 0 40 160h133.62l-28.84 60.56a8 8 0 1 0 14.44 6.88l80-168a8 8 0 0 0-.46-7.71ZM181.23 144H59.31l34.35-34.34a8 8 0 0 0 0-11.32L59.31 64h160Z" /></Svg>;
export default FlagBanner;