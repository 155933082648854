/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookOpenUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="book-open-user__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-book-open-user'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 72h-72a40 40 0 0 0-32 16 40 40 0 0 0-32-16H24a8 8 0 0 0-8 8v120a8 8 0 0 0 8 8h72a24 24 0 0 1 24 24 8 8 0 0 0 16 0 24 24 0 0 1 24-24h72a8 8 0 0 0 8-8V80a8 8 0 0 0-8-8ZM96 192H32V88h64a24 24 0 0 1 24 24v88a39.81 39.81 0 0 0-24-8Zm128 0h-64a39.81 39.81 0 0 0-24 8v-88a24 24 0 0 1 24-24h64ZM89.6 43.19a48 48 0 0 1 76.8 0 8 8 0 0 1-12.79 9.62 32 32 0 0 0-51.22 0 8 8 0 1 1-12.79-9.62Z" /></Svg>;
export default BookOpenUser;