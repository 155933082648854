/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LegoSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lego-smiley-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lego-smiley-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 122a10 10 0 1 1 10-10 10 10 0 0 1-10 10Zm56-20a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm-3.2 44.92a47 47 0 0 1-49.6 0 6 6 0 0 0-6.4 10.16 59 59 0 0 0 62.4 0 6 6 0 1 0-6.4-10.16ZM214 80v96a30.05 30.05 0 0 1-24 29.4V224a14 14 0 0 1-14 14H80a14 14 0 0 1-14-14v-18.6A30.05 30.05 0 0 1 42 176V80a30 30 0 0 1 30-30h18V32a14 14 0 0 1 14-14h48a14 14 0 0 1 14 14v18h18a30 30 0 0 1 30 30ZM102 50h52V32a2 2 0 0 0-2-2h-48a2 2 0 0 0-2 2Zm76 174v-18H78v18a2 2 0 0 0 2 2h96a2 2 0 0 0 2-2Zm24-144a18 18 0 0 0-18-18H72a18 18 0 0 0-18 18v96a18 18 0 0 0 18 18h112a18 18 0 0 0 18-18Z" /></Svg>;
export default LegoSmiley;