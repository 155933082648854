/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowsCounterClockwise = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrows-counter-clockwise-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrows-counter-clockwise-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 100H40a4 4 0 0 1-4-4V48a4 4 0 0 1 8 0v38.34l21.46-21.45A91.39 91.39 0 0 1 130 37.94h.51a91.43 91.43 0 0 1 64.29 26.19 4 4 0 0 1-5.6 5.72 83.44 83.44 0 0 0-58.68-23.91h-.47a83.52 83.52 0 0 0-58.94 24.6L49.66 92H88a4 4 0 0 1 0 8Zm128 56h-48a4 4 0 0 0 0 8h38.34l-21.45 21.46a83.52 83.52 0 0 1-58.89 24.6h-.47a83.44 83.44 0 0 1-58.73-23.91 4 4 0 0 0-5.6 5.72 91.43 91.43 0 0 0 64.28 26.19h.52a91.39 91.39 0 0 0 64.55-26.95L212 169.66V208a4 4 0 0 0 8 0v-48a4 4 0 0 0-4-4Z" /></Svg>;
export default ArrowsCounterClockwise;