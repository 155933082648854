/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SnapchatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="snapchat-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-snapchat-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M245.87 182.68a6 6 0 0 0-3.85-4.43c-.4-.14-30.71-11.53-44.87-52.25l21.08-8.43a6 6 0 1 0-4.46-11.14l-20 8A148.66 148.66 0 0 1 190 80a62 62 0 0 0-124 0 151.37 151.37 0 0 1-3.72 34.48l-20.05-8a6 6 0 0 0-4.46 11.14L58.93 126A96.13 96.13 0 0 1 40 158.87c-12.85 14.44-25.91 19.34-26 19.38a6 6 0 0 0-2.08 10c6.6 6.19 16.83 7.2 26.71 8.18 6.51.64 13.23 1.31 17.16 3.47 3.76 2.07 7.36 7 10.85 11.79 5.21 7.13 11.11 15.22 20.12 17.53 8.5 2.16 17.09-.76 25.4-3.59 5.72-1.94 11.11-3.78 15.86-3.78s10.14 1.84 15.86 3.78c6.29 2.14 12.74 4.34 19.19 4.34a25.36 25.36 0 0 0 6.21-.75c9-2.3 14.91-10.39 20.12-17.52 3.49-4.78 7.09-9.72 10.85-11.79 3.93-2.16 10.65-2.83 17.16-3.47 9.88-1 20.11-2 26.71-8.18a6 6 0 0 0 1.75-5.58Zm-29.66 1.84c-7.71.76-15.68 1.55-21.76 4.9s-10.5 9.39-14.77 15.22-8.56 11.74-13.39 13c-5 1.28-11.61-1-18.57-3.32-6.38-2.17-13-4.42-19.72-4.42s-13.34 2.25-19.72 4.42c-7 2.37-13.53 4.6-18.57 3.32-4.83-1.24-9.18-7.2-13.39-13s-8.67-11.88-14.77-15.23-14-4.14-21.76-4.9c-3.37-.33-6.79-.67-9.89-1.21a93.88 93.88 0 0 0 18.55-15.9c8.24-9.11 17.44-22.86 23.35-42.48a1.42 1.42 0 0 0 .08-.18 5.47 5.47 0 0 0 .35-1.27A156.21 156.21 0 0 0 78 80a50 50 0 0 1 100 0 156.21 156.21 0 0 0 5.77 43.51 5.34 5.34 0 0 0 .35 1.27.89.89 0 0 0 .08.17c5.91 19.63 15.11 33.38 23.35 42.49a93.88 93.88 0 0 0 18.55 15.9c-3.1.51-6.52.85-9.89 1.18Z" /></Svg>;
export default SnapchatLogo;