/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhosphorLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phosphor-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phosphor-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 32H72a8 8 0 0 0-8 8v128a80.09 80.09 0 0 0 80 80 8 8 0 0 0 8-8v-64a72 72 0 0 0 0-144Zm-16 199.5A64.14 64.14 0 0 1 80.51 176H136Zm0-94L85.68 48H136Zm16 22.5V48a56 56 0 0 1 0 112Z" /></Svg>;
export default PhosphorLogo;