/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinkBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="link-break__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-link-break'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M198.63 57.37a32 32 0 0 0-45.19-.06l-11.65 12.21a8 8 0 0 1-11.58-11l11.72-12.29a1.59 1.59 0 0 1 .13-.13 48 48 0 0 1 67.88 67.88 1.59 1.59 0 0 1-.13.13l-12.29 11.72a8 8 0 0 1-11-11.58l12.21-11.65a32 32 0 0 0-.1-45.23Zm-84.42 129.11-11.65 12.21a32 32 0 0 1-45.25-45.25l12.21-11.65a8 8 0 0 0-11-11.58l-12.33 11.72a1.59 1.59 0 0 0-.13.13 48 48 0 0 0 67.88 67.88 1.59 1.59 0 0 0 .13-.13l11.72-12.29a8 8 0 1 0-11.58-11ZM216 152h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16ZM40 104h24a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16Zm120 80a8 8 0 0 0-8 8v24a8 8 0 0 0 16 0v-24a8 8 0 0 0-8-8ZM96 72a8 8 0 0 0 8-8V40a8 8 0 0 0-16 0v24a8 8 0 0 0 8 8Z" /></Svg>;
export default LinkBreak;