/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImageSquare = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="image-square-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-image-square-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 24v63.72l-17.86-17.86a20 20 0 0 0-28.28 0L52 203.72V52ZM85.66 204 172 117.66l32 32V204ZM76 96a20 20 0 1 1 20 20 20 20 0 0 1-20-20Z" /></Svg>;
export default ImageSquare;