/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ladder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ladder-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ladder-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.27 211.9 168.77 73l7.63-21h7.6a12 12 0 0 0 0-24H88a12 12 0 0 0-1.11 23.94l-58.16 160a12 12 0 0 0 7.17 15.37 11.83 11.83 0 0 0 4.1.69 12 12 0 0 0 11.28-7.9L60 196h38.5l-5.78 15.9a12 12 0 0 0 7.18 15.37 11.79 11.79 0 0 0 4.1.73 12 12 0 0 0 11.28-7.9l17.49-48.1h46.46l17.49 48.1A12 12 0 0 0 208 228a11.83 11.83 0 0 0 4.1-.73 12 12 0 0 0 7.17-15.37ZM124.69 124H86.22L95 100h38.46Zm17.45-48h-38.46l8.73-24h38.46Zm-73.37 96 8.73-24H116l-8.73 24Zm72.73-24 14.5-39.89L170.5 148Z" /></Svg>;
export default Ladder;