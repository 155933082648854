/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Subway = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subway-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subway-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 96v112a12 12 0 0 1-24 0V96a52.06 52.06 0 0 0-52-52h-48a52.06 52.06 0 0 0-52 52v112a12 12 0 0 1-24 0V96a76.08 76.08 0 0 1 76-76h48a76.08 76.08 0 0 1 76 76Zm-40 0v72a28 28 0 0 1-18.89 26.47l2 5.07a12 12 0 0 1-6.68 15.6 11.86 11.86 0 0 1-4.43.86 12 12 0 0 1-11.14-7.54l-5-12.46h-31.74l-5 12.46A12 12 0 0 1 96 216a11.86 11.86 0 0 1-4.46-.86 12 12 0 0 1-6.68-15.6l2-5.07A28 28 0 0 1 68 168V96a28 28 0 0 1 28-28h64a28 28 0 0 1 28 28Zm-96 0v36h72V96a4 4 0 0 0-4-4H96a4 4 0 0 0-4 4Zm72 72v-12h-24v16h20a4 4 0 0 0 4-4Zm-48 4v-16H92v12a4 4 0 0 0 4 4Z" /></Svg>;
export default Subway;