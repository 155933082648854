/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandEye = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-eye-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-eye-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 180a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm72-76v48a88 88 0 0 1-176 0V64a16 16 0 0 1 32 0v56a8 8 0 0 0 16 0V32a16 16 0 0 1 32 0v80a8 8 0 0 0 16 0V48a16 16 0 0 1 32 0v80a8 8 0 0 0 16 0v-24a16 16 0 0 1 32 0Zm-36.42 74.21c-.7-1.4-17.5-34.21-51.58-34.21s-50.88 32.81-51.58 34.21a4 4 0 0 0 0 3.58c.7 1.4 17.5 34.21 51.58 34.21s50.88-32.81 51.58-34.21a4 4 0 0 0 0-3.58Z" /></Svg>;
export default HandEye;