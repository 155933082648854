/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Axe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="axe-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-axe-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M255.15 97.72A16 16 0 0 0 242 86.94a136.46 136.46 0 0 1-51.65-18l10.31-10.3a25 25 0 0 0-35.32-35.32l-13.2 13.21c-2.33-2.8-3.81-4.84-4.41-5.69a16 16 0 0 0-24.41-2.15L84.68 67.36a16 16 0 0 0 2.14 24.4c.86.6 2.9 2.08 5.7 4.41l-85.21 85.2a25 25 0 0 0 35.32 35.32l82.3-82.31a136.63 136.63 0 0 1 18 51.65 16 16 0 0 0 10.77 13.12 16.21 16.21 0 0 0 5.15.85 15.88 15.88 0 0 0 11.26-4.69l81.18-81.19a15.86 15.86 0 0 0 3.86-16.4ZM241 94.87Zm-64.31-60.24a9 9 0 1 1 12.68 12.68l-12.55 12.55a152.5 152.5 0 0 1-13.72-11.65ZM31.31 205.37a9 9 0 1 1-12.68-12.68l85.58-85.58a149.75 149.75 0 0 1 11.65 13.72Z" /></Svg>;
export default Axe;