/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CashRegister = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cash-register-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cash-register-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m237.82 158.54-22.48-89.93A14 14 0 0 0 201.75 58H134V40a14 14 0 0 0-14-14H80a14 14 0 0 0-14 14v18H54.25a14 14 0 0 0-13.59 10.6l-22.48 89.94A6 6 0 0 0 18 160v32a14 14 0 0 0 14 14h192a14 14 0 0 0 14-14v-32a6 6 0 0 0-.18-1.46ZM78 40a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v18H78ZM52.31 71.51A2 2 0 0 1 54.25 70h147.5a2 2 0 0 1 1.94 1.51L224.32 154H31.68ZM224 194H32a2 2 0 0 1-2-2v-26h196v26a2 2 0 0 1-2 2ZM66 96a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12H72a6 6 0 0 1-6-6Zm48 0a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12h-16a6 6 0 0 1-6-6Zm48 0a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12h-16a6 6 0 0 1-6-6Zm-96 32a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12H72a6 6 0 0 1-6-6Zm48 0a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12h-16a6 6 0 0 1-6-6Zm48 0a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12h-16a6 6 0 0 1-6-6Z" /></Svg>;
export default CashRegister;