/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Equalizer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="equalizer-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-equalizer-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M76 96a4 4 0 0 1-4 4H24a4 4 0 0 1 0-8h48a4 4 0 0 1 4 4Zm-4 28H24a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm0 32H24a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm0 32H24a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm80-64h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm0 32h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm0 32h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm80-96h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm-48-24h48a4 4 0 0 0 0-8h-48a4 4 0 0 0 0 8Zm48 56h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm0 32h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Zm0 32h-48a4 4 0 0 0 0 8h48a4 4 0 0 0 0-8Z" /></Svg>;
export default Equalizer;