/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiceSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dice-six-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dice-six-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 36H64a28 28 0 0 0-28 28v128a28 28 0 0 0 28 28h128a28 28 0 0 0 28-28V64a28 28 0 0 0-28-28Zm20 156a20 20 0 0 1-20 20H64a20 20 0 0 1-20-20V64a20 20 0 0 1 20-20h128a20 20 0 0 1 20 20ZM100 84a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm72 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-72 44a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm72 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-72 44a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm72 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default DiceSix;