/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PintGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pint-glass-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pint-glass-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M209 24a12 12 0 0 0-9-4H56a12 12 0 0 0-11.91 13.43l23.15 193A20 20 0 0 0 87.1 244h81.8a20 20 0 0 0 19.86-17.62l23.15-192.95A12 12 0 0 0 209 24Zm-22.53 20-1.92 16H71.45l-1.92-16Zm-21.12 176h-74.7L74.33 84h107.34Z" /></Svg>;
export default PintGlass;