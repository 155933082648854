/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Trolley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trolley-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trolley-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M86 224a14 14 0 1 1-14-14 14 14 0 0 1 14 14Zm130-14a14 14 0 1 0 14 14 14 14 0 0 0-14-14Zm24-32H54V75.31a13.94 13.94 0 0 0-4.1-9.9L28.24 43.76a6 6 0 1 0-8.48 8.48L41.41 73.9a2 2 0 0 1 .59 1.41V178H32a6 6 0 0 0 0 12h208a6 6 0 0 0 0-12Z" /></Svg>;
export default Trolley;