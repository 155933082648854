/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerHifi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-hifi-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-hifi-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 20H64a20 20 0 0 0-20 20v176a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V40a20 20 0 0 0-20-20Zm-4 192H68V44h120ZM112 76a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm16 120a44 44 0 1 0-44-44 44.05 44.05 0 0 0 44 44Zm0-64a20 20 0 1 1-20 20 20 20 0 0 1 20-20Z" /></Svg>;
export default SpeakerHifi;