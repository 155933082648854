/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Toilet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toilet-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toilet-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 64a4 4 0 0 1-4 4H96a4 4 0 0 1 0-8h16a4 4 0 0 1 4 4Zm52 130.86 3.92 27.44A12 12 0 0 1 160 236H96a12 12 0 0 1-11.88-13.7L88 194.86A92.11 92.11 0 0 1 36 112a4 4 0 0 1 4-4h20V40a12 12 0 0 1 12-12h112a12 12 0 0 1 12 12v68h20a4 4 0 0 1 4 4 92.11 92.11 0 0 1-52 82.86ZM68 108h120V40a4 4 0 0 0-4-4H72a4 4 0 0 0-4 4Zm92.34 90.13a92 92 0 0 1-64.68 0L92 223.43a4 4 0 0 0 .94 3.19A3.93 3.93 0 0 0 96 228h64a3.93 3.93 0 0 0 3-1.38 4 4 0 0 0 .94-3.19ZM211.91 116H44.09a84 84 0 0 0 167.82 0Z" /></Svg>;
export default Toilet;