/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitPullRequest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-pull-request-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-pull-request-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M108 64a36 36 0 1 0-48 33.94v60.12a36 36 0 1 0 24 0V97.94A36.07 36.07 0 0 0 108 64ZM72 52a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm0 152a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm140-45.94v-47.43a27.81 27.81 0 0 0-8.2-19.8L173 60h19a12 12 0 0 0 0-24h-48a12 12 0 0 0-12 12v48a12 12 0 0 0 24 0V77l30.83 30.83a4 4 0 0 1 1.17 2.83v47.43a36 36 0 1 0 24 0ZM200 204a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default GitPullRequest;