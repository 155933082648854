/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsSixVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-six-vertical-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-six-vertical-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32v192a16 16 0 0 1-16 16H64a16 16 0 0 1-16-16V32a16 16 0 0 1 16-16h128a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M104 60a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm60 12a12 12 0 1 0-12-12 12 12 0 0 0 12 12Zm-72 44a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm72 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm-72 68a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm72 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z" /></Svg>;
export default DotsSixVertical;