/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tilde = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tilde-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tilde-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 56v144a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h144a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M222.23 130.59c-14.51 18-28.84 27.6-43.8 29.17a43 43 0 0 1-4.5.24c-19.3 0-35.39-13.1-51-25.8-14.91-12.14-29-23.61-43.7-22-10.51 1.1-21.31 8.72-33 23.28a8 8 0 0 1-12.46-10c14.51-18 28.84-27.6 43.8-29.17 21.32-2.25 38.69 11.89 55.48 25.56 14.91 12.14 29 23.62 43.7 22 10.51-1.1 21.31-8.72 33-23.28a8 8 0 1 1 12.46 10Z" /></Svg>;
export default Tilde;