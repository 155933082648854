/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Taxi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="taxi-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-taxi-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 104h-11.36l-27.39-47.94A16 16 0 0 0 187.36 48h-21.94l-12-29.94A15.93 15.93 0 0 0 138.58 8h-21.16a15.93 15.93 0 0 0-14.86 10.06L90.58 48H68.64a16 16 0 0 0-13.89 8.06L27.36 104H16a8 8 0 0 0 0 16h8v80a16 16 0 0 0 16 16h24a16 16 0 0 0 16-16v-8h96v8a16 16 0 0 0 16 16h24a16 16 0 0 0 16-16v-80h8a8 8 0 0 0 0-16ZM80 152H56a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16Zm120 0h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16ZM45.79 104l22.85-40h118.72l22.85 40Z" /></Svg>;
export default Taxi;