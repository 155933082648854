/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChalkboardSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chalkboard-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chalkboard-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 188h-4v-28a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v28H44V60h168v60a12 12 0 0 0 24 0V56a20 20 0 0 0-20-20H40a20 20 0 0 0-20 20v132h-4a12 12 0 0 0 0 24h224a12 12 0 0 0 0-24Zm-68-16h40v16h-40Z" /></Svg>;
export default ChalkboardSimple;