/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sunglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sunglasses-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sunglasses-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 40a8 8 0 0 0 0 16 16 16 0 0 1 16 16v56H40V72a16 16 0 0 1 16-16 8 8 0 0 0 0-16 32 32 0 0 0-32 32v92a44 44 0 0 0 88 0v-20h32v20a44 44 0 0 0 88 0V72a32 32 0 0 0-32-32ZM91.22 179.22a8 8 0 0 1-11.31 0l-21.57-21.56a8 8 0 0 1 11.32-11.32l21.56 21.57a8 8 0 0 1 0 11.31Zm120 0a8 8 0 0 1-11.31 0l-21.57-21.56a8 8 0 0 1 11.32-11.32l21.56 21.57a8 8 0 0 1 0 11.31Z" /></Svg>;
export default Sunglasses;