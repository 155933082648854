/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-break-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-break-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 102c0 66-104 122-104 122S24 168 24 102a54 54 0 0 1 92.18-38.18L128 75.63l11.82-11.81A54 54 0 0 1 232 102Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M178 40a61.6 61.6 0 0 0-43.84 18.16L128 64.32l-6.16-6.16A62 62 0 0 0 16 102c0 70 103.79 126.67 108.21 129a8 8 0 0 0 7.58 0C136.21 228.67 240 172 240 102a62.07 62.07 0 0 0-62-62Zm-50 174.8c-18.26-10.64-96-59.11-96-112.8a46 46 0 0 1 78.53-32.53l6.16 6.16L106.34 86a8 8 0 0 0 0 11.31l24.53 24.53-16.53 16.52a8 8 0 0 0 11.32 11.32l22.18-22.19a8 8 0 0 0 0-11.31l-24.53-24.55 22.16-22.16A46 46 0 0 1 224 102c0 53.61-77.76 102.15-96 112.8Z" /></Svg>;
export default HeartBreak;