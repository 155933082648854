/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextHTwo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-h-two-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-h-two-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148 56v120a4 4 0 0 1-8 0v-56H44v56a4 4 0 0 1-8 0V56a4 4 0 0 1 8 0v56h96V56a4 4 0 0 1 8 0Zm92 148h-40l38.36-51.15a28 28 0 1 0-48.77-26.18 4 4 0 1 0 7.54 2.66A20.36 20.36 0 0 1 200 124a20 20 0 0 1 32 24l-43.2 57.6a4 4 0 0 0 3.2 6.4h48a4 4 0 0 0 0-8Z" /></Svg>;
export default TextHTwo;