/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-minus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-minus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M256 136a12 12 0 0 1-12 12h-40a12 12 0 0 1 0-24h40a12 12 0 0 1 12 12Zm-54.81 56.28a12 12 0 1 1-18.38 15.44C169.12 191.42 145 172 108 172c-28.89 0-55.46 12.68-74.81 35.72a12 12 0 0 1-18.38-15.44 124.08 124.08 0 0 1 48.69-35.75 72 72 0 1 1 89 0 124 124 0 0 1 48.69 35.75ZM108 148a48 48 0 1 0-48-48 48.05 48.05 0 0 0 48 48Z" /></Svg>;
export default UserMinus;