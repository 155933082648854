/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CubeFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cube-focus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cube-focus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 48v40a4 4 0 0 1-8 0V52h-36a4 4 0 0 1 0-8h40a4 4 0 0 1 4 4ZM72 204H36v-36a4 4 0 0 0-8 0v40a4 4 0 0 0 4 4h40a4 4 0 0 0 0-8Zm152-40a4 4 0 0 0-4 4v36h-36a4 4 0 0 0 0 8h40a4 4 0 0 0 4-4v-40a4 4 0 0 0-4-4ZM32 92a4 4 0 0 0 4-4V52h36a4 4 0 0 0 0-8H32a4 4 0 0 0-4 4v40a4 4 0 0 0 4 4Zm154 71.47-56 32a4 4 0 0 1-4 0l-56-32a4 4 0 0 1-2-3.47V96a4 4 0 0 1 2-3.47l56-32a4 4 0 0 1 4 0l56 32a4 4 0 0 1 2 3.47v64a4 4 0 0 1-2 3.47ZM80.06 96 128 123.39 175.94 96 128 68.61ZM76 157.68l48 27.43v-54.79l-48-27.43Zm104 0v-54.79l-48 27.43v54.79Z" /></Svg>;
export default CubeFocus;