/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ghost = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ghost-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ghost-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 120v96l-29.33-24-29.34 24L128 192l-29.33 24-29.34-24L40 216v-96a88 88 0 0 1 176 0Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M112 116a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm44-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm68 16v96a8 8 0 0 1-13.07 6.19l-24.26-19.85-24.27 19.85a8 8 0 0 1-10.13 0L128 202.34l-24.27 19.85a8 8 0 0 1-10.13 0l-24.27-19.85-24.26 19.85A8 8 0 0 1 32 216v-96a96 96 0 0 1 192 0Zm-16 0a80 80 0 0 0-160 0v79.12l16.27-13.31a8 8 0 0 1 10.13 0l24.27 19.85 24.26-19.85a8 8 0 0 1 10.14 0l24.26 19.85 24.27-19.85a8 8 0 0 1 10.13 0L208 199.12Z" /></Svg>;
export default Ghost;