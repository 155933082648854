/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cricket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cricket-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cricket-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m243.31 81.37-52.68-52.68a16 16 0 0 0-22.63 0L60.69 136a16 16 0 0 0 0 22.63l20.68 20.68-47 47a8 8 0 0 0 11.32 11.32l47-47 20.68 20.68a16 16 0 0 0 22.63 0L243.31 104a16 16 0 0 0 0-22.63ZM124.69 200 104 179.31l29.66-29.65a8 8 0 0 0-11.32-11.32L92.69 168 72 147.31 107.31 112H160v52.69ZM32 60a28 28 0 1 1 28 28 28 28 0 0 1-28-28Z" /></Svg>;
export default Cricket;