/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Webcam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webcam-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webcam-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 32a72 72 0 1 0 72 72 72 72 0 0 0-72-72Zm0 104a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M168 104a40 40 0 1 0-40 40 40 40 0 0 0 40-40Zm-64 0a24 24 0 1 1 24 24 24 24 0 0 1-24-24Zm120 96h-88v-16.4a80 80 0 1 0-16 0V200H32a8 8 0 0 0 0 16h192a8 8 0 0 0 0-16ZM64 104a64 64 0 1 1 64 64 64.07 64.07 0 0 1-64-64Z" /></Svg>;
export default Webcam;