/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceMobileSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-mobile-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-mobile-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.38 221.92a8 8 0 0 1-11.3-.54l-2.26-2.48A24 24 0 0 1 176 240H80a24 24 0 0 1-24-24V60.69L42.08 45.38a8 8 0 1 1 11.84-10.76l160 176a8 8 0 0 1-.54 11.3Zm-27.3-65.71a8 8 0 0 0 13.92-5.38V40a24 24 0 0 0-24-24H76.7a8 8 0 0 0-5.92 13.38Z" /></Svg>;
export default DeviceMobileSlash;