/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Plug = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plug-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plug-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.83 69.17a4 4 0 0 0-5.66 0L192 106.34 149.66 64l37.17-37.17a4 4 0 1 0-5.66-5.66L144 58.34l-29.17-29.17a4 4 0 0 0-5.66 5.66l9.17 9.17-55.8 55.8a36.05 36.05 0 0 0 0 50.91l18.55 18.54-51.92 51.92a4 4 0 0 0 5.66 5.66l51.92-51.92 18.54 18.55a36.06 36.06 0 0 0 50.91 0l55.8-55.8 9.17 9.17a4 4 0 0 0 5.66-5.66L197.66 112l37.17-37.17a4 4 0 0 0 0-5.66ZM150.54 187.8a28 28 0 0 1-39.59 0L68.2 145.05a28 28 0 0 1 0-39.59l55.8-55.8L206.34 132Z" /></Svg>;
export default Plug;