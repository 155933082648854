/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShippingContainer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shipping-container-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shipping-container-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.3 74.5 129.1 44.15a4.09 4.09 0 0 0-1.67-.11L22.3 59.06A12.06 12.06 0 0 0 12 70.94v114.12a12.06 12.06 0 0 0 10.3 11.88l105.13 15a3.64 3.64 0 0 0 .57 0 4.11 4.11 0 0 0 1.1-.15l106.2-30.29A12 12 0 0 0 244 170V86a12 12 0 0 0-8.7-11.5ZM96 124H76V59.47l48-6.86v150.78l-48-6.86V132h20a4 4 0 0 0 0-8Zm-76 61.06V70.94a4 4 0 0 1 3.43-4L68 60.61V124H48a4 4 0 0 0 0 8h20v63.39L23.43 189a4 4 0 0 1-3.43-3.94ZM236 170a4 4 0 0 1-2.9 3.84L132 202.7V53.3l101.1 28.89A4 4 0 0 1 236 86Z" /></Svg>;
export default ShippingContainer;