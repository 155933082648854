/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Playlist = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="playlist-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-playlist-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM64 72h128a8 8 0 0 1 0 16H64a8 8 0 0 1 0-16Zm0 48h72a8 8 0 0 1 0 16H64a8 8 0 0 1 0-16Zm40 64H64a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16Zm103.59-53.47a8 8 0 0 1-10.12 5.06L184 131.1V176a24 24 0 1 1-16-22.62V120a8 8 0 0 1 10.53-7.59l24 8a8 8 0 0 1 5.06 10.12Z" /></Svg>;
export default Playlist;