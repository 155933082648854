/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderMale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-male-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-male-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 28h-48a12 12 0 0 0 0 24h19l-32.72 32.74a84 84 0 1 0 17 17L204 69v19a12 12 0 0 0 24 0V40a12 12 0 0 0-12-12Zm-69.59 166.46a60 60 0 1 1 0-84.87 60.1 60.1 0 0 1 0 84.87Z" /></Svg>;
export default GenderMale;