/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Control = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="control-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-control-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 120H56l72-72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m205.66 114.34-72-72a8 8 0 0 0-11.32 0l-72 72A8 8 0 0 0 56 128h144a8 8 0 0 0 5.66-13.66ZM75.31 112 128 59.31 180.69 112Z" /></Svg>;
export default Control;