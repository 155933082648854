/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BluetoothX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-x-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-x-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M183.2 166.4 132 128l11.61-8.71a12 12 0 1 0-14.4-19.2L124 104V56l5.21 3.91a12 12 0 1 0 14.4-19.2L119.2 22.4A12 12 0 0 0 100 32v72L55.2 70.4a12 12 0 0 0-14.4 19.2L92 128l-51.2 38.4a12 12 0 1 0 14.4 19.2L100 152v72a12 12 0 0 0 19.2 9.6l64-48a12 12 0 0 0 0-19.2ZM124 200v-48l32 24ZM240.49 95.51a12 12 0 0 1-17 17L208 97l-15.51 15.52a12 12 0 1 1-17-17L191 80l-15.48-15.51a12 12 0 1 1 17-17L208 63l15.51-15.52a12 12 0 0 1 17 17L225 80Z" /></Svg>;
export default BluetoothX;