/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StandardDefinition = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="standard-definition-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-standard-definition-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 76a4 4 0 0 0-4 4v96a4 4 0 0 0 4 4h24a52 52 0 0 0 0-104Zm68 52a44.05 44.05 0 0 1-44 44h-20V84h20a44.05 44.05 0 0 1 44 44ZM28 48a4 4 0 0 1 4-4h192a4 4 0 0 1 0 8H32a4 4 0 0 1-4-4Zm200 160a4 4 0 0 1-4 4H32a4 4 0 0 1 0-8h192a4 4 0 0 1 4 4Zm-120-56c0-12.58-11.79-16.92-29.78-22.11C63 125.51 45.78 120.54 45.78 104c0-16 14.71-28 34.22-28 14.21 0 26.3 6.46 31.55 16.85a4 4 0 1 1-7.13 3.61C100.53 88.78 91.17 84 80 84c-15 0-26.22 8.6-26.22 20 0 9.81 10.07 13.43 26.66 18.21S116 132.46 116 152c0 15.44-16.15 28-36 28s-36-12.56-36-28a4 4 0 0 1 8 0c0 11 12.56 20 28 20s28-9 28-20Z" /></Svg>;
export default StandardDefinition;