/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Copyright = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="copyright-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-copyright-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 56a72 72 0 1 0 72 72 72.08 72.08 0 0 0-72-72Zm0 104a31.8 31.8 0 0 0 25.61-12.8 8 8 0 1 1 12.79 9.61 48 48 0 1 1 0-57.63 8 8 0 1 1-12.79 9.61A32 32 0 1 0 128 160Zm0-136a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Z" /></Svg>;
export default Copyright;