/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.28 177.85a16 16 0 0 1-18.38 5.07l-24.76-19a3.43 3.43 0 0 1-.53-.48l-83.43-91.82a4 4 0 0 1 2.55-6.68c43-4.62 87.74 9.12 119.86 41.24 19.99 19.99 21.92 49.46 4.69 71.67ZM53.93 34.62a8 8 0 1 0-11.84 10.76l27.62 30.39a142 142 0 0 0-45.3 30.41c-20 20-21.92 49.46-4.69 71.67a16 16 0 0 0 18.38 5.07l49-17.37.29-.11a16 16 0 0 0 9.75-11.72l5.9-29.51a73.64 73.64 0 0 1 8.57-2.39l90.5 99.56a8 8 0 1 0 11.84-10.76Z" /></Svg>;
export default PhoneSlash;