/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WashingMachine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="washing-machine-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-washing-machine-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12Zm4 172a4 4 0 0 1-4 4H48a4 4 0 0 1-4-4V48a4 4 0 0 1 4-4h160a4 4 0 0 1 4 4ZM128 68a60 60 0 1 0 60 60 60.07 60.07 0 0 0-60-60Zm0 112a52 52 0 1 1 52-52 52.06 52.06 0 0 1-52 52Zm68-112a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-73.17 46.83-16 16a4 4 0 0 1-5.66-5.66l16-16a4 4 0 0 1 5.66 5.66Zm32 2.34a4 4 0 0 1 0 5.66l-32 32a4 4 0 0 1-5.66-5.66l32-32a4 4 0 0 1 5.66 0Z" /></Svg>;
export default WashingMachine;