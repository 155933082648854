/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RadioButton = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-button__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-button'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm0-144a56 56 0 1 0 56 56 56.06 56.06 0 0 0-56-56Zm0 96a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" /></Svg>;
export default RadioButton;