/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GooglePhotosLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-photos-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-photos-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 88a63.69 63.69 0 0 1-14 40h-50V24a64 64 0 0 1 64 64ZM64 168a64 64 0 0 0 64 64V128H78a63.69 63.69 0 0 0-14 40Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 120h-39.51A72 72 0 0 0 128 16a8 8 0 0 0-8 8v39.51A72 72 0 0 0 16 128a8 8 0 0 0 8 8h39.51A72 72 0 0 0 128 240a8 8 0 0 0 8-8v-39.51A72 72 0 0 0 240 128a8 8 0 0 0-8-8ZM120 223.43A56.09 56.09 0 0 1 72 168a55.31 55.31 0 0 1 10-32h38ZM120 120H32.57A56.09 56.09 0 0 1 88 72a55.31 55.31 0 0 1 32 10Zm16-87.43A56.09 56.09 0 0 1 184 88a55.31 55.31 0 0 1-10 32h-38ZM168 184a55.31 55.31 0 0 1-32-10v-38h87.43A56.09 56.09 0 0 1 168 184Z" /></Svg>;
export default GooglePhotosLogo;