/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmScript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-script-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-script-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40v176a8 8 0 0 1-8 8H56a8 8 0 0 1-8-8V40a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm0 192H56V40h144v176ZM96 80a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm0 96a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm0-48a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default FilmScript;