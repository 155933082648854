/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cylinder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cylinder-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cylinder-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20c-38.13 0-68 17.57-68 40v136c0 22.43 29.87 40 68 40s68-17.57 68-40V60c0-22.43-29.87-40-68-40Zm0 8c32.52 0 60 14.65 60 32s-27.48 32-60 32-60-14.65-60-32 27.48-32 60-32Zm60 168c0 17.35-27.48 32-60 32s-60-14.65-60-32V79.11C79.35 91.65 101.71 100 128 100s48.65-8.35 60-20.89Z" /></Svg>;
export default Cylinder;