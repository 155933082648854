/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popsicle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popsicle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popsicle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 4a76.08 76.08 0 0 0-76 76v96a20 20 0 0 0 20 20h24v28a32 32 0 0 0 64 0v-28h24a20 20 0 0 0 20-20V80a76.08 76.08 0 0 0-76-76Zm8 220a8 8 0 0 1-16 0v-28h16Zm44-52H76V80a52 52 0 0 1 104 0Zm-60-96v68a12 12 0 0 1-24 0V76a12 12 0 0 1 24 0Zm40 0v68a12 12 0 0 1-24 0V76a12 12 0 0 1 24 0Z" /></Svg>;
export default Popsicle;