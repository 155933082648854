/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MarkerCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="marker-circle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-marker-circle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 211.16Zm-96 0ZM224 128a96 96 0 1 0-144 83.16V176a8 8 0 0 1 8-8h8v-24a8 8 0 0 1 8-8h4.16l12-57.63c1.77-8.49 13.89-8.49 15.66 0l12 57.63H152a8 8 0 0 1 8 8v24h8a8 8 0 0 1 8 8v35.16A96 96 0 0 0 224 128Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M201.54 54.46A104 104 0 0 0 54.46 201.54 104 104 0 0 0 201.54 54.46ZM88 206.37V176h80v30.37a88.38 88.38 0 0 1-80 0ZM104 144h48v16h-48Zm14-16 10-48 10 48Zm72.22 62.23c-2 2-4.08 3.87-6.22 5.64V176a16 16 0 0 0-16-16v-16a16 16 0 0 0-13.61-15.8l-10.73-51.46a16 16 0 0 0-31.32 0l-10.73 51.46A16 16 0 0 0 88 144v16a16 16 0 0 0-16 16v19.87a88.917 88.917 0 0 1-6.22-5.64 88 88 0 1 1 124.44 0Z" /></Svg>;
export default MarkerCircle;