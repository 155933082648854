/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TruckTrailer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="truck-trailer-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-truck-trailer-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 93.65A60.08 60.08 0 0 0 164 36a20 20 0 0 0-20 20v116h-16V72a12 12 0 0 0-12-12H20A20 20 0 0 0 0 80v104a36 36 0 0 0 60 26.8 36 36 0 0 0 57.94-14.8h68.12A36 36 0 0 0 256 184v-48a44.08 44.08 0 0 0-32-42.35Zm-56-33.43A36.06 36.06 0 0 1 200 96v8a12 12 0 0 0 12 12 20 20 0 0 1 20 20v14.06A36 36 0 0 0 186.06 172H168ZM104 84v70.08a35.92 35.92 0 0 0-44 3.12 35.93 35.93 0 0 0-36-7.14V84ZM36 196a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm48 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm136 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default TruckTrailer;