/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TelevisionSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="television-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-television-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 66h-73.52l37.76-37.76a6 6 0 0 0-8.48-8.48L128 63.51 84.24 19.76a6 6 0 1 0-8.48 8.48L113.52 66H40a14 14 0 0 0-14 14v120a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V80a14 14 0 0 0-14-14Zm2 134a2 2 0 0 1-2 2H40a2 2 0 0 1-2-2V80a2 2 0 0 1 2-2h176a2 2 0 0 1 2 2Z" /></Svg>;
export default TelevisionSimple;