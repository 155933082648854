/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagPennant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-pennant-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-pennant-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m243.94 92.67-184-64A12 12 0 0 0 44 40v176a12 12 0 0 0 24 0v-39.47l175.94-61.2a12 12 0 0 0 0-22.66ZM68 151.12V56.88L203.47 104Z" /></Svg>;
export default FlagPennant;