/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segment-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segment-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.81 44.19a28 28 0 0 0-42.23 36.57l-88.82 88.82a28 28 0 0 0-36.57 2.63 28 28 0 1 0 42.23 3l88.82-88.82a28 28 0 0 0 36.57-42.23Zm-133.67 162a20 20 0 1 1 0-28.28 20 20 0 0 1 0 28.24Zm128-128a20 20 0 0 1-28.28 0 20 20 0 1 1 28.28 0Z" /></Svg>;
export default LineSegment;