/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-half-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-half-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M169.1 54.82A6 6 0 1 0 166.9 43c-16.6 3.13-30.25 11.82-38.9 24.4C117.3 51.5 99 42 78 42a60.07 60.07 0 0 0-60 60c0 29.2 18.2 59.59 54.1 90.31a334.68 334.68 0 0 0 53.06 37 5.93 5.93 0 0 0 5.68 0 329.42 329.42 0 0 0 40.82-27 6 6 0 0 0-7.32-9.51A341.88 341.88 0 0 1 134 213.56V81.28c6-13.78 18.76-23.4 35.1-26.46ZM122 213.54C97.4 198.51 30 152.7 30 102a48.05 48.05 0 0 1 48-48c19.87 0 36.62 10.4 44 27.22ZM232.33 102a5 5 0 0 1-.67 0 6 6 0 0 1-5.95-5.34 47.89 47.89 0 0 0-21.05-34.58 6 6 0 1 1 6.68-10 59.85 59.85 0 0 1 26.29 43.23 6 6 0 0 1-5.3 6.69Zm-3.66 36.72c-5.58 11.2-13.75 22.65-24.26 34a6 6 0 0 1-8.82-8.15c9.75-10.54 17.27-21.05 22.35-31.24a6 6 0 1 1 10.73 5.36Z" /></Svg>;
export default HeartHalf;