/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Folders = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folders-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folders-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 56h-68l-26.67-20a20.12 20.12 0 0 0-12-4H76a20 20 0 0 0-20 20v20H36a20 20 0 0 0-20 20v112a20 20 0 0 0 20 20h152.89A19.13 19.13 0 0 0 208 204.89V184h20.89A19.13 19.13 0 0 0 248 164.89V76a20 20 0 0 0-20-20Zm-44 144H40V96h40l28.8 21.6a12 12 0 0 0 7.2 2.4h68Zm40-40h-16v-44a20 20 0 0 0-20-20h-68L93.33 76a20.12 20.12 0 0 0-12-4H80V56h40l28.8 21.6A12 12 0 0 0 156 80h68Z" /></Svg>;
export default Folders;