/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sunglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sunglasses-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sunglasses-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 44a4 4 0 0 0 0 8 20 20 0 0 1 20 20v60H36V72a20 20 0 0 1 20-20 4 4 0 0 0 0-8 28 28 0 0 0-28 28v92a40 40 0 0 0 80 0v-24h40v24a40 40 0 0 0 80 0V72a28 28 0 0 0-28-28ZM36 164v-24h2.34l49.27 49.26A32 32 0 0 1 36 164Zm64 0a31.83 31.83 0 0 1-6.74 19.61L49.66 140H100Zm56 0v-24h2.34l49.27 49.26A32 32 0 0 1 156 164Zm57.26 19.61L169.66 140H220v24a31.83 31.83 0 0 1-6.74 19.61Z" /></Svg>;
export default Sunglasses;