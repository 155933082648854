/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SolarPanel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="solar-panel-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-solar-panel-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 104a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16H40a8 8 0 0 1-8-8Zm39.43-45.25a8 8 0 0 0 11.32-11.32L71.43 36.12a8 8 0 0 0-11.31 11.31ZM128 40a8 8 0 0 0 8-8V16a8 8 0 0 0-16 0v16a8 8 0 0 0 8 8Zm50.91 21.09a8 8 0 0 0 5.66-2.34l11.31-11.32a8 8 0 0 0-11.31-11.31l-11.32 11.31a8 8 0 0 0 5.66 13.66ZM192 104a8 8 0 0 0 8 8h16a8 8 0 0 0 0-16h-16a8 8 0 0 0-8 8Zm-104 8a8 8 0 0 0 8-8 32 32 0 0 1 64 0 8 8 0 0 0 16 0 48 48 0 0 0-96 0 8 8 0 0 0 8 8Zm55.2 24h-30.4a4 4 0 0 0-3.91 3.15L102.62 168h50.76l-6.27-28.85a4 4 0 0 0-3.91-3.15ZM31.75 186 17 212.06a8 8 0 0 0 1.16 9.45 8.22 8.22 0 0 0 6 2.49h46.69a4 4 0 0 0 3.91-3.15l8-36.85H35.23a4 4 0 0 0-3.48 2Zm207.21 26-14.71-26a4 4 0 0 0-3.48-2h-47.54l8 36.85a4 4 0 0 0 3.91 3.15h46.62a8.22 8.22 0 0 0 6-2.49 8 8 0 0 0 1.24-9.45Zm-28.27-50-12.42-22a8 8 0 0 0-7-4.06h-23.51a4 4 0 0 0-3.91 4.85l5.9 27.15h37.45a4 4 0 0 0 3.49-5.94ZM88.24 136H64.7a8 8 0 0 0-7 4.06L45.31 162a4 4 0 0 0 3.49 6h37.45l5.9-27.15a4 4 0 0 0-3.91-4.85Zm68.62 48H99.14l-7.64 35.15a4 4 0 0 0 3.91 4.85h65.18a4 4 0 0 0 3.91-4.85Z" /></Svg>;
export default SolarPanel;