/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Intersection = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersection-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersection-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 120v80a12 12 0 0 1-24 0v-80a60 60 0 0 0-120 0v80a12 12 0 0 1-24 0v-80a84 84 0 0 1 168 0Z" /></Svg>;
export default Intersection;