/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bird = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bird-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bird-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174 68a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm64 12a6 6 0 0 1-2.67 5L214 99.21V120a102.12 102.12 0 0 1-102 102H24a14 14 0 0 1-10.93-22.75l.07-.09L98 97.35V76.89c0-32.32 25.89-58.74 57.72-58.89h.28a58 58 0 0 1 55.51 41.13L235.33 75a6 6 0 0 1 2.67 5Zm-16.82 0L203 67.88a6 6 0 0 1-2.48-3.48A46 46 0 0 0 156 30h-.23C130.53 30.12 110 51.16 110 76.89v22.63a6 6 0 0 1-1.39 3.85L22.43 206.78A2 2 0 0 0 24 210h27.86l71.53-85.84a6 6 0 0 1 9.22 7.68L67.48 210H112a90.1 90.1 0 0 0 90-90V96a6 6 0 0 1 2.67-5Z" /></Svg>;
export default Bird;