/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldChevron = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-chevron-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-chevron-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H48a20 20 0 0 0-20 20v56c0 54.29 26.32 87.22 48.4 105.29 23.71 19.39 47.44 26 48.44 26.29a12.1 12.1 0 0 0 6.32 0c1-.28 24.73-6.9 48.44-26.29 22.08-18.07 48.4-51 48.4-105.29V56a20 20 0 0 0-20-20ZM52 60h152v52a119.54 119.54 0 0 1-7 41.64l-62.11-43.47a12 12 0 0 0-13.76 0L59 153.64A119.54 119.54 0 0 1 52 112Zm113.09 138.15A126.28 126.28 0 0 1 128 219.38a126.14 126.14 0 0 1-37.09-21.23A109 109 0 0 1 70 175.23l58-40.58 58 40.58a109 109 0 0 1-20.91 22.92Z" /></Svg>;
export default ShieldChevron;