/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleSki = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-ski-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-ski-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 60a28 28 0 1 1 28 28 28 28 0 0 1-28-28ZM37.76 87.68l111 32.36 5.61 5.61A8 8 0 0 0 160 128h40a8 8 0 0 0 0-16h-36.69l-4.72-4.72-24.9-24.9a8 8 0 0 0-11.32 0L112 92.67 42.24 72.32a8 8 0 0 0-4.48 15.36Zm200.9 105.47a8 8 0 0 0-11.1-2.22 53.78 53.78 0 0 1-45 6.9l-62.79-18.28 29.9-29.9a8 8 0 0 0-3.46-13.35l-56-16a8 8 0 0 0-4.4 15.39L128.73 148l-26.5 26.49-76-22.13a8 8 0 1 0-4.48 15.36l156.31 45.52a69.78 69.78 0 0 0 58.37-8.95 8 8 0 0 0 2.23-11.14Z" /></Svg>;
export default PersonSimpleSki;