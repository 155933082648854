/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiscoBall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="disco-ball-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-disco-ball-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M118 66.23V16a6 6 0 0 0-12 0v50.23a86 86 0 1 0 12 0ZM185.74 146h-35.87c-1.3-32.59-13-54.15-22.36-66.35A74.15 74.15 0 0 1 185.74 146Zm-99.6 12h51.72c-1.63 37.69-18.33 58.46-25.86 66-7.54-7.55-24.23-28.33-25.86-66Zm0-12c1.63-37.69 18.33-58.46 25.86-66 7.54 7.51 24.23 28.29 25.86 66Zm10.35-66.35c-9.38 12.2-21.06 33.76-22.36 66.35H38.26a74.15 74.15 0 0 1 58.23-66.35ZM38.26 158h35.87c1.3 32.59 13 54.15 22.36 66.35A74.15 74.15 0 0 1 38.26 158Zm89.25 66.35c9.38-12.2 21.06-33.76 22.36-66.35h35.87a74.15 74.15 0 0 1-58.23 66.35ZM254 88a6 6 0 0 1-6 6h-10v10a6 6 0 0 1-12 0V94h-10a6 6 0 0 1 0-12h10V72a6 6 0 0 1 12 0v10h10a6 6 0 0 1 6 6Zm-46-42h-18v18a6 6 0 0 1-12 0V46h-18a6 6 0 0 1 0-12h18V16a6 6 0 0 1 12 0v18h18a6 6 0 0 1 0 12Z" /></Svg>;
export default DiscoBall;