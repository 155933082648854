/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cigarette = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cigarette-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cigarette-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 132H32a12 12 0 0 0-12 12v32a12 12 0 0 0 12 12h192a12 12 0 0 0 12-12v-32a12 12 0 0 0-12-12ZM28 176v-32a4 4 0 0 1 4-4h52v40H32a4 4 0 0 1-4-4Zm200 0a4 4 0 0 1-4 4H92v-40h132a4 4 0 0 1 4 4ZM204.51 62c8.22-14.61 8.21-23.23 6.75-27.9a10.82 10.82 0 0 0-4.94-6.51 4 4 0 0 1-2-5.26 4 4 0 0 1 5.3-2c.66.29 6.52 3.09 9.16 11 3 9 .53 20.66-7.29 34.57-8.22 14.61-8.21 23.24-6.75 27.91a10.74 10.74 0 0 0 4.94 6.5A4 4 0 0 1 208 108a4.09 4.09 0 0 1-1.63-.35c-.66-.3-6.52-3.09-9.16-11-2.98-9.06-.52-20.65 7.3-34.65Zm-40 0c8.22-14.61 8.21-23.23 6.75-27.9a10.82 10.82 0 0 0-4.94-6.51 4 4 0 0 1-2-5.26 4 4 0 0 1 5.3-2c.66.29 6.52 3.09 9.16 11 3 9 .53 20.66-7.29 34.57-8.22 14.61-8.21 23.24-6.75 27.91a10.74 10.74 0 0 0 4.94 6.5A4 4 0 0 1 168 108a4.09 4.09 0 0 1-1.63-.35c-.66-.3-6.52-3.09-9.16-11-2.98-9.06-.52-20.65 7.3-34.65Z" /></Svg>;
export default Cigarette;