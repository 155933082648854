/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CirclesThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circles-three-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circles-three-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 76a40 40 0 1 0-40 40 40 40 0 0 0 40-40Zm-40 32a32 32 0 1 1 32-32 32 32 0 0 1-32 32Zm60 24a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 72a32 32 0 1 1 32-32 32 32 0 0 1-32 32ZM68 132a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm0 72a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" /></Svg>;
export default CirclesThree;