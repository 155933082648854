/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Wine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wine-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wine-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M203.41 104.23 181.64 30.3a6 6 0 0 0-5.75-4.3H80.11a6 6 0 0 0-5.75 4.3l-21.77 73.93a61.48 61.48 0 0 0 16.87 62.65A85.35 85.35 0 0 0 122 189.77V234H88a6 6 0 1 0 0 12h80a6 6 0 1 0 0-12h-34v-44.23a85.38 85.38 0 0 0 52.54-22.89 61.48 61.48 0 0 0 16.87-62.65ZM84.6 38h86.8l20.51 69.63c.26.9.5 1.8.71 2.7-22.54 9.07-48.17-.73-61.91-7.68-26.61-13.48-47.23-14.31-61.88-11.13Zm93.75 120.11a73.67 73.67 0 0 1-100.7 0 49.53 49.53 0 0 1-13.56-50.48l.74-2.51c12.34-4.46 32.26-6.05 60.46 8.23 11 5.55 28.65 12.64 47.52 12.64a65.56 65.56 0 0 0 21.19-3.37 49.22 49.22 0 0 1-15.65 35.49Z" /></Svg>;
export default Wine;