/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BluetoothSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bluetooth-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bluetooth-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m224.88 207.93-160-176a12 12 0 0 0-17.76 16.14L115 122.75 56.8 166.4a12 12 0 1 0 14.4 19.2L116 152v72a12 12 0 0 0 19.2 9.6l47.91-35.94 24 26.41a12 12 0 0 0 17.76-16.14ZM140 200v-48l5 3.77 21.87 24.06ZM116 59.74V32a12 12 0 0 1 19.2-9.6l64 48a12 12 0 0 1 0 19.2l-27.1 20.33a12 12 0 0 1-14.4-19.2L172 80l-32-24v3.74a12 12 0 0 1-24 0Z" /></Svg>;
export default BluetoothSlash;