/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Basket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="basket-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-basket-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238 82.73a8 8 0 0 0-6-2.73h-44.37L134 18.73a8 8 0 0 0-12 0L68.37 80H24a8 8 0 0 0-7.93 9.06l15.07 113.06A16.06 16.06 0 0 0 47 216h162a16.06 16.06 0 0 0 15.86-13.88l15.07-113.06a8 8 0 0 0-1.93-6.33ZM81.6 184a7.32 7.32 0 0 1-.81 0 8 8 0 0 1-8-7.2l-5.6-56a8 8 0 0 1 15.92-1.6l5.6 56a8 8 0 0 1-7.11 8.8Zm54.4-8a8 8 0 0 1-16 0v-56a8 8 0 0 1 16 0ZM89.63 80 128 36.15 166.37 80Zm99.13 40.8-5.6 56a8 8 0 0 1-7.95 7.2 7.32 7.32 0 0 1-.81 0 8 8 0 0 1-7.16-8.76l5.6-56a8 8 0 0 1 15.92 1.6Z" /></Svg>;
export default Basket;