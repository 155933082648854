/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const YinYang = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="yin-yang-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-yin-yang-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26ZM38 128a90.1 90.1 0 0 1 90-90 42 42 0 0 1 0 84 54 54 0 0 0-44.88 84A90.06 90.06 0 0 1 38 128Zm90 90a42 42 0 0 1 0-84 54 54 0 0 0 44.88-84A90 90 0 0 1 128 218Zm10-42a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-20-96a10 10 0 1 1 10 10 10 10 0 0 1-10-10Z" /></Svg>;
export default YinYang;