/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Star = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243 96a20.33 20.33 0 0 0-17.74-14l-56.59-4.57-21.84-52.81a20.36 20.36 0 0 0-37.66 0L87.35 77.44 30.76 82a20.45 20.45 0 0 0-11.66 35.88l43.18 37.24-13.2 55.7A20.37 20.37 0 0 0 79.57 233L128 203.19 176.43 233a20.39 20.39 0 0 0 30.49-22.15l-13.2-55.7 43.18-37.24A20.43 20.43 0 0 0 243 96Zm-70.47 45.7a12 12 0 0 0-3.84 11.86L181.58 208l-47.29-29.08a12 12 0 0 0-12.58 0L74.42 208l12.89-54.4a12 12 0 0 0-3.84-11.86l-42.27-36.5 55.4-4.47a12 12 0 0 0 10.13-7.38L128 41.89l21.27 51.5a12 12 0 0 0 10.13 7.38l55.4 4.47Z" /></Svg>;
export default Star;