/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WaveTriangle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wave-triangle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wave-triangle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m241.73 135-52 72a12 12 0 0 1-19.46 0L76 76.5 33.73 135a12 12 0 1 1-19.46-14l52-72a12 12 0 0 1 19.46 0L180 179.5l42.27-58.5a12 12 0 1 1 19.46 14Z" /></Svg>;
export default WaveTriangle;