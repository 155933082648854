/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const JarLabel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="jar-label__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-jar-label'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 48.81V32a16 16 0 0 0-16-16H88a16 16 0 0 0-16 16v16.81A40.05 40.05 0 0 0 40 88v112a40 40 0 0 0 40 40h96a40 40 0 0 0 40-40V88a40.05 40.05 0 0 0-32-39.19ZM56 112h144v64H56Zm112-64h-16V32h16Zm-48 0V32h16v16Zm-16-16v16H88V32ZM80 64h96a24 24 0 0 1 24 24v8H56v-8a24 24 0 0 1 24-24Zm96 160H80a24 24 0 0 1-24-24v-8h144v8a24 24 0 0 1-24 24Z" /></Svg>;
export default JarLabel;