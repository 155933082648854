/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vibrate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vibrate-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vibrate-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 36H96a20 20 0 0 0-20 20v144a20 20 0 0 0 20 20h64a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20Zm12 164a12 12 0 0 1-12 12H96a12 12 0 0 1-12-12V56a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12Zm40-112v80a4 4 0 0 1-8 0V88a4 4 0 0 1 8 0Zm32 16v48a4 4 0 0 1-8 0v-48a4 4 0 0 1 8 0ZM52 88v80a4 4 0 0 1-8 0V88a4 4 0 0 1 8 0Zm-32 16v48a4 4 0 0 1-8 0v-48a4 4 0 0 1 8 0Z" /></Svg>;
export default Vibrate;