/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberFive = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-five-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-five-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm-76 80a44 44 0 1 1-34.22 71.66 8 8 0 0 1 12.44-10.06 28 28 0 1 0 .35-35.62 8 8 0 0 1-14-6.29l7.55-52.82A8 8 0 0 1 104 64h56a8 8 0 0 1 0 16h-49.06L107 107.4a44 44 0 0 1 17-3.4Z" /></Svg>;
export default NumberFive;