/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CalendarHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calendar-heart-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calendar-heart-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28h-20v-4a12 12 0 0 0-24 0v4H92v-4a12 12 0 0 0-24 0v4H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 176H52V52h16a12 12 0 0 0 24 0h72a12 12 0 0 0 24 0h16ZM152 84a35.86 35.86 0 0 0-24 9.19A36 36 0 0 0 68 120c0 17 9.53 33.56 28.32 49.22a151.47 151.47 0 0 0 26.31 17.51 12 12 0 0 0 10.74 0 151.47 151.47 0 0 0 26.31-17.51C178.47 153.56 188 137 188 120a36 36 0 0 0-36-36Zm-24 78.29c-13.15-7.86-36-25.06-36-42.29a12 12 0 0 1 24 0 12 12 0 0 0 24 0 12 12 0 0 1 24 0c0 17.23-22.86 34.44-36 42.29Z" /></Svg>;
export default CalendarHeart;