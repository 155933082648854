/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Goggles = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="goggles-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-goggles-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M254 136a70.08 70.08 0 0 0-70-70H72a70.08 70.08 0 0 0-70 70 22 22 0 0 0 16.68 21.35c2.06 8.24 8.85 16.06 19.64 22.44S61.87 190 72 190a38.05 38.05 0 0 0 37.52-32h37A38.05 38.05 0 0 0 184 190c10.13 0 23-3.91 33.68-10.21s17.58-14.2 19.64-22.44A22 22 0 0 0 254 136ZM44.43 169.46C35.26 164 30 157.67 30 152c0-6.64 6.77-13.19 15.45-18.06l40.19 40.18A25.81 25.81 0 0 1 72 178c-7.86 0-18.94-3.43-27.57-8.54Zm49.69-3.82-36.88-36.89A48.84 48.84 0 0 1 72 126a26 26 0 0 1 22.12 39.64ZM158 152a25.89 25.89 0 0 1 7.39-18.13L204.55 173c-7.07 3.07-14.63 5-20.55 5a26 26 0 0 1-26-26Zm57.46 15-39.65-39.64A25.84 25.84 0 0 1 184 126c7.86 0 18.94 3.43 27.57 8.54C220.74 140 226 146.33 226 152c0 5.32-4.35 10.58-10.54 15Zm21.31-22.18c-2.57-7.56-9.12-14.68-19.09-20.58C207 117.91 194.13 114 184 114a38.05 38.05 0 0 0-37.52 32h-37A38.05 38.05 0 0 0 72 114c-10.13 0-23 3.91-33.68 10.21-10 5.9-16.52 13-19.09 20.58A10 10 0 0 1 14 136a58.07 58.07 0 0 1 58-58h112a58.07 58.07 0 0 1 58 58 10 10 0 0 1-5.23 8.79Z" /></Svg>;
export default Goggles;