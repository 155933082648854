/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DeviceTablet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="device-tablet-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-device-tablet-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 20H64a28 28 0 0 0-28 28v160a28 28 0 0 0 28 28h128a28 28 0 0 0 28-28V48a28 28 0 0 0-28-28ZM60 84h136v88H60Zm4-40h128a4 4 0 0 1 4 4v12H60V48a4 4 0 0 1 4-4Zm128 168H64a4 4 0 0 1-4-4v-12h136v12a4 4 0 0 1-4 4Z" /></Svg>;
export default DeviceTablet;