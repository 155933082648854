/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListBullets = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-bullets-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-bullets-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M82 64a6 6 0 0 1 6-6h128a6 6 0 0 1 0 12H88a6 6 0 0 1-6-6Zm134 58H88a6 6 0 0 0 0 12h128a6 6 0 0 0 0-12Zm0 64H88a6 6 0 0 0 0 12h128a6 6 0 0 0 0-12ZM44 54a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0 128a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0-64a10 10 0 1 0 10 10 10 10 0 0 0-10-10Z" /></Svg>;
export default ListBullets;