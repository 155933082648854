/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImageBroken = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="image-broken__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-image-broken'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h64a8 8 0 0 0 7.59-5.47l14.83-44.48L163 151.43a8.07 8.07 0 0 0 4.46-4.46l14.62-36.55 44.48-14.83A8 8 0 0 0 232 88V56a16 16 0 0 0-16-16ZM112.41 157.47 98.23 200H40v-28l52-52 30.42 30.42-5.42 2.15a8 8 0 0 0-4.59 4.9ZM216 82.23l-42.53 14.18a8 8 0 0 0-4.9 4.62l-14.72 36.82-15.27 6.15-35.27-35.27a16 16 0 0 0-22.62 0L40 149.37V56h176Zm12.68 33a8 8 0 0 0-7.21-1.1l-23.8 7.94a8 8 0 0 0-4.9 4.61l-14.31 35.77-35.77 14.31a8 8 0 0 0-4.61 4.9l-7.94 23.8a8 8 0 0 0 7.59 10.54H216a16 16 0 0 0 16-16v-78.27a8 8 0 0 0-3.32-6.49ZM216 200h-67.17l3.25-9.75 35.51-14.2a8.07 8.07 0 0 0 4.46-4.46l14.2-35.51 9.75-3.25Z" /></Svg>;
export default ImageBroken;