/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Signature = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="signature-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-signature-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80.3 120.26a58.29 58.29 0 0 1 .7-23.19C83.32 87 87.89 80 92.1 80c2.57 0 2.94.67 3.12 1 .88 1.61 4 10.93-12.63 46.52a28.87 28.87 0 0 1-2.29-7.26ZM232 56v144a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16ZM84 160c2-3.59 3.94-7.32 5.9-11.14 10.34-.32 22.21-7.57 35.47-21.68 5 9.69 11.38 15.25 18.87 16.55 8 1.38 16-2.38 23.94-11.2 6 5.53 16.15 11.47 31.8 11.47a8 8 0 0 0 0-16c-17.91 0-24.3-10.88-24.84-11.86a7.83 7.83 0 0 0-6.54-4.51 8 8 0 0 0-7.25 3.6c-6.78 10-11.87 13.16-14.39 12.73-4-.69-9.15-10-11.23-18a8 8 0 0 0-14-3c-8.88 10.94-16.3 17.79-22.13 21.66 15.8-35.65 13.27-48.59 9.6-55.3-1.85-3.48-6.61-9.32-17.1-9.32-12.44 0-22.42 11-26.69 29.46a75 75 0 0 0-.83 29.81c1.7 8.9 5.17 15.73 10.16 20.12-3 5.81-6.09 11.43-9 16.61H56a8 8 0 0 0 0 16h.44c-4.26 7.12-7.11 11.59-7.18 11.69a8 8 0 0 0 13.48 8.62c.36-.55 5.47-8.57 12.29-20.31H200a8 8 0 0 0 0-16Z" /></Svg>;
export default Signature;