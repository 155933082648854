/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pentagon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pentagon-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pentagon-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m231.26 105.19-32 107.54-.06.17A15.94 15.94 0 0 1 184 224H72a15.94 15.94 0 0 1-15.2-11.1l-.06-.17-32-107.54a16 16 0 0 1 5.7-17.63l87.92-68.31.18-.14a15.93 15.93 0 0 1 18.92 0l.18.14 87.92 68.31a16 16 0 0 1 5.7 17.63Z" /></Svg>;
export default Pentagon;