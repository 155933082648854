/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyCny = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-cny-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-cny-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 16a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 16ZM88 72h80a8 8 0 0 1 0 16H88a8 8 0 0 1 0-16Zm104 88a8 8 0 0 1-8 8h-24a24 24 0 0 1-24-24v-24h-16a48.05 48.05 0 0 1-48 48 8 8 0 0 1 0-16 32 32 0 0 0 32-32H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16h-24v24a8 8 0 0 0 8 8h16v-8a8 8 0 0 1 16 0Z" /></Svg>;
export default CurrencyCny;