/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegments = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segments__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segments'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.64 33.36a32 32 0 0 0-45.26 0 32 32 0 0 0 0 45.26c.29.29.6.57.9.85l-26.63 49.46a32.19 32.19 0 0 0-23.9 3.5l-20.18-20.18a32 32 0 0 0-50.2-38.89 32 32 0 0 0 0 45.26c.29.29.59.57.89.85l-26.63 49.47a32 32 0 0 0-30.27 8.44 32 32 0 1 0 45.26 0c-.29-.29-.6-.57-.9-.85l26.63-49.46A32.4 32.4 0 0 0 96 128a32 32 0 0 0 16.25-4.41l20.18 20.18a32 32 0 1 0 50.2-6.38c-.29-.29-.59-.57-.89-.85l26.63-49.46A32.33 32.33 0 0 0 216 88a32 32 0 0 0 22.63-54.62ZM51.3 211.33a16 16 0 0 1-22.63-22.64 16 16 0 1 1 22.63 22.64Zm33.38-104a16 16 0 0 1 0-22.63 16 16 0 1 1 0 22.63Zm86.64 64a16 16 0 0 1-22.63-22.63 16 16 0 0 1 22.63 22.63Zm56-104a16 16 0 1 1-22.62-22.66 16 16 0 0 1 22.63 22.64Z" /></Svg>;
export default LineSegments;