/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderNonbinary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-nonbinary-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-nonbinary-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm-72 184a52 52 0 0 1-8-103.38v-20.8L91 95.43a8 8 0 0 1-6-14.86L106.46 72 85 63.43a8 8 0 0 1 6-14.86l37 14.81 37-14.81a8 8 0 1 1 6 14.86L149.54 72 171 80.57a8 8 0 0 1-6 14.86l-29-11.61v20.8A52 52 0 0 1 128 208Zm36-52a36 36 0 1 1-36-36 36 36 0 0 1 36 36Z" /></Svg>;
export default GenderNonbinary;