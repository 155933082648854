/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PathIcon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="path-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-path-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 172a28 28 0 0 0-27.71 24H72a36 36 0 0 1 0-72h96a36 36 0 0 0 0-72H72a4 4 0 0 0 0 8h96a28 28 0 0 1 0 56H72a44 44 0 0 0 0 88h100.29A28 28 0 1 0 200 172Zm0 48a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default PathIcon;