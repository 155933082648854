/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderSimpleMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-simple-minus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-simple-minus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 72h-85.33l-27.74-20.8a16.12 16.12 0 0 0-9.6-3.2H40a16 16 0 0 0-16 16v136a16 16 0 0 0 16 16h176.89A15.13 15.13 0 0 0 232 200.89V88a16 16 0 0 0-16-16Zm-64 80h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Z" /></Svg>;
export default FolderSimpleMinus;