/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileRs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-rs-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-rs-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m213.66 82.34-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v76a4 4 0 0 0 4 4h128a4 4 0 0 1 4 4v104a4 4 0 0 0 4 4h20a16 16 0 0 0 16-16V88a8 8 0 0 0-2.34-5.66ZM152 88V44l44 44Zm-88.58 56H48a8 8 0 0 0-8 8v55.73a8.17 8.17 0 0 0 7.47 8.27 8 8 0 0 0 8.53-8v-8h8a28.48 28.48 0 0 0 5.73-.59L77.09 212a8 8 0 0 0 11.81 2.3 8.14 8.14 0 0 0 1.91-10.54l-7-12a27.92 27.92 0 0 0 8.19-20.4C91.65 156.05 78.74 144 63.42 144Zm.23 40H56v-24h8a12 12 0 0 1 12 13.16A12.25 12.25 0 0 1 63.65 184Zm92.16 12.31a20.82 20.82 0 0 1-9.19 15.23C141.43 215 135 216 129.13 216a61.14 61.14 0 0 1-15.13-2 8 8 0 1 1 4.3-15.41c4.38 1.2 14.95 2.7 19.55-.36.88-.59 1.83-1.52 2.14-3.93.35-2.67-.71-4.1-12.78-7.59-9.35-2.7-25-7.23-23-23.11a20.56 20.56 0 0 1 9-14.95c11.84-8 30.71-3.31 32.83-2.76a8 8 0 0 1-4.07 15.48c-4.49-1.17-15.23-2.56-19.83.56a4.54 4.54 0 0 0-2 3.67c-.12.9-.14 1.09 1.11 1.9 2.31 1.49 6.45 2.68 10.45 3.84 9.79 2.83 26.35 7.66 24.11 24.97Z" /></Svg>;
export default FileRs;