/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NavigationArrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="navigation-arrow-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-navigation-arrow-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.7 102.46 62.81 37.21l-.25-.09a20 20 0 0 0-25.44 25.44l.09.25 65.25 175.89A20 20 0 0 0 121.3 252h.35a20 20 0 0 0 18.77-14.12l.09-.29 21.23-75.85 75.85-21.23.29-.09a20 20 0 0 0 .82-38Zm-89.93 38a12 12 0 0 0-8.32 8.32l-19.68 70.29L62.8 62.8l156.26 58Z" /></Svg>;
export default NavigationArrow;