/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Notification = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="notification-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-notification-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 128v80a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h80a8 8 0 0 1 0 16H48v152h152v-80a8 8 0 0 1 16 0ZM196 24a36 36 0 1 0 36 36 36 36 0 0 0-36-36Z" /></Svg>;
export default Notification;