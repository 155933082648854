/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SteamLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="steam-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-steam-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm50.16 122.22a40 40 0 1 0-57.25-55.34L92.57 123.6 55.18 86.21a83.55 83.55 0 1 1-9.39 24.55l32.76 32.76a32 32 0 0 0 49.51 37.41ZM117 131.27A40.26 40.26 0 0 0 132.73 147l-19.43 15-.25.2a7.93 7.93 0 0 1-5 1.79 8 8 0 0 1-8-8 7.93 7.93 0 0 1 1.79-5.05l.2-.25Zm35-3.27a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default SteamLogo;