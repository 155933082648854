/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotMemberOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-member-of-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-member-of-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 35.56a6 6 0 0 0-8.48.4l-5.49 6H128A85.93 85.93 0 0 0 65.9 187.4L43.56 212a6 6 0 0 0 8.88 8l22.33-24.57A85.52 85.52 0 0 0 128 214h72a6 6 0 0 0 0-12h-72a73.65 73.65 0 0 1-45.14-15.42L130.65 134H200a6 6 0 0 0 0-12h-58.44l70.88-78a6 6 0 0 0-.44-8.44ZM74 178.5A73.74 73.74 0 0 1 54.26 134h60.18ZM54.26 122A74.09 74.09 0 0 1 128 54h59.16l-61.81 68Z" /></Svg>;
export default NotMemberOf;