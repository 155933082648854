/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextHTwo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-h-two-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-h-two-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 72v136H56a16 16 0 0 1-16-16V56h184a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M248 208a8 8 0 0 1-8 8h-48a8 8 0 0 1-6.4-12.8l43.16-57.56a16 16 0 1 0-25.54-19.27 16.28 16.28 0 0 0-2.32 4.3 8 8 0 1 1-15.08-5.34 32 32 0 1 1 55.73 29.93L208 200h32a8 8 0 0 1 8 8ZM144 48a8 8 0 0 0-8 8v52H48V56a8 8 0 0 0-16 0v120a8 8 0 0 0 16 0v-52h88v52a8 8 0 0 0 16 0V56a8 8 0 0 0-8-8Z" /></Svg>;
export default TextHTwo;