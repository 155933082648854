/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FourK = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="four-k-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-four-k-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48v160H32V48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M24 48a8 8 0 0 1 8-8h192a8 8 0 0 1 0 16H32a8 8 0 0 1-8-8Zm200 152H32a8 8 0 0 0 0 16h192a8 8 0 0 0 0-16ZM144 72a8 8 0 0 0-8 8v96a8 8 0 0 0 16 0v-29l13.09-15 28 48a8 8 0 1 0 13.82-8l-30.7-52.63L206 85.27a8 8 0 1 0-12-10.54l-42 48V80a8 8 0 0 0-8-8ZM88 176v-16H40a8 8 0 0 1-6.31-12.91l56-72A8 8 0 0 1 104 80v64h8a8 8 0 0 1 0 16h-8v16a8 8 0 0 1-16 0Zm0-32v-40.68L56.36 144Z" /></Svg>;
export default FourK;