/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stool__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stool'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 64V40a16 16 0 0 0-16-16H72a16 16 0 0 0-16 16v24a16 16 0 0 0 16 16h6.64L56.1 222.75a8 8 0 0 0 6.65 9.15 7.82 7.82 0 0 0 1.25.1 8 8 0 0 0 7.89-6.75L79.68 176h96.64l7.78 49.25A8 8 0 0 0 192 232a7.82 7.82 0 0 0 1.26-.1 8 8 0 0 0 6.65-9.15L177.36 80H184a16 16 0 0 0 16-16ZM72 40h112v24H72Zm101.79 120H82.21l12.63-80h66.32Z" /></Svg>;
export default Stool;