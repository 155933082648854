/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MagnifyingGlassMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="magnifying-glass-minus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-magnifying-glass-minus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m229.66 218.34-50.06-50.06a88.21 88.21 0 1 0-11.32 11.31l50.06 50.07a8 8 0 0 0 11.32-11.32ZM144 120H80a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Z" /></Svg>;
export default MagnifyingGlassMinus;