/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pi-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pi-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 172a40 40 0 0 1-80 0V76h-56v124a12 12 0 0 1-24 0V76h-4a36 36 0 0 0-36 36 12 12 0 0 1-24 0 60.07 60.07 0 0 1 60-60h152a12 12 0 0 1 0 24h-44v96a16 16 0 0 0 32 0 12 12 0 0 1 24 0Z" /></Svg>;
export default Pi;