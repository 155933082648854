/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiceTwo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dice-two-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dice-two-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 28H64a36 36 0 0 0-36 36v128a36 36 0 0 0 36 36h128a36 36 0 0 0 36-36V64a36 36 0 0 0-36-36Zm12 164a12 12 0 0 1-12 12H64a12 12 0 0 1-12-12V64a12 12 0 0 1 12-12h128a12 12 0 0 1 12 12Zm-84-88a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm48 48a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default DiceTwo;