/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GraphicsCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="graphics-card-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-graphics-card-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 50H16a6 6 0 0 0-6 6v152a6 6 0 0 0 12 0v-18h20v18a6 6 0 0 0 12 0v-18h20v18a6 6 0 0 0 12 0v-18h20v18a6 6 0 0 0 12 0v-18h114a14 14 0 0 0 14-14V64a14 14 0 0 0-14-14Zm2 126a2 2 0 0 1-2 2H22V62h210a2 2 0 0 1 2 2Zm-58-94a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm26 38a25.81 25.81 0 0 1-3.88 13.64l-35.76-35.76A26 26 0 0 1 202 120Zm-52 0a25.81 25.81 0 0 1 3.88-13.64l35.76 35.76A26 26 0 0 1 150 120ZM80 82a38 38 0 1 0 38 38 38 38 0 0 0-38-38Zm26 38a25.81 25.81 0 0 1-3.88 13.64L66.36 97.88A26 26 0 0 1 106 120Zm-52 0a25.81 25.81 0 0 1 3.88-13.64l35.76 35.76A26 26 0 0 1 54 120Z" /></Svg>;
export default GraphicsCard;