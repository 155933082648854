/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LadderSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ladder-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ladder-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 32v192H64V32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M192 24a8 8 0 0 0-8 8v32H72V32a8 8 0 0 0-16 0v192a8 8 0 0 0 16 0v-32h112v32a8 8 0 0 0 16 0V32a8 8 0 0 0-8-8Zm-8 56v40H72V80ZM72 176v-40h112v40Z" /></Svg>;
export default LadderSimple;