/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Golf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="golf-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-golf-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174 100a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-42 22a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm82-26a86 86 0 1 1-86-86 86.1 86.1 0 0 1 86 86Zm-12 0a74 74 0 1 0-74 74 74.09 74.09 0 0 0 74-74Zm-36.14 98.4c-13.38 5.11-25.77 7.6-37.86 7.6s-24.48-2.49-37.86-7.6a6 6 0 1 0-4.28 11.2 122.32 122.32 0 0 0 36.14 8.21V248a6 6 0 0 0 12 0v-34.19a122.32 122.32 0 0 0 36.14-8.21 6 6 0 0 0-4.28-11.2Z" /></Svg>;
export default Golf;