/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Balloon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="balloon-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-balloon-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 12a92.1 92.1 0 0 0-92 92c0 24.53 9.55 50.13 26.19 70.22 10 12 21.56 21.07 34.05 26.76L85 227.27A12 12 0 0 0 96 244h64a12 12 0 0 0 11-16.73L159.76 201c12.49-5.69 24.08-14.73 34.05-26.76C210.45 154.13 220 128.53 220 104a92.1 92.1 0 0 0-92-92Zm13.8 208h-27.6l5.35-12.49a73.1 73.1 0 0 0 16.9 0Zm33.53-61.09C161.93 175.09 145.12 184 128 184s-33.93-8.91-47.33-25.09C67.73 143.29 60 122.76 60 104a68 68 0 0 1 136 0c0 18.76-7.73 39.29-20.67 54.91Zm-6.34-47q-.6.06-1.2.06a12 12 0 0 1-11.93-10.81 28 28 0 0 0-19.47-23.91 12 12 0 1 1 7.22-22.89 51.94 51.94 0 0 1 36.13 44.42A12 12 0 0 1 169 111.94Z" /></Svg>;
export default Balloon;