/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandsClapping = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hands-clapping-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hands-clapping-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M162.21 24V8a6 6 0 0 1 12 0v16a6 6 0 0 1-12 0Zm34.93 15.25a6 6 0 0 0 8.26-2l8-13.11a6 6 0 0 0-10.26-6.25l-8 13.11a6 6 0 0 0 2 8.25Zm44.57 14.91a6 6 0 0 0-7.57-3.87l-15 4.85a6 6 0 0 0 1.84 11.72 5.91 5.91 0 0 0 1.84-.3l15.06-4.84a6 6 0 0 0 3.83-7.56Zm-26.49 44.42a78.2 78.2 0 0 1-10.35 92c-.36 1.87-.76 3.74-1.26 5.6A78.18 78.18 0 0 1 60.49 215l-35.27-61a24 24 0 0 1 20.85-36l-4.61-8a24 24 0 0 1 20.82-36L60 70a24 24 0 0 1 36.11-30.46A24.05 24.05 0 0 1 138.56 38l13.89 24a24 24 0 0 1 41.62 0ZM107.33 56 134 102a24 24 0 0 1 20.8-12l-26.65-46a12 12 0 1 0-20.82 12Zm-37 8 8.08 14a24 24 0 0 1 20.82-12l-8.06-14a12 12 0 0 0-22 2.91 11.9 11.9 0 0 0 1.2 9.09Zm115 79-20.23-35a12 12 0 0 0-21.1 11.27L156.56 141a6 6 0 0 1-10.4 6l-36.51-63a12 12 0 0 0-20.82 12l26 45a6 6 0 0 1-10.4 6L72.68 92a12 12 0 0 0-20.81 12l35.23 61a6 6 0 0 1-10.41 6l-20.25-35a12 12 0 1 0-20.81 12l35.27 61A66.13 66.13 0 0 0 192 193.09a65.53 65.53 0 0 0-6.6-50.09Zm19.41-38.42L183.66 68a12 12 0 0 0-16.42-4.39A12 12 0 0 0 162.82 80l33 57a77.7 77.7 0 0 1 10.14 31.54 66.25 66.25 0 0 0-1.15-63.96Z" /></Svg>;
export default HandsClapping;