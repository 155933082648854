/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Toilet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toilet-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toilet-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M118 64a6 6 0 0 1-6 6H96a6 6 0 0 1 0-12h16a6 6 0 0 1 6 6Zm52.14 132 3.72 26A14 14 0 0 1 160 238H96a14 14 0 0 1-13.86-16l3.72-26A94.1 94.1 0 0 1 34 112a6 6 0 0 1 6-6h18V40a14 14 0 0 1 14-14h112a14 14 0 0 1 14 14v66h18a6 6 0 0 1 6 6 94.1 94.1 0 0 1-51.86 84ZM70 106h116V40a2 2 0 0 0-2-2H72a2 2 0 0 0-2 2Zm88.71 94.84a94 94 0 0 1-61.42 0L94 223.72a2 2 0 0 0 .47 1.59A2 2 0 0 0 96 226h64a2 2 0 0 0 1.51-.69 2 2 0 0 0 .47-1.59ZM209.78 118H46.22a82 82 0 0 0 163.56 0Z" /></Svg>;
export default Toilet;