/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cookie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cookie-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cookie-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 120a40 40 0 0 1-40-40 8 8 0 0 0-8-8 40 40 0 0 1-40-40 8 8 0 0 0-8-8 104 104 0 1 0 104 104 8 8 0 0 0-8-8ZM75.51 99.51a12 12 0 1 1 0 17 12 12 0 0 1 0-17Zm25 73a12 12 0 1 1 0-17 12 12 0 0 1-.02 16.98Zm23-40a12 12 0 1 1 17 0 12 12 0 0 1-17-.02Zm41 48a12 12 0 1 1 0-17 12 12 0 0 1-.02 16.98Z" /></Svg>;
export default Cookie;