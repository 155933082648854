/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CheckFat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="check-fat-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-check-fat-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m246.15 65.46-.07-.08-23.93-23.53a20 20 0 0 0-28.23-.05l-90 88.83-33.86-32.85a20 20 0 0 0-28.21.08l-24 24a20 20 0 0 0 0 28.26l71.62 72a20 20 0 0 0 28.29 0L246.15 93.74a20 20 0 0 0 0-28.28ZM103.61 202.33 37.64 136 56 117.61 95.65 156a12 12 0 0 0 16.78-.08L208 61.61l18.32 18Z" /></Svg>;
export default CheckFat;