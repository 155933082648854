/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockAfternoon = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-afternoon-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-afternoon-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm56 112h-36.69l26.35 26.34a8 8 0 0 1-11.32 11.32l-40-40A8 8 0 0 1 128 120h56a8 8 0 0 1 0 16Z" /></Svg>;
export default ClockAfternoon;