/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinSimpleLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-simple-line-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-simple-line-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 210h-82v-76.34a54 54 0 1 0-12 0V210H40a6 6 0 0 0 0 12h176a6 6 0 0 0 0-12ZM86 80a42 42 0 1 1 42 42 42 42 0 0 1-42-42Z" /></Svg>;
export default MapPinSimpleLine;