/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Gavel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gavel-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gavel-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.69 99.31a16 16 0 0 1 0-22.63l64-64a16 16 0 0 1 22.63 22.63l-64 64a16 16 0 0 1-22.63 0Zm190.63 17.37a16 16 0 0 0-22.63 0l-64 64a16 16 0 0 0 0 22.63 16 16 0 0 0 22.63 0l64-64a16 16 0 0 0 0-22.63Zm-35.11-15.8-53.09-53.09a4 4 0 0 0-5.66 0L87.8 109.45a4 4 0 0 0 0 5.66l15.2 15.23-74.31 74.35a16 16 0 0 0 22.62 22.62L125.66 153l15.23 15.23a4 4 0 0 0 5.66 0l61.66-61.66a4 4 0 0 0 0-5.69Z" /></Svg>;
export default Gavel;