/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextHSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-h-six-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-h-six-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 148a32.08 32.08 0 0 0-9.75 1.52l21.18-35.47a4 4 0 0 0-6.86-4.1l-32.25 54a.89.89 0 0 0-.08.17A32 32 0 1 0 212 148Zm0 56a24 24 0 1 1 24-24 24 24 0 0 1-24 24ZM148 56v120a4 4 0 0 1-8 0v-56H44v56a4 4 0 0 1-8 0V56a4 4 0 0 1 8 0v56h96V56a4 4 0 0 1 8 0Z" /></Svg>;
export default TextHSix;