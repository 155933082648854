/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandDeposit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-deposit-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-deposit-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M124 25.66V128a4 4 0 0 1-8 0V25.66L90.83 50.83a4 4 0 0 1-5.66-5.66l32-32a4 4 0 0 1 5.66 0l32 32a4 4 0 0 1-5.66 5.66Zm64 100.12V96a12 12 0 0 0-12-12h-16a4 4 0 0 0 0 8h16a4 4 0 0 1 4 4v92.9l-3.27-5a24 24 0 0 0-41.51 24.1 1.22 1.22 0 0 0 .12.19l22.26 34a4 4 0 1 0 6.69-4.38l-22.2-33.9A16 16 0 0 1 169.86 188l.12.19 10.67 16.31a4 4 0 0 0 7.35-2.19v-66.84a78.83 78.83 0 0 1 32 63.18V240a4 4 0 0 0 8 0v-41.35a86.84 86.84 0 0 0-40-72.87ZM80 84H64a12 12 0 0 0-12 12v104a4 4 0 0 0 8 0V96a4 4 0 0 1 4-4h16a4 4 0 0 0 0-8Z" /></Svg>;
export default HandDeposit;