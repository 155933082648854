/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Guitar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="guitar-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-guitar-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92.49 187.5a12 12 0 1 1-17 17l-24-24a12 12 0 0 1 17-17ZM112 120a24 24 0 1 0 24 24 24 24 0 0 0-24-24Zm140.49-59.52a12 12 0 0 1-14.12 2.12L182 119a73.13 73.13 0 0 1 5.36 18.68c2.51 17.58-2.2 33.35-13.27 44.42a46.11 46.11 0 0 1-15.73 10.29 4.32 4.32 0 0 0-2.39 4c-.53 15.61-6.16 29.22-16.28 39.34C129 246.37 114.5 252 98.13 252a76.73 76.73 0 0 1-10.77-.78c-19.47-2.78-38.73-12.85-54.23-28.35S7.56 188.11 4.78 168.64c-3-20.75 2.56-39.34 15.55-52.33 10.12-10.12 23.73-15.75 39.34-16.31a4.32 4.32 0 0 0 4-2.39 46.11 46.11 0 0 1 10.26-15.7C85 70.84 100.77 66.13 118.35 68.64A73.13 73.13 0 0 1 137 74l56.4-56.37a12 12 0 0 1 19.09-14.12l40 40a12 12 0 0 1 0 16.97ZM221 46l-11-11-19 19 11 11Zm-74.31 63.28C135.53 98.09 121.39 92 109.34 92c-7.29 0-13.81 2.21-18.44 6.85a22.16 22.16 0 0 0-4.9 7.62A28.23 28.23 0 0 1 60.48 124c-6.57.22-15.93 2-23.18 9.26-7.52 7.52-10.63 18.87-8.76 32 2.05 14.37 9.71 28.81 21.56 40.66s26.29 19.51 40.66 21.56c13.09 1.87 24.44-1.24 32-8.76 7.25-7.25 9-16.61 9.26-23.18A28.23 28.23 0 0 1 149.53 170a22.16 22.16 0 0 0 7.59-4.94c12.31-12.27 7.55-37.83-10.4-55.78ZM169 98.07 185 82l-11-11-16.07 16c2 1.67 3.9 3.42 5.76 5.27s3.6 3.82 5.31 5.8Z" /></Svg>;
export default Guitar;