/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NavigationArrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="navigation-arrow-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-navigation-arrow-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 121.58a15.76 15.76 0 0 1-11.29 15l-.2.06-78 21.84-21.84 78-.06.2a15.77 15.77 0 0 1-15 11.29h-.3a15.77 15.77 0 0 1-15.07-10.67L41 61.41a1 1 0 0 1-.05-.16 16 16 0 0 1 20.3-20.35l.16.05 175.92 65.26A15.78 15.78 0 0 1 248 121.58Z" /></Svg>;
export default NavigationArrow;