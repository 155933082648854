/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StarHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-half-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-half-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m236.9 117.88-5.06 4.36a12 12 0 0 1-16.87-17 12 12 0 0 1 2-23.92l8.27.67a20.45 20.45 0 0 1 11.66 35.87Zm-64.37 23.82a12 12 0 0 0-3.84 11.86l4.08 17.22a12 12 0 1 0 23.35-5.54l-2.4-10.12 6.12-5.28a12 12 0 0 0-15.67-18.17Zm33.07 63.55a12 12 0 0 0-23.68 2.93 12 12 0 0 0-12.2 20.66l6.71 4.13a20.39 20.39 0 0 0 30.49-22.15ZM175 102l-15.64-1.26a12 12 0 0 1-10.13-7.38L140 70.94v111.45l5.53 3.4A12 12 0 0 1 133 206.24l-5-3.05L79.57 233a20.39 20.39 0 0 1-30.49-22.15l13.2-55.7-43.18-37.27A20.45 20.45 0 0 1 30.75 82l56.6-4.57 21.82-52.82a20.36 20.36 0 0 1 37.66 0l21.82 52.82 8.32.67A12 12 0 0 1 175 102Zm-59 80.36V70.94l-9.27 22.45a12 12 0 0 1-10.13 7.38l-55.4 4.47 42.27 36.46a12 12 0 0 1 3.84 11.86L74.42 208Z" /></Svg>;
export default StarHalf;