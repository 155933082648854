/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LockKey = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lock-key-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lock-key-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 76h-28V56a52 52 0 0 0-104 0v20H48a20 20 0 0 0-20 20v112a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V96a20 20 0 0 0-20-20ZM100 56a28 28 0 0 1 56 0v20h-56Zm104 148H52V100h152Zm-76-92a32 32 0 0 0-12 61.66V180a12 12 0 0 0 24 0v-6.34A32 32 0 0 0 128 112Zm0 24a8 8 0 1 1-8 8 8 8 0 0 1 8-8Z" /></Svg>;
export default LockKey;