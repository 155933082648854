/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-check-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-check-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M133.17 158.84a64 64 0 1 0-50.34 0c-23.76 5.46-45.18 18.69-61.89 38.59a4 4 0 1 0 6.12 5.14C48 177.7 76.71 164 108 164s60 13.7 80.94 38.57a4 4 0 0 0 6.12-5.14c-16.71-19.9-38.13-33.13-61.89-38.59ZM52 100a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Zm198.83 30.83-32 32a4 4 0 0 1-5.66 0l-16-16a4 4 0 0 1 5.66-5.66L216 154.34l29.17-29.17a4 4 0 1 1 5.66 5.66Z" /></Svg>;
export default UserCheck;