/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FingerprintSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fingerprint-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fingerprint-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 128a156.12 156.12 0 0 1-19.5 75.56 12 12 0 1 1-21-11.64A132 132 0 0 0 68 128a60 60 0 0 1 99.68-45 12 12 0 1 1-15.88 18A36 36 0 0 0 92 128Zm84.16-8H176a12 12 0 0 0-12 11.8 226.76 226.76 0 0 1-17 82.84 12 12 0 1 0 22.19 9.13A250.42 250.42 0 0 0 188 132.2a12 12 0 0 0-11.85-12.2Zm-62.22 81.21a12 12 0 0 0-16 5.54c-1.37 2.81-2.85 5.65-4.38 8.42a12 12 0 1 0 21 11.63c1.74-3.14 3.41-6.35 5-9.55a12 12 0 0 0-5.63-16.04ZM128 116a12 12 0 0 0-12 12 181 181 0 0 1-4.75 41.26 12 12 0 0 0 8.94 14.42 11.79 11.79 0 0 0 2.75.32 12 12 0 0 0 11.67-9.26A204.55 204.55 0 0 0 140 128a12 12 0 0 0-12-12Zm0-96a108 108 0 0 0-31.5 4.67 12 12 0 0 0 7 23A84.06 84.06 0 0 1 212 128a277.39 277.39 0 0 1-6.84 61.34 12 12 0 0 0 9.05 14.36 11.61 11.61 0 0 0 2.66.3 12 12 0 0 0 11.7-9.34A301.89 301.89 0 0 0 236 128 108.12 108.12 0 0 0 128 20ZM68.47 51.74a12 12 0 0 0-17 0A107.32 107.32 0 0 0 20 128a83.26 83.26 0 0 1-7.61 35 12 12 0 1 0 21.81 10 107.11 107.11 0 0 0 9.8-45 83.42 83.42 0 0 1 24.5-59.29 12 12 0 0 0-.03-16.97Z" /></Svg>;
export default FingerprintSimple;