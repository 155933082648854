/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Unite = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="unite-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-unite-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172.91 83.09a78 78 0 1 0-89.82 89.82 78 78 0 1 0 89.82-89.82ZM226 160a65.31 65.31 0 0 1-.62 8.9l-53.76-53.77A77.84 77.84 0 0 0 174 96v-.49A66.1 66.1 0 0 1 226 160ZM45.31 53.79l55.5 55.5a77.86 77.86 0 0 0-12 19L34 73.48a66 66 0 0 1 11.31-19.69Zm88.92 96-28-28a66.47 66.47 0 0 1 15.52-15.52l28 28a66.47 66.47 0 0 1-15.52 15.48ZM162 96a65.62 65.62 0 0 1-6 27.49L132.51 100A65.62 65.62 0 0 1 160 94h1.95c.05.7.05 1.35.05 2Zm-52.71 4.81-55.5-55.5A66 66 0 0 1 73.48 34l54.8 54.81a77.86 77.86 0 0 0-18.99 12ZM94 160a65.62 65.62 0 0 1 6-27.49L123.49 156A65.62 65.62 0 0 1 96 162c-.65 0-1.3 0-2-.05V160Zm52.71-4.81 55.5 55.5A66 66 0 0 1 182.52 222l-54.8-54.81a77.86 77.86 0 0 0 18.99-12Zm8.48-8.48a77.86 77.86 0 0 0 12-19L222 182.52a66 66 0 0 1-11.35 19.69Zm5.3-64.7H160a77.84 77.84 0 0 0-19.13 2.38L87.1 30.62A65.31 65.31 0 0 1 96 30a66.1 66.1 0 0 1 64.49 52ZM30 96a65.31 65.31 0 0 1 .62-8.9l53.76 53.77A77.84 77.84 0 0 0 82 160v.49A66.1 66.1 0 0 1 30 96Zm65.51 78H96a77.84 77.84 0 0 0 19.13-2.38l53.77 53.76a65.31 65.31 0 0 1-8.9.62 66.1 66.1 0 0 1-64.49-52Z" /></Svg>;
export default Unite;