/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-six-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-six-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 106a53.74 53.74 0 0 0-19.94 3.83L141.23 51a6 6 0 1 0-10.46-5.89l-49.54 88A54 54 0 1 0 128 106Zm0 96a42 42 0 1 1 42-42 42 42 0 0 1-42 42Z" /></Svg>;
export default NumberSix;