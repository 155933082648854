/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Football = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="football-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-football-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.06 53.18a37 37 0 0 0-30.24-30.24c-15.24-2.58-38.75-4.78-64.26-.61-30.9 5.06-57 18.06-77.56 38.67s-33.59 46.68-38.65 77.58c-4.17 25.51-2 49 .61 64.26a37 37 0 0 0 30.24 30.24A209.43 209.43 0 0 0 87.9 236a184.13 184.13 0 0 0 29.54-2.33c30.9-5.06 57-18.06 77.58-38.65s33.59-46.68 38.65-77.58c4.17-25.51 1.97-49.02-.61-64.26Zm-23.66 4a192 192 0 0 1 2.6 25.88L172.93 44a190.57 190.57 0 0 1 25.88 2.64 13 13 0 0 1 10.59 10.55ZM46.6 198.81a191.79 191.79 0 0 1-2.6-25.87L83.06 212a191.79 191.79 0 0 1-25.87-2.63 13 13 0 0 1-10.59-10.56Zm131.45-20.76c-11 11-31.31 26.16-63.2 31.83l-68.73-68.73C51.79 109.26 67 88.93 78 78s31.3-26.16 63.2-31.83l68.73 68.73c-5.72 31.84-20.93 52.17-31.88 63.15Zm-13.56-69.57L159 114l5.52 5.51a12 12 0 0 1-17 17L142 131l-11 11 5.52 5.52a12 12 0 0 1-17 17L114 159l-5.52 5.52a12 12 0 0 1-17-17L97 142l-5.52-5.51a12 12 0 1 1 17-17L114 125l11-11-5.52-5.52a12 12 0 1 1 17-17L142 97l5.52-5.52a12 12 0 0 1 17 17Z" /></Svg>;
export default Football;