/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreePalm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tree-palm-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tree-palm-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.84 60.33a8 8 0 0 1-4.65 5.75L179 90.55a71.42 71.42 0 0 1 43.36 33.21 70.64 70.64 0 0 1 7.2 54.32 8 8 0 0 1-12.56 4.28l-81-61.68V224a8 8 0 0 1-16 0V120.68l-81 61.68a8 8 0 0 1-12.57-4.28 70.64 70.64 0 0 1 7.2-54.32A71.42 71.42 0 0 1 77 90.55L20.81 66.08a8 8 0 0 1-2.6-12.85 66.86 66.86 0 0 1 97.74 0 72.21 72.21 0 0 1 12 17 72.21 72.21 0 0 1 12.05-17 66.86 66.86 0 0 1 97.74 0 8 8 0 0 1 2.1 7.1Z" /></Svg>;
export default TreePalm;