/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Paragraph = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paragraph-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paragraph-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 42H96a62 62 0 0 0 0 124h42v42a6 6 0 0 0 12 0V54h28v154a6 6 0 0 0 12 0V54h18a6 6 0 0 0 0-12Zm-70 112H96a50 50 0 0 1 0-100h42Z" /></Svg>;
export default Paragraph;