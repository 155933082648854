/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserSquare = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-square__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-square'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM96 120a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm-27.33 88a64.36 64.36 0 0 1 19.13-25.8 64 64 0 0 1 80.4 0 64.36 64.36 0 0 1 19.13 25.8ZM208 208h-3.67a79.9 79.9 0 0 0-46.68-50.29 48 48 0 1 0-59.3 0A79.9 79.9 0 0 0 51.67 208H48V48h160v160Z" /></Svg>;
export default UserSquare;