/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LastfmLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lastfm-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lastfm-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 160a36 36 0 0 1-36 36h-17.43a52.19 52.19 0 0 1-47.12-30l-30.15-64.6A44.17 44.17 0 0 0 73.43 76H64a44.05 44.05 0 0 0-44 44v24a44.05 44.05 0 0 0 44 44h8a36 36 0 0 0 33-21.6 4 4 0 1 1 7.33 3.2A44 44 0 0 1 72 196h-8a52.06 52.06 0 0 1-52-52v-24a52.06 52.06 0 0 1 52-52h9.43a52.19 52.19 0 0 1 47.12 30l30.15 64.6a44.17 44.17 0 0 0 39.87 25.4H208a28 28 0 0 0 0-56h-20a32 32 0 0 1 0-64h20a28 28 0 0 1 28 28 4 4 0 0 1-8 0 20 20 0 0 0-20-20h-20a24 24 0 0 0 0 48h20a36 36 0 0 1 36 36Z" /></Svg>;
export default LastfmLogo;