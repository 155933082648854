/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M211.69 19.27a6 6 0 0 0-5.15-1.09l-128 32A6 6 0 0 0 74 56v114.11A34 34 0 1 0 86 196v-87.32l116-29v58.43A34 34 0 1 0 214 164V24a6 6 0 0 0-2.31-4.73ZM52 218a22 22 0 1 1 22-22 22 22 0 0 1-22 22ZM86 96.32V60.68l116-29v35.64ZM180 186a22 22 0 1 1 22-22 22 22 0 0 1-22 22Z" /></Svg>;
export default MusicNotes;