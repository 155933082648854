/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FirstAidKit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="first-aid-kit-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-first-aid-kit-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 56h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V72a16 16 0 0 0-16-16Zm-64 88h-16v16a8 8 0 0 1-16 0v-16h-16a8 8 0 0 1 0-16h16v-16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 0 16Zm8-88H96v-8a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8Z" /></Svg>;
export default FirstAidKit;