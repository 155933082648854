/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitMerge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-merge-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-merge-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 114a30 30 0 0 0-29.21 23.19l-44-6.28a10 10 0 0 1-6.18-3.39L91.18 83.83A30 30 0 1 0 74 85.4v85.2a30 30 0 1 0 12 0V96.22l33.52 39.11a22 22 0 0 0 13.6 7.46l45.35 6.48A30 30 0 1 0 208 114ZM62 56a18 18 0 1 1 18 18 18 18 0 0 1-18-18Zm36 144a18 18 0 1 1-18-18 18 18 0 0 1 18 18Zm110-38a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default GitMerge;