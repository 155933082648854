/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPinSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 172h-12.64L180.77 44H192a4 4 0 0 0 0-8H64a4 4 0 0 0 0 8h11.23L52.64 172H40a4 4 0 0 0 0 8h84v60a4 4 0 0 0 8 0v-60h84a4 4 0 0 0 0-8ZM83.36 44h89.28l22.59 128H60.77Z" /></Svg>;
export default PushPinSimple;