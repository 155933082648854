/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldWarning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-warning-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-warning-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M124 136V96a4 4 0 0 1 8 0v40a4 4 0 0 1-8 0Zm4 28a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm92-108v56c0 51.16-24.73 82.12-45.47 99.1-22.4 18.32-44.55 24.5-45.48 24.76a4 4 0 0 1-2.1 0c-.93-.26-23.08-6.44-45.48-24.76C60.73 194.12 36 163.16 36 112V56a12 12 0 0 1 12-12h160a12 12 0 0 1 12 12Zm-8 0a4 4 0 0 0-4-4H48a4 4 0 0 0-4 4v56c0 38.44 14.23 69.63 42.29 92.71A132.23 132.23 0 0 0 128 227.82a132.45 132.45 0 0 0 41.71-23.11C197.77 181.63 212 150.44 212 112Z" /></Svg>;
export default ShieldWarning;