/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatsTeardrop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chats-teardrop-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chats-teardrop-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M166.76 76.32A76 76 0 0 0 20 104v64a12 12 0 0 0 12 12h57.33A76.13 76.13 0 0 0 160 228h64a12 12 0 0 0 12-12v-64a76 76 0 0 0-69.24-75.68ZM28 168v-64a68 68 0 1 1 68 68H32a4 4 0 0 1-4-4Zm200 48a4 4 0 0 1-4 4h-64a68.16 68.16 0 0 1-62-40 76 76 0 0 0 71.5-95.33A68 68 0 0 1 228 152Z" /></Svg>;
export default ChatsTeardrop;