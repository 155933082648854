/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeadCircuit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="head-circuit-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-head-circuit-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M215.93 100.72A80 80 0 0 0 56 104l-23.35 44.87a8 8 0 0 0 3.77 10.31L64 171.81V208a8 8 0 0 0 8 8h48a16 16 0 0 0 16 16h56l-8-64a79.86 79.86 0 0 0 31.93-67.28Zm-103.8-26.64a16 16 0 1 1 13.79 13.79 16 16 0 0 1-13.79-13.79Zm34 77.79a16 16 0 1 1 13.79-13.79 16 16 0 0 1-13.84 13.79Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M192.5 171.47a88.34 88.34 0 0 0 31.5-69.54c-1-45.71-37.61-83.4-83.24-85.8A88 88 0 0 0 48 102l-22.45 43.18c-.09.18-.18.36-.26.54a16 16 0 0 0 7.55 20.62l.25.11L56 176.94V208a16 16 0 0 0 16 16h48a8 8 0 0 0 0-16H72v-36.19a8 8 0 0 0-4.67-7.28L40 152l23.07-44.34A7.9 7.9 0 0 0 64 104a72 72 0 0 1 56-70.21v15.59a24 24 0 1 0 16 0V32c1.3 0 2.6 0 3.9.1A72.26 72.26 0 0 1 203.84 80H184a8 8 0 0 0-6.15 2.88l-25.51 30.62a24.06 24.06 0 1 0 12.28 10.25L187.75 96h19.79q.36 3.12.44 6.3a72.26 72.26 0 0 1-28.78 59.3 8 8 0 0 0-3.14 7.39l8 64a8 8 0 0 0 7.93 7 8.39 8.39 0 0 0 1-.06 8 8 0 0 0 6.95-8.93ZM128 80a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm16 64a8 8 0 1 1 8-8 8 8 0 0 1-8 8Z" /></Svg>;
export default HeadCircuit;