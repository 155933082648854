/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MetaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="meta-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-meta-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 149.31c0 15.38-3 28.45-8.6 37.78-6.67 11.06-17 16.91-30 16.91-25.55 0-44.83-36-65.25-74.11C113.78 95.53 94.74 60 74 60c-11.1 0-22.7 10.53-31.84 28.89A142.23 142.23 0 0 0 28 149.31c0 13.93 2.58 25.56 7.45 33.65 5.22 8.65 13 13 23.11 13 17.15 0 33.07-24.32 47.73-50.6a4 4 0 1 1 7 3.9C98.52 175.76 80.9 204 58.56 204c-12.93 0-23.29-5.85-30-16.91C23 177.76 20 164.69 20 149.31a150.73 150.73 0 0 1 15-64C45.69 63.83 59.54 52 74 52c25.54 0 44.83 36 65.25 74.11 18.41 34.36 37.44 69.89 58.2 69.89 10.12 0 17.89-4.39 23.11-13 4.87-8.09 7.45-19.72 7.45-33.65a142.23 142.23 0 0 0-14.16-60.42C204.7 70.53 193.1 60 182 60c-9.75 0-20.15 8-31.78 24.57a4 4 0 0 1-6.54-4.6c13.42-19.08 25.6-28 38.32-28 14.45 0 28.3 11.83 39 33.32a150.73 150.73 0 0 1 15 64.02Z" /></Svg>;
export default MetaLogo;