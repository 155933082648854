/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RadioButton = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radio-button-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radio-button-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84Zm0-140a56 56 0 1 0 56 56 56.06 56.06 0 0 0-56-56Zm0 88a32 32 0 1 1 32-32 32 32 0 0 1-32 32Z" /></Svg>;
export default RadioButton;