/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LessThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="less-than-or-equal-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-less-than-or-equal-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M42 104a6 6 0 0 1 3.93-5.63l152-56a6 6 0 1 1 4.15 11.26L65.36 104l136.71 50.37A6 6 0 0 1 200 166a6.09 6.09 0 0 1-2.08-.37l-152-56A6 6 0 0 1 42 104Zm158 90H48a6 6 0 0 0 0 12h152a6 6 0 0 0 0-12Z" /></Svg>;
export default LessThanOrEqual;