/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-simple-x-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-simple-x-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224.49 176.49 209 192l15.52 15.51a12 12 0 0 1-17 17L192 209l-15.51 15.52a12 12 0 0 1-17-17L175 192l-15.52-15.51a12 12 0 0 1 17-17L192 175l15.51-15.52a12 12 0 0 1 17 17ZM236 128a12 12 0 0 1-12 12H100.54c4.37 49.56 34.63 74.37 35 74.63A12 12 0 0 1 128 236a108 108 0 1 1 108-108Zm-77.79-78.36A144.3 144.3 0 0 1 179.56 116h31.57a84.22 84.22 0 0 0-52.92-66.36Zm-40.57 13.59A119.74 119.74 0 0 0 100.53 116h54.94c-3-33.9-18.09-56.22-27.45-67a107.75 107.75 0 0 0-10.38 14.23ZM44.87 116h31.57a144.3 144.3 0 0 1 21.35-66.36A84.22 84.22 0 0 0 44.87 116Zm31.57 24H44.87a84.22 84.22 0 0 0 52.92 66.36A144.3 144.3 0 0 1 76.44 140Z" /></Svg>;
export default GlobeSimpleX;