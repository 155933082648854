/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MoneyWavy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="money-wavy-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-money-wavy-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244.24 60a8 8 0 0 0-7.75-.4c-42.93 21-73.59 11.16-106 .78-34.09-10.85-69.29-22.1-118 1.68A8 8 0 0 0 8 69.24v119.93a8 8 0 0 0 11.51 7.19c42.93-21 73.59-11.16 106.05-.78 19.24 6.15 38.84 12.42 61 12.42 17.09 0 35.73-3.72 56.91-14.06a8 8 0 0 0 4.49-7.18V66.83a8 8 0 0 0-3.72-6.83ZM48 152a8 8 0 0 1-16 0V88a8 8 0 0 1 16 0Zm80 8a32 32 0 1 1 32-32 32 32 0 0 1-32 32Zm96 8a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Z" /></Svg>;
export default MoneyWavy;