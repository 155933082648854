/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StarFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-four-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-four-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m226.76 135.48-66.94 24.34-24.34 66.94a8 8 0 0 1-15 0l-24.3-66.94-66.94-24.34a8 8 0 0 1 0-15l66.94-24.3 24.34-66.94a8 8 0 0 1 15 0l24.34 66.94 66.94 24.34a8 8 0 0 1-.04 14.96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m229.5 113-63.44-23.06L143 26.5a16 16 0 0 0-30 0L89.94 89.94 26.5 113a16 16 0 0 0 0 30l63.44 23.07L113 229.5a16 16 0 0 0 30 0l23.07-63.44L229.5 143a16 16 0 0 0 0-30Zm-72.42 39.3a8 8 0 0 0-4.78 4.78L128 223.9l-24.3-66.82a8 8 0 0 0-4.78-4.78L32.1 128l66.82-24.3a8 8 0 0 0 4.78-4.78L128 32.1l24.3 66.82a8 8 0 0 0 4.78 4.78L223.9 128Z" /></Svg>;
export default StarFour;