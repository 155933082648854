/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookOpenText = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="book-open-text-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-book-open-text-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 48h-64a32 32 0 0 0-32 32v87.73a8.17 8.17 0 0 1-7.47 8.25 8 8 0 0 1-8.53-8V80a32 32 0 0 0-32-32H24a8 8 0 0 0-8 8v144a8 8 0 0 0 8 8h72a24 24 0 0 1 24 23.94 7.9 7.9 0 0 0 5.12 7.55A8 8 0 0 0 136 232a24 24 0 0 1 24-24h72a8 8 0 0 0 8-8V56a8 8 0 0 0-8-8Zm-24 120h-39.73a8.17 8.17 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h39.73a8.17 8.17 0 0 1 8.25 7.47 8 8 0 0 1-8 8.53Zm0-32h-39.73a8.17 8.17 0 0 1-8.25-7.47 8 8 0 0 1 8-8.53h39.73a8.17 8.17 0 0 1 8.25 7.47 8 8 0 0 1-8 8.53Zm0-32h-39.73a8.17 8.17 0 0 1-8.27-7.47 8 8 0 0 1 8-8.53h39.73a8.17 8.17 0 0 1 8.27 7.47 8 8 0 0 1-8 8.53Z" /></Svg>;
export default BookOpenText;