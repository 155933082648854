/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LegoSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lego-smiley-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lego-smiley-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 48h-16V32a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v16H72a32 32 0 0 0-32 32v96a32.06 32.06 0 0 0 24 31v17a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-17a32.06 32.06 0 0 0 24-31V80a32 32 0 0 0-32-32Zm-28 52a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm4.27 58.77a61 61 0 0 1-64.54 0 8 8 0 0 1 8.54-13.54 45 45 0 0 0 47.46 0 8 8 0 0 1 8.54 13.54ZM104 32h48v16h-48Zm-4 68a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm76 124H80v-16h96Z" /></Svg>;
export default LegoSmiley;