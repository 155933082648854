/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lightning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightning-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightning-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.71 117.38a12 12 0 0 0-7.25-8.52l-51.18-20.47 10.59-70.61a12 12 0 0 0-20.64-10l-112 120a12 12 0 0 0 4.31 19.33l51.18 20.47-10.59 70.64a12 12 0 0 0 20.64 10l112-120a12 12 0 0 0 2.94-10.84ZM113.6 203.55l6.27-41.77a12 12 0 0 0-7.41-12.92l-43.72-17.49 73.66-78.92-6.27 41.77a12 12 0 0 0 7.41 12.92l43.72 17.49Z" /></Svg>;
export default Lightning;