/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm89.8 96h-43.91c-1.54-40.77-18.48-68.23-30.43-82.67A90.19 90.19 0 0 1 217.8 122ZM128 215.83a110 110 0 0 1-15.19-19.45A128.37 128.37 0 0 1 94.13 134h67.74a128.37 128.37 0 0 1-18.68 62.38A110 110 0 0 1 128 215.83ZM94.13 122a128.37 128.37 0 0 1 18.68-62.38A110 110 0 0 1 128 40.17a110 110 0 0 1 15.19 19.45A128.37 128.37 0 0 1 161.87 122Zm18.41-82.67c-12 14.44-28.89 41.9-30.43 82.67H38.2a90.19 90.19 0 0 1 74.34-82.67ZM38.2 134h43.91c1.54 40.77 18.48 68.23 30.43 82.67A90.19 90.19 0 0 1 38.2 134Zm105.26 82.67c11.95-14.44 28.89-41.9 30.43-82.67h43.91a90.19 90.19 0 0 1-74.34 82.67Z" /></Svg>;
export default GlobeSimple;