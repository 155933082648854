/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BoxingGlove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boxing-glove-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boxing-glove-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 16h-48a56 56 0 0 0-56 56v31.73a8.17 8.17 0 0 1-7.47 8.27 8 8 0 0 1-8.53-8V78.7a4 4 0 0 0-5.63-3.65A32 32 0 0 0 24 104v29.19a16.14 16.14 0 0 0 3.5 10q.3.36.63.69L64 179.34V216a16 16 0 0 0 16 16h112a16 16 0 0 0 16-16v-38.88l15.38-53.84a16 16 0 0 0 .62-4.4V72a56 56 0 0 0-56-56Zm3.58 168.84a8 8 0 0 1-7.16 14.32L136 184.94l-28.42 14.22a8 8 0 1 1-7.16-14.32l17.69-8.84-17.69-8.84a8 8 0 1 1 7.16-14.32L136 167.06l28.42-14.22a8 8 0 1 1 7.16 14.32L153.89 176Z" /></Svg>;
export default BoxingGlove;