/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagBanner = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-banner-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-banner-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m239.22 59.44-45.63 95.82a3.54 3.54 0 0 1-.16.34l-34.21 71.84a8 8 0 1 1-14.44-6.88L173.62 160H40a8 8 0 0 1-5.66-13.66L76.69 104 34.34 61.66A8 8 0 0 1 40 48h192a8 8 0 0 1 7.22 11.44Z" /></Svg>;
export default FlagBanner;