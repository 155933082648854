/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const City = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="city-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-city-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 212h-12V88a4 4 0 0 0-4-4h-64a4 4 0 0 0-4 4v44h-56V40a4 4 0 0 0-4-4H32a4 4 0 0 0-4 4v172H16a4 4 0 0 0 0 8h224a4 4 0 0 0 0-8ZM164 92h56v120h-56Zm-8 48v72h-56v-72ZM36 44h56v168H36Zm32 28v16a4 4 0 0 1-8 0V72a4 4 0 0 1 8 0Zm0 48v16a4 4 0 0 1-8 0v-16a4 4 0 0 1 8 0Zm0 48v16a4 4 0 0 1-8 0v-16a4 4 0 0 1 8 0Zm56 16v-16a4 4 0 0 1 8 0v16a4 4 0 0 1-8 0Zm64 0v-16a4 4 0 0 1 8 0v16a4 4 0 0 1-8 0Zm0-48v-16a4 4 0 0 1 8 0v16a4 4 0 0 1-8 0Z" /></Svg>;
export default City;