/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Camera = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="camera-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-camera-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 56h-27.72l-13.63-20.44A8 8 0 0 0 160 32H96a8 8 0 0 0-6.65 3.56L75.71 56H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h160a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24Zm-44 76a36 36 0 1 1-36-36 36 36 0 0 1 36 36Z" /></Svg>;
export default Camera;