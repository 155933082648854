/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderOpen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-open-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-open-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248.23 112.31A20 20 0 0 0 232 104h-12V88a20 20 0 0 0-20-20h-68l-26.66-20a20.12 20.12 0 0 0-12-4H40a20 20 0 0 0-20 20v144a12 12 0 0 0 12 12h179.1a12 12 0 0 0 11.33-8l28.49-81.47.06-.17a20 20 0 0 0-2.75-18.05ZM92 68l28.8 21.6A12 12 0 0 0 128 92h68v12H69.77a20 20 0 0 0-18.94 13.58L44 137.15V68Zm110.59 128H48.89l23.72-68h153.76Z" /></Svg>;
export default FolderOpen;