/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotEquals = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-equals-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-equals-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 160a12 12 0 0 1-12 12H104.22l-47.34 52.07a12 12 0 0 1-17.76-16.14L71.78 172H40a12 12 0 0 1 0-24h53.6l36.4-40H40a12 12 0 0 1 0-24h111.78l47.34-52.07a12 12 0 0 1 17.76 16.14L184.22 84H216a12 12 0 0 1 0 24h-53.6L126 148h90a12 12 0 0 1 12 12Z" /></Svg>;
export default NotEquals;