/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockCountdown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-countdown-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-countdown-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 136.33A100.13 100.13 0 1 1 119.67 28a4 4 0 1 1 .66 8A92.13 92.13 0 1 0 220 135.67a4 4 0 1 1 8 .66ZM128 132h56a4 4 0 0 0 0-8h-52V72a4 4 0 0 0-8 0v56a4 4 0 0 0 4 4Zm32-88a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm36 24a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm24 36a8 8 0 1 0-8-8 8 8 0 0 0 8 8Z" /></Svg>;
export default ClockCountdown;