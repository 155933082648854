/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitCommit = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-commit-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-commit-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 122h-66.34a54 54 0 0 0-107.32 0H8a6 6 0 0 0 0 12h66.34a54 54 0 0 0 107.32 0H248a6 6 0 0 0 0-12Zm-120 48a42 42 0 1 1 42-42 42 42 0 0 1-42 42Z" /></Svg>;
export default GitCommit;