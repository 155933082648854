/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Recycle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="recycle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-recycle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M94 208a6 6 0 0 1-6 6H40a22 22 0 0 1-19-33l36.71-63.44-18.76 5a6 6 0 0 1-3.1-11.6l32.77-8.77a6 6 0 0 1 7.38 4.26l8.8 32.76a6 6 0 0 1-4.24 7.35 6.09 6.09 0 0 1-1.56.21 6 6 0 0 1-5.79-4.45l-5-18.8L31.38 187A10 10 0 0 0 40 202h48a6 6 0 0 1 6 6Zm141-27-23.14-40a6 6 0 0 0-10.38 6l23.14 40a10 10 0 0 1-8.62 15h-73.52l13.76-13.76a6 6 0 0 0-8.48-8.48l-24 24a6 6 0 0 0 0 8.48l24 24a6 6 0 0 0 8.48-8.48L142.48 214H216a22 22 0 0 0 19-33ZM136.65 35l36.72 63.44-18.76-5A6 6 0 0 0 151.5 105l32.78 8.79a6 6 0 0 0 7.34-4.25l8.79-32.78a6 6 0 1 0-11.58-3.11l-5.05 18.82L147 29a22 22 0 0 0-38 0L85.8 69a6 6 0 0 0 10.39 6l23.16-40a10 10 0 0 1 17.3 0Z" /></Svg>;
export default Recycle;