/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Globe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24Zm78.36 64h-35.65a135.28 135.28 0 0 0-22.3-45.6A88.29 88.29 0 0 1 206.37 88Zm9.64 40a87.61 87.61 0 0 1-3.33 24h-38.51a157.44 157.44 0 0 0 0-48h38.51a87.61 87.61 0 0 1 3.33 24Zm-88-85a115.27 115.27 0 0 1 26 45h-52a115.11 115.11 0 0 1 26-45Zm-26 125h52a115.11 115.11 0 0 1-26 45 115.27 115.27 0 0 1-26-45Zm-3.9-16a140.84 140.84 0 0 1 0-48h59.88a140.84 140.84 0 0 1 0 48Zm50.35 61.6a135.28 135.28 0 0 0 22.3-45.6h35.66a88.29 88.29 0 0 1-58 45.6Z" /></Svg>;
export default Globe;