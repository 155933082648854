/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNoteSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-note-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-note-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m211.45 52.51-80-24A12 12 0 0 0 116 40v100.22A52 52 0 1 0 140 184V56.13l64.55 19.36a12 12 0 1 0 6.9-23ZM88 212a28 28 0 1 1 28-28 28 28 0 0 1-28 28Z" /></Svg>;
export default MusicNoteSimple;