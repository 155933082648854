/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dresser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dresser-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dresser-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148 176a12 12 0 0 1-12 12h-16a12 12 0 0 1 0-24h16a12 12 0 0 1 12 12Zm-28-84h16a12 12 0 0 0 0-24h-16a12 12 0 0 0 0 24Zm100-52v176a20 20 0 0 1-20 20H56a20 20 0 0 1-20-20V40a20 20 0 0 1 20-20h144a20 20 0 0 1 20 20ZM60 44v72h136V44Zm136 168v-72H60v72Z" /></Svg>;
export default Dresser;