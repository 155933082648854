/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Usb = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="usb-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-usb-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m251.2 118.4-48-36A12 12 0 0 0 184 92v24H76V76h22.06a36 36 0 1 0 0-24H72a20 20 0 0 0-20 20v44H12a12 12 0 0 0 0 24h40v44a20 20 0 0 0 20 20h24v4a20 20 0 0 0 20 20h32a20 20 0 0 0 20-20v-32a20 20 0 0 0-20-20h-32a20 20 0 0 0-20 20v4H76v-40h108v24a12 12 0 0 0 19.2 9.6l48-36a12 12 0 0 0 0-19.2ZM132 52a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm-12 128h24v24h-24Zm88-40v-24l16 12Z" /></Svg>;
export default Usb;