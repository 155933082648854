/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BowlSteam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bowl-steam-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bowl-steam-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 116H32a4 4 0 0 0-4 4 100.32 100.32 0 0 0 56 89.82V216a12 12 0 0 0 12 12h64a12 12 0 0 0 12-12v-6.18A100.32 100.32 0 0 0 228 120a4 4 0 0 0-4-4Zm-57.67 87.66a4 4 0 0 0-2.33 3.64v8.7a4 4 0 0 1-4 4H96a4 4 0 0 1-4-4v-8.7a4 4 0 0 0-2.33-3.64A92.31 92.31 0 0 1 36.09 124h183.82a92.31 92.31 0 0 1-53.58 79.66ZM164.88 57.5c5.56-6.92 8-12.89 6.83-16.81-1-3.54-4.9-4.87-4.94-4.88a4 4 0 0 1 2.42-7.63c.32.1 7.84 2.52 10.16 10.11 2.05 6.72-.72 14.87-8.23 24.22-5.56 6.91-8 12.88-6.83 16.8 1 3.54 4.9 4.87 4.94 4.88A4 4 0 0 1 168 92a4.09 4.09 0 0 1-1.19-.18c-.32-.1-7.84-2.52-10.16-10.11-2.05-6.71.72-14.87 8.23-24.21Zm-40 0c5.56-6.92 8-12.89 6.83-16.81-1-3.54-4.9-4.87-4.94-4.88a4 4 0 0 1 2.42-7.63c.32.1 7.84 2.52 10.16 10.11 2.05 6.72-.72 14.87-8.23 24.22-5.56 6.91-8 12.88-6.83 16.8 1 3.54 4.9 4.87 4.94 4.88A4 4 0 0 1 128 92a4.09 4.09 0 0 1-1.19-.18c-.32-.1-7.84-2.52-10.16-10.11-2.05-6.71.72-14.87 8.23-24.21Zm-40 0c5.56-6.92 8-12.89 6.83-16.81-1-3.54-4.9-4.87-4.94-4.88a4 4 0 0 1 2.42-7.63c.32.1 7.84 2.52 10.16 10.11 2.05 6.72-.72 14.87-8.23 24.22-5.56 6.91-8 12.88-6.83 16.8 1 3.54 4.9 4.87 4.94 4.88A4 4 0 0 1 88 92a4.09 4.09 0 0 1-1.19-.18c-.32-.1-7.84-2.52-10.16-10.11-2.05-6.71.72-14.87 8.23-24.21Z" /></Svg>;
export default BowlSteam;