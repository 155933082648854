/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Graph = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="graph-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-graph-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 152a35.77 35.77 0 0 0-16.46 4l-21.39-16.64a35.49 35.49 0 0 0 1.85-10.71l10.35-3.44A36 36 0 1 0 164 100c0 1.11.06 2.21.16 3.3l-7.78 2.59A36 36 0 0 0 128 92c-1 0-1.88 0-2.81.12l-4.45-10A36 36 0 1 0 96 92c1 0 1.88 0 2.81-.12l4.45 10a35.91 35.91 0 0 0-8.59 39.7l-21.28 18.91a36 36 0 1 0 15.94 17.93l21.28-18.91a35.91 35.91 0 0 0 36.8-1.21L167 173.56A36 36 0 1 0 200 152Zm0-64a12 12 0 1 1-12 12 12 12 0 0 1 12-12ZM84 56a12 12 0 1 1 12 12 12 12 0 0 1-12-12ZM56 204a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm60-76a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm84 72a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default Graph;