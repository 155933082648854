/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popsicle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popsicle-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popsicle-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 12a68.07 68.07 0 0 0-68 68v96a12 12 0 0 0 12 12h36v44a20 20 0 0 0 40 0v-44h36a12 12 0 0 0 12-12V80a68.07 68.07 0 0 0-68-68Zm12 220a12 12 0 0 1-24 0v-44h24Zm48-56a4 4 0 0 1-4 4H72a4 4 0 0 1-4-4V80a60 60 0 0 1 120 0ZM116 72v80a4 4 0 0 1-8 0V72a4 4 0 0 1 8 0Zm32 0v80a4 4 0 0 1-8 0V72a4 4 0 0 1 8 0Z" /></Svg>;
export default Popsicle;