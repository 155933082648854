/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeStand = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-stand-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-stand-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 182a78 78 0 1 0-78-78 78.09 78.09 0 0 0 78 78Zm0-144a66 66 0 1 1-66 66 66.08 66.08 0 0 1 66-66Zm76.33 136.89a6 6 0 0 1-.17 8.48A109.21 109.21 0 0 1 142 213.83V234h26a6 6 0 0 1 0 12h-64a6 6 0 0 1 0-12h26v-20.17a110 110 0 0 1-73.38-186 6 6 0 0 1 8.66 8.32 98 98 0 0 0 138.56 138.57 6 6 0 0 1 8.49.17Z" /></Svg>;
export default GlobeStand;