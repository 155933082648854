/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Avocado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="avocado-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-avocado-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m203.45 133.33-29.82-84.28a48 48 0 0 0-90.9-1l-29.54 83.54a80 80 0 1 0 150.26 1.74ZM128 200a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 112a48 48 0 1 0 48 48 48.05 48.05 0 0 0-48-48Zm0 80a32 32 0 1 1 32-32 32 32 0 0 1-32 32Zm83-61.34-29.8-84.19a56 56 0 0 0-106-1.14l-29.51 83.5A88 88 0 1 0 211 130.66ZM128 232a72.05 72.05 0 0 1-67.33-97.57 1.34 1.34 0 0 1 .07-.18l29.54-83.59a40 40 0 0 1 75.74.88l.06.18L195.9 136a72.05 72.05 0 0 1-67.9 96Z" /></Svg>;
export default Avocado;