/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Knife = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="knife-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-knife-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 49a25 25 0 0 0-42.63-17.66l-50.21 50.2L18.34 206.4a8 8 0 0 0 3.86 13.45A160.67 160.67 0 0 0 58.4 224c32.95 0 65.91-10.2 96.94-30.23 31.76-20.5 50.19-43.82 51-44.8a8 8 0 0 0-.64-10.59L181.31 114l47.38-47.39A24.84 24.84 0 0 0 236 49Zm-89.77 131.6c-34.43 22.1-69.94 30.92-105.76 26.3L146 101.34l43.09 43.1a220.09 220.09 0 0 1-42.86 36.16Z" /></Svg>;
export default Knife;