/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireExtinguisher = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-extinguisher-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-extinguisher-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m218.3 48.34-60.68-18.2 30-15a8 8 0 0 0-7.2-14.29L134 24.05a80.08 80.08 0 0 0-78 80V208a8 8 0 0 0 16 0v-32h16v56a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V104a48.07 48.07 0 0 0-40-47.32V42.75l69.7 20.91a8 8 0 1 0 4.6-15.32ZM72 160v-56a64.07 64.07 0 0 1 56-63.48v16.16A48.07 48.07 0 0 0 88 104v56Zm96-56v56h-64v-56a32 32 0 0 1 64 0Z" /></Svg>;
export default FireExtinguisher;