/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stethoscope = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stethoscope-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stethoscope-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 160a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-4.17 35.79A44.06 44.06 0 0 1 168 236h-24a44.05 44.05 0 0 1-44-44v-44.15A60 60 0 0 1 44 88V40a4 4 0 0 1 4-4h24a4 4 0 0 1 0 8H52v44a52 52 0 0 0 52.69 52C133 139.63 156 115.93 156 87.17V44h-20a4 4 0 0 1 0-8h24a4 4 0 0 1 4 4v47.17c0 32-24.84 58.59-56 60.69V192a36 36 0 0 0 36 36h24a36.05 36.05 0 0 0 35.8-32.25 36 36 0 1 1 8 0ZM236 160a28 28 0 1 0-28 28 28 28 0 0 0 28-28Z" /></Svg>;
export default Stethoscope;