/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GreaterThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="greater-than-or-equal-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-greater-than-or-equal-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51.85 148.74 173.29 104 51.85 59.26a12 12 0 0 1 8.3-22.52l152 56a12 12 0 0 1 0 22.52l-152 56a12 12 0 1 1-8.3-22.52ZM208 188H56a12 12 0 0 0 0 24h152a12 12 0 0 0 0-24Z" /></Svg>;
export default GreaterThanOrEqual;