/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBrush = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-brush-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-brush-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 32a12 12 0 0 0-12-12c-44.78 0-90 48.54-115.9 82a64 64 0 0 0-80 62c0 12-3.1 22.71-9.23 31.76a43 43 0 0 1-9.47 10.29 11.88 11.88 0 0 0-4.91 13.38A12.07 12.07 0 0 0 16.11 228h76A64 64 0 0 0 154 148c33.49-25.95 82-71.2 82-116Zm-26.38 14.39c-4 12.92-13.15 27.49-26.92 42.91-3 3.39-6.16 6.7-9.35 9.89a104.31 104.31 0 0 0-16.5-16.51c3.19-3.19 6.49-6.32 9.88-9.35 15.42-13.78 29.98-22.9 42.89-26.94ZM92.07 204H42a80.17 80.17 0 0 0 10.14-40 40 40 0 1 1 40 40Zm38.18-91.32c3.12-3.93 6.55-8.09 10.23-12.35a80.52 80.52 0 0 1 15.23 15.24 388.06 388.06 0 0 1-12.35 10.23 64.43 64.43 0 0 0-13.11-13.12Z" /></Svg>;
export default PaintBrush;