/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LetterCircleV = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="letter-circle-v-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-letter-circle-v-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90Zm37.57-119.77-32 80a6 6 0 0 1-11.14 0l-32-80a6 6 0 1 1 11.14-4.46L128 159.84l26.43-66.07a6 6 0 0 1 11.14 4.46Z" /></Svg>;
export default LetterCircleV;