/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Onigiri = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="onigiri-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-onigiri-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 168v56H88v-56a8 8 0 0 1 8-8h64a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m231.53 146.57-55.85-95.91-.11-.19a56 56 0 0 0-95.14 0l-.11.19-55.85 95.91A56 56 0 0 0 72.09 232h111.82a56 56 0 0 0 47.62-85.43ZM160 216H96v-48h64Zm58.86-20.55A39.49 39.49 0 0 1 183.91 216H176v-48a16 16 0 0 0-16-16H96a16 16 0 0 0-16 16v48h-7.91a40 40 0 0 1-34-61.09 2 2 0 0 0 .11-.2l55.85-95.9a40 40 0 0 1 67.84 0l55.85 95.9a2 2 0 0 0 .11.2 39.5 39.5 0 0 1 1 40.54Z" /></Svg>;
export default Onigiri;