/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UsersFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="users-four-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-users-four-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.4 219.19A8 8 0 0 1 224 232H32a8 8 0 0 1-6.4-12.8A67.88 67.88 0 0 1 53 197.51a40 40 0 1 1 53.93 0 67.42 67.42 0 0 1 21 14.29 67.42 67.42 0 0 1 21-14.29 40 40 0 1 1 53.93 0 67.85 67.85 0 0 1 27.54 21.68ZM27.2 126.4a8 8 0 0 0 11.2-1.6 52 52 0 0 1 83.2 0 8 8 0 0 0 12.8 0 52 52 0 0 1 83.2 0 8 8 0 0 0 12.8-9.61A67.85 67.85 0 0 0 203 93.51a40 40 0 1 0-53.93 0 67.42 67.42 0 0 0-21 14.29 67.42 67.42 0 0 0-21-14.29 40 40 0 1 0-53.93 0A67.88 67.88 0 0 0 25.6 115.2a8 8 0 0 0 1.6 11.2Z" /></Svg>;
export default UsersFour;