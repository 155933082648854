/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-medium-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-medium-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M54 136a6 6 0 0 1-6 6H24a6 6 0 0 1 0-12h10V62H24a6 6 0 0 1 0-12h16a6 6 0 0 1 5.09 2.8L80 108.68l34.91-55.86A6 6 0 0 1 120 50h16a6 6 0 0 1 0 12h-10v68h10a6 6 0 0 1 0 12h-24a6 6 0 0 1 0-12h2V76.92l-28.91 46.26a6 6 0 0 1-10.18 0L46 76.92V130h2a6 6 0 0 1 6 6Zm114-26h64a6 6 0 0 0 0-12h-64a6 6 0 0 0 0 12Zm64 20h-64a6 6 0 0 0 0 12h64a6 6 0 0 0 0-12Zm0 32H80a6 6 0 0 0 0 12h152a6 6 0 0 0 0-12Zm0 32H80a6 6 0 0 0 0 12h152a6 6 0 0 0 0-12Z" /></Svg>;
export default ArticleMedium;