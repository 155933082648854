/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileRs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-rs-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-rs-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m212.24 83.76-56-56A6 6 0 0 0 152 26H56a14 14 0 0 0-14 14v72a6 6 0 0 0 12 0V40a2 2 0 0 1 2-2h90v50a6 6 0 0 0 6 6h50v122a2 2 0 0 1-2 2h-16a6 6 0 0 0 0 12h16a14 14 0 0 0 14-14V88a6 6 0 0 0-1.76-4.24ZM158 46.48 193.52 82H158ZM90 172a26 26 0 0 0-26-26H48a6 6 0 0 0-6 6v56a6 6 0 0 0 12 0v-10h10a26 26 0 0 0 6.71-.88L78.82 211a6 6 0 0 0 5.18 3 5.91 5.91 0 0 0 3-.82 6 6 0 0 0 2.16-8.2l-7.91-13.57A25.93 25.93 0 0 0 90 172Zm-36 14v-28h10a14 14 0 0 1 0 28Zm99.83 10.06a18.89 18.89 0 0 1-8.31 13.81c-4.82 3.19-10.87 4.14-16.36 4.14a58.89 58.89 0 0 1-14.68-2 6 6 0 0 1 3.23-11.56c3.71 1 15.58 3.11 21.19-.62a6.85 6.85 0 0 0 3-5.34c.58-4.43-2.08-6.26-14.2-9.76-9.31-2.69-23.37-6.75-21.57-20.94a18.61 18.61 0 0 1 8.08-13.54c11.11-7.49 29.18-3 31.21-2.48a6 6 0 0 1-3.06 11.6c-3.78-1-15.85-3-21.45.84a6.59 6.59 0 0 0-2.88 5.08c-.41 3.22 2.14 4.78 13 7.91 9.89 2.89 24.81 7.2 22.8 22.86Z" /></Svg>;
export default FileRs;