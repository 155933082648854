/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Storefront = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="storefront-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-storefront-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M26.22 94.41A6 6 0 0 0 26 96v16a38 38 0 0 0 16 31v73a6 6 0 0 0 6 6h160a6 6 0 0 0 6-6v-73a38 38 0 0 0 16-31V96a5.91 5.91 0 0 0-.23-1.64l-14.34-50.21A14.07 14.07 0 0 0 202 34H54a14.07 14.07 0 0 0-13.43 10.15Zm25.89-47A2 2 0 0 1 54 46h148a2 2 0 0 1 1.92 1.45L216.05 90H40ZM102 102h52v10a26 26 0 0 1-52 0Zm-64 0h52v10a26 26 0 0 1-38.18 23 6 6 0 0 0-1.65-1A26 26 0 0 1 38 112Zm164 108H54v-61.34a38 38 0 0 0 42-16.21 37.95 37.95 0 0 0 64 0 38 38 0 0 0 42 16.21Zm3.83-76a6 6 0 0 0-1.65 1A26 26 0 0 1 166 112v-10h52v10a26 26 0 0 1-12.17 22Z" /></Svg>;
export default Storefront;