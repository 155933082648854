/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleDriveLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-drive-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-drive-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.6 143.78 169.13 31.52A15.91 15.91 0 0 0 155.56 24h-55.13a15.89 15.89 0 0 0-13.56 7.52l-.05.07L18.44 143.7a16 16 0 0 0-.33 16.42l27.32 47.82A16 16 0 0 0 59.32 216h137.35a16 16 0 0 0 13.89-8.06l27.32-47.82a15.91 15.91 0 0 0-.28-16.34ZM219 144h-46.48l-35.19-58.67 22.75-37.92Zm-116.87 0L128 100.88 153.87 144Zm61.34 16 24 40H68.53l24-40ZM128 69.78 110.12 40l35.78-.05ZM95.91 47.41l22.76 37.92L83.47 144H37ZM36.54 160h37.33l-19.15 31.92Zm164.74 31.93L182.12 160h37.41Z" /></Svg>;
export default GoogleDriveLogo;