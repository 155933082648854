/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireTruck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-truck-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-truck-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m255.14 115.54-14-35A19.9 19.9 0 0 0 222.58 68H204v-4a12 12 0 0 0-24 0v94.06A36.23 36.23 0 0 0 158.06 180h-44.12a36 36 0 0 0-67.88 0H36v-36a12 12 0 0 0-24 0v40a20 20 0 0 0 20 20h14.06a36 36 0 0 0 67.88 0h44.12a36 36 0 0 0 67.88 0H236a20 20 0 0 0 20-20v-64a12 12 0 0 0-.86-4.46ZM219.88 92l6.4 16H204V92ZM80 204a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm112 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm33.94-24A36.23 36.23 0 0 0 204 158.06V132h28v48ZM24 92a12 12 0 0 0 0 24h128a12 12 0 0 0 0-24h-12V76h12a12 12 0 0 0 0-24H24a12 12 0 0 0 0 24h12v16Zm92 0h-16V76h16ZM60 76h16v16H60Z" /></Svg>;
export default FireTruck;