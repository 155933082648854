/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FinnTheHuman = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="finn-the-human-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-finn-the-human-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 148a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-84-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm148-64v80a72.08 72.08 0 0 1-72 72H88a72.08 72.08 0 0 1-72-72V72a32 32 0 0 1 63-8h98a32 32 0 0 1 63 8Zm-40 72a32 32 0 0 0-32-32H88a32 32 0 0 0-32 32v8a32 32 0 0 0 32 32h80a32 32 0 0 0 32-32Z" /></Svg>;
export default FinnTheHuman;