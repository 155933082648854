/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cylinder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cylinder-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cylinder-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 18c-39.25 0-70 18.45-70 42v136c0 23.55 30.75 42 70 42s70-18.45 70-42V60c0-23.55-30.75-42-70-42Zm0 12c31.44 0 58 13.74 58 30s-26.56 30-58 30-58-13.74-58-30 26.56-30 58-30Zm0 196c-31.44 0-58-13.74-58-30V83.81C82.48 94.87 103.59 102 128 102s45.52-7.13 58-18.19V196c0 16.26-26.56 30-58 30Z" /></Svg>;
export default Cylinder;