/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LightningA = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightning-a-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightning-a-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M72.8 216 88 152l-56-25.14L127.2 24 112 88l56 25.14Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M175.84 111.54a8 8 0 0 0-4.56-5.7l-50-22.43L135 25.85a8 8 0 0 0-13.65-7.28L26.13 121.42a8 8 0 0 0 2.59 12.73l50 22.44L65 214.15a8 8 0 0 0 13.65 7.28l95.2-102.85a8 8 0 0 0 1.99-7.04Zm-88.22 76.67 8.16-34.36a8 8 0 0 0-4.5-9.15l-45.85-20.58 66.95-72.33-8.16 34.36a8 8 0 0 0 4.5 9.15l45.84 20.58Zm151.53 24.21-36-72a8 8 0 0 0-14.31 0l-36 72a8 8 0 0 0 14.31 7.16l9.79-19.58h38.11l9.79 19.58A8 8 0 0 0 232 224a8 8 0 0 0 7.15-11.58ZM184.94 184 196 161.89 207.05 184Z" /></Svg>;
export default LightningA;