/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CodaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="coda-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-coda-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 92a35.79 35.79 0 0 1 19.38 5.47A16 16 0 0 0 220 84V48a20 20 0 0 0-20-20H56a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h144a20 20 0 0 0 20-20v-36a16 16 0 0 0-24.6-13.48c-6.58 4.22-11.89 5.76-18.92 5.48H176a36 36 0 0 1 0-72Zm-.24 96a52.4 52.4 0 0 0 20.24-3v19H60V52h136v19.3a61.55 61.55 0 0 0-20-3.3 60 60 0 0 0-.24 120Z" /></Svg>;
export default CodaLogo;