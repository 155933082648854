/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Checkerboard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="checkerboard-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-checkerboard-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 48a20 20 0 0 0-20-20H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20Zm-35 68-53-53V52h11l53 53v11Zm-53-19 19 19h-19Zm64-26-19-19h19Zm-88-19v64H52V52Zm-11 152-53-53v-11h11l53 53v11Zm11-45-19-19h19Zm-64 26 19 19H52Zm88 19v-64h64v64Z" /></Svg>;
export default Checkerboard;