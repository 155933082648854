/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Toolbox = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="toolbox-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-toolbox-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 64h-48v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H32a16 16 0 0 0-16 16v28a4 4 0 0 0 4 4h44V96.27A8.17 8.17 0 0 1 71.47 88 8 8 0 0 1 80 96v16h96V96.27a8.17 8.17 0 0 1 7.47-8.27 8 8 0 0 1 8.53 8v16h44a4 4 0 0 0 4-4V80a16 16 0 0 0-16-16Zm-64 0H96v-8a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8Zm80 68v60a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-60a4 4 0 0 1 4-4h44v16a8 8 0 0 0 8.53 8 8.17 8.17 0 0 0 7.47-8.27V128h96v16a8 8 0 0 0 8.53 8 8.17 8.17 0 0 0 7.47-8.25V128h44a4 4 0 0 1 4 4Z" /></Svg>;
export default Toolbox;