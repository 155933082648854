/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Meteor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="meteor-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-meteor-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96 124a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm0 64a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm122.83-62.83a4 4 0 0 1 0 5.66l-48 48a4 4 0 0 1-5.66-5.66l48-48a4 4 0 0 1 5.66 0Zm-32-18.34-24 24a4 4 0 0 1-5.66-5.66l24-24a4 4 0 1 1 5.66 5.66Zm40-40-16 16a4 4 0 0 1-5.66-5.66l16-16a4 4 0 1 1 5.66 5.66ZM125.17 93.17l72-72a4 4 0 1 1 5.66 5.66l-72 72a4 4 0 1 1-5.66-5.66Zm18.91 109.26a4 4 0 0 1 0 5.65 68 68 0 1 1-96.16-96.16l82.74-82.75a4 4 0 1 1 5.66 5.66l-82.75 82.74a60 60 0 0 0 84.86 84.86 4 4 0 0 1 5.65 0Z" /></Svg>;
export default Meteor;