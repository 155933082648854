/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Placeholder = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="placeholder-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-placeholder-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 28H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 159L69 52h135ZM52 69l135 135H52Z" /></Svg>;
export default Placeholder;