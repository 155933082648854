/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Users = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="users-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-users-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M125.18 156.94a64 64 0 1 0-82.36 0 100.23 100.23 0 0 0-39.49 32 12 12 0 0 0 19.35 14.2 76 76 0 0 1 122.64 0 12 12 0 0 0 19.36-14.2 100.33 100.33 0 0 0-39.5-32ZM44 108a40 40 0 1 1 40 40 40 40 0 0 1-40-40Zm206.1 97.67a12 12 0 0 1-16.78-2.57A76.31 76.31 0 0 0 172 172a12 12 0 0 1 0-24 40 40 0 1 0-10.3-78.67 12 12 0 1 1-6.16-23.19 64 64 0 0 1 57.64 110.8 100.23 100.23 0 0 1 39.49 32 12 12 0 0 1-2.57 16.73Z" /></Svg>;
export default Users;