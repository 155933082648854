/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareZero = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-zero-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-zero-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 188c28.26 0 48-24.67 48-60s-19.74-60-48-60-48 24.67-48 60 19.74 60 48 60Zm0-96c23.33 0 24 32.32 24 36s-.67 36-24 36-24-32.32-24-36 .67-36 24-36Zm80-64H48a20 20 0 0 0-20 20v160a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20V48a20 20 0 0 0-20-20Zm-4 176H52V52h152Z" /></Svg>;
export default NumberSquareZero;