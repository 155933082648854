/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PenNib = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pen-nib-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pen-nib-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 92.68a19.86 19.86 0 0 0-5.86-14.14L177.46 9.85a20 20 0 0 0-28.29 0l-27.82 27.82-57.24 21.47A20 20 0 0 0 51.4 74.58L28.16 214A12 12 0 0 0 40 228a11.9 11.9 0 0 0 2-.16l139.45-23.25a20.07 20.07 0 0 0 15.44-12.7l21.46-57.25 27.82-27.82A19.85 19.85 0 0 0 252 92.68Zm-76.8 88.62L75 198l33-33a34 34 0 1 0-17-17l-33 33L74.7 80.8 125 61.94 194.05 131ZM112 134a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm96-23-63-63 18.34-18.34 63 63Z" /></Svg>;
export default PenNib;