/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BabyCarriage = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baby-carriage-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baby-carriage-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 28h-8a20 20 0 0 0-20 20v52H58.16A40.07 40.07 0 0 0 20 72a12 12 0 0 0 0 24 16 16 0 0 1 16 16 84.09 84.09 0 0 0 84 84h40a84 84 0 0 0 0-168Zm48.06 48.12A59.58 59.58 0 0 1 218.79 100h-40.58ZM160 52a59.66 59.66 0 0 1 29.83 8L156 87V52Zm0 120h-40a60.1 60.1 0 0 1-58.79-48h157.58A60.1 60.1 0 0 1 160 172Zm-52 52a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm104 0a20 20 0 1 1-20-20 20 20 0 0 1 20 20Z" /></Svg>;
export default BabyCarriage;