/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Calculator = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="calculator-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-calculator-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16ZM88 200a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-40a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm40 40a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-40a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm40 40a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm0-40a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm16-56a8 8 0 0 1-8 8H80a8 8 0 0 1-8-8V64a8 8 0 0 1 8-8h96a8 8 0 0 1 8 8Z" /></Svg>;
export default Calculator;