/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 0 0-6 5.38l15.62 17.14A95.41 95.41 0 0 0 36 124v84a12 12 0 0 0 12 12h84a96.34 96.34 0 0 0 57.11-18.83L205 218.69a4 4 0 1 0 5.92-5.38ZM132 212H48a4 4 0 0 1-4-4v-84a87.49 87.49 0 0 1 22-58.23l117.7 129.44A86.92 86.92 0 0 1 132 212Zm96-88a95.79 95.79 0 0 1-13.78 49.58 4 4 0 1 1-6.84-4.14A88 88 0 0 0 93.92 44.64a4 4 0 1 1-3.46-7.21A95 95 0 0 1 132 28a96.11 96.11 0 0 1 96 96Z" /></Svg>;
export default ChatTeardropSlash;