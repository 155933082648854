/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Barricade = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="barricade-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-barricade-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 64H32a16 16 0 0 0-16 16v72a16 16 0 0 0 16 16h24v32a8 8 0 0 0 16 0v-32h112v32a8 8 0 0 0 16 0v-32h24a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16ZM32 152V92l60 60Zm192 0h-56.69l-72-72H164l60 60v12Z" /></Svg>;
export default Barricade;