/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shovel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shovel-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shovel-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m248.49 55.51-48-48a12 12 0 0 0-17 17L199 40l-65.37 65.37-31.49-31.51a20 20 0 0 0-28.28 0l-56 56A19.9 19.9 0 0 0 12 144v80a20 20 0 0 0 20 20h80a19.86 19.86 0 0 0 14.14-5.86l56-56a20 20 0 0 0 0-28.28l-31.51-31.52L216 57l15.51 15.52a12 12 0 1 0 17-17ZM162.34 168l-52 52H36v-74.34l52-52 28.69 28.68-37.18 37.17a12 12 0 0 0 17 17l37.17-37.18Z" /></Svg>;
export default Shovel;