/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Island = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="island__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-island'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.25 229a8 8 0 0 1-11.25 1.25c-.37-.3-38.82-30.25-99-30.25s-98.64 30-99 30.26a8 8 0 0 1-10-12.51c1.63-1.3 38.52-30.26 98.29-33.45a119.94 119.94 0 0 1-3.29-37.93c1.74-21.71 10.92-50.63 43-72.48A64.65 64.65 0 0 0 140.26 72c-19 .62-30.94 11.71-36.5 33.92A8 8 0 0 1 96 112a7.64 7.64 0 0 1-1.94-.24 8 8 0 0 1-5.82-9.7c9.25-36.95 33.11-45.42 51.5-46a81.48 81.48 0 0 1 21.68 2.45c-3.83-6.33-9.43-12.93-17.21-16.25-10-4.24-22.17-2.39-36.31 5.51a8 8 0 0 1-7.8-14c18.74-10.45 35.72-12.54 50.48-6.2 12.49 5.36 20.73 15.78 25.87 25 6.18-9.64 13.88-16.17 22.39-18.94 11.86-3.87 24.64-.72 38 9.37a8 8 0 0 1-9.64 12.76c-8.91-6.73-16.77-9.06-23.35-6.93-7.29 2.35-12.87 10-16.37 16.61A70.46 70.46 0 0 1 208 73.07c14.61 8.35 32 26.05 32 62.94a8 8 0 0 1-16 0c0-23.46-8.07-40-24-49a50.49 50.49 0 0 0-5.75-2.8 55.64 55.64 0 0 1 5.06 33.06 59.41 59.41 0 0 1-8.86 23.41 8 8 0 0 1-13.09-9.2c.74-1.09 16.33-24.38-3.26-49.37-27 15.21-41.89 37.25-44.16 65.59a104.27 104.27 0 0 0 3.83 36.44c62.65 1.81 101.52 32.33 103.2 33.66a8 8 0 0 1 1.28 11.2ZM24 140a28 28 0 1 1 28 28 28 28 0 0 1-28-28Zm16 0a12 12 0 1 0 12-12 12 12 0 0 0-12 12Z" /></Svg>;
export default Island;