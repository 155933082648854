/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrendUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trend-up-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trend-up-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 56v64l-64-64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M232 48h-64a8 8 0 0 0-5.66 13.66L188.69 88 136 140.69l-34.34-34.35a8 8 0 0 0-11.32 0l-72 72a8 8 0 0 0 11.32 11.32L96 123.31l34.34 34.35a8 8 0 0 0 11.32 0L200 99.31l26.34 26.35A8 8 0 0 0 240 120V56a8 8 0 0 0-8-8Zm-8 52.69L187.31 64H224Z" /></Svg>;
export default TrendUp;