/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Magnet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="magnet-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-magnet-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M209.84 47.44A91.42 91.42 0 0 0 144.62 20h-.35a91.44 91.44 0 0 0-65.12 27l-61.39 62.17a20 20 0 0 0 .08 28.21L46.5 166a19.89 19.89 0 0 0 14.15 5.86h.11a19.86 19.86 0 0 0 14.2-6l60.87-62.39a12.07 12.07 0 0 1 16.77-.12 11.63 11.63 0 0 1 3.5 8.35 12.85 12.85 0 0 1-3.66 9.11L90.19 181a20 20 0 0 0-.21 28.5l28.66 28.66a20.08 20.08 0 0 0 28.15.14l61.76-60.44.09-.09c36.01-35.99 36.54-94.46 1.2-130.33ZM60.62 146.21l-23-23 18.19-18.41 22.88 22.88Zm72.2 72.18-23-23 18.61-18 22.88 22.87Zm58.89-57.62-23.23 22.73-22.78-22.77L169.21 138l.15-.14a37 37 0 0 0 10.74-26.37 35.44 35.44 0 0 0-10.85-25.41 36.2 36.2 0 0 0-50.44.48l-.1.11-23.25 23.82-22.78-22.77 23.5-23.8A67.55 67.55 0 0 1 144.27 44h.26a67.62 67.62 0 0 1 48.21 20.29c26.11 26.49 25.64 69.76-1.03 96.48Z" /></Svg>;
export default Magnet;