/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MedalMilitary = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="medal-military-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-medal-military-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M207 44H49a13 13 0 0 0-13 13v49.21A13 13 0 0 0 43.62 118l70.72 32.14a44 44 0 1 0 27.32 0L212.38 118a13 13 0 0 0 7.62-11.79V57a13 13 0 0 0-13-13Zm-43 8v79.24l-36 16.37-36-16.37V52ZM44 106.21V57a5 5 0 0 1 5-5h35v75.61l-37.07-16.85a5 5 0 0 1-2.93-4.55ZM164 192a36 36 0 1 1-36-36 36 36 0 0 1 36 36Zm48-85.79a5 5 0 0 1-2.93 4.55L172 127.61V52h35a5 5 0 0 1 5 5Z" /></Svg>;
export default MedalMilitary;