/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-four-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-four-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm2 174a2 2 0 0 1-2 2H48a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2h160a2 2 0 0 1 2 2Zm-50-62h-10V80a6 6 0 0 0-10.74-3.68l-56 72A6 6 0 0 0 88 158h50v18a6 6 0 0 0 12 0v-18h10a6 6 0 0 0 0-12Zm-22 0h-37.73L138 97.49Z" /></Svg>;
export default NumberSquareFour;