/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WhatsappLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="whatsapp-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-whatsapp-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m186.68 146.63-32-16a6 6 0 0 0-6 .38L133 141.46A42.49 42.49 0 0 1 114.54 123L125 107.33a6 6 0 0 0 .38-6l-16-32A6 6 0 0 0 104 66a38 38 0 0 0-38 38 86.1 86.1 0 0 0 86 86 38 38 0 0 0 38-38 6 6 0 0 0-3.32-5.37ZM152 178a74.09 74.09 0 0 1-74-74 26 26 0 0 1 22.42-25.75l12.66 25.32-10.39 15.58a6 6 0 0 0-.54 5.63 54.43 54.43 0 0 0 29.07 29.07 6 6 0 0 0 5.63-.54l15.58-10.39 25.32 12.66A26 26 0 0 1 152 178ZM128 26a102 102 0 0 0-89.65 150.69l-11.62 34.87a14 14 0 0 0 17.71 17.71l34.87-11.62A102 102 0 1 0 128 26Zm0 192a90 90 0 0 1-45.06-12.08 6.09 6.09 0 0 0-3-.81 6.2 6.2 0 0 0-1.9.31l-37.39 12.46a2 2 0 0 1-2.53-2.53L50.58 178a6 6 0 0 0-.5-4.91A90 90 0 1 1 128 218Z" /></Svg>;
export default WhatsappLogo;