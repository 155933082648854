/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrolleySuitcase = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trolley-suitcase-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trolley-suitcase-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96 156h120a20 20 0 0 0 20-20V76a20 20 0 0 0-20-20h-24V40a20 20 0 0 0-20-20h-32a20 20 0 0 0-20 20v16H96a20 20 0 0 0-20 20v60a20 20 0 0 0 20 20Zm48-112h24v12h-24Zm-44 36h112v52H100Zm-8 144a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm144 0a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm16-44a12 12 0 0 1-12 12H32a12 12 0 0 1 0-24h4V77L15.51 56.49a12 12 0 0 1 17-17l21.63 21.68A19.86 19.86 0 0 1 60 75.31V168h180a12 12 0 0 1 12 12Z" /></Svg>;
export default TrolleySuitcase;