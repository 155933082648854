/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartBreak = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-break-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-break-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.81 107.5c-5.19 67.42-103.7 121.23-108 123.54a8 8 0 0 1-7.58 0C119.8 228.67 16 172 16 102a62 62 0 0 1 96.47-51.55 4 4 0 0 1 .61 6.17L99.72 70a8 8 0 0 0 0 11.31l32.53 32.53L111 135a8 8 0 1 0 11.31 11.31l26.88-26.87a8 8 0 0 0 0-11.31l-32.49-32.5 17.47-17.47A61.63 61.63 0 0 1 178.41 40c36.32.23 64.18 31.29 61.4 67.5Z" /></Svg>;
export default HeartBreak;