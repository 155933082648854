/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SolarRoof = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="solar-roof-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-solar-roof-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 128H96L56 48h144Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m247.16 124.42-40-80A8 8 0 0 0 200 40H56a8 8 0 0 0-7.16 4.42l-40 80A8.08 8.08 0 0 0 8 128v56a16 16 0 0 0 16 16h208a16 16 0 0 0 16-16v-56a8.08 8.08 0 0 0-.84-3.58ZM99.06 56l12 24H80.94l-12-24Zm48 0 12 24h-30.12l-12-24Zm-46.12 64-12-24h30.12l12 24Zm48 0-12-24h30.12l12 24Zm48 0-12-24h30.12l12 24Zm10.12-40h-30.12l-12-24h30.12ZM24 129.89l32-64 32 64V184H24ZM104 184v-48h128v48Z" /></Svg>;
export default SolarRoof;