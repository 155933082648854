/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileCpp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-cpp-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-cpp-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M46 180c0 12.13 8.07 22 18 22a16.23 16.23 0 0 0 11.67-5.28 6 6 0 0 1 8.66 8.3A28.06 28.06 0 0 1 64 214c-16.54 0-30-15.25-30-34s13.46-34 30-34a28.06 28.06 0 0 1 20.33 9 6 6 0 0 1-8.66 8.3A16.23 16.23 0 0 0 64 158c-9.93 0-18 9.87-18 22Zm-4-68V40a14 14 0 0 1 14-14h96a6 6 0 0 1 4.25 1.76l56 56A6 6 0 0 1 214 88v24a6 6 0 0 1-12 0V94h-50a6 6 0 0 1-6-6V38H56a2 2 0 0 0-2 2v72a6 6 0 0 1-12 0Zm116-30h35.52L158 46.48Zm-10 92h-14v-14a6 6 0 0 0-12 0v14h-14a6 6 0 0 0 0 12h14v14a6 6 0 0 0 12 0v-14h14a6 6 0 0 0 0-12Zm68 0h-14v-14a6 6 0 0 0-12 0v14h-14a6 6 0 0 0 0 12h14v14a6 6 0 0 0 12 0v-14h14a6 6 0 0 0 0-12Z" /></Svg>;
export default FileCpp;