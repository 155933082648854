/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MarkdownLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="markdown-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-markdown-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 50H24a14 14 0 0 0-14 14v128a14 14 0 0 0 14 14h208a14 14 0 0 0 14-14V64a14 14 0 0 0-14-14Zm2 142a2 2 0 0 1-2 2H24a2 2 0 0 1-2-2V64a2 2 0 0 1 2-2h208a2 2 0 0 1 2 2Zm-108-88v48a6 6 0 0 1-12 0v-33.51l-21.76 21.75a6 6 0 0 1-8.48 0L62 118.49V152a6 6 0 0 1-12 0v-48a6 6 0 0 1 10.24-4.24L88 127.51l27.76-27.75A6 6 0 0 1 126 104Zm78.24 19.76a6 6 0 0 1 0 8.48l-24 24a6 6 0 0 1-8.48 0l-24-24a6 6 0 1 1 8.48-8.48L170 137.51V104a6 6 0 0 1 12 0v33.51l13.76-13.75a6 6 0 0 1 8.48 0Z" /></Svg>;
export default MarkdownLogo;