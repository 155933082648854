/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CellSignalSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cell-signal-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cell-signal-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 152v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Zm-52 28a12 12 0 0 0-12 12v8a12 12 0 0 0 24 0v-8a12 12 0 0 0-12-12Zm176.88 27.93-160-176a12 12 0 1 0-17.76 16.14L108 123.84V200a12 12 0 0 0 24 0v-49.76l16 17.6V200a12 12 0 0 0 24 0v-5.76l27.12 29.83a12 12 0 0 0 17.76-16.14ZM160 115.74a12 12 0 0 0 12-12V72a12 12 0 0 0-24 0v31.74a12 12 0 0 0 12 12Zm40 44a12 12 0 0 0 12-12V32a12 12 0 0 0-24 0v115.74a12 12 0 0 0 12 12Z" /></Svg>;
export default CellSignalSlash;