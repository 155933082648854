/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSubscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-subscript-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-subscript-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 208a4 4 0 0 1-4 4h-48a4 4 0 0 1-3.2-6.4L232 148a20 20 0 0 0-4-28 20 20 0 0 0-28 4 20.23 20.23 0 0 0-2.89 5.37 4 4 0 0 1-7.55-2.66 28.34 28.34 0 0 1 4-7.52 28 28 0 0 1 44.72 33.7L200 204h40a4 4 0 0 1 4 4ZM146.62 53a4 4 0 0 0-5.64.4L92 109.89 43 53.38a4 4 0 1 0-6 5.24L86.71 116 37 173.38a4 4 0 1 0 6 5.24l49-56.51 49 56.51a4 4 0 0 0 6-5.24L97.29 116 147 58.62a4 4 0 0 0-.38-5.62Z" /></Svg>;
export default TextSubscript;