/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Images = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="images-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-images-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 88a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm76-32v104a20 20 0 0 1-20 20h-12v20a20 20 0 0 1-20 20H40a20 20 0 0 1-20-20V88a20 20 0 0 1 20-20h20V56a20 20 0 0 1 20-20h136a20 20 0 0 1 20 20Zm-56 124H80a20 20 0 0 1-20-20V92H44v104h136Zm-21.66-24L124 121.66 89.66 156ZM212 60H84v67.72l25.86-25.86a20 20 0 0 1 28.28 0L192.28 156H212Z" /></Svg>;
export default Images;