/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GreaterThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="greater-than-or-equal-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-greater-than-or-equal-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M53.93 154.37 190.64 104 53.93 53.63a6 6 0 1 1 4.15-11.26l152 56a6 6 0 0 1 0 11.26l-152 56A6.09 6.09 0 0 1 56 166a6 6 0 0 1-2.07-11.63ZM208 194H56a6 6 0 0 0 0 12h152a6 6 0 0 0 0-12Z" /></Svg>;
export default GreaterThanOrEqual;