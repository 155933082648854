/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitMerge = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-merge-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-merge-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 56a24 24 0 1 1-24-24 24 24 0 0 1 24 24Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 112a32.05 32.05 0 0 0-30.69 23l-42.21-6a8 8 0 0 1-4.95-2.71L94.43 84.55A32 32 0 1 0 72 87v82a32 32 0 1 0 16 0v-67.37l30 35a24 24 0 0 0 14.83 8.14l44 6.28A32 32 0 1 0 208 112ZM64 56a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm32 144a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm112-40a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default GitMerge;