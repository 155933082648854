/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Syringe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="syringe__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-syringe'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m237.66 66.34-48-48a8 8 0 0 0-11.32 11.32L196.69 48 168 76.69l-34.34-34.35a8 8 0 0 0-11.32 11.32l6.35 6.34-84 84A15.86 15.86 0 0 0 40 155.31v49.38l-21.66 21.65a8 8 0 0 0 11.32 11.32L51.31 216h49.38a15.86 15.86 0 0 0 11.31-4.69l84-84 6.34 6.35a8 8 0 0 0 11.32-11.32L179.31 88 208 59.31l18.34 18.35a8 8 0 0 0 11.32-11.32ZM100.69 200H56v-44.69l18-18 20.34 20.35a8 8 0 0 0 11.32-11.32L85.31 126 98 113.31l20.34 20.35a8 8 0 0 0 11.32-11.32L109.31 102 140 71.31 184.69 116Z" /></Svg>;
export default Syringe;