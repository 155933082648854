/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Virus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="virus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-virus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 108a24 24 0 1 0-24 24 24 24 0 0 0 24-24Zm-24 16a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm80 4a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-48 48a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm100-52h-20.09a91.64 91.64 0 0 0-24.12-58.13l15-15a4 4 0 1 0-5.66-5.66l-15 15A91.64 91.64 0 0 0 132 36.09V16a4 4 0 0 0-8 0v20.09a91.64 91.64 0 0 0-58.13 24.12l-15-15a4 4 0 0 0-5.66 5.66l15 15A91.64 91.64 0 0 0 36.09 124H16a4 4 0 0 0 0 8h20.09a91.64 91.64 0 0 0 24.12 58.13l-15 15a4 4 0 0 0 5.66 5.66l15-15A91.64 91.64 0 0 0 124 219.91V240a4 4 0 0 0 8 0v-20.09a91.64 91.64 0 0 0 58.13-24.12l15 15a4 4 0 0 0 5.66-5.66l-15-15A91.64 91.64 0 0 0 219.91 132H240a4 4 0 0 0 0-8Zm-112 88a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84Z" /></Svg>;
export default Virus;