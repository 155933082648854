/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyMeh = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-meh__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-meh'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm48-56a8 8 0 0 1-8 8H88a8 8 0 0 1 0-16h80a8 8 0 0 1 8 8Zm-96-52a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm96 0a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default SmileyMeh;