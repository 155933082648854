/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Needle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="needle-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-needle-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212.28 43.72a40 40 0 0 0-56.56 0l-24 24a8 8 0 0 0-2.23 4.3c-8.8 51.26-93.49 136.71-95.13 138.31a8 8 0 0 0 11.31 11.32c.86-.87 86.83-86.31 138.32-95.15a8 8 0 0 0 4.3-2.23l24-24a40 40 0 0 0 0-56.56Zm-22.62 33.94-16 16a8 8 0 0 1-11.32-11.32l16-16a8 8 0 0 1 11.32 11.32Z" /></Svg>;
export default Needle;