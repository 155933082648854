/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ExclamationMark = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="exclamation-mark-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-exclamation-mark-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm-72 136a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm-8-24V80a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0Z" /></Svg>;
export default ExclamationMark;