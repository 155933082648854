/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FigmaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="figma-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-figma-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188.45 96A38 38 0 0 0 168 26H96a38 38 0 0 0-20.45 70A38 38 0 0 0 77 160.89 42 42 0 1 0 142 196v-40.32A38 38 0 1 0 188.45 96ZM194 64a26 26 0 0 1-26 26h-26V38h26a26 26 0 0 1 26 26ZM70 64a26 26 0 0 1 26-26h34v52H96a26 26 0 0 1-26-26Zm26 90a26 26 0 0 1 0-52h34v52H96Zm34 42a30 30 0 1 1-30-30h30Zm38-42a26 26 0 1 1 26-26 26 26 0 0 1-26 26Z" /></Svg>;
export default FigmaLogo;