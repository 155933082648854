/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HouseSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="house-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-house-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m217.9 110.1-80-80a14 14 0 0 0-19.8 0l-80 80A13.92 13.92 0 0 0 34 120v96a6 6 0 0 0 6 6h176a6 6 0 0 0 6-6v-96a13.92 13.92 0 0 0-4.1-9.9ZM210 210H46v-90a2 2 0 0 1 .58-1.42l80-80a2 2 0 0 1 2.84 0l80 80A2 2 0 0 1 210 120Z" /></Svg>;
export default HouseSimple;