/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SwimmingPool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="swimming-pool-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-swimming-pool-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M24 168a8 8 0 0 1 8-8c14.42 0 22.19 5.18 28.44 9.34C66 173.06 70.42 176 80 176s14-2.94 19.56-6.66c6.24-4.16 14-9.34 28.43-9.34s22.2 5.18 28.44 9.34c5.58 3.72 10 6.66 19.57 6.66s14-2.94 19.56-6.66c6.25-4.16 14-9.34 28.44-9.34a8 8 0 0 1 0 16c-9.58 0-14 2.94-19.56 6.66-6.25 4.16-14 9.34-28.44 9.34s-22.2-5.18-28.44-9.34C142 178.94 137.57 176 128 176s-14 2.94-19.56 6.66c-6.24 4.16-14 9.34-28.43 9.34s-22.19-5.18-28.44-9.34C46 178.94 41.58 176 32 176a8 8 0 0 1-8-8Zm200 32c-14.42 0-22.19 5.18-28.44 9.34C190 213.06 185.58 216 176 216s-14-2.94-19.57-6.66c-6.24-4.16-14-9.34-28.44-9.34s-22.19 5.18-28.43 9.34C94 213.06 89.57 216 80 216s-14-2.94-19.56-6.66C54.19 205.18 46.42 200 32 200a8 8 0 0 0 0 16c9.58 0 14 2.94 19.56 6.66 6.25 4.16 14 9.34 28.44 9.34s22.19-5.18 28.43-9.34c5.58-3.72 10-6.66 19.56-6.66s14 2.94 19.57 6.66c6.24 4.16 14 9.34 28.44 9.34s22.19-5.18 28.44-9.34c5.57-3.72 10-6.66 19.56-6.66a8 8 0 0 0 0-16ZM80 141.39V32a8 8 0 0 1 16 0v8h64v-8a8 8 0 0 1 16 0v111.29a8 8 0 0 1-16 0V136H96v5.39a8 8 0 0 1-16 0ZM96 72a8 8 0 0 0 8 8h48a8 8 0 0 0 0-16h-48a8 8 0 0 0-8 8Zm0 32a8 8 0 0 0 8 8h48a8 8 0 0 0 0-16h-48a8 8 0 0 0-8 8Z" /></Svg>;
export default SwimmingPool;