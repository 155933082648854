/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eyeglasses = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eyeglasses-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eyeglasses-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 36a12 12 0 0 0 0 24 12 12 0 0 1 12 12v50.46A47.94 47.94 0 0 0 142.75 148h-29.5A47.94 47.94 0 0 0 44 122.46V72a12 12 0 0 1 12-12 12 12 0 0 0 0-24 36 36 0 0 0-36 36v92a48 48 0 0 0 95.32 8h25.36a48 48 0 0 0 95.32-8V72a36 36 0 0 0-36-36ZM68 188a24 24 0 1 1 24-24 24 24 0 0 1-24 24Zm120 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24Z" /></Svg>;
export default Eyeglasses;