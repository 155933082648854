/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MapPinSimpleArea = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="map-pin-simple-area-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-map-pin-simple-area-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M122 101.52V176a6 6 0 0 0 12 0v-74.48a38 38 0 1 0-12 0ZM128 38a26 26 0 1 1-26 26 26 26 0 0 1 26-26Zm110 138c0 13.34-12.18 25.38-34.31 33.88C183.38 217.7 156.5 222 128 222s-55.38-4.3-75.69-12.12C30.18 201.38 18 189.34 18 176c0-19.6 26.46-36 69.05-42.86A6 6 0 0 1 89 145c-18 2.89-33.27 7.66-44.09 13.78C35.28 164.2 30 170.32 30 176c0 16.08 40.25 34 98 34s98-17.92 98-34c0-5.68-5.28-11.8-14.86-17.23-10.82-6.12-26.07-10.89-44.09-13.78a6 6 0 0 1 1.9-11.85C211.54 140 238 156.4 238 176Z" /></Svg>;
export default MapPinSimpleArea;