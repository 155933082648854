/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LadderSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ladder-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ladder-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 26a6 6 0 0 0-6 6v34H70V32a6 6 0 0 0-12 0v192a6 6 0 0 0 12 0v-34h116v34a6 6 0 0 0 12 0V32a6 6 0 0 0-6-6Zm-6 52v44H70V78ZM70 178v-44h116v44Z" /></Svg>;
export default LadderSimple;