/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandGrabbing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-grabbing-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-grabbing-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 104v48a88 88 0 0 1-176 0v-16a16 16 0 0 1 32 0v8a8 8 0 0 0 16 0V88a16 16 0 0 1 32 0v16a8 8 0 0 0 16 0V88a16 16 0 0 1 32 0v16a8 8 0 0 0 16 0 16 16 0 0 1 32 0Z" /></Svg>;
export default HandGrabbing;