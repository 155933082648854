/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BowlSteam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bowl-steam-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bowl-steam-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 114H32a6 6 0 0 0-6 6 102.35 102.35 0 0 0 56 91.06V216a14 14 0 0 0 14 14h64a14 14 0 0 0 14-14v-4.94A102.35 102.35 0 0 0 230 120a6 6 0 0 0-6-6Zm-58.5 87.84a6 6 0 0 0-3.5 5.45V216a2 2 0 0 1-2 2H96a2 2 0 0 1-2-2v-8.71a6 6 0 0 0-3.5-5.45A90.35 90.35 0 0 1 38.2 126h179.6a90.35 90.35 0 0 1-52.3 75.84ZM83.32 56.24c5.05-6.28 7.41-11.73 6.48-15-.72-2.53-3.64-3.57-3.67-3.58A6 6 0 0 1 88 26a5.81 5.81 0 0 1 1.78.27c.91.28 8.9 3 11.48 11.44 2.26 7.39-.63 16.15-8.58 26.05-5.05 6.28-7.41 11.73-6.48 15 .72 2.53 3.64 3.57 3.67 3.58A6 6 0 0 1 88 94a5.81 5.81 0 0 1-1.78-.27c-.91-.28-8.9-3-11.48-11.44-2.26-7.39.63-16.15 8.58-26.05Zm40 0c5.05-6.28 7.41-11.73 6.48-15-.72-2.53-3.64-3.57-3.67-3.58A6 6 0 0 1 128 26a5.81 5.81 0 0 1 1.78.27c.91.28 8.9 3 11.48 11.44 2.26 7.39-.63 16.15-8.58 26.05-5.05 6.28-7.41 11.73-6.48 15 .72 2.53 3.64 3.57 3.67 3.58A6 6 0 0 1 128 94a5.81 5.81 0 0 1-1.78-.27c-.91-.28-8.9-3-11.48-11.44-2.26-7.39.63-16.15 8.58-26.05Zm40 0c5.05-6.28 7.41-11.73 6.48-15-.72-2.53-3.64-3.57-3.67-3.58A6 6 0 0 1 168 26a5.81 5.81 0 0 1 1.78.27c.91.28 8.9 3 11.48 11.44 2.26 7.39-.63 16.15-8.58 26.05-5.05 6.28-7.41 11.73-6.48 15 .72 2.53 3.64 3.57 3.67 3.58A6 6 0 0 1 168 94a5.81 5.81 0 0 1-1.78-.27c-.91-.28-8.9-3-11.48-11.44-2.26-7.39.63-16.15 8.58-26.05Z" /></Svg>;
export default BowlSteam;