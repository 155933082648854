/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotesMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-minus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-minus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 48a12 12 0 0 1-12 12h-48a12 12 0 0 1 0-24h48a12 12 0 0 1 12 12Zm-16 40v76a40 40 0 1 1-24-36.65V88a12 12 0 0 1 24 0Zm-24 76a16 16 0 1 0-16 16 16 16 0 0 0 16-16ZM92 113.37V196a40 40 0 1 1-24-36.65V56a12 12 0 0 1 9.09-11.64l56-14a12 12 0 0 1 5.82 23.28L92 65.37v23.26l65.09-16.27a12 12 0 0 1 5.82 23.28ZM68 196a16 16 0 1 0-16 16 16 16 0 0 0 16-16Z" /></Svg>;
export default MusicNotesMinus;