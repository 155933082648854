/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Butterfly = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="butterfly__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-butterfly'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.7 50.48C229 45.7 221.84 40 209 40c-16.85 0-38.46 11.28-57.81 30.16A140.07 140.07 0 0 0 136 87.53V56a8 8 0 0 0-16 0v31.53a140.07 140.07 0 0 0-15.15-17.37C85.49 51.28 63.88 40 47 40c-12.84 0-20 5.7-23.7 10.48-6.82 8.77-12.18 24.08-.21 71.2 6.05 23.83 19.51 33 30.63 36.42A44 44 0 0 0 128 205.27a44 44 0 0 0 74.28-47.17c11.12-3.4 24.57-12.59 30.63-36.42 6.72-26.44 11.94-55.58-.21-71.2ZM92 208a28.12 28.12 0 0 1-3.14-56 8 8 0 1 0-1.76-15.9 43.64 43.64 0 0 0-20.74 7.9c-8.43.09-22-3.57-27.76-26.26-2.88-11.35-11.6-45.88-2.66-57.44C37.37 58.46 40.09 56 47 56c27.27 0 73 44.88 73 71.67V180a28 28 0 0 1-28 28Zm125.4-90.26c-5.77 22.69-19.33 26.34-27.77 26.26a43.6 43.6 0 0 0-20.74-7.95 8 8 0 1 0-1.76 15.9A28.11 28.11 0 1 1 136 180v-52.33C136 100.88 181.69 56 209 56c6.95 0 9.66 2.46 11.1 4.3 8.95 11.56.18 46.09-2.7 57.44Z" /></Svg>;
export default Butterfly;