/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudArrowUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-arrow-up__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-arrow-up'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M178.34 165.66 160 147.31V208a8 8 0 0 1-16 0v-60.69l-18.34 18.35a8 8 0 0 1-11.32-11.32l32-32a8 8 0 0 1 11.32 0l32 32a8 8 0 0 1-11.32 11.32ZM160 40a88.08 88.08 0 0 0-78.71 48.68A64 64 0 1 0 72 216h40a8 8 0 0 0 0-16H72a48 48 0 0 1 0-96c1.1 0 2.2 0 3.29.12A88 88 0 0 0 72 128a8 8 0 0 0 16 0 72 72 0 1 1 100.8 66 8 8 0 0 0 3.2 15.34 7.9 7.9 0 0 0 3.2-.68A88 88 0 0 0 160 40Z" /></Svg>;
export default CloudArrowUp;