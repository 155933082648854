/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FaceMask = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="face-mask-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-face-mask-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 88h72a12 12 0 0 1 0 24H92a12 12 0 0 1 0-24Zm72 40H92a12 12 0 0 0 0 24h72a12 12 0 0 0 0-24Zm92-24v24a36 36 0 0 1-36 36h-6.15c-7.22 15.48-20.72 28.87-40.07 39.52a169.11 169.11 0 0 1-43.43 16.25 12.12 12.12 0 0 1-4.7 0 169.11 169.11 0 0 1-43.43-16.25c-19.35-10.65-32.85-24-40.07-39.52H36a36 36 0 0 1-36-36v-24a36 36 0 0 1 36-36h2a19.93 19.93 0 0 1 10.55-9.77l72-28.8a19.89 19.89 0 0 1 14.86 0l72 28.8A19.93 19.93 0 0 1 218 68h2a36 36 0 0 1 36 36ZM36.13 140c-.08-1.32-.13-2.65-.13-4V92a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12ZM196 79.51l-68-27.2-68 27.2V136c0 40.63 55.72 56.65 68 59.69 12.25-3 68-19.05 68-59.69ZM232 104a12 12 0 0 0-12-12v44c0 1.35-.05 2.68-.13 4h.13a12 12 0 0 0 12-12Z" /></Svg>;
export default FaceMask;