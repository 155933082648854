/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FinnTheHuman = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="finn-the-human-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-finn-the-human-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 106H88a38 38 0 0 0-38 38v8a38 38 0 0 0 38 38h80a38 38 0 0 0 38-38v-8a38 38 0 0 0-38-38Zm26 46a26 26 0 0 1-26 26H88a26 26 0 0 1-26-26v-8a26 26 0 0 1 26-26h80a26 26 0 0 1 26 26Zm14-110a30.05 30.05 0 0 0-29.4 24H77.4A30 30 0 0 0 18 72v80a70.08 70.08 0 0 0 70 70h80a70.08 70.08 0 0 0 70-70V72a30 30 0 0 0-30-30Zm18 110a58.07 58.07 0 0 1-58 58H88a58.07 58.07 0 0 1-58-58V72a18 18 0 0 1 36 0 6 6 0 0 0 6 6h112a6 6 0 0 0 6-6 18 18 0 0 1 36 0Zm-124-4a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm72 0a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default FinnTheHuman;