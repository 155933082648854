/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CellSignalMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cell-signal-medium-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cell-signal-medium-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 112v88a12 12 0 0 1-24 0v-88a12 12 0 0 1 24 0Zm-52 28a12 12 0 0 0-12 12v48a12 12 0 0 0 24 0v-48a12 12 0 0 0-12-12Zm-40 40a12 12 0 0 0-12 12v8a12 12 0 0 0 24 0v-8a12 12 0 0 0-12-12Z" /></Svg>;
export default CellSignalMedium;