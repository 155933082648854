/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CowboyHat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cowboy-hat-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cowboy-hat-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 116a12 12 0 0 0-10.18 5.64q-2.48 4-5 7.57l-14.57-76.88A20 20 0 0 0 150.1 40.4l-.18.15L128 58.8l-21.92-18.25-.18-.15a20 20 0 0 0-32.15 11.9l-14.61 76.92q-2.49-3.61-5-7.57A12 12 0 0 0 44 116a44 44 0 1 0 0 88h168a44 44 0 1 0 0-88ZM96.07 63.44l19.25 16 .19.15a19.89 19.89 0 0 0 25 0l.19-.15 19.25-16L171.44 124H84.56ZM44 180a20 20 0 0 1-5.94-39.1c11.7 17.38 23.7 30 35 39.1Zm35-26.75 1-5.25h96l1 5.25c-25.33 25-46.71 26.65-49 26.75-2.29-.1-23.67-1.71-49-26.75ZM212 180h-29c11.28-9.12 23.28-21.72 35-39.1a20 20 0 0 1-6 39.1Z" /></Svg>;
export default CowboyHat;