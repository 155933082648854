/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BoxingGlove = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boxing-glove-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boxing-glove-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M168 18h-48a54.06 54.06 0 0 0-54 54v2H56a30 30 0 0 0-30 30v29.19a14 14 0 0 0 3.07 8.75 5.66 5.66 0 0 0 .47.52l36.46 36V216a14 14 0 0 0 14 14h112a14 14 0 0 0 14-14v-39.16l15.46-54.11a13.93 13.93 0 0 0 .54-3.85V72a54.06 54.06 0 0 0-54-54Zm42 100.88a2 2 0 0 1-.08.55l-15.69 54.92A6.14 6.14 0 0 0 194 176v40a2 2 0 0 1-2 2H80a2 2 0 0 1-2-2v-40a6 6 0 0 0-1.78-4.27L38.3 134.25a2 2 0 0 1-.3-1.06V104a18 18 0 0 1 18-18h10v18a6 6 0 0 0 12 0V72a42 42 0 0 1 42-42h48a42 42 0 0 1 42 42Zm-39.32 46.49L149.42 176l21.26 10.63a6 6 0 0 1-5.36 10.74L136 182.71l-29.32 14.66a6 6 0 0 1-5.36-10.74L122.58 176l-21.26-10.63a6 6 0 0 1 5.36-10.74L136 169.29l29.32-14.66a6 6 0 1 1 5.36 10.74Z" /></Svg>;
export default BoxingGlove;