/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seat-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seat-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 232a6 6 0 0 1-6 6H112a6 6 0 1 1 0-12h104a6 6 0 0 1 6 6Zm0-72v32a14 14 0 0 1-14 14h-93.89a13.94 13.94 0 0 1-12.53-7.74l-58.11-116a14.06 14.06 0 0 1 0-12.52l22.12-44a14 14 0 0 1 18.58-6.35l33.74 14.24.34.17a14 14 0 0 1 6.27 18.78l-.09.16-14.25 26.42a2 2 0 0 0 0 1.73l31.78 64a2 2 0 0 0 1.78 1.09H208A14 14 0 0 1 222 160Zm-12 0a2 2 0 0 0-2-2h-64.23a13.94 13.94 0 0 1-12.52-7.74l-31.78-64a14 14 0 0 1 0-12.5l.09-.17 14.25-26.42a2 2 0 0 0 .08-1.47 2 2 0 0 0-.89-1.1L79.35 30.37l-.35-.16a2 2 0 0 0-.89-.21 2 2 0 0 0-1.79 1.1l-22.12 44a2 2 0 0 0 0 1.78l58.12 116a2 2 0 0 0 1.79 1.11H208a2 2 0 0 0 2-2Z" /></Svg>;
export default Seat;