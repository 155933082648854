/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileRs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-rs-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-rs-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m210.83 85.17-56-56A4 4 0 0 0 152 28H56a12 12 0 0 0-12 12v72a4 4 0 0 0 8 0V40a4 4 0 0 1 4-4h92v52a4 4 0 0 0 4 4h52v124a4 4 0 0 1-4 4h-16a4 4 0 0 0 0 8h16a12 12 0 0 0 12-12V88a4 4 0 0 0-1.17-2.83ZM156 41.65 198.34 84H156ZM88 172a24 24 0 0 0-24-24H48a4 4 0 0 0-4 4v56a4 4 0 0 0 8 0v-12h12a23.85 23.85 0 0 0 7.64-1.25l8.9 15.25a4 4 0 1 0 6.92-4l-8.77-15A24 24 0 0 0 88 172Zm-24 16H52v-32h12a16 16 0 0 1 0 32Zm87.84 7.8a17 17 0 0 1-7.43 12.41c-4.39 2.91-10 3.77-15.22 3.77a57.89 57.89 0 0 1-14.19-1.87 4 4 0 0 1 2.15-7.7c4.22 1.17 16.56 3.29 22.83-.88a8.94 8.94 0 0 0 3.91-6.75c.83-6.45-4.38-8.69-15.64-11.94-9.68-2.8-21.72-6.28-20.14-18.77a16.66 16.66 0 0 1 7.22-12.13c4.56-3.07 11-4.36 19.1-3.82a61.33 61.33 0 0 1 10.48 1.61 4 4 0 0 1-2.05 7.74c-4.29-1.13-16.81-3.12-23.06 1.11a8.51 8.51 0 0 0-3.75 6.49c-.66 5.17 3.89 7 14.42 10.08 9.79 2.85 23.17 6.69 21.37 20.65Z" /></Svg>;
export default FileRs;