/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreeEvergreen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tree-evergreen-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tree-evergreen-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.74 188.32 180.27 126H208a6 6 0 0 0 4.76-9.66l-80-104a6 6 0 0 0-9.52 0l-80 104A6 6 0 0 0 48 126h27.73l-48.47 62.32A6 6 0 0 0 32 198h90v42a6 6 0 0 0 12 0v-42h90a6 6 0 0 0 4.74-9.68ZM44.27 186l48.47-62.32A6 6 0 0 0 88 114H60.19L128 25.84 195.81 114H168a6 6 0 0 0-4.74 9.68L211.73 186Z" /></Svg>;
export default TreeEvergreen;