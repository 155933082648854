/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrophoneSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 39.93a12 12 0 1 0-17.76 16.14L76 96.64V128a52 52 0 0 0 72.11 48l11.26 12.39A67.34 67.34 0 0 1 128 196a68.07 68.07 0 0 1-68-68 12 12 0 0 0-24 0 92.14 92.14 0 0 0 80 91.22V240a12 12 0 0 0 24 0v-20.77a90.39 90.39 0 0 0 35.92-12.68l23.2 25.52a12 12 0 0 0 17.76-16.14ZM128 156a28 28 0 0 1-28-28v-5l29.9 32.89c-.63.11-1.26.11-1.9.11Zm63-2.42a67.63 67.63 0 0 0 5-25.58 12 12 0 0 1 24 0 91.48 91.48 0 0 1-6.74 34.61 12 12 0 0 1-22.23-9ZM85.7 33.75A52 52 0 0 1 180 64v56.54a12 12 0 0 1-24 0V64a28 28 0 0 0-50.79-16.28 12 12 0 0 1-19.51-14Z" /></Svg>;
export default MicrophoneSlash;