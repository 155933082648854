/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrosoftOutlookLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microsoft-outlook-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microsoft-outlook-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 144a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm144-32v96a16 16 0 0 1-16 16H88a16 16 0 0 1-16-16v-16H40a16 16 0 0 1-16-16V80a16 16 0 0 1 16-16h56V40a8 8 0 0 1 8-8h96a8 8 0 0 1 8 8v64h16a8 8 0 0 1 8 8ZM112 64h24a16 16 0 0 1 16 16v74.13l40-28.89V48h-80Zm-24 96a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm111.26 48L152 173.87V176a16 16 0 0 1-16 16H88v16ZM216 127.65 165.66 164 216 200.35Z" /></Svg>;
export default MicrosoftOutlookLogo;