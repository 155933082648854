/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlowArrow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flow-arrow-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flow-arrow-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m244.24 75.76-32-32a6 6 0 0 0-8.48 8.48L225.51 74H208c-48 0-59.44 27.46-69.54 51.69-9.43 22.64-17.66 42.33-53 44.16a38 38 0 1 0 .06 12c43.34-2.06 54.29-28.29 64-51.55C159.44 106.53 168 86 208 86h17.51l-21.75 21.76a6 6 0 1 0 8.48 8.48l32-32a6 6 0 0 0 0-8.48ZM48 202a26 26 0 1 1 26-26 26 26 0 0 1-26 26Z" /></Svg>;
export default FlowArrow;