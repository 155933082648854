/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpotifyLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spotify-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spotify-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm31.07 145.74a8 8 0 0 1-10.81 3.33 42.79 42.79 0 0 0-40.52 0 8 8 0 0 1-7.48-14.14 59.33 59.33 0 0 1 55.48 0 8 8 0 0 1 3.33 10.81Zm16-28a8 8 0 0 1-10.82 3.3 77.07 77.07 0 0 0-72.48 0 8 8 0 0 1-7.52-14.12 93 93 0 0 1 87.52 0 8 8 0 0 1 3.29 10.84Zm16-28a8 8 0 0 1-10.83 3.29 110.62 110.62 0 0 0-104.46 0 8 8 0 0 1-7.54-14.12 126.67 126.67 0 0 1 119.54 0 8 8 0 0 1 3.28 10.85Z" /></Svg>;
export default SpotifyLogo;