/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VideoCamera = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="video-camera-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-video-camera-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 72v112a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16V72a16 16 0 0 1 16-16h144a16 16 0 0 1 16 16Zm58 .25a8.23 8.23 0 0 0-6.63 1.22l-33.59 22.39a4 4 0 0 0-1.78 3.33v57.62a4 4 0 0 0 1.78 3.33l33.78 22.52a8 8 0 0 0 8.58.19 8.33 8.33 0 0 0 3.86-7.17V80a8 8 0 0 0-6-7.75Z" /></Svg>;
export default VideoCamera;