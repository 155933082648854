/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThreadsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="threads-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-threads-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188.84 120.46a68.14 68.14 0 0 0-10-6.23c-3.72-21.68-16.41-37.41-35.52-43.2-21.38-6.48-46.03 1.39-57.32 18.31a12 12 0 0 0 20 13.32c5.47-8.2 19.11-12.08 30.41-8.66a24.72 24.72 0 0 1 14.88 12.24 86.73 86.73 0 0 0-8.86-.45C108.56 105.79 84 125.22 84 152c0 22.9 17.54 39.52 41.71 39.52a52 52 0 0 0 37.23-16c6-6.23 12.88-16.46 15.72-32.07 6.2 6.42 9.34 14.67 9.34 24.59 0 17.74-19.07 44-60 44-45.76 0-68-27.48-68-84s22.24-84 68-84c31.08 0 51 12.42 60.8 38a12 12 0 0 0 22.4-8.62C197.77 38.44 169 20 128 20c-59.33 0-92 38.35-92 108s32.67 108 92 108c31.36 0 51.08-12.05 62.11-22.15C203.81 201.28 212 184.14 212 168c0-19.64-8-36.08-23.16-47.54Zm-43.2 38.39a27.9 27.9 0 0 1-19.93 8.67c-8.17 0-17.71-4.06-17.71-15.52 0-15.26 17.84-22.21 34.41-22.21a60.23 60.23 0 0 1 13.51 1.52c-.56 11.62-4.08 21.1-10.28 27.54Z" /></Svg>;
export default ThreadsLogo;