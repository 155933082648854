/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ComputerTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="computer-tower-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-computer-tower-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M84 76a12 12 0 0 1 12-12h64a12 12 0 0 1 0 24H96a12 12 0 0 1-12-12Zm12 52h64a12 12 0 0 0 0-24H96a12 12 0 0 0 0 24Zm116-88v176a20 20 0 0 1-20 20H64a20 20 0 0 1-20-20V40a20 20 0 0 1 20-20h128a20 20 0 0 1 20 20Zm-24 4H68v168h120Zm-60 124a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default ComputerTower;