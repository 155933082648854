/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GraphicsCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="graphics-card-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-graphics-card-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 52H16a4 4 0 0 0-4 4v152a4 4 0 0 0 8 0v-20h24v20a4 4 0 0 0 8 0v-20h24v20a4 4 0 0 0 8 0v-20h24v20a4 4 0 0 0 8 0v-20h116a12 12 0 0 0 12-12V64a12 12 0 0 0-12-12Zm4 124a4 4 0 0 1-4 4H20V60h212a4 4 0 0 1 4 4Zm-60-92a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm-28 36a27.89 27.89 0 0 1 5.58-16.76l39.18 39.18A28 28 0 0 1 148 120Zm50.42 16.76-39.18-39.18a28 28 0 0 1 39.18 39.18ZM80 84a36 36 0 1 0 36 36 36 36 0 0 0-36-36Zm-28 36a27.89 27.89 0 0 1 5.58-16.76l39.18 39.18A28 28 0 0 1 52 120Zm50.42 16.76L63.24 97.58a28 28 0 0 1 39.18 39.18Z" /></Svg>;
export default GraphicsCard;