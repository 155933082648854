/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleHike = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-hike-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-hike-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 48a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm72 88c-23.37 0-28.92-8.56-36.6-20.4-3.65-5.64-7.79-12-14.16-17.55a40.92 40.92 0 0 0-8-5.47 8 8 0 0 0-11 3.92L64.66 228.81a8 8 0 0 0 4.15 10.52A7.84 7.84 0 0 0 72 240a8 8 0 0 0 7.34-4.81l33.59-77.27 31.07 22.2V232a8 8 0 0 0 16 0v-56a8 8 0 0 0-3.35-6.51l-37.2-26.57 13.4-30.81c3.57 3.62 6.28 7.8 9.13 12.19 7.67 11.84 16.27 25.11 42 27.36V232a8 8 0 0 0 16 0v-88a8 8 0 0 0-7.98-8ZM72 152a8 8 0 0 0 7.36-4.85l24-56a8 8 0 0 0-4.2-10.5l-28-12a8 8 0 0 0-10.5 4.2l-24 56a8 8 0 0 0 4.2 10.5l28 12A8 8 0 0 0 72 152Z" /></Svg>;
export default PersonSimpleHike;