/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FigmaLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="figma-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-figma-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M198.15 96A44 44 0 0 0 168 20H96a44 44 0 0 0-30.15 76 43.9 43.9 0 0 0 1.23 65.12A48 48 0 1 0 148 196v-28.83A44 44 0 0 0 198.15 96ZM188 64a20 20 0 0 1-20 20h-20V44h20a20 20 0 0 1 20 20ZM76 64a20 20 0 0 1 20-20h28v40H96a20 20 0 0 1-20-20Zm20 84a20 20 0 0 1 0-40h28v40H96Zm28 48a24 24 0 1 1-24-24h24Zm44-48a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default FigmaLogo;