/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChalkboardTeacher = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chalkboard-teacher-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chalkboard-teacher-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 80v96h-88a32 32 0 1 0-32-32h-8V80Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h13.39a8 8 0 0 0 7.23-4.57 48 48 0 0 1 86.76 0 8 8 0 0 0 7.23 4.57H216a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM80 144a24 24 0 1 1 24 24 24 24 0 0 1-24-24Zm136 56h-56.57a64.39 64.39 0 0 0-28.83-26.16 40 40 0 1 0-53.2 0A64.39 64.39 0 0 0 48.57 200H40V56h176ZM56 96V80a8 8 0 0 1 8-8h128a8 8 0 0 1 8 8v96a8 8 0 0 1-8 8h-16a8 8 0 0 1 0-16h8V88H72v8a8 8 0 0 1-16 0Z" /></Svg>;
export default ChalkboardTeacher;