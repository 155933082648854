/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FourK = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="four-k-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-four-k-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28 48a4 4 0 0 1 4-4h192a4 4 0 0 1 0 8H32a4 4 0 0 1-4-4Zm196 156H32a4 4 0 0 0 0 8h192a4 4 0 0 0 0-8ZM144 76a4 4 0 0 0-4 4v96a4 4 0 0 0 8 0v-30.5l17.75-20.28L196.54 178a4 4 0 1 0 6.92-4l-32.16-55.13L203 82.63a4 4 0 1 0-6-5.26l-49 56V80a4 4 0 0 0-4-4ZM92 176v-20H40a4 4 0 0 1-3.16-6.46l56-72A4 4 0 0 1 100 80v68h12a4 4 0 0 1 0 8h-12v20a4 4 0 0 1-8 0Zm0-28V91.66L48.18 148Z" /></Svg>;
export default FourK;