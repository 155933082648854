/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileVue = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-vue-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-vue-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m213.66 82.34-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v76a4 4 0 0 0 4 4h168a4 4 0 0 0 4-4V88a8 8 0 0 0-2.34-5.66ZM152 88V44l44 44Zm-64.64 67-19.89 55.69a8 8 0 0 1-15.08 0L32.5 155a8.21 8.21 0 0 1 4.5-10.45 8 8 0 0 1 10.46 4.76l12.47 34.9 12.47-34.9a8 8 0 0 1 10.46-4.76 8.22 8.22 0 0 1 4.5 10.45Zm96.64 5v12h15.73a8.19 8.19 0 0 1 8.26 7.47 8 8 0 0 1-8 8.53H184v12h23.73a8.18 8.18 0 0 1 8.26 7.47 8 8 0 0 1-8 8.53H176a8 8 0 0 1-8-8v-56a8 8 0 0 1 8-8h31.74a8.18 8.18 0 0 1 8.26 7.47 8 8 0 0 1-8 8.53Zm-32-8v37.45c0 14.14-11.07 26.12-25.22 26.54A26 26 0 0 1 100 190v-37.73a8.18 8.18 0 0 1 7.47-8.25 8 8 0 0 1 8.54 8v37.65a10.23 10.23 0 0 0 9.26 10.33A10 10 0 0 0 136 190v-37.73a8.18 8.18 0 0 1 7.47-8.25A8 8 0 0 1 152 152Z" /></Svg>;
export default FileVue;