/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatCenteredDots = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-centered-dots-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-centered-dots-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 120a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-52-8a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm88 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm56-56v128a12 12 0 0 1-12 12h-62.75l-14.83 26a12 12 0 0 1-20.84 0l-14.83-26H40a12 12 0 0 1-12-12V56a12 12 0 0 1 12-12h176a12 12 0 0 1 12 12Zm-8 0a4 4 0 0 0-4-4H40a4 4 0 0 0-4 4v128a4 4 0 0 0 4 4h65.07a4 4 0 0 1 3.47 2l16 28a4 4 0 0 0 6.94 0l16-28a4 4 0 0 1 3.47-2H216a4 4 0 0 0 4-4Z" /></Svg>;
export default ChatCenteredDots;