/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popcorn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popcorn-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popcorn-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.52 74.21a8 8 0 0 0-7.13-2A44 44 0 0 0 168 41.67a44 44 0 0 0-80 0 44 44 0 0 0-54.4 30.51 8 8 0 0 0-9.4 9.65l30.56 129.84A16 16 0 0 0 70.34 224h115.32a16 16 0 0 0 15.58-12.33l30.55-129.84a8 8 0 0 0-2.27-7.62ZM70.34 208 42.91 91.44l37.85 10.81L94.86 208Zm51.72-134.24-34.49 13.8L49 76.54a28 28 0 0 1 40.1-17.28 8 8 0 0 0 11.56-5.34 28 28 0 0 1 54.66 0 8 8 0 0 0 11.56 5.34A28 28 0 0 1 207 76.54l-38.56 11-34.49-13.8a16 16 0 0 0-11.89.02ZM185.66 208h-24.52l14.1-105.75 37.85-10.81Z" /></Svg>;
export default Popcorn;