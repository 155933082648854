/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CableCar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cable-car-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cable-car-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M247.87 30.59a8 8 0 0 0-9.28-6.46l-224 40A8 8 0 0 0 16 80a8.6 8.6 0 0 0 1.42-.12L120 61.56V96H64a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h128a32 32 0 0 0 32-32v-64a32 32 0 0 0-32-32h-56V58.7l105.4-18.82a8 8 0 0 0 6.47-9.29ZM104 160v-48h48v48Zm-40-48h24v48H48v-32a16 16 0 0 1 16-16Zm144 16v32h-40v-48h24a16 16 0 0 1 16 16Z" /></Svg>;
export default CableCar;