/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotMemberOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-member-of-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-member-of-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-18 37.27L145.63 120H176a8 8 0 0 1 0 16h-44.37l-28.76 32.87A47.72 47.72 0 0 0 128 176h48a8 8 0 0 1 0 16h-48a63.62 63.62 0 0 1-35.78-11L78 197.27a8 8 0 0 1-12-10.54l14.21-16.24A64 64 0 0 1 128 64h45.37l4.63-5.27a8 8 0 1 1 12 10.54ZM128 80h31.37l-35 40H80.68A48.07 48.07 0 0 1 128 80Zm-47.32 56h29.69l-19.53 22.32A47.78 47.78 0 0 1 80.68 136Z" /></Svg>;
export default NotMemberOf;