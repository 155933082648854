/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GrainsSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="grains-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-grains-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M51 37.31a4 4 0 0 0-6 5.38l16.81 18.44A84.83 84.83 0 0 0 48 60a4 4 0 0 0-4 4v80a84.09 84.09 0 0 0 84 84 83.82 83.82 0 0 0 61.33-26.6L205 218.69a4 4 0 1 0 5.92-5.38Zm20.39 34.37 70.06 77.06A84.23 84.23 0 0 0 128 174.41a84.17 84.17 0 0 0-76-58.31v-48a75.86 75.86 0 0 1 19.39 3.58ZM124 219.9A76.11 76.11 0 0 1 52 144v-19.9a76.11 76.11 0 0 1 72 75.9Zm8 0V200a76 76 0 0 1 14.94-45.22l37 40.68A75.86 75.86 0 0 1 132 219.9Zm80-75.9a84.32 84.32 0 0 1-3 22.34 4 4 0 0 1-3.86 2.94 3.86 3.86 0 0 1-1.06-.14 4 4 0 0 1-2.8-4.92A76 76 0 0 0 204 144v-19.9a74.9 74.9 0 0 0-30.37 8.1 4 4 0 1 1-3.62-7.14 82.94 82.94 0 0 1 34-9v-48a76.32 76.32 0 0 0-57.59 31.35 4 4 0 1 1-6.48-4.69 84.14 84.14 0 0 1 27-24C156.88 46.53 134.48 32.28 128 28.56a103.64 103.64 0 0 0-26.45 22 4 4 0 0 1-6.11-5.17 108.09 108.09 0 0 1 30.77-25 4 4 0 0 1 3.58 0c1.32.66 31.31 16 44.33 46.75A83.91 83.91 0 0 1 208 60a4 4 0 0 1 4 4Z" /></Svg>;
export default GrainsSlash;