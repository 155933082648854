/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TennisBall = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tennis-ball-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tennis-ball-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M24.81 115.41a103.9 103.9 0 0 1 90.6-90.65 4 4 0 0 1 4.47 3.79 87.82 87.82 0 0 1-91.27 91.33 4 4 0 0 1-3.8-4.47Zm202.54 20.7c-1.12 0-2.23-.07-3.35-.07a87.84 87.84 0 0 0-87.88 91.41 4 4 0 0 0 4.47 3.79 103.9 103.9 0 0 0 90.6-90.66 4 4 0 0 0-3.84-4.47Zm-76.89 14.35A103.33 103.33 0 0 1 224 120h3.09a4 4 0 0 0 4.12-4.43 103.91 103.91 0 0 0-90.88-90.89 4 4 0 0 0-4.43 4.12 103.72 103.72 0 0 1-30.36 76.7A103.33 103.33 0 0 1 32 136h-3.09a4 4 0 0 0-4.12 4.43 103.91 103.91 0 0 0 90.88 90.89 4 4 0 0 0 4.43-4.12 103.72 103.72 0 0 1 30.36-76.74Z" /></Svg>;
export default TennisBall;