/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popcorn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popcorn__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popcorn'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.52 74.21a8 8 0 0 0-7.13-2A44 44 0 0 0 168 41.67a44 44 0 0 0-80 0 44 44 0 0 0-54.4 30.51 8 8 0 0 0-9.4 9.65l30.56 129.84A16 16 0 0 0 70.34 224h115.32a16 16 0 0 0 15.58-12.33l30.55-129.84a8 8 0 0 0-2.27-7.62ZM76 56a27.68 27.68 0 0 1 13.11 3.26 8 8 0 0 0 11.56-5.34 28 28 0 0 1 54.66 0 8 8 0 0 0 11.56 5.34A28 28 0 0 1 207 76.54l-38.56 11-34.49-13.8a16 16 0 0 0-11.88 0l-34.5 13.82L49 76.54A28 28 0 0 1 76 56Zm-5.66 152L42.91 91.44l37.85 10.81L94.86 208Zm74.66 0h-34L96.75 101.12 128 88.62l31.25 12.5Zm40.66 0h-24.52l14.1-105.75 37.85-10.81Z" /></Svg>;
export default Popcorn;