/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pizza = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pizza-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pizza-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.66 63.94a11.94 11.94 0 0 0-5.44-7.43 197.51 197.51 0 0 0-204.44 0 12 12 0 0 0-4 16.47l96 157.27a12 12 0 0 0 20.54 0L234.25 73a11.9 11.9 0 0 0 1.41-9.06ZM58.59 118a28 28 0 1 1 27.66 45.3Zm94 73.41a28 28 0 0 1 29.15-47.76Zm33.33-54.61A35.66 35.66 0 0 0 168 132a36 36 0 0 0-19.56 66.22l-17 27.86a4 4 0 0 1-6.88 0l-34-55.67a36 36 0 1 0-36.33-59.54L43.33 93a156 156 0 0 1 169.34 0Zm41.48-68-10.55 17.34a164.07 164.07 0 0 0-177.7 0L28.58 68.81a3.9 3.9 0 0 1-.46-3 4 4 0 0 1 1.82-2.48 189.47 189.47 0 0 1 196.12 0 4 4 0 0 1 1.82 2.48 3.9 3.9 0 0 1-.46 3Z" /></Svg>;
export default Pizza;