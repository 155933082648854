/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VideoConference = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="video-conference-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-video-conference-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 42H40a14 14 0 0 0-14 14v144a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14Zm2 14v66h-52V54h50a2 2 0 0 1 2 2ZM38 200V56a2 2 0 0 1 2-2h114v148H40a2 2 0 0 1-2-2Zm178 2h-50v-68h52v66a2 2 0 0 1-2 2ZM182 88a10 10 0 1 1 10 10 10 10 0 0 1-10-10Zm20 80a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-68.19-1.49A38 38 0 0 0 115.23 143a30 30 0 1 0-38.45 0 38 38 0 0 0-18.59 23.5 6 6 0 0 0 11.62 3C72.67 158.38 83.93 150 96 150s23.34 8.38 26.19 19.49a6 6 0 0 0 11.62-3ZM78 120a18 18 0 1 1 18 18 18 18 0 0 1-18-18Z" /></Svg>;
export default VideoConference;