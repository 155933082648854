/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Axe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="axe-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-axe-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M253.25 98.36a14 14 0 0 0-11.48-9.44 138.65 138.65 0 0 1-54.6-19.6l12.1-12.11a23 23 0 1 0-32.48-32.48L152 39.47c-3.21-3.73-5.19-6.46-5.92-7.5a14 14 0 0 0-21.35-1.88L86.09 68.77A14 14 0 0 0 88 90.12c1 .73 3.77 2.71 7.5 5.92L8.73 182.79A23 23 0 0 0 25 222a22.79 22.79 0 0 0 16.24-6.73l84.11-84.1a138.65 138.65 0 0 1 19.6 54.6 14 14 0 0 0 9.44 11.48 13.86 13.86 0 0 0 14.35-3.36l81.18-81.18a13.87 13.87 0 0 0 3.33-14.35Zm-78-65.15a11 11 0 0 1 15.52 15.52L177 62.49a153.67 153.67 0 0 1-16.76-14.26ZM32.73 206.79a11 11 0 0 1-15.52-15.52l87-87A153.67 153.67 0 0 1 118.49 121Zm208.68-102.57-81.19 81.19a1.87 1.87 0 0 1-2 .48 2 2 0 0 1-1.38-1.71C148.17 119.4 100.3 84.11 94.87 80.3a2 2 0 0 1-.87-1.48 1.94 1.94 0 0 1 .57-1.57l38.67-38.67a2 2 0 0 1 1.39-.58h.18a2 2 0 0 1 1.48.86c3.81 5.43 39.1 53.3 103.88 62a2 2 0 0 1 1.71 1.38 1.85 1.85 0 0 1-.47 1.98Z" /></Svg>;
export default Axe;