/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GithubLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="github-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-github-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 104v8a56.06 56.06 0 0 1-48.44 55.47A39.8 39.8 0 0 1 176 192v40a8 8 0 0 1-8 8h-64a8 8 0 0 1-8-8v-16H72a40 40 0 0 1-40-40 24 24 0 0 0-24-24 8 8 0 0 1 0-16 40 40 0 0 1 40 40 24 24 0 0 0 24 24h24v-8a39.8 39.8 0 0 1 8.44-24.53A56.06 56.06 0 0 1 56 112v-8a58.14 58.14 0 0 1 7.69-28.32A59.78 59.78 0 0 1 69.07 28 8 8 0 0 1 76 24a59.75 59.75 0 0 1 48 24h24a59.75 59.75 0 0 1 48-24 8 8 0 0 1 6.93 4 59.74 59.74 0 0 1 5.37 47.68A58 58 0 0 1 216 104Z" /></Svg>;
export default GithubLogo;