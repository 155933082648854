/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScanSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scan-smiley-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scan-smiley-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 40v36a4 4 0 0 1-8 0V44h-32a4 4 0 0 1 0-8h36a4 4 0 0 1 4 4Zm-4 136a4 4 0 0 0-4 4v32h-32a4 4 0 0 0 0 8h36a4 4 0 0 0 4-4v-36a4 4 0 0 0-4-4ZM76 212H44v-32a4 4 0 0 0-8 0v36a4 4 0 0 0 4 4h36a4 4 0 0 0 0-8ZM40 80a4 4 0 0 0 4-4V44h32a4 4 0 0 0 0-8H40a4 4 0 0 0-4 4v36a4 4 0 0 0 4 4Zm88 116a68 68 0 1 1 68-68 68.07 68.07 0 0 1-68 68Zm60-68a60 60 0 1 0-60 60 60.07 60.07 0 0 0 60-60Zm-76-12a8 8 0 1 0-8 8 8 8 0 0 0 8-8Zm40-8a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm-2.65 41c-4.52 4-13.7 7-21.35 7s-16.83-3-21.35-7a4 4 0 0 0-5.3 6c6 5.3 17 9 26.65 9s20.64-3.7 26.65-9a4 4 0 1 0-5.3-6Z" /></Svg>;
export default ScanSmiley;