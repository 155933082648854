/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreasureChest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="treasure-chest-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-treasure-chest-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 112v80a8 8 0 0 1-8 8H32a8 8 0 0 1-8-8v-80h88v32h32v-32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M184 40H72a56.06 56.06 0 0 0-56 56v96a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V96a56.06 56.06 0 0 0-56-56Zm40 56v8h-32V56.8A40.07 40.07 0 0 1 224 96Zm-88 40h-16v-32h16Zm-24 16h32a8 8 0 0 0 8-8v-24h24v72H80v-72h24v24a8 8 0 0 0 8 8Zm40-48v-8a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v8H80V56h96v48ZM64 56.8V104H32v-8a40.07 40.07 0 0 1 32-39.2ZM32 120h32v72H32Zm192 72h-32v-72h32v72Z" /></Svg>;
export default TreasureChest;