/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Van = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="van-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-van-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m253.11 104.18-45.57-53.09A19.94 19.94 0 0 0 192.26 44H32a20 20 0 0 0-20 20v112a20 20 0 0 0 20 20h14.06a36 36 0 0 0 67.88 0h40.12a36 36 0 0 0 67.88 0H236a20 20 0 0 0 20-20v-64a12.05 12.05 0 0 0-2.89-7.82ZM217.89 100H176V68h14.42ZM104 100V68h48v32ZM80 68v32H36V68Zm0 128a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm108 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm33.94-24a36 36 0 0 0-67.88 0h-40.12a36 36 0 0 0-67.88 0H36v-48h196v48Z" /></Svg>;
export default Van;