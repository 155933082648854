/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingBag = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-bag-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-bag-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 36H40a20 20 0 0 0-20 20v144a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20Zm-4 160H44V60h168ZM76 88a12 12 0 0 1 24 0 28 28 0 0 0 56 0 12 12 0 0 1 24 0 52 52 0 0 1-104 0Z" /></Svg>;
export default ShoppingBag;