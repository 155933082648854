/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vignette = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vignette-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vignette-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm-16 88c0 30.93-32.24 56-72 56s-72-25.07-72-56 32.24-56 72-56 72 25.07 72 56Z" /></Svg>;
export default Vignette;