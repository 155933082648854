/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UsersThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="users-three-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-users-three-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M164.38 181.1a52 52 0 1 0-72.76 0 75.89 75.89 0 0 0-30 28.89 12 12 0 0 0 20.78 12 53 53 0 0 1 91.22 0 12 12 0 1 0 20.78-12 75.89 75.89 0 0 0-30.02-28.89ZM100 144a28 28 0 1 1 28 28 28 28 0 0 1-28-28Zm147.21 9.59a12 12 0 0 1-16.81-2.39c-8.33-11.09-19.85-19.59-29.33-21.64a12 12 0 0 1-1.82-22.91 20 20 0 1 0-24.78-28.3 12 12 0 1 1-21-11.6 44 44 0 1 1 73.28 48.35 92.18 92.18 0 0 1 22.85 21.69 12 12 0 0 1-2.39 16.8Zm-192.28-24c-9.48 2.05-21 10.55-29.33 21.65a12 12 0 0 1-19.19-14.45 92.37 92.37 0 0 1 22.85-21.69 44 44 0 1 1 73.28-48.35 12 12 0 1 1-21 11.6 20 20 0 1 0-24.78 28.3 12 12 0 0 1-1.82 22.91Z" /></Svg>;
export default UsersThree;