/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingCart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-cart-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-cart-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.61 60.16A6 6 0 0 0 224 58H61l-6.37-35.07a6 6 0 0 0-5.9-4.93H24a6 6 0 0 0 0 12h19.72l25.81 141.94a21.93 21.93 0 0 0 6.24 11.77 26 26 0 1 0 38.12 6.29h52.22A26 26 0 1 0 188 178H91.17a10 10 0 0 1-9.84-8.21L77.73 150H196.1a22 22 0 0 0 21.65-18.06l12.15-66.87a6 6 0 0 0-1.29-4.91ZM106 204a14 14 0 1 1-14-14 14 14 0 0 1 14 14Zm96 0a14 14 0 1 1-14-14 14 14 0 0 1 14 14Zm3.94-74.21a10 10 0 0 1-9.84 8.21H75.55L63.19 70h153.62Z" /></Svg>;
export default ShoppingCart;