/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-dashed__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-dashed'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M96.26 37.05a8 8 0 0 1 5.74-9.76 104.11 104.11 0 0 1 52 0 8 8 0 0 1-2 15.75 8.15 8.15 0 0 1-2-.26 88.09 88.09 0 0 0-44 0 8 8 0 0 1-9.74-5.73ZM53.79 55.14a104.05 104.05 0 0 0-26 45 8 8 0 0 0 15.42 4.27 88 88 0 0 1 22-38.09 8 8 0 0 0-11.42-11.18Zm-10.58 96.41a8 8 0 1 0-15.42 4.28 104.12 104.12 0 0 0 26 45 8 8 0 0 0 11.41-11.22 88.14 88.14 0 0 1-21.99-38.06ZM150 213.22a88 88 0 0 1-44 0 8 8 0 1 0-4 15.49 104.11 104.11 0 0 0 52 0 8 8 0 0 0-4-15.49ZM222.65 146a8 8 0 0 0-9.85 5.58 87.91 87.91 0 0 1-22 38.08 8 8 0 1 0 11.42 11.21 104 104 0 0 0 26-45 8 8 0 0 0-5.57-9.87Zm-9.86-41.54a8 8 0 0 0 15.42-4.28 104 104 0 0 0-26-45A8 8 0 1 0 190.8 66.4a88 88 0 0 1 21.99 38.05Z" /></Svg>;
export default CircleDashed;