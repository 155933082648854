/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cursor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cursor-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cursor-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M165.17 135.51a4 4 0 0 1 1.17-6.46l46.31-17.79.16-.06a12 12 0 0 0-1.05-22.33L51.67 36.6A12 12 0 0 0 36.6 51.67l52.27 160.09a11.86 11.86 0 0 0 10.8 8.24h.58a11.86 11.86 0 0 0 11-7.19l.06-.16 17.79-46.31a4 4 0 0 1 6.47-1.17l51.31 51.32a12 12 0 0 0 17 0l12.69-12.69a12 12 0 0 0 0-17Zm45.66 62.63-12.69 12.69a4 4 0 0 1-5.66 0l-51.31-51.31a12 12 0 0 0-8.48-3.52 12.13 12.13 0 0 0-2.24.21 12 12 0 0 0-8.77 7l-.06.16-17.79 46.31a4 4 0 0 1-7.36-.42L44.2 49.19a4 4 0 0 1 5-5l160.07 52.28a4 4 0 0 1 .42 7.36l-46.31 17.78-.16.07a12 12 0 0 0-3.71 19.49l51.32 51.31a4 4 0 0 1 0 5.66Z" /></Svg>;
export default Cursor;