/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextSuperscript = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-superscript-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-superscript-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-81.45 147.41a8 8 0 1 1-13.1 9.18L92 158l-21.45 30.59a8 8 0 1 1-13.1-9.18L82.23 144l-24.78-35.41a8 8 0 1 1 13.1-9.18L92 130.05l21.45-30.64a8 8 0 0 1 13.1 9.18L101.77 144ZM192 160h-40a8 8 0 0 1-6.4-12.8l36-48a12 12 0 1 0-19.15-14.46 13.06 13.06 0 0 0-2.58 4.81 8 8 0 1 1-15.68-3.18 28.17 28.17 0 1 1 50.2 22.44L168 144h24a8 8 0 0 1 0 16Z" /></Svg>;
export default TextSuperscript;