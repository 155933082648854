/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Guitar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="guitar__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-guitar'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m249.66 46.34-40-40a8 8 0 0 0-11.31 11.32l2.34 2.34-60.17 60.16c-22.79-11.86-48.31-10.87-63.77 4.58a42.27 42.27 0 0 0-9.39 14.37 8.24 8.24 0 0 1-7.55 4.89c-14.59.49-27.26 5.72-36.65 15.11C11.08 131.22 6 148.6 8.74 168.07 11.4 186.7 21.07 205.15 36 220s33.34 24.56 52 27.22a71.13 71.13 0 0 0 10.1.78c15.32 0 28.83-5.23 38.76-15.16 9.39-9.39 14.62-22.06 15.11-36.65a8.24 8.24 0 0 1 4.92-7.55 42.12 42.12 0 0 0 14.37-9.39c15.45-15.46 16.44-41 4.58-63.77L236 55.31l2.34 2.34a8 8 0 1 0 11.32-11.31ZM160 167.93a26.12 26.12 0 0 1-8.95 5.83 24.24 24.24 0 0 0-15 21.89c-.36 10.46-4 19.41-10.43 25.88-8.44 8.43-21 11.95-35.36 9.89-15.26-2.17-30.53-10.23-42.99-22.69S26.75 181 24.58 165.81c-2-14.37 1.46-26.92 9.89-35.36C40.94 124 49.89 120.37 60.35 120a24.22 24.22 0 0 0 21.89-15 26.12 26.12 0 0 1 5.83-9c5.49-5.49 13-8.13 21.38-8.13a49.38 49.38 0 0 1 19.13 4.19l-20.08 20.13a32 32 0 1 0 35.31 35.31l20.08-20.08c6.52 15.29 5.58 30.99-3.89 40.51Zm-10.4-61.48a72.9 72.9 0 0 1 5.93 6.75l-15.42 15.42a32.22 32.22 0 0 0-12.68-12.68l15.42-15.43a73 73 0 0 1 6.7 5.94ZM112 128a16 16 0 0 1 16 16 16 16 0 1 1-16-16Zm48.85-32.85a86.94 86.94 0 0 0-6.68-6L176 67.31 188.69 80l-21.83 21.82a86.94 86.94 0 0 0-6-6.68ZM200 68.68 187.32 56 212 31.31 224.69 44ZM93.66 194.33a8 8 0 0 1-11.31 11.32l-32-32a8 8 0 0 1 11.32-11.31Z" /></Svg>;
export default Guitar;