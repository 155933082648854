/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingCartSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-cart-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-cart-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.55 64.74A12 12 0 0 0 232 60H60.23l-8.67-31.21A12 12 0 0 0 40 20H20a12 12 0 0 0 0 24h10.88l34.3 123.49a28.09 28.09 0 0 0 27 20.51H191a28.09 28.09 0 0 0 27-20.51l25.63-92.28a12 12 0 0 0-2.08-10.47Zm-46.75 96.33A4 4 0 0 1 191 164H92.16a4 4 0 0 1-3.85-2.93L66.9 84h149.31ZM108 220a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm104 0a20 20 0 1 1-20-20 20 20 0 0 1 20 20Z" /></Svg>;
export default ShoppingCartSimple;