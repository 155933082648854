/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tag = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tag-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tag-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m246.15 133.18-99.32-99.32A19.85 19.85 0 0 0 132.69 28H40a12 12 0 0 0-12 12v92.69a19.85 19.85 0 0 0 5.86 14.14l99.32 99.32a20 20 0 0 0 28.28 0l84.69-84.69a20 20 0 0 0 0-28.28Zm-98.83 93.17L52 131V52h79l95.32 95.32ZM104 88a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default Tag;