/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryFull = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-full-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-full-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 80v96a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16V80a16 16 0 0 1 16-16h168a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M200 56H32A24 24 0 0 0 8 80v96a24 24 0 0 0 24 24h168a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24Zm8 120a8 8 0 0 1-8 8H32a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8h168a8 8 0 0 1 8 8Zm-24-80v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Zm-40 0v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Zm-40 0v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Zm-40 0v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Zm192 0v64a8 8 0 0 1-16 0V96a8 8 0 0 1 16 0Z" /></Svg>;
export default BatteryFull;