/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hand = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M188 52a23.9 23.9 0 0 0-16 6.13V44a24 24 0 0 0-47.93-1.8A24 24 0 0 0 84 60v78l-11.25-18.06a24 24 0 0 0-41.62 23.92C64.44 214.12 82.17 236 128 236a84.09 84.09 0 0 0 84-84V76a24 24 0 0 0-24-24Zm16 100a76.09 76.09 0 0 1-76 76c-37.29 0-53.59-11.51-89.71-87.71l-.15-.29a16 16 0 0 1 27.71-16 .75.75 0 0 1 .07.12l18.68 30A4 4 0 0 0 92 152V60a16 16 0 0 1 32 0v60a4 4 0 0 0 8 0V44a16 16 0 0 1 32 0v76a4 4 0 0 0 8 0V76a16 16 0 0 1 32 0Z" /></Svg>;
export default Hand;