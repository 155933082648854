/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBucket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-bucket-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-bucket-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M256 208a24 24 0 0 1-48 0c0-17.91 15.57-41.77 17.34-44.44a8 8 0 0 1 13.32 0C240.43 166.23 256 190.09 256 208Zm-123.51-83.51a12 12 0 0 0-17-17 12 12 0 0 0 17 16.94ZM37.65 18.34a8 8 0 0 0-11.31 11.32l32.6 32.6L70.25 51Zm196.88 120.73a8 8 0 0 0 3.13-13.24L122.17 10.34a8 8 0 0 0-11.31 0L70.25 51l40.43 40.42a28 28 0 1 1-11.31 11.32L58.94 62.26 15 106.17a24 24 0 0 0 0 33.94L99.89 225a24 24 0 0 0 33.94 0l78.49-78.49Z" /></Svg>;
export default PaintBucket;