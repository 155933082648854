/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Knife = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="knife__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-knife'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.87 32.13a27.84 27.84 0 0 0-39.32 0L18.34 206.4a8 8 0 0 0 3.86 13.45A160.67 160.67 0 0 0 58.4 224c32.95 0 65.92-10.2 96.95-30.23 31.76-20.5 50.19-43.82 51-44.81a8 8 0 0 0-.64-10.59L185.32 118l46.55-46.56a27.85 27.85 0 0 0 0-39.31ZM189.1 144.44a220.41 220.41 0 0 1-42.86 36.16c-34.43 22.1-69.94 30.92-105.77 26.3L146 101.33Zm31.46-84.3L174 106.7 157.32 90l46.55-46.56a11.8 11.8 0 0 1 16.69 16.69Z" /></Svg>;
export default Knife;