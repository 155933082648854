/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Headset = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="headset-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-headset-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M199.05 57.48A100.07 100.07 0 0 0 28 128v56a20 20 0 0 0 20 20h16a20 20 0 0 0 20-20v-40a20 20 0 0 0-20-20H36.08A92 92 0 0 1 128 36h.7a91.75 91.75 0 0 1 91.22 88H192a20 20 0 0 0-20 20v40a20 20 0 0 0 20 20h28v4a28 28 0 0 1-28 28h-56a4 4 0 0 0 0 8h56a36 36 0 0 0 36-36v-80a99.44 99.44 0 0 0-28.95-70.52ZM64 132a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H48a12 12 0 0 1-12-12v-52Zm116 52v-40a12 12 0 0 1 12-12h28v64h-28a12 12 0 0 1-12-12Z" /></Svg>;
export default Headset;