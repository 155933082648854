/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Trolley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trolley-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trolley-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 224a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm124-20a20 20 0 1 0 20 20 20 20 0 0 0-20-20Zm24-36H60V75.31a19.86 19.86 0 0 0-5.86-14.14L32.49 39.51a12 12 0 0 0-17 17L36 77v91h-4a12 12 0 0 0 0 24h208a12 12 0 0 0 0-24Z" /></Svg>;
export default Trolley;