/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GooglePlayLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-play-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-play-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M241.79 110.7 74 14.65a20.24 20.24 0 0 0-20.12.06A19.62 19.62 0 0 0 44 31.84v192.32a19.62 19.62 0 0 0 9.91 17.13 20.22 20.22 0 0 0 20.12.06l167.76-96a19.76 19.76 0 0 0 0-34.6ZM68 203V53l75 75Zm92-58 12.4 12.4-58 33.2Zm-45.59-79.57 58 33.2L160 111ZM194 145l-17-17 17-17 29.72 17Z" /></Svg>;
export default GooglePlayLogo;