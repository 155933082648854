/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GreaterThanOrEqual = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="greater-than-or-equal-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-greater-than-or-equal-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 104 56 160V48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M53.24 152.49 184.86 104 53.24 55.51a8 8 0 1 1 5.53-15l152 56a8 8 0 0 1 0 15l-152 56A8.13 8.13 0 0 1 56 168a8 8 0 0 1-2.76-15.51ZM208 192H56a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16Z" /></Svg>;
export default GreaterThanOrEqual;