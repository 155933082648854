/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Moped = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="moped-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-moped-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 124c-1.22 0-2.43.06-3.62.16l-33.14-88.37A12 12 0 0 0 164 28h-32a12 12 0 0 0 0 24h23.68l30.23 80.6A44.28 44.28 0 0 0 171 152h-29.2l-22.56-60.21A12 12 0 0 0 108 84H28a12 12 0 0 0 0 24h4v2.92A60.14 60.14 0 0 0 0 164a12 12 0 0 0 12 12h4.74a44 44 0 0 0 86.52 0h65.48A44 44 0 1 0 212 124Zm-164 6.05a12 12 0 0 0 8-11.32V108h43.68l16.5 44H26.06A36 36 0 0 1 48 130.05ZM60 188a20 20 0 0 1-18.32-12h36.64A20 20 0 0 1 60 188Zm152 0a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default Moped;