/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IntersectSquare = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="intersect-square-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-intersect-square-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 84h-44V40a12 12 0 0 0-12-12H40a12 12 0 0 0-12 12v120a12 12 0 0 0 12 12h44v44a12 12 0 0 0 12 12h120a12 12 0 0 0 12-12V96a12 12 0 0 0-12-12ZM52 148V52h96v32H96a12 12 0 0 0-12 12v52Zm56-23 23 23h-23Zm40 6-23-23h23Zm56 73h-96v-32h52a12 12 0 0 0 12-12v-52h32Z" /></Svg>;
export default IntersectSquare;