/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Keyhole = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="keyhole-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-keyhole-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84Zm0-144a44 44 0 0 0-33.61 72.41l-9.86 32.06A12 12 0 0 0 96 188h64a12 12 0 0 0 11.47-15.53l-9.86-32.06A44 44 0 0 0 128 68Zm8.53 72.51 7.22 23.49h-31.5l7.22-23.49a12 12 0 0 0-4-12.89 20 20 0 1 1 25 0 12 12 0 0 0-3.94 12.89Z" /></Svg>;
export default Keyhole;