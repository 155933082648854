/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NetworkSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="network-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-network-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 54V40a12 12 0 0 1 12-12h32a12 12 0 0 1 12 12v32a12 12 0 0 1-12 12h-16.39a4 4 0 0 1 0-8H144a4 4 0 0 0 4-4V40a4 4 0 0 0-4-4h-32a4 4 0 0 0-4 4v14a4 4 0 0 1-8 0Zm111 159.31a4 4 0 1 1-5.92 5.38L119 124H68v40h12a12 12 0 0 1 12 12v32a12 12 0 0 1-12 12H48a12 12 0 0 1-12-12v-32a12 12 0 0 1 12-12h12v-40H24a4 4 0 0 1 0-8h87.68L45 42.69a4 4 0 0 1 6-5.38ZM80 172H48a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4v-32a4 4 0 0 0-4-4Zm152-56h-68a4 4 0 0 0 0 8h24v26.83a4 4 0 1 0 8 0V124h36a4 4 0 0 0 0-8Z" /></Svg>;
export default NetworkSlash;