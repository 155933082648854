/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CookingPot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cooking-pot-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cooking-pot-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M253.76 93A12 12 0 0 0 237 90.24l-9 6.44V80a12 12 0 0 0-12-12H40a12 12 0 0 0-12 12v16.68l-9-6.44a12 12 0 1 0-14 19.52l23 16.42V184a36 36 0 0 0 36 36h128a36 36 0 0 0 36-36v-57.82l23-16.42A12 12 0 0 0 253.76 93ZM204 184a12 12 0 0 1-12 12H64a12 12 0 0 1-12-12V92h152ZM76 40V16a12 12 0 0 1 24 0v24a12 12 0 0 1-24 0Zm40 0V16a12 12 0 0 1 24 0v24a12 12 0 0 1-24 0Zm40 0V16a12 12 0 0 1 24 0v24a12 12 0 0 1-24 0Z" /></Svg>;
export default CookingPot;