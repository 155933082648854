/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CloudFog = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cloud-fog__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cloud-fog'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 208H72a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Zm64-16h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16Zm-24 32h-56a8 8 0 0 0 0 16h56a8 8 0 0 0 0-16Zm72-124a76.08 76.08 0 0 1-76 76H76a52 52 0 0 1 0-104 53.26 53.26 0 0 1 8.92.76A76.08 76.08 0 0 1 232 100Zm-16 0a60.06 60.06 0 0 0-120-3.54 8 8 0 0 1-16-.92q.21-3.66.77-7.23A38.11 38.11 0 0 0 76 88a36 36 0 0 0 0 72h80a60.07 60.07 0 0 0 60-60Z" /></Svg>;
export default CloudFog;