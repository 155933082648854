/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShirtFolded = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shirt-folded-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shirt-folded-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M201 40h-21.65l-13.69-13.66A8 8 0 0 0 160 24H96a8 8 0 0 0-5.66 2.34L76.65 40H55a15 15 0 0 0-15 15v154a15 15 0 0 0 15 15h61a4 4 0 0 0 4-4V104.27a8.18 8.18 0 0 1 7.47-8.27 8 8 0 0 1 8.53 8v116a4 4 0 0 0 4 4h61a15 15 0 0 0 15-15V55a15 15 0 0 0-15-15ZM86.54 107.08A4 4 0 0 1 80 104V59.31l15.24-15.24 23.47 35.21ZM128 80Zm48 24a4 4 0 0 1-2.3 3.63 3.93 3.93 0 0 1-4.21-.51l-32.2-27.82 23.47-35.21L176 59.31Z" /></Svg>;
export default ShirtFolded;