/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Racquet = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="racquet-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-racquet-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.53 27.47C201.38.33 152.16 5.37 118.81 38.72 87.22 70.31 81.05 116.13 103.56 144l-32.23 32.19-2.1-2.11a14 14 0 0 0-19.8 0L22.1 201.41a14 14 0 0 0 0 19.8l12.69 12.7a14 14 0 0 0 19.81 0l27.32-27.32a14 14 0 0 0 0-19.81l-2.1-2.1L112 152.46c11.17 9 25.24 13.45 40.13 13.45 22.2 0 46.21-9.81 65.12-28.72 33.38-33.35 38.42-82.57 11.28-109.72ZM73.43 198.09l-27.32 27.33a2 2 0 0 1-2.83 0l-12.69-12.69a2 2 0 0 1 0-2.83l27.32-27.33a2 2 0 0 1 1.42-.58 2 2 0 0 1 1.41.58l6.35 6.34 6.34 6.34a2 2 0 0 1 0 2.83ZM232.34 58h-34.28V23.66A46.33 46.33 0 0 1 232.34 58ZM183.9 22h2.15v36H150V30.57a74.84 74.84 0 0 1 33.9-8.65Zm2.15 48v36H150V70ZM127.3 47.21A93.2 93.2 0 0 1 138 38v20h-20a93.3 93.3 0 0 1 9.3-10.79ZM110.65 70H138v36h-36c-.34-11.72 2.58-24.21 8.65-36Zm-6.9 48H138v34.28A46.33 46.33 0 0 1 103.75 118Zm46.29 36v-36h36v27.38c-11.83 6.04-24.32 8.96-36.04 8.55Zm58.75-25.23a93.07 93.07 0 0 1-10.73 9.22V118H218a94.06 94.06 0 0 1-9.21 10.7ZM225.44 106h-27.38V70h36c.37 11.63-2.55 24.12-8.62 36Z" /></Svg>;
export default Racquet;