/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HardHat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hard-hat-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hard-hat-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 152h-48V40a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16Zm72 16H32a16 16 0 0 0-16 16v8a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-8a16 16 0 0 0-16-16Zm0-20v-12a96.44 96.44 0 0 0-50.11-84.31 4 4 0 0 0-5.89 3.53V152h52a4 4 0 0 0 4-4Zm-188 4h52V55.22a4 4 0 0 0-5.89-3.53A96.44 96.44 0 0 0 32 136v12a4 4 0 0 0 4 4Z" /></Svg>;
export default HardHat;