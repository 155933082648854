/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiNone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-none__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-none'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 204a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default WifiNone;