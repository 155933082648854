/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Subway = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="subway-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-subway-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 96v112a4 4 0 0 1-8 0V96a60.07 60.07 0 0 0-60-60h-48a60.07 60.07 0 0 0-60 60v112a4 4 0 0 1-8 0V96a68.07 68.07 0 0 1 68-68h48a68.07 68.07 0 0 1 68 68Zm-40 0v72a20 20 0 0 1-20 20h-1.53l5.11 10.21a4 4 0 0 1-1.79 5.37 4.12 4.12 0 0 1-1.79.42 4 4 0 0 1-3.58-2.21L149.53 188h-43.06l-6.89 13.79A4 4 0 0 1 96 204a4.12 4.12 0 0 1-1.79-.42 4 4 0 0 1-1.79-5.37L97.53 188H96a20 20 0 0 1-20-20V96a20 20 0 0 1 20-20h64a20 20 0 0 1 20 20Zm-96 0v52h88V96a12 12 0 0 0-12-12H96a12 12 0 0 0-12 12Zm32 60v24h24v-24Zm-20 24h12v-24H84v12a12 12 0 0 0 12 12Zm76-12v-12h-24v24h12a12 12 0 0 0 12-12Z" /></Svg>;
export default Subway;