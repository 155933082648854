/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Beanie = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="beanie-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-beanie-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 164.7V144a92.15 92.15 0 0 0-75.57-90.53 24 24 0 1 0-32.86 0A92.15 92.15 0 0 0 36 144v20.7a12 12 0 0 0-8 11.3v32a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12v-32a12 12 0 0 0-8-11.3ZM112 36a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm16 24a84.09 84.09 0 0 1 84 84v20H44v-20a84.09 84.09 0 0 1 84-84Zm-4 112v40H76v-40Zm8 0h48v40h-48Zm-96 36v-32a4 4 0 0 1 4-4h28v40H40a4 4 0 0 1-4-4Zm184 0a4 4 0 0 1-4 4h-28v-40h28a4 4 0 0 1 4 4Z" /></Svg>;
export default Beanie;