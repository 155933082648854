/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SlackLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="slack-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-slack-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M218 128a30 30 0 1 0-36-48V56a30 30 0 0 0-54-18 30 30 0 1 0-48 36H56a30 30 0 0 0-18 54 30 30 0 1 0 36 48v24a30 30 0 0 0 54 18 30 30 0 1 0 48-36h24a30 30 0 0 0 18-54Zm-18-42a18 18 0 0 1 0 36h-18v-18a18 18 0 0 1 18-18Zm-48-48a18 18 0 0 1 18 18v48a18 18 0 0 1-18 18h-18V56a18 18 0 0 1 18-18ZM86 56a18 18 0 0 1 36 0v18h-18a18 18 0 0 1-18-18Zm-48 48a18 18 0 0 1 18-18h48a18 18 0 0 1 18 18v18H56a18 18 0 0 1-18-18Zm18 66a18 18 0 0 1 0-36h18v18a18 18 0 0 1-18 18Zm48 48a18 18 0 0 1-18-18v-48a18 18 0 0 1 18-18h18v66a18 18 0 0 1-18 18Zm66-18a18 18 0 0 1-36 0v-18h18a18 18 0 0 1 18 18Zm30-30h-48a18 18 0 0 1-18-18v-18h66a18 18 0 0 1 0 36Z" /></Svg>;
export default SlackLogo;