/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChartDonut = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chart-donut-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chart-donut-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a6 6 0 0 0-6 6v56a6 6 0 0 0 6 6 34 34 0 1 1-29.45 17 6 6 0 0 0-2.2-8.2l-48.5-28a6 6 0 0 0-8.2 2.2A102 102 0 1 0 128 26ZM47.21 88.29l38.29 22.1A46 46 0 0 0 82 128a47.64 47.64 0 0 0 .4 6l-42.7 11.45a90.27 90.27 0 0 1 7.51-57.16ZM42.81 157l42.7-11.44a46.12 46.12 0 0 0 36.49 28v44.2A90.2 90.2 0 0 1 42.81 157ZM134 217.8v-44.2a46 46 0 0 0 0-91.21V38.2a90 90 0 0 1 0 179.6Z" /></Svg>;
export default ChartDonut;