/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SkipBack = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="skip-back-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-skip-back-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 47.88v160.24a8 8 0 0 1-12.19 6.65L59.7 134.65a7.83 7.83 0 0 1 0-13.3l128.11-80.12A8 8 0 0 1 200 47.88Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M199.81 34a16 16 0 0 0-16.24.43L64 109.23V40a8 8 0 0 0-16 0v176a8 8 0 0 0 16 0v-69.23l119.57 74.78A15.95 15.95 0 0 0 208 208.12V47.88A15.86 15.86 0 0 0 199.81 34ZM192 208 64.16 128 192 48.07Z" /></Svg>;
export default SkipBack;