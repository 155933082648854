/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LineSegment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="line-segment-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-line-segment-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.23 42.77A30 30 0 0 0 167 80.54L80.54 167a30.07 30.07 0 0 0-37.77 3.81A30 30 0 1 0 89 175.46L175.46 89a30 30 0 0 0 37.77-46.25Zm-136.51 162a18 18 0 1 1 0-25.46 18 18 0 0 1 0 25.43Zm128-128a18 18 0 0 1-25.46 0 18 18 0 1 1 25.46 0Z" /></Svg>;
export default LineSegment;