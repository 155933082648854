/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FaceMask = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="face-mask-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-face-mask-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M172 104a4 4 0 0 1-4 4H88a4 4 0 0 1 0-8h80a4 4 0 0 1 4 4Zm-4 28H88a4 4 0 0 0 0 8h80a4 4 0 0 0 0-8Zm84-28v24a28 28 0 0 1-28 28h-7.84c-6.41 16-20.74 29.76-42.37 40.58a187.14 187.14 0 0 1-45.07 15.36 4.35 4.35 0 0 1-1.44 0 187.14 187.14 0 0 1-45.07-15.36C60.58 185.76 46.25 172 39.84 156H32a28 28 0 0 1-28-28v-24a28 28 0 0 1 28-28h4.06a12 12 0 0 1 7.84-10.19l80-29.09a12 12 0 0 1 8.2 0l80 29.09A12 12 0 0 1 219.94 76H224a28 28 0 0 1 28 28ZM37.35 148A53.71 53.71 0 0 1 36 136V84h-4a20 20 0 0 0-20 20v24a20 20 0 0 0 20 20ZM212 136V77.09a4 4 0 0 0-2.63-3.76l-80-29.09a4 4 0 0 0-2.74 0l-80 29.09A4 4 0 0 0 44 77.09V136c0 49.42 75.76 66.24 84 67.92 8.24-1.68 84-18.5 84-67.92Zm32-32a20 20 0 0 0-20-20h-4v52a53.71 53.71 0 0 1-1.35 12H224a20 20 0 0 0 20-20Z" /></Svg>;
export default FaceMask;