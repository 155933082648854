/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Orange = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="orange-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-orange-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M161.15 72.65A62.08 62.08 0 0 0 198 16a6 6 0 0 0-6-6h-8a62.08 62.08 0 0 0-56 35.42A62.08 62.08 0 0 0 72 10h-8a6 6 0 0 0 0 12h8a50.06 50.06 0 0 1 49.66 44.26 85.95 85.95 0 1 0 39.49 6.39ZM184 22h1.64A50.07 50.07 0 0 1 136 66h-1.64A50.07 50.07 0 0 1 184 22Zm-56 204a74 74 0 1 1 74-74 74.09 74.09 0 0 1-74 74Zm53.92-65A55.48 55.48 0 0 1 137 205.92a6.74 6.74 0 0 1-1 .08 6 6 0 0 1-1-11.92A43.29 43.29 0 0 0 170.08 159a6 6 0 1 1 11.84 2Z" /></Svg>;
export default Orange;