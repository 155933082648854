/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandFist = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-fist-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-fist-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 120v8a104 104 0 0 1-104.37 104c-54-.19-98-42.06-103.12-94.78a4 4 0 0 1 5.56-4A35.94 35.94 0 0 0 72 122.59a35.92 35.92 0 0 0 53.94 2.33 40.36 40.36 0 0 0 12.87 13A47.94 47.94 0 0 0 120 176a8 8 0 0 0 8.67 8 8.21 8.21 0 0 0 7.33-8.26A32 32 0 0 1 168 144a8 8 0 0 0 8-8.53 8.18 8.18 0 0 0-8.25-7.47H160a24 24 0 0 1-24-24V88h64a32 32 0 0 1 32 32Zm-187.27 0C55.57 119.6 64 110.37 64 99.52v-23C64 65.63 55.57 56.4 44.73 56A20 20 0 0 0 24 76v24a20 20 0 0 0 20.73 20Zm56 0c10.84-.39 19.27-9.62 19.27-20.47v-47c0-10.85-8.43-20.08-19.27-20.47A20 20 0 0 0 80 52v48a20 20 0 0 0 20.73 20ZM176 52a20 20 0 0 0-20.73-20c-10.84.4-19.27 9.63-19.27 20.48V72h36a4 4 0 0 0 4-4Z" /></Svg>;
export default HandFist;