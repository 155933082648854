/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmSlate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-slate-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-slate-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 100h-83.08l78.14-20.63a12 12 0 0 0 8.52-14.75l-8.16-30a19.94 19.94 0 0 0-24.3-13.95L34.79 60.87a19.74 19.74 0 0 0-12.14 9.27 19.48 19.48 0 0 0-1.95 15L28 112v88a20 20 0 0 0 20 20h160a20 20 0 0 0 20-20v-88a12 12 0 0 0-12-12Zm-91.11-38.09 18.29 10.56-25.95 6.85-18.29-10.56Zm64.45-17 3.91 14.35-17.13 4.52-18.29-10.57ZM66 77.46 84.29 88l-35.4 9.35L45 83ZM204 196H52v-72h152Z" /></Svg>;
export default FilmSlate;