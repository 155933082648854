/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Keyboard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="keyboard-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-keyboard-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 48H32a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16ZM64 168H48a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm96 0H96a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Zm48 0h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-32H48a8 8 0 0 1 0-16h160a8 8 0 0 1 0 16Zm0-32H48a8 8 0 0 1 0-16h160a8 8 0 0 1 0 16Z" /></Svg>;
export default Keyboard;