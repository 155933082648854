/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Books = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="books-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-books-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 36H56a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h48a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12ZM52 76h56v104H52Zm4-32h48a4 4 0 0 1 4 4v20H52V48a4 4 0 0 1 4-4Zm48 168H56a4 4 0 0 1-4-4v-20h56v20a4 4 0 0 1-4 4Zm123.74-16.62L194.55 37.57a12 12 0 0 0-14.25-9.3l-46.81 10.05a12.1 12.1 0 0 0-9.23 14.3l33.19 157.81a12 12 0 0 0 14.25 9.3l46.81-10.06a12.08 12.08 0 0 0 9.23-14.29Zm-83.21-85.27 54.63-11.73 15 71.07-54.63 11.74Zm-6.64-31.56 54.64-11.74 5 23.74-54.64 11.73Zm-2.71-32.4L182 36.09a4 4 0 0 1 .84-.09 3.94 3.94 0 0 1 2.14.64 4 4 0 0 1 1.76 2.58L190.88 59l-54.64 11.72L132.09 51a4.07 4.07 0 0 1 3.09-4.85Zm81.65 155.7L170 211.91a4 4 0 0 1-3-.55 4 4 0 0 1-1.76-2.58L161.12 189l54.64-11.73 4.15 19.73a4.07 4.07 0 0 1-3.08 4.85Z" /></Svg>;
export default Books;