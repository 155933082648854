/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StarAndCrescent = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-and-crescent-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-and-crescent-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M154.73 201.06a82 82 0 0 1 0-146.12 6 6 0 0 0 0-10.69A93 93 0 0 0 112 34a94 94 0 0 0 0 188 93 93 0 0 0 42.73-10.25 6 6 0 0 0 0-10.69ZM112 210a82 82 0 1 1 26.81-159.53 94 94 0 0 0 0 155.06A81.4 81.4 0 0 1 112 210Zm138.38-87.51-27.61-11.91-2.4-31a6 6 0 0 0-10.61-3.36l-19.39 23.45-28.93-7.16a6 6 0 0 0-6.61 8.87L170.5 128l-15.67 26.62a6 6 0 0 0 6.61 8.87l28.93-7.16 19.39 23.49a6 6 0 0 0 10.61-3.36l2.4-31 27.61-11.91a6 6 0 0 0 0-11Zm-35.69 13.35a6 6 0 0 0-3.61 5l-1.53 19.83-12.33-14.94a6 6 0 0 0-4.63-2.18 5.92 5.92 0 0 0-1.44.18l-18.74 4.63L182.64 131a6 6 0 0 0 0-6.08l-10.23-17.37 18.74 4.63a6 6 0 0 0 6.07-2l12.33-14.94 1.53 19.83a6 6 0 0 0 3.61 5l18.17 7.93Z" /></Svg>;
export default StarAndCrescent;