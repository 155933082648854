/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSeven = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-seven-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-seven-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m173.75 49.72-48 160a6 6 0 1 1-11.5-3.45L159.94 54H88a6 6 0 0 1 0-12h80a6 6 0 0 1 5.75 7.72Z" /></Svg>;
export default NumberSeven;