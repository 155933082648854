/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileIni = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-ini-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-ini-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M44 152v56a4 4 0 0 1-8 0v-56a4 4 0 0 1 8 0Zm76-4a4 4 0 0 0-4 4v43.52l-32.75-45.84A4 4 0 0 0 76 152v56a4 4 0 0 0 8 0v-43.52l32.75 45.84A4 4 0 0 0 124 208v-56a4 4 0 0 0-4-4Zm40 0a4 4 0 0 0-4 4v56a4 4 0 0 0 8 0v-56a4 4 0 0 0-4-4Zm52-60v136a4 4 0 0 1-8 0V92h-52a4 4 0 0 1-4-4V36H56a4 4 0 0 0-4 4v72a4 4 0 0 1-8 0V40a12 12 0 0 1 12-12h96a4 4 0 0 1 2.83 1.17l56 56A4 4 0 0 1 212 88Zm-56-4h42.34L156 41.65Z" /></Svg>;
export default FileIni;