/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Fan = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fan-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fan-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.85 134a64 64 0 0 0-87.43-42.11l14.22-56.77a12 12 0 0 0-5.17-13A64 64 0 0 0 86 127.52l-56.28 16.07a12 12 0 0 0-8.69 11 64 64 0 0 0 127.56 10l42 40.7a12 12 0 0 0 13.86 2 64 64 0 0 0 32.4-73.29ZM116 128a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm8-92a40 40 0 0 1 14 2.53l-13.4 53.64a36 36 0 0 0-25.85 14.88A40 40 0 0 1 124 36Zm-19 156.1A40 40 0 0 1 45.5 164l53.18-15.19a36 36 0 0 0 25.8 15A39.84 39.84 0 0 1 105 192.1Zm104.7-21.56a39.92 39.92 0 0 1-9.21 10.89L160.73 143a35.9 35.9 0 0 0 .05-29.83 40 40 0 0 1 48.89 57.4Z" /></Svg>;
export default Fan;