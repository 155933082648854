/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lasso = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lasso-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lasso-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204.68 61.63C184.11 49 156.88 42 128 42s-56.11 7-76.68 19.63C29.83 74.85 18 92.73 18 112s11.83 37.13 33.32 50.35C70.45 174.1 95.35 181 122 181.85v1.94c0 14.76-6.94 27.1-19.54 34.73-15.22 9.22-36 9.9-51.68 1.71a6 6 0 1 0-5.56 10.63A64.45 64.45 0 0 0 75 238a65.24 65.24 0 0 0 33.7-9.22c16.34-9.89 25.33-25.87 25.33-45v-1.94c26.65-.89 51.57-7.74 70.72-19.52C226.17 149.11 238 131.23 238 112s-11.83-37.15-33.32-50.37Zm-140 94.46c5.32-12 17.2-15.07 26.48-13.87 12.28 1.6 24.64 10.67 29.08 27.55-20.51-.96-39.76-5.69-55.52-13.68Zm133.67-4c-17.62 10.85-40.83 17.1-65.77 17.79a52.77 52.77 0 0 0-11.31-23.84 45.24 45.24 0 0 0-28.56-15.73c-16.89-2.2-31.49 5.54-38.41 19.68C38.61 139.37 30 126 30 112c0-14.93 9.8-29.18 27.61-40.13C76.32 60.34 101.32 54 128 54s51.68 6.34 70.39 17.85C216.2 82.8 226 97.05 226 112s-9.8 29.16-27.61 40.11Z" /></Svg>;
export default Lasso;