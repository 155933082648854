/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SmileyMelting = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-melting-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-melting-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174 140a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-48-48a10 10 0 1 0-10 10 10 10 0 0 0 10-10Zm73.62-36.63A102 102 0 0 0 52 196a6 6 0 1 0 8.94-8 90.09 90.09 0 0 1 65.78-150H128a90 90 0 0 1 67.07 150 6 6 0 0 0 8.95 8 102 102 0 0 0-4.41-140.63ZM152 170h-16c-22.65 0-50-18.73-50-42a43.15 43.15 0 0 1 .58-7 6 6 0 1 0-11.83-2 54 54 0 0 0-.75 9c0 14.26 7.2 28 20.27 38.6 12 9.79 27.26 15.4 41.73 15.4h16a10 10 0 0 1 0 20H96a22 22 0 0 0 0 44 6 6 0 0 0 0-12 10 10 0 0 1 0-20h56a22 22 0 0 0 0-44Z" /></Svg>;
export default SmileyMelting;