/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Smiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="smiley-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-smiley-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M178.39 158c-11 19.06-29.39 30-50.39 30s-39.36-10.93-50.39-30a12 12 0 0 1 20.78-12c3.89 6.73 12.91 18 29.61 18s25.72-11.28 29.61-18a12 12 0 1 1 20.78 12ZM236 128A108 108 0 1 1 128 20a108.12 108.12 0 0 1 108 108Zm-24 0a84 84 0 1 0-84 84 84.09 84.09 0 0 0 84-84Zm-120-4a16 16 0 1 0-16-16 16 16 0 0 0 16 16Zm72-32a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default Smiley;