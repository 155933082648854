/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ScanSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="scan-smiley-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-scan-smiley-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 40v36a8 8 0 0 1-16 0V48h-28a8 8 0 0 1 0-16h36a8 8 0 0 1 8 8Zm-8 132a8 8 0 0 0-8 8v28h-28a8 8 0 0 0 0 16h36a8 8 0 0 0 8-8v-36a8 8 0 0 0-8-8ZM76 208H48v-28a8 8 0 0 0-16 0v36a8 8 0 0 0 8 8h36a8 8 0 0 0 0-16ZM40 84a8 8 0 0 0 8-8V48h28a8 8 0 0 0 0-16H40a8 8 0 0 0-8 8v36a8 8 0 0 0 8 8Zm88 116a72 72 0 1 1 72-72 72.08 72.08 0 0 1-72 72Zm-24-72a12 12 0 1 0-12-12 12 12 0 0 0 12 12Zm54 18.71a8 8 0 0 0-11.29-.71c-3.81 3.37-12 6-18.71 6s-14.9-2.63-18.71-6a8 8 0 1 0-10.58 12c7.83 6.91 20.35 10 29.29 10s21.46-3.09 29.29-10a8 8 0 0 0 .71-11.29Zm6-30.71a12 12 0 1 0-12 12 12 12 0 0 0 12-12Z" /></Svg>;
export default ScanSmiley;