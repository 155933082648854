/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radical-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radical-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm0 80a8 8 0 0 1-16 0v-8h-66.58l-30 75a8 8 0 0 1-14.86 0l-32-80a8 8 0 1 1 14.87-6L88 154.46 112.57 93a8 8 0 0 1 7.43-5h80a8 8 0 0 1 8 8Z" /></Svg>;
export default Radical;