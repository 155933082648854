/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-slash__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-slash'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M53.92 34.62A8 8 0 0 0 40.26 42 16 16 0 0 0 32 56v56c0 52.72 25.52 84.67 46.93 102.19 23.06 18.86 46 25.27 47 25.53a8 8 0 0 0 4.2 0c1.36-.37 31.27-8.78 57.09-34.72l14.89 16.38a8 8 0 1 0 11.84-10.76Zm74.07 189a128.48 128.48 0 0 1-38.92-21.81C61.82 179.51 48 149.3 48 112V56h3.71l124.7 137.15A129.26 129.26 0 0 1 128 223.62ZM224 56v56c0 20.58-3.89 39.61-11.56 56.59a8 8 0 1 1-14.58-6.59c6.73-14.89 10.14-31.71 10.14-50V56H98.52a8 8 0 1 1 0-16H208a16 16 0 0 1 16 16Z" /></Svg>;
export default ShieldSlash;