/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChefHat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chef-hat-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chef-hat-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 112a52.06 52.06 0 0 0-52-52 53 53 0 0 0-7.77.59 52 52 0 0 0-96.46 0A53 53 0 0 0 72 60a52 52 0 0 0-20 100v48a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-48a52.07 52.07 0 0 0 32-48Zm-40 96a4 4 0 0 1-4 4H64a4 4 0 0 1-4-4v-45.41A52.13 52.13 0 0 0 72 164h112a52.13 52.13 0 0 0 12-1.41Zm-12-52h-18.88l6.76-27a4 4 0 0 0-7.76-1.94l-7.24 29H132V128a4 4 0 0 0-8 0v28H99.12l-7.24-29a4 4 0 1 0-7.76 2l6.76 27H72a44 44 0 0 1 0-88 45.3 45.3 0 0 1 5.34.33A51.68 51.68 0 0 0 76 80a4 4 0 0 0 8 0 44 44 0 0 1 88 0 4 4 0 0 0 8 0 51.68 51.68 0 0 0-1.34-11.67A45.3 45.3 0 0 1 184 68a44 44 0 0 1 0 88Z" /></Svg>;
export default ChefHat;