/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Baseball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="baseball-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-baseball-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20ZM77 194.66A108.9 108.9 0 0 0 85.54 182a12 12 0 1 0-20.77-12 83.28 83.28 0 0 1-4.77 7.27 83.73 83.73 0 0 1 0-98.54A83.28 83.28 0 0 1 64.77 86a12 12 0 1 0 20.77-12A108.9 108.9 0 0 0 77 61.34a83.76 83.76 0 0 1 102.06 0 108.9 108.9 0 0 0-8.6 12.66 12 12 0 1 0 20.77 12 83.28 83.28 0 0 1 4.77-7.27 83.73 83.73 0 0 1 0 98.54 83.28 83.28 0 0 1-4.74-7.28 12 12 0 1 0-20.77 12 108.9 108.9 0 0 0 8.51 12.67 83.76 83.76 0 0 1-102.06 0Zm22.2-80.14A110 110 0 0 1 100 128a110 110 0 0 1-.83 13.48A12 12 0 0 1 87.27 152a12.83 12.83 0 0 1-1.5-.09 12 12 0 0 1-10.42-13.39A85.75 85.75 0 0 0 76 128a85.75 85.75 0 0 0-.65-10.52 12 12 0 1 1 23.82-3Zm71.06 37.39a12.83 12.83 0 0 1-1.5.09 12 12 0 0 1-11.9-10.52 109.88 109.88 0 0 1 0-27 12 12 0 1 1 23.82 3 85.46 85.46 0 0 0 0 21 12 12 0 0 1-10.45 13.43Z" /></Svg>;
export default Baseball;