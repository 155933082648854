/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderFemale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-female-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-female-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 96a84 84 0 1 0-96 83.13V196H88a12 12 0 0 0 0 24h28v20a12 12 0 0 0 24 0v-20h28a12 12 0 0 0 0-24h-28v-16.87A84.12 84.12 0 0 0 212 96ZM68 96a60 60 0 1 1 60 60 60.07 60.07 0 0 1-60-60Z" /></Svg>;
export default GenderFemale;