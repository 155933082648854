/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SolarRoof = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="solar-roof-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-solar-roof-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m245.37 125.32-40-80A6 6 0 0 0 200 42H56a6 6 0 0 0-5.37 3.32l-40 80A6.07 6.07 0 0 0 10 128v56a14 14 0 0 0 14 14h208a14 14 0 0 0 14-14v-56a6.07 6.07 0 0 0-.63-2.68ZM99.71 122l-14-28h34.58l14 28Zm.58-68 14 28H79.71l-14-28Zm48 0 14 28h-34.58l-14-28Zm-.58 68-14-28h34.58l14 28Zm48 0-14-28h34.58l14 28Zm14.58-40h-34.58l-14-28h34.58ZM22 184v-54.58l34-68 34 68V186H24a2 2 0 0 1-2-2Zm210 2H102v-52h132v50a2 2 0 0 1-2 2Z" /></Svg>;
export default SolarRoof;