/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CassetteTape = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cassette-tape-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cassette-tape-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 50H32a14 14 0 0 0-14 14v128a14 14 0 0 0 14 14h192a14 14 0 0 0 14-14V64a14 14 0 0 0-14-14ZM76 194l15-20h74l15 20Zm150-2a2 2 0 0 1-2 2h-29l-22.2-29.6a6 6 0 0 0-4.8-2.4H88a6 6 0 0 0-4.8 2.4L61 194H32a2 2 0 0 1-2-2V64a2 2 0 0 1 2-2h192a2 2 0 0 1 2 2ZM176 82H80a30 30 0 0 0 0 60h96a30 30 0 0 0 0-60Zm-24 12a29.92 29.92 0 0 0 0 36h-48a29.92 29.92 0 0 0 0-36Zm-90 18a18 18 0 1 1 18 18 18 18 0 0 1-18-18Zm114 18a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default CassetteTape;