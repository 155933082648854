/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FireTruck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fire-truck-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fire-truck-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 120v64a8 8 0 0 1-8 8h-24a24 24 0 0 0-32-22.63A24 24 0 0 0 168 192h-64a24 24 0 0 0-48 0H32a8 8 0 0 1-8-8V72a8 8 0 0 1 8-8h152v56Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m255.43 117-14-35a15.93 15.93 0 0 0-14.85-10H192v-8a8 8 0 0 0-16 0v100.31A32.11 32.11 0 0 0 161 184h-50a32 32 0 0 0-62 0H32v-48a8 8 0 0 0-16 0v48a16 16 0 0 0 16 16h17a32 32 0 0 0 62 0h50a32 32 0 0 0 62 0h17a16 16 0 0 0 16-16v-64a7.92 7.92 0 0 0-.57-3Zm-28.85-29 9.6 24H192V88ZM80 208a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm112 0a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm31-24a32.06 32.06 0 0 0-31-24v-32h48v56ZM24 96a8 8 0 0 0 0 16h128a8 8 0 0 0 0-16h-16V72h16a8 8 0 0 0 0-16H24a8 8 0 0 0 0 16h16v24Zm96 0H96V72h24ZM56 72h24v24H56Z" /></Svg>;
export default FireTruck;