/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MathOperations = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="math-operations-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-math-operations-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-61.66 45.66a8 8 0 0 1 11.32-11.32L168 76.69l10.34-10.35a8 8 0 0 1 11.32 11.32L179.31 88l10.35 10.34a8 8 0 0 1-11.32 11.32L168 99.31l-10.34 10.35a8 8 0 0 1-11.32-11.32L156.69 88ZM112 176H96v16a8 8 0 0 1-16 0v-16H64a8 8 0 0 1 0-16h16v-16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 0 16Zm0-80H64a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Zm80 96h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Zm0-32h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Z" /></Svg>;
export default MathOperations;