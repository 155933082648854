/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Asclepius = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="asclepius-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-asclepius-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 48v8a32 32 0 0 1-32 32H32v-8a32 32 0 0 1 32-32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 79v1a40 40 0 0 1-40 40h-40v80h8a16 16 0 0 0 10.67-27.93 8 8 0 0 1 10.66-11.92A32 32 0 0 1 144 216h-8v16a8 8 0 0 1-16 0v-16H96a8 8 0 0 1 0-16h24v-80H96a16 16 0 0 0 0 32 8 8 0 0 1 0 16 32 32 0 0 1 0-64h24V24a8 8 0 0 1 16 0v80h40a24 24 0 0 0 24-24v-1a23 23 0 0 0-23-23h-17a8 8 0 0 1 0-16h17a39 39 0 0 1 39 39ZM56 96H32a8 8 0 0 1-8-8v-8a40 40 0 0 1 40-40h32a8 8 0 0 1 0 16 40 40 0 0 1-40 40Zm24-40H64a24 24 0 0 0-24 24h16a24 24 0 0 0 24-24Z" /></Svg>;
export default Asclepius;