/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SupersetOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="superset-of-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-superset-of-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 200a12 12 0 0 1-12 12H56a12 12 0 0 1 0-24h152a12 12 0 0 1 12 12Zm-68-52H56a12 12 0 0 0 0 24h96a68 68 0 0 0 0-136H56a12 12 0 0 0 0 24h96a44 44 0 0 1 0 88Z" /></Svg>;
export default SupersetOf;