/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SplitVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="split-vertical-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-split-vertical-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 56H96l32-32Zm-32 176 32-32H96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 144H48a8 8 0 0 0 0 16h72v32H96a8 8 0 0 0-5.66 13.66l32 32a8 8 0 0 0 11.32 0l32-32A8 8 0 0 0 160 192h-24v-32h72a8 8 0 0 0 0-16Zm-80 76.69L115.31 208h25.38ZM48 112h160a8 8 0 0 0 0-16h-72V64h24a8 8 0 0 0 5.66-13.66l-32-32a8 8 0 0 0-11.32 0l-32 32A8 8 0 0 0 96 64h24v32H48a8 8 0 0 0 0 16Zm80-76.69L140.69 48h-25.38Z" /></Svg>;
export default SplitVertical;