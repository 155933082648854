/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Door = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="door-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-door-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 212h-20V40a20 20 0 0 0-20-20H64a20 20 0 0 0-20 20v172H24a12 12 0 0 0 0 24h208a12 12 0 0 0 0-24ZM68 44h120v168H68Zm104 88a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default Door;