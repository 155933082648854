/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MicrosoftOutlookLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microsoft-outlook-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microsoft-outlook-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M116 128a28 28 0 1 0-28 28 28 28 0 0 0 28-28Zm-48 0a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm156-20h-20V40a4 4 0 0 0-4-4h-96a4 4 0 0 0-4 4v28H40a12 12 0 0 0-12 12v96a12 12 0 0 0 12 12h36v20a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-96a4 4 0 0 0-4-4Zm-4 100.17L158.83 164 220 119.82V208a1 1 0 0 1 0 .17ZM211.63 116l-7.63 5.51V116ZM108 44h88v83.29l-44 31.78-4-2.89V80a12 12 0 0 0-12-12h-28ZM36 176V80a4 4 0 0 1 4-4h96a4 4 0 0 1 4 4v96a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4Zm48 32v-20h52a12 12 0 0 0 12-12v-9.95L211.63 212H88a4 4 0 0 1-4-4Z" /></Svg>;
export default MicrosoftOutlookLogo;