/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FinnTheHuman = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="finn-the-human-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-finn-the-human-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 96H96a52 52 0 0 0 0 104h64a52 52 0 0 0 0-104Zm0 80H96a28 28 0 0 1 0-56h64a28 28 0 0 1 0 56Zm-48-28a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm64 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm36-112a36 36 0 0 0-33.94 24H77.94A36 36 0 0 0 8 72v76a88.1 88.1 0 0 0 88 88h64a88.1 88.1 0 0 0 88-88V72a36 36 0 0 0-36-36Zm12 112a64.07 64.07 0 0 1-64 64H96a64.07 64.07 0 0 1-64-64V72a12 12 0 0 1 24 0 12 12 0 0 0 12 12h120a12 12 0 0 0 12-12 12 12 0 0 1 24 0Z" /></Svg>;
export default FinnTheHuman;