/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandsPraying = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hands-praying-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hands-praying-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M229.66 197 197 229.66a8 8 0 0 1-11.31 0l-18.35-18.35 44-44 18.35 18.35a8 8 0 0 1-.03 11.34ZM26.34 185.66a8 8 0 0 0 0 11.31L59 229.66a8 8 0 0 0 11.31 0l18.35-18.35-44-44Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m235.32 180-36.24-36.25-36.46-120.29A21.76 21.76 0 0 0 128 12.93a21.76 21.76 0 0 0-34.62 10.53l-36.46 120.3L20.68 180a16 16 0 0 0 0 22.62l32.69 32.69a16 16 0 0 0 22.63 0L124.28 187a40.68 40.68 0 0 0 3.72-4.29 40.68 40.68 0 0 0 3.72 4.29L180 235.32a16 16 0 0 0 22.63 0l32.69-32.69a16 16 0 0 0 0-22.63ZM64.68 224 32 191.32l12.69-12.69 32.69 32.69ZM120 158.75a23.85 23.85 0 0 1-7 17L88.68 200 56 167.32l13.65-13.66a8 8 0 0 0 2-3.34l37-122.22A5.78 5.78 0 0 1 120 29.78Zm23 17a23.85 23.85 0 0 1-7-17v-129a5.78 5.78 0 0 1 11.31-1.68l37 122.22a8 8 0 0 0 2 3.34l14.49 14.49-33.4 32ZM191.32 224l-12.56-12.57 33.39-32L224 191.32Z" /></Svg>;
export default HandsPraying;