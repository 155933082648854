/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tornado = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tornado-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tornado-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 228a12 12 0 0 1-12 12h-16a12 12 0 0 1 0-24h16a12 12 0 0 1 12 12Zm76-196H60a12 12 0 0 0 0 24 12 12 0 0 1 0 24H44a12 12 0 0 0 0 24h32a12 12 0 0 1 0 24 12 12 0 0 0 0 24h48a12 12 0 0 1 0 24 12 12 0 0 0 0 24h48a12 12 0 0 0 0-24 12 12 0 0 1 0-24h16a12 12 0 0 0 0-24h-24a12 12 0 0 1 0-24 12 12 0 0 0 0-24 12 12 0 0 1 0-24h56a12 12 0 0 0 0-24Z" /></Svg>;
export default Tornado;