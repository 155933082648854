/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpotifyLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="spotify-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-spotify-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90Zm29.31-49.2a6 6 0 0 1-8.11 2.51 44.75 44.75 0 0 0-42.4 0 6 6 0 1 1-5.6-10.62 57.37 57.37 0 0 1 53.6 0 6 6 0 0 1 2.51 8.11Zm32-56a6 6 0 0 1-8.12 2.46 112.67 112.67 0 0 0-106.34 0 6 6 0 1 1-5.66-10.58 124.65 124.65 0 0 1 117.66 0 6 6 0 0 1 2.44 8.14Zm-16 28A6 6 0 0 1 168 144a6.07 6.07 0 0 1-2.82-.7 79 79 0 0 0-74.36 0 6 6 0 1 1-5.64-10.6 91 91 0 0 1 85.64 0 6 6 0 0 1 2.48 8.12Z" /></Svg>;
export default SpotifyLogo;