/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CourtBasketball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="court-basketball-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-court-basketball-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 92.23v71.54a4 4 0 0 1-4.41 4 40 40 0 0 1 0-79.52 4 4 0 0 1 4.41 3.98ZM20.41 167.76a40 40 0 0 0 0-79.52 4 4 0 0 0-4.41 4v71.54a4 4 0 0 0 4.41 3.98ZM116 48H32a16 16 0 0 0-16 16v4.13a4 4 0 0 0 3.8 4 56 56 0 0 1 0 111.74 4 4 0 0 0-3.8 4V192a16 16 0 0 0 16 16h84a4 4 0 0 0 4-4V52a4 4 0 0 0-4-4Zm108 0h-84a4 4 0 0 0-4 4v152a4 4 0 0 0 4 4h84a16 16 0 0 0 16-16v-4.13a4 4 0 0 0-3.8-4 56 56 0 0 1 0-111.74 4 4 0 0 0 3.8-4V64a16 16 0 0 0-16-16Z" /></Svg>;
export default CourtBasketball;