/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiceFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dice-four-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dice-four-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 34H64a30 30 0 0 0-30 30v128a30 30 0 0 0 30 30h128a30 30 0 0 0 30-30V64a30 30 0 0 0-30-30Zm18 158a18 18 0 0 1-18 18H64a18 18 0 0 1-18-18V64a18 18 0 0 1 18-18h128a18 18 0 0 1 18 18Zm-100-92a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm56 0a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-56 56a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm56 0a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default DiceFour;