/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookmarksSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bookmarks-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bookmarks-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 56H60a20 20 0 0 0-20 20v152a12 12 0 0 0 19 9.76l49-35 49 35a12 12 0 0 0 19-9.76V76a20 20 0 0 0-20-20Zm-4 148.68-37-26.45a12 12 0 0 0-14 0l-37 26.45V80h88ZM216 36v152a12 12 0 0 1-24 0V40H92a12 12 0 0 1 0-24h104a20 20 0 0 1 20 20Z" /></Svg>;
export default BookmarksSimple;