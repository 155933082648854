/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ReplitLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="replit-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-replit-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 84h-60V40a20 20 0 0 0-20-20H72a20 20 0 0 0-20 20v48a20 20 0 0 0 20 20h60v40H72a20 20 0 0 0-20 20v48a20 20 0 0 0 20 20h64a20 20 0 0 0 20-20v-44h60a20 20 0 0 0 20-20v-48a20 20 0 0 0-20-20Zm-84 128H76v-40h56Zm0-128H76V44h56Zm80 64h-56v-40h56Z" /></Svg>;
export default ReplitLogo;