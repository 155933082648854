/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14l45.64 50.2-50.9 50.9A19.86 19.86 0 0 0 28 163.31V208a20 20 0 0 0 20 20h44.69a19.85 19.85 0 0 0 14.14-5.86l47.43-47.42 44.87 49.35a12 12 0 1 0 17.76-16.14Zm54.55 95.68L72 167l-11-11 40-39.95ZM52 181l23 23H52Zm48 14-11-11 38.62-38.62 10.51 11.56ZM230.15 70.54l-44.69-44.68a20 20 0 0 0-28.28 0l-36 36a12 12 0 0 0 14.55 18.82L147.08 92A12 12 0 0 0 164 108.93L175 120l-.54.53a12 12 0 1 0 17 17l38.67-38.67a20 20 0 0 0 .02-28.32ZM192 103l-39-39 18.34-18.34 39 39Z" /></Svg>;
export default PencilSlash;