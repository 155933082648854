/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropboxLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dropbox-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dropbox-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M238.83 146.13 201.08 120l37.75-26.13a12 12 0 0 0 0-19.74l-52-36a12 12 0 0 0-13.66 0L128 69.41 82.83 38.13a12 12 0 0 0-13.66 0l-52 36a12 12 0 0 0 0 19.74L54.92 120l-37.75 26.13a12 12 0 0 0 0 19.74l52 36a12 12 0 0 0 13.66 0L128 170.59l45.17 31.28a12 12 0 0 0 13.66 0l52-36a12 12 0 0 0 0-19.74ZM128 141.41 97.08 120 128 98.59 158.92 120Zm52-78.81L210.92 84 180 105.4 149.08 84Zm-104 0L106.92 84 76 105.4 45.08 84Zm0 114.8L45.08 156 76 134.6l30.92 21.4Zm104 0L149.08 156 180 134.6l30.92 21.4Zm-25.27 32.09a12 12 0 0 1-3 16.7l-16.86 11.68a12 12 0 0 1-13.66 0l-16.86-11.68A12 12 0 1 1 118 206.46l10 6.94 10-6.94a12 12 0 0 1 16.73 3.03Z" /></Svg>;
export default DropboxLogo;