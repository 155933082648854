/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberEight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-eight-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-eight-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M147.08 119.64a44 44 0 1 0-38.16 0 52 52 0 1 0 38.16 0ZM92 80a36 36 0 1 1 36 36 36 36 0 0 1-36-36Zm36 132a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Z" /></Svg>;
export default NumberEight;