/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PencilLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pencil-line__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pencil-line'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.32 73.37-44.69-44.68a16 16 0 0 0-22.63 0L36.69 152A15.86 15.86 0 0 0 32 163.31V208a16 16 0 0 0 16 16h168a8 8 0 0 0 0-16H115.32l112-112a16 16 0 0 0 0-22.63ZM136 75.31 152.69 92 68 176.69 51.31 160ZM48 208v-28.69L76.69 208Zm48-3.31L79.32 188 164 103.31 180.69 120Zm96-96L147.32 64l24-24L216 84.69Z" /></Svg>;
export default PencilLine;