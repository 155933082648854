/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const City = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="city-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-city-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 40h64v176H32Zm128 48v128h64V88Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M240 208h-8V88a8 8 0 0 0-8-8h-64a8 8 0 0 0-8 8v40h-48V40a8 8 0 0 0-8-8H32a8 8 0 0 0-8 8v168h-8a8 8 0 0 0 0 16h224a8 8 0 0 0 0-16ZM168 96h48v112h-48Zm-16 48v64h-48v-64ZM40 48h48v160H40Zm32 24v16a8 8 0 0 1-16 0V72a8 8 0 0 1 16 0Zm0 48v16a8 8 0 0 1-16 0v-16a8 8 0 0 1 16 0Zm0 48v16a8 8 0 0 1-16 0v-16a8 8 0 0 1 16 0Zm48 16v-16a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0Zm64 0v-16a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0Zm0-48v-16a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0Z" /></Svg>;
export default City;