/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StarHalf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-half-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-half-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m233 113.32-5.06 4.36a6 6 0 1 1-7.83-9.08l5.06-4.36a2.38 2.38 0 0 0 .72-2.65 2.28 2.28 0 0 0-2.07-1.65l-8.28-.67a6 6 0 1 1 1-12l8.27.67a14.17 14.17 0 0 1 12.52 9.89 14.43 14.43 0 0 1-4.33 15.49Zm-58.45 38.83 4.08 17.22a6 6 0 0 0 5.83 4.62 5.51 5.51 0 0 0 1.39-.17 6 6 0 0 0 4.46-7.22l-3.24-13.67 8.87-7.65a6 6 0 1 0-7.84-9.09l-11.63 10a6 6 0 0 0-1.94 5.96Zm25.23 54.45a6 6 0 1 0-11.67 2.77l1.32 5.57a2.35 2.35 0 0 1-.95 2.57 2.24 2.24 0 0 1-2.6.1l-6.71-4.13a6 6 0 1 0-6.29 10.22l6.72 4.13a14.39 14.39 0 0 0 21.5-15.65ZM175.52 96l-15.64-1.26a6 6 0 0 1-5.06-3.69L134 40.7v145l8.39 5.15a6 6 0 0 1-6.29 10.23l-8.1-5-51.58 31.75a14.39 14.39 0 0 1-21.5-15.65l14-59.25L23 113.32a14.43 14.43 0 0 1-4.3-15.45A14.17 14.17 0 0 1 31.24 88l60.25-4.87 23.22-56.2a14.37 14.37 0 0 1 26.58 0l23.22 56.2 12 1a6 6 0 0 1-1 12ZM122 185.72V40.7l-20.82 50.39a6 6 0 0 1-5.06 3.69L32.2 99.94a2.28 2.28 0 0 0-2.07 1.65 2.38 2.38 0 0 0 .72 2.65l48.7 42a6 6 0 0 1 1.92 5.92l-14.88 62.78a2.35 2.35 0 0 0 1 2.57 2.24 2.24 0 0 0 2.6.1Z" /></Svg>;
export default StarHalf;