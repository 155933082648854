/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShuffleAngular = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shuffle-angular-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shuffle-angular-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.24 179.76a6 6 0 0 1 0 8.48l-24 24a6 6 0 0 1-8.48-8.48L217.52 190H168a6 6 0 0 1-4.88-2.51L84.91 78H32a6 6 0 0 1 0-12h56a6 6 0 0 1 4.88 2.51L171.09 178h46.43l-13.76-13.76a6 6 0 0 1 8.48-8.48Zm-92.07-74.4a6 6 0 0 0 8.37-1.4l18.55-26h46.43l-13.76 13.8a6 6 0 1 0 8.48 8.48l24-24a6 6 0 0 0 0-8.48l-24-24a6 6 0 0 0-8.48 8.48L217.52 66H168a6 6 0 0 0-4.88 2.51L142.78 97a6 6 0 0 0 1.39 8.36Zm-32.34 45.28a6 6 0 0 0-8.37 1.4L84.91 178H32a6 6 0 0 0 0 12h56a6 6 0 0 0 4.88-2.51L113.22 159a6 6 0 0 0-1.39-8.36Z" /></Svg>;
export default ShuffleAngular;