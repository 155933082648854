/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderFemale = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-female-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-female-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 96a72 72 0 1 1-72-72 72 72 0 0 1 72 72Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 96a80 80 0 1 0-88 79.6V200H88a8 8 0 0 0 0 16h32v24a8 8 0 0 0 16 0v-24h32a8 8 0 0 0 0-16h-32v-24.4A80.11 80.11 0 0 0 208 96ZM64 96a64 64 0 1 1 64 64 64.07 64.07 0 0 1-64-64Z" /></Svg>;
export default GenderFemale;