/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrolleySuitcase = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trolley-suitcase-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trolley-suitcase-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 156h128a12 12 0 0 0 12-12V72a12 12 0 0 0-12-12h-36V40a12 12 0 0 0-12-12h-32a12 12 0 0 0-12 12v20H88a12 12 0 0 0-12 12v72a12 12 0 0 0 12 12Zm44-116a4 4 0 0 1 4-4h32a4 4 0 0 1 4 4v20h-40ZM84 72a4 4 0 0 1 4-4h128a4 4 0 0 1 4 4v72a4 4 0 0 1-4 4H88a4 4 0 0 1-4-4Zm0 152a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm144 0a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm16-40a4 4 0 0 1-4 4H32a4 4 0 0 1 0-8h12V75.31a4 4 0 0 0-1.17-2.83L21.17 50.83a4 4 0 0 1 5.66-5.66l21.66 21.66A12 12 0 0 1 52 75.31V180h188a4 4 0 0 1 4 4Z" /></Svg>;
export default TrolleySuitcase;