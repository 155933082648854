/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LampPendant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lamp-pendant-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lamp-pendant-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 72.28V72a20 20 0 0 0-20-20h-20V16a12 12 0 0 0-24 0v36H96a20 20 0 0 0-20 20v.28A115.7 115.7 0 0 0 12 176a12 12 0 0 0 12 12h60.19a44 44 0 0 0 87.62 0H232a12 12 0 0 0 12-12 115.7 115.7 0 0 0-64-103.72ZM128 204a20 20 0 0 1-19.6-16h39.2a20 20 0 0 1-19.6 16Zm-91.22-40a91.75 91.75 0 0 1 55.84-72.95A12 12 0 0 0 100 80v-4h56v4a12 12 0 0 0 7.38 11.08 91.75 91.75 0 0 1 55.84 73Z" /></Svg>;
export default LampPendant;