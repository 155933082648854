/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const OpenAiLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="open-ai-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-open-ai-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228.88 113.61A60 60 0 0 0 166 33.42 60 60 0 0 0 65.1 47.83a60 60 0 0 0-38 94.56A60.08 60.08 0 0 0 83.6 223a57.83 57.83 0 0 0 6.45-.36 60 60 0 0 0 100.85-14.47 60 60 0 0 0 38-94.56ZM181.81 58.3a36 36 0 0 1 26.58 37.6L170 73.73a12 12 0 0 0-12 0l-38 21.94V81.81l34.5-19.91a35.74 35.74 0 0 1 27.31-3.6ZM136 123.38v9.24l-8 4.62-8-4.62v-9.24l8-4.62ZM84 72a36 36 0 0 1 56.39-29.67L102 64.49a12 12 0 0 0-6 10.39v43.88l-12-6.93ZM44.33 88.93A35.81 35.81 0 0 1 60 74.43v44.33a12 12 0 0 0 6 10.4l38 21.93L92 158l-34.5-19.9a36 36 0 0 1-13.17-49.17ZM74.19 197.7a36 36 0 0 1-26.58-37.6L86 182.27a12 12 0 0 0 12 0l38-21.94v13.86l-34.5 19.91a35.74 35.74 0 0 1-27.31 3.6ZM172 184a36 36 0 0 1-56.39 29.67L154 191.51a12 12 0 0 0 6-10.39v-43.88l12 6.93Zm39.67-16.93a35.81 35.81 0 0 1-15.67 14.5v-44.33a12 12 0 0 0-6-10.4l-38-21.93L164 98l34.5 19.92a36 36 0 0 1 13.17 49.15Z" /></Svg>;
export default OpenAiLogo;