/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rss = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104.08 151.92A67.52 67.52 0 0 1 124 200a4 4 0 0 1-8 0 60 60 0 0 0-60-60 4 4 0 0 1 0-8 67.52 67.52 0 0 1 48.08 19.92ZM56 84a4 4 0 0 0 0 8 108 108 0 0 1 108 108 4 4 0 0 0 8 0A116 116 0 0 0 56 84Zm116 0A162.92 162.92 0 0 0 56 36a4 4 0 0 0 0 8 155 155 0 0 1 110.31 45.69A155 155 0 0 1 212 200a4 4 0 0 0 8 0 162.92 162.92 0 0 0-48-116ZM60 188a8 8 0 1 0 8 8 8 8 0 0 0-8-8Z" /></Svg>;
export default Rss;