/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CableCar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cable-car-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cable-car-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M243.94 31.3a4 4 0 0 0-4.64-3.24l-224 40A4 4 0 0 0 16 76a4.14 4.14 0 0 0 .7-.06L124 56.78V100H64a28 28 0 0 0-28 28v64a28 28 0 0 0 28 28h128a28 28 0 0 0 28-28v-64a28 28 0 0 0-28-28h-60V55.35l108.7-19.41a4 4 0 0 0 3.24-4.64ZM100 164v-56h56v56Zm-36-56h28v56H44v-36a20 20 0 0 1 20-20Zm128 104H64a20 20 0 0 1-20-20v-20h168v20a20 20 0 0 1-20 20Zm20-84v36h-48v-56h28a20 20 0 0 1 20 20Z" /></Svg>;
export default CableCar;