/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SidebarSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sidebar-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sidebar-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a12 12 0 0 0-12 12v144a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12ZM36 200V56a4 4 0 0 1 4-4h44v152H40a4 4 0 0 1-4-4Zm184 0a4 4 0 0 1-4 4H92V52h124a4 4 0 0 1 4 4Z" /></Svg>;
export default SidebarSimple;