/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImageBroken = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="image-broken-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-image-broken-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a12 12 0 0 0-12 12v144a12 12 0 0 0 12 12h64a4 4 0 0 0 3.79-2.74L123.21 163l38.28-15.31a4 4 0 0 0 2.22-2.22L179 107.21l46.24-15.42A4 4 0 0 0 228 88V56a12 12 0 0 0-12-12Zm-97.49 112.29a4 4 0 0 0-2.3 2.45L101.12 204H40a4 4 0 0 1-4-4v-29.66l53.17-53.17a4 4 0 0 1 5.66 0l34.71 34.71ZM220 85.12l-45.26 15.09a4 4 0 0 0-2.45 2.3l-15.37 38.41-19.3 7.73-37.13-37.14a12 12 0 0 0-17 0L36 159V56a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4Zm6.34 33.37a4 4 0 0 0-3.6-.55l-23.81 7.93a4 4 0 0 0-2.44 2.31l-15 37.36-37.36 15a4 4 0 0 0-2.31 2.44l-7.93 23.81a4 4 0 0 0 .55 3.6 4 4 0 0 0 3.24 1.66H216a12 12 0 0 0 12-12v-78.32a4 4 0 0 0-1.66-3.24ZM220 200a4 4 0 0 1-4 4h-72.72l5.59-16.78 37.23-14.89a4 4 0 0 0 2.23-2.23l14.89-37.23 16.78-5.59Z" /></Svg>;
export default ImageBroken;