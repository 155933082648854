/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Desktop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="desktop__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-desktop'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 40H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h72v16H96a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16h-24v-16h72a24 24 0 0 0 24-24V64a24 24 0 0 0-24-24ZM48 56h160a8 8 0 0 1 8 8v80H40V64a8 8 0 0 1 8-8Zm160 128H48a8 8 0 0 1-8-8v-16h176v16a8 8 0 0 1-8 8Z" /></Svg>;
export default Desktop;