/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HeartStraight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="heart-straight-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-heart-straight-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221.6 58.38a56.06 56.06 0 0 0-79.12-.08L128 71.78 113.52 58.3a56 56 0 0 0-79.15 79.25l89.36 90.66a6 6 0 0 0 8.54 0l89.33-90.62a56 56 0 0 0 0-79.21Zm-8.52 70.75L128 215.45 42.89 129.1a44 44 0 0 1 62.22-62.23 1.07 1.07 0 0 0 .16.14l18.64 17.36a6 6 0 0 0 8.18 0L150.73 67a1.07 1.07 0 0 0 .16-.14 44 44 0 1 1 62.19 62.26Z" /></Svg>;
export default HeartStraight;