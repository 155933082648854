/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LinuxLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="linux-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-linux-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 208h-56c-8-14.35-22.91-24-40-24s-32 9.65-40 24H32s32-40 32-120a64 64 0 0 1 128 0c0 80 32 120 32 120Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M229 214.25a8 8 0 0 1-11.24-1.25C216.39 211.27 184 169.86 184 88a56 56 0 0 0-112 0c0 81.86-32.37 123.27-33.75 125a8 8 0 0 1-12.51-10c.15-.2 7.69-9.9 15.13-28.74C47.77 156.8 56 127.64 56 88a72 72 0 0 1 144 0c0 39.64 8.23 68.8 15.13 86.28 7.48 18.94 15.06 28.64 15.14 28.74a8 8 0 0 1-1.27 11.23ZM100 88a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm68 12a12 12 0 1 0-12 12 12 12 0 0 0 12-12Zm-68.42 28.84a8 8 0 0 0-7.15 14.31l32 16a7.94 7.94 0 0 0 7.15 0l32-16a8 8 0 0 0-7.16-14.31L128 143.05ZM128 176a54.07 54.07 0 0 0-47 28.11 8 8 0 1 0 14 7.78 37.35 37.35 0 0 1 66 0 8 8 0 0 0 14-7.78A54.07 54.07 0 0 0 128 176Z" /></Svg>;
export default LinuxLogo;