/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tooth = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tooth__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tooth'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M171 71.42 149.54 80 171 88.57a8 8 0 1 1-6 14.85l-37-14.81-37 14.81a8 8 0 1 1-6-14.85L106.46 80 85 71.42a8 8 0 1 1 6-14.85l37 14.81 37-14.81a8 8 0 1 1 6 14.85Zm53 8.33c0 42.72-8 75.4-14.69 95.28-8.73 25.8-20.63 45.49-32.65 54a15.69 15.69 0 0 1-15.95 1.41 16.09 16.09 0 0 1-9.18-13.36c-.85-11.5-5.05-49.08-23.53-49.08s-22.68 37.59-23.53 49.11a16.09 16.09 0 0 1-16 14.9 15.67 15.67 0 0 1-9.13-2.95c-12-8.53-23.92-28.22-32.65-54C40 155.15 32 122.47 32 79.75A56 56 0 0 1 88 24h80a56 56 0 0 1 56 55.75Zm-16 0A40 40 0 0 0 168 40H88a40 40 0 0 0-40 39.76c0 40.55 7.51 71.4 13.85 90.14 11.05 32.66 23 43.37 26.61 46C91.57 174.67 105.59 152 128 152s36.45 22.71 39.49 63.94c3.6-2.59 15.57-13.26 26.66-46 6.34-18.78 13.85-49.63 13.85-90.18Z" /></Svg>;
export default Tooth;