/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FrameCorners = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="frame-corners-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-frame-corners-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M198 80v32a6 6 0 0 1-12 0V86h-26a6 6 0 0 1 0-12h32a6 6 0 0 1 6 6ZM96 170H70v-26a6 6 0 0 0-12 0v32a6 6 0 0 0 6 6h32a6 6 0 0 0 0-12ZM230 56v144a14 14 0 0 1-14 14H40a14 14 0 0 1-14-14V56a14 14 0 0 1 14-14h176a14 14 0 0 1 14 14Zm-12 0a2 2 0 0 0-2-2H40a2 2 0 0 0-2 2v144a2 2 0 0 0 2 2h176a2 2 0 0 0 2-2Z" /></Svg>;
export default FrameCorners;