/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Jeep = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="jeep-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-jeep-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 92h-12.77l-10-46.51a12.07 12.07 0 0 0-11.7-9.49H50.47a12.07 12.07 0 0 0-11.74 9.49L28.77 92H16a4 4 0 0 0 0 8h12v100a12 12 0 0 0 12 12h24a12 12 0 0 0 12-12v-28h104v28a12 12 0 0 0 12 12h24a12 12 0 0 0 12-12V100h12a4 4 0 0 0 0-8ZM46.56 47.16A4 4 0 0 1 50.47 44h155.06a4 4 0 0 1 3.91 3.16L219.05 92H37ZM68 200a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4v-28h32Zm148 4h-24a4 4 0 0 1-4-4v-28h32v28a4 4 0 0 1-4 4Zm4-40h-72v-36a4 4 0 0 0-8 0v36h-24v-36a4 4 0 0 0-8 0v36H36v-64h184ZM60 132a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm120 0a8 8 0 1 1 8 8 8 8 0 0 1-8-8Z" /></Svg>;
export default Jeep;