/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThreeD = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="three-d-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-three-d-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M98 148a22 22 0 0 0-22-22 6 6 0 0 1-4.92-9.44L92.48 86H56a6 6 0 0 1 0-12h48a6 6 0 0 1 4.92 9.44l-22.53 32.18a34 34 0 1 1-34.68 56.17 6 6 0 0 1 8.58-8.39A22 22 0 0 0 98 148Zm62-74a54 54 0 0 1 0 108h-24a6 6 0 0 1-6-6V80a6 6 0 0 1 6-6Zm0 12h-18v84h18a42 42 0 0 0 0-84ZM32 54h192a6 6 0 0 0 0-12H32a6 6 0 0 0 0 12Zm192 148H32a6 6 0 0 0 0 12h192a6 6 0 0 0 0-12Z" /></Svg>;
export default ThreeD;