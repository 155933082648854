/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatTeardropDots = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-teardrop-dots-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-teardrop-dots-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 20A104.11 104.11 0 0 0 28 124v84a20 20 0 0 0 20 20h84a104 104 0 0 0 0-208Zm0 184H52v-80a80 80 0 1 1 80 80Zm-8-76a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm48 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default ChatTeardropDots;