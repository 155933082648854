/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shovel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shovel__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shovel'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m245.66 58.34-48-48a8 8 0 0 0-11.32 11.32L204.69 40l-71 71-34.38-34.32a16 16 0 0 0-22.62 0l-56 56A15.89 15.89 0 0 0 16 144v80a16 16 0 0 0 16 16h80a15.86 15.86 0 0 0 11.31-4.69l56-56a16 16 0 0 0 0-22.62L145 122.34l71-71 18.34 18.35a8 8 0 0 0 11.32-11.32ZM168 168l-56 56H32v-80l56-56 34.34 34.34-40 40a8 8 0 0 0 11.32 11.32l40-40Z" /></Svg>;
export default Shovel;