/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const StarFour = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="star-four-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-star-four-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m230.86 109.25-61.68-22.43-22.43-61.68a19.95 19.95 0 0 0-37.5 0L86.82 86.82l-61.68 22.43a19.95 19.95 0 0 0 0 37.5l61.68 22.43 22.43 61.68a19.95 19.95 0 0 0 37.5 0l22.43-61.68 61.68-22.43a19.95 19.95 0 0 0 0-37.5Zm-75.14 39.29a12 12 0 0 0-7.18 7.18L128 212.21l-20.54-56.49a12 12 0 0 0-7.18-7.18L43.79 128l56.49-20.54a12 12 0 0 0 7.18-7.18L128 43.79l20.54 56.49a12 12 0 0 0 7.18 7.18L212.21 128Z" /></Svg>;
export default StarFour;