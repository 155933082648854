/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Database = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="database-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-database-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M196 35.52C177.62 25.51 153.48 20 128 20s-49.62 5.51-68 15.52C39.37 46.79 28 62.58 28 80v96c0 17.42 11.37 33.21 32 44.48 18.35 10 42.49 15.52 68 15.52s49.62-5.51 68-15.52c20.66-11.27 32-27.06 32-44.48V80c0-17.42-11.37-33.21-32-44.48Zm8 92.48c0 17-31.21 36-76 36s-76-19-76-36v-8.46a88.9 88.9 0 0 0 8 4.94c18.35 10 42.49 15.52 68 15.52s49.62-5.51 68-15.52a88.9 88.9 0 0 0 8-4.94Zm-76-84c44.79 0 76 19 76 36s-31.21 36-76 36-76-19-76-36 31.21-36 76-36Zm0 168c-44.79 0-76-19-76-36v-8.46a88.9 88.9 0 0 0 8 4.94c18.35 10 42.49 15.52 68 15.52s49.62-5.51 68-15.52a88.9 88.9 0 0 0 8-4.94V176c0 17-31.21 36-76 36Z" /></Svg>;
export default Database;