/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextAa = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-aa-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-aa-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M90.86 50.89a12 12 0 0 0-21.72 0l-64 136a12 12 0 0 0 21.71 10.22L42.44 164h75.12l15.58 33.11a12 12 0 0 0 21.72-10.22ZM53.74 140 80 84.18 106.27 140ZM200 84c-13.85 0-24.77 3.86-32.45 11.48a12 12 0 1 0 16.9 17c3-3 8.26-4.52 15.55-4.52 11 0 20 7.18 20 16v4.39a47.28 47.28 0 0 0-20-4.35c-24.26 0-44 17.94-44 40s19.74 40 44 40a47.18 47.18 0 0 0 22-5.38 12 12 0 0 0 22-6.62v-68c0-22.06-19.74-40-44-40Zm0 96c-11 0-20-7.18-20-16s9-16 20-16 20 7.18 20 16-9 16-20 16Z" /></Svg>;
export default TextAa;