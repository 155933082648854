/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-minus-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-minus-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 76a44 44 0 1 1-44 44 44 44 0 0 1 44-44Zm48-12h48a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16Zm39.87 24.46A8 8 0 0 0 211 98.67a88 88 0 0 1-17.23 87.74A79.86 79.86 0 0 0 172 165.1a4 4 0 0 0-4.84.32 59.81 59.81 0 0 1-78.27 0 4 4 0 0 0-4.89-.32 79.71 79.71 0 0 0-21.79 21.31A88 88 0 0 1 128 40a88.76 88.76 0 0 1 14.68 1.22 8 8 0 0 0 2.64-15.78 103.9 103.9 0 1 0 80.76 67.89 8 8 0 0 0-10.21-4.87Z" /></Svg>;
export default UserCircleMinus;