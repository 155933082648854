/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DotsThree = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dots-three-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dots-three-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 80H32a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16ZM60 140a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm68 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm68 0a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default DotsThree;