/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Television = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="television-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-television-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 72v136H40a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 64h-68.69l34.35-34.34a8 8 0 1 0-11.32-11.32L128 60.69 85.66 18.34a8 8 0 0 0-11.32 11.32L108.69 64H40a16 16 0 0 0-16 16v120a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16ZM40 80h104v120H40Zm176 120h-56V80h56v120Zm-16-84a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm0 48a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default Television;