/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Campfire = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="campfire-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-campfire-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.51 227.4A12 12 0 0 1 216 236a12.15 12.15 0 0 1-3.4-.49l-84.6-25-84.6 25a12.15 12.15 0 0 1-3.4.49 12 12 0 0 1-3.4-23.51L85.65 198 36.6 183.51a12 12 0 0 1 6.8-23l84.6 25 84.6-25a12 12 0 1 1 6.8 23L170.35 198l49.05 14.49a12 12 0 0 1 8.11 14.91ZM64 104c0-50.59 55.93-81.28 58.31-82.57a12 12 0 0 1 11.38 0C136.07 22.72 192 53.41 192 104a64 64 0 0 1-128 0Zm64 40a12 12 0 0 0 12-12c0-6.47-2.71-12.55-8-18.07a41.26 41.26 0 0 0-4-3.57 42.49 42.49 0 0 0-4 3.57c-5.33 5.52-8 11.6-8 18.07a12 12 0 0 0 12 12Zm-40-40a39.78 39.78 0 0 0 4.85 19.08c4.81-25 28.66-37.25 29.78-37.81a12 12 0 0 1 10.74 0c1.12.56 25 12.78 29.78 37.81A39.78 39.78 0 0 0 168 104c0-28.34-27.74-49.81-40-57.92-12.25 8.1-40 29.58-40 57.92Z" /></Svg>;
export default Campfire;