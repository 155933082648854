/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-check-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-check-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220.69 100.17a12 12 0 0 0-9.85 13.83 85 85 0 0 1 1.16 14 83.57 83.57 0 0 1-18 51.94 83.48 83.48 0 0 0-29-23.42 52 52 0 1 0-74 0 83.48 83.48 0 0 0-29 23.42A83.94 83.94 0 0 1 128 44a85 85 0 0 1 14 1.16 12 12 0 0 0 4-23.67A108.1 108.1 0 0 0 20 128a108 108 0 0 0 216 0 109.19 109.19 0 0 0-1.49-18 12 12 0 0 0-13.82-9.83ZM100 120a28 28 0 1 1 28 28 28 28 0 0 1-28-28Zm-20.43 76.57a60 60 0 0 1 96.86 0 83.72 83.72 0 0 1-96.86 0ZM240.49 48.49l-32 32a12 12 0 0 1-17 0l-16-16a12 12 0 0 1 17-17L200 55l23.51-23.52a12 12 0 1 1 17 17Z" /></Svg>;
export default UserCircleCheck;