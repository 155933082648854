/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LockLaminated = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lock-laminated-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lock-laminated-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 96v112a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V96a8 8 0 0 1 8-8h160a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 80h-32V56a48 48 0 0 0-96 0v24H48a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16ZM48 128h160v16H48Zm0 32h160v16H48ZM96 56a32 32 0 0 1 64 0v24H96Zm112 40v16H48V96Zm0 112H48v-16h160v16Z" /></Svg>;
export default LockLaminated;