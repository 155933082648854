/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerSimpleLow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-simple-low-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-simple-low-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M165.27 21.22a12 12 0 0 0-12.64 1.31L83.88 76H40a20 20 0 0 0-20 20v64a20 20 0 0 0 20 20h43.88l68.75 53.47A12 12 0 0 0 172 224V32a12 12 0 0 0-6.73-10.78ZM148 199.46l-52.63-40.93A12 12 0 0 0 88 156H44v-56h44a12 12 0 0 0 7.37-2.53L148 56.54ZM212 104v48a12 12 0 0 1-24 0v-48a12 12 0 0 1 24 0Z" /></Svg>;
export default SpeakerSimpleLow;