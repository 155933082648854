/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowClockwise = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-clockwise-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-clockwise-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 56v48a4 4 0 0 1-4 4h-48a4 4 0 0 1 0-8h37.7l-34.17-31.31-.13-.12a84 84 0 1 0-1.75 120.51 4 4 0 0 1 5.5 5.82A91.43 91.43 0 0 1 128 220h-1.26A92 92 0 1 1 193 62.84l35 32.05V56a4 4 0 1 1 8 0Z" /></Svg>;
export default ArrowClockwise;