/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MusicNotesMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="music-notes-minus-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-music-notes-minus-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 48a6 6 0 0 1-6 6h-48a6 6 0 0 1 0-12h48a6 6 0 0 1 6 6Zm-16 40v76a34.06 34.06 0 1 1-12-25.89V88a6 6 0 0 1 12 0Zm-12 76a22 22 0 1 0-22 22 22 22 0 0 0 22-22ZM86 108.69V196a34.06 34.06 0 1 1-12-25.89V56a6 6 0 0 1 4.54-5.82l56-14a6 6 0 1 1 2.92 11.64L86 60.68v35.63l72.54-18.13a6 6 0 1 1 2.92 11.64ZM74 196a22 22 0 1 0-22 22 22 22 0 0 0 22-22Z" /></Svg>;
export default MusicNotesMinus;