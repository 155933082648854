/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserGear = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-gear-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-gear-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148.5 156.53a72 72 0 1 0-89 0 124 124 0 0 0-48.69 35.75 12 12 0 0 0 18.38 15.44C48.54 184.69 75.11 172 104 172c37 0 61.12 19.42 74.81 35.72a12 12 0 1 0 18.38-15.44 124 124 0 0 0-48.69-35.75ZM56 100a48 48 0 1 1 48 48 48.05 48.05 0 0 1-48-48Zm190.53 40-7.11 2.31 4.39 6.05a12 12 0 1 1-19.41 14.11l-4.4-6.06-4.4 6.06a12 12 0 1 1-19.41-14.11l4.39-6.05-7.11-2.31a12 12 0 1 1 7.41-22.83l7.12 2.31V112a12 12 0 0 1 24 0v7.48l7.12-2.31a12 12 0 1 1 7.41 22.83Z" /></Svg>;
export default UserGear;