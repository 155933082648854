/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Hamburger = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hamburger-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hamburger-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.9 152.72-39.7 14.44-35.74-14.3a12 12 0 0 0-8.92 0L108 167.08l-35.54-14.22a12 12 0 0 0-8.56-.14l-44 16a12 12 0 0 0 8.2 22.56l8.12-2.95A44.06 44.06 0 0 0 80 228h96a44.05 44.05 0 0 0 44-44v-2.87l16.1-5.85a12 12 0 0 0-8.2-22.56ZM176 204H80a20 20 0 0 1-20-20v-4.32l7.8-2.84 35.74 14.3a12 12 0 0 0 8.92 0L148 176.92l35.54 14.22a12 12 0 0 0 8.56.14l2.89-1.06A20 20 0 0 1 176 204ZM12 128a12 12 0 0 1 12-12h208a12 12 0 0 1 0 24H24a12 12 0 0 1-12-12Zm36.2-24h159.6a20.36 20.36 0 0 0 16.38-8.29 19.59 19.59 0 0 0 2.88-17.65C216.12 43.88 175.39 20 128 20S39.89 43.87 28.94 78.05a19.56 19.56 0 0 0 2.88 17.65A20.32 20.32 0 0 0 48.2 104ZM128 44c33.7 0 63.61 14.85 74 36H54c10.4-21.15 40.31-36 74-36Z" /></Svg>;
export default Hamburger;