/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShootingStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shooting-star__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shooting-star'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239.35 70.08a13.41 13.41 0 0 0-11.77-9.28l-36.94-2.92-14.21-33.66a13.51 13.51 0 0 0-24.86 0l-14.21 33.66-36.94 2.92a13.39 13.39 0 0 0-7.66 23.58l28.06 23.68-8.56 35.39a13.32 13.32 0 0 0 5.1 13.91 13.51 13.51 0 0 0 15 .69L164 139l31.65 19.06a13.54 13.54 0 0 0 15-.69 13.34 13.34 0 0 0 5.09-13.91l-8.56-35.39 28.06-23.68a13.32 13.32 0 0 0 4.11-14.31ZM193.08 99a8 8 0 0 0-2.61 8l8.28 34.21-30.62-18.41a8 8 0 0 0-8.25 0l-30.62 18.43 8.28-34.23a8 8 0 0 0-2.62-8L108 76.26l35.52-2.81a8 8 0 0 0 6.74-4.87L164 35.91l13.79 32.67a8 8 0 0 0 6.74 4.87l35.53 2.81Zm-105 24.18-58.42 58.48a8 8 0 0 1-11.32-11.32l58.45-58.45a8 8 0 0 1 11.32 11.32Zm10.81 49.87a8 8 0 0 1 0 11.31l-53.23 53.3a8 8 0 0 1-11.32-11.32l53.27-53.26a8 8 0 0 1 11.31 0Zm73-1a8 8 0 0 1 0 11.32l-54.28 54.28a8 8 0 0 1-11.32-11.32l54.29-54.28a8 8 0 0 1 11.36.01Z" /></Svg>;
export default ShootingStar;