/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Webcam = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="webcam-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-webcam-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 104a32 32 0 1 1-32-32 32 32 0 0 1 32 32Zm72 104a8 8 0 0 1-8 8H32a8 8 0 0 1 0-16h88v-16.4a80 80 0 1 1 16 0V200h88a8 8 0 0 1 8 8Zm-104-56a48 48 0 1 0-48-48 48.05 48.05 0 0 0 48 48Z" /></Svg>;
export default Webcam;