/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleMedium = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-medium__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-medium'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 136a8 8 0 0 1-8 8H24a8 8 0 0 1 0-16h8V64h-8a8 8 0 0 1 0-16h16a8 8 0 0 1 6.78 3.74L80 104.91l33.22-53.15A8 8 0 0 1 120 48h16a8 8 0 0 1 0 16h-8v64h8a8 8 0 0 1 0 16h-24a8 8 0 0 1 0-16V83.89l-25.22 40.35a8 8 0 0 1-13.56 0L48 83.89V128a8 8 0 0 1 8 8Zm112-24h64a8 8 0 0 0 0-16h-64a8 8 0 0 0 0 16Zm64 16h-64a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16Zm0 32H80a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16Zm0 32H80a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16Z" /></Svg>;
export default ArticleMedium;