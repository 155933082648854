/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ThermometerHot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="thermometer-hot__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-thermometer-hot'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 153V48a8 8 0 0 0-16 0v105a32 32 0 1 0 16 0Zm-8 47a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm40-66V48a40 40 0 0 0-80 0v86a64 64 0 1 0 80 0Zm-40 98a48 48 0 0 1-27.42-87.4A8 8 0 0 0 96 138V48a24 24 0 0 1 48 0v90a8 8 0 0 0 3.42 6.56A48 48 0 0 1 120 232Zm57.3-151.23a8 8 0 0 1 2.33-11.07c15-9.79 26.87-4.75 35.51-1.06 7.86 3.36 12.62 5.36 20.49.25a8 8 0 0 1 8.74 13.41C237.88 86.53 232 88 226.69 88c-7 0-12.92-2.54-17.83-4.63C201 80 196.24 78 188.37 83.11a8 8 0 0 1-11.07-2.34Zm69.4 22.46a8 8 0 0 1-2.33 11.07c-6.49 4.23-12.37 5.7-17.68 5.7-7 0-12.92-2.54-17.83-4.63-7.87-3.36-12.62-5.38-20.49-.25a8 8 0 0 1-8.74-13.41c15-9.79 26.87-4.75 35.51-1.06 7.87 3.36 12.62 5.39 20.49.25a8 8 0 0 1 11.07 2.33Z" /></Svg>;
export default ThermometerHot;