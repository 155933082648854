/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseRightClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-right-click-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-right-click-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 16h-32a64.07 64.07 0 0 0-64 64v96a64.07 64.07 0 0 0 64 64h32a64.07 64.07 0 0 0 64-64V80a64.07 64.07 0 0 0-64-64Zm-32 16h16v72H64V80a48.05 48.05 0 0 1 48-48Zm32 192h-32a48.05 48.05 0 0 1-48-48v-56h128v56a48.05 48.05 0 0 1-48 48Z" /></Svg>;
export default MouseRightClick;