/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ear = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ear-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ear-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 104a12 12 0 0 1-24 0 68 68 0 0 0-136 0c0 25 7.58 32.3 16.35 40.76S96 163.71 96 188a32 32 0 0 0 32 32c9 0 16.19-3.7 22.75-11.64a12 12 0 0 1 18.5 15.28C158.09 237.15 144.21 244 128 244a56.06 56.06 0 0 1-56-56c0-14.09-4.63-18.56-12.31-26C49.13 151.86 36 139.19 36 104a92 92 0 0 1 184 0Zm-40.13 53.61a12 12 0 0 0-16.4 4.38 4 4 0 0 1-7.47-2c0-7.61 3.65-12.86 9.6-20.8C172 130.65 180 120 180 104a52 52 0 0 0-104 0 12 12 0 0 0 24 0 28 28 0 0 1 56 0c0 7.61-3.65 12.86-9.6 20.8C140 133.35 132 144 132 160a28 28 0 0 0 52.25 14 12 12 0 0 0-4.38-16.39Z" /></Svg>;
export default Ear;