/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cricket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cricket-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cricket-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m246.15 78.54-52.69-52.69a20 20 0 0 0-28.28 0L57.86 133.17a20 20 0 0 0 0 28.28l17.86 17.86-44.2 44.2a12 12 0 0 0 17 17l44.2-44.21 17.86 17.86a20 20 0 0 0 28.28 0l107.29-107.34a20 20 0 0 0 0-28.28Zm-121.46 115.8-15-15 26.83-26.83a12 12 0 0 0-17-17l-26.83 26.83-15-15L109 116h47v47ZM180 139v-35a12 12 0 0 0-12-12h-35l46.34-46.34 47 47ZM60 92a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm0-40a8 8 0 1 1-8 8 8 8 0 0 1 8-8Z" /></Svg>;
export default Cricket;