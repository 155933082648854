/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bird = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bird-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bird-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 72a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm68 8a12 12 0 0 1-5.34 10L220 102.42V120a108.12 108.12 0 0 1-108 108H24a20 20 0 0 1-15.59-32.5l.15-.18L92 95.18V76.89c0-35.61 28.57-64.72 63.69-64.89h.31a63.94 63.94 0 0 1 60.58 43.29L238.66 70A12 12 0 0 1 244 80Zm-33.63 0-10.69-7.13a12 12 0 0 1-5-7A40 40 0 0 0 156 36h-.19c-21.95.11-39.8 18.45-39.8 40.89v22.63a12 12 0 0 1-2.79 7.69L32.57 204h20.48l69.74-83.68a12 12 0 1 1 18.43 15.36L84.29 204H112a84.09 84.09 0 0 0 84-84V96a12 12 0 0 1 5.35-10Z" /></Svg>;
export default Bird;