/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tabs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tabs-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tabs-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M255.49 164.55v-.06a.29.29 0 0 0 0-.09L233.2 90.25A19.87 19.87 0 0 0 214.05 76H204a12 12 0 0 0 0 24h7.07l16.8 56h-30.94L177.2 90.25A19.87 19.87 0 0 0 158.05 76H148a12 12 0 0 0 0 24h7.07l16.8 56h-30.94L121.2 90.25A19.87 19.87 0 0 0 102.05 76H42a19.87 19.87 0 0 0-19.2 14.25L.55 164.4a.29.29 0 0 0 0 .09v.06a5.68 5.68 0 0 0-.16.62A12 12 0 0 0 12 180h232a12 12 0 0 0 11.49-15.45ZM44.93 100h54.14l16.8 56H28.13Z" /></Svg>;
export default Tabs;