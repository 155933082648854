/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Log = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="log-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-log-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 136c0 35.35-17.91 64-40 64s-40-28.65-40-64 17.91-64 40-64 40 28.65 40 64Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M212 136a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm36 0c0 40.37-21.08 72-48 72H56c-26.92 0-48-31.63-48-72s21.08-72 48-72h36.69l37.65-37.66A8 8 0 0 1 136 24h32a8 8 0 0 1 0 16h-28.69l-24 24H200c26.92 0 48 31.63 48 72ZM56 192h113.51a73.46 73.46 0 0 1-12.67-24H80a8 8 0 0 1 0-16h73.16a110.63 110.63 0 0 1-1.16-16c0-22.86 6.76-42.9 17.51-56H56c-12.47 0-23.55 13.26-28.8 32H104a8 8 0 0 1 0 16H24.35q-.34 3.93-.35 8c0 30.36 14.65 56 32 56Zm176-56c0-30.36-14.65-56-32-56s-32 25.64-32 56 14.65 56 32 56 32-25.64 32-56Z" /></Svg>;
export default Log;