/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SelectionInverse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="selection-inverse-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-selection-inverse-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 216a8 8 0 0 1-8 8h-32a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8ZM40 152a8 8 0 0 0 8-8v-32a8 8 0 0 0-16 0v32a8 8 0 0 0 8 8Zm32 56H48v-24a8 8 0 0 0-16 0v24a16 16 0 0 0 16 16h24a8 8 0 0 0 0-16ZM224 48a16 16 0 0 0-16-16H48a15.87 15.87 0 0 0-10.66 4.11 7.67 7.67 0 0 0-1.23 1.23A15.87 15.87 0 0 0 32 48v24a8 8 0 0 0 16 0V59.31L196.69 208H184a8 8 0 0 0 0 16h24a15.91 15.91 0 0 0 10.66-4.1 7.35 7.35 0 0 0 .65-.59 6 6 0 0 0 .58-.65A15.87 15.87 0 0 0 224 208Z" /></Svg>;
export default SelectionInverse;