/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boules = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boules-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boules-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 128c0 56.63-47.38 104-104 104a103.67 103.67 0 0 1-31.52-4.89 4 4 0 0 1-1.62-6.65l125.6-125.61a4 4 0 0 1 6.65 1.62A103.69 103.69 0 0 1 232 128Zm-16.16-55.61a103.16 103.16 0 0 0-6.06-8.56 4 4 0 0 0-6-.33L63.5 203.82a4 4 0 0 0 .33 6 103.16 103.16 0 0 0 8.56 6.06 4 4 0 0 0 5-.54L215.3 77.39a4 4 0 0 0 .54-5Zm-23.67-26.17a103.16 103.16 0 0 0-8.56-6.06 4 4 0 0 0-5 .54L40.7 178.62a4 4 0 0 0-.54 5 103.16 103.16 0 0 0 6.06 8.56 4 4 0 0 0 6 .33L192.5 52.18a4 4 0 0 0-.33-5.96Zm-32.64-17.33A103.67 103.67 0 0 0 128 24C71.38 24 24 71.37 24 128a103.69 103.69 0 0 0 4.89 31.53 4 4 0 0 0 6.65 1.62L161.15 35.54a4 4 0 0 0-1.62-6.65Z" /></Svg>;
export default Boules;