/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreasureChest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="treasure-chest-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-treasure-chest-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 44H72a52.06 52.06 0 0 0-52 52v96a12 12 0 0 0 12 12h192a12 12 0 0 0 12-12V96a52.06 52.06 0 0 0-52-52Zm44 52v12h-40V52.19A44.06 44.06 0 0 1 228 96Zm-88 44h-24v-40h24Zm-28 8h32a4 4 0 0 0 4-4v-28h32v80H76v-80h32v28a4 4 0 0 0 4 4Zm36-40V96a4 4 0 0 0-4-4h-32a4 4 0 0 0-4 4v12H76V52h104v56ZM68 52.19V108H28V96a44.06 44.06 0 0 1 40-43.81ZM28 192v-76h40v80H32a4 4 0 0 1-4-4Zm196 4h-36v-80h40v76a4 4 0 0 1-4 4Z" /></Svg>;
export default TreasureChest;