/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Basket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="basket-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-basket-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M132 120v56a4 4 0 0 1-8 0v-56a4 4 0 0 1 8 0Zm49.2-4a4 4 0 0 0-4.38 3.58l-5.6 56a4 4 0 0 0 3.58 4.42h.41a4 4 0 0 0 4-3.6l5.6-56a4 4 0 0 0-3.61-4.4Zm-106.4 0a4 4 0 0 0-3.58 4.38l5.6 56a4 4 0 0 0 4 3.6h.41a4 4 0 0 0 3.58-4.38l-5.6-56a4 4 0 0 0-4.41-3.6ZM236 88.53l-15.11 113.06A12 12 0 0 1 209 212H47a12 12 0 0 1-11.89-10.41L20 88.53a4 4 0 0 1 1-3.16A4 4 0 0 1 24 84h46.18L125 21.37a4 4 0 0 1 6 0L185.82 84H232a4 4 0 0 1 3 1.37 4 4 0 0 1 1 3.16ZM80.82 84h94.36L128 30.07Zm146.61 8H28.57L43 200.53a4 4 0 0 0 4 3.47h162a4 4 0 0 0 4-3.47Z" /></Svg>;
export default Basket;