/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlipHorizontal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flip-horizontal-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flip-horizontal-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M104 20.41a19.83 19.83 0 0 0-22.4 11.81c-.07.15-.13.31-.19.46L21.47 192.49A20 20 0 0 0 40 220h60a20 20 0 0 0 20-20V40a19.83 19.83 0 0 0-16-19.59ZM96 196H45.79L96 62.09Zm138.53-3.51L174.61 32.68c-.06-.15-.12-.31-.19-.46A20 20 0 0 0 136 40v160a20 20 0 0 0 20 20h60a20 20 0 0 0 18.54-27.51ZM160 196V62.09L210.21 196Z" /></Svg>;
export default FlipHorizontal;