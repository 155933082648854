/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerHigh = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-high-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-high-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M154.64 26.61a6 6 0 0 0-6.32.65L77.94 82H32a14 14 0 0 0-14 14v64a14 14 0 0 0 14 14h45.94l70.38 54.74A6 6 0 0 0 158 224V32a6 6 0 0 0-3.36-5.39ZM30 160V96a2 2 0 0 1 2-2h42v68H32a2 2 0 0 1-2-2Zm116 51.73-60-46.66V90.93l60-46.66Zm50.53-108.85a38 38 0 0 1 0 50.24 6 6 0 1 1-9-7.94 26 26 0 0 0 0-34.37 6 6 0 0 1 9-7.93ZM246 128a77.86 77.86 0 0 1-19.86 52 6 6 0 1 1-8.94-8 66 66 0 0 0 0-88 6 6 0 1 1 8.94-8A77.86 77.86 0 0 1 246 128Z" /></Svg>;
export default SpeakerHigh;