/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GearSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gear-six-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gear-six-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 76a52 52 0 1 0 52 52 52.06 52.06 0 0 0-52-52Zm0 80a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm113.86-49.57a12 12 0 0 0-5.86-8.09l-27.79-15.85-.11-31.31a12 12 0 0 0-4.25-9.12 116 116 0 0 0-38-21.41 12 12 0 0 0-9.68.89L128 37.27 99.83 21.53a12 12 0 0 0-9.7-.9 116.06 116.06 0 0 0-38 21.47 12 12 0 0 0-4.24 9.1l-.14 31.34L20 98.35a12 12 0 0 0-5.85 8.11 110.7 110.7 0 0 0 0 43.11 12 12 0 0 0 5.85 8.09l27.82 15.85.11 31.31a12 12 0 0 0 4.25 9.12 116 116 0 0 0 38 21.41 12 12 0 0 0 9.68-.89L128 218.73l28.14 15.74a12 12 0 0 0 9.7.9 116.06 116.06 0 0 0 38-21.47 12 12 0 0 0 4.24-9.1l.14-31.34 27.81-15.81a12 12 0 0 0 5.85-8.11 110.7 110.7 0 0 0-.02-43.11Zm-22.63 33.18-26.88 15.28a11.94 11.94 0 0 0-4.55 4.59c-.54 1-1.11 1.93-1.7 2.88a12 12 0 0 0-1.83 6.31l-.14 30.33a91.83 91.83 0 0 1-21.07 11.87l-27.15-15.19a12 12 0 0 0-5.86-1.53h-3.73a12.08 12.08 0 0 0-6.14 1.51L93 210.82A92.27 92.27 0 0 1 71.88 199l-.11-30.24a12 12 0 0 0-1.83-6.32c-.58-.94-1.16-1.91-1.7-2.88A11.92 11.92 0 0 0 63.7 155l-26.9-15.37a86.53 86.53 0 0 1 0-23.24l26.88-15.28a12 12 0 0 0 4.55-4.58c.54-1 1.11-1.94 1.7-2.89a12 12 0 0 0 1.83-6.31L71.87 57a91.83 91.83 0 0 1 21.07-11.83l27.15 15.19a11.92 11.92 0 0 0 6.15 1.52h3.44a12.08 12.08 0 0 0 6.14-1.51L163 45.18A92.27 92.27 0 0 1 184.12 57l.11 30.24a12 12 0 0 0 1.83 6.32c.58.94 1.16 1.91 1.7 2.88a11.92 11.92 0 0 0 4.54 4.56l26.9 15.33a86.53 86.53 0 0 1 .03 23.28Z" /></Svg>;
export default GearSix;