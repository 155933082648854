/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Onigiri = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="onigiri-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-onigiri-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m228.11 148.63-55.88-96-.06-.09a52 52 0 0 0-88.34 0l-.06.09-55.88 96A52 52 0 0 0 72.09 228h111.82a52 52 0 0 0 44.2-79.37ZM164 220H92v-52a4 4 0 0 1 4-4h64a4 4 0 0 1 4 4Zm58.36-22.61A43.43 43.43 0 0 1 183.91 220H172v-52a12 12 0 0 0-12-12H96a12 12 0 0 0-12 12v52H72.09a44 44 0 0 1-37.37-67.2.31.31 0 0 1 .05-.1l55.88-95.95a44 44 0 0 1 74.7 0l55.88 95.95a.31.31 0 0 1 .05.1 43.43 43.43 0 0 1 1.07 44.59Z" /></Svg>;
export default Onigiri;