/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Speedometer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speedometer-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speedometer-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m119.51 143.51 88-88a12 12 0 1 1 17 17l-88 88a12 12 0 1 1-17-17Zm14.23-43.2a12 12 0 1 0 2.62-23.85A75.15 75.15 0 0 0 128 76a76.08 76.08 0 0 0-76 76 12 12 0 0 0 24 0 52.06 52.06 0 0 1 52-52 54.75 54.75 0 0 1 5.74.31Zm101.54 7.5a12 12 0 0 0-22.19 9.19 92.47 92.47 0 0 1 2.58 63H40.34A92.23 92.23 0 0 1 128 60h.84a91.43 91.43 0 0 1 34.2 6.91 12 12 0 0 0 9.14-22.19A116.07 116.07 0 0 0 18.57 190.58 20.09 20.09 0 0 0 37.46 204h181.07a20.06 20.06 0 0 0 18.88-13.38 116.39 116.39 0 0 0-2.13-82.81Z" /></Svg>;
export default Speedometer;