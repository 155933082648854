/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserCircleCheck = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-circle-check-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-circle-check-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.56 110.68a103.92 103.92 0 1 1-85.24-85.24 8 8 0 0 1-2.64 15.78A88.07 88.07 0 0 0 40 128a87.62 87.62 0 0 0 22.24 58.41A79.71 79.71 0 0 1 84 165.1a4 4 0 0 1 4.84.32 59.8 59.8 0 0 0 78.26 0 4 4 0 0 1 4.84-.32 79.86 79.86 0 0 1 21.79 21.31A87.62 87.62 0 0 0 216 128a88.85 88.85 0 0 0-1.22-14.68 8 8 0 1 1 15.78-2.64ZM84 120a44 44 0 1 0 44-44 44 44 0 0 0-44 44Zm153.66-85.66a8 8 0 0 0-11.32 0L200 60.69l-10.34-10.35a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0 0-11.32Z" /></Svg>;
export default UserCircleCheck;