/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RedditLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="reddit-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-reddit-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 104a36 36 0 0 0-57.4-28.93 145.56 145.56 0 0 0-52.54-14.45l3.8-22.81 15.67 2.61a24 24 0 1 0 3.95-23.67L138 12.16A12 12 0 0 0 124.16 22l-6.38 38.31C97.48 61.62 78 66.7 61.4 75.07a36 36 0 0 0-47.52 53.68A62.87 62.87 0 0 0 12 144c0 23.29 12.65 44.87 35.63 60.78C69.26 219.75 97.8 228 128 228s58.74-8.25 80.37-23.22C231.35 188.87 244 167.29 244 144a62.87 62.87 0 0 0-1.88-15.25A36.16 36.16 0 0 0 252 104Zm-29.91 10.34a12 12 0 0 0-5 15A39.61 39.61 0 0 1 220 144c0 15.18-9 29.75-25.29 41.05C177.05 197.27 153.36 204 128 204s-49-6.73-66.71-18.95C45 173.75 36 159.18 36 144a39.74 39.74 0 0 1 2.84-14.64 12 12 0 0 0-4.93-15A12 12 0 1 1 49.71 97a12 12 0 0 0 15.94 3.18l.22-.13C82.94 89.68 105 84 128 84s45 5.69 62.09 16l.22.13A12 12 0 0 0 206.29 97a11.88 11.88 0 0 1 9.71-5 12 12 0 0 1 6.09 22.34ZM88 148a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm100-20a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm-20.52 40.52a12 12 0 0 1-8 15 111.88 111.88 0 0 1-63 0 12 12 0 0 1 7-23 88.12 88.12 0 0 0 49 0 12 12 0 0 1 15 8Z" /></Svg>;
export default RedditLogo;