/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vault = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vault-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vault-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 48H40a8 8 0 0 0-8 8v136a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8V56a8 8 0 0 0-8-8Zm-64 120a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 40H40a16 16 0 0 0-16 16v136a16 16 0 0 0 16 16h16v16a8 8 0 0 0 16 0v-16h112v16a8 8 0 0 0 16 0v-16h16a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm0 152H40V56h176v64h-16.68a48 48 0 1 0 0 16H216v56Zm-50.16-72a16 16 0 1 0 0 16H183a32 32 0 1 1 0-16Z" /></Svg>;
export default Vault;