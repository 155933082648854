/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ToteSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tote-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tote-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M239 66.75A20.12 20.12 0 0 0 224 60h-44.17a52 52 0 0 0-103.66 0H32.08a20 20 0 0 0-19.94 22.35l14.25 120A20.06 20.06 0 0 0 46.33 220h163.34a20.06 20.06 0 0 0 19.94-17.65l14.25-120a20 20 0 0 0-4.86-15.6ZM128 36a28 28 0 0 1 27.71 24h-55.42A28 28 0 0 1 128 36Zm78.19 160H49.81L36.5 84h183Z" /></Svg>;
export default ToteSimple;