/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextAlignCenter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-align-center-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-align-center-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-32 152H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Zm16-32H64a8 8 0 0 1 0-16h128a8 8 0 0 1 0 16ZM72 112a8 8 0 0 1 8-8h96a8 8 0 0 1 0 16H80a8 8 0 0 1-8-8Zm120-24H64a8 8 0 0 1 0-16h128a8 8 0 0 1 0 16Z" /></Svg>;
export default TextAlignCenter;