/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HouseLine = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="house-line-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-house-line-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 210h-18v-78.83l5.76 5.76a6 6 0 0 0 8.48-8.49L137.9 30.09a14 14 0 0 0-19.8 0l-98.34 98.35a6 6 0 0 0 8.48 8.49l5.76-5.76V210H16a6 6 0 0 0 0 12h224a6 6 0 0 0 0-12ZM46 119.17l80.58-80.59a2 2 0 0 1 2.84 0L210 119.17V210h-52v-58a6 6 0 0 0-6-6h-48a6 6 0 0 0-6 6v58H46ZM146 210h-36v-52h36Z" /></Svg>;
export default HouseLine;