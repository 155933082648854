/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Translate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="translate-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-translate-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m245.37 213.32-56-112a6 6 0 0 0-10.74 0l-22.3 44.6A90 90 0 0 1 105 127.19 101.73 101.73 0 0 0 133.82 62H160a6 6 0 0 0 0-12h-58V32a6 6 0 0 0-12 0v18H32a6 6 0 0 0 0 12h89.79A89.71 89.71 0 0 1 96 119.23 89.81 89.81 0 0 1 75.11 86a6 6 0 1 0-11.31 4A101.66 101.66 0 0 0 87 127.2 89.56 89.56 0 0 1 32 146a6 6 0 0 0 0 12 101.55 101.55 0 0 0 64-22.63 102.11 102.11 0 0 0 54.53 22.17l-27.89 55.78a6 6 0 0 0 10.74 5.36L147.71 190h72.58l14.34 28.68A6 6 0 0 0 240 222a5.87 5.87 0 0 0 2.68-.64 6 6 0 0 0 2.69-8.04ZM153.71 178 184 117.42 214.29 178Z" /></Svg>;
export default Translate;