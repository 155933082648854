/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stamp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stamp-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stamp-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 224a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h176a12 12 0 0 1 12 12Zm0-80v32a20 20 0 0 1-20 20H48a20 20 0 0 1-20-20v-32a20 20 0 0 1 20-20h48L83.55 54.33A36 36 0 0 1 119 12h18a36 36 0 0 1 35.44 42.33L160 124h48a20 20 0 0 1 20 20ZM107.17 50.11l13.2 73.89h15.26l13.2-73.89A12 12 0 0 0 137 36h-18a12 12 0 0 0-11.82 14.11ZM204 148H52v24h152Z" /></Svg>;
export default Stamp;