/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Play = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="play-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-play-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M231.36 116.19 87.28 28.06a14 14 0 0 0-14.18-.27A13.69 13.69 0 0 0 66 39.87v176.26a13.69 13.69 0 0 0 7.1 12.08 14 14 0 0 0 14.18-.27l144.08-88.13a13.82 13.82 0 0 0 0-23.62Zm-6.26 13.38L81 217.7a2 2 0 0 1-2.06 0 1.78 1.78 0 0 1-1-1.61V39.87a1.78 1.78 0 0 1 1-1.61A2.06 2.06 0 0 1 80 38a2 2 0 0 1 1 .31l144.1 88.12a1.82 1.82 0 0 1 0 3.14Z" /></Svg>;
export default Play;