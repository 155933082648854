/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreasureChest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="treasure-chest-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-treasure-chest-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 42H72a54.06 54.06 0 0 0-54 54v96a14 14 0 0 0 14 14h192a14 14 0 0 0 14-14V96a54.06 54.06 0 0 0-54-54Zm42 54v10h-36V54.44A42.05 42.05 0 0 1 226 96Zm-88 42h-20v-36h20Zm-26 12h32a6 6 0 0 0 6-6v-26h28v76H78v-76h28v26a6 6 0 0 0 6 6Zm38-44V96a6 6 0 0 0-6-6h-32a6 6 0 0 0-6 6v10H78V54h100v52ZM66 54.44V106H30V96a42.05 42.05 0 0 1 36-41.56ZM30 192v-74h36v76H32a2 2 0 0 1-2-2Zm194 2h-34v-76h36v74a2 2 0 0 1-2 2Z" /></Svg>;
export default TreasureChest;