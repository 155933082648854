/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Parallelogram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="parallelogram-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-parallelogram-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m246.58 62.57-64.8 144a16 16 0 0 1-14.59 9.43H24a16 16 0 0 1-14.58-22.57l64.8-144A16 16 0 0 1 88.81 40H232a16 16 0 0 1 14.59 22.57Z" /></Svg>;
export default Parallelogram;