/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitFork = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-fork__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-fork'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 64a32 32 0 1 0-40 31v17a8 8 0 0 1-8 8H80a8 8 0 0 1-8-8V95a32 32 0 1 0-16 0v17a24 24 0 0 0 24 24h40v25a32 32 0 1 0 16 0v-25h40a24 24 0 0 0 24-24V95a32.06 32.06 0 0 0 24-31ZM48 64a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm96 128a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm48-112a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default GitFork;