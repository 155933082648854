/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tilde = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tilde-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tilde-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M225.35 133.1c-15.22 18.93-30.43 29-46.5 30.65a46.71 46.71 0 0 1-4.85.25c-20.81 0-38.16-14.13-53.59-26.7-14.24-11.6-27.68-22.54-40.75-21.18-9.26 1-19.46 8.32-30.32 21.82a12 12 0 0 1-18.7-15C45.87 104 61.08 94 77.15 92.25c23-2.42 41.82 12.92 58.43 26.45 14.24 11.6 27.68 22.54 40.75 21.18 9.26-1 19.46-8.32 30.32-21.82a12 12 0 1 1 18.7 15Z" /></Svg>;
export default Tilde;