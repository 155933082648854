/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Desktop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="desktop-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-desktop-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 44H48a20 20 0 0 0-20 20v112a20 20 0 0 0 20 20h76v24H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8h-28v-24h76a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20ZM48 52h160a12 12 0 0 1 12 12v84H36V64a12 12 0 0 1 12-12Zm160 136H48a12 12 0 0 1-12-12v-20h184v20a12 12 0 0 1-12 12Z" /></Svg>;
export default Desktop;