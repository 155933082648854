/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PushPinSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="push-pin-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-push-pin-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m235.33 104-47.62 47.78a4 4 0 0 1-5.79-.14L108 70.27a4 4 0 0 1 .13-5.51L152 20.7a16 16 0 0 1 22.63 0l60.69 60.68a16 16 0 0 1 .01 22.62ZM53.92 34.62a8 8 0 0 0-12 .21 8.18 8.18 0 0 0 .37 10.75l25.03 27.57C58.26 75.09 48.2 79.37 38 87.63a16 16 0 0 0-1.29 23.78L85 159.71l-42.45 42.43a8.17 8.17 0 0 0-.6 11.09 8 8 0 0 0 11.71.43L96.29 171l48.29 48.29A16 16 0 0 0 155.9 224h1.13a15.93 15.93 0 0 0 11.64-6.33 88.62 88.62 0 0 0 11.64-20.2l21.77 23.95a8 8 0 1 0 11.84-10.76Z" /></Svg>;
export default PushPinSlash;