/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShieldStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shield-star-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shield-star-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M173.57 117.77a6 6 0 0 1-3.34 7.8l-32.73 13.09 19.3 25.74a6 6 0 0 1-9.6 7.2L128 146l-19.2 25.6a6 6 0 1 1-9.6-7.2l19.3-25.74-32.73-13.09a6 6 0 0 1 4.46-11.14L122 127.14V96a6 6 0 0 1 12 0v31.14l31.77-12.71a6 6 0 0 1 7.8 3.34ZM222 56v56c0 51.94-25.12 83.4-46.2 100.64-22.73 18.6-45.27 24.89-46.22 25.15a6 6 0 0 1-3.16 0c-1-.26-23.49-6.55-46.22-25.15C59.12 195.4 34 163.94 34 112V56a14 14 0 0 1 14-14h160a14 14 0 0 1 14 14Zm-12 0a2 2 0 0 0-2-2H48a2 2 0 0 0-2 2v56c0 37.75 13.94 68.39 41.44 91.06A130.94 130.94 0 0 0 128 225.72a131.17 131.17 0 0 0 40.56-22.66C196.06 180.39 210 149.75 210 112Z" /></Svg>;
export default ShieldStar;