/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaperPlaneTilt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paper-plane-tilt-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paper-plane-tilt-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224.47 31.52a11.87 11.87 0 0 0-11.82-3L20.74 86.67a12 12 0 0 0-1.91 22.38L105 151l41.92 86.15a11.88 11.88 0 0 0 10.82 6.85h1a11.89 11.89 0 0 0 10.52-8.63l58.21-192v-.08a11.85 11.85 0 0 0-3-11.77Zm-4.62 9.54-58.23 192a4 4 0 0 1-7.48.59l-41.3-84.86 50-50a4 4 0 1 0-5.66-5.66l-50 50-84.9-41.31a3.88 3.88 0 0 1-2.27-4 3.93 3.93 0 0 1 3-3.54L214.9 36.16A3.93 3.93 0 0 1 216 36a4 4 0 0 1 2.79 1.19 3.93 3.93 0 0 1 1.06 3.87Z" /></Svg>;
export default PaperPlaneTilt;