/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SketchLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sketch-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sketch-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m249 96.1-56-64a12 12 0 0 0-9-4.1H72a12 12 0 0 0-9 4.1l-56 64a12 12 0 0 0 .26 16.09l112 120a12 12 0 0 0 17.54 0l112-120a12 12 0 0 0 .2-16.09ZM213.55 92H182l-30-40h26.55ZM71.88 116l21.19 53-49.46-53Zm86.4 0L128 191.69 97.72 116ZM104 92l24-32 24 32Zm80.12 24h28.27l-49.46 53ZM77.45 52H104L74 92H42.45Z" /></Svg>;
export default SketchLogo;