/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Visor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="visor-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-visor-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 127.15A64 64 0 0 1 184 192c-15.48 0-29.68-9.35-40.75-18.5a23.91 23.91 0 0 0-30.45-.05c-11.81 9.79-27.19 19.26-43.89 18.48A64 64 0 0 1 72 64h111.05c35.17 0 64.49 28 64.95 63.15Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M183.05 56H72A72 72 0 0 0 .08 131.4c1.69 36.69 31.76 66.79 68.45 68.52 15.85.74 32-5.9 49.38-20.3a15.88 15.88 0 0 1 20.24 0C148.72 188.39 165 200 184 200a72 72 0 0 0 72-72.95C255.49 87.87 222.76 56 183.05 56Zm40.81 111.34A55.63 55.63 0 0 1 184 184c-13.88 0-27-9.51-35.65-16.67a31.91 31.91 0 0 0-40.65 0C93.52 179 81 184.49 69.28 183.94a56.36 56.36 0 0 1-53.22-53.28A56 56 0 0 1 72 72h111.05c31 0 56.55 24.79 56.95 55.25a55.66 55.66 0 0 1-16.14 40.09ZM184 96a8 8 0 0 1-8 8H80a8 8 0 0 1 0-16h96a8 8 0 0 1 8 8Z" /></Svg>;
export default Visor;