/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatDots = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-dots-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-dots-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M138 128a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-54-10a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm88 0a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm58-54v128a14 14 0 0 1-14 14H82.23l-33.16 28.64-.06.05A13.87 13.87 0 0 1 40 238a14.11 14.11 0 0 1-5.95-1.33A13.88 13.88 0 0 1 26 224V64a14 14 0 0 1 14-14h176a14 14 0 0 1 14 14Zm-12 0a2 2 0 0 0-2-2H40a2 2 0 0 0-2 2v160a2 2 0 0 0 3.26 1.55l34.82-30.08A6 6 0 0 1 80 194h136a2 2 0 0 0 2-2Z" /></Svg>;
export default ChatDots;