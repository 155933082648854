/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlipVertical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flip-vertical-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flip-vertical-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56 120h160a20 20 0 0 0 7.79-38.42l-.46-.19L63.51 21.47A20 20 0 0 0 36 40v60a20 20 0 0 0 20 20Zm4-74.21L193.91 96H60ZM216 136H56a20 20 0 0 0-20 20v60a20 20 0 0 0 27.51 18.54l159.81-59.92.46-.19A20 20 0 0 0 216 136ZM60 210.22V160h133.91Z" /></Svg>;
export default FlipVertical;