/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FastForwardCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fast-forward-circle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fast-forward-circle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90Zm63.6-94.8-48-36A6 6 0 0 0 134 92v30L87.6 87.2A6 6 0 0 0 78 92v72a6 6 0 0 0 9.6 4.8L134 134v30a6 6 0 0 0 9.6 4.8l48-36a6 6 0 0 0 0-9.6ZM90 152v-48l32 24Zm56 0v-48l32 24Z" /></Svg>;
export default FastForwardCircle;