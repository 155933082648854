/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandTap = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-tap-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-tap-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 156v36c0 13.85-1.63 26.52-4.58 35.68a12 12 0 1 1-22.84-7.36c2.14-6.65 3.42-17.24 3.42-28.32v-36a8 8 0 0 0-16 0v4a12 12 0 0 1-24 0v-20a8 8 0 0 0-16 0v12a12 12 0 0 1-24 0V84a8 8 0 0 0-16 0v108a12 12 0 0 1-22.18 6.34l-18.68-30-.21-.34A8 8 0 0 0 53 175.92l25.27 41.88a12 12 0 0 1-20.56 12.39l-25.31-42-.12-.2A32 32 0 0 1 84 150.83V84a32 32 0 0 1 64 0v25a32 32 0 0 1 36.78 17A32 32 0 0 1 228 156ZM56 96a12 12 0 0 0 12-12 48 48 0 0 1 96 0 12 12 0 0 0 24 0 72 72 0 0 0-144 0 12 12 0 0 0 12 12Z" /></Svg>;
export default HandTap;