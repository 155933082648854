/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Terminal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="terminal-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-terminal-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 80v112H40V64h160a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m117.31 134-72 64a8 8 0 1 1-10.63-12L100 128 34.69 70a8 8 0 1 1 10.63-12l72 64a8 8 0 0 1 0 12ZM216 184h-96a8 8 0 0 0 0 16h96a8 8 0 0 0 0-16Z" /></Svg>;
export default Terminal;