/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeSimpleX = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-simple-x-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-simple-x-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M218.83 170.83 197.66 192l21.17 21.17a4 4 0 0 1-5.66 5.66L192 197.66l-21.17 21.17a4 4 0 0 1-5.66-5.66L186.34 192l-21.17-21.17a4 4 0 0 1 5.66-5.66L192 186.34l21.17-21.17a4 4 0 0 1 5.66 5.66ZM228 128a4 4 0 0 1-4 4H92.08c1.61 58.53 38.05 88.58 38.42 88.88A4 4 0 0 1 128 228a100 100 0 0 1 0-200 100.11 100.11 0 0 1 100 100Zm-89.54-91.4c11.74 12.88 32.33 41.85 33.48 87.4h48a92.14 92.14 0 0 0-81.48-87.4ZM92.07 124h71.86c-1.36-49.48-27.61-78.6-35.93-86.61-8.32 8-34.57 37.13-35.93 86.61Zm-56 0h48c1.15-45.55 21.74-74.52 33.48-87.4A92.14 92.14 0 0 0 36.09 124Zm48 8h-48a92.14 92.14 0 0 0 81.45 87.4c-11.72-12.88-32.31-41.85-33.46-87.4Z" /></Svg>;
export default GlobeSimpleX;