/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Boules = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="boules-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-boules-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm50.28 40.75L60.75 178.28a84.87 84.87 0 0 1-8.36-13.7L164.58 52.39a84.87 84.87 0 0 1 13.7 8.36Zm17 17a84.87 84.87 0 0 1 8.36 13.7L91.42 203.61a84.87 84.87 0 0 1-13.7-8.36ZM128 44a84.53 84.53 0 0 1 10.37.66l-93.71 93.71A83.87 83.87 0 0 1 128 44Zm0 168a84.53 84.53 0 0 1-10.37-.66l93.71-93.71A83.87 83.87 0 0 1 128 212Z" /></Svg>;
export default Boules;