/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LadderSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ladder-simple-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ladder-simple-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 24a8 8 0 0 0-8 8v8H72v-8a8 8 0 0 0-16 0v192a8 8 0 0 0 16 0v-8h112v8a8 8 0 0 0 16 0V32a8 8 0 0 0-8-8Zm-16 160H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Zm0-48H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Zm0-48H80a8 8 0 0 1 0-16h96a8 8 0 0 1 0 16Z" /></Svg>;
export default LadderSimple;