/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seatbelt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seatbelt-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seatbelt-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 112a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44Zm72 104H77.16l120.13-106a8.17 8.17 0 0 0 1.1-1.19 8.07 8.07 0 0 0 1.61-5.08A8 8 0 0 0 186.71 98l-24.54 21.65A80 80 0 0 0 49 179.25a8.33 8.33 0 0 0-.1 1.1l-.9 43.48a8 8 0 0 0 8 8.17h144a8 8 0 0 0 0-16Zm-11.88-73a8 8 0 0 0-6.25 1.94L119.47 200H200a8 8 0 0 0 8-8 79.6 79.6 0 0 0-14.27-45.62 8 8 0 0 0-5.61-3.38Z" /></Svg>;
export default Seatbelt;