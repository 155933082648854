/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-heart-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-heart-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M34 64a6 6 0 0 1 6-6h176a6 6 0 0 1 0 12H40a6 6 0 0 1-6-6Zm6 70h64a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12Zm80 52H40a6 6 0 0 0 0 12h80a6 6 0 0 0 0-12Zm126-42c0 35.3-49.22 60.32-51.32 61.37a6 6 0 0 1-5.36 0C187.22 204.32 138 179.3 138 144a30 30 0 0 1 54-18 30 30 0 0 1 54 18Zm-12 0a18 18 0 0 0-36 0 6 6 0 0 1-12 0 18 18 0 0 0-36 0c0 14.15 11.78 27 21.67 35.25A138.82 138.82 0 0 0 192 193.19a138 138 0 0 0 20.33-13.94C222.22 171 234 158.15 234 144Z" /></Svg>;
export default ListHeart;