/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bed-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bed-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 74H30V48a6 6 0 0 0-12 0v160a6 6 0 0 0 12 0v-34h212v34a6 6 0 0 0 12 0v-96a38 38 0 0 0-38-38ZM30 86h76v76H30Zm88 76V86h98a26 26 0 0 1 26 26v50Z" /></Svg>;
export default Bed;