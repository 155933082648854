/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Wheelchair = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wheelchair-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wheelchair-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M251.79 190.73a4 4 0 0 0-5.06-2.52l-20.7 6.9-30.45-60.9A4 4 0 0 0 192 132h-84v-31.85c1.32-.09 2.65-.15 4-.15h56a4 4 0 0 0 0-8h-56c-1.34 0-2.68 0-4 .13V75.71a28 28 0 1 0-8 0v17.37A68 68 0 0 0 112 228c31.72 0 60.27-21.45 67.87-51a4 4 0 0 0-7.74-2c-6.71 26.08-32 45-60.13 45a60 60 0 0 1-12-118.79V136a4 4 0 0 0 4 4h85.53l30.89 61.79A4 4 0 0 0 224 204a3.92 3.92 0 0 0 1.26-.21l24-8a4 4 0 0 0 2.53-5.06ZM84 48a20 20 0 1 1 20 20 20 20 0 0 1-20-20Z" /></Svg>;
export default Wheelchair;