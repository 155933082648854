/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileXls = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-xls-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-xls-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 208a4 4 0 0 1-4 4h-28a4 4 0 0 1-4-4v-56a4 4 0 0 1 8 0v52h24a4 4 0 0 1 4 4Zm-61.68-59.25a4 4 0 0 0-5.58.92L68 173.12l-16.75-23.45a4 4 0 0 0-6.5 4.66L63.08 180l-18.33 25.67a4 4 0 0 0 .93 5.58A3.91 3.91 0 0 0 48 212a4 4 0 0 0 3.25-1.67L68 186.88l16.74 23.45A4 4 0 0 0 88 212a3.91 3.91 0 0 0 2.32-.75 4 4 0 0 0 .93-5.58L72.91 180l18.34-25.67a4 4 0 0 0-.93-5.58Zm100.17 26.4c-10.53-3-15.08-4.91-14.43-10.08a8.57 8.57 0 0 1 3.75-6.49c6.26-4.23 18.77-2.24 23.07-1.11a4 4 0 0 0 2-7.74 61.33 61.33 0 0 0-10.48-1.61c-8.11-.54-14.54.75-19.09 3.82a16.63 16.63 0 0 0-7.22 12.13c-1.59 12.49 10.46 16 20.14 18.77 11.25 3.25 16.46 5.49 15.63 11.94a8.93 8.93 0 0 1-3.9 6.75c-6.28 4.17-18.61 2.05-22.83.88a4 4 0 1 0-2.15 7.7 57.7 57.7 0 0 0 14.21 1.89c5.17 0 10.83-.86 15.22-3.77a17 17 0 0 0 7.43-12.41c1.79-13.98-11.58-17.82-21.35-20.67ZM204 92h-52a4 4 0 0 1-4-4V36H56a4 4 0 0 0-4 4v72a4 4 0 0 1-8 0V40a12 12 0 0 1 12-12h96a4 4 0 0 1 2.83 1.17l56 56A4 4 0 0 1 212 88v24a4 4 0 0 1-8 0Zm-5.65-8L156 41.65V84Z" /></Svg>;
export default FileXls;