/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TriangleDashed = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="triangle-dashed-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-triangle-dashed-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.8 188.09 149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.34 24.34 0 0 0 40.55 224h174.9a24.34 24.34 0 0 0 21.33-12.19 23.51 23.51 0 0 0 .02-23.72ZM108 200H60.79a12 12 0 0 1-10.39-18l24.18-42a8 8 0 0 1 13.87 8l-20.74 36H108a8 8 0 0 1 0 16Zm-1.12-84A8 8 0 0 1 93 108l24.59-42.7a12 12 0 0 1 20.8 0L163 108a8 8 0 0 1-13.87 8L128 79.31Zm98.72 78a12.05 12.05 0 0 1-10.39 6H148a8 8 0 0 1 0-16h40.29l-20.74-36a8 8 0 0 1 13.87-8l24.18 42a12 12 0 0 1 0 12Z" /></Svg>;
export default TriangleDashed;