/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowCounterClockwise = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-counter-clockwise-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-counter-clockwise-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128a96 96 0 0 1-94.71 96H128a95.38 95.38 0 0 1-65.9-26.2 8 8 0 0 1 11-11.63 80 80 0 1 0-1.67-114.78 3.07 3.07 0 0 1-.26.25l-10.54 9.65 17 17A8 8 0 0 1 72 112H24a8 8 0 0 1-8-8V56a8 8 0 0 1 13.66-5.7L49.31 70l10.94-10A96 96 0 0 1 224 128Z" /></Svg>;
export default ArrowCounterClockwise;