/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserFocus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-focus-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-focus-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 40v36a12 12 0 0 1-24 0V52h-24a12 12 0 0 1 0-24h36a12 12 0 0 1 12 12Zm-12 128a12 12 0 0 0-12 12v24h-24a12 12 0 0 0 0 24h36a12 12 0 0 0 12-12v-36a12 12 0 0 0-12-12ZM76 204H52v-24a12 12 0 0 0-24 0v36a12 12 0 0 0 12 12h36a12 12 0 0 0 0-24ZM40 88a12 12 0 0 0 12-12V52h24a12 12 0 0 0 0-24H40a12 12 0 0 0-12 12v36a12 12 0 0 0 12 12Zm136 92a12 12 0 0 1-9.6-4.79 48 48 0 0 0-76.82 0 12 12 0 0 1-19.18-14.42 72.1 72.1 0 0 1 23.92-20.5 44 44 0 1 1 67.34 0 72.1 72.1 0 0 1 23.92 20.5A12 12 0 0 1 176 180Zm-48-48a20 20 0 1 0-20-20 20 20 0 0 0 20 20Z" /></Svg>;
export default UserFocus;