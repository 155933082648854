/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyDollar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-dollar-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-dollar-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 122h-18V54h10a34 34 0 0 1 34 34 6 6 0 0 0 12 0 46.06 46.06 0 0 0-46-46h-10V24a6 6 0 0 0-12 0v18h-10a46 46 0 0 0 0 92h10v68h-18a34 34 0 0 1-34-34 6 6 0 0 0-12 0 46.06 46.06 0 0 0 46 46h18v18a6 6 0 0 0 12 0v-18h18a46 46 0 0 0 0-92Zm-40 0a34 34 0 0 1 0-68h10v68Zm40 80h-18v-68h18a34 34 0 0 1 0 68Z" /></Svg>;
export default CurrencyDollar;