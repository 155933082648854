/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WifiHigh = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wifi-high-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wifi-high-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M138 204a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm97.81-115.45a170 170 0 0 0-215.62 0 6 6 0 1 0 7.62 9.27 158 158 0 0 1 200.38 0 6 6 0 1 0 7.62-9.27Zm-32.08 35.79a122 122 0 0 0-151.46 0 6 6 0 0 0 7.46 9.41 110 110 0 0 1 136.54 0A6 6 0 0 0 200 135a6 6 0 0 0 3.73-10.7Zm-32.2 35.81a74 74 0 0 0-87.06 0 6 6 0 0 0 7.06 9.7 62 62 0 0 1 72.94 0 6 6 0 0 0 8.38-1.32 6 6 0 0 0-1.32-8.38Z" /></Svg>;
export default WifiHigh;