/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Rss = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M109.74 146.26A75.53 75.53 0 0 1 132 200a12 12 0 0 1-24 0 52 52 0 0 0-52-52 12 12 0 0 1 0-24 75.51 75.51 0 0 1 53.74 22.26ZM56 76a12 12 0 0 0 0 24 100 100 0 0 1 100 100 12 12 0 0 0 24 0A124 124 0 0 0 56 76Zm121.62 2.38A170.85 170.85 0 0 0 56 28a12 12 0 0 0 0 24 147 147 0 0 1 104.65 43.35A147 147 0 0 1 204 200a12 12 0 0 0 24 0 170.85 170.85 0 0 0-50.38-121.62ZM60 180a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default Rss;