/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowFatUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-fat-up-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-fat-up-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m232.48 111.51-96-96a12 12 0 0 0-17 0l-96 96A12 12 0 0 0 32 132h36v76a20 20 0 0 0 20 20h80a20 20 0 0 0 20-20v-76h36a12 12 0 0 0 8.48-20.49ZM176 108a12 12 0 0 0-12 12v84H92v-84a12 12 0 0 0-12-12H61l67-67 67 67Z" /></Svg>;
export default ArrowFatUp;