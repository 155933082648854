/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Warning = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="warning-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-warning-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.34 190.09 145.88 38.22a20.75 20.75 0 0 0-35.76 0L22.66 190.09a19.52 19.52 0 0 0 0 19.71A20.36 20.36 0 0 0 40.54 220h174.92a20.36 20.36 0 0 0 17.86-10.2 19.52 19.52 0 0 0 .02-19.71Zm-6.94 15.71a12.47 12.47 0 0 1-10.94 6.2H40.54a12.47 12.47 0 0 1-10.94-6.2 11.45 11.45 0 0 1 0-11.72l87.45-151.87a12.76 12.76 0 0 1 21.9 0l87.45 151.87a11.45 11.45 0 0 1 0 11.72ZM124 144v-40a4 4 0 0 1 8 0v40a4 4 0 0 1-8 0Zm12 36a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default Warning;