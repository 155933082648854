/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitPullRequest = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-pull-request-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-pull-request-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M102 64a30 30 0 1 0-36 29.4v69.2a30 30 0 1 0 12 0V93.4A30.05 30.05 0 0 0 102 64Zm-48 0a18 18 0 1 1 18 18 18 18 0 0 1-18-18Zm36 128a18 18 0 1 1-18-18 18 18 0 0 1 18 18Zm116-29.4v-52a21.88 21.88 0 0 0-6.44-15.56L158.48 54H192a6 6 0 0 0 0-12h-48a6 6 0 0 0-6 6v48a6 6 0 0 0 12 0V62.48l41.07 41.08a9.91 9.91 0 0 1 2.93 7.07v52a30 30 0 1 0 12 0Zm-6 47.4a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default GitPullRequest;