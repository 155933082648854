/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Axe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="axe-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-axe-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m245.65 108.46-81.19 81.19a8 8 0 0 1-13.59-4.65c-7.09-53.14-42.41-87.8-59.45-99.76A8 8 0 0 1 90.33 73L129 34.33a8 8 0 0 1 12.2 1.09c12 17 46.62 52.36 99.76 59.45a8 8 0 0 1 4.69 13.59Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M255.15 97.72A16 16 0 0 0 242 86.94a136.46 136.46 0 0 1-51.65-18l10.31-10.3a25 25 0 0 0-35.32-35.32l-13.2 13.21c-2.33-2.8-3.81-4.84-4.41-5.69a16 16 0 0 0-24.41-2.15L84.68 67.36a16 16 0 0 0 2.14 24.4c.86.6 2.9 2.08 5.7 4.41l-85.21 85.2a25 25 0 0 0 35.32 35.32l82.3-82.31a136.63 136.63 0 0 1 18 51.65 16 16 0 0 0 10.77 13.12 16.21 16.21 0 0 0 5.15.85 15.88 15.88 0 0 0 11.26-4.69l81.18-81.19a15.86 15.86 0 0 0 3.86-16.4Zm-78.46-63.09a9 9 0 1 1 12.68 12.68l-12.55 12.55a152.5 152.5 0 0 1-13.72-11.65ZM31.31 205.37a9 9 0 1 1-12.68-12.68l85.58-85.58a150.89 150.89 0 0 1 11.65 13.71Zm127.49-21.45C150 118.29 101.52 82.52 96 78.67L134.66 40c3.86 5.5 39.63 54 105.25 62.78Z" /></Svg>;
export default Axe;