/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NuclearPlant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="nuclear-plant-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-nuclear-plant-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 34h24a10 10 0 0 0 10-10 6 6 0 0 1 12 0 22 22 0 0 1-22 22h-24a10 10 0 0 0-10 10 6 6 0 0 1-12 0 22 22 0 0 1 22-22Zm-48 28a6 6 0 0 0 6-6 42 42 0 0 1 42-42h8a6 6 0 0 0 0-12h-8a54.06 54.06 0 0 0-54 54 6 6 0 0 0 6 6Zm142 154a6 6 0 0 1-6 6H16a6 6 0 0 1 0-12h18c14-28 30.2-69.68 31.88-114.59A13.92 13.92 0 0 1 79.85 82h88.33a14 14 0 0 1 14 13.37c2.14 49.08 24.74 94.63 36.1 114.63H240a6 6 0 0 1 6 6ZM168.18 94H150c.06.46.1.94.12 1.41C151.82 140.32 168 182 182 210h22.55c-12.44-23.1-32.32-66.9-34.4-114.11a2 2 0 0 0-1.97-1.89ZM47.34 210h121.32c-13.86-28.94-28.86-69.92-30.51-114.14a2 2 0 0 0-2-1.86h-56.3a2 2 0 0 0-2 1.86C76.2 140.08 61.2 181.06 47.34 210Z" /></Svg>;
export default NuclearPlant;