/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Barn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="barn-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-barn-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 120v80H72v-80Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M240 192h-8v-61.43l1.49 2.08a8 8 0 1 0 13-9.3l-40-56a8 8 0 0 0-2-1.94L137 18.77l-.1-.07a16 16 0 0 0-17.76 0l-.1.07-67.59 46.65a8 8 0 0 0-2 1.94l-40 56a8 8 0 1 0 13 9.3l1.55-2.09V192h-8a8 8 0 0 0 0 16h224a8 8 0 0 0 0-16ZM40 108.17l21.7-30.38L128 32l66.3 45.78 21.7 30.39V192h-24v-72a8 8 0 0 0-8-8H72a8 8 0 0 0-8 8v72H40Zm88 42L97 128h62Zm48-14.62v48.91L141.76 160ZM114.24 160 80 184.46v-48.91Zm13.76 9.83L159 192H97ZM104 88a8 8 0 0 1 8-8h32a8 8 0 1 1 0 16h-32a8 8 0 0 1-8-8Z" /></Svg>;
export default Barn;