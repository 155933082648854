/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberEight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-eight-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-eight-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M162.44 118.91a52 52 0 1 0-68.88 0 60 60 0 1 0 68.88 0ZM100 80a28 28 0 1 1 28 28 28 28 0 0 1-28-28Zm28 124a36 36 0 1 1 36-36 36 36 0 0 1-36 36Z" /></Svg>;
export default NumberEight;