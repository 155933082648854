/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TidalLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tidal-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tidal-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m250.83 93.17-40-40a4 4 0 0 0-5.66 0L168 90.34l-37.17-37.17a4 4 0 0 0-5.66 0L88 90.34 50.83 53.17a4 4 0 0 0-5.66 0l-40 40a4 4 0 0 0 0 5.66l40 40a4 4 0 0 0 5.66 0L88 101.66 122.34 136l-37.17 37.17a4 4 0 0 0 0 5.66l40 40a4 4 0 0 0 5.66 0l40-40a4 4 0 0 0 0-5.66L133.66 136 168 101.66l37.17 37.17a4 4 0 0 0 5.66 0l40-40a4 4 0 0 0 0-5.66ZM48 130.34 13.66 96 48 61.66 82.34 96Zm80 80L93.66 176 128 141.66 162.34 176Zm0-80L93.66 96 128 61.66 162.34 96Zm80 0L173.66 96 208 61.66 242.34 96Z" /></Svg>;
export default TidalLogo;