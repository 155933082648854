/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Crown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="crown__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-crown'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 80a28 28 0 1 0-51.12 15.77l-26.79 33L146 73.4a28 28 0 1 0-36.06 0l-24.03 55.34-26.79-33a28 28 0 1 0-26.6 12L47 194.63A16 16 0 0 0 62.78 208h130.44A16 16 0 0 0 209 194.63l14.47-86.85A28 28 0 0 0 248 80ZM128 40a12 12 0 1 1-12 12 12 12 0 0 1 12-12ZM24 80a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm169.22 112H62.78l-13.92-83.48L81.79 149a8 8 0 0 0 6.21 3 7.83 7.83 0 0 0 1.08-.07 8 8 0 0 0 6.26-4.74l29.3-67.4a27 27 0 0 0 6.72 0l29.3 67.4a8 8 0 0 0 6.26 4.74 7.83 7.83 0 0 0 1.08.07 8 8 0 0 0 6.21-3l32.93-40.52ZM220 92a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z" /></Svg>;
export default Crown;