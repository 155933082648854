/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Leaf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="leaf-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-leaf-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M223.45 40.07a8 8 0 0 0-7.52-7.52C139.8 28.08 78.82 51 52.82 94a87.09 87.09 0 0 0-12.76 49 101.72 101.72 0 0 0 6.64 32.2 4 4 0 0 0 6.61 1.43l85-86.3a8 8 0 0 1 11.32 11.32l-92.89 94.29-14.19 14.19a8.2 8.2 0 0 0-.6 11.1 8 8 0 0 0 11.71.43l16.79-16.79c14.14 6.84 28.41 10.57 42.56 11.07q1.67.06 3.33.06A86.93 86.93 0 0 0 162 203.18c43-26 65.93-86.97 61.45-163.11Z" /></Svg>;
export default Leaf;