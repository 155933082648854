/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TelegramLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="telegram-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-telegram-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.57 27.7a7 7 0 0 0-7.13-1.22L17.78 105.79a12.23 12.23 0 0 0 2.1 23.39L74 139.81V200a14 14 0 0 0 24.08 9.71l26.64-27.63 41.58 36.45a13.9 13.9 0 0 0 9.2 3.49 14.33 14.33 0 0 0 4.36-.69 13.86 13.86 0 0 0 9.34-10.17l40.62-176.59a7 7 0 0 0-2.25-6.87ZM22.05 117.37a.46.46 0 0 1 0-.32.51.51 0 0 1 .15-.08l159.71-62.52-103.3 74-56.41-11.04Zm67.39 84A2 2 0 0 1 86 200v-51.89l29.69 26Zm88.07 7.08a1.93 1.93 0 0 1-1.34 1.44 2 2 0 0 1-2-.4l-84.53-74.15L215 45.5Z" /></Svg>;
export default TelegramLogo;