/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VirtualReality = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="virtual-reality-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-virtual-reality-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m121.62 98.11-24 64a6 6 0 0 1-11.24 0l-24-64a6 6 0 0 1 11.24-4.22l18.38 49 18.38-49a6 6 0 1 1 11.24 4.22ZM254 128a78.09 78.09 0 0 1-78 78H80a78 78 0 0 1 0-156h96a78.09 78.09 0 0 1 78 78Zm-12 0a66.08 66.08 0 0 0-66-66H80a66 66 0 0 0 0 132h96a66.08 66.08 0 0 0 66-66Zm-63.8 9.76 11 19.26a6 6 0 0 1-10.42 6l-12.07-21.12a27.06 27.06 0 0 1-2.71.1h-14v18a6 6 0 0 1-12 0V96a6 6 0 0 1 6-6h20a26 26 0 0 1 14.2 47.76ZM164 130a14 14 0 0 0 0-28h-14v28Z" /></Svg>;
export default VirtualReality;