/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Perspective = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="perspective-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-perspective-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 116h-12V48a20 20 0 0 0-23.58-19.67l-160 29.09A20 20 0 0 0 28 77.09V116H16a12 12 0 0 0 0 24h12v38.91a20 20 0 0 0 16.42 19.67l160 29.09A20 20 0 0 0 228 208v-68h12a12 12 0 0 0 0-24ZM52 80.43 204 52.8V116H52ZM204 203.2 52 175.57V140h152Z" /></Svg>;
export default Perspective;