/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const YinYang = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="yin-yang__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-yin-yang'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24ZM40 128a88.1 88.1 0 0 1 88-88 40 40 0 0 1 0 80 56 56 0 0 0-50.61 80A88 88 0 0 1 40 128Zm88 88a40 40 0 0 1 0-80 56 56 0 0 0 50.61-79.95A88 88 0 0 1 128 216Zm12-40a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-24-96a12 12 0 1 1 12 12 12 12 0 0 1-12-12Z" /></Svg>;
export default YinYang;