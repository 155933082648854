/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyNgn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-ngn-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-ngn-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 116h-12V46a12 12 0 0 0-24 0v70h-46.14l-60.4-77.38A12 12 0 0 0 52 46v70H40a12 12 0 0 0 0 24h12v70a12 12 0 0 0 24 0v-70h46.14l60.4 77.38A12 12 0 0 0 204 210v-70h12a12 12 0 0 0 0-24Zm-140 0V80.88L103.41 116Zm104 59.12L152.59 140H180Z" /></Svg>;
export default CurrencyNgn;