/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Horse = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="horse-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-horse-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M134 100a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm96 29.45A102.29 102.29 0 0 1 130.06 230h-2.13A101.33 101.33 0 0 1 60 204.47a6 6 0 1 1 8-8.94 87.12 87.12 0 0 0 22.09 14.39l29-39.89c-23.93-11.37-50.18-6.93-64.8-4.45a29.84 29.84 0 0 1-29.38-12 3.62 3.62 0 0 1-.22-.32l-13.79-22a6 6 0 0 1 1.95-8.29L114 60.65V32a6 6 0 0 1 6-6h8a102 102 0 0 1 102 103.45Zm-12-.17A90 90 0 0 0 128 38h-2v26a6 6 0 0 1-2.86 5.11l-98.85 60.83 10.47 16.74a17.91 17.91 0 0 0 17.54 7.06c15.82-2.67 48.42-8.18 77.23 8.22A42 42 0 0 0 170 120a6 6 0 0 1 12 0 54.06 54.06 0 0 1-50.87 53.9l-29.36 40.37a92.83 92.83 0 0 0 28.05 3.73A90.28 90.28 0 0 0 218 129.28Z" /></Svg>;
export default Horse;