/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmSlate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-slate-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-slate-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 104H102.09L210 75.51a8 8 0 0 0 5.68-9.84l-8.16-30a15.93 15.93 0 0 0-19.42-11.13L35.81 64.74a15.75 15.75 0 0 0-9.7 7.4 15.51 15.51 0 0 0-1.55 12L32 111.56V200a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-88a8 8 0 0 0-8-8Zm-23.84-64 6 22.07L164.57 71l-28.13-16.28ZM77.55 70.27l28.12 16.24-59.6 15.73-6-22.08Z" /></Svg>;
export default FilmSlate;