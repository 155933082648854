/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GitFork = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="git-fork-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-git-fork-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222 64a30 30 0 1 0-36 29.4V112a10 10 0 0 1-10 10H80a10 10 0 0 1-10-10V93.4a30 30 0 1 0-12 0V112a22 22 0 0 0 22 22h42v28.6a30 30 0 1 0 12 0V134h42a22 22 0 0 0 22-22V93.4A30.05 30.05 0 0 0 222 64ZM46 64a18 18 0 1 1 18 18 18 18 0 0 1-18-18Zm100 128a18 18 0 1 1-18-18 18 18 0 0 1 18 18Zm46-110a18 18 0 1 1 18-18 18 18 0 0 1-18 18Z" /></Svg>;
export default GitFork;