/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilePy = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-py-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-py-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 88h-56V32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m213.66 82.34-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v72a8 8 0 0 0 16 0V40h88v48a8 8 0 0 0 8 8h48v120h-32a8 8 0 0 0 0 16h32a16 16 0 0 0 16-16V88a8 8 0 0 0-2.34-5.66ZM160 51.31 188.69 80H160ZM64 144H48a8 8 0 0 0-8 8v56a8 8 0 0 0 16 0v-8h8a28 28 0 0 0 0-56Zm0 40h-8v-24h8a12 12 0 0 1 0 24Zm90.78-27.76-18.78 30V208a8 8 0 0 1-16 0v-21.71l-18.78-30a8 8 0 1 1 13.56-8.48l13.22 21.1 13.22-21.15a8 8 0 1 1 13.56 8.48Z" /></Svg>;
export default FilePy;