/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DropSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="drop-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-drop-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.92 210.62a8 8 0 1 1-11.84 10.76l-12.9-14.19A87.71 87.71 0 0 1 128 232c-48 0-87.49-38.93-88-86.88-.27-24.34 8.22-49.84 24.73-74.81L42.3 45.63a8.23 8.23 0 0 1 .14-11.38 8 8 0 0 1 11.48.37Zm-10.07-34.86a4 4 0 0 0 6.7-1.27A87.66 87.66 0 0 0 216 144c0-31.4-14.51-64.68-42-96.25a254.19 254.19 0 0 0-41.45-38.3 8 8 0 0 0-9.18 0A251.26 251.26 0 0 0 87.17 42a4 4 0 0 0 0 5.41Z" /></Svg>;
export default DropSlash;