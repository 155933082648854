/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dot-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dot-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 128a48 48 0 1 1-48-48 48 48 0 0 1 48 48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M140 128a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default Dot;