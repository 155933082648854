/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintRoller = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-roller-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-roller-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 84h-20V64a20 20 0 0 0-20-20H52a20 20 0 0 0-20 20v20H16a12 12 0 0 0 0 24h16v20a20 20 0 0 0 20 20h140a20 20 0 0 0 20-20v-20h16v47l-97.5 27.8A20.09 20.09 0 0 0 116 202v30a12 12 0 0 0 24 0v-26.95l97.5-27.85A20.09 20.09 0 0 0 252 158v-54a20 20 0 0 0-20-20Zm-44 40H56V68h132Z" /></Svg>;
export default PaintRoller;