/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cactus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cactus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cactus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 212h-52v-32h8a64.07 64.07 0 0 0 64-64 24 24 0 0 0-48 0 16 16 0 0 1-16 16h-8V56a36 36 0 0 0-72 0v36h-8a16 16 0 0 1-16-16 24 24 0 0 0-48 0 64.07 64.07 0 0 0 64 64h8v72H40a4 4 0 0 0 0 8h176a4 4 0 0 0 0-8ZM96 132H84a56.06 56.06 0 0 1-56-56 16 16 0 0 1 32 0 24 24 0 0 0 24 24h12a4 4 0 0 0 4-4V56a28 28 0 0 1 56 0v80a4 4 0 0 0 4 4h12a24 24 0 0 0 24-24 16 16 0 0 1 32 0 56.06 56.06 0 0 1-56 56h-12a4 4 0 0 0-4 4v36h-56v-76a4 4 0 0 0-4-4Z" /></Svg>;
export default Cactus;