/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerSimpleSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-simple-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-simple-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M194 152v-48a6 6 0 0 1 12 0v48a6 6 0 0 1-12 0Zm38-70a6 6 0 0 0-6 6v80a6 6 0 0 0 12 0V88a6 6 0 0 0-6-6Zm-11.56 130a6 6 0 0 1-8.88 8.08L166 169.92V224a6 6 0 0 1-9.68 4.74L85.94 174H40a14 14 0 0 1-14-14V96a14 14 0 0 1 14-14h46.07L51.56 44a6 6 0 0 1 8.88-8ZM154 156.72 97 94H40a2 2 0 0 0-2 2v64a2 2 0 0 0 2 2h48a6 6 0 0 1 3.68 1.26L154 211.73Zm-30.17-89L154 44.27v62.56a6 6 0 0 0 12 0V32a6 6 0 0 0-9.68-4.74l-39.85 31a6 6 0 1 0 7.36 9.47Z" /></Svg>;
export default SpeakerSimpleSlash;