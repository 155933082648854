/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Skull = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="skull-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-skull-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24c-53 0-96 41.19-96 92 0 34.05 19.31 63.78 48 79.69V216a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-20.31c28.69-15.91 48-45.64 48-79.69 0-50.81-43-92-96-92ZM92 152a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm72 0a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M92 104a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 40a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm72-40a28 28 0 1 0 28 28 28 28 0 0 0-28-28Zm0 40a12 12 0 1 1 12-12 12 12 0 0 1-12 12ZM128 16C70.65 16 24 60.86 24 116c0 34.1 18.27 66 48 84.28V216a16 16 0 0 0 16 16h80a16 16 0 0 0 16-16v-15.72C213.73 182 232 150.1 232 116c0-55.14-46.65-100-104-100Zm44.12 172.69a8 8 0 0 0-4.12 7V216h-16v-24a8 8 0 0 0-16 0v24h-16v-24a8 8 0 0 0-16 0v24H88v-20.31a8 8 0 0 0-4.12-7C56.81 173.69 40 145.84 40 116c0-46.32 39.48-84 88-84s88 37.68 88 84c0 29.83-16.81 57.69-43.88 72.69Z" /></Svg>;
export default Skull;