/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatDots = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-dots-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-dots-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M88 128a16 16 0 1 1 16 16 16 16 0 0 1-16-16Zm64 16a16 16 0 1 0-16-16 16 16 0 0 0 16 16Zm84-80v128a20 20 0 0 1-20 20H84.47L53 239.17l-.12.11A19.91 19.91 0 0 1 40.05 244a20.14 20.14 0 0 1-8.49-1.9A19.82 19.82 0 0 1 20 224V64a20 20 0 0 1 20-20h176a20 20 0 0 1 20 20Zm-24 4H44v147.23l28.16-24.32A11.93 11.93 0 0 1 80 188h132Z" /></Svg>;
export default ChatDots;