/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Popsicle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="popsicle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-popsicle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 10a70.08 70.08 0 0 0-70 70v96a14 14 0 0 0 14 14h34v42a22 22 0 0 0 44 0v-42h34a14 14 0 0 0 14-14V80a70.08 70.08 0 0 0-70-70Zm10 222a10 10 0 0 1-20 0v-42h20Zm48-56a2 2 0 0 1-2 2H72a2 2 0 0 1-2-2V80a58 58 0 0 1 116 0ZM118 72v80a6 6 0 0 1-12 0V72a6 6 0 0 1 12 0Zm32 0v80a6 6 0 0 1-12 0V72a6 6 0 0 1 12 0Z" /></Svg>;
export default Popsicle;