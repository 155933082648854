/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TidalLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tidal-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tidal-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m253.66 101.66-36 36a8 8 0 0 1-11.32 0l-36-36-.34-.38-.34.38L135.31 136l34.35 34.34a8 8 0 0 1 0 11.32l-36 36a8 8 0 0 1-11.32 0l-36-36a8 8 0 0 1 0-11.32L120.69 136l-34.35-34.34-.34-.38-.34.38-36 36a8 8 0 0 1-11.32 0l-36-36a8 8 0 0 1 0-11.32l36-36a8 8 0 0 1 11.32 0l36 36 .34.38.34-.38 36-36a8 8 0 0 1 11.32 0l36 36 .34.38.34-.38 36-36a8 8 0 0 1 11.32 0l36 36a8 8 0 0 1 0 11.32Z" /></Svg>;
export default TidalLogo;