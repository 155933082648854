/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Screencast = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="screencast-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-screencast-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230 56v144a14 14 0 0 1-14 14h-72a6 6 0 0 1 0-12h72a2 2 0 0 0 2-2V56a2 2 0 0 0-2-2H40a2 2 0 0 0-2 2v40a6 6 0 0 1-12 0V56a14 14 0 0 1 14-14h176a14 14 0 0 1 14 14ZM32 186a6 6 0 0 0 0 12 10 10 0 0 1 10 10 6 6 0 0 0 12 0 22 22 0 0 0-22-22Zm0-32a6 6 0 0 0 0 12 42 42 0 0 1 42 42 6 6 0 0 0 12 0 54.06 54.06 0 0 0-54-54Zm0-32a6 6 0 0 0 0 12 74.09 74.09 0 0 1 74 74 6 6 0 0 0 12 0 86.1 86.1 0 0 0-86-86Z" /></Svg>;
export default Screencast;