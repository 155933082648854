/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-star__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-star'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M32 64a8 8 0 0 1 8-8h176a8 8 0 0 1 0 16H40a8 8 0 0 1-8-8Zm8 72h56a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16Zm72 48H40a8 8 0 0 0 0 16h72a8 8 0 0 0 0-16Zm125.09-40.22-22.52 18.59 6.86 27.71a8 8 0 0 1-11.82 8.81L184 183.82l-25.61 15.07a8 8 0 0 1-11.82-8.81l6.85-27.71-22.51-18.59a8 8 0 0 1 4.47-14.14l29.84-2.31 11.43-26.5a8 8 0 0 1 14.7 0l11.43 26.5 29.84 2.31a8 8 0 0 1 4.47 14.14Zm-25.47.28-14.89-1.15a8 8 0 0 1-6.73-4.8l-6-13.92-6 13.92a8 8 0 0 1-6.73 4.8l-14.89 1.15 11.11 9.18a8 8 0 0 1 2.68 8.09l-3.5 14.12 13.27-7.81a8 8 0 0 1 8.12 0l13.27 7.81-3.5-14.12a8 8 0 0 1 2.68-8.09Z" /></Svg>;
export default ListStar;