/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Plant = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="plant-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-plant-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M245.63 48a6 6 0 0 0-5.63-5.63c-51-3-91.88 12.42-109.35 41.26-11.63 19.21-11.38 42.68.53 65a69.42 69.42 0 0 0-15.48 30.59l-19.8-19.81c8.15-16.1 8.1-32.95-.32-46.85C82.77 91.41 53 80.07 15.9 82.27a6 6 0 0 0-5.63 5.64c-2.18 37.09 9.14 66.86 30.29 79.67A43.75 43.75 0 0 0 63.44 174a53.17 53.17 0 0 0 24-6L114 194.48V224a6 6 0 0 0 12 0v-29.49A57.55 57.55 0 0 1 139.76 157c11.21 5.93 22.72 8.91 33.82 8.91a58.9 58.9 0 0 0 30.79-8.57C233.21 139.89 248.63 99 245.63 48ZM46.78 157.31C30.47 147.44 21.39 124 22 94c30-.65 53.41 8.44 63.28 24.75 5.68 9.37 6.16 20.38 1.54 31.59l-26.58-26.59a6 6 0 0 0-8.49 8.49l26.62 26.61c-11.21 4.62-22.22 4.15-31.59-1.54Zm151.38-10.22c-14.74 8.92-32.14 9.18-49.67.9l55.76-55.75a6 6 0 0 0-8.49-8.49L140 139.51c-8.28-17.53-8-34.93.9-49.66 14.52-24 49.06-37.18 93-35.75 1.43 43.9-11.76 78.46-35.74 92.99Z" /></Svg>;
export default Plant;