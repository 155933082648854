/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Share = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="share-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-share-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m226.83 106.83-48 48a4 4 0 0 1-5.66-5.66L214.34 108H165a92 92 0 0 0-89.11 69 4 4 0 0 1-3.89 3 3.87 3.87 0 0 1-1-.13 4 4 0 0 1-2.87-4.87A99.93 99.93 0 0 1 165 100h49.36l-41.19-41.17a4 4 0 0 1 5.66-5.66l48 48a4 4 0 0 1 0 5.66ZM192 212H36V88a4 4 0 0 0-8 0v128a4 4 0 0 0 4 4h160a4 4 0 0 0 0-8Z" /></Svg>;
export default Share;