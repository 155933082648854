/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Eject = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="eject__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-eject'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 152H48a16 16 0 0 0-16 16v24a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-24a16 16 0 0 0-16-16Zm0 40H48v-24h160ZM48.24 136h159.52a16.18 16.18 0 0 0 14.93-9.76 15.59 15.59 0 0 0-3.1-17.12l-73.73-77.51a24.76 24.76 0 0 0-35.72 0l-73.73 77.51a15.59 15.59 0 0 0-3.1 17.12A16.18 16.18 0 0 0 48.24 136Zm73.49-93.36a8.77 8.77 0 0 1 12.54 0L207.85 120H48.14Z" /></Svg>;
export default Eject;