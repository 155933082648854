/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Factory = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="factory-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-factory-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M120 176a12 12 0 0 1-12 12H80a12 12 0 0 1 0-24h28a12 12 0 0 1 12 12Zm56-12h-28a12 12 0 0 0 0 24h28a12 12 0 0 0 0-24Zm68 52a12 12 0 0 1-12 12H24a12 12 0 0 1 0-24h4V88a12 12 0 0 1 19.2-9.6L92 112V88a12 12 0 0 1 19.2-9.6l33.33 25 10.61-74.23A20.1 20.1 0 0 1 174.94 12h18.12a20.1 20.1 0 0 1 19.8 17.17l15 105.13c0 .1.12 1.34.12 1.7v68h4A12 12 0 0 1 244 216Zm-77.57-96.17L172 124h30.16l-12.57-88h-11.18ZM52 204h152v-56h-36a12 12 0 0 1-7.2-2.4l-14.38-10.78h-.06L116 112v24a12 12 0 0 1-19.2 9.6L52 112Z" /></Svg>;
export default Factory;