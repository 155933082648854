/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Asclepius = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="asclepius-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-asclepius-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M214 79v1a38 38 0 0 1-38 38h-42v84h10a18 18 0 0 0 12-31.42 6 6 0 1 1 8-8.94A30 30 0 0 1 144 214h-10v18a6 6 0 0 1-12 0v-18H96a6 6 0 0 1 0-12h26v-84H96a18 18 0 0 0 0 36 6 6 0 0 1 0 12 30 30 0 0 1 0-60h26V24a6 6 0 0 1 12 0v82h42a26 26 0 0 0 26-26v-1a25 25 0 0 0-25-25h-17a6 6 0 0 1 0-12h17a37 37 0 0 1 37 37ZM56 94H32a6 6 0 0 1-6-6v-8a38 38 0 0 1 38-38h32a6 6 0 0 1 0 12h-2v2a38 38 0 0 1-38 38Zm26-40H64a26 26 0 0 0-26 26v2h18a26 26 0 0 0 26-26Z" /></Svg>;
export default Asclepius;