/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bandaids = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bandaids-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bandaids-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 116a12 12 0 1 1-12 12 12 12 0 0 1 12-12Zm84.28 39.72a40 40 0 1 1-56.56 56.56L128 184.57l-27.72 27.71a40 40 0 1 1-56.56-56.56L71.43 128l-27.71-27.72a40 40 0 1 1 56.56-56.56L128 71.43l27.72-27.71a40 40 0 1 1 56.56 56.56L184.57 128Zm-95.59 17.53-33.94-33.94L55 167a24 24 0 1 0 34 34ZM161.94 128 128 94.06 94.06 128 128 161.94Zm39-39A24 24 0 1 0 167 55l-27.69 27.75 33.94 33.94Z" /></Svg>;
export default Bandaids;