/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TagSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tag-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tag-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m250 121.34-45.64-68.43A20 20 0 0 0 187.72 44H40a20 20 0 0 0-20 20v128a20 20 0 0 0 20 20h147.72a20 20 0 0 0 16.64-8.91L250 134.66a12 12 0 0 0 0-13.32ZM185.58 188H44V68h141.58l40 60Z" /></Svg>;
export default TagSimple;