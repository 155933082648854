/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bookmarks = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bookmarks-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bookmarks-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 20H96a20 20 0 0 0-20 20v20H64a20 20 0 0 0-20 20v144a12 12 0 0 0 19.32 9.51L108 199.14l44.69 34.37A12 12 0 0 0 172 224v-46.4l20.68 15.91A12 12 0 0 0 212 184V40a20 20 0 0 0-20-20Zm-44 179.63-32.69-25.14a12 12 0 0 0-14.63 0L68 199.63V84h80Zm40-40-16-12.3V80a20 20 0 0 0-20-20h-52V44h88Z" /></Svg>;
export default Bookmarks;