/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BookmarksSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bookmarks-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bookmarks-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 60H64a12 12 0 0 0-12 12v152a4 4 0 0 0 6.33 3.25L112 188.92l53.69 38.33a3.94 3.94 0 0 0 2.31.75 4.08 4.08 0 0 0 1.83-.44A4 4 0 0 0 172 224V72a12 12 0 0 0-12-12Zm4 156.23-49.68-35.49a4 4 0 0 0-4.65 0L60 216.23V72a4 4 0 0 1 4-4h96a4 4 0 0 1 4 4ZM204 40v152a4 4 0 0 1-8 0V40a4 4 0 0 0-4-4H88a4 4 0 0 1 0-8h104a12 12 0 0 1 12 12Z" /></Svg>;
export default BookmarksSimple;