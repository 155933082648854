/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArticleNyTimes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="article-ny-times-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-article-ny-times-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM64 92a8 8 0 0 1-16 0V80a8 8 0 0 1 8-8h72a8 8 0 0 1 8 8v12a8 8 0 0 1-16 0v-4h-20v48h4a8 8 0 0 1 0 16H80a8 8 0 0 1 0-16h4V88H64Zm136 92H80a8 8 0 0 1 0-16h120a8 8 0 0 1 0 16Zm0-32h-64a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16Zm0-32h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16Z" /></Svg>;
export default ArticleNyTimes;