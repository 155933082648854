/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Chat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chat-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chat-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a20 20 0 0 0-20 20v160a19.82 19.82 0 0 0 11.56 18.1 20.14 20.14 0 0 0 8.49 1.9 19.91 19.91 0 0 0 12.82-4.72l.12-.11L84.47 212H216a20 20 0 0 0 20-20V64a20 20 0 0 0-20-20Zm-4 144H80a11.93 11.93 0 0 0-7.84 2.92L44 215.23V68h168Z" /></Svg>;
export default Chat;