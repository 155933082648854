/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FilmReel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="film-reel-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-film-reel-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 216h-48.64A103.95 103.95 0 1 0 128 232h104a8 8 0 0 0 0-16ZM80 148a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm48 48a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm0-96a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm28 28a20 20 0 1 1 20 20 20 20 0 0 1-20-20Z" /></Svg>;
export default FilmReel;