/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserSound = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-sound-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-sound-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M198.13 202.85A8 8 0 0 1 192 216H24a8 8 0 0 1-6.12-13.15c14.94-17.78 33.52-30.41 54.17-37.17a68 68 0 1 1 71.9 0c20.65 6.76 39.23 19.39 54.18 37.17Zm-1.27-141.46a8 8 0 0 0-4.22 10.5 92.26 92.26 0 0 1 0 72.22 8 8 0 1 0 14.72 6.29 108.36 108.36 0 0 0 0-84.8 8 8 0 0 0-10.5-4.21Zm39.85-8.54a8 8 0 1 0-14.7 6.3 124.43 124.43 0 0 1 0 97.7 8 8 0 1 0 14.7 6.3 140.34 140.34 0 0 0 0-110.3Z" /></Svg>;
export default UserSound;