/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Clover = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clover-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clover-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 120c0 22.63-6 36.72-17.93 41.87a27.3 27.3 0 0 1-11 2.13 41.75 41.75 0 0 1-8.4-.93 4.05 4.05 0 0 1-2.52-1.64 368.49 368.49 0 0 0-47.75-55.26 8 8 0 0 0-11 11.62c14.84 13.91 64.13 63.49 78.32 120.27a8 8 0 0 1-5.82 9.7 8.13 8.13 0 0 1-1.9.24 8 8 0 0 1-7.75-6.06c-4.12-16.47-11.65-32.48-20.46-47.09a25.85 25.85 0 0 1-1.9 7.21C164.72 214 150.63 220 128 220s-36.72-6-41.88-17.94c-5.45-12.58-.39-30.82 15-54.21.68-1 1.36-2 2-3l-3 2C82.84 158.27 68.35 164 56.89 164a27.3 27.3 0 0 1-11-2.13C34 156.72 28 142.63 28 120s6-36.72 17.93-41.88c12.59-5.45 30.83-.39 54.22 15l3 2-2-3c-15.41-23.39-20.47-41.63-15-54.22C91.28 26 105.37 20 128 20s36.72 6 41.88 17.93c5.45 12.59.39 30.83-15 54.22q-1 1.53-2 3l3-2c23.39-15.41 41.63-20.47 54.22-15C222 83.28 228 97.37 228 120Z" /></Svg>;
export default Clover;