/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Dresser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="dresser-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-dresser-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 192a4 4 0 0 1-4 4h-16a4 4 0 0 1 0-8h16a4 4 0 0 1 4 4ZM120 68h16a4 4 0 0 0 0-8h-16a4 4 0 0 0 0 8Zm16 56h-16a4 4 0 0 0 0 8h16a4 4 0 0 0 0-8Zm76-84v176a12 12 0 0 1-12 12H56a12 12 0 0 1-12-12V40a12 12 0 0 1 12-12h144a12 12 0 0 1 12 12ZM52 156h152v-56H52Zm0-116v52h152V40a4 4 0 0 0-4-4H56a4 4 0 0 0-4 4Zm152 176v-52H52v52a4 4 0 0 0 4 4h144a4 4 0 0 0 4-4Z" /></Svg>;
export default Dresser;