/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tilde = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tilde-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tilde-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220.68 129.34c-14.17 17.62-28.06 26.92-42.46 28.44a40.75 40.75 0 0 1-4.22.22c-18.64 0-34.44-12.87-49.76-25.35S94.57 108.51 79 110.16c-11.06 1.16-22.3 9-34.36 24a6 6 0 1 1-9.36-7.52c14.17-17.61 28.06-26.92 42.46-28.43 20.52-2.18 37.54 11.7 54 25.12 15.26 12.43 29.68 24.15 45.26 22.51 11.06-1.16 22.3-9 34.36-24a6 6 0 0 1 9.36 7.52Z" /></Svg>;
export default Tilde;