/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Building = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="building-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-building-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 224h-24V32h8a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16h8v192H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16ZM88 56h24a8 8 0 0 1 0 16H88a8 8 0 0 1 0-16Zm0 40h24a8 8 0 0 1 0 16H88a8 8 0 0 1 0-16Zm-8 48a8 8 0 0 1 8-8h24a8 8 0 0 1 0 16H88a8 8 0 0 1-8-8Zm72 80h-48v-40h48Zm16-72h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16Zm0-40h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16Zm0-40h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16Z" /></Svg>;
export default Building;