/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SoundcloudLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="soundcloud-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-soundcloud-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M20 120v48a4 4 0 0 1-8 0v-48a4 4 0 0 1 8 0Zm28-28a4 4 0 0 0-4 4v96a4 4 0 0 0 8 0V96a4 4 0 0 0-4-4Zm32-8a4 4 0 0 0-4 4v104a4 4 0 0 0 8 0V88a4 4 0 0 0-4-4Zm32-32a4 4 0 0 0-4 4v136a4 4 0 0 0 8 0V56a4 4 0 0 0-4-4Zm107.27 57.46A76 76 0 0 0 144 44a4 4 0 0 0 0 8 67.75 67.75 0 0 1 67.66 61.13 4 4 0 0 0 3.22 3.53A36 36 0 0 1 208 188h-64a4 4 0 0 0 0 8h64a44 44 0 0 0 11.27-86.54Z" /></Svg>;
export default SoundcloudLogo;