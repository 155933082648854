/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LightningA = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lightning-a-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lightning-a-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m173.87 118.58-95.2 102.85A8 8 0 0 1 65 214.15l13.67-57.56-50-22.44a8 8 0 0 1-2.59-12.73l95.2-102.85A8 8 0 0 1 135 25.85l-13.69 57.56 50 22.43a8 8 0 0 1 2.59 12.74Zm61.71 104.57a7.91 7.91 0 0 1-3.58.85 8 8 0 0 1-7.16-4.42L215.05 200h-38.11l-9.79 19.58a8 8 0 0 1-14.31-7.16l36-72a8 8 0 0 1 14.31 0l36 72a8 8 0 0 1-3.57 10.73ZM207.05 184 196 161.89 184.94 184Z" /></Svg>;
export default LightningA;