/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cigarette = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cigarette-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cigarette-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 128H32a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16Zm0 48H96v-32h128v32ZM201 60.08c8-14.23 7.42-21.71 6.36-24.91a7.79 7.79 0 0 0-2.64-3.86 8 8 0 1 1 6.5-14.62 22.57 22.57 0 0 1 11.32 13.44c3.36 10.14.81 22.85-7.6 37.79-8 14.23-7.42 21.72-6.36 24.92a7.79 7.79 0 0 0 2.64 3.85 8 8 0 1 1-6.5 14.62 22.53 22.53 0 0 1-11.32-13.44c-3.33-10.14-.78-22.87 7.6-37.79Zm-40 0c8-14.23 7.42-21.71 6.36-24.91a7.79 7.79 0 0 0-2.64-3.86 8 8 0 1 1 6.5-14.62 22.57 22.57 0 0 1 11.32 13.44c3.36 10.14.81 22.85-7.6 37.79-8 14.23-7.42 21.72-6.36 24.92a7.79 7.79 0 0 0 2.64 3.85 8 8 0 1 1-6.5 14.62 22.53 22.53 0 0 1-11.32-13.44c-3.33-10.14-.78-22.87 7.6-37.79Z" /></Svg>;
export default Cigarette;