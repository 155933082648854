/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ShoppingCart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shopping-cart-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shopping-cart-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M233.21 56.31A12 12 0 0 0 224 52H66l-5.47-30.15A12 12 0 0 0 48.73 12H24a12 12 0 0 0 0 24h14.71l24.91 137a28 28 0 0 0 4.07 10.21A32 32 0 1 0 123 196h34a32 32 0 1 0 31-24H91.17a4 4 0 0 1-3.93-3.28L84.92 156H196.1a28 28 0 0 0 27.55-23l12.16-66.86a12 12 0 0 0-2.6-9.83ZM100 204a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm88 8a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm12-83.28a4 4 0 0 1-3.9 3.28H80.56L70.38 76h139.24Z" /></Svg>;
export default ShoppingCart;