/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CellSignalSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cell-signal-slash-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cell-signal-slash-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M213.38 221.92a8 8 0 0 1-11.3-.54l-5.51-6.06a16.11 16.11 0 0 1-4.57.68H32a16 16 0 0 1-15.06-10.59 16.4 16.4 0 0 1 4.07-17l79.13-79.12-57.88-63.67a8.22 8.22 0 0 1 .14-11.38 8 8 0 0 1 11.48.37l160 176a8 8 0 0 1-.5 11.31ZM201 172.66a4 4 0 0 0 7-2.69V40a16 16 0 0 0-27.32-11.32l-55.21 55.2a4 4 0 0 0-.13 5.52Z" /></Svg>;
export default CellSignalSlash;