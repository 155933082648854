/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Microscope = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microscope-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microscope-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 210h-24.47A85.2 85.2 0 0 0 222 152a86.1 86.1 0 0 0-80-85.77V32a14 14 0 0 0-14-14H80a14 14 0 0 0-14 14v104a14 14 0 0 0 14 14h48a14 14 0 0 0 14-14V78.26A74 74 0 0 1 182 210H32a6 6 0 0 0 0 12h192a6 6 0 0 0 0-12Zm-94-74a2 2 0 0 1-2 2H80a2 2 0 0 1-2-2V32a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2Zm-58 46a6 6 0 0 1 0-12h64a6 6 0 0 1 0 12Z" /></Svg>;
export default Microscope;