/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const LegoSmiley = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lego-smiley-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lego-smiley-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 120a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm56-16a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm-2.13 44.62a49 49 0 0 1-51.74 0 4 4 0 0 0-4.26 6.77 57 57 0 0 0 60.26 0 4 4 0 0 0-4.26-6.76ZM212 80v96a28 28 0 0 1-24 27.71V224a12 12 0 0 1-12 12H80a12 12 0 0 1-12-12v-20.29A28 28 0 0 1 44 176V80a28 28 0 0 1 28-28h20V32a12 12 0 0 1 12-12h48a12 12 0 0 1 12 12v20h20a28 28 0 0 1 28 28ZM100 52h56V32a4 4 0 0 0-4-4h-48a4 4 0 0 0-4 4Zm80 172v-20H76v20a4 4 0 0 0 4 4h96a4 4 0 0 0 4-4Zm24-144a20 20 0 0 0-20-20H72a20 20 0 0 0-20 20v96a20 20 0 0 0 20 20h112a20 20 0 0 0 20-20Z" /></Svg>;
export default LegoSmiley;