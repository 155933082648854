/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CaretRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="caret-right-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-caret-right-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m180.24 132.24-80 80a6 6 0 0 1-8.48-8.48L167.51 128 91.76 52.24a6 6 0 0 1 8.48-8.48l80 80a6 6 0 0 1 0 8.48Z" /></Svg>;
export default CaretRight;