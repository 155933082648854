/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Barn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="barn-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-barn-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 192h-8v-61.43l1.49 2.08a8 8 0 1 0 13-9.3l-40-56a8 8 0 0 0-2-1.94L137 18.77l-.1-.07a16 16 0 0 0-17.76 0l-.1.07-67.59 46.65a8 8 0 0 0-2 1.94l-40 56a8 8 0 1 0 13 9.3l1.55-2.09V192h-8a8 8 0 0 0 0 16h224a8 8 0 0 0 0-16ZM112 80h32a8 8 0 1 1 0 16h-32a8 8 0 1 1 0-16Zm52.64 40L128 146.17 91.36 120ZM72 125.83 114.24 156 72 186.17ZM91.36 192 128 165.83 164.64 192Zm92.64-5.83L141.76 156 184 125.83Z" /></Svg>;
export default Barn;