/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ProjectorScreen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="projector-screen-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-projector-screen-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 64V48a16 16 0 0 0-16-16H40a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16v96h-8a8 8 0 0 0 0 16h88v17.38a24 24 0 1 0 16 0V192h88a8 8 0 0 0 0-16h-8V80a16 16 0 0 0 16-16ZM128 240a8 8 0 1 1 8-8 8 8 0 0 1-8 8ZM40 48h176v16H40Z" /></Svg>;
export default ProjectorScreen;