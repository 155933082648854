/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Taxi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="taxi-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-taxi-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 106h-12.52l-28-48.95a14 14 0 0 0-12.15-7h-23.3L151.58 18.8a13.93 13.93 0 0 0-13-8.8h-21.16a13.93 13.93 0 0 0-13 8.8L91.94 50h-23.3a14 14 0 0 0-12.15 7l-28 49H16a6 6 0 0 0 0 12h10v82a14 14 0 0 0 14 14h24a14 14 0 0 0 14-14v-18h100v18a14 14 0 0 0 14 14h24a14 14 0 0 0 14-14v-82h10a6 6 0 0 0 0-12ZM115.56 23.26a2 2 0 0 1 1.86-1.26h21.16a2 2 0 0 1 1.86 1.26L151.14 50h-46.28ZM66.91 63a2 2 0 0 1 1.73-1h118.72a2 2 0 0 1 1.73 1l24.57 43H42.34ZM66 200a2 2 0 0 1-2 2H40a2 2 0 0 1-2-2v-18h28Zm150 2h-24a2 2 0 0 1-2-2v-18h28v18a2 2 0 0 1-2 2Zm2-32H38v-52h180ZM58 144a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12H64a6 6 0 0 1-6-6Zm112 0a6 6 0 0 1 6-6h16a6 6 0 0 1 0 12h-16a6 6 0 0 1-6-6Z" /></Svg>;
export default Taxi;