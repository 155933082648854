/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CircleHalfTilt = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="circle-half-tilt-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-circle-half-tilt-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24ZM40 128a88 88 0 0 1 150.2-62.2L65.8 190.2A87.76 87.76 0 0 1 40 128Z" /></Svg>;
export default CircleHalfTilt;