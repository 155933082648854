/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const RssSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="rss-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-rss-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 192a4 4 0 0 1-8 0c0-81.61-66.39-148-148-148a4 4 0 0 1 0-8c86 0 156 70 156 156ZM64 108a4 4 0 0 0 0 8 76.08 76.08 0 0 1 76 76 4 4 0 0 0 8 0 84.09 84.09 0 0 0-84-84Zm4 72a8 8 0 1 0 8 8 8 8 0 0 0-8-8Z" /></Svg>;
export default RssSimple;