/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stairs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stairs-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stairs-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 24H56a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm-40 80h40v24h-40Zm-48 40h88v24h-88Zm88 72H56v-32h144v32Z" /></Svg>;
export default Stairs;