/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const WechatLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wechat-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wechat-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M140 140a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm48-8a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm47.5 80.6a12 12 0 0 1-14.9 14.9l-26.21-7.71a76 76 0 0 1-105.15-40.11 75 75 0 0 1-27.63-7.89L35.4 179.5a12 12 0 0 1-14.9-14.9l7.71-26.21a76 76 0 1 1 138.58-62.07 76 76 0 0 1 61 110.07Zm-149-41.28A76 76 0 0 1 158 76.05a68 68 0 1 0-122 60 4.07 4.07 0 0 1 .31 3l-8.17 27.76a4 4 0 0 0 5 5l27.76-8.17a4.07 4.07 0 0 1 3 .31 66.93 66.93 0 0 0 22.6 7.37Zm141.33 43.54-8.17-27.76a4.07 4.07 0 0 1 .31-3 68 68 0 1 0-27.89 27.9 4.07 4.07 0 0 1 1.89-.47 4 4 0 0 1 1.13.16l27.76 8.17a4 4 0 0 0 5-5Z" /></Svg>;
export default WechatLogo;