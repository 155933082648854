/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyDollar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-dollar-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-dollar-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M160 152a16 16 0 0 1-16 16h-8v-32h8a16 16 0 0 1 16 16Zm72-24A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-56 24a32 32 0 0 0-32-32h-8V88h4a16 16 0 0 1 16 16 8 8 0 0 0 16 0 32 32 0 0 0-32-32h-4v-8a8 8 0 0 0-16 0v8h-4a32 32 0 0 0 0 64h4v32h-8a16 16 0 0 1-16-16 8 8 0 0 0-16 0 32 32 0 0 0 32 32h8v8a8 8 0 0 0 16 0v-8h8a32 32 0 0 0 32-32Zm-76-48a16 16 0 0 0 16 16h4V88h-4a16 16 0 0 0-16 16Z" /></Svg>;
export default CurrencyDollar;