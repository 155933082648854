/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CellTower = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cell-tower-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cell-tower-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M133.37 85.32a6 6 0 0 0-10.74 0l-72 144a6 6 0 0 0 10.74 5.37L75.71 206h104.58l14.34 28.68A6 6 0 0 0 200 238a5.87 5.87 0 0 0 2.68-.64 6 6 0 0 0 2.69-8.05Zm-5.37 16.1L158.29 162H97.71ZM81.71 194l10-20h72.58l10 20Zm79.74-99.9A33.59 33.59 0 0 0 162 88a34 34 0 0 0-68 0 33.59 33.59 0 0 0 .55 6.1 6 6 0 1 1-11.81 2.13 46 46 0 1 1 90.52 0 6 6 0 0 1-5.89 4.94 5.64 5.64 0 0 1-1.08-.1 6 6 0 0 1-4.84-6.97ZM65.75 135A78 78 0 1 1 206 88a77.33 77.33 0 0 1-15.75 47 6 6 0 1 1-9.57-7.24A65.42 65.42 0 0 0 194 88a66 66 0 0 0-132 0 65.42 65.42 0 0 0 13.32 39.76 6 6 0 1 1-9.57 7.24Z" /></Svg>;
export default CellTower;