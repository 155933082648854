/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BuildingApartment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="building-apartment-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-building-apartment-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 208h-8V72a8 8 0 0 0-8-8h-40V40a8 8 0 0 0-8-8H80a8 8 0 0 0-8 8v56H32a8 8 0 0 0-8 8v104h-8a8 8 0 0 0 0 16h224a8 8 0 0 0 0-16ZM80 176H64a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-32H64a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm64 64h-32v-40h32Zm-8-64h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-32h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-32h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm56 96h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-32h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Zm0-32h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16Z" /></Svg>;
export default BuildingApartment;