/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NumberSquareSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="number-square-six__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-number-square-six'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm0 176H48V48h160v160Zm-80-96h-1.82l16.69-27.9a8 8 0 0 0-13.74-8.2l-32.23 54A36 36 0 1 0 128 112Zm0 56a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default NumberSquareSix;