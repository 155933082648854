/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Pentagram = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pentagram-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pentagram-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.39 92.29A11.82 11.82 0 0 0 224 84h-64l-20.58-63.53a11.93 11.93 0 0 0-22.83 0L96 84H32a12 12 0 0 0-7 21.72L76.82 143 57 204.25a11.83 11.83 0 0 0 4.36 13.41 11.8 11.8 0 0 0 14.09 0L128 179.83l52.58 37.83a12 12 0 0 0 18.47-13.41L179.18 143 231 105.7a11.83 11.83 0 0 0 4.39-13.41ZM124.21 22.88a3.93 3.93 0 0 1 7.59 0L151.62 84h-47.24ZM29.67 99.23A4 4 0 0 1 32 92h61.37l-14 43Zm41 112a4 4 0 0 1-6.15-4.47l19.08-58.8 37.51 27Zm15.52-71.26L101.78 92h52.44l15.55 47.93L128 170Zm105.21 66.79a4 4 0 0 1-6.17 4.46l-50.38-36.32 37.51-27Zm34.9-107.5L176.59 135l-14-43H224a4 4 0 0 1 2.36 7.22Z" /></Svg>;
export default Pentagram;