/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const City = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="city-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-city-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 204h-4V88a12 12 0 0 0-12-12h-72a12 12 0 0 0-12 12v36h-24V40a12 12 0 0 0-12-12H32a12 12 0 0 0-12 12v164h-4a12 12 0 0 0 0 24h224a12 12 0 0 0 0-24Zm-76-104h48v104h-48Zm-24 48v56h-24v-56ZM44 52h48v152H44Zm36 24v8a12 12 0 0 1-24 0v-8a12 12 0 0 1 24 0Zm0 48v8a12 12 0 0 1-24 0v-8a12 12 0 0 1 24 0Zm0 48v8a12 12 0 0 1-24 0v-8a12 12 0 0 1 24 0Zm96 8v-8a12 12 0 0 1 24 0v8a12 12 0 0 1-24 0Zm0-48v-8a12 12 0 0 1 24 0v8a12 12 0 0 1-24 0Z" /></Svg>;
export default City;