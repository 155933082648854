/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PixLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pix-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pix-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m238.16 113.89-96-96a20 20 0 0 0-28.22 0l-96 96.05a20 20 0 0 0 0 28.22l96.05 96a20 20 0 0 0 28.22 0l96-96a20 20 0 0 0 0-28.22ZM128 37.68 174.32 84H160a12 12 0 0 0-8.49 3.51L128 111l-23.51-23.49A12 12 0 0 0 96 84H81.68ZM57.68 108H91l20 20-20 20H57.68l-20-20ZM128 218.32 81.68 172H96a12 12 0 0 0 8.49-3.51L128 145l23.51 23.52A12 12 0 0 0 160 172h14.32ZM198.32 148H165l-20-20 20-20h33.35l20 20Z" /></Svg>;
export default PixLogo;