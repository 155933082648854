/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CameraSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="camera-slash-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-camera-slash-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52.44 36a6 6 0 0 0-8.88 8l12.69 14H48a22 22 0 0 0-22 22v112a22 22 0 0 0 22 22h150.07l5.49 6a6 6 0 0 0 8.88-8.08Zm53.06 76.2 40.11 44.13A29.67 29.67 0 0 1 128 162a30 30 0 0 1-22.5-49.84ZM48 202a10 10 0 0 1-10-10V80a10 10 0 0 1 10-10h19.16l30.23 33.25a42 42 0 0 0 56.33 62L187.16 202ZM230 80v106a6 6 0 0 1-12 0V80a10 10 0 0 0-10-10h-32a6 6 0 0 1-5-2.67L156.78 46H99.21l-.23.34a6 6 0 0 1-10-6.65l2-3A6 6 0 0 1 96 34h64a6 6 0 0 1 5 2.67L179.21 58H208a22 22 0 0 1 22 22Z" /></Svg>;
export default CameraSlash;