/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const SpeakerHifi = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="speaker-hifi__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-speaker-hifi'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M192 24H64a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16Zm0 192H64V40h128ZM116 76a12 12 0 1 1 12 12 12 12 0 0 1-12-12Zm12 116a40 40 0 1 0-40-40 40 40 0 0 0 40 40Zm0-64a24 24 0 1 1-24 24 24 24 0 0 1 24-24Z" /></Svg>;
export default SpeakerHifi;