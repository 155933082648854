/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GlobeStand = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-stand-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-stand-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 180a76 76 0 1 0-76-76 76.08 76.08 0 0 0 76 76Zm0-144a68 68 0 1 1-68 68 68.07 68.07 0 0 1 68-68Zm74.89 140.28a4 4 0 0 1-.12 5.65 107.31 107.31 0 0 1-70.77 30V236h28a4 4 0 0 1 0 8h-64a4 4 0 0 1 0-8h28v-24.08A108 108 0 0 1 58.06 29.23a4 4 0 1 1 5.77 5.54 100 100 0 0 0 141.4 141.39 4 4 0 0 1 5.66.12Z" /></Svg>;
export default GlobeStand;