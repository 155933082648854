/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const VideoConference = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="video-conference-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-video-conference-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a12 12 0 0 0-12 12v144a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12Zm4 12v68h-56V52h52a4 4 0 0 1 4 4ZM36 200V56a4 4 0 0 1 4-4h116v152H40a4 4 0 0 1-4-4Zm180 4h-52v-72h56v68a4 4 0 0 1-4 4ZM184 88a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm16 80a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm-68.13-1a36.2 36.2 0 0 0-20.43-23.67 28 28 0 1 0-30.88 0A36.28 36.28 0 0 0 60.13 167a4 4 0 0 0 2.87 4.87 3.87 3.87 0 0 0 1 .13 4 4 0 0 0 3.87-3C71 157 83.05 148 96 148s25.06 9 28.13 21a4 4 0 1 0 7.74-2ZM76 120a20 20 0 1 1 20 20 20 20 0 0 1-20-20Z" /></Svg>;
export default VideoConference;