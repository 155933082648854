/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Seat = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="seat-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-seat-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220 232a4 4 0 0 1-4 4H112a4 4 0 0 1 0-8h104a4 4 0 0 1 4 4Zm0-72v32a12 12 0 0 1-12 12h-93.89a11.94 11.94 0 0 1-10.74-6.63l-58.11-116a12.07 12.07 0 0 1 0-10.74l22.12-44a12 12 0 0 1 16-5.42l33.78 14.27.23.11a12 12 0 0 1 5.37 16.1l-.06.1-14.3 26.47a4 4 0 0 0 0 3.53l31.78 64a4 4 0 0 0 3.57 2.2H208A12 12 0 0 1 220 160Zm-8 0a4 4 0 0 0-4-4h-64.23a11.94 11.94 0 0 1-10.77-6.63l-31.78-64a12 12 0 0 1 0-10.72l.06-.11 14.27-26.47a4 4 0 0 0-1.71-5.27L80.13 28.53l-.24-.11a3.91 3.91 0 0 0-1.78-.42 4 4 0 0 0-3.58 2.21l-22.12 44a4 4 0 0 0 0 3.57l58.11 116a4 4 0 0 0 3.58 2.21H208a4 4 0 0 0 4-4Z" /></Svg>;
export default Seat;