/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagBannerFold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-banner-fold-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-banner-fold-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M235.36 45.83A4 4 0 0 0 232 44h-80a4 4 0 0 0-3.64 2.34L138.52 68H28a4 4 0 0 0-3 6.69L62.59 116 25 157.31a4 4 0 0 0 3 6.69h73.09a4 4 0 0 0 3.64-2.35l9.85-21.65h71.21l-37.43 82.34a4 4 0 0 0 2 5.3 4.07 4.07 0 0 0 1.64.36 4 4 0 0 0 3.64-2.35l80-176a4 4 0 0 0-.28-3.82ZM98.52 156H37l34-37.31a4 4 0 0 0 0-5.38L37 76h97.84Zm90.9-24h-71.21l36.37-80h71.21Z" /></Svg>;
export default FlagBannerFold;