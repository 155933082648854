/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Sliders = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="sliders-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-sliders-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M60 108.29V40a4 4 0 0 0-8 0v68.29a28 28 0 0 0 0 55.42V216a4 4 0 0 0 8 0v-52.29a28 28 0 0 0 0-55.42ZM56 156a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm76-95.71V40a4 4 0 0 0-8 0v20.29a28 28 0 0 0 0 55.42V216a4 4 0 0 0 8 0V115.71a28 28 0 0 0 0-55.42ZM128 108a20 20 0 1 1 20-20 20 20 0 0 1-20 20Zm100 60a28 28 0 0 0-24-27.71V40a4 4 0 0 0-8 0v100.29a28 28 0 0 0 0 55.42V216a4 4 0 0 0 8 0v-20.29A28 28 0 0 0 228 168Zm-28 20a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" /></Svg>;
export default Sliders;