/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Basketball = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="basketball-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-basketball-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20ZM60 177.28A83.59 83.59 0 0 1 44.87 140h30.27A83.1 83.1 0 0 1 60 177.28ZM44.87 116A83.59 83.59 0 0 1 60 78.72 83.1 83.1 0 0 1 75.14 116ZM116 211.13a83.63 83.63 0 0 1-39-16.46A106.94 106.94 0 0 0 99.34 140H116Zm0-95.13H99.34A106.94 106.94 0 0 0 77 61.33a83.63 83.63 0 0 1 39-16.46Zm80-37.28A83.59 83.59 0 0 1 211.13 116h-30.27A83.1 83.1 0 0 1 196 78.72Zm-56 132.41V140h16.66A106.94 106.94 0 0 0 179 194.67a83.63 83.63 0 0 1-39 16.46ZM156.66 116H140V44.87a83.63 83.63 0 0 1 39 16.46A106.94 106.94 0 0 0 156.66 116ZM196 177.28A83.1 83.1 0 0 1 180.86 140h30.27A83.59 83.59 0 0 1 196 177.28Z" /></Svg>;
export default Basketball;