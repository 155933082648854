/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Wrench = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="wrench-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-wrench-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M230.47 67.5a12 12 0 0 0-19.26-4.32L172.43 99l-12.68-2.72L157 83.57l35.79-38.78a12 12 0 0 0-4.32-19.26 76.07 76.07 0 0 0-100.06 96.11l-57.49 52.54a4.68 4.68 0 0 0-.39.38 36 36 0 0 0 50.91 50.91l.38-.39 52.54-57.49A76.05 76.05 0 0 0 230.47 67.5ZM160 148a51.5 51.5 0 0 1-23.35-5.52 12 12 0 0 0-14.26 2.62l-58.08 63.56a12 12 0 0 1-17-17l63.55-58.07a12 12 0 0 0 2.62-14.26A51.5 51.5 0 0 1 108 96a52.06 52.06 0 0 1 52-52h.89l-25.72 27.87a12 12 0 0 0-2.91 10.65l5.66 26.35a12 12 0 0 0 9.21 9.21l26.35 5.66a12 12 0 0 0 10.65-2.91L212 95.12v.89A52.06 52.06 0 0 1 160 148Z" /></Svg>;
export default Wrench;