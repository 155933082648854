/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlagCheckered = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flag-checkered-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flag-checkered-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.32 48.75a8 8 0 0 0-8.56 1.25c-28 24.22-51.72 12.48-79.21-1.13C111.07 34.76 78.78 18.79 42.76 50A8 8 0 0 0 40 56v168a8 8 0 0 0 16 0v-44.23c26.79-21.16 49.87-9.75 76.45 3.41 16.4 8.11 34.06 16.85 53 16.85 13.93 0 28.54-4.75 43.82-18a8 8 0 0 0 2.76-6V56a8 8 0 0 0-4.71-7.25ZM56 160.44v-50.56c16.85-11.28 32.64-11.59 48-7.34v51.74c-15.13-3.81-31.13-3.57-48 6.16Zm48-109.57c9.25 2.83 18.61 7.45 28.45 12.32 11.26 5.57 23.11 11.43 35.55 14.56v51.74c15.35 4.25 31.14 3.94 48-7.35v50.11c-16.87 13.32-32.27 13.72-48 8.91v-51.67c-21.62-6-42.38-21-64-26.95Z" /></Svg>;
export default FlagCheckered;