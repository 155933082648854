/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaperPlane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paper-plane-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paper-plane-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m241.42 198.2-.06-.09L145.3 30.17a20 20 0 0 0-34.82 0L14.58 198.2a20 20 0 0 0 24.06 28.65L128 196.67l89.36 30.18a20 20 0 0 0 6.69 1.15 20 20 0 0 0 17.37-29.8ZM140 175.39V120a12 12 0 0 0-24 0v55.39l-75.28 25.43 87.17-152.76 87.37 152.75Z" /></Svg>;
export default PaperPlane;