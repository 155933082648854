/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Certificate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="certificate-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-certificate-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 86.53V56a16 16 0 0 0-16-16H40a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h120v24a8 8 0 0 0 12 7l24-13.74L220 231a8 8 0 0 0 12-7v-62.53a51.88 51.88 0 0 0 0-74.94ZM128 144H72a8 8 0 0 1 0-16h56a8 8 0 0 1 0 16Zm0-32H72a8 8 0 0 1 0-16h56a8 8 0 0 1 0 16Zm88 98.21-16-9.16a8 8 0 0 0-7.94 0l-16 9.16V172a51.88 51.88 0 0 0 40 0ZM196 160a36 36 0 1 1 36-36 36 36 0 0 1-36 36Z" /></Svg>;
export default Certificate;