/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserList = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-list-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-list-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M156 80a4 4 0 0 1 4-4h88a4 4 0 0 1 0 8h-88a4 4 0 0 1-4-4Zm92 44h-88a4 4 0 0 0 0 8h88a4 4 0 0 0 0-8Zm0 48h-64a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Zm-100.13 19a4 4 0 0 1-2.87 4.87 3.87 3.87 0 0 1-1 .13 4 4 0 0 1-3.87-3c-6.71-26.08-32-45-60.13-45s-53.41 18.92-60.13 45a4 4 0 1 1-7.74-2c5.92-23 24.57-41.14 47.52-48a44 44 0 1 1 40.7 0c22.95 6.86 41.65 25 47.52 48ZM80 140a36 36 0 1 0-36-36 36 36 0 0 0 36 36Z" /></Svg>;
export default UserList;