/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Numpad = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="numpad-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-numpad-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M76 48a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm52-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm64 24a12 12 0 1 0-12-12 12 12 0 0 0 12 12ZM64 92a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm64 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm64 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12ZM64 148a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm64 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm0 56a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm64-56a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z" /></Svg>;
export default Numpad;