/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrafficSignal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="traffic-signal-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-traffic-signal-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 144h-16V80h16a8 8 0 0 0 0-16h-16V40a16 16 0 0 0-16-16H72a16 16 0 0 0-16 16v24H40a8 8 0 0 0 0 16h16v64H40a8 8 0 0 0 0 16h16v56a16 16 0 0 0 16 16h112a16 16 0 0 0 16-16v-56h16a8 8 0 0 0 0-16Zm-88-28a28 28 0 1 1 28-28 28 28 0 0 1-28 28Zm0 24a28 28 0 1 1-28 28 28 28 0 0 1 28-28Z" /></Svg>;
export default TrafficSignal;