/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderMinus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-minus__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-minus'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 72h-84.69L104 44.69A15.86 15.86 0 0 0 92.69 40H40a16 16 0 0 0-16 16v144.62A15.4 15.4 0 0 0 39.38 216h177.51A15.13 15.13 0 0 0 232 200.89V88a16 16 0 0 0-16-16ZM92.69 56l16 16H40V56ZM216 200H40V88h176Zm-112-64h48a8 8 0 0 1 0 16h-48a8 8 0 0 1 0-16Z" /></Svg>;
export default FolderMinus;