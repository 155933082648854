/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockUser = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-user-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-user-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M134 72v46.29l39.32-19.66a6 6 0 0 1 5.36 10.74l-48 24A6 6 0 0 1 122 128V72a6 6 0 0 1 12 0Zm-6 146a90 90 0 1 1 90-90 6 6 0 0 0 12 0 102 102 0 1 0-102 102 6 6 0 0 0 0-12Zm101.8 4.46a6 6 0 0 1-11.6 3.08C215.14 214 204.37 206 192 206s-23.14 8-26.2 19.54A6 6 0 0 1 160 230a6.26 6.26 0 0 1-1.54-.2 6 6 0 0 1-4.26-7.34A38.09 38.09 0 0 1 172.72 199a30 30 0 1 1 38.56 0 38.09 38.09 0 0 1 18.52 23.46ZM174 176a18 18 0 1 0 18-18 18 18 0 0 0-18 18Z" /></Svg>;
export default ClockUser;