/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Microphone = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="microphone-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-microphone-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 174a46.06 46.06 0 0 0 46-46V64a46 46 0 0 0-92 0v64a46.06 46.06 0 0 0 46 46ZM94 64a34 34 0 0 1 68 0v64a34 34 0 0 1-68 0Zm40 141.75V240a6 6 0 0 1-12 0v-34.25A78.09 78.09 0 0 1 50 128a6 6 0 0 1 12 0 66 66 0 0 0 132 0 6 6 0 0 1 12 0 78.09 78.09 0 0 1-72 77.75Z" /></Svg>;
export default Microphone;