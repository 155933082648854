/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vignette = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vignette-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vignette-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 36H40a20 20 0 0 0-20 20v144a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20Zm-4 160H44V60h168ZM81.43 166.05C94 175.05 110.56 180 128 180s34-4.95 46.57-13.95C188.19 156.32 196 142.45 196 128s-7.81-28.32-21.43-38C162 81 145.44 76 128 76s-34 5-46.57 14C67.81 99.68 60 113.55 60 128s7.81 28.32 21.43 38.05ZM128 100c23.85 0 44 12.82 44 28s-20.15 28-44 28-44-12.82-44-28 20.15-28 44-28Z" /></Svg>;
export default Vignette;