/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlowerLotus = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flower-lotus-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flower-lotus-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M242.37 123.65a11.54 11.54 0 0 0-7.08-5.46 71.5 71.5 0 0 0-26.29-1.67c5.48-21.46 2.38-38.36-.75-48a12.16 12.16 0 0 0-14.16-8.19 82.85 82.85 0 0 0-31 14.17 91.06 91.06 0 0 0-27.9-36 11.91 11.91 0 0 0-14.44 0 91.06 91.06 0 0 0-27.9 36 82.92 82.92 0 0 0-31-14.17 12.16 12.16 0 0 0-14.16 8.19c-3.13 9.68-6.23 26.58-.75 48a71.5 71.5 0 0 0-26.26 1.67 11.54 11.54 0 0 0-7.08 5.46 12 12 0 0 0-1.2 9.22c3.24 12 13.2 34.81 43.52 52.92S113.45 204 128 204s41.61 0 72.07-18.21 40.28-40.93 43.52-52.92a12 12 0 0 0-1.22-9.22ZM195.8 68.11a4.2 4.2 0 0 1 4.87 2.89c4 12.5 8 38.35-10.77 71-10.45 18.19-25.11 32.2-38.11 41.44C162 170 172 149.24 172 119.19a113.35 113.35 0 0 0-5.88-37 75.28 75.28 0 0 1 29.68-14.08ZM55.33 71a4.19 4.19 0 0 1 4.87-2.84 75.28 75.28 0 0 1 29.68 14.03 113.35 113.35 0 0 0-5.88 37c0 30.05 10 50.82 20.21 64.23-13-9.24-27.66-23.25-38.11-41.44C47.32 109.3 51.29 83.45 55.33 71Zm4.72 108c-27.9-16.67-37-37.32-39.9-48.15a4 4 0 0 1 .41-3.13 3.59 3.59 0 0 1 2.21-1.73 64.62 64.62 0 0 1 26.73-1 123.48 123.48 0 0 0 9.66 21c13.28 23.1 32.66 39.67 48.27 49.11a116.34 116.34 0 0 1-47.38-16.17Zm68 16.34a75.75 75.75 0 0 1-17.08-16.4C98.37 162.58 92 142.5 92 119.19c0-44.25 23.49-66.75 33.59-74.36a4 4 0 0 1 4.82 0c10.1 7.61 33.59 30.11 33.59 74.36 0 23.31-6.37 43.39-18.92 59.68a75.75 75.75 0 0 1-17.08 16.4Zm107.85-64.49c-2.92 10.83-12 31.48-39.9 48.15a116.34 116.34 0 0 1-47.38 16.15c15.61-9.44 35-26 48.27-49.11a123.48 123.48 0 0 0 9.66-21 64.45 64.45 0 0 1 26.73 1 3.59 3.59 0 0 1 2.21 1.73 4 4 0 0 1 .36 3.01Z" /></Svg>;
export default FlowerLotus;