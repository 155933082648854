/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Robot = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="robot-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-robot-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 52h-68V16a4 4 0 0 0-8 0v36H56a28 28 0 0 0-28 28v112a28 28 0 0 0 28 28h144a28 28 0 0 0 28-28V80a28 28 0 0 0-28-28Zm20 140a20 20 0 0 1-20 20H56a20 20 0 0 1-20-20V80a20 20 0 0 1 20-20h144a20 20 0 0 1 20 20ZM76 108a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm88 0a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm0 32H92a24 24 0 0 0 0 48h72a24 24 0 0 0 0-48Zm-20 8v32h-32v-32Zm-68 16a16 16 0 0 1 16-16h12v32H92a16 16 0 0 1-16-16Zm88 16h-12v-32h12a16 16 0 0 1 0 32Z" /></Svg>;
export default Robot;