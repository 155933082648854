/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const MouseMiddleClick = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="mouse-middle-click-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-mouse-middle-click-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 20h-32a60.07 60.07 0 0 0-60 60v96a60.07 60.07 0 0 0 60 60h32a60.07 60.07 0 0 0 60-60V80a60.07 60.07 0 0 0-60-60Zm52 60v28h-48V88a12 12 0 0 0-12-12h-4V28h12a52.06 52.06 0 0 1 52 52Zm-80 8a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v48a4 4 0 0 1-4 4h-16a4 4 0 0 1-4-4Zm-4-60h12v48h-4a12 12 0 0 0-12 12v20H60V80a52.06 52.06 0 0 1 52-52Zm32 200h-32a52.06 52.06 0 0 1-52-52v-60h48v20a12 12 0 0 0 12 12h16a12 12 0 0 0 12-12v-20h48v60a52.06 52.06 0 0 1-52 52Z" /></Svg>;
export default MouseMiddleClick;