/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListHeart = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-heart-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-heart-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M124 192a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h72a12 12 0 0 1 12 12ZM40 76h176a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Zm0 64h56a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Zm212 4c0 17-9.53 33.56-28.32 49.22a151.47 151.47 0 0 1-26.31 17.51 12 12 0 0 1-10.74 0 151.47 151.47 0 0 1-26.31-17.51C141.53 177.56 132 161 132 144a36 36 0 0 1 60-26.81A36 36 0 0 1 252 144Zm-24 0a12 12 0 0 0-24 0 12 12 0 0 1-24 0 12 12 0 0 0-24 0c0 17.23 22.85 34.43 36 42.29 13.14-7.85 36-25.06 36-42.29Z" /></Svg>;
export default ListHeart;