/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Vibrate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="vibrate-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-vibrate-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 56v144a16 16 0 0 1-16 16H96a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M160 32H96a24 24 0 0 0-24 24v144a24 24 0 0 0 24 24h64a24 24 0 0 0 24-24V56a24 24 0 0 0-24-24Zm8 168a8 8 0 0 1-8 8H96a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8h64a8 8 0 0 1 8 8Zm48-112v80a8 8 0 0 1-16 0V88a8 8 0 0 1 16 0Zm32 16v48a8 8 0 0 1-16 0v-48a8 8 0 0 1 16 0ZM56 88v80a8 8 0 0 1-16 0V88a8 8 0 0 1 16 0Zm-32 16v48a8 8 0 0 1-16 0v-48a8 8 0 0 1 16 0Z" /></Svg>;
export default Vibrate;