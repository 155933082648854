/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shapes = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shapes-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shapes-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M71.49 60.55a12 12 0 0 0-23 0l-36 120A12 12 0 0 0 24 196h72a12 12 0 0 0 11.49-15.45ZM40.13 172 60 105.76 79.87 172ZM212 74a54 54 0 1 0-54 54 54.06 54.06 0 0 0 54-54Zm-84 0a30 30 0 1 1 30 30 30 30 0 0 1-30-30Zm96 70h-88a12 12 0 0 0-12 12v52a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-52a12 12 0 0 0-12-12Zm-12 52h-64v-28h64Z" /></Svg>;
export default Shapes;