/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Laptop = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="laptop-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-laptop-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 168h-8V72a24 24 0 0 0-24-24H56a24 24 0 0 0-24 24v96h-8a8 8 0 0 0-8 8v16a24 24 0 0 0 24 24h176a24 24 0 0 0 24-24v-16a8 8 0 0 0-8-8ZM112 72h32a8 8 0 0 1 0 16h-32a8 8 0 0 1 0-16Zm112 120a8 8 0 0 1-8 8H40a8 8 0 0 1-8-8v-8h192Z" /></Svg>;
export default Laptop;