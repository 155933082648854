/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GpsSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gps-slash-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gps-slash-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M244 128a4 4 0 0 1-4 4h-28.1a83.08 83.08 0 0 1-7.24 30.38 4 4 0 0 1-3.65 2.37 3.94 3.94 0 0 1-1.63-.36 4 4 0 0 1-2-5.28A76.06 76.06 0 0 0 103.62 56a4 4 0 1 1-2.56-7.57A83.56 83.56 0 0 1 124 44.1V16a4 4 0 0 1 8 0v28.1a84.11 84.11 0 0 1 79.9 79.9H240a4 4 0 0 1 4 4Zm-33 85.31a4 4 0 1 1-5.92 5.38l-23.56-25.91A83.81 83.81 0 0 1 132 211.9V240a4 4 0 0 1-8 0v-28.1A84.11 84.11 0 0 1 44.1 132H16a4 4 0 0 1 0-8h28.1a84.24 84.24 0 0 1 24.5-55.39L45 42.69a4 4 0 0 1 6-5.38L184.77 184.5Zm-34.87-26.47L74 74.53a76 76 0 0 0 102.1 112.31Z" /></Svg>;
export default GpsSlash;