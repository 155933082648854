/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CaretDoubleDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="caret-double-down-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-caret-double-down-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216.49 127.51a12 12 0 0 1 0 17l-80 80a12 12 0 0 1-17 0l-80-80a12 12 0 1 1 17-17L128 199l71.51-71.52a12 12 0 0 1 16.98.03Zm-97 17a12 12 0 0 0 17 0l80-80a12 12 0 0 0-17-17L128 119 56.49 47.51a12 12 0 0 0-17 17Z" /></Svg>;
export default CaretDoubleDown;