/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TrendUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="trend-up-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-trend-up-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 56v64a4 4 0 0 1-8 0V65.66l-89.17 89.17a4 4 0 0 1-5.66 0L96 117.66l-69.17 69.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0L136 146.34 222.34 60H168a4 4 0 0 1 0-8h64a4 4 0 0 1 4 4Z" /></Svg>;
export default TrendUp;