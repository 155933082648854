/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PauseCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pause-circle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pause-circle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm0 192a90 90 0 1 1 90-90 90.1 90.1 0 0 1-90 90ZM110 96v64a6 6 0 0 1-12 0V96a6 6 0 0 1 12 0Zm48 0v64a6 6 0 0 1-12 0V96a6 6 0 0 1 12 0Z" /></Svg>;
export default PauseCircle;