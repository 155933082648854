/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BatteryVerticalFull = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="battery-vertical-full-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-battery-vertical-full-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M92 8a4 4 0 0 1 4-4h64a4 4 0 0 1 0 8H96a4 4 0 0 1-4-4Zm104 48v168a20 20 0 0 1-20 20H80a20 20 0 0 1-20-20V56a20 20 0 0 1 20-20h96a20 20 0 0 1 20 20Zm-8 0a12 12 0 0 0-12-12H80a12 12 0 0 0-12 12v168a12 12 0 0 0 12 12h96a12 12 0 0 0 12-12Zm-28 20H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Zm0 40H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Zm0 40H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Zm0 40H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8Z" /></Svg>;
export default BatteryVerticalFull;