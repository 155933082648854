/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TreeEvergreen = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tree-evergreen-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tree-evergreen-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.16 189.54-51-65.54H208a4 4 0 0 0 3.17-6.44l-80-104a4 4 0 0 0-6.34 0l-80 104A4 4 0 0 0 48 124h31.82l-51 65.54A4 4 0 0 0 32 196h92v44a4 4 0 0 0 8 0v-44h92a4 4 0 0 0 3.16-6.46ZM40.18 188l51-65.54A4 4 0 0 0 88 116H56.12L128 22.56 199.88 116H168a4 4 0 0 0-3.16 6.46l51 65.54Z" /></Svg>;
export default TreeEvergreen;