/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Detective = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="detective-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-detective-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 114h-28.94L171 47.77a14 14 0 0 0-22.16-.61l-12.91 14.92a1.15 1.15 0 0 0-.14.17 10 10 0 0 1-15.58 0 1.15 1.15 0 0 0-.14-.17L107.2 47.16a14 14 0 0 0-22.2.61L36.94 114H8a6 6 0 0 0 0 12h240a6 6 0 0 0 0-12ZM94.75 54.82a2 2 0 0 1 3.15-.07l.15.17 12.86 14.92A21.88 21.88 0 0 0 128 78a21.88 21.88 0 0 0 17.09-8.16L158 54.92l.15-.17a2 2 0 0 1 3.15.07l43 59.18H51.77ZM180 146a34 34 0 0 0-33.94 32h-36.12a34 34 0 1 0-1.44 12h39a34 34 0 1 0 32.5-44ZM76 202a22 22 0 1 1 22-22 22 22 0 0 1-22 22Zm104 0a22 22 0 1 1 22-22 22 22 0 0 1-22 22Z" /></Svg>;
export default Detective;