/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Paragraph = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paragraph-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paragraph-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M144 48v112H96a56 56 0 0 1 0-112Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 40H96a64 64 0 0 0 0 128h40v40a8 8 0 0 0 16 0V56h24v152a8 8 0 0 0 16 0V56h16a8 8 0 0 0 0-16Zm-72 112H96a48 48 0 0 1 0-96h40Z" /></Svg>;
export default Paragraph;