/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FileC = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="file-c-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-file-c-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M48 180c0 11 7.18 20 16 20a14.07 14.07 0 0 0 10.07-4.51 8.19 8.19 0 0 1 10.88-.9 8 8 0 0 1 .83 11.81A30.06 30.06 0 0 1 64 216c-17.65 0-32-16.15-32-36s14.35-36 32-36a30 30 0 0 1 21.39 9.19 8.26 8.26 0 0 1 .73 11.09 8 8 0 0 1-11.9.38A14.24 14.24 0 0 0 64 160c-8.82 0-16 9-16 20Zm168-92v128a16 16 0 0 1-16 16h-84a4 4 0 0 1-4-4V124a4 4 0 0 0-4-4H44a4 4 0 0 1-4-4V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-20 0-44-44v44Z" /></Svg>;
export default FileC;