/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Globe = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="globe-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-globe-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm81.57 64h-40.38a132.58 132.58 0 0 0-25.73-50.67A90.29 90.29 0 0 1 209.57 90Zm8.43 38a89.7 89.7 0 0 1-3.83 26h-42.36a155.43 155.43 0 0 0 0-52h42.36a89.7 89.7 0 0 1 3.83 26Zm-90 87.83a110 110 0 0 1-15.19-19.45A124.24 124.24 0 0 1 99.35 166h57.3a124.24 124.24 0 0 1-13.46 30.38A110 110 0 0 1 128 215.83ZM96.45 154a139.18 139.18 0 0 1 0-52h63.1a139.18 139.18 0 0 1 0 52ZM38 128a89.7 89.7 0 0 1 3.83-26h42.36a155.43 155.43 0 0 0 0 52H41.83A89.7 89.7 0 0 1 38 128Zm90-87.83a110 110 0 0 1 15.19 19.45A124.24 124.24 0 0 1 156.65 90h-57.3a124.24 124.24 0 0 1 13.46-30.38A110 110 0 0 1 128 40.17Zm-15.46-.84A132.58 132.58 0 0 0 86.81 90H46.43a90.29 90.29 0 0 1 66.11-50.67ZM46.43 166h40.38a132.58 132.58 0 0 0 25.73 50.67A90.29 90.29 0 0 1 46.43 166Zm97 50.67A132.58 132.58 0 0 0 169.19 166h40.38a90.29 90.29 0 0 1-66.11 50.67Z" /></Svg>;
export default Globe;