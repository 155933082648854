/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ListMagnifyingGlass = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="list-magnifying-glass-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-list-magnifying-glass-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M28 64a12 12 0 0 1 12-12h176a12 12 0 0 1 0 24H40a12 12 0 0 1-12-12Zm12 76h64a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Zm80 40H40a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24Zm120.49 20.49a12 12 0 0 1-17 0l-18.08-18.08a44 44 0 1 1 17-17l18.08 18.07a12 12 0 0 1 0 17.01ZM184 164a20 20 0 1 0-20-20 20 20 0 0 0 20 20Z" /></Svg>;
export default ListMagnifyingGlass;