/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Queue = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="queue-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-queue-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M34 64a6 6 0 0 1 6-6h176a6 6 0 0 1 0 12H40a6 6 0 0 1-6-6Zm102 58H40a6 6 0 0 0 0 12h96a6 6 0 0 0 0-12Zm0 64H40a6 6 0 0 0 0 12h96a6 6 0 0 0 0-12Zm110-26a6 6 0 0 1-2.82 5.09l-64 40A6 6 0 0 1 170 200v-80a6 6 0 0 1 9.18-5.09l64 40A6 6 0 0 1 246 160Zm-17.32 0L182 130.83v58.34Z" /></Svg>;
export default Queue;