/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Asclepius = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="asclepius-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-asclepius-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M136 128v72h11.62A12.25 12.25 0 0 0 160 188.53a12 12 0 0 0-5.93-10.87 4.08 4.08 0 0 1-2.06-3.49v-8.79a4 4 0 0 1 5.25-3.81A28.06 28.06 0 0 1 176 187.71c.16 15.56-13 28.29-28.59 28.29H136v16a8 8 0 0 1-16 0v-16H96a8 8 0 0 1-8-8.53 8.17 8.17 0 0 1 8.27-7.47H120v-72h-15.54c-8.6 0-16 6.6-16.44 15.19a16 16 0 0 0 12.87 16.51 3.94 3.94 0 0 1 3.11 3.89V172a4 4 0 0 1-4 4 36 36 0 0 1-36-36.87c.47-19.65 17-35.13 36.68-35.13H120V24a8 8 0 0 1 16 0v80h32a16 16 0 0 0 16-16.81C183.56 78.6 176.14 72 167.54 72H156a4 4 0 0 1-4-4V44a4 4 0 0 1 4-4h15.22c24.62 0 45.2 20.15 44.77 44.76A44 44 0 0 1 172 128ZM92.66 72H100a4 4 0 0 0 4-4V44a4 4 0 0 0-4-4H64a40 40 0 0 0-40 40v8a8 8 0 0 0 8 8h24a40 40 0 0 0 36.66-24Z" /></Svg>;
export default Asclepius;