/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FolderSimpleStar = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="folder-simple-star-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-folder-simple-star-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m205.6 183.41 8.07 32.59L184 198.54 154.33 216l8.07-32.59-26.4-21.8 34.65-2.67L184 128l13.35 30.94 34.65 2.67Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M128 208a8 8 0 0 1-8 8H40a16 16 0 0 1-16-16V64a16 16 0 0 1 16-16h53.33a16.12 16.12 0 0 1 9.6 3.2L130.67 72H216a16 16 0 0 1 16 16v32a8 8 0 0 1-16 0V88h-88a8 8 0 0 1-4.8-1.6L93.33 64H40v136h80a8 8 0 0 1 8 8Zm109.09-40.22-22.51 18.59 6.85 27.71a8 8 0 0 1-11.82 8.81L184 207.82l-25.61 15.07a8 8 0 0 1-11.82-8.81l6.85-27.71-22.51-18.59a8 8 0 0 1 4.47-14.14l29.84-2.31 11.43-26.5a8 8 0 0 1 14.7 0l11.43 26.5 29.84 2.31a8 8 0 0 1 4.47 14.14Zm-25.47.28-14.89-1.15a8 8 0 0 1-6.73-4.8l-6-13.92-6 13.92a8 8 0 0 1-6.73 4.8l-14.89 1.15 11.11 9.18a8 8 0 0 1 2.68 8.09l-3.5 14.12 13.27-7.81a8 8 0 0 1 8.12 0l13.27 7.81-3.5-14.12a8 8 0 0 1 2.68-8.09Z" /></Svg>;
export default FolderSimpleStar;