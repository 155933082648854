/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Empty = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="empty-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-empty-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M195.51 62.66 212.44 44a6 6 0 1 0-8.88-8l-16.93 18.58A94 94 0 0 0 60.49 193.34L43.56 212a6 6 0 0 0 8.88 8l16.93-18.62A94 94 0 0 0 195.51 62.66ZM46 128a81.93 81.93 0 0 1 132.53-64.51L68.59 184.43A81.69 81.69 0 0 1 46 128Zm82 82a81.57 81.57 0 0 1-50.53-17.49L187.41 71.57A81.94 81.94 0 0 1 128 210Z" /></Svg>;
export default Empty;