/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IceCream = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ice-cream-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ice-cream-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204 100.4V96a76 76 0 0 0-152 0v4.4a20 20 0 0 0 4 39.6h5.61l56 97.95a12 12 0 0 0 20.84 0l56-97.95H200a20 20 0 0 0 4-39.6ZM131.47 234a4 4 0 0 1-6.94 0l-53.71-94h28.57l42.9 75.06Zm5.64-94 24 42.06L146.89 207l-38.28-67Zm28.64 34-19.43-34h38.86ZM200 132H56a12 12 0 0 1 0-24 4 4 0 0 0 4-4v-8a68 68 0 0 1 136 0v8a4 4 0 0 0 4 4 12 12 0 0 1 0 24Z" /></Svg>;
export default IceCream;