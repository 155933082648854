/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PersonSimpleRun = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="person-simple-run-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-person-simple-run-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 56a24 24 0 1 1-24-24 24 24 0 0 1 24 24Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M152 88a32 32 0 1 0-32-32 32 32 0 0 0 32 32Zm0-48a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm67.31 100.68c-.61.28-7.49 3.28-19.67 3.28-13.85 0-34.55-3.88-60.69-20a169.31 169.31 0 0 1-15.41 32.34 104.29 104.29 0 0 1 31.31 15.81C173.92 186.65 184 207.35 184 232a8 8 0 0 1-16 0c0-41.7-34.69-56.71-54.14-61.85-.55.7-1.12 1.41-1.69 2.1-19.64 23.8-44.25 36.18-71.63 36.18a92.29 92.29 0 0 1-9.34-.43 8 8 0 0 1 1.6-16c25.92 2.59 48.47-7.49 67-30 12.49-15.14 21-33.61 25.25-47-38.92-22.66-63.78-3.37-64.05-3.16a8 8 0 1 1-10-12.48c1.5-1.2 37.22-29 89.51 6.57 45.47 30.91 71.93 20.31 72.18 20.19a8 8 0 1 1 6.63 14.56Z" /></Svg>;
export default PersonSimpleRun;