/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NotSupersetOf = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="not-superset-of-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-not-superset-of-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 188H89.67l14.55-16H152a68 68 0 0 0 52.86-110.71l12-13.22a12 12 0 0 0-17.76-16.14l-12.4 13.63A67.62 67.62 0 0 0 152 36H56a12 12 0 0 0 0 24h96a43.74 43.74 0 0 1 18.06 3.89L93.6 148H56a12 12 0 0 0 0 24h15.78l-32.66 35.93a12 12 0 1 0 17.76 16.14l11-12.07H208a12 12 0 0 0 0-24ZM188.44 79.36A44 44 0 0 1 152 148h-26Z" /></Svg>;
export default NotSupersetOf;