/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bag = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bag-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bag-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 60h-36.17a52 52 0 0 0-103.66 0H40a20 20 0 0 0-20 20v120a20 20 0 0 0 20 20h176a20 20 0 0 0 20-20V80a20 20 0 0 0-20-20Zm-88-24a28 28 0 0 1 27.71 24h-55.42A28 28 0 0 1 128 36Zm84 160H44V84h32v12a12 12 0 0 0 24 0V84h56v12a12 12 0 0 0 24 0V84h32Z" /></Svg>;
export default Bag;