/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Tilde = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="tilde-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-tilde-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-10 99.66c-13.19 15-25.34 20.29-36.37 20.29-14.94 0-27.81-9.61-38.43-17.54-19.2-14.34-31.89-23.81-53.2.48a8 8 0 1 1-12-10.55c31.05-35.41 56.34-16.53 74.8-2.75 19.2 14.34 31.89 23.81 53.2-.48a8 8 0 1 1 12 10.55Z" /></Svg>;
export default Tilde;