/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ImageBroken = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="image-broken-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-image-broken-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h64a8 8 0 0 0 7.59-5.47l14.83-44.48L163 151.43a8.07 8.07 0 0 0 4.46-4.46l14.62-36.55 44.48-14.83A8 8 0 0 0 232 88V56a16 16 0 0 0-16-16Zm-99 112.57a8 8 0 0 0-4.62 4.9L98.23 200H40v-39.31l46.34-46.35a8 8 0 0 1 11.32 0l32.84 32.84Zm115-30.84V200a16 16 0 0 1-16 16h-78.27a8 8 0 0 1-7.59-10.53l7.94-23.8a8 8 0 0 1 4.61-4.9l35.77-14.31 14.31-35.77a8 8 0 0 1 4.9-4.61l23.8-7.94a8 8 0 0 1 10.53 7.59Z" /></Svg>;
export default ImageBroken;