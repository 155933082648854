/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GoogleDriveLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-drive-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-drive-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M24 152h64l-32.88 54.8a7.91 7.91 0 0 1-2.74-2.8l-27.33-47.85A8 8 0 0 1 24 152Zm144 0 32.88 54.8a7.91 7.91 0 0 0 2.74-2.83l27.32-47.82A8 8 0 0 0 232 152ZM100.43 32a8.06 8.06 0 0 0-3.84 1L128 85.33 159.41 33a8.07 8.07 0 0 0-3.85-1Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M237.6 143.78 169.13 31.52A15.91 15.91 0 0 0 155.56 24h-55.13a15.89 15.89 0 0 0-13.56 7.52l-.05.07L18.44 143.7a16 16 0 0 0-.33 16.42l27.32 47.82A16 16 0 0 0 59.32 216h137.35a16 16 0 0 0 13.89-8.06l27.32-47.82a15.91 15.91 0 0 0-.28-16.34ZM219 144h-46.48l-35.19-58.67 22.75-37.92Zm-116.87 0L128 100.88 153.87 144Zm61.34 16 24 40H68.53l24-40ZM128 69.78 110.12 40l35.78-.05ZM95.91 47.41l22.76 37.92L83.47 144H37ZM36.54 160h37.33l-19.15 31.92Zm164.74 31.93L182.12 160h37.41Z" /></Svg>;
export default GoogleDriveLogo;