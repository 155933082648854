/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FlyingSaucer = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="flying-saucer-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-flying-saucer-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M187.77 213.65a12 12 0 0 1-9.42 14.12 11.87 11.87 0 0 1-2.35.23 12 12 0 0 1-11.76-9.65l-4-20a12 12 0 1 1 23.54-4.7ZM128 188a12 12 0 0 0-12 12v24a12 12 0 0 0 24 0v-24a12 12 0 0 0-12-12Zm-41.65-3.77a12 12 0 0 0-14.12 9.42l-4 20a12 12 0 0 0 9.42 14.12A11.87 11.87 0 0 0 80 228a12 12 0 0 0 11.76-9.65l4-20a12 12 0 0 0-9.41-14.12ZM252 112c0 17.92-14.23 33.89-40.08 45-22.61 9.69-52.42 15-83.92 15s-61.31-5.34-83.92-15C18.23 145.89 4 129.92 4 112c0-24.06 25.28-44 67.91-53.89A67.61 67.61 0 0 1 175.77 47.6a69.05 69.05 0 0 1 8.79 10.62C226.9 68.14 252 88.05 252 112ZM84 96.83v3a4 4 0 0 0 3 3.87 184.32 184.32 0 0 0 41 4.3 184.32 184.32 0 0 0 41-4.27 4 4 0 0 0 3-3.87V96a44 44 0 0 0-44-44h-.59C103.47 52.31 84 72.43 84 96.83ZM228 112c0-6.64-7.65-12.76-12.21-15.83a91.73 91.73 0 0 0-20.48-9.88A69.47 69.47 0 0 1 196 96v3.93a27.84 27.84 0 0 1-21.66 27.19A207.92 207.92 0 0 1 128 132a207.92 207.92 0 0 1-46.33-4.88A27.84 27.84 0 0 1 60 99.94v-3.07a70.37 70.37 0 0 1 .82-10.63 92.39 92.39 0 0 0-20.53 9.88C35.7 99.19 28 105.34 28 112c0 14.68 39 36 100 36s100-21.32 100-36Z" /></Svg>;
export default FlyingSaucer;