/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PhonePause = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="phone-pause-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-phone-pause-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212.78 170.13 165.56 149a12 12 0 0 0-11.38 1 3.37 3.37 0 0 0-.38.28L129 171.42a3.9 3.9 0 0 1-3.7.21c-16.24-7.84-33-24.52-40.89-40.57a3.9 3.9 0 0 1 .18-3.69l21.2-25.21c.1-.12.19-.25.28-.38a12 12 0 0 0 1-11.36L85.9 43.28a12 12 0 0 0-12.48-7.19A52.25 52.25 0 0 0 28 88c0 77.2 62.8 140 140 140a52.25 52.25 0 0 0 51.91-45.42 12 12 0 0 0-7.13-12.45Zm-.78 11.45A44.23 44.23 0 0 1 168 220C95.22 220 36 160.78 36 88a44.23 44.23 0 0 1 38.42-44 3.87 3.87 0 0 1 .48 0 4 4 0 0 1 3.67 2.49l21.11 47.14a4 4 0 0 1-.23 3.6l-21.19 25.2c-.1.13-.2.25-.29.39a12 12 0 0 0-.78 11.75c8.69 17.79 26.61 35.58 44.6 44.27a12 12 0 0 0 11.79-.87l.37-.28 24.83-21.12a3.93 3.93 0 0 1 3.57-.27l47.21 21.16a4 4 0 0 1 2.44 4.12ZM196 104V48a4 4 0 0 1 8 0v56a4 4 0 0 1-8 0Zm-40 0V48a4 4 0 0 1 8 0v56a4 4 0 0 1-8 0Z" /></Svg>;
export default PhonePause;