/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const CurrencyBtc = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="currency-btc-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-currency-btc-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M185.08 114.46A48 48 0 0 0 160 37.52V24a12 12 0 0 0-24 0v12h-16V24a12 12 0 0 0-24 0v12H72a12 12 0 0 0 0 24h4v128h-4a12 12 0 0 0 0 24h24v12a12 12 0 0 0 24 0v-12h16v12a12 12 0 0 0 24 0v-12a52 52 0 0 0 25.08-97.54ZM172 84a24 24 0 0 1-24 24h-48V60h48a24 24 0 0 1 24 24Zm-12 104h-60v-56h60a28 28 0 0 1 0 56Z" /></Svg>;
export default CurrencyBtc;