/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ChatsCircle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="chats-circle-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-chats-circle-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236.34 187.09A84 84 0 0 0 172.29 68.9a84 84 0 0 0-152.63 70.19l-6.84 23.26a20 20 0 0 0 24.83 24.83l23.26-6.84a83.94 83.94 0 0 0 22.76 6.74 84.06 84.06 0 0 0 111.42 41.26l23.26 6.84a20 20 0 0 0 24.83-24.83ZM62 155.5a11.88 11.88 0 0 0-3.39.49l-20.72 6.09L44 141.35a12 12 0 0 0-.93-9 60 60 0 1 1 24.63 24.57 12 12 0 0 0-5.7-1.42Zm150.89 24.8a12 12 0 0 0-.93 9l6.09 20.73-20.69-6.03a12 12 0 0 0-9.06.93 60 60 0 0 1-77.3-18.3 83.93 83.93 0 0 0 68.55-91.37 60 60 0 0 1 33.38 85Z" /></Svg>;
export default ChatsCircle;