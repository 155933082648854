/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const NetworkSlash = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="network-slash-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-network-slash-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M56.88 31.93a12 12 0 1 0-17.76 16.14L93.6 108H24a12 12 0 0 0 0 24h28v24h-4a20 20 0 0 0-20 20v32a20 20 0 0 0 20 20h32a20 20 0 0 0 20-20v-32a20 20 0 0 0-20-20h-4v-24h39.42l83.7 92.07a12 12 0 0 0 17.76-16.14ZM76 204H52v-24h24ZM92 42.14V40a20 20 0 0 1 20-20h32a20 20 0 0 1 20 20v32a20 20 0 0 1-20 20h-5.58a12 12 0 1 1 0-24H140V44h-24.14A12 12 0 0 1 92 42.14ZM244 120a12 12 0 0 1-12 12h-28v6.94a12 12 0 0 1-24 0V132h-5.21a12 12 0 1 1 0-24H232a12 12 0 0 1 12 12Z" /></Svg>;
export default NetworkSlash;