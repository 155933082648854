/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PaintBrushHousehold = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="paint-brush-household-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-paint-brush-household-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.81 28.19a28 28 0 0 0-39.6 0l-.21.23-56.22 65.69-13.63-13.63a20 20 0 0 0-28.29 0l-76.69 76.69a4 4 0 0 0 0 5.65l80 80a4 4 0 0 0 5.65 0l76.69-76.69a20 20 0 0 0 0-28.29l-13.63-13.63L227.58 68l.23-.21a28 28 0 0 0 0-39.6ZM96 234.34 73.66 212l25.17-25.18a4 4 0 0 0-5.65-5.65L68 206.34 49.66 188l25.17-25.18a4 4 0 0 0-5.65-5.65L44 182.34 21.66 160 72 109.65 146.35 184ZM222.26 62l-68.85 59a4 4 0 0 0-.23 5.87l16.69 16.69a12 12 0 0 1 0 17L152 178.34 77.66 104l17.86-17.87a12 12 0 0 1 17 0l16.69 16.69a4 4 0 0 0 5.87-.23L194 33.74A20 20 0 0 1 222.26 62Z" /></Svg>;
export default PaintBrushHousehold;