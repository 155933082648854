/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const IdentificationCard = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="identification-card-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-identification-card-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M148 108a12 12 0 0 1 12-12h28a12 12 0 0 1 0 24h-28a12 12 0 0 1-12-12Zm40 28h-20a12 12 0 0 0 0 24h20a12 12 0 0 0 0-24Zm48-80v144a20 20 0 0 1-20 20H40a20 20 0 0 1-20-20V56a20 20 0 0 1 20-20h176a20 20 0 0 1 20 20Zm-24 4H44v136h168ZM58.28 159.37A43.82 43.82 0 0 1 71.53 142a36 36 0 1 1 56.94 0 43.84 43.84 0 0 1 13.26 17.37 12 12 0 0 1-22.15 9.26C116.48 161.19 108.42 156 100 156s-16.47 5.2-19.59 12.63a12 12 0 1 1-22.13-9.26ZM88 120a12 12 0 1 0 12-12 12 12 0 0 0-12 12Z" /></Svg>;
export default IdentificationCard;