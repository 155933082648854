/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ContactlessPayment = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="contactless-payment-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-contactless-payment-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M95.31 101.2a57.37 57.37 0 0 1 0 53.6 6 6 0 0 1-10.62-5.6 44.75 44.75 0 0 0 0-42.4 6 6 0 1 1 10.62-5.6Zm47.86-34.49a6 6 0 0 0-2.46 8.12 112.67 112.67 0 0 1 0 106.34 6 6 0 1 0 10.58 5.66 124.65 124.65 0 0 0 0-117.66 6 6 0 0 0-8.12-2.46Zm-28 16a6 6 0 0 0-2.48 8.12 79 79 0 0 1 0 74.36 6 6 0 0 0 10.6 5.64 91 91 0 0 0 0-85.64 6 6 0 0 0-8.11-2.49ZM230 128A102 102 0 1 1 128 26a102.12 102.12 0 0 1 102 102Zm-12 0a90 90 0 1 0-90 90 90.1 90.1 0 0 0 90-90Z" /></Svg>;
export default ContactlessPayment;