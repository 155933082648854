/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const TextHSix = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="text-h-six-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-text-h-six-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M212 146a34.5 34.5 0 0 0-5.6.47l18.75-31.39a6 6 0 0 0-10.3-6.16l-32.25 54-.22.41A34 34 0 1 0 212 146Zm0 56a22 22 0 1 1 22-22 22 22 0 0 1-22 22ZM150 56v120a6 6 0 0 1-12 0v-54H46v54a6 6 0 0 1-12 0V56a6 6 0 0 1 12 0v54h92V56a6 6 0 0 1 12 0Z" /></Svg>;
export default TextHSix;