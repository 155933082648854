/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Radical = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="radical-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-radical-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 80v24a12 12 0 0 1-24 0V92h-91.68L91.24 212.21a12 12 0 0 1-22.47 0l-48-128a12 12 0 1 1 22.47-8.43l36.76 98 36.77-98A12 12 0 0 1 128 68h112a12 12 0 0 1 12 12Z" /></Svg>;
export default Radical;