/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Stool = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="stool-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-stool-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M204 64V40a20 20 0 0 0-20-20H72a20 20 0 0 0-20 20v24a20 20 0 0 0 20 20h2L52.15 222.13a12 12 0 1 0 23.7 3.74L83.1 180h89.8l7.25 45.87a12 12 0 1 0 23.7-3.74L182 84h2a20 20 0 0 0 20-20ZM76 44h104v16H76Zm93.11 112H86.89l11.36-72h59.5Z" /></Svg>;
export default Stool;