/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Lectern = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="lectern-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-lectern-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m242.72 122.63-40-80A11.93 11.93 0 0 0 192 36H64a11.93 11.93 0 0 0-10.73 6.63l-40 80A12 12 0 0 0 24 140h100v72H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8h-28v-72h100a12 12 0 0 0 10.73-17.37Zm-7.33 7.47A3.94 3.94 0 0 1 232 132H24a4 4 0 0 1-3.58-5.79l40-80A4 4 0 0 1 64 44h128a4 4 0 0 1 3.58 2.21l40 80a3.94 3.94 0 0 1-.19 3.89ZM188 104a4 4 0 0 1-4 4H72a4 4 0 0 1 0-8h112a4 4 0 0 1 4 4Z" /></Svg>;
export default Lectern;