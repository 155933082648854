/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BezierCurve = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bezier-curve-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bezier-curve-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M221 144.4A96.26 96.26 0 0 0 181 88h59a8 8 0 0 0 0-16h-81a32 32 0 0 0-62 0H16a8 8 0 0 0 0 16h59a96.26 96.26 0 0 0-40 56.4A32 32 0 1 0 71 184h114a32 32 0 1 0 36-39.6ZM40 192a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm88-128a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm88 128a16 16 0 1 1 16-16 16 16 0 0 1-16 16Z" /></Svg>;
export default BezierCurve;