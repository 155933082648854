/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Couch = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="couch-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-couch-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M16 100V72a16 16 0 0 1 16-16h84a4 4 0 0 1 4 4v76H64a32 32 0 0 0-32-32H20a4 4 0 0 1-4-4Zm208 4h12a4 4 0 0 0 4-4V72a16 16 0 0 0-16-16h-84a4 4 0 0 0-4 4v76h56a32 32 0 0 1 32-32Zm8 16h-8a16 16 0 0 0-16 16v8a8 8 0 0 1-8 8H56a8 8 0 0 1-8-8v-8a16 16 0 0 0-16-16h-8a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h8v15.73a8.18 8.18 0 0 0 7.47 8.27 8 8 0 0 0 8.53-8v-16h160v15.73a8.17 8.17 0 0 0 7.47 8.25 8 8 0 0 0 8.53-8V184h8a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16Z" /></Svg>;
export default Couch;