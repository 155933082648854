/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Cheese = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="cheese-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-cheese-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M184 40 24 88v24h8a24 24 0 0 1 24 23.54C56.25 149 44.91 160 31.46 160H24v32h56v-8a32 32 0 0 1 64 0v8h80a8 8 0 0 0 8-8V88a48 48 0 0 0-48-48Zm-16 96a32 32 0 0 1-27.72-48h55.44A32 32 0 0 1 168 136Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M184 32a7.81 7.81 0 0 0-2.3.34l-160 48A8 8 0 0 0 16 88v24a8 8 0 0 0 8 8h8a16.08 16.08 0 0 1 16 15.69A15.6 15.6 0 0 1 43.42 147a16.87 16.87 0 0 1-12 5.05H24a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h200a16 16 0 0 0 16-16V88a56.06 56.06 0 0 0-56-56Zm1.12 16a40.06 40.06 0 0 1 38.07 32H78.51Zm6.88 56a24 24 0 1 1-46.62-8h45.24a23.86 23.86 0 0 1 1.38 8ZM88 184a24 24 0 0 1 48 0Zm136 0h-72a40 40 0 0 0-80 0H32v-16a33 33 0 0 0 22.84-9.85A31.39 31.39 0 0 0 64 135.38 32.15 32.15 0 0 0 32 104v-8h96.81a40 40 0 1 0 78.38 0H224Z" /></Svg>;
export default Cheese;