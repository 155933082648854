/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Bandaids = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bandaids-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bandaids-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m190.23 128 24.88-24.89a44 44 0 1 0-62.22-62.22L128 65.77l-24.89-24.88a44 44 0 1 0-62.22 62.22L65.77 128l-24.88 24.89a44 44 0 1 0 62.22 62.22L128 190.23l24.89 24.88a44 44 0 1 0 62.22-62.22Zm-20.37-70.14a20 20 0 1 1 28.28 28.28L173.25 111 145 82.75ZM156.28 128 128 156.28 99.72 128 128 99.72ZM57.86 86.14a20 20 0 1 1 28.28-28.28L111 82.75 82.75 111Zm28.28 112a20 20 0 1 1-28.28-28.28L82.75 145 111 173.25Zm112 0a20 20 0 0 1-28.28 0L145 173.25 173.25 145l24.89 24.89a20 20 0 0 1 0 28.28Z" /></Svg>;
export default Bandaids;