/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GenderNeuter = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="gender-neuter-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-gender-neuter-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206 104a78 78 0 1 0-84 77.75V232a6 6 0 0 0 12 0v-50.25A78.09 78.09 0 0 0 206 104Zm-78 66a66 66 0 1 1 66-66 66.08 66.08 0 0 1-66 66Z" /></Svg>;
export default GenderNeuter;