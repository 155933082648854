/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const HandPeace = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="hand-peace-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-hand-peace-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M215.7 28a32 32 0 0 0-58.62 7.72L148 69.64l-9.09-33.91A32 32 0 0 0 77.1 52.3l7.72 28.82-2.49-.48a32 32 0 0 0-33 49c.63.95 1.31 1.86 2 2.73a31.69 31.69 0 0 0-6.67 14 32.08 32.08 0 0 0-.65 6.59V160a84 84 0 0 0 84 84h.64c46-.34 83.36-38.43 83.36-84.9v-3.34a59.83 59.83 0 0 0-11.41-35.1l18.3-68.36a31.84 31.84 0 0 0-3.2-24.3Zm-35.44 13.94a8 8 0 0 1 15.46 4.14L180.47 103a59.64 59.64 0 0 0-15-5.67ZM101.08 40a8 8 0 0 1 14.65 1.93l13.61 50.78a32.24 32.24 0 0 0-7.92 2.83 31.86 31.86 0 0 0-10-8l-11.1-41.43a8 8 0 0 1 .76-6.11ZM69.33 157.14a8 8 0 0 1 8.18-12.26l10.09 2a8 8 0 0 1 6.24 9.43 8 8 0 0 1-9.49 6.23l-10-2a8 8 0 0 1-5.02-3.4Zm-1.16-46.73a8 8 0 0 1 9.44-6.24h.13l20.73 4a8 8 0 0 1-3.26 15.67h-.13l-20.72-4a8 8 0 0 1-6.19-9.43ZM188 159.1c0 33.34-26.7 60.66-59.53 60.9a60.09 60.09 0 0 1-55.1-35.17l6.24 1.25a32 32 0 0 0 38.1-35.82 32 32 0 0 0 12 5.11l2.56.51A40.6 40.6 0 0 0 132 160a39.79 39.79 0 0 0 11.43 28 12 12 0 0 0 17.14-16.8 16 16 0 0 1-2.66-18.8 12 12 0 0 0-8.19-17.49l-15.32-3.06a8 8 0 1 1 3.25-15.67l21.4 4.28a36.09 36.09 0 0 1 28.95 35.3Z" /></Svg>;
export default HandPeace;