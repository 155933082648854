/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const GooglePhotosLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="google-photos-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-google-photos-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 116h-33.36A76 76 0 0 0 128 12a12 12 0 0 0-12 12v33.36A76 76 0 0 0 12 128a12 12 0 0 0 12 12h33.36A76 76 0 0 0 128 244a12 12 0 0 0 12-12v-33.36A76 76 0 0 0 244 128a12 12 0 0 0-12-12Zm-52-28a51.38 51.38 0 0 1-8.18 28H140V37.4A52.09 52.09 0 0 1 180 88ZM88 76a51.38 51.38 0 0 1 28 8.18V116H37.4A52.09 52.09 0 0 1 88 76Zm-12 92a51.38 51.38 0 0 1 8.18-28H116v78.6A52.09 52.09 0 0 1 76 168Zm92 12a51.38 51.38 0 0 1-28-8.18V140h78.6a52.09 52.09 0 0 1-50.6 40Z" /></Svg>;
export default GooglePhotosLogo;