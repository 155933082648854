/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ClockCountdown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="clock-countdown__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-clock-countdown'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 136.66A104.12 104.12 0 1 1 119.34 24a8 8 0 0 1 1.32 16A88.12 88.12 0 1 0 216 135.34a8 8 0 0 1 16 1.32ZM120 72v56a8 8 0 0 0 8 8h56a8 8 0 0 0 0-16h-48V72a8 8 0 0 0-16 0Zm40-24a12 12 0 1 0-12-12 12 12 0 0 0 12 12Zm36 24a12 12 0 1 0-12-12 12 12 0 0 0 12 12Zm24 36a12 12 0 1 0-12-12 12 12 0 0 0 12 12Z" /></Svg>;
export default ClockCountdown;