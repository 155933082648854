/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PenNibStraight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="pen-nib-straight__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-pen-nib-straight'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M222.33 123.89c-.06-.13-.12-.26-.19-.38L192 69.9V32a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v37.92l-30.14 53.59c-.07.12-.13.25-.2.38a15.94 15.94 0 0 0 1.46 16.57l.11.14 86.44 112.28a8 8 0 0 0 12.67 0l86.43-112.28.11-.14a15.92 15.92 0 0 0 1.45-16.57ZM176 32v32H80V32Zm-48 112a12 12 0 1 1 12-12 12 12 0 0 1-12 12Zm8 80.5v-65.67a28 28 0 1 0-16 0v65.66L48 131l28.69-51h102.63L208 131Z" /></Svg>;
export default PenNibStraight;