/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BugBeetle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="bug-beetle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-bug-beetle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M206 150h18a6 6 0 0 0 0-12h-18v-20h18a6 6 0 0 0 0-12h-18.25a77.81 77.81 0 0 0-22.38-48.88l20.87-20.88a6 6 0 1 0-8.48-8.48l-21.49 21.49a77.8 77.8 0 0 0-92.53 0l-21.5-21.49a6 6 0 0 0-8.48 8.48l20.88 20.88A77.76 77.76 0 0 0 50.25 106H32a6 6 0 0 0 0 12h18v20H32a6 6 0 0 0 0 12h18v10a78.6 78.6 0 0 0 .66 10H32a6 6 0 0 0 0 12h21.18a78 78 0 0 0 149.64 0H224a6 6 0 0 0 0-12h-18.66a78.6 78.6 0 0 0 .66-10ZM128 46a66.07 66.07 0 0 1 65.71 60H62.29A66.07 66.07 0 0 1 128 46Zm6 179.71V144a6 6 0 0 0-12 0v81.71A66.07 66.07 0 0 1 62 160v-42h132v42a66.07 66.07 0 0 1-60 65.71Z" /></Svg>;
export default BugBeetle;