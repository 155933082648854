/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const DiscordLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="discord-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-discord-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 140a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm64-8a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm72.83 57.25-67 29.71a12.36 12.36 0 0 1-5 1 12.13 12.13 0 0 1-11.38-7.88l-9.15-24.81c-5.36.45-10.81.69-16.34.69s-11-.24-16.34-.69l-9.15 24.81A12.13 12.13 0 0 1 91.13 220a12.36 12.36 0 0 1-5-1l-67-29.71a12 12 0 0 1-6.8-13.88L41.9 59a12.06 12.06 0 0 1 9.77-8.91l36.06-5.92a12.18 12.18 0 0 1 13.73 8.91l4.12 16.22a195.47 195.47 0 0 1 44.84 0l4.12-16.22a12.18 12.18 0 0 1 13.73-8.91l36.06 5.92A12.06 12.06 0 0 1 214.1 59l29.53 116.38a12 12 0 0 1-6.8 13.87Zm-1-11.91L206.35 61a4.07 4.07 0 0 0-3.35-3l-36-5.95a4.15 4.15 0 0 0-4.69 3l-3.91 15.33a166.74 166.74 0 0 1 18.68 4.08 4 4 0 1 1-2.16 7.7A176.21 176.21 0 0 0 128 76a176.21 176.21 0 0 0-46.92 6.16 4 4 0 1 1-2.16-7.7 166.74 166.74 0 0 1 18.68-4.08L93.71 55a4.15 4.15 0 0 0-4.69-3L53 58a4.07 4.07 0 0 0-3.31 3L20.12 177.34a4 4 0 0 0 2.29 4.59l67 29.71a4.16 4.16 0 0 0 3.35 0 4 4 0 0 0 2.24-2.29l8.45-22.88a171.49 171.49 0 0 1-24.53-4.92 4 4 0 0 1 2.16-7.71A176.21 176.21 0 0 0 128 180a176.21 176.21 0 0 0 46.92-6.16 4 4 0 0 1 2.16 7.71 171.49 171.49 0 0 1-24.53 4.92l8.45 22.88a4 4 0 0 0 2.23 2.32 4.16 4.16 0 0 0 3.35 0l67-29.71a4 4 0 0 0 2.3-4.62Z" /></Svg>;
export default DiscordLogo;