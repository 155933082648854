/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const BriefcaseMetal = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="briefcase-metal-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-briefcase-metal-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 58h-42V48a22 22 0 0 0-22-22h-48a22 22 0 0 0-22 22v10H40a14 14 0 0 0-14 14v128a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V72a14 14 0 0 0-14-14ZM38 110h180v52H38Zm56-62a10 10 0 0 1 10-10h48a10 10 0 0 1 10 10v10H94ZM40 70h176a2 2 0 0 1 2 2v26H38V72a2 2 0 0 1 2-2Zm176 132H40a2 2 0 0 1-2-2v-26h180v26a2 2 0 0 1-2 2Z" /></Svg>;
export default BriefcaseMetal;