/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const FastForward = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="fast-forward-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-fast-forward-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m246.52 118-88.19-56.13a12 12 0 0 0-12.18-.39A11.66 11.66 0 0 0 140 71.84v44.59L54.33 61.87a12 12 0 0 0-12.18-.39A11.66 11.66 0 0 0 36 71.84v112.32a11.66 11.66 0 0 0 6.15 10.36 12 12 0 0 0 12.18-.39L140 139.57v44.59a11.66 11.66 0 0 0 6.15 10.36 12 12 0 0 0 12.18-.39L246.52 138a11.81 11.81 0 0 0 0-19.94Zm-108.3 13.19L50 187.38a3.91 3.91 0 0 1-4 .13 3.76 3.76 0 0 1-2-3.35V71.84a3.76 3.76 0 0 1 2-3.35 4 4 0 0 1 1.91-.5 3.94 3.94 0 0 1 2.13.63l88.18 56.16a3.8 3.8 0 0 1 0 6.44Zm104 0L154 187.38a3.91 3.91 0 0 1-4 .13 3.76 3.76 0 0 1-2-3.35V71.84a3.76 3.76 0 0 1 2-3.35 4 4 0 0 1 1.91-.5 3.94 3.94 0 0 1 2.13.63l88.18 56.16a3.8 3.8 0 0 1 0 6.44Z" /></Svg>;
export default FastForward;