/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Translate = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="translate-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-translate-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m250.73 210.63-56-112a12 12 0 0 0-21.46 0l-20.52 41A84.2 84.2 0 0 1 114 126.22 107.48 107.48 0 0 0 139.33 68H160a12 12 0 0 0 0-24h-52V32a12 12 0 0 0-24 0v12H32a12 12 0 0 0 0 24h83.13A83.69 83.69 0 0 1 96 110.35 84 84 0 0 1 83.6 91a12 12 0 1 0-21.81 10A107.55 107.55 0 0 0 78 126.24 83.54 83.54 0 0 1 32 140a12 12 0 0 0 0 24 107.47 107.47 0 0 0 64-21.07 108.4 108.4 0 0 0 45.39 19.44l-24.13 48.26a12 12 0 1 0 21.46 10.73L151.41 196h65.17l12.68 25.36a12 12 0 1 0 21.47-10.73ZM163.41 172 184 130.83 204.58 172Z" /></Svg>;
export default Translate;