/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Motorcycle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="motorcycle__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-motorcycle'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 120a41 41 0 0 0-6.6.55l-5.82-15.14A55.64 55.64 0 0 1 216 104a8 8 0 0 0 0-16h-19.12l-13.41-34.87A8 8 0 0 0 176 48h-32a8 8 0 0 0 0 16h26.51l9.23 24H152c-18.5 0-33.5 4.31-43.37 12.46a16 16 0 0 1-16.76 2.07c-10.58-4.81-60.74-25.2-65.16-26.93L21 73.36A17.74 17.74 0 0 0 16 72a8 8 0 0 0-2.87 15.46c.46.18 47.19 18.3 72.13 29.63a32.15 32.15 0 0 0 33.56-4.29c4.86-4 14.57-8.8 33.19-8.8h18.82a71.74 71.74 0 0 0-24.17 36.59A15.86 15.86 0 0 1 131.32 152H79.2a40 40 0 1 0 0 16h52.12a31.91 31.91 0 0 0 30.74-23.1 56 56 0 0 1 26.59-33.72l5.82 15.13A40 40 0 1 0 216 120ZM40 168h22.62a24 24 0 1 1 0-16H40a8 8 0 0 0 0 16Zm176 16a24 24 0 0 1-15.58-42.23l8.11 21.1a8 8 0 1 0 14.94-5.74L215.35 136h.65a24 24 0 0 1 0 48Z" /></Svg>;
export default Motorcycle;