/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ticket = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ticket-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ticket-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 100a4 4 0 0 0 4-4V64a12 12 0 0 0-12-12H32a12 12 0 0 0-12 12v32a4 4 0 0 0 4 4 28 28 0 0 1 0 56 4 4 0 0 0-4 4v32a12 12 0 0 0 12 12h192a12 12 0 0 0 12-12v-32a4 4 0 0 0-4-4 28 28 0 0 1 0-56ZM28 192v-28.22a36 36 0 0 0 0-71.56V64a4 4 0 0 1 4-4h60v136H32a4 4 0 0 1-4-4Zm168-64a36.06 36.06 0 0 0 32 35.78V192a4 4 0 0 1-4 4H100V60h124a4 4 0 0 1 4 4v28.22A36.06 36.06 0 0 0 196 128Z" /></Svg>;
export default Ticket;