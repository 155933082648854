/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Shovel = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="shovel-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-shovel-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m244.24 59.76-48-48a6 6 0 0 0-8.48 8.48L207.52 40l-73.86 73.86L97.9 78.1a14 14 0 0 0-19.8 0l-56 56A13.94 13.94 0 0 0 18 144v80a14 14 0 0 0 14 14h80a13.94 13.94 0 0 0 9.9-4.1l56-56a14 14 0 0 0 0-19.8l-35.76-35.76L216 48.48l19.76 19.76a6 6 0 1 0 8.48-8.48Zm-74.83 106.82a2 2 0 0 1 0 2.84l-56 56a2 2 0 0 1-1.41.58H32a2 2 0 0 1-2-2v-80a2 2 0 0 1 .58-1.42l56-56A2 2 0 0 1 88 86a2.07 2.07 0 0 1 1.42.58l35.75 35.76-41.41 41.42a6 6 0 1 0 8.48 8.48l41.42-41.41Z" /></Svg>;
export default Shovel;