/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const UserRectangle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="user-rectangle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-user-rectangle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 56v144a8 8 0 0 1-8 8h-20.1a72 72 0 0 0-67.9-48 40 40 0 1 0-40-40 40 40 0 0 0 40 40 72 72 0 0 0-67.9 48H40a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8h176a8 8 0 0 1 8 8Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM96 120a32 32 0 1 1 32 32 32 32 0 0 1-32-32Zm-23.43 80a64 64 0 0 1 110.86 0ZM216 200h-14.67a80.14 80.14 0 0 0-43.69-42.28 48 48 0 1 0-59.28 0A80.14 80.14 0 0 0 54.67 200H40V56h176v144Z" /></Svg>;
export default UserRectangle;