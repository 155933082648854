/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const PicnicTable = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="picnic-table-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-picnic-table-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 130h-48.29l-30-60H192a6 6 0 0 0 0-12H64a6 6 0 0 0 0 12h22.29l-30 60H8a6 6 0 0 0 0 12h42.29l-23.66 47.32a6 6 0 0 0 10.74 5.36L63.71 142h128.58l26.34 52.68a6 6 0 1 0 10.74-5.36L205.71 142H248a6 6 0 0 0 0-12Zm-178.29 0 30-60h56.58l30 60Z" /></Svg>;
export default PicnicTable;